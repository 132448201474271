import { dialogStore } from "@ucl/library";
import { DialogProps } from "@ucl/library/lib/components/Dialogs/Dialog/Dialog";
import { CommentTemplateBrowseForm } from "./CommentTemplateBrowseForm";
import { FormTypes } from "../../../../../common/types/FormTypes";
import { Dispatch, SetStateAction } from "react";

const dialogIdBase = "comment-template-browse-dialog";

const getDialogId = (): string => `${dialogIdBase}`;

const buildCommentTemplateDialog = (
  formType: FormTypes,
  formFieldKey: string,
  setSelectedTemplate: Dispatch<SetStateAction<string>>
): DialogProps => {
  return {
    id: getDialogId(),
    className: dialogIdBase,
    header: "Browse Comment Templates",
    allowDragging: false,
    content: () => (
      <div>
        <CommentTemplateBrowseForm
          formType={formType}
          formFieldKey={formFieldKey}
          setSelectedTemplate={setSelectedTemplate}
        />
      </div>
    ),
  };
};

export const openCommentTemplateBrowseDialog = (
  formType: FormTypes,
  formFieldKey: string,
  setSelectedTemplate: Dispatch<SetStateAction<string>>
) => {
  dialogStore.openDialog(
    buildCommentTemplateDialog(formType, formFieldKey, setSelectedTemplate)
  );
};

export const closeCommentTemplateBrowseDialog = () =>
  dialogStore.closeDialog(getDialogId());
