import React from "react";
import "./styles.scss";
import { Evaluation_CommercialEvaluation } from "../../../commercial/types/CommercialEvaluation";
import { Evaluation_MultifamilyEvaluation } from "../../../multifamily/types/MultifamilyEvaluation";
import { Evaluation_MultifamilyEvaluationFormModel } from "../../../multifamily/types/MultifamilyEvaluationFormModel";
import { Evaluation_CommercialEvaluationFormModel } from "../../../commercial/types/CommercialEvaluationFormModel";
import { Evaluation_CommercialRedesignation } from "../../../commercialRedesignation/types/CommercialRedesignation";
import { Evaluation_CommercialRedesignationFormModel } from "../../../commercialRedesignation/types/CommercialRedesignationFormModel";
import { Evaluation_MultifamilyRedesignation } from "../../../multifamilyRedesignation/types/MultifamilyRedesignation";
import { Evaluation_MultifamilyRedesignationFormModel } from "../../../multifamilyRedesignation/types/MultifamilyRedesignationFormModel";

interface FCFMFAppHeaderProps {
  evaluation:
    | Evaluation_CommercialEvaluation
    | Evaluation_CommercialEvaluationFormModel
    | Evaluation_MultifamilyEvaluation
    | Evaluation_MultifamilyEvaluationFormModel
    | Evaluation_CommercialRedesignation
    | Evaluation_CommercialRedesignationFormModel
    | Evaluation_MultifamilyRedesignation
    | Evaluation_MultifamilyRedesignationFormModel;
}

const FCFMFAppHeader: React.FC<FCFMFAppHeaderProps> = ({ evaluation }) => {
  const isCommercial =
    "productType_AsString" in evaluation &&
    evaluation.productType_AsString === "FORTIFIED Commercial";
  const evaluationType = isCommercial ? "Commercial" : "Multifamily";
  const fortifiedId = evaluation.fortifiedId;
  const address = evaluation.buildingAddress_AsString;

  return (
    <div className="fcfmf-app-header">
      <div className="fcfmf-app-header__primary">
        Fortified {evaluationType} -
      </div>
      <div className="fcfmf-app-header__address">
        <div className="fcfmf-app-header__address__primary">{address}</div>
      </div>
      <div className="fcfmf-app-header__application-id">
        {` (${fortifiedId})`}
      </div>
    </div>
  );
};

export default FCFMFAppHeader;
