import { useContext, useEffect, useState } from "react";
import { UnsavedChangesBlockerContext } from "../../../../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import {
  FormChecklistPart,
  FormChecklistStatus,
} from "../../../../../../../common/types/Forms/FormChecklist";
import { MultifamilyEvaluationFieldInspectionFormContainerProps } from "./MultifamilyEvaluationFieldInspectionFormContainer";
import { FCFMFEvaluationFieldInspectionFormParts } from "../../../../../common/types/Evaluation/BaseFCFMFEvaluationFieldInspectionForm";
import { FCFMFSchemaFactoryV2 } from "../../../../../common/customHooks/useFCFMFFieldSchemaFactory";
import { Evaluation_MultifamilyEvaluationFormModel } from "../../../../../multifamily/types/MultifamilyEvaluationFormModel";
import { fortifiedFieldSchemaApiClient } from "../../../../../../Common/lib/apiClients/fortifiedFieldSchemaApiClient";

function useMultifamilyEvaluationFieldInspectionFormContainer(
  props: MultifamilyEvaluationFieldInspectionFormContainerProps
) {
  const [multifamilySchemaFactory, setMultifamilySchemaFactory] =
    useState<FCFMFSchemaFactoryV2>();

  const getSchema = async () => {
    const schema = await fortifiedFieldSchemaApiClient.getSchema();
    const newSchemaFactory = new FCFMFSchemaFactoryV2(schema);
    setMultifamilySchemaFactory(newSchemaFactory);
  };

  useEffect(() => {
    if (!multifamilySchemaFactory) {
      getSchema();
    }
    //return () => {
    //     setDefaultHeader();
    //   };
  }, []);

  useEffect(() => {
    setEvaluationFieldInspectionFormPart(getBestFormPart());
  }, [props.evaluationFieldInspectionFormPart]);

  // const { setWildfireEvaluationHeader, setDefaultHeader } =
  //   useContext(AppHeaderContext);
  const { setHasUnsavedChanges } = useContext(UnsavedChangesBlockerContext);

  const [
    evaluationFieldInspectionFormPart,
    setEvaluationFieldInspectionFormPart,
  ] = useState<FCFMFEvaluationFieldInspectionFormParts | undefined>(
    props.evaluationFieldInspectionFormPart
  );

  const [evaluationFormModel, setEvaluationFormModel] =
    useState<Evaluation_MultifamilyEvaluationFormModel>();

  const evaluationFieldInspectionForm =
    evaluationFormModel?.multifamilyEvaluation
      .multifamilyEvaluationFieldInspectionForm;

  const getNextUnsubmittedFormPart = () => {
    if (!evaluationFieldInspectionForm) {
      return null;
    }

    if (!evaluationFieldInspectionForm?.isFileUploadersComplete) {
      return FCFMFEvaluationFieldInspectionFormParts.EvaluationFieldInspection;
    }

    // Form is complete
    return null;
  };

  const getBestFormPart = (): FCFMFEvaluationFieldInspectionFormParts => {
    // Default to the first part of the form if we can't find a better option
    const defaultFormPart = Object.values(
      FCFMFEvaluationFieldInspectionFormParts
    )[0];

    // If the form part is provided (by URL), use it
    if (!!props.evaluationFieldInspectionFormPart) {
      return props.evaluationFieldInspectionFormPart;
    }

    // Otherwise, try to find the first unsubmitted part of the form
    // TODO: This doesn't work yet, needs to wait for applicationFormModel to load
    return getNextUnsubmittedFormPart() ?? defaultFormPart;
  };

  const getEvaluationFieldInspectionPartChecklistStatus = (
    formPart: FCFMFEvaluationFieldInspectionFormParts
  ): FormChecklistStatus => {
    if (formPart === getNextUnsubmittedFormPart()) {
      return FormChecklistStatus.NextUp;
    }

    switch (formPart) {
      case FCFMFEvaluationFieldInspectionFormParts.EvaluationFieldInspection:
        return evaluationFieldInspectionForm?.isFileUploadersComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
    }
  };

  const evaluationFieldInspectionChecklistParts: FormChecklistPart<FCFMFEvaluationFieldInspectionFormParts>[] =
    [
      {
        formPart:
          FCFMFEvaluationFieldInspectionFormParts.EvaluationFieldInspection,
        displayName: "Field Inspection",
        requiredFieldCount:
          evaluationFieldInspectionForm?.evaluationFieldInspectionFormRequiredFieldCount,
        disabled:
          evaluationFieldInspectionForm?.isFileUploadersComplete !== true,
      },
    ];

  return {
    evaluationFieldInspectionChecklistParts,
    getEvaluationFieldInspectionPartChecklistStatus,
    setEvaluationFormModel,
    setHasUnsavedChanges,
    evaluationFieldInspectionFormPart,
    getBestFormPart,
    multifamilySchemaFactory,
  };
}

export default useMultifamilyEvaluationFieldInspectionFormContainer;
