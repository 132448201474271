import baseApiClient from "../../../../../../foritfied/lib/apiClients/baseApiClient";
import { BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts } from "../../../../common/types/BaseHomeEvaluationRoofingComplianceGeneralInformationFormModel";
import { Evaluation_HomeNCIUAEvaluationFormModel } from "../../types/HomeNCIUAEvaluationFormModel";
import { HomeNCIUAEvaluationRoofingComplianceGeneralInformationFormModel } from "../../types/HomeNCIUAEvaluationRoofingComplianceGeneralInformationFormModel";
import baseExternalApiClient from "../../../../../../foritfied/lib/apiClients/baseExternalApiClient";

class HomeNCIUAEvaluationRoofingComplianceGeneralInformationAPIClient {
  updateHomeNCIUARoofingComplianceGeneralInformationForm = async (
    evaluationId: string,
    form: HomeNCIUAEvaluationRoofingComplianceGeneralInformationFormModel,
    fieldKey?: string
  ): Promise<Evaluation_HomeNCIUAEvaluationFormModel> => {
    return baseApiClient.put<Evaluation_HomeNCIUAEvaluationFormModel>(
      `/home-nciua/roofing-compliance-general-information-form/${evaluationId}`,
      {
        roofingComplianceGeneralInformationForm: form,
        fieldKey,
      }
    );
  };

  updateHomeNCIUARoofingComplianceGeneralInformationFormExternal = async (
    evaluationId: string,
    form: HomeNCIUAEvaluationRoofingComplianceGeneralInformationFormModel,
    accessToken: string
  ): Promise<Evaluation_HomeNCIUAEvaluationFormModel> => {
    return baseExternalApiClient.put<Evaluation_HomeNCIUAEvaluationFormModel>(
      `/home-nciua/roofing-compliance-general-information-form/external/${evaluationId}`,
      {
        roofingComplianceGeneralInformationForm: form,
      },
      {
        headers: {
          AccessToken: accessToken,
        },
      }
    );
  };

  submitHomeNCIUARoofingComplianceGeneralInformationForm = async (
    evaluationId: string,
    form: HomeNCIUAEvaluationRoofingComplianceGeneralInformationFormModel,
    formPart?: BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts
  ): Promise<Evaluation_HomeNCIUAEvaluationFormModel> => {
    // If applicationFormPart is not provided, default to the last part of the form
    const formPartValue = (
      formPart ??
      (Object.values(
        BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts
      ).pop() as BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts)
    ).valueOf();
    return baseApiClient.post<Evaluation_HomeNCIUAEvaluationFormModel>(
      `/home-nciua/roofing-compliance-general-information-form/${evaluationId}/${formPartValue}`,
      form
    );
  };

  submitHomeNCIUARoofingComplianceGeneralInformationFormExternal = async (
    evaluationId: string,
    form: HomeNCIUAEvaluationRoofingComplianceGeneralInformationFormModel,
    accessToken: string
  ): Promise<Evaluation_HomeNCIUAEvaluationFormModel> => {
    // Always default to the last part of the form
    const formPartValue = (
      Object.values(
        BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts
      ).pop() as BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts
    ).valueOf();
    return baseExternalApiClient.post<Evaluation_HomeNCIUAEvaluationFormModel>(
      `/home-nciua/roofing-compliance-general-information-form/${evaluationId}/${formPartValue}`,
      form,
      {
        headers: {
          AccessToken: accessToken,
        },
      }
    );
  };
}

export const homeNCIUAEvaluationRoofingComplianceGeneralInformationAPIClient =
  new HomeNCIUAEvaluationRoofingComplianceGeneralInformationAPIClient();
