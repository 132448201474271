import { useContext, useEffect, useState } from "react";
import { UnsavedChangesBlockerContext } from "../../../../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import {
  FormChecklistPart,
  FormChecklistStatus,
} from "../../../../../../../common/types/Forms/FormChecklist";
import { HomeSchemaFactoryV2 } from "../../../../../customHooks/useHomeFieldSchemaFactory";
import { BaseHomeRoofingComplianceRoofCoverFormParts } from "../../../../../common/types/BaseHomeEvaluationRoofingComplianceRoofCoverFormModel";
import { HomeStandardRoofingRoofCoverFormContainerProps } from "./HomeStandardRedesignationRoofingComplianceRoofCoverContainer";
import { AppHeaderContext } from "../../../../../../../wildfire/components/Provider/AppHeaderContext";
import { Evaluation_HomeStandardRedesignationFormModel } from "../../../../types/HomeStandardRedesignationFormModel_Model";
import { fortifiedFieldSchemaApiClient } from "../../../../../../Common/lib/apiClients/fortifiedFieldSchemaApiClient";
import {
  shouldHideAsphaltShingleRoofCoverDetailsFields,
  shouldHideClayConcreteTileRoofCoverDetailsFields,
  shouldHideLowSlopeRoofCoverDetailsFields,
  shouldHideMetalRoofCoverDetailsFields,
  shouldHideWoodShakeSingleRoofCoverDetailsFields,
} from "../../../../../common/components/FieldSchema/sharedConditionalLogic";

function useHomeStandardRoofingComplianceRoofCoverFormContainer(
  props: HomeStandardRoofingRoofCoverFormContainerProps
) {
  const [schemaFactory, setSchemaFactory] = useState<HomeSchemaFactoryV2>();

  const getSchema = async () => {
    const schema = await fortifiedFieldSchemaApiClient.getSchema();
    const newSchemaFactory = new HomeSchemaFactoryV2(schema);
    setSchemaFactory(newSchemaFactory);
  };

  useEffect(() => {
    if (!schemaFactory) {
      getSchema();
    }
    return () => {
      setDefaultHeader();
    };
  }, []);

  useEffect(() => {
    setFormPart(getBestFormPart());
  }, [props.formParts]);

  const { setHasUnsavedChanges } = useContext(UnsavedChangesBlockerContext);

  const [formPart, setFormPart] = useState<
    BaseHomeRoofingComplianceRoofCoverFormParts | undefined
  >(props.formParts);

  const [evaluationFormModel, setEvaluationFormModel] =
    useState<Evaluation_HomeStandardRedesignationFormModel>();

  const formModel =
    evaluationFormModel?.homeStandardRedesignation
      .roofingComplianceRoofCoverForm;

  const { setFotifiedHomeEvaluationHeader, setDefaultHeader } =
    useContext(AppHeaderContext);

  useEffect(() => {
    if (evaluationFormModel) {
      setFotifiedHomeEvaluationHeader(evaluationFormModel);
    }
  }, [evaluationFormModel]);

  const getNextUnsubmittedFormPart = () => {
    if (!formModel) {
      return null;
    }

    if (!formModel.isRoofCoverInformationComplete) {
      return BaseHomeRoofingComplianceRoofCoverFormParts.RoofCoverInformation;
    } else if (!formModel.isAsphaltShingleRoofCoverDetailsComplete) {
      return BaseHomeRoofingComplianceRoofCoverFormParts.AsphaltShingleRoofCoverDetails;
    } else if (!formModel.isMetalRoofCoverDetailsComplete) {
      return BaseHomeRoofingComplianceRoofCoverFormParts.MetalRoofCoverDetails;
    } else if (!formModel.isClayConcreteTileRoofCoverDetailsComplete) {
      return BaseHomeRoofingComplianceRoofCoverFormParts.ClayConcreteTileRoofCoverDetails;
    } else if (!formModel.isWoodShakeSingleRoofCoverDetailsComplete) {
      return BaseHomeRoofingComplianceRoofCoverFormParts.WoodShakeSingleRoofCoverDetails;
    } else if (!formModel.isLowSlopeRoofCoverDetailsComplete) {
      return BaseHomeRoofingComplianceRoofCoverFormParts.LowSlopeRoofCoverDetails;
    }

    // Form is complete
    return null;
  };

  const getBestFormPart = (): BaseHomeRoofingComplianceRoofCoverFormParts => {
    // Default to the first part of the form if we can't find a better option
    const defaultFormPart = Object.values(
      BaseHomeRoofingComplianceRoofCoverFormParts
    )[0];

    // If the application form part is provided (by URL), use it
    if (!!props.formParts) {
      return props.formParts;
    }

    // Otherwise, try to find the first unsubmitted part of the form
    // TODO: This doesn't work yet, needs to wait for applicationFormModel to load
    return getNextUnsubmittedFormPart() ?? defaultFormPart;
  };

  const getApplicationPartChecklistStatus = (
    formPart: BaseHomeRoofingComplianceRoofCoverFormParts
  ): FormChecklistStatus => {
    if (formPart === getNextUnsubmittedFormPart()) {
      return FormChecklistStatus.NextUp;
    }

    switch (formPart) {
      case BaseHomeRoofingComplianceRoofCoverFormParts.RoofCoverInformation:
        return formModel?.isRoofCoverInformationComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case BaseHomeRoofingComplianceRoofCoverFormParts.AsphaltShingleRoofCoverDetails:
        return formModel?.isAsphaltShingleRoofCoverDetailsComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case BaseHomeRoofingComplianceRoofCoverFormParts.MetalRoofCoverDetails:
        return formModel?.isMetalRoofCoverDetailsComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case BaseHomeRoofingComplianceRoofCoverFormParts.ClayConcreteTileRoofCoverDetails:
        return formModel?.isClayConcreteTileRoofCoverDetailsComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case BaseHomeRoofingComplianceRoofCoverFormParts.WoodShakeSingleRoofCoverDetails:
        return formModel?.isWoodShakeSingleRoofCoverDetailsComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case BaseHomeRoofingComplianceRoofCoverFormParts.LowSlopeRoofCoverDetails:
        return formModel?.isLowSlopeRoofCoverDetailsComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      default:
        return FormChecklistStatus.Unsubmitted;
    }
  };

  const checklistParts: FormChecklistPart<BaseHomeRoofingComplianceRoofCoverFormParts>[] =
    [
      {
        formPart:
          BaseHomeRoofingComplianceRoofCoverFormParts.RoofCoverInformation,
        displayName: "Roof Cover Information",
        requiredFieldCount: formModel?.roofCoverInformationRequiredFieldCount,
      },
      {
        formPart:
          BaseHomeRoofingComplianceRoofCoverFormParts.AsphaltShingleRoofCoverDetails,
        displayName: "Asphalt Shingle Roof Cover Details",
        requiredFieldCount:
          formModel?.asphaltShingleRoofCoverDetailsRequiredFieldCount,
        hidden: shouldHideAsphaltShingleRoofCoverDetailsFields(
          formModel?.roofCoverType
        ),
      },
      {
        formPart:
          BaseHomeRoofingComplianceRoofCoverFormParts.MetalRoofCoverDetails,
        displayName: "Metal Roof Cover Details",
        requiredFieldCount: formModel?.metalRoofCoverDetailsRequiredFieldCount,
        hidden: shouldHideMetalRoofCoverDetailsFields(formModel?.roofCoverType),
      },
      {
        formPart:
          BaseHomeRoofingComplianceRoofCoverFormParts.ClayConcreteTileRoofCoverDetails,
        displayName: "Clay/Concrete Tile Roof Cover Details",
        requiredFieldCount:
          formModel?.clayConcreteTileRoofCoverDetailsRequiredFieldCount,
        hidden: shouldHideClayConcreteTileRoofCoverDetailsFields(
          formModel?.roofCoverType
        ),
      },
      {
        formPart:
          BaseHomeRoofingComplianceRoofCoverFormParts.WoodShakeSingleRoofCoverDetails,
        displayName: "Wood Shake/Shingle Roof Cover Details",
        requiredFieldCount:
          formModel?.woodShakeSingleRoofCoverDetailsRequiredFieldCount,
        hidden: shouldHideWoodShakeSingleRoofCoverDetailsFields(
          formModel?.roofCoverType
        ),
      },
      {
        formPart:
          BaseHomeRoofingComplianceRoofCoverFormParts.LowSlopeRoofCoverDetails,
        displayName: "Low Slope Roof Cover Details",
        requiredFieldCount:
          formModel?.lowSlopeRoofCoverDetailsRequiredFieldCount,
        hidden: shouldHideLowSlopeRoofCoverDetailsFields(
          formModel?.roofCoverType
        ),
      },
    ];

  return {
    applicationChecklistParts: checklistParts,
    getApplicationPartChecklistStatus,
    setEvaluationFormModel,
    setHasUnsavedChanges,
    formPart,
    getBestFormPart,
    schemaFactory,
  };
}

export default useHomeStandardRoofingComplianceRoofCoverFormContainer;
