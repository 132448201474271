import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { useState } from "react";
import { useNavigate } from "react-router";
import { showErrorAppToaster, showSuccessAppToaster } from "../../Toast/Toast";
import { useProductTypeHandler } from "../../../providers/ProductTypeHandlerProvider";
import { RelativityTooltip } from "@ucl/library";

interface ResubmitEvaluationActionButtonProps {
  resubmit: () => Promise<void>;
}

const ResubmitEvaluationActionButton: React.FC<
  ResubmitEvaluationActionButtonProps
> = ({ resubmit }) => {
  const navigate = useNavigate();
  const { routePrefix } = useProductTypeHandler();
  const [isLoading, setIsLoading] = useState(false);
  return (
    <RelativityTooltip
      shouldShowTooltip={true}
      compact={true}
      content={
        <div className="reopen-tooltip">
          By clicking this button, this Evaluation will be moved back to Payment
          status so all fields may be edited.
        </div>
      }
    >
      <Button
        text="Reopen"
        intent={Intent.PRIMARY}
        icon={IconNames.Repeat}
        disabled={false}
        loading={isLoading}
        minimal
        onClick={async () => {
          setIsLoading(true);
          await resubmit()
            .then(() => {
              showSuccessAppToaster("Evaluation Resubmitted");
              navigate(`${routePrefix}`);
            })
            .catch(() => {
              showErrorAppToaster("Evaluation Resubmission Failed");
            })
            .finally(() => {
              setIsLoading(false);
            });
        }}
      />
    </RelativityTooltip>
  );
};

export default ResubmitEvaluationActionButton;
