export enum FormTypes {
  // Home Standard Forms 1-100
  HomeStandardEvaluationBasicInformationForm = 1,
  HomeStandardEvaluationRoofingComplianceGeneralInformationForm = 2,
  HomeStandardEvaluationRoofingComplianceRoofCoverForm = 3,
  HomeStandardEvaluationRooferConfirmationForm = 4,
  HomeStandardRedesignationHomeownerAuthorizationForm = 5,
  HomeStandardEvaluationEngineerConfirmationForm = 6,
  HomeStandardEvaluationSilverGoldComplianceGeneralInformationForm = 7,
  HomeStandardEvaluationInstallingContractorConfirmationForm = 8,
  HomeStandardEvaluationInstallerComplianceGablesForm = 9,
  HomeStandardEvaluationInstallerComplianceContinuousLoadPathForm = 10,
  HomeStandardEvaluationInstallerComplianceChimneyForm = 11,
  HomeStandardEvaluationInstallerComplianceAttachedStructureForm = 12,
  HomeStandardEvaluationEngineeringComplianceGeneralInformationForm = 13,
  HomeStandardEvaluationEngineeringComplianceGablesForm = 14,
  HomeStandardEvaluationEngineeringComplianceContinuousLoadPathForm = 15,
  HomeStandardEvaluationEngineeringComplianceChimneyForm = 16,
  HomeStandardEvaluationEngineeringComplianceAttachedStructureForm = 17,
  HomeStandardEvaluationEligibilityForm = 18,
  HomeStandardRedesignationForm = 19,

  // Home NCIUA Forms 101-200
  HomeNCIUAEvaluationForm = 101,
  HomeNCIUAEvaluationRoofingComplianceRoofCoverForm = 102,
  HomeNCIUAEvaluationRoofingComplianceGeneralInformationForm = 103,
  HomeNCIUAEvaluationRooferConfirmationForm = 104,
  HomeNCIUAEvaluationEligibilityForm = 105,
  HomeNCIUARedesignationRedesignationForm = 106,
  HomeNCIUARedesignationHomeownerAuthorizationForm = 107,

  // Home SCSHE Forms 201-300
  HomeSCSHEvaluationForm = 201,
  HomeSCSHEvaluationBasicInformationForm = 202,

  // Wildfire Forms 301-400
  WildfireApplicationForm = 301,
  WildfireRoofFieldEvaluationForm = 302,
  WildfireHomeExteriorFieldEvaluationForm = 303,
  WildfireLandscapeFieldEvaluationForm = 304,
  WildfireOtherFieldEvaluationForm = 305,

  // Multifamily Evaluation Forms 401-500
  MultifamilyEvaluationApplicationForm = 401,
  MultifamilyEvaluationCompanyAssignmentForm = 402,
  MultifamilyEvaluationComplianceForm = 403,
  MultifamilyEvaluationComplianceLetterForm = 404,
  MultifamilyEvaluationFieldInspectionForm = 405,
  MultifamilyRedesignationRedesignationForm = 406,
  MultifamilyRedesignationEvaluatorEmailForm = 407,
  MultifamilyRedesignationComplianceLetterForm = 408,
  MultifamilyRedesignationFieldInspectionForm = 409,

  // Commercial Evaluation Forms 501-600
  CommercialEvaluationApplicationForm = 501,
  CommercialEvaluationCompanyAssignmentForm = 502,
  CommercialEvaluationFieldInspectionForm = 503,
  CommercialEvaluationComplianceForm = 504,
  CommercialEvaluationComplianceLetter = 505,
  CommercialRedesignationRedesignationForm = 506,
  CommercialRedesignationEvaluatorEmailForm = 507,
  CommercialRedesignationComplianceLetterForm = 508,
  CommercialRedesignationFieldInspectionForm = 509,
}

export interface FormTypeOption {
  label: string;
  subcategory?: string;
  value: FormTypes;
  fullFormName?: string;
}

export const FormTypeOptions: { [key: number]: FormTypeOption[] } = {
  // Commercial Evaluation Forms
  1: [
    {
      label: "Application Form",
      value: FormTypes.CommercialEvaluationApplicationForm,
      fullFormName: "CommercialEvaluationApplicationForm",
    },
    {
      label: "Company Assignment Form",
      value: FormTypes.CommercialEvaluationCompanyAssignmentForm,
      fullFormName: "CommercialEvaluationCompanyAssignmentForm",
    },
    {
      label: "Field Inspection Form",
      value: FormTypes.CommercialEvaluationFieldInspectionForm,
      fullFormName: "CommercialEvaluationFieldInspectionForm",
    },
    {
      label: "Compliance Form",
      value: FormTypes.CommercialEvaluationComplianceForm,
      fullFormName: "CommercialEvaluationComplianceForm",
    },
    {
      label: "Compliance Letter Form",
      value: FormTypes.CommercialEvaluationComplianceLetter,
      fullFormName: "CommercialEvaluationComplianceLetter",
    },
    {
      label: "Redesignation Form",
      value: FormTypes.CommercialRedesignationRedesignationForm,
      fullFormName: "CommercialRedesignationForm",
    },
    {
      label: "Redesignation Evaluator Email Form",
      value: FormTypes.CommercialRedesignationEvaluatorEmailForm,
      fullFormName: "CommercialRedesignationEvaluatorEmailForm",
    },
    {
      label: "Redesignation Compliance Letter Form",
      value: FormTypes.CommercialRedesignationComplianceLetterForm,
      fullFormName: "CommercialRedesignationComplianceLetterForm",
    },
    {
      label: "Redesignation Field Inspection Form",
      value: FormTypes.CommercialRedesignationFieldInspectionForm,
      fullFormName: "CommercialRedesignationFieldInspectionForm",
    },
  ],
  // Multifamily Evaluation Forms
  2: [
    {
      label: "Application Form",
      value: FormTypes.MultifamilyEvaluationApplicationForm,
      fullFormName: "MultifamilyEvaluationApplicationForm",
    },
    {
      label: "Company Assignment Form",
      value: FormTypes.MultifamilyEvaluationCompanyAssignmentForm,
      fullFormName: "MultifamilyEvaluationCompanyAssignmentForm",
    },
    {
      label: "Field Inspection Form",
      value: FormTypes.MultifamilyEvaluationFieldInspectionForm,
      fullFormName: "MultifamilyEvaluationFieldInspectionForm",
    },
    {
      label: "Compliance Form",
      value: FormTypes.MultifamilyEvaluationComplianceForm,
      fullFormName: "MultifamilyEvaluationComplianceForm",
    },
    {
      label: "Compliance Letter Form",
      value: FormTypes.MultifamilyEvaluationComplianceLetterForm,
      fullFormName: "MultifamilyEvaluationComplianceLetterForm",
    },
    {
      label: "Redesignation Form",
      value: FormTypes.MultifamilyRedesignationRedesignationForm,
      fullFormName: "MultifamilyRedesignationForm",
    },
    {
      label: "Redesignation Evaluator Email Form",
      value: FormTypes.MultifamilyRedesignationEvaluatorEmailForm,
      fullFormName: "MultifamilyRedesignationEvaluatorEmailForm",
    },
    {
      label: "Redesignation Compliance Letter Form",
      value: FormTypes.MultifamilyRedesignationComplianceLetterForm,
      fullFormName: "MultifamilyRedesignationComplianceLetterForm",
    },
    {
      label: "Redesignation Field Inspection Form",
      value: FormTypes.MultifamilyRedesignationFieldInspectionForm,
      fullFormName: "MultifamilyRedesignationFieldInspectionForm",
    },
  ],
  // Home Evaluation Forms
  3: [
    {
      label: "Standard Basic Information Form",
      value: FormTypes.HomeStandardEvaluationBasicInformationForm,
      fullFormName: "HomeStandardEvaluationBasicInformationForm",
    },
    {
      label: "Standard Eligibility Form",
      value: FormTypes.HomeStandardEvaluationEligibilityForm,
      fullFormName: "HomeStandardEvaluationEligibilityForm",
    },
    {
      label: "Standard Redesignation Form",
      value: FormTypes.HomeStandardRedesignationForm,
      fullFormName: "HomeStandardRedesignationForm",
    },
    {
      label: "Standard Roofing Compliance General Information Form",
      value:
        FormTypes.HomeStandardEvaluationRoofingComplianceGeneralInformationForm,
      fullFormName:
        "HomeStandardEvaluationRoofingComplianceGeneralInformationForm",
    },
    {
      label: "Standard Roofing Compliance Roof Cover Form",
      value: FormTypes.HomeStandardEvaluationRoofingComplianceRoofCoverForm,
      fullFormName: "HomeStandardEvaluationRoofingComplianceRoofCoverForm",
    },
    {
      label: "Standard Roofer Confirmation Form",
      value: FormTypes.HomeStandardEvaluationRooferConfirmationForm,
      fullFormName: "HomeStandardEvaluationRooferConfirmationForm",
    },
    {
      label: "Standard Homeowner Authorization Form",
      value: FormTypes.HomeStandardRedesignationHomeownerAuthorizationForm,
      fullFormName: "HomeStandardRedesignationHomeownerAuthorizationForm",
    },
    {
      label: "Standard Engineer Confirmation Form",
      value: FormTypes.HomeStandardEvaluationEngineerConfirmationForm,
      fullFormName: "HomeStandardEvaluationEngineerConfirmationForm",
    },
    {
      label: "Standard Silver Gold Compliance General Information Form",
      value:
        FormTypes.HomeStandardEvaluationSilverGoldComplianceGeneralInformationForm,
      fullFormName:
        "HomeStandardEvaluationSilverGoldComplianceGeneralInformationForm",
    },
    {
      label: "Standard Installing Contractor Confirmation Form",
      value:
        FormTypes.HomeStandardEvaluationInstallingContractorConfirmationForm,
      fullFormName:
        "HomeStandardEvaluationInstallingContractorConfirmationForm",
    },
    {
      label: "Standard Installer Compliance Gables Form",
      value: FormTypes.HomeStandardEvaluationInstallerComplianceGablesForm,
      fullFormName: "HomeStandardEvaluationInstallerComplianceGablesForm",
    },
    {
      label: "Standard Installer Compliance Continuous Load Path Form",
      value:
        FormTypes.HomeStandardEvaluationInstallerComplianceContinuousLoadPathForm,
      fullFormName:
        "HomeStandardEvaluationInstallerComplianceContinuousLoadPathForm",
    },
    {
      label: "Standard Installer Compliance Chimney Form",
      value: FormTypes.HomeStandardEvaluationInstallerComplianceChimneyForm,
      fullFormName: "HomeStandardEvaluationInstallerComplianceChimneyForm",
    },
    {
      label: "Standard Installer Compliance Attached Structure Form",
      value:
        FormTypes.HomeStandardEvaluationInstallerComplianceAttachedStructureForm,
      fullFormName:
        "HomeStandardEvaluationInstallerComplianceAttachedStructureForm",
    },
    {
      label: "Standard Engineering Compliance General Information Form",
      value:
        FormTypes.HomeStandardEvaluationEngineeringComplianceGeneralInformationForm,
      fullFormName:
        "HomeStandardEvaluationEngineeringComplianceGeneralInformationForm",
    },
    {
      label: "Standard Engineering Compliance Gables Form",
      value: FormTypes.HomeStandardEvaluationEngineeringComplianceGablesForm,
      fullFormName: "HomeStandardEvaluationEngineeringComplianceGablesForm",
    },
    {
      label: "Standard Engineering Compliance Continuous Load Path Form",
      value:
        FormTypes.HomeStandardEvaluationEngineeringComplianceContinuousLoadPathForm,
      fullFormName:
        "HomeStandardEvaluationEngineeringComplianceContinuousLoadPathForm",
    },
    {
      label: "Standard Engineering Compliance Chimney Form",
      value: FormTypes.HomeStandardEvaluationEngineeringComplianceChimneyForm,
      fullFormName: "HomeStandardEvaluationEngineeringComplianceChimneyForm",
    },
    {
      label: "Standard Engineering Compliance Attached Structure Form",
      value:
        FormTypes.HomeStandardEvaluationEngineeringComplianceAttachedStructureForm,
      fullFormName:
        "HomeStandardEvaluationEngineeringComplianceAttachedStructureForm",
    },
    {
      label: "NCIUA Evaluation Form",
      value: FormTypes.HomeNCIUAEvaluationForm,
      fullFormName: "HomeNCIUAEvaluationForm",
    },
    {
      label: "NCIUA Redesignation Form",
      value: FormTypes.HomeNCIUARedesignationRedesignationForm,
      fullFormName: "HomeNCIUARedesignationForm",
    },
    {
      label: "NCIUA Eligibility Form",
      value: FormTypes.HomeNCIUAEvaluationEligibilityForm,
      fullFormName: "HomeNCIUAEvaluationEligibilityForm",
    },
    {
      label: "NCIUA Homeowner Authorization Form",
      value: FormTypes.HomeNCIUARedesignationHomeownerAuthorizationForm,
      fullFormName: "HomeNCIUARedesignationHomeownerAuthorizationForm",
    },
    {
      label: "NCIUA Roofing Compliance Roof Cover Form",
      value: FormTypes.HomeNCIUAEvaluationRoofingComplianceRoofCoverForm,
      fullFormName: "HomeNCIUAEvaluationRoofingComplianceRoofCoverForm",
    },
    {
      label: "NCIUA Roofing Compliance General Information Form",
      value:
        FormTypes.HomeNCIUAEvaluationRoofingComplianceGeneralInformationForm,
      fullFormName:
        "HomeNCIUAEvaluationRoofingComplianceGeneralInformationForm",
    },
    {
      label: "NCIUA Roofer Confirmation Form",
      value: FormTypes.HomeNCIUAEvaluationRooferConfirmationForm,
      fullFormName: "HomeNCIUAEvaluationRooferConfirmationForm",
    },
    {
      label: "SCSH Evaluation Form",
      value: FormTypes.HomeSCSHEvaluationForm,
      fullFormName: "HomeSCSHEvaluationForm",
    },
    {
      label: "SCSH Basic Information Form",
      value: FormTypes.HomeSCSHEvaluationBasicInformationForm,
      fullFormName: "HomeSCSHEvaluationBasicInformationForm",
    },
  ],
  // Wildfire Evaluation Forms
  4: [
    {
      label: "Application Form",
      value: FormTypes.WildfireApplicationForm,
      fullFormName: "WildfireApplicationForm",
    },
    {
      label: "Roof Field Evaluation Form",
      value: FormTypes.WildfireRoofFieldEvaluationForm,
      fullFormName: "WildfireRoofFieldEvaluationForm",
    },
    {
      label: "Home Exterior Field Evaluation Form",
      value: FormTypes.WildfireHomeExteriorFieldEvaluationForm,
      fullFormName: "WildfireHomeExteriorFieldEvaluationForm",
    },
    {
      label: "Landscape Field Evaluation Form",
      value: FormTypes.WildfireLandscapeFieldEvaluationForm,
      fullFormName: "WildfireLandscapeFieldEvaluationForm",
    },
    {
      label: "Other Field Evaluation Form",
      value: FormTypes.WildfireOtherFieldEvaluationForm,
      fullFormName: "WildfireOtherFieldEvaluationForm",
    },
  ],
};

export const getFormTypeFromField = (field: string): FormTypes | undefined => {
  // field is in the format of Evaluation__<SystemName/ProductType?>__<FormType>__<FieldName>
  const parts = field.split("__");
  if (parts.length < 3) {
    return undefined;
  }

  const productName = parts[1]; // can be HomeStandardEvaluation, HomeNCIUAEvaluation, HomeSCSHEvaluation, or WildfireApplication, etc.
  const exactForm = parts[2]; // form name (RoofingComplianceRoofCoverForm, RoofingComplianceGeneralInformationForm, etc.)
  let formTypeKey = ""; // should match a key in FormTypes enum

  switch (productName) {
    case "WildfireApplication":
      formTypeKey = "Wildfire" + exactForm;
      break;
    case "HomeStandardEvaluation":
      formTypeKey = `${productName}${exactForm}`;
      break;
    case "HomeSCSHEvaluation":
      formTypeKey = `${productName}${exactForm}`;
      break;
    case "HomeNCIUAEvaluation":
      formTypeKey = `${productName}${exactForm}`;
      break;
    case "HomeNCIUARedesignation":
      formTypeKey = `${productName}${exactForm}`;
      break;
    case "CommercialEvaluation":
      formTypeKey = `${exactForm}`;
      break;
    case "CommercialRedesignation":
      formTypeKey = `${productName}${exactForm}`;
      break;
    case "MultifamilyEvaluation":
      formTypeKey = `${exactForm}`;
      break;
    case "MultifamilyRedesignation":
      formTypeKey = `${productName}${exactForm}`;
      break;
    default:
      break;
  }

  return FormTypes[formTypeKey as keyof typeof FormTypes];
};

export const getSchemaFieldKeyFromIterationKey = (
  iterationKey: string
): string | undefined => {
  const parts = iterationKey.split("__");
  if (parts.length < 4) {
    return undefined;
  }

  let formName = "";
  const fieldName = parts[parts.length - 1];

  // Special handling for Wildfire forms
  if (parts[1] === "WildfireApplication") {
    const formType = getFormTypeFromField(iterationKey);
    if (formType) {
      // Check for any Wildfire form type
      const sectionId = WildfireFormSections.getFieldSection(iterationKey);
      if (sectionId) {
        return WildfireFormSections.createSectionKey(formType, sectionId);
      }
    }
    // Default fallback for Wildfire forms if no section is found
    formName = "WildfireApplicationForm";
  } else if (
    parts[1] === "CommercialEvaluation" ||
    parts[1] === "MultifamilyEvaluation"
  ) {
    // Commercial/Multifamily: Evaluation__[Type]Evaluation__[Type]EvaluationApplicationForm__ApplicantFirstName
    // to: [Type]EvaluationApplicationForm__ApplicantFirstName
    formName = parts[2];
  } else {
    formName = parts[1] + parts[2];
  }

  return `${formName}__${fieldName}`;
};

// Helper object to manage form sections
export const WildfireFormSections = {
  createSectionKey: (formType: FormTypes, sectionId: string): string => {
    return `${FormTypes[formType]}__Section__${sectionId}`;
  },

  // NOTE: Sections data automated generation by Claude.ai
  Sections: {
    ApplicationForm: {
      Application: {
        id: "Application",
        displayName: "Application",
        fields: [
          "HowDidYouHearAboutUs",
          "HowDidYouHearAboutUsOther",
          "OwnershipAttestation",
          "HasCoOwner",
          "CoOwnerFirstName",
          "CoOwnerLastName",
          "CoOwnerEmail",
          "CoOwnerPhoneNumber",
          "HasSecondaryContact",
          "SecondaryContactFirstName",
          "SecondaryContactLastName",
          "SecondaryContactEmail",
          "SecondaryContactPhoneNumber",
          "RoofInstallationYearKnown",
          "RoofInstallationYear",
          "HomeConstructionYearKnown",
          "HomeConstructionYear",
          "StreetAddress",
          "HasHotTub",
          "HasStationaryPropaneTank",
          "HasGate",
          "GateCode",
          "HasPets",
          "PetRestraintAttestation",
          "NeedsAppointment",
          "AppointmentPhoneNumber",
          "OtherPropertyNotes",
          "ServiceType",
          "SelfServiceWorkAttestation",
          "SelfServicePhotoAttestation",
          "SelfServiceNonRefundabilityAttestation",
          "FrontPhotoFolder",
          "RightSidePhotoFolder",
          "BackPhotoFolder",
          "LeftSidePhotoFolder",
        ],
      },
    },
    RoofFieldEvaluation: {
      CoverMaterial: {
        id: "CoverMaterial",
        displayName: "Cover Material",
        fields: [
          "RoofCoverMaterialPhotoFolder",
          "RoofCoverMaterial",
          "DominantRoofCoverPercentage",
          "SecondaryRoofCoverPercentage",
          "SecondaryRoofCoverMaterial",
        ],
      },
      OtherRoofCoverConditions: {
        id: "OtherRoofCoverConditions",
        displayName: "Other Roof Cover Conditions",
        fields: ["IsRoofClearOfDebris", "AreSkylightsFlatGlass"],
      },
      GuttersAndDownspouts: {
        id: "GuttersAndDownspouts",
        displayName: "Gutters and Downspouts",
        fields: [
          "GuttersAndDownspoutsPhotoFolder",
          "AreGuttersAndDownspoutsMetal",
          "AreGuttersAndDownspoutsClearOfDebris",
          "AreGuttersCoveredWithMetalGuard",
        ],
      },
      EavesAndSoffits: {
        id: "EavesAndSoffits",
        displayName: "Eaves and Soffits",
        fields: [
          "EavesAndSoffitsPhotoFolder",
          "AreEavesEnclosedWithNoncombustibleMaterial",
        ],
      },
      Vents: {
        id: "Vents",
        displayName: "Vents",
        fields: ["VentsPhotoFolder", "AreRoofVentsFireResistant", "OtherNotes"],
      },
    },
    HomeExteriorFieldEvaluation: {
      Vents: {
        id: "Vents",
        displayName: "Vents",
        fields: [
          "VentsPhotoFolder",
          "AreGableEndVentsFireResistant",
          "AreVentsUnderOpenFramedEaveCoveredWithMetalMesh",
          "AreCrawlSpaceVentsCoveredWithMetalMesh",
          "DoesDryerVentHaveLouverOrFlap",
          "IsDryerVentMetalWithLouverOrFlap",
        ],
      },
      SixInchVertical: {
        id: "SixInchVertical",
        displayName: '6" Vertical',
        fields: [
          "SixInchVerticalPhotoFolder",
          "DoAllExteriorWallsHaveSixInchNoncombustibleVertical",
        ],
      },
      SidingMaterial: {
        id: "SidingMaterial",
        displayName: "Siding Material",
        fields: [
          "SidingMaterialPhotoFolder",
          "DominantWallCoverPercentage",
          "DominantWallCoverMaterial",
          "SecondaryWallCoverPercentage",
          "SecondaryWallCoverMaterial",
          "AreDecorativeShuttersNoncombustible",
          "AreFunctionalShuttersPresentOnAllWindows",
          "IsSpaceUnderBayWindowsEnclosed",
        ],
      },
      Windows: {
        id: "Windows",
        displayName: "Windows",
        fields: ["WindowsPhotoFolder", "AreAllWindowsCompliant"],
      },
      Doors: {
        id: "Doors",
        displayName: "Doors",
        fields: ["DoorsPhotoFolder", "AreAllExteriorDoorsCompliant"],
      },
      Decks: {
        id: "Decks",
        displayName: "Decks",
        fields: [
          "DecksPhotoFolder",
          "DoesAreaAroundDecksHaveNoncombustibleSpace",
          "IsTopSurfaceOfDecksClearOfYardDebris",
          "IsTopSurfaceOfDecksFreeFromTreesAndShrubs",
          "DoesTopSurfaceOfDecksHaveNoncombustibleItems",
          "IsUnderneathDecksFreeFromVegetation",
          "AreDecksClearOfStoredItems",
          "AreDecksFullyEnclosedUnderneath",
          "IsAttachedDeckStructureMetal",
          "DoesDetachedDeckMeetAttachedDeckRequirements",
          "AreDecksConstructedWithNoncombustibleMaterials",
        ],
      },
    },
    LandscapeFieldEvaluation: {
      ZeroToFiveFeet: {
        id: "ZeroToFiveFeet",
        displayName: "0-5 feet",
        fields: [
          "ZeroToFiveFeetPhotosFolder",
          "IsGroundCoverIntheHIZNoncombustible",
          "IsHIZFreeFromVegetation",
          "IsHIZFreeFromCombustibleItems",
          "IsHIZFreeOfVehicles",
          "IsFencingNearHomeNonCombustible",
        ],
      },
      FiveToThirtyFeet: {
        id: "FiveToThirtyFeet",
        displayName: "5-30 feet",
        fields: [
          "FiveToThirtyFeetPhotosFolder",
          "IsYardFreeOfDebris",
          "TreesSpacedApartAndPruned",
          "SmallVegetationSpacingCompliance",
          "HasAllDeadVegetationRemoved",
          "IsGapBetweenBackToBackFencesSufficient",
        ],
      },
    },
    OtherFieldEvaluation: {
      OtherStructuresAndOutbuildings: {
        id: "OtherStructuresAndOutbuildings",
        displayName: "Other Structures/Outbuildings",
        fields: [
          "StructuresAndOutbuildingsPhotosFolder",
          "MiscPhotosFolder",
          "AccessoryStructuresDistanceOrAbsent",
          "MaxThreeLargeAccessoriesNearHome",
          "AccessoryStructure1ComplianceWithHome",
          "AccessoryStructure2ComplianceWithHome",
          "AccessoryStructure3ComplianceWithHome",
          "HotTubPlacementCompliance",
          "PropaneTankPlacementAndSafety",
        ],
      },
    },
  },

  getFormFields: (formType: FormTypes) => {
    switch (formType) {
      case FormTypes.WildfireApplicationForm:
        return Object.values(WildfireFormSections.Sections.ApplicationForm).map(
          (section) => ({
            value: WildfireFormSections.createSectionKey(formType, section.id),
            label: section.displayName,
          })
        );

      case FormTypes.WildfireRoofFieldEvaluationForm:
        return Object.values(
          WildfireFormSections.Sections.RoofFieldEvaluation
        ).map((section) => ({
          value: WildfireFormSections.createSectionKey(formType, section.id),
          label: section.displayName,
        }));

      case FormTypes.WildfireHomeExteriorFieldEvaluationForm:
        return Object.values(
          WildfireFormSections.Sections.HomeExteriorFieldEvaluation
        ).map((section) => ({
          value: WildfireFormSections.createSectionKey(formType, section.id),
          label: section.displayName,
        }));

      case FormTypes.WildfireLandscapeFieldEvaluationForm:
        return Object.values(
          WildfireFormSections.Sections.LandscapeFieldEvaluation
        ).map((section) => ({
          value: WildfireFormSections.createSectionKey(formType, section.id),
          label: section.displayName,
        }));

      case FormTypes.WildfireOtherFieldEvaluationForm:
        return Object.values(
          WildfireFormSections.Sections.OtherFieldEvaluation
        ).map((section) => ({
          value: WildfireFormSections.createSectionKey(formType, section.id),
          label: section.displayName,
        }));

      default:
        return [];
    }
  },

  getFieldSection(fieldKey: string): string | undefined {
    const parts = fieldKey.split("__");
    const fieldName = parts[parts.length - 1];

    // Search through all sections to find matching field
    for (const sections of Object.values(this.Sections)) {
      for (const section of Object.values(sections)) {
        if (section.fields.includes(fieldName)) {
          return section.id;
        }
      }
    }

    // We return undefined if we can't find a matching section
    // This helps calling code know that this field doesn't belong to any section
    return undefined;
  },
};

export const getHomeStandardFormTypeFromLabel = (
  formName: string
): FormTypes => {
  const formTypeKey = `HomeStandardEvaluation${formName}`;

  return FormTypes[formTypeKey as keyof typeof FormTypes];
};
