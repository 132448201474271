import { PrimaryCard } from "@ucl/library";
import { EvaluationStep } from "../../../../../../foritfied/types/evaluation/Evaluation";
import { useContext, useEffect, useState } from "react";
import { UnsavedChangesBlockerContext } from "../../../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import classNames from "classnames";
import { CommercialRedesignationForm } from "../ApplicationForm/Form/CommercialRedesignationForm";
import CommercialRedesignationEvaluatorEmailForm from "../RedesignationEvaluatorEmailForm/Form/CommercialRedesignationEvaluatorEmailForm";
import CommercialRedesignationFieldInspectionForm from "../EvaluationFieldInspectionForm/Form/CommercialEvaluationFieldInspectionForm";
import CommercialRedesignationComplianceLetterForm from "../ComplianceLetter/Form/CommercialRedesignationComplianceLetterForm";

interface IterationEngineFormsProps {
  evaluationId: string;
  evaluationStep: EvaluationStep;
  onFormSave: (fieldKey: string) => Promise<void>;
  onAllFormsInitialized: () => void;
  fieldsContainerClassName: string;
  selectedTabId: string;
}

const CommercialRedesignationIterationEngineForms: React.FC<
  IterationEngineFormsProps
> = (props) => {
  const [redesignationFormInitiaized, setRedesignationFormInitiaized] =
    useState<boolean>();

  const [
    redesignationEvaluatorEmailFormInitiaized,
    setRedesignationEvaluatorEmailFormInitiaized,
  ] = useState<boolean>();

  const [
    redesignationFieldInspectionFormInitiaized,
    setRedesignationFieldInspectionFormInitiaized,
  ] = useState<boolean>();

  const [
    redesignationComplianceLetterFormInitiaized,
    setRedesignationComplianceLetterFormInitiaized,
  ] = useState<boolean>();

  const [allFormsInitialized, setAllFormsInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const formsInitialized =
      redesignationFormInitiaized ||
      redesignationEvaluatorEmailFormInitiaized ||
      redesignationFieldInspectionFormInitiaized ||
      redesignationComplianceLetterFormInitiaized;

    if (formsInitialized) {
      setAllFormsInitialized(true);
    } else {
      setAllFormsInitialized(false);
    }
  }, [
    redesignationFormInitiaized,
    redesignationEvaluatorEmailFormInitiaized,
    redesignationFieldInspectionFormInitiaized,
    redesignationComplianceLetterFormInitiaized,
  ]);

  useEffect(() => {
    if (allFormsInitialized) {
      setTimeout(() => {
        props.onAllFormsInitialized();
      }, 800);
      setIsLoading(false);
      setAllFormsInitialized(false);
      setRedesignationFormInitiaized(false);
      setRedesignationEvaluatorEmailFormInitiaized(false);
      setRedesignationFieldInspectionFormInitiaized(false);
      setRedesignationComplianceLetterFormInitiaized(false);
    }
  }, [allFormsInitialized]);

  const { setHasUnsavedChanges } = useContext(UnsavedChangesBlockerContext);

  const getFormAndHeaderContent = () => {
    switch (props.selectedTabId) {
      case "CommercialRedesignationEvaluatorEmailForm":
        return {
          headerContent: "Commercial Redesignation Evaluator Email Form",
          form: (
            <CommercialRedesignationEvaluatorEmailForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setRedesignationEvaluatorEmailFormInitiaized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      case "CommercialRedesignationFieldInspectionForm":
        return {
          headerContent: "Commercial Redesignation Field Inspection Form",
          form: (
            <CommercialRedesignationFieldInspectionForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setRedesignationFieldInspectionFormInitiaized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      case "CommercialRedesignationComplianceLetterForm":
        return {
          headerContent: "Commercial Redesignation Compliance Letter Form",
          form: (
            <CommercialRedesignationComplianceLetterForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setRedesignationComplianceLetterFormInitiaized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      default:
        return {
          headerContent: "Commercial Redesignation Form",
          form: (
            <CommercialRedesignationForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setRedesignationFormInitiaized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
    }
  };

  const { headerContent, form } = getFormAndHeaderContent();

  return (
    <>
      {isLoading && (
        <>
          <Loading />
        </>
      )}
      <div
        className={classNames(props.fieldsContainerClassName, {
          "evaluation-forms-container-initialized": !isLoading,
        })}
      >
        <PrimaryCard headerContent={headerContent}>{form}</PrimaryCard>
      </div>
    </>
  );
};

export default CommercialRedesignationIterationEngineForms;
