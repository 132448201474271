import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import baseExternalApiClient from "../../../../../foritfied/lib/apiClients/baseExternalApiClient";
import { BaseHomeEvaluationEngineeringComplianceAttachedStructureFormParts } from "../../../common/types/BaseHomeEvaluationEngineeringComplianceAttachedStructureFormModel";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../types/HomeEvaluationFormModel";
import { HomeStandardEvaluationEngineeringComplianceAttachedStructureFormModel } from "../../types/HomeStandardEvaluationEngineeringComplianceAttachedStructureFormModel";

class HomeStandardEvaluationEngineeringComplianceAttachedStructureAPIClient {
  updateHomeStandardEngineeringComplianceAttachedStructureForm = async (
    evaluationId: string,
    engineeringComplianceAttachedStructureForm: HomeStandardEvaluationEngineeringComplianceAttachedStructureFormModel,
    fieldKey?: string
  ): Promise<Evaluation_HomeStandardEvaluationFormModel> => {
    return baseApiClient.put<Evaluation_HomeStandardEvaluationFormModel>(
      `/home-standard/engineering-compliance-attached-structure-form/${evaluationId}`,
      {
        engineeringComplianceAttachedStructureForm,
        fieldKey,
      }
    );
  };

  updateHomeStandardEngineeringComplianceAttachedStructureFormExternal = async (
    evaluationId: string,
    engineeringComplianceAttachedStructureForm: HomeStandardEvaluationEngineeringComplianceAttachedStructureFormModel,
    accessToken: string
  ): Promise<Evaluation_HomeStandardEvaluationFormModel> => {
    return baseExternalApiClient.put<Evaluation_HomeStandardEvaluationFormModel>(
      `/home-standard/engineering-compliance-attached-structure-form/external/${evaluationId}`,
      {
        engineeringComplianceAttachedStructureForm,
      },
      {
        headers: {
          AccessToken: accessToken,
        },
      }
    );
  };

  submitHomeStandardEngineeringComplianceAttachedStructureForm = async (
    evaluationId: string,
    engineeringComplianceAttachedStructureForm: HomeStandardEvaluationEngineeringComplianceAttachedStructureFormModel,
    formPart?: BaseHomeEvaluationEngineeringComplianceAttachedStructureFormParts
  ): Promise<Evaluation_HomeStandardEvaluationFormModel> => {
    // If applicationFormPart is not provided, default to the last part of the form
    const formPartValue = (
      formPart ??
      (Object.values(
        BaseHomeEvaluationEngineeringComplianceAttachedStructureFormParts
      ).pop() as BaseHomeEvaluationEngineeringComplianceAttachedStructureFormParts)
    ).valueOf();
    return baseApiClient.post<Evaluation_HomeStandardEvaluationFormModel>(
      `/home-standard/engineering-compliance-attached-structure-form/${evaluationId}/${formPartValue}`,
      engineeringComplianceAttachedStructureForm
    );
  };

  submitHomeStandardEngineeringComplianceAttachedStructureFormExternal = async (
    evaluationId: string,
    engineeringComplianceAttachedStructureForm: HomeStandardEvaluationEngineeringComplianceAttachedStructureFormModel,
    accessToken: string
  ): Promise<Evaluation_HomeStandardEvaluationFormModel> => {
    // Always default to the last part of the form
    const formPartValue = (
      Object.values(
        BaseHomeEvaluationEngineeringComplianceAttachedStructureFormParts
      ).pop() as BaseHomeEvaluationEngineeringComplianceAttachedStructureFormParts
    ).valueOf();
    return baseExternalApiClient.post<Evaluation_HomeStandardEvaluationFormModel>(
      `/home-standard/engineering-compliance-attached-structure-form/external/${evaluationId}/${formPartValue}`,
      engineeringComplianceAttachedStructureForm,
      {
        headers: {
          AccessToken: accessToken,
        },
      }
    );
  };
}

export const homeStandardEvaluationEngineeringComplianceAttachedStructureAPIClient =
  new HomeStandardEvaluationEngineeringComplianceAttachedStructureAPIClient();
