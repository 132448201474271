import { AccountInfo } from "@azure/msal-browser";
import { makeObservable, observable } from "mobx";
import { appInsights } from "../../AppInsights";
import { UserReadDTO } from "../../foritfied/lib/apiClients/user/types";
import { wildfireUserApiClient } from "../lib/user/wildfireUserApiClient";
import { User, UserRoleType } from "../../common/Components/User/userTypes";

class WildfireUserStore {
  public user?: User;

  constructor() {
    makeObservable(this, {
      user: observable,
    });
  }

  async initAccount(account: AccountInfo | null) {
    if (account != null) {
      const userFromSystem: UserReadDTO = await this.signIn();
      this.user = {
        id: userFromSystem.id,
        email: userFromSystem.email,
        firstName: userFromSystem.firstName,
        lastName: userFromSystem.lastName,
        role: userFromSystem.role_AsString as UserRoleType,
        canClaimEvaluations: userFromSystem.canClaimEvaluations,
        canUpdateEvaluatorFromAdmin: userFromSystem.canUpdateEvaluatorFromAdmin,
      };

      appInsights.setAuthenticatedUserContext(
        this.user?.id,
        account?.homeAccountId,
        true
      );
    }
  }

  async signIn() {
    return wildfireUserApiClient.signIn();
  }

  get displayRole() {
    return !!this.user ? this.user.role : "";
  }

  get fullUserName() {
    return !!this.user ? `${this.user.firstName} ${this.user.lastName}` : "";
  }

  get isWildfireUser() {
    return (
      this.isWildfireApplicant ||
      this.isWildfireAdmin ||
      this.isWildfireEvaluator ||
      this.isWildfireAuditor
    );
  }

  get isWildfireApplicant() {
    return this.user?.role === "WFP Applicant";
  }

  get isWildfireAdmin() {
    return (
      this.user?.role === "WFP Admin" ||
      this.user?.role === "IBHS Admin" ||
      this.user?.role === "Relativity Admin"
    );
  }

  get isWildfireEvaluator() {
    return this.user?.role === "WFP Evaluator";
  }

  get isWildfireAuditor() {
    return this.user?.role === "WFP Auditor";
  }
}

export const wildfireUserStore = new WildfireUserStore();
