import ErrorWrapper from "../../../common/Components/Error/ErrorWrapper";
import { PagesLayout } from "./PagesLayout";
import { createBrowserRouter } from "react-router-dom";
import { utils, WithParams } from "@ucl/library";
import { Board } from "../Board";
import { Evaluation } from "../Evaluation";
import { Checklist } from "../Checklist";
import { ReportsPage } from "../ReportsPage";
import { AdminPage } from "../AdminPage";
import { HelpPage } from "../HelpPage";
import { EvaluationBidPage } from "../EvaluationBidPage";
import { ExternalEvaluationSummaryPage } from "../ExternalEvaluationSummaryPage";
import { ReportViewerPage } from "../reports/ReportViewerPage";
import { fortifiedCommercialMultifamilyLogout } from "../../../common/lib/msal/fortifiedCommercialMultifamilyMsal";
import {
  faChartSimple,
  faList,
  faSliders,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { CommercialApplicationFormPage } from "../../../fortifiedV2/FCFMF/commercial/pages/CommercialApplicationFormPage";
import { FCFMFPagesLayout } from "../../../fortifiedV2/FCFMF/common/pages/index/FCFMFPagesLayout";
import { CommercialIterationEnginePage } from "../../../fortifiedV2/FCFMF/commercial/pages/CommercialIterationEnginePage";
import { FCFMFApplicationFormParts } from "../../../fortifiedV2/FCFMF/common/types/Evaluation/BaseFCFMFApplicationForm";
import { CommercialEvaluationCompanyAssignmentFormPage } from "../../../fortifiedV2/FCFMF/commercial/pages/CommercialEvaluationCompanyAssignmentFormPage";
import { MultifamilyApplicationFormPage } from "../../../fortifiedV2/FCFMF/multifamily/pages/MultifamilyApplicationFormPage";
import { MultifamilyIterationEnginePage } from "../../../fortifiedV2/FCFMF/multifamily/pages/MultifamilyIterationEnginePage";
import { MultifamilyEvaluationCompanyAssignmentFormPage } from "../../../fortifiedV2/FCFMF/multifamily/pages/MultifamilyEvaluationCompanyAssignmentFormPage";
import { CommercialEvaluationFieldInspectionFormPage } from "../../../fortifiedV2/FCFMF/commercial/pages/CommercialEvaluationFieldInspectionFormPage";
import { MultifamilyEvaluationFieldInspectionFormPage } from "../../../fortifiedV2/FCFMF/multifamily/pages/MultifamilyEvaluationFieldInspectionFormPage";
import { CommercialRedesignationFormPage } from "../../../fortifiedV2/FCFMF/commercialRedesignation/pages/CommercialRedesignationFormPage";
import { FCFMFRedesignationFormParts } from "../../../fortifiedV2/FCFMF/common/types/Redesignation/BaseFCFMFRedesignationFormModel";
import { CommercialRedesignationIterationEnginePage } from "../../../fortifiedV2/FCFMF/commercialRedesignation/pages/CommercialRedesignationIterationEnginePage";
import { MultifamilyRedesignationFormPage } from "../../../fortifiedV2/FCFMF/multifamilyRedesignation/pages/MultifamilyRedesignationFormPage";
import { MultifamilyRedesignationIterationEnginePage } from "../../../fortifiedV2/FCFMF/multifamilyRedesignation/pages/MultifamilyRedesignationIterationEnginePage";
import { PaymentRedirectPage } from "../../../common/pages/PaymentRedirectPage";
import { multifamilyEvaluationAPIClient } from "../../../fortifiedV2/FCFMF/multifamily/lib/apiClients/multifamilyEvaluationAPIClient";
import { getFortifiedCheckoutUrlMethod } from "../../../common/utils/paymentRouteUtils";
import { commercialRedesignationAPIClient } from "../../../fortifiedV2/FCFMF/commercialRedesignation/lib/apiClients/commercialRedesignationAPIClient";
import { multifamilyRedesignationAPIClient } from "../../../fortifiedV2/FCFMF/multifamilyRedesignation/lib/apiClients/multifamilyRedesignationAPIClient";
import { uclRouteObject as uclRouteObject_fortifiedHomeRouteConfig } from "./fortifiedHomeRouteConfig";
import { permissionStore } from "../../stores/PermissionStore";

const errorWrapper = (
  <div className="fortified-page">
    <ErrorWrapper logOutUser={fortifiedCommercialMultifamilyLogout} />
  </div>
);

export const fortifiedCommercialMultifamilyRoutePrefix = "/fc-fmf";

export const commercialRoutePrefix = `${fortifiedCommercialMultifamilyRoutePrefix}/commercial`;
export const commercialRedesignationRoutePrefix = `${fortifiedCommercialMultifamilyRoutePrefix}/commercial-redesignation`;

export const multifamilyRoutePrefix = `${fortifiedCommercialMultifamilyRoutePrefix}/multifamily`;
export const multifamilyRedesignationRoutePrefix = `${fortifiedCommercialMultifamilyRoutePrefix}/multifamily-redesignation`;

export const uclRouteObject = (): utils.routes.UclRouteObject[] => {
  return [
    {
      path: `${fortifiedCommercialMultifamilyRoutePrefix}`,
      label: "Board",
      icon: faList,
      isAvailable: true,
      showInNavigation: true,
      showInSubNavigation: true,
      errorElement: errorWrapper,
      element: (
        <PagesLayout
          redirectPath={`${fortifiedCommercialMultifamilyRoutePrefix}`}
          errorWrapper={errorWrapper}
        />
      ),
      children: [
        {
          path: "",
          label: "",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: true,
          errorElement: errorWrapper,
          element: <Board />,
        },
      ],
    },
    {
      path: `${fortifiedCommercialMultifamilyRoutePrefix}/evaluation/:id`,
      label: "Evaluation Page",
      icon: undefined,
      isAvailable: true,
      showInNavigation: false,
      showInSubNavigation: false,
      errorElement: errorWrapper,
      element: (
        <PagesLayout
          redirectPath={`${fortifiedCommercialMultifamilyRoutePrefix}`}
          errorWrapper={errorWrapper}
        />
      ),
      children: [
        {
          path: "",
          label: "Evaluation Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: <Evaluation />,
        },
      ],
    },
    {
      path: `${fortifiedCommercialMultifamilyRoutePrefix}/checklist`,
      label: "Checklist Page",
      icon: undefined,
      isAvailable: true,
      showInNavigation: false,
      showInSubNavigation: false,
      errorElement: errorWrapper,
      element: (
        <PagesLayout
          redirectPath={`${fortifiedCommercialMultifamilyRoutePrefix}`}
          errorWrapper={errorWrapper}
        />
      ),
      children: [
        {
          path: "",
          label: "Checklist Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: <Checklist />,
        },
      ],
    },
    {
      path: `${fortifiedCommercialMultifamilyRoutePrefix}/reports`,
      label: "Reports",
      icon: faChartSimple,
      isAvailable: true,
      showInNavigation: permissionStore.canViewReportsPage,
      showInSubNavigation: permissionStore.canViewReportsPage,
      errorElement: errorWrapper,
      element: (
        <PagesLayout
          redirectPath={`${fortifiedCommercialMultifamilyRoutePrefix}`}
          errorWrapper={errorWrapper}
        />
      ),
      children: [
        {
          path: "",
          label: "Reports",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: <ReportsPage />,
        },
        {
          path: ":slug",
          label: "Reports",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: <ReportViewerPage />,
        },
      ],
    },
    {
      path: `${fortifiedCommercialMultifamilyRoutePrefix}/admin`,
      label: "Admin",
      icon: faSliders,
      isAvailable: true,
      showInNavigation: permissionStore.canViewAdminPage,
      showInSubNavigation: permissionStore.canViewAdminPage,
      errorElement: errorWrapper,
      element: (
        <PagesLayout
          redirectPath={`${fortifiedCommercialMultifamilyRoutePrefix}`}
          errorWrapper={errorWrapper}
        />
      ),
      children: [
        {
          path: "",
          label: "Admin",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: true,
          errorElement: errorWrapper,
          element: <AdminPage />,
        },
      ],
    },
    {
      path: `${fortifiedCommercialMultifamilyRoutePrefix}/help`,
      label: "Help",
      icon: faQuestionCircle,
      isAvailable: true,
      showInNavigation: true,
      showInSubNavigation: true,
      errorElement: errorWrapper,
      element: (
        <PagesLayout
          redirectPath={`${fortifiedCommercialMultifamilyRoutePrefix}`}
          errorWrapper={errorWrapper}
        />
      ),
      children: [
        {
          path: "",
          label: "Help",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: true,
          errorElement: errorWrapper,
          element: <HelpPage />,
        },
      ],
    },
    {
      path: `${fortifiedCommercialMultifamilyRoutePrefix}/bid/:id`,
      label: "Bids Page",
      icon: undefined,
      isAvailable: true,
      showInNavigation: false,
      showInSubNavigation: false,
      errorElement: errorWrapper,
      element: (
        <PagesLayout
          redirectPath={`${fortifiedCommercialMultifamilyRoutePrefix}`}
          errorWrapper={errorWrapper}
        />
      ),
      children: [
        {
          path: "",
          label: "Bids Page",
          isAvailable: true,
          showInNavigation: false,
          errorElement: errorWrapper,
          showInSubNavigation: false,
          element: <EvaluationBidPage />,
        },
      ],
    },
    {
      path: `${fortifiedCommercialMultifamilyRoutePrefix}/external/evaluation-summary/:id`,
      label: "Bids Page",
      icon: undefined,
      isAvailable: true,
      showInNavigation: false,
      showInSubNavigation: false,
      errorElement: errorWrapper,
      element: (
        <PagesLayout
          redirectPath={`${fortifiedCommercialMultifamilyRoutePrefix}`}
          errorWrapper={errorWrapper}
        />
      ),
      children: [
        {
          path: "",
          label: "Bids Page",
          isAvailable: true,
          showInNavigation: false,
          errorElement: errorWrapper,
          showInSubNavigation: false,
          element: <ExternalEvaluationSummaryPage />,
        },
      ],
    },
    //Commerical V2 Routes
    {
      path: `${commercialRoutePrefix}/:evaluationId`,
      label: "Forms",
      icon: undefined,
      isAvailable: true,
      showInNavigation: false,
      showInSubNavigation: false,
      errorElement: errorWrapper,
      element: (
        <FCFMFPagesLayout
          redirectPath={`${fortifiedCommercialMultifamilyRoutePrefix}`}
        />
      ),
      children: [
        {
          path: "payment/:paymentType",
          label: "Payment",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: (
            <WithParams<{
              evaluationId: string;
              paymentType: string;
            }>>
              {({ evaluationId, paymentType }) => {
                const getCheckoutUrl =
                  getFortifiedCheckoutUrlMethod(paymentType);

                if (!getCheckoutUrl || !evaluationId) {
                  return errorWrapper;
                }

                return (
                  <PaymentRedirectPage
                    getCheckoutUrl={() => getCheckoutUrl(evaluationId)}
                  />
                );
              }}
            </WithParams>
          ),
        },
        {
          path: "application-form",
          label: "Application Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return (
                  <CommercialApplicationFormPage evaluationId={evaluationId} />
                );
              }}
            </WithParams>
          ),
        },
        {
          path: "application-form/:commercialApplicationFormPart",
          label: "Application Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              evaluationId: string;
              commercialApplicationFormPart: FCFMFApplicationFormParts;
            }>>
              {({ evaluationId, commercialApplicationFormPart }) => {
                return (
                  <CommercialApplicationFormPage
                    evaluationId={evaluationId}
                    commercialApplicationFormPart={
                      commercialApplicationFormPart
                    }
                  />
                );
              }}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
        {
          path: "company-assignment-form",
          label: "Evaluation Evaluator Assignment Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return (
                  <CommercialEvaluationCompanyAssignmentFormPage
                    evaluationId={evaluationId}
                  />
                );
              }}
            </WithParams>
          ),
        },
        {
          path: "field-inspection-form",
          label: "Evaluation Field Inspection Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return (
                  <CommercialEvaluationFieldInspectionFormPage
                    evaluationId={evaluationId}
                  />
                );
              }}
            </WithParams>
          ),
        },
      ],
    },
    {
      path: `${commercialRoutePrefix}/iteration-engine/:evaluationId`,
      label: "Iteration Engine",
      isAvailable: true,
      showInNavigation: false,
      showInSubNavigation: false,
      errorElement: errorWrapper,
      element: (
        <FCFMFPagesLayout
          redirectPath={`${fortifiedCommercialMultifamilyRoutePrefix}`}
        />
      ),
      children: [
        {
          path: "",
          label: "",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return (
                  <CommercialIterationEnginePage evaluationId={evaluationId} />
                );
              }}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
      ],
    },
    //Commercial Redesignation V2 Routes
    {
      path: `${commercialRedesignationRoutePrefix}/:evaluationId`,
      label: "Forms",
      icon: undefined,
      isAvailable: true,
      showInNavigation: false,
      showInSubNavigation: false,
      errorElement: errorWrapper,
      element: (
        <FCFMFPagesLayout
          redirectPath={`${fortifiedCommercialMultifamilyRoutePrefix}`}
        />
      ),
      children: [
        {
          path: "form",
          label: "Redesignation Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return (
                  <CommercialRedesignationFormPage
                    evaluationId={evaluationId}
                  />
                );
              }}
            </WithParams>
          ),
        },
        {
          path: "form/:formPart",
          label: "Redesignation Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              evaluationId: string;
              formPart: FCFMFRedesignationFormParts;
            }>>
              {({ evaluationId, formPart }) => {
                return (
                  <CommercialRedesignationFormPage
                    evaluationId={evaluationId}
                    formPart={formPart}
                  />
                );
              }}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
        {
          path: "payment",
          label: "Payment",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                if (!evaluationId) {
                  return errorWrapper;
                }

                return (
                  <PaymentRedirectPage
                    getCheckoutUrl={async () =>
                      commercialRedesignationAPIClient.getApplicationFeeStripeCheckoutLink(
                        evaluationId
                      )
                    }
                  />
                );
              }}
            </WithParams>
          ),
        },
      ],
    },
    {
      path: `${commercialRedesignationRoutePrefix}/iteration-engine/:evaluationId`,
      label: "Iteration Engine",
      isAvailable: true,
      showInNavigation: false,
      showInSubNavigation: false,
      errorElement: errorWrapper,
      element: (
        <FCFMFPagesLayout
          redirectPath={`${fortifiedCommercialMultifamilyRoutePrefix}`}
        />
      ),
      children: [
        {
          path: "",
          label: "",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return (
                  <CommercialRedesignationIterationEnginePage
                    evaluationId={evaluationId}
                  />
                );
              }}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
      ],
    },
    //Multifamily V2 Routes
    {
      path: `${multifamilyRoutePrefix}/:evaluationId`,
      label: "Forms",
      icon: undefined,
      isAvailable: true,
      showInNavigation: false,
      showInSubNavigation: false,
      errorElement: errorWrapper,
      element: (
        <FCFMFPagesLayout
          redirectPath={`${fortifiedCommercialMultifamilyRoutePrefix}`}
        />
      ),
      children: [
        {
          path: "payment/:paymentType",
          label: "Payment",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: (
            <WithParams<{
              evaluationId: string;
              paymentType: string;
            }>>
              {({ evaluationId, paymentType }) => {
                if (!evaluationId) {
                  return errorWrapper;
                }

                let getRedirectUrl: (evaluationId: string) => Promise<string>;
                switch (paymentType) {
                  case "application-fee":
                    getRedirectUrl =
                      multifamilyEvaluationAPIClient.getApplicationFeeStripeCheckoutLink;
                    break;

                  case "certificate-fee":
                    getRedirectUrl =
                      multifamilyEvaluationAPIClient.getCertificateFeeStripeCheckoutLink;
                    break;

                  default:
                    return errorWrapper;
                }

                return (
                  <PaymentRedirectPage
                    getCheckoutUrl={async () => getRedirectUrl(evaluationId)}
                  />
                );
              }}
            </WithParams>
          ),
        },
        {
          path: "application-form",
          label: "Application Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return (
                  <MultifamilyApplicationFormPage evaluationId={evaluationId} />
                );
              }}
            </WithParams>
          ),
        },
        {
          path: "application-form/:multifamilyApplicationFormPart",
          label: "Application Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              evaluationId: string;
              multifamilyApplicationFormPart: FCFMFApplicationFormParts;
            }>>
              {({ evaluationId, multifamilyApplicationFormPart }) => {
                return (
                  <MultifamilyApplicationFormPage
                    evaluationId={evaluationId}
                    multifamilyApplicationFormPart={
                      multifamilyApplicationFormPart
                    }
                  />
                );
              }}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
        {
          path: "company-assignment-form",
          label: "Evaluation Evaluator Assignment Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return (
                  <MultifamilyEvaluationCompanyAssignmentFormPage
                    evaluationId={evaluationId}
                  />
                );
              }}
            </WithParams>
          ),
        },
        {
          path: "field-inspection-form",
          label: "Evaluation Field Inspection Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return (
                  <MultifamilyEvaluationFieldInspectionFormPage
                    evaluationId={evaluationId}
                  />
                );
              }}
            </WithParams>
          ),
        },
      ],
    },

    {
      path: `${multifamilyRoutePrefix}/iteration-engine/:evaluationId`,
      label: "Iteration Engine",
      isAvailable: true,
      showInNavigation: false,
      showInSubNavigation: false,
      errorElement: errorWrapper,
      element: (
        <FCFMFPagesLayout
          redirectPath={`${fortifiedCommercialMultifamilyRoutePrefix}`}
        />
      ),
      children: [
        {
          path: "",
          label: "",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return (
                  <MultifamilyIterationEnginePage evaluationId={evaluationId} />
                );
              }}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
      ],
    },
    //Multifamily Redesignation V2 Routes
    {
      path: `${multifamilyRedesignationRoutePrefix}/:evaluationId`,
      label: "Forms",
      icon: undefined,
      isAvailable: true,
      showInNavigation: false,
      showInSubNavigation: false,
      errorElement: errorWrapper,
      element: (
        <FCFMFPagesLayout
          redirectPath={`${fortifiedCommercialMultifamilyRoutePrefix}`}
        />
      ),
      children: [
        {
          path: "form",
          label: "Redesignation Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return (
                  <MultifamilyRedesignationFormPage
                    evaluationId={evaluationId}
                  />
                );
              }}
            </WithParams>
          ),
        },
        {
          path: "form/:formPart",
          label: "Redesignation Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              evaluationId: string;
              formPart: FCFMFRedesignationFormParts;
            }>>
              {({ evaluationId, formPart }) => {
                return (
                  <MultifamilyRedesignationFormPage
                    evaluationId={evaluationId}
                    formPart={formPart}
                  />
                );
              }}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
        {
          path: "payment",
          label: "Payment",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                if (!evaluationId) {
                  return errorWrapper;
                }

                return (
                  <PaymentRedirectPage
                    getCheckoutUrl={async () =>
                      multifamilyRedesignationAPIClient.getApplicationFeeStripeCheckoutLink(
                        evaluationId
                      )
                    }
                  />
                );
              }}
            </WithParams>
          ),
        },
      ],
    },
    {
      path: `${multifamilyRedesignationRoutePrefix}/iteration-engine/:evaluationId`,
      label: "Iteration Engine",
      isAvailable: true,
      showInNavigation: false,
      showInSubNavigation: false,
      errorElement: errorWrapper,
      element: (
        <FCFMFPagesLayout
          redirectPath={`${fortifiedCommercialMultifamilyRoutePrefix}`}
        />
      ),
      children: [
        {
          path: "",
          label: "",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return (
                  <MultifamilyRedesignationIterationEnginePage
                    evaluationId={evaluationId}
                  />
                );
              }}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
      ],
    },
  ] as utils.routes.UclRouteObject[];
};

export const fortified_Commercial_Multifamily_browserRouter = () =>
  createBrowserRouter(
    [...uclRouteObject(), ...uclRouteObject_fortifiedHomeRouteConfig()],
    {
      basename: process.env.REACT_APP_PATH_PREFIX,
    }
  );
