import {
  AsyncAutoCompleteFieldProps,
  EnumRadioFieldProps,
  FieldFormV2,
  FieldFormV2Module,
  FieldSchemaFormBuilderV2,
  Footer,
} from "@ucl/library";
import { FC, useEffect, useRef, useState } from "react";
import { EnumRadioFieldValue } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { useNavigate } from "react-router";
import "./styles.scss";
import WildfireSecondaryButton from "../../../../../../../../wildfire/components/Button/DialogFooterButtons/SecondaryButton/SecondaryButton";
import WildfireSubmitButton from "../../../../../../../../wildfire/components/Button/DialogFooterButtons/SubmitButton/SubmitButton";
import { closeCreateHomeEvaluationDialog } from "./CreateHomeEvaluationDialog";
import { homeStandardEvaluationAPIClient } from "../../../../../../standard/lib/apiClients/homeStandardEvaluationAPIClient";
import { homeSCSHEvaluationAPIClient } from "../../../../../../scsh/lib/apiClients/homeSCSHEvaluationAPIClient";
import { homeNCIUAEvaluationAPIClient } from "../../../../../../nciua/evaluation/lib/apiClients/homeNCIUAEvaluationAPIClient";
import { getAuthRequestHeader } from "../../../../../../../../common/lib/msal/fortifiedHomeMsal";
import variables from "../../../../../../../../common/config/variables";
import { Predicate } from "@syncfusion/ej2-data";
import { HomeEvaluationOptions } from "../../../../../../../../foritfied/types/schema";
import { homeStandardRedesignationAPIClient } from "../../../../../../standardRedesignation/lib/apiClients/homeStandardRedesignationAPIClient";
import { fortifiedUserStore } from "../../../../../../../../foritfied/stores/FortifiedUserStore";
import HomeEvaluationFieldInfoContainer from "../../../../../../../../foritfied/components/Dialogs/CreateEvaluation/Home/HomeEvaluationFieldInfoContainer";
import EvaluationHistory from "../../../../../../../../foritfied/components/Evaluation/History/EvaluationHistory";
import { ProductSubtypes } from "../../../../../../../Common/types/Evaluation/BaseEvaluation";
import { FeatureToggleSet } from "../../../../../../../../common/Components/FeatureToggle/FeatureToggles";
import { featureToggleApiClient } from "../../../../../../../../common/Components/FeatureToggle/class/featureToggleApiClient";

export interface CreateHomeEvaluationFormModel {
  selectedEvaluationType?: EnumRadioFieldValue;
  redesignationOption?: HomeEvaluationOptions;
  redesignationId?: string;
}

export const CreateHomeEvaluationForm: FC = () => {
  const createFCFMFEvaluationFormRef =
    useRef<FieldFormV2Module<CreateHomeEvaluationFormModel>>(null);

  const [homeEvaluationFormModel, setHomeEvaluationFormModel] =
    useState<CreateHomeEvaluationFormModel>();

  const [featureToggles, setFeatureToggles] = useState<FeatureToggleSet>();

  const getFeatureToggles = async () => {
    const toggles = await featureToggleApiClient.getFeatureToggleSet();

    setFeatureToggles(toggles);
  };

  useEffect(() => {
    getFeatureToggles();
  }, []);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const createHomeEvaluation = async (form: CreateHomeEvaluationFormModel) => {
    switch (form.selectedEvaluationType) {
      case 1:
      case 5:
        setIsLoading(true);
        await homeStandardEvaluationAPIClient
          .createHomeStandardEvaluation(form.selectedEvaluationType === 5)
          .then((homeStandardEvaluation) => {
            closeCreateHomeEvaluationDialog();
            navigate(
              `/fh/standard/${homeStandardEvaluation.id}/basic-information/homeowner-information`
            );
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            setIsLoading(false);
          });

        break;
      case 2:
        setIsLoading(true);

        await homeStandardRedesignationAPIClient
          .createHomeStandardRedesignation(form.redesignationId || "")
          .then((homeStandardRedesignation) => {
            closeCreateHomeEvaluationDialog();
            navigate(
              `/fh/standard-redesignation/checklist/${homeStandardRedesignation.id}`
            );
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;
      case 3:
        setIsLoading(true);
        await homeSCSHEvaluationAPIClient
          .createHomeSCSHEvaluation()
          .then((homeSCSHEvaluation) => {
            closeCreateHomeEvaluationDialog();
            navigate(
              `/fh/scsh/${homeSCSHEvaluation.id}/basic-information/homeowner-information`
            );
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            setIsLoading(false);
          });

        break;
      case 4:
        setIsLoading(true);
        await homeNCIUAEvaluationAPIClient
          .createHomeNCIUAEvaluation()
          .then((homeNCIUAEvaluation) => {
            closeCreateHomeEvaluationDialog();
            navigate(
              `/fh/nciua/${homeNCIUAEvaluation.id}/basic-information/homeowner-information`
            );
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            setIsLoading(false);
          });

        break;
      default:
        break;
    }
  };

  const optionValues = [
    {
      label: "New Evaluation",
      value: 1,
    },
    {
      label: "Redesignation",
      value: 2,
    },
    //TODO: Add These for Relativity Admins to test Special Workflows
    // {
    //   label: "SCSH",
    //   value: 3,
    // },
    // {
    //   label: "NCIUA",
    //   value: 4,
    // },
    {
      label: "Milestone Inspection",
      value: 5,
    },
  ];

  const isRelativityAdmin = fortifiedUserStore.IsRelativityAdmin;

  const filteredOptionValues = optionValues.filter(
    (option) =>
      // Keep the option if you're a Relativity Admin OR its value is not 3 or 4
      (isRelativityAdmin || (option.value !== 3 && option.value !== 4)) &&
      // Hide option value 5 when featureToggles?.MilestoneInspections === false
      (featureToggles?.MilestoneInspections || option.value !== 5)
  );

  const createFcFMFFormBuilder: FieldSchemaFormBuilderV2<
    CreateHomeEvaluationFormModel
  > = (onValueChange, form, _) => {
    return [
      {
        type: "EnumRadio",
        fieldName: "updateHomeowner",
        fieldKey: "updateHomeowner",
        label: "Choose an Evaluation Type",
        value: form.selectedEvaluationType,
        optionValues: filteredOptionValues,
        onSubmit: function (value) {
          onValueChange(
            {
              ...form,
              selectedEvaluationType: value as number,
              redesignationId: undefined,
              redesignationOption: undefined,
            },
            value,
            this
          );
        },
      } as EnumRadioFieldProps,
      //TODO: This needs to be refactored potentially
      {
        type: "AsyncAutoComplete",
        fieldName: "evaluationSearchByFID",
        fieldKey: "evaluationSearchByFID",
        hidden: form.selectedEvaluationType != 2,
        label: "Search by FID",
        placeholder: "Type to search Evaluations...",
        noResultsLabel: "No Evaluations found",
        apiBaseUrl: variables.apiBaseUrl,
        odataUrl: `${variables.apiBaseUrl}odata/evaluationodata/options`,
        getAuthRequestHeader,
        fieldNames: [
          "id",
          "fortifiedId",
          "isApproved",
          "isRedesignation",
          "isActive",
        ],
        autoCompleteSettings: {
          fields: { text: "fortifiedId", value: "id" },
          itemTemplate: "<table><tr><td>${fortifiedId}</td></tr><table>",
        },
        sortBy: "fortifiedId",
        searchBy: (searchText: string) =>
          new Predicate("fortifiedId", "contains", searchText, true),
        value: form.redesignationId,
        onSubmit: function (value) {
          const evaluation = value as unknown as HomeEvaluationOptions;
          onValueChange(
            {
              ...form,
              redesignationId: evaluation?.id,
              redesignationOption: evaluation,
            },
            value,
            this
          );
        },
      } as AsyncAutoCompleteFieldProps,
      {
        type: "AsyncAutoComplete",
        fieldName: "evaluationSearchByAddress",
        fieldKey: "evaluationSearchByAddress",
        hidden: form.selectedEvaluationType != 2,
        label: "Search by Address",
        placeholder: "Type to search Evaluations...",
        noResultsLabel: "No Evaluations found",
        apiBaseUrl: variables.apiBaseUrl,
        odataUrl: `${variables.apiBaseUrl}odata/homeevaluationaddressodata/options`,
        getAuthRequestHeader,
        fieldNames: [
          "id",
          "fortifiedId",
          "buildingAddress_AsString",
          "isApproved",
          "isRedesignation",
          "isActive",
        ],
        autoCompleteSettings: {
          fields: { text: "buildingAddress_AsString", value: "id" },
          itemTemplate:
            "<table><tr><td>${buildingAddress_AsString}</td></tr><table>",
        },
        sortBy: "fortifiedId",
        searchBy: (searchText: string) =>
          new Predicate(
            "buildingAddress_AsString",
            "contains",
            searchText,
            true
          ),
        value: form.redesignationId,
        onSubmit: function (value) {
          const evaluation = value as unknown as HomeEvaluationOptions;
          onValueChange(
            {
              ...form,
              redesignationId: evaluation?.id,
              redesignationOption: evaluation,
            },
            value,
            this
          );
        },
      } as AsyncAutoCompleteFieldProps,
    ];
  };

  if (!featureToggles) {
    return <div>Loading...</div>;
  }

  return (
    <div className="create-home-evalaution-dialog">
      <FieldFormV2<CreateHomeEvaluationFormModel>
        ref={createFCFMFEvaluationFormRef}
        value={homeEvaluationFormModel}
        isDisabled={isLoading}
        fieldSchemaFormBuilder={createFcFMFFormBuilder}
        onFormSubmit={async (form) => {
          await createHomeEvaluation(form);
        }}
        onFieldChanged={(form) => {
          setHomeEvaluationFormModel(form);
        }}
      />
      <div>You will be starting a new FORTIFIED Application, continue?</div>
      <br />

      {homeEvaluationFormModel?.redesignationOption && (
        <>
          <div className="create-home-evaluation-info-container">
            <HomeEvaluationFieldInfoContainer
              selectedOption={homeEvaluationFormModel?.redesignationOption}
              selectedChildOption={undefined}
            />
          </div>
          <EvaluationHistory
            evaluationId={homeEvaluationFormModel?.redesignationOption.id}
            productSubtype={ProductSubtypes.HomeStandardEvaluation}
          />
        </>
      )}

      <Footer>
        <WildfireSecondaryButton
          buttonText="Cancel"
          onClick={() => closeCreateHomeEvaluationDialog()}
          disabled={isLoading}
        />
        <WildfireSubmitButton
          buttonText="Submit"
          onClick={() => createFCFMFEvaluationFormRef.current?.submit()}
          isLoading={isLoading}
        />
      </Footer>
    </div>
  );
};
