import { first } from "lodash";
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import FortifiedGridColumn from "../types/fortifiedGrid/FortifiedGridColumn";
import { fortifiedGridViewApiClient } from "../lib/apiClients/fortifiedGridView/fortifiedGridViewApiClient";
import FortifiedGridViewCreateDTO from "../types/dtos/FortifiedGridViewCreateDTO";
import FortifiedGridViewReadDTO, {
  ViewFilterSettings,
} from "../types/dtos/FortifiedGridViewReadDTO";
import FortifiedGridViewUpdateFilterSettingsDTO from "../types/dtos/FortifiedGridViewUpdateFilterSettingsDTO";
import { FortifiedGridFilterSettings } from "../types/fortifiedGrid/FortifiedGridFilterSettings";
import FortifiedGridView from "../types/fortifiedGrid/FortifiedGridView";
import { FortifiedGridBaseSettings } from "../types/fortifiedGrid/FortifiedGridBaseSettings";
import { fortifiedUserStore } from "./FortifiedUserStore";
import { UserRoleType } from "../../common/Components/User/userTypes";
import { BoardGridView } from "../lib/gridColumnConfigurations/boardViewsConfiguration";

export class FortifiedGridViewModuleStore {
  public currentGridView?: FortifiedGridView;
  public userGridViews?: FortifiedGridViewReadDTO[];
  public baseGridColumnsConfiguration?: FortifiedGridColumn[];
  public gridBaseSettings?: FortifiedGridBaseSettings;
  public get currentGridViewKey(): string {
    return `${this.currentGridView?.id ?? "-"}-${
      this.currentGridView?.columnSettings.join("") ?? "-"
    }`;
  }

  private initalLoad = false;

  // Load all views based on the Grid Id, and auto create views as needed.
  /**
   * @param {Record<UserRoleType, FortifiedGridColumn[]>} roleColumnsConfiguration - Only used for Auto Views creation
   */
  public loadViews = async (
    gridTitle: string,
    gridId: string,
    getColumnsConfigurationByRole: (
      role?: UserRoleType
    ) => FortifiedGridColumn[],
    getDefaultViewByRole: (role?: UserRoleType) => BoardGridView
  ) => {
    // Fetch
    let gridViewDTOs = await fortifiedGridViewApiClient.fetchViews(gridId);

    // If None, Auto Create
    if (gridViewDTOs.length <= 0 && this.initalLoad !== true) {
      this.initalLoad = true;

      await this.createDefaultViews(gridId, getColumnsConfigurationByRole);
      gridViewDTOs = await fortifiedGridViewApiClient.fetchViews(gridId);
    }

    // Setup observables
    this.setGridBaseSettings({ id: gridId, title: gridTitle });
    this.setBaseGridColumnsConfiguration(
      getColumnsConfigurationByRole(fortifiedUserStore.user?.role)
    );
    this.setUserGridViews(gridViewDTOs);

    const current =
      // First, try to assign view based on user role
      gridViewDTOs.find(
        (x) => x.title === getDefaultViewByRole(fortifiedUserStore.user?.role)
      ) ??
      // If that doesn't work, try to assign default view "All"
      gridViewDTOs.find((x) => x.title === "All") ??
      // Last chance, try to assign default view "Auto"
      gridViewDTOs.find((x) => x.title === "Auto") ??
      // Worst case, just pick the first view that exists
      first(gridViewDTOs);

    if (current) {
      this.setCurrentGridView(current.id);
    }
  };

  public createDefaultViews = async (
    gridId: string,
    getColumnsConfigurationByRole: (
      role?: UserRoleType
    ) => FortifiedGridColumn[]
  ): Promise<FortifiedGridViewReadDTO> => {
    // Get config by role
    const columnsConfiguration = getColumnsConfigurationByRole(
      fortifiedUserStore.user?.role
    );

    // Creates All Grid Views for the Specific User By Role
    const newGridView = await this.createView(gridId, {
      title: "Auto",
      columnSettings: [...columnsConfiguration].map((x) => x.field).join(","),
    } as FortifiedGridViewCreateDTO);

    return newGridView;
  };

  public createView = async (
    gridId: string,
    fortifiedGridViewCreateDTO: FortifiedGridViewCreateDTO
  ): Promise<FortifiedGridViewReadDTO> => {
    const newGridView = await fortifiedGridViewApiClient.createView(
      gridId,
      fortifiedGridViewCreateDTO
    );
    return newGridView;
  };

  public getViewFilterSettings = (
    fortifiedGridFilterSettings: FortifiedGridFilterSettings
  ): ViewFilterSettings => {
    return {
      columnSettings: fortifiedGridFilterSettings.rawColumnSettings.map(
        (x) => x.field
      ),
      filterSettings: JSON.stringify(
        fortifiedGridFilterSettings.rawFilterSettings
      ),
      sortSettings: JSON.stringify(fortifiedGridFilterSettings.rawSortSettings),
    };
  };

  public updateViewFilterSettings = async (
    gridId: string,
    fortifiedGridFilterSettings: FortifiedGridFilterSettings
  ): Promise<void> => {
    if (!this.userGridViews || !this.currentGridView) return;

    // Setup
    const getViewFilterSettings = this.getViewFilterSettings(
      fortifiedGridFilterSettings
    );

    // Update Local
    const idx = this.userGridViews.findIndex(
      (x) => x.id === this.currentGridView?.id
    );
    if (idx < 0) return;

    this.userGridViews[idx].columnSettings =
      getViewFilterSettings.columnSettings;
    this.userGridViews[idx].filterSettings =
      getViewFilterSettings.filterSettings;
    this.userGridViews[idx].sortSettings = getViewFilterSettings.sortSettings;

    // Update
    const updateFilterSettingsDTO = {
      columnSettings: getViewFilterSettings.columnSettings.join(","),
      filterSettings: JSON.stringify(
        fortifiedGridFilterSettings.rawFilterSettings
      ),
      sortSettings: JSON.stringify(fortifiedGridFilterSettings.rawSortSettings),
    } as FortifiedGridViewUpdateFilterSettingsDTO;

    await fortifiedGridViewApiClient.updateViewFilterSettings(
      gridId,
      this.currentGridView.id,
      updateFilterSettingsDTO
    );
  };

  public updateViewColumnSettings = async (
    viewId: string,
    columnSettings: string[]
  ): Promise<void> => {
    if (!this.userGridViews || !this.currentGridView) return;

    // Update Local
    const idx = this.userGridViews.findIndex(
      (x) => x.id === this.currentGridView?.id
    );
    if (idx < 0) return;

    this.userGridViews[idx].columnSettings = columnSettings;

    // Update
    await fortifiedGridViewApiClient.updateViewColumnSettings(
      this.userGridViews[idx].gridId,
      viewId,
      columnSettings.join(",")
    );

    if (this.currentGridView.id === viewId) {
      this.setCurrentGridView(viewId);
    }
  };

  public updateViewMetricSettings = async (
    viewId: string,
    metricSettings: string[]
  ): Promise<void> => {
    if (!this.userGridViews || !this.currentGridView) {
      return;
    }

    // Update Local
    const idx = this.userGridViews.findIndex(
      (x) => x.id === this.currentGridView?.id
    );
    if (idx < 0) {
      return;
    }

    this.userGridViews[idx].metricSettings = metricSettings.join(",");

    // Update
    await fortifiedGridViewApiClient.updateViewMetricsSettings(
      this.userGridViews[idx].gridId,
      viewId,
      metricSettings.join(",")
    );

    if (this.currentGridView.id === viewId) {
      this.setCurrentGridView(viewId);
    }
  };

  // Set Observables
  public setGridBaseSettings = (
    fortifiedGridBaseSettings: FortifiedGridBaseSettings
  ) => {
    this.gridBaseSettings = fortifiedGridBaseSettings;
  };
  public setBaseGridColumnsConfiguration = (
    fortifiedGridColumns: FortifiedGridColumn[]
  ) => {
    this.baseGridColumnsConfiguration = fortifiedGridColumns;
  };

  public setUserGridViews = (
    fortifiedGridViews: FortifiedGridViewReadDTO[]
  ) => {
    this.userGridViews = fortifiedGridViews;
  };

  public setCurrentGridView = (id: string) => {
    const gridViewDto = this.userGridViews?.find((x) => x.id === id);
    if (this.baseGridColumnsConfiguration && gridViewDto) {
      const gridView = new FortifiedGridView(
        gridViewDto,
        this.baseGridColumnsConfiguration
      );
      runInAction(() => {
        this.currentGridView = gridView;
      });
    }
  };

  public getGridView = (id: string): FortifiedGridView => {
    if (!this.userGridViews || !this.baseGridColumnsConfiguration) {
      throw `No views data loaded.`;
    }

    const gridViewDto = this.userGridViews?.find((x) => x.id === id);

    if (!gridViewDto) throw `Grid View ${id} could not be found.`;

    const gridView = new FortifiedGridView(
      gridViewDto,
      this.baseGridColumnsConfiguration
    );

    return gridView;
  };

  constructor() {
    makeObservable(this, {
      currentGridView: observable,
      userGridViews: observable,
      baseGridColumnsConfiguration: observable,
      gridBaseSettings: observable,
      currentGridViewKey: computed,
      loadViews: action,
      updateViewFilterSettings: action,
      updateViewColumnSettings: action,
      setBaseGridColumnsConfiguration: action,
      setUserGridViews: action,
      setCurrentGridView: action,
      setGridBaseSettings: action,
    });
  }

  reset = () => {
    runInAction(() => {
      this.currentGridView = undefined;
      this.userGridViews = undefined;
      this.baseGridColumnsConfiguration = undefined;
      this.gridBaseSettings = undefined;
    });
  };
}

export const fortifiedGridViewModuleStore = new FortifiedGridViewModuleStore();
