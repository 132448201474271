import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import variables from "../../../common/config/variables";

export class ExternalApiClient {
  private axiosInstance: AxiosInstance;

  constructor(baseApiUrl: string) {
    this.axiosInstance = axios.create({ baseURL: baseApiUrl });
  }

  async get<T>(url: string, config?: AxiosRequestConfig) {
    return await this.axiosInstance.get<T>(url, config).then((res) => res.data);
  }

  async post<T>(url: string, data = {}, config?: AxiosRequestConfig) {
    return await this.axiosInstance
      .post<T>(url, data, config)
      .then((res) => res.data);
  }

  async put<T>(url: string, data = {}, config?: AxiosRequestConfig) {
    return await this.axiosInstance
      .put<T>(url, data, config)
      .then((res) => res.data);
  }
}

export default new ExternalApiClient(`${variables.apiBaseUrl as string}api`);
