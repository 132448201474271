export enum ProductTypes {
  FORTIFIEDCommercial = 1,
  FORTIFIEDMultifamily = 2,
  FORTIFIEDHome = 3,
  WildFirePrepared = 4,
}

export enum EvaluationProductTypes {
  None = 0,
  FortifiedCommercial = 1,
  FortifiedMultifamily = 2,
  FortifiedHome = 3,
  WildfirePrepared = 4,
  FortifiedHomeNCIUA = 5,
  FortifiedHomeSCSH = 6,
  FortifiedCommercialRedesignation = 7,
  FortifiedMultifamilyRedesignation = 8,
  FortifiedHomeRedesignation = 9,
}

export interface ProductTypeOption {
  label: string;
  value: ProductTypes;
}

export const ProductTypeOptions: ProductTypeOption[] = [
  {
    label: "FORTIFIED Commercial",
    value: ProductTypes.FORTIFIEDCommercial,
  },
  {
    label: "FORTIFIED Multifamily",
    value: ProductTypes.FORTIFIEDMultifamily,
  },
  {
    label: "FORTIFIED Home",
    value: ProductTypes.FORTIFIEDHome,
  },
  {
    label: "WildFire Prepared",
    value: ProductTypes.WildFirePrepared,
  },
];
