import {
  EnumMultiSelectFieldV2Value,
  EnumMultiSelectFieldValue,
  EnumRadioFieldValue,
  EnumSingleSelectFieldValue,
} from "@ucl/library/lib/components/Fields/types/fieldTypes";
import {
  BuildingElementTypes,
  ContinuousLoadPathPathwayTypes,
  DesignationLevelTypes,
  NewOrExistingRoofTypes,
} from "../../types/BaseHomeEvaluationEligibilityFormModel";
import { YesOrNoSelection } from "../../../../../wildfire/types/FormFields";
import { HazardTypes } from "../../../../Common/types/Evaluation/BaseEvaluation";
import { RoofCoverTypes } from "../../types/BaseHomeEvaluationRoofingComplianceRoofCoverFormModel";

// Roofing Compliance Form: General Information
export const shouldHidePermitInformationFields = (
  wasPermitIssued: EnumRadioFieldValue
) => wasPermitIssued !== YesOrNoSelection.Yes;

export const shouldHideSiteConditionsFields = (
  newOrExistingRoof: EnumSingleSelectFieldValue
) => newOrExistingRoof === NewOrExistingRoofTypes.ExistingRoof;

export const shouldHideSealedRoofDeckFields = (
  isEntireRoofLowSloped: EnumRadioFieldValue
) => isEntireRoofLowSloped !== YesOrNoSelection.No;

export const shouldHideAtticVentsFields = (hazard?: HazardTypes) =>
  hazard !== HazardTypes.Hurricane;

// Roofing Compliance Form: Roof Cover
export const shouldHideAsphaltShingleRoofCoverDetailsFields = (
  roofCoverTypes?: EnumMultiSelectFieldValue
) => !roofCoverTypes?.includes(RoofCoverTypes.AsphaltShingle);

export const shouldHideMetalRoofCoverDetailsFields = (
  roofCoverTypes?: EnumMultiSelectFieldValue
) => !roofCoverTypes?.includes(RoofCoverTypes.MetalPanel);

export const shouldHideClayConcreteTileRoofCoverDetailsFields = (
  roofCoverTypes?: EnumMultiSelectFieldValue
) => !roofCoverTypes?.includes(RoofCoverTypes.ClayOrConcreteTile);

export const shouldHideWoodShakeSingleRoofCoverDetailsFields = (
  roofCoverTypes?: EnumMultiSelectFieldValue
) => !roofCoverTypes?.includes(RoofCoverTypes.WoodShakeOrShingle);

export const shouldHideLowSlopeRoofCoverDetailsFields = (
  roofCoverTypes?: EnumMultiSelectFieldValue
) => !roofCoverTypes?.includes(RoofCoverTypes.LowSlope);

// Silver and Gold Compliance Form: General Information
export const shouldHideEntryDoorsFields = (hazard?: HazardTypes) =>
  hazard !== HazardTypes.Hurricane;

export const shouldHideGableEndsFields = (
  buildingElementTypes?: EnumMultiSelectFieldV2Value
) =>
  !(
    buildingElementTypes?.includes(BuildingElementTypes.GablesOver3Feet) ||
    buildingElementTypes?.includes(BuildingElementTypes.GableOverhangs)
  );

export const shouldHideSoffitsFields = (hazard?: HazardTypes) =>
  hazard !== HazardTypes.Hurricane;

export const shouldHideChimneysFields = (
  buildingElementTypes?: EnumMultiSelectFieldV2Value
) => !buildingElementTypes?.includes(BuildingElementTypes.Chimneys);

export const shouldHideAttachedStructuresFields = (
  buildingElementTypes?: EnumMultiSelectFieldV2Value
) =>
  !(
    buildingElementTypes?.includes(
      BuildingElementTypes.SingleLevelAttachedStructures
    ) ||
    buildingElementTypes?.includes(
      BuildingElementTypes.MultiLevelAttachedStructures
    )
  );

export const shouldHideGoldComplianceInformation = (
  designationLevel?: EnumSingleSelectFieldValue,
  hazard?: HazardTypes
) =>
  !(
    designationLevel === DesignationLevelTypes.Gold &&
    hazard === HazardTypes.Hurricane
  );

// Installer Forms
export const shouldHideInstallerEngineeredGablesFields = (
  elements?: EnumMultiSelectFieldV2Value,
  engineered?: EnumMultiSelectFieldV2Value
) =>
  !(
    (elements?.includes(BuildingElementTypes.GablesOver3Feet) &&
      engineered?.includes(BuildingElementTypes.GablesOver3Feet)) ||
    (elements?.includes(BuildingElementTypes.GableOverhangs) &&
      engineered?.includes(BuildingElementTypes.GableOverhangs))
  );

export const shouldHideInstallerPrescriptiveGablesFields = (
  elements?: EnumMultiSelectFieldV2Value,
  engineered?: EnumMultiSelectFieldV2Value
) =>
  !(
    (elements?.includes(BuildingElementTypes.GablesOver3Feet) &&
      !engineered?.includes(BuildingElementTypes.GablesOver3Feet)) ||
    (elements?.includes(BuildingElementTypes.GableOverhangs) &&
      !engineered?.includes(BuildingElementTypes.GableOverhangs))
  );

export const shouldHideInstallerEngineeredChimneysFields = (
  elements?: EnumMultiSelectFieldV2Value,
  engineered?: EnumMultiSelectFieldV2Value
) =>
  !(
    elements?.includes(BuildingElementTypes.Chimneys) &&
    engineered?.includes(BuildingElementTypes.Chimneys)
  );

export const shouldHideInstallerPrescriptiveChimneysFields = (
  elements?: EnumMultiSelectFieldV2Value,
  engineered?: EnumMultiSelectFieldV2Value
) =>
  !(
    elements?.includes(BuildingElementTypes.Chimneys) &&
    !engineered?.includes(BuildingElementTypes.Chimneys)
  );

export const shouldHideInstallerEngineeredAttachedStructuresFields = (
  elements?: EnumMultiSelectFieldV2Value,
  engineered?: EnumMultiSelectFieldV2Value
) =>
  !(
    (elements?.includes(BuildingElementTypes.SingleLevelAttachedStructures) &&
      engineered?.includes(
        BuildingElementTypes.SingleLevelAttachedStructures
      )) ||
    (elements?.includes(BuildingElementTypes.MultiLevelAttachedStructures) &&
      engineered?.includes(BuildingElementTypes.MultiLevelAttachedStructures))
  );

export const shouldHideInstallerPrescriptiveAttachedStructuresFields = (
  elements?: EnumMultiSelectFieldV2Value,
  engineered?: EnumMultiSelectFieldV2Value
) =>
  !(
    (elements?.includes(BuildingElementTypes.SingleLevelAttachedStructures) &&
      !engineered?.includes(
        BuildingElementTypes.SingleLevelAttachedStructures
      )) ||
    (elements?.includes(BuildingElementTypes.MultiLevelAttachedStructures) &&
      !engineered?.includes(BuildingElementTypes.MultiLevelAttachedStructures))
  );

export const shouldHideInstallerCLPForNewConstructionFields = (
  continuousLoadPathPathwayType: EnumSingleSelectFieldValue
) =>
  continuousLoadPathPathwayType !==
  ContinuousLoadPathPathwayTypes.EngineeredCLPForNewConstruction;

export const shouldHideInstallerCLPForRetrofitOfExistingHomeFields = (
  continuousLoadPathPathwayType: EnumSingleSelectFieldValue
) =>
  continuousLoadPathPathwayType !==
  ContinuousLoadPathPathwayTypes.EngineeredRetrofitOfExistingHome;

export const shouldHideInstallerCLPForWoodFramingFields = (
  continuousLoadPathPathwayType: EnumSingleSelectFieldValue
) =>
  continuousLoadPathPathwayType !==
  ContinuousLoadPathPathwayTypes.PrescriptiveHighWindOnlyForNewWoodFramedConstruction;

// Engineering Forms
export const shouldHideEngineeringCLPForNewConstructionFields = (
  continuousLoadPathPathwayType: EnumSingleSelectFieldValue
) =>
  continuousLoadPathPathwayType !==
  ContinuousLoadPathPathwayTypes.EngineeredCLPForNewConstruction;

export const shouldHideEngineeringCLPForRetrofitOfExistingHomeFields = (
  continuousLoadPathPathwayType: EnumSingleSelectFieldValue
) =>
  continuousLoadPathPathwayType !==
  ContinuousLoadPathPathwayTypes.EngineeredRetrofitOfExistingHome;
