import { useContext, useEffect, useState } from "react";
import { UnsavedChangesBlockerContext } from "../../../../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import {
  FormChecklistPart,
  FormChecklistStatus,
} from "../../../../../../../common/types/Forms/FormChecklist";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../../../types/HomeEvaluationFormModel";
import { HomeSchemaFactoryV2 } from "../../../../../customHooks/useHomeFieldSchemaFactory";
import { HomeStandardEngineeringComplianceContinuousLoadPathFormContainerProps } from "./HomeStandardEngineeringComplianceContinuousLoadPathFormContainer";
import { BaseHomeEvaluationEngineeringComplianceContinuousLoadPathFormParts } from "../../../../../common/types/BaseHomeEvaluationEngineeringComplianceContinuousLoadPathFormModel";
import { AppHeaderContext } from "../../../../../../../wildfire/components/Provider/AppHeaderContext";
import { fortifiedFieldSchemaApiClient } from "../../../../../../Common/lib/apiClients/fortifiedFieldSchemaApiClient";
import {
  shouldHideEngineeringCLPForNewConstructionFields,
  shouldHideEngineeringCLPForRetrofitOfExistingHomeFields,
} from "../../../../../common/components/FieldSchema/sharedConditionalLogic";

function useHomeStandardEngineeringComplianceContinuousLoadPathFormContainer(
  props: HomeStandardEngineeringComplianceContinuousLoadPathFormContainerProps
) {
  const [schemaFactory, setSchemaFactory] = useState<HomeSchemaFactoryV2>();

  const getSchema = async () => {
    const schema = await fortifiedFieldSchemaApiClient.getSchema();
    const newSchemaFactory = new HomeSchemaFactoryV2(schema);
    setSchemaFactory(newSchemaFactory);
  };

  useEffect(() => {
    if (!schemaFactory) {
      getSchema();
    }
    return () => {
      setDefaultHeader();
    };
  }, []);

  useEffect(() => {
    setFormPart(getBestFormPart());
  }, [props.formParts]);

  const { setHasUnsavedChanges } = useContext(UnsavedChangesBlockerContext);

  const [formPart, setFormPart] = useState<
    | BaseHomeEvaluationEngineeringComplianceContinuousLoadPathFormParts
    | undefined
  >(props.formParts);

  const [evaluationFormModel, setEvaluationFormModel] =
    useState<Evaluation_HomeStandardEvaluationFormModel>();

  const { setFotifiedHomeEvaluationHeader, setDefaultHeader } =
    useContext(AppHeaderContext);

  useEffect(() => {
    if (evaluationFormModel) {
      setFotifiedHomeEvaluationHeader(evaluationFormModel);
    }
  }, [evaluationFormModel]);

  const formModel =
    evaluationFormModel?.homeStandardEvaluation
      .engineeringComplianceContinuousLoadPathForm;

  const getNextUnsubmittedFormPart = () => {
    if (!formModel) {
      return null;
    }

    if (!formModel.isContinuousLoadPathForNewConstructionComplete) {
      return BaseHomeEvaluationEngineeringComplianceContinuousLoadPathFormParts.ContinuousLoadPathForNewConstruction;
    } else if (
      !formModel.isContinuousLoadPathForRetrofitOfExistingHomeComplete
    ) {
      return BaseHomeEvaluationEngineeringComplianceContinuousLoadPathFormParts.ContinuousLoadPathForRetrofitOfExistingHome;
    }

    // Form is complete
    return null;
  };

  const getBestFormPart =
    (): BaseHomeEvaluationEngineeringComplianceContinuousLoadPathFormParts => {
      // Default to the first part of the form if we can't find a better option
      const defaultFormPart = Object.values(
        BaseHomeEvaluationEngineeringComplianceContinuousLoadPathFormParts
      )[0];

      // If the application form part is provided (by URL), use it
      if (!!props.formParts) {
        return props.formParts;
      }

      // Otherwise, try to find the first unsubmitted part of the form
      // TODO: This doesn't work yet, needs to wait for applicationFormModel to load
      return getNextUnsubmittedFormPart() ?? defaultFormPart;
    };

  const getApplicationPartChecklistStatus = (
    formPart: BaseHomeEvaluationEngineeringComplianceContinuousLoadPathFormParts
  ): FormChecklistStatus => {
    if (formPart === getNextUnsubmittedFormPart()) {
      return FormChecklistStatus.NextUp;
    }

    switch (formPart) {
      case BaseHomeEvaluationEngineeringComplianceContinuousLoadPathFormParts.ContinuousLoadPathForNewConstruction:
        return formModel?.isContinuousLoadPathForNewConstructionComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case BaseHomeEvaluationEngineeringComplianceContinuousLoadPathFormParts.ContinuousLoadPathForRetrofitOfExistingHome:
        return formModel?.isContinuousLoadPathForRetrofitOfExistingHomeComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      default:
        return FormChecklistStatus.Unsubmitted;
    }
  };

  const checklistParts: FormChecklistPart<BaseHomeEvaluationEngineeringComplianceContinuousLoadPathFormParts>[] =
    [
      {
        formPart:
          BaseHomeEvaluationEngineeringComplianceContinuousLoadPathFormParts.ContinuousLoadPathForNewConstruction,
        displayName: "Continuous Load Path For New Construction",
        requiredFieldCount:
          formModel?.continuousLoadPathForNewConstructionRequiredFieldCount,
        hidden: shouldHideEngineeringCLPForNewConstructionFields(
          evaluationFormModel?.homeStandardEvaluation.eligibilityForm
            .continuousLoadPathPathwayType
        ),
      },
      {
        formPart:
          BaseHomeEvaluationEngineeringComplianceContinuousLoadPathFormParts.ContinuousLoadPathForRetrofitOfExistingHome,
        displayName: "Continuous Load Path For Retrofit Of Existing Home",
        requiredFieldCount:
          formModel?.continuousLoadPathForRetrofitOfExistingHomeRequiredFieldCount,
        hidden: shouldHideEngineeringCLPForRetrofitOfExistingHomeFields(
          evaluationFormModel?.homeStandardEvaluation.eligibilityForm
            .continuousLoadPathPathwayType
        ),
      },
    ];

  return {
    applicationChecklistParts: checklistParts,
    getApplicationPartChecklistStatus,
    setEvaluationFormModel,
    setHasUnsavedChanges,
    formPart,
    getBestFormPart,
    schemaFactory,
  };
}

export default useHomeStandardEngineeringComplianceContinuousLoadPathFormContainer;
