import { FieldSchemaFormBuilderV2, SchemaFactoryV2 } from "@ucl/library";

import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import get from "lodash/get";
import {
  WildfireBoxContentExplorer,
  WildfireBoxContentExplorerProps,
} from "../../../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import { Evaluation_CommercialRedesignationFormModel } from "../../../types/CommercialRedesignationFormModel";
import { permissionStore } from "../../../../../../foritfied/stores/PermissionStore";

const modelName = "Evaluation";
const subModelName = "CommercialRedesignation";
const formName = "ComplianceLetterForm";

const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

const fileUploaderFields = (
  _form: Evaluation_CommercialRedesignationFormModel,
  errors?: { [key: string]: string[] }
) => [
  {
    fieldKey: buildFieldKey("ComplianceLetterPhotoUploader"),
    defaultToUpload: false,
    errorMessages: get(errors, "complianceLetterPhotoUploader.FileCount"),
    canDelete: permissionStore.canDeleteFilesFromSensitiveUploaders,
  } as WildfireBoxContentExplorerProps,
];

const complianceLetterPhotoUploaderRequiredFields = [
  buildFieldKey("ComplianceLetterPhotoUploader"),
];

export const commerciaRedesignationlComplianceLetterBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_CommercialRedesignationFormModel> => {
  return ((onValueChange, form, errors) => {
    return factory.buildFieldSchemas<Evaluation_CommercialRedesignationFormModel>(
      modelName,
      [...fileUploaderFields(form, errors)],
      onValueChange,
      form,
      errors,
      [...complianceLetterPhotoUploaderRequiredFields],
      {
        FileUploader: WildfireBoxContentExplorer,
      }
    );
  }) as FieldSchemaFormBuilderV2<Evaluation_CommercialRedesignationFormModel>;
};

export const commercialRedesignationIterationEngineComplianceLetterBuilder = (
  factory: SchemaFactoryV2,
  beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
): FieldSchemaFormBuilderV2<Evaluation_CommercialRedesignationFormModel> => {
  return ((onValueChange, form, errors) => {
    let fields = [
      ...factory.buildFieldSchemas<Evaluation_CommercialRedesignationFormModel>(
        modelName,
        [...fileUploaderFields(form, errors)],
        onValueChange,
        form,
        errors,
        [...complianceLetterPhotoUploaderRequiredFields],
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    if (beforeBuild) {
      fields = beforeBuild(fields);
    }
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_CommercialRedesignationFormModel>;
};
