import classNames from "classnames";
import { fortifiedHomeRoutePrefix } from "../../foritfied/pages/index/fortifiedHomeRouteConfig";
import variables from "../config/variables";
import { wildfireRoutePrefix } from "../../wildfire/pages/index/wildfireRouteConfig";
import { fortifiedCommercialMultifamilyRoutePrefix } from "../../foritfied/pages/index/fortifiedCommercialMultifamilyRouteConfig";
import ProductTypeNavigator from "./ProductTypeNavigator";
import WildfirePages from "../../wildfire/pages/index/index";
import { FCFMFProductTypeHandler } from "./FCFMFProductTypeHandler";
import { FHProductTypeHandler } from "./FHProductTypeHandler";

export const productTypeRouteConfig = [
  {
    pathPrefix: `${wildfireRoutePrefix}`,
    component: <WildfirePages />,
  },
  {
    pathPrefix: `${fortifiedHomeRoutePrefix}`,
    component: (
      <div
        className={classNames(
          "app-container",
          variables.theme ? `theme-${variables.theme}` : ""
        )}
      >
        <FHProductTypeHandler />
      </div>
    ),
  },
  {
    pathPrefix: `${fortifiedCommercialMultifamilyRoutePrefix}`,
    component: (
      <div
        className={classNames(
          "app-container",
          variables.theme ? `theme-${variables.theme}` : ""
        )}
      >
        <FCFMFProductTypeHandler />
      </div>
    ),
  },
  {
    pathPrefix: `/`,
    component: <ProductTypeNavigator />,
  },
];
