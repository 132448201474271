import baseApiClient from "../../foritfied/lib/apiClients/baseApiClient";
import { AccessToken } from "../types/accessTokens";

class AccessTokensApiClient {
  createExternalRooferFormsAccessToken = async (
    evaluationId: string
  ): Promise<AccessToken> => {
    return baseApiClient.post<AccessToken>(
      `access-token/external-roofer-forms/${evaluationId}`
    );
  };

  createExternalEngineerFormsAccessToken = async (
    evaluationId: string
  ): Promise<AccessToken> => {
    return baseApiClient.post<AccessToken>(
      `access-token/external-engineer-forms/${evaluationId}`
    );
  };

  createExternalContractorFormsAccessToken = async (
    evaluationId: string
  ): Promise<AccessToken> => {
    return baseApiClient.post<AccessToken>(
      `access-token/external-contractor-forms/${evaluationId}`
    );
  };
}

export const accessTokensApiClient = new AccessTokensApiClient();
