import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import baseExternalApiClient from "../../../../../foritfied/lib/apiClients/baseExternalApiClient";
import { BaseHomeRoofingComplianceRoofCoverFormParts } from "../../../common/types/BaseHomeEvaluationRoofingComplianceRoofCoverFormModel";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../types/HomeEvaluationFormModel";
import { HomeStandardEvaluationRoofingComplianceRoofCoverFormModel } from "../../types/HomeStandardEvaluationRoofingComplianceRoofCoverFormModel";

class HomeStandardEvaluationRoofingComplianceRoofCoverAPIClient {
  updateHomeStandardRoofingComplianceRoofCoverForm = async (
    evaluationId: string,
    roofingComplianceRoofCoverForm: HomeStandardEvaluationRoofingComplianceRoofCoverFormModel,
    fieldKey?: string
  ): Promise<Evaluation_HomeStandardEvaluationFormModel> => {
    return baseApiClient.put<Evaluation_HomeStandardEvaluationFormModel>(
      `/home-standard/roofing-compliance-roof-cover-form/${evaluationId}`,
      {
        roofingComplianceRoofCoverForm,
        fieldKey,
      }
    );
  };

  updateHomeStandardRoofingComplianceRoofCoverFormExternal = async (
    evaluationId: string,
    roofingComplianceRoofCoverForm: HomeStandardEvaluationRoofingComplianceRoofCoverFormModel,
    accessToken: string
  ): Promise<Evaluation_HomeStandardEvaluationFormModel> => {
    return baseExternalApiClient.put<Evaluation_HomeStandardEvaluationFormModel>(
      `/home-standard/roofing-compliance-roof-cover-form/external/${evaluationId}`,
      {
        roofingComplianceRoofCoverForm,
      },
      {
        headers: {
          AccessToken: accessToken,
        },
      }
    );
  };

  submitHomeStandardRoofingComplianceRoofCoverForm = async (
    evaluationId: string,
    form: HomeStandardEvaluationRoofingComplianceRoofCoverFormModel,
    formPart?: BaseHomeRoofingComplianceRoofCoverFormParts
  ): Promise<Evaluation_HomeStandardEvaluationFormModel> => {
    // If applicationFormPart is not provided, default to the last part of the form
    const formPartValue = (
      formPart ??
      (Object.values(
        BaseHomeRoofingComplianceRoofCoverFormParts
      ).pop() as BaseHomeRoofingComplianceRoofCoverFormParts)
    ).valueOf();
    return baseApiClient.post<Evaluation_HomeStandardEvaluationFormModel>(
      `/home-standard/roofing-compliance-roof-cover-form/${evaluationId}/${formPartValue}`,
      form
    );
  };

  submitHomeStandardRoofingComplianceRoofCoverFormExternal = async (
    evaluationId: string,
    form: HomeStandardEvaluationRoofingComplianceRoofCoverFormModel,
    accessToken: string
  ): Promise<Evaluation_HomeStandardEvaluationFormModel> => {
    // Always default to the last part of the form
    const formPartValue = (
      Object.values(
        BaseHomeRoofingComplianceRoofCoverFormParts
      ).pop() as BaseHomeRoofingComplianceRoofCoverFormParts
    ).valueOf();
    return baseExternalApiClient.post<Evaluation_HomeStandardEvaluationFormModel>(
      `/home-standard/roofing-compliance-roof-cover-form/external/${evaluationId}/${formPartValue}`,
      form,
      {
        headers: {
          AccessToken: accessToken,
        },
      }
    );
  };
}

export const homeStandardEvaluationRoofingComplianceRoofCoverAPIClient =
  new HomeStandardEvaluationRoofingComplianceRoofCoverAPIClient();
