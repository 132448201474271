import { Button, Icon } from "@blueprintjs/core";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { Observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { reactPlugin } from "../../AppInsights";
import { useFortifiedGridViewModule } from "../../common/customHooks/useFortifiedGridViewModule";
import { FortifiedGridFiltersModule } from "../components/FortifiedGridFiltersModule/FortifiedGridFiltersModule";
import { FortifiedGridViewPicker } from "../components/FortifiedGridViewsModule/FortifiedGridViewPicker";
import { Header } from "../components/Header/Header";
import { IterationEngineActions } from "../components/IterationEngine/IterationEngineActions";
import { PageSidebar } from "../components/PageSidebar/PageSidebar";
import { getGridColumnsConfigurationByRole } from "../lib/gridColumnConfigurations/boardColumnsConfiguration";
import { getGridDefaultViewByRole } from "../lib/gridColumnConfigurations/boardViewsConfiguration";
import { dialogsViewerStore } from "../stores/DialogsViewerStore";
import { evaluationStore } from "../stores/EvaluationStore";
import { fortifiedGridViewModuleStore } from "../stores/FortifiedGridViewModuleStore";
import { permissionStore } from "../stores/PermissionStore";
import { EvaluationReadDTO } from "../types/dtos/EvaluationReadDTO";
import {
  EvaluationStep_AsString,
  getStepAsString,
  ProductTypes,
  ProductTypes_AsString,
} from "../types/evaluation/Evaluation";
import "./styles.scss";
import { useProductTypeHandler } from "../providers/ProductTypeHandlerProvider";
import { Dialogs } from "@ucl/library";
import { openCreateFCFMFEvaluationDialog } from "../../fortifiedV2/FCFMF/common/components/Dialogs/CreateFCFMFEvaluation/CreateFCFMFEvaluationDialog";
import { openCreateHomeEvaluationDialog } from "../../fortifiedV2/Home/common/components/Dialogs/CreateHomeEvaluation/Dialogs/CreateFCFMFEvaluation/CreateHomeEvaluationDialog";
import { fortifiedCommercialMultifamilyRoutePrefix } from "./index/fortifiedCommercialMultifamilyRouteConfig";
import { fortifiedHomeRoutePrefix } from "./index/fortifiedHomeRouteConfig";
import { featureToggleApiClient } from "../../common/Components/FeatureToggle/class/featureToggleApiClient";
import { FeatureToggleSet } from "../../common/Components/FeatureToggle/FeatureToggles";
import { FortifiedGridMetrics } from "../components/FortifiedGrid/FortifiedGridMetrics";
import { FortifiedEvaluationMetricReadDTO } from "../types/dtos/FortifiedEvaluationMetricReadDTO";
import { SwitchComponent } from "@syncfusion/ej2-react-buttons";
import { IconNames } from "@blueprintjs/icons";
import { FortifiedGrid } from "../components/FortifiedGrid/FortifiedGrid";
import variables from "../../common/config/variables";
import { fortifiedUserStore } from "../stores/FortifiedUserStore";
import { evaluationApiClient } from "../lib/apiClients/evaluation/evaluationApiClient";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import { ApplicationOverviewCardContainer } from "../../fortifiedV2/Common/components/ApplicationOverviewCards/ApplicationOverviewCardContainer";

// Component to display empty state for new commercial/multifamily users with no applications
const EmptyStateView: React.FC<{
  onCreateNew: () => void;
  isFormstackMigration: boolean | undefined;
}> = ({ onCreateNew, isFormstackMigration }) => {
  return (
    <div className="create-fortified-application-container">
      <Button
        minimal
        className="create-fortified-application-button"
        icon={IconNames.Plus}
        onClick={onCreateNew}
      >
        <div className="text-content">
          Click here to start your first{" "}
          {isFormstackMigration ? "New Application V2" : "New Application"}
        </div>
      </Button>
    </div>
  );
};

export interface BoardProps {}

const BoardComponent: React.FC<BoardProps> = () => {
  // Manage component state for initialization, loading, and initial data check.
  const [featureToggles, setFeatureToggles] = useState<FeatureToggleSet>();
  const [isInitialized, setIsInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const hasFCFMFApplicantAnyEvaluations = useRef(false);

  const navigate = useNavigate();
  const { routePrefix } = useProductTypeHandler();

  const isFCFMFApplicant =
    fortifiedUserStore.hasRole("FC/FMF Applicant") &&
    evaluationStore.getProductTypeByUserRole === "commercial";

  // Determine if empty state should be shown based on user type and data
  const shouldShowEmptyState = () => {
    if (isLoading || !isInitialized) return false;

    return (
      isFCFMFApplicant &&
      permissionStore.canCreateNewEvaluation &&
      !hasFCFMFApplicantAnyEvaluations.current
    );
  };

  // Initialize feature toggles and fetch initial evaluations data
  useEffect(() => {
    const initialize = async () => {
      setIsLoading(true);
      try {
        // Get feature toggles
        const toggles = await featureToggleApiClient.getFeatureToggleSet();
        setFeatureToggles(toggles);

        // Initial evaluations fetch just to determine empty state
        if (isFCFMFApplicant) {
          const data =
            await evaluationApiClient.getFCFMFApplicantAnyEvaluations();
          hasFCFMFApplicantAnyEvaluations.current = data;
        }
      } catch (error) {
        console.error("Error during initialization:", error);
      } finally {
        setIsLoading(false);
        setIsInitialized(true);
      }
    };

    initialize();

    if (permissionStore.canToggleGridToCardView) {
      const savedView = localStorage.getItem("cardView");
      if (savedView !== null) {
        const parsedValue = JSON.parse(savedView);
        if (parsedValue !== evaluationStore.isCardViewInternal) {
          evaluationStore.setCardViewToggle(parsedValue);
        }
      }
    }
  }, []);

  const shouldNavigateToChecklistPage = (
    productType: string,
    evaluationStatus: string
  ) => {
    const inChecklistStatus =
      evaluationStatus === EvaluationStep_AsString.Application ||
      evaluationStatus === EvaluationStep_AsString.ApplicationForms ||
      evaluationStatus === EvaluationStep_AsString.Payment;
    return (
      productType === ProductTypes_AsString.FortifiedHome && inChecklistStatus
    );
  };

  const shouldNavigateToChecklistPageForCardView = (
    productType: number,
    evaluationStep: number
  ) => {
    const inChecklistSteps = [
      1, // ApplicationInitiated
      8, // ApplicationForms
      9, // Payment
    ];

    return (
      productType === ProductTypes.FortifiedHome &&
      inChecklistSteps.includes(evaluationStep)
    );
  };

  const shouldNaviateToComplexEvaluation = (rowData: EvaluationReadDTO) => {
    return (
      rowData.isComplexCommercialEvaluationBuilding === true &&
      !!rowData.complexCommercialAndMultifamilyEvaluationId
    );
  };

  const getIterationEngineRoute = (evaluationId: string) => {
    return `${routePrefix}/evaluation/${evaluationId}`;
  };

  const goToEvaluation = (rowData: EvaluationReadDTO) => {
    const route = getRoute(rowData);
    navigate(route);
  };

  const getRoute = (rowData: EvaluationReadDTO) => {
    if (rowData.isFortifiedVersionTwo) {
      return getFortifiedV2Route(rowData);
    }

    if (shouldNavigateToChecklistPage(rowData.productType, rowData.step)) {
      return `${routePrefix}/checklist?fortifiedId=${rowData.fortifiedId}`;
    }

    if (shouldNaviateToComplexEvaluation(rowData)) {
      return getIterationEngineRoute(
        rowData.complexCommercialAndMultifamilyEvaluationId
      );
    }

    return getIterationEngineRoute(rowData.id);
  };

  const getFortifiedV2Route = (rowData: EvaluationReadDTO) => {
    if (
      rowData.evaluationProductType &&
      rowData.evaluationProductType !== "None"
    ) {
      return getFortifiedV2RouteByEvaluationProductType(rowData);
    }

    return getFortifiedV2RouteByProductTypeFallback(rowData);
  };

  const getFortifiedV2RouteByEvaluationProductType = (
    rowData: EvaluationReadDTO
  ) => {
    switch (rowData.evaluationProductType) {
      case "FortifiedCommercial":
        if (rowData.step === EvaluationStep_AsString.Application) {
          return `${fortifiedCommercialMultifamilyRoutePrefix}/commercial/${rowData.id}/application-form`;
        } else {
          return `${fortifiedCommercialMultifamilyRoutePrefix}/commercial/iteration-engine/${rowData.id}`;
        }
      case "FortifiedMultifamily":
        if (rowData.step === EvaluationStep_AsString.Application) {
          return `${fortifiedCommercialMultifamilyRoutePrefix}/multifamily/${rowData.id}/application-form`;
        } else {
          return `${fortifiedCommercialMultifamilyRoutePrefix}/multifamily/iteration-engine/${rowData.id}`;
        }
      case "FortifiedHome":
        if (
          rowData.step === EvaluationStep_AsString.IBHSMilestoneReview ||
          rowData.step === EvaluationStep_AsString.FinalReview
        ) {
          return `${fortifiedHomeRoutePrefix}/standard/iteration-engine/${rowData.id}`;
        } else {
          return `${fortifiedHomeRoutePrefix}/standard/checklist/${rowData.id}`;
        }
      case "FortifiedCommercialRedesignation":
        return `${fortifiedCommercialMultifamilyRoutePrefix}/commercial-redesignation/iteration-engine/${rowData.id}`;
      case "FortifiedMultifamilyRedesignation":
        return `${fortifiedCommercialMultifamilyRoutePrefix}/multifamily-redesignation/iteration-engine/${rowData.id}`;
      case "FortifiedHomeRedesignation":
        if (rowData.step === EvaluationStep_AsString.FinalReview) {
          return `${fortifiedHomeRoutePrefix}/standard-redesignation/iteration-engine/${rowData.id}`;
        } else {
          return `${fortifiedHomeRoutePrefix}/standard-redesignation/checklist/${rowData.id}`;
        }
      case "FortifiedHomeNCIUA":
        if (
          rowData.step === EvaluationStep_AsString.IBHSMilestoneReview ||
          rowData.step === EvaluationStep_AsString.FinalReview
        ) {
          return `${fortifiedHomeRoutePrefix}/nciua/iteration-engine/${rowData.id}`;
        } else {
          return `${fortifiedHomeRoutePrefix}/nciua/checklist/${rowData.id}`;
        }
      case "FortifiedHomeSCSH":
        if (
          rowData.step === EvaluationStep_AsString.IBHSMilestoneReview ||
          rowData.step === EvaluationStep_AsString.FinalReview
        ) {
          return `${fortifiedHomeRoutePrefix}/scsh/iteration-engine/${rowData.id}`;
        } else {
          return `${fortifiedHomeRoutePrefix}/scsh/checklist/${rowData.id}`;
        }
      default:
        return "";
    }
  };

  const getFortifiedV2RouteByProductTypeFallback = (
    rowData: EvaluationReadDTO
  ) => {
    switch (rowData.productType) {
      case "FortifiedCommercial":
        if (rowData.isRedesignation) {
          return `${fortifiedCommercialMultifamilyRoutePrefix}/commercial-redesignation/iteration-engine/${rowData.id}`;
        } else {
          if (rowData.step === EvaluationStep_AsString.Application) {
            return `${fortifiedCommercialMultifamilyRoutePrefix}/commercial/${rowData.id}/application-form`;
          } else {
            return `${fortifiedCommercialMultifamilyRoutePrefix}/commercial/iteration-engine/${rowData.id}`;
          }
        }
      case "FortifiedMultifamily":
        if (rowData.isRedesignation) {
          return `${fortifiedCommercialMultifamilyRoutePrefix}/multifamily-redesignation/iteration-engine/${rowData.id}`;
        } else {
          if (rowData.step === EvaluationStep_AsString.Application) {
            return `${fortifiedCommercialMultifamilyRoutePrefix}/multifamily/${rowData.id}/application-form`;
          } else {
            return `${fortifiedCommercialMultifamilyRoutePrefix}/multifamily/iteration-engine/${rowData.id}`;
          }
        }
      case "FortifiedHome":
        if (rowData.isRedesignation) {
          if (rowData.step === EvaluationStep_AsString.FinalReview) {
            return `${fortifiedHomeRoutePrefix}/standard-redesignation/iteration-engine/${rowData.id}`;
          } else {
            return `${fortifiedHomeRoutePrefix}/standard-redesignation/checklist/${rowData.id}`;
          }
        } else {
          if (
            rowData.step === EvaluationStep_AsString.IBHSMilestoneReview ||
            rowData.step === EvaluationStep_AsString.FinalReview
          ) {
            return `${fortifiedHomeRoutePrefix}/standard/iteration-engine/${rowData.id}`;
          } else {
            return `${fortifiedHomeRoutePrefix}/standard/checklist/${rowData.id}`;
          }
        }
      default:
        return "";
    }
  };

  const goToCardEvaluation = (rowData: EvaluationReadDTO) => {
    const route = getCardRoute(rowData);
    navigate(route);
  };

  const getCardRoute = (rowData: EvaluationReadDTO) => {
    if (rowData.isFortifiedVersionTwo) {
      return getFortifiedV2RouteCardView(rowData);
    }

    if (
      shouldNavigateToChecklistPageForCardView(
        Number(rowData.productType),
        Number(rowData.step)
      )
    ) {
      return `${routePrefix}/checklist?fortifiedId=${rowData.fortifiedId}`;
    }

    if (shouldNaviateToComplexEvaluation(rowData)) {
      return getIterationEngineRoute(
        rowData.complexCommercialAndMultifamilyEvaluationId
      );
    }

    return getIterationEngineRoute(rowData.id);
  };

  const getFortifiedV2RouteCardView = (rowData: EvaluationReadDTO) => {
    const stepAsString = getStepAsString(Number(rowData.step));

    switch (Number(rowData.productType)) {
      case ProductTypes.FortifiedCommercial:
        if (rowData.isRedesignation) {
          return `${fortifiedCommercialMultifamilyRoutePrefix}/commercial-redesignation/iteration-engine/${rowData.id}`;
        } else {
          if (stepAsString === "ApplicationInitiated") {
            return `${fortifiedCommercialMultifamilyRoutePrefix}/commercial/${rowData.id}/application-form`;
          } else {
            return `${fortifiedCommercialMultifamilyRoutePrefix}/commercial/iteration-engine/${rowData.id}`;
          }
        }
      case ProductTypes.FortifiedMultifamily:
        if (rowData.isRedesignation) {
          return `${fortifiedCommercialMultifamilyRoutePrefix}/multifamily-redesignation/iteration-engine/${rowData.id}`;
        } else {
          if (stepAsString === "ApplicationInitiated") {
            return `${fortifiedCommercialMultifamilyRoutePrefix}/multifamily/${rowData.id}/application-form`;
          } else {
            return `${fortifiedCommercialMultifamilyRoutePrefix}/multifamily/iteration-engine/${rowData.id}`;
          }
        }
      case ProductTypes.FortifiedHome:
        if (rowData.isRedesignation) {
          if (stepAsString === "FinalReview") {
            return `${fortifiedHomeRoutePrefix}/standard-redesignation/iteration-engine/${rowData.id}`;
          } else {
            return `${fortifiedHomeRoutePrefix}/standard-redesignation/checklist/${rowData.id}`;
          }
        } else {
          if (
            stepAsString === "IBHSMilestoneReview" ||
            stepAsString === "FinalReview"
          ) {
            return `${fortifiedHomeRoutePrefix}/standard/iteration-engine/${rowData.id}`;
          } else {
            return `${fortifiedHomeRoutePrefix}/standard/checklist/${rowData.id}`;
          }
        }
      default:
        return "";
    }
  };
  useFortifiedGridViewModule(
    "board-grid",
    "Board",
    getGridColumnsConfigurationByRole,
    getGridDefaultViewByRole
  );

  // Main render:
  // - Shows loading state while initializing
  // - Shows empty state for new commercial/multifamily users
  // - Otherwise shows grid/card view based on user preference (toggle state)
  return (
    <div className="fortified-page board-page">
      <Dialogs />
      <Observer>
        {() => <Header renderActions={() => <IterationEngineActions />} />}
      </Observer>
      {isInitialized && (
        <div className="content">
          <PageSidebar activePage="home" />
          <div className="page-main-content">
            {!shouldShowEmptyState() && (
              <Observer>
                {() => (
                  <section className="board-toolbar">
                    {!evaluationStore.isCardView && (
                      <ul className="board-toolbar-left">
                        <li>
                          <FortifiedGridViewPicker
                            key={
                              fortifiedGridViewModuleStore.currentGridViewKey
                            }
                          />
                        </li>
                      </ul>
                    )}
                    <ul className="board-toolbar-right">
                      {!evaluationStore.isCardView && (
                        <li>
                          <FortifiedGridFiltersModule />
                        </li>
                      )}
                      {permissionStore.canToggleGridToCardView && (
                        <li className="board-toolbar-right--card-view-toggle">
                          <Icon icon={IconNames.GRID_VIEW} />
                          <SwitchComponent
                            id="view-toggle"
                            checked={evaluationStore.isCardView}
                            onChange={() => {
                              const newValue = !evaluationStore.isCardView;
                              evaluationStore.setCardViewToggle(newValue);
                            }}
                          />
                          <Icon icon={IconNames.PANEL_TABLE} />
                        </li>
                      )}
                      {featureToggles?.LegacyFortified &&
                        permissionStore.canCreateNewEvaluation && (
                          <li>
                            <Button
                              className="evaluation-button"
                              text={
                                evaluationStore.getProductTypeByUserRole ===
                                "commercial"
                                  ? "New Application"
                                  : "New Evaluation"
                              }
                              icon="add"
                              intent="primary"
                              onClick={() =>
                                dialogsViewerStore.setIsEvaluationCreationDialog(
                                  true
                                )
                              }
                            />
                          </li>
                        )}
                      {featureToggles?.FormstackMigration &&
                        permissionStore.canCreateNewEvaluation &&
                        evaluationStore.getProductTypeByUserRole ===
                          "commercial" && (
                          <li>
                            <Button
                              text={"New Application V2"}
                              icon="add"
                              intent="primary"
                              onClick={() => openCreateFCFMFEvaluationDialog()}
                            />
                          </li>
                        )}
                      {featureToggles?.FormstackMigration &&
                        permissionStore.canCreateNewEvaluation &&
                        evaluationStore.getProductTypeByUserRole === "home" && (
                          <li>
                            <Button
                              text={"New Evaluation V2"}
                              icon="add"
                              intent="primary"
                              onClick={() => openCreateHomeEvaluationDialog()}
                            />
                          </li>
                        )}
                    </ul>
                  </section>
                )}
              </Observer>
            )}

            <Observer>
              {() => (
                <section className="board-grid-container">
                  {!isInitialized || isLoading ? (
                    <Loading />
                  ) : shouldShowEmptyState() ? (
                    <EmptyStateView
                      onCreateNew={() =>
                        featureToggles?.FormstackMigration
                          ? openCreateFCFMFEvaluationDialog()
                          : dialogsViewerStore.setIsEvaluationCreationDialog(
                              true
                            )
                      }
                      isFormstackMigration={featureToggles?.FormstackMigration}
                    />
                  ) : (
                    <>
                      {evaluationStore.isCardView ? (
                        <div className="fortified-application-overview-cards-container">
                          {!!fortifiedGridViewModuleStore.currentGridView && (
                            <ApplicationOverviewCardContainer
                              onNavigateToEvaluation={goToCardEvaluation}
                            />
                          )}
                        </div>
                      ) : (
                        <>
                          {!!fortifiedGridViewModuleStore.currentGridView && (
                            <FortifiedGrid<EvaluationReadDTO>
                              key={
                                fortifiedGridViewModuleStore.currentGridViewKey
                              }
                              dataSource={`${
                                variables.apiBaseUrl as string
                              }odata/evaluationodatagrid`}
                              columnsConfiguration={[
                                ...fortifiedGridViewModuleStore.currentGridView
                                  .columnConfiguration,
                              ]}
                              filterConfiguration={[
                                ...(fortifiedGridViewModuleStore.currentGridView
                                  .filterConfiguration || []),
                              ]}
                              sortConfiguration={[
                                ...(fortifiedGridViewModuleStore.currentGridView
                                  .sortConfiguration || []),
                              ]}
                              gridConfiguration={{
                                allowTextWrap: true,
                                textWrapSettings: { wrapMode: "Header" },
                                defaultSort: {
                                  field: "createdAt",
                                  direction:
                                    fortifiedUserStore.isAuditorTrainee ||
                                    fortifiedGridViewModuleStore.currentGridView
                                      .title === "Eligibility Reviews"
                                      ? "Ascending"
                                      : "Descending",
                                },
                                exportFilename: "Evaluations.xlsx",
                                onRecordClick: ({ rowData }) => {
                                  goToEvaluation(rowData);
                                },
                              }}
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                </section>
              )}
            </Observer>

            {!evaluationStore.isCardView &&
              featureToggles?.NewGridMetrics &&
              !permissionStore.canToggleGridToCardView && (
                <section className="board-metrics-container">
                  <Observer>
                    {() => (
                      <>
                        {!!fortifiedGridViewModuleStore.currentGridView && (
                          <FortifiedGridMetrics<FortifiedEvaluationMetricReadDTO>
                            gridKey="board-grid"
                            url={`evaluation`}
                            columns={4}
                            metricConfiguration={
                              fortifiedGridViewModuleStore.currentGridView
                                ?.metricConfiguration
                            }
                            gridViewTitle={
                              fortifiedGridViewModuleStore.currentGridView
                                ?.title
                            }
                            updateViewMetricSettings={
                              fortifiedGridViewModuleStore.updateViewMetricSettings
                            }
                            viewId={
                              fortifiedGridViewModuleStore.currentGridView.id
                            }
                            showMetricViewPicker={true}
                            minimal={true}
                            languageMapping={{
                              evaluationCount: "Evaluation Count",
                              completedEvaluationsThisWeek:
                                "Completed Evaluations This Week",
                              redesignationsComingDueInSixMonths:
                                "Redesignations Coming Due in 6 Months",
                              auditQueueCount: "Audit Queue Count",
                              auditQueueAgedUpToThreeDays:
                                "Audit Queue Aged < 3 Days",
                              auditQueueAgedFourToSevenDays:
                                "Audit Queue Aged 4-7 Days",
                              auditQueueAgedEightToFourteenDays:
                                "Audit Queue Aged 8-14 Days",
                              auditQueueAgedOverFourteenDays:
                                "Audit Queue Aged > 14 Days",
                              auditQueueOldestAge:
                                "Audit Queue Oldest Submission",
                              statusApplicationCount:
                                "Application Status Count",
                              statusIBHSEligibilityReviewCount:
                                "Eligibility Review Status Count",
                              statusChangesRequiredCount:
                                "Changes Required Status Count",
                              statusReadyForReReviewCount:
                                "Ready For Re-Review Status Count",
                              statusEvaluatorAssignmentCount:
                                "Evaluator Assignment Status Count",
                              statusComplianceFormsCount:
                                "Compliance Forms Status Count",
                              statusFieldInspectionsCount:
                                "Field Inspections Status Count",
                              statusComplianceLetterCount:
                                "Compliance Letter Status Count",
                              statusIBHSReviewCount: "IBHS Review Status Count",
                              statusApplicationFormsCount:
                                "Application Forms Status Count",
                              statusPaymentCount: "Payment Status Count",
                              statusApplicationFeeCount:
                                "Application Fee Status Count",
                              statusCertificateFeeCount:
                                "Certificate Fee Status Count",
                              statusApprovedCount: "Approved Status Count",
                              statusNotApprovedCount:
                                "Not Approved Status Count",
                              statusWithdrawnCount: "Withdrawn Status Count",
                              specialProjectNCIUASYRCount:
                                "NCIUA/NCJUA SYR Count",
                              specialProjectNCIUASYCRCount:
                                "NCIUA/NCJUA SYCR Count",
                              specialProjectNCIUAClaimCount:
                                "NCIUA/NCJUA Claim Count",
                              specialProjectStrengthenALHomesCount:
                                "Strengthen AL Homes Count",
                              specialProjectSCSafeHomeCount:
                                "SC Safe Home Count",
                              specialProjectLDIRoofGrantCount:
                                "LDI Roof Grant Count",
                              specialProjectFannieMaeCount: "Fannie Mae Count",
                            }}
                          />
                        )}
                      </>
                    )}
                  </Observer>
                </section>
              )}
          </div>
        </div>
      )}
    </div>
  );
};

export const Board = withAITracking(
  reactPlugin,
  BoardComponent,
  "Board",
  "tracking-container"
);
