import { fieldTypes, UsStates } from "@ucl/library";
import variables from "../../../../common/config/variables";
import { ExternalApiClient } from "../baseExternalApiClient";

class AddressApiClient {
  getFilteredAddressesByQuery = (
    address: Partial<fieldTypes.AddressFieldValue>
  ) => {
    const apiClient = new ExternalApiClient(
      `${variables.apiBaseUrl as string}`
    );
    return apiClient.post<fieldTypes.AddressFieldValue[]>(
      `address/many`,
      new URLSearchParams({
        ...(address.street && { street: address.street }),
        ...(address.street2 && { street2: address.street2 }),
        ...(address.city && { city: address.city }),
        ...(address.state && { state: address.state }),
        ...(address.zip && { zip: address.zip }),
        stateFilter: [UsStates.CA, UsStates.OR].join(","),
      }),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
  };

  getAddressesByQuery = (address: Partial<fieldTypes.AddressFieldValue>) => {
    const apiClient = new ExternalApiClient(
      `${variables.apiBaseUrl as string}`
    );
    return apiClient.post<fieldTypes.AddressFieldValue[]>(
      `address/many`,
      new URLSearchParams({
        ...(address.street && { street: address.street }),
        ...(address.street2 && { street2: address.street2 }),
        ...(address.city && { city: address.city }),
        ...(address.state && { state: address.state }),
        ...(address.zip && { zip: address.zip }),
      }),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
  };

  getAddressByQuery = (queryAddress: fieldTypes.AddressFieldValue) => {
    const apiClient = new ExternalApiClient(
      `${variables.apiBaseUrl as string}`
    );

    return apiClient.post<fieldTypes.AddressFieldValue>(
      "address/one",
      new URLSearchParams({
        ...(queryAddress.street && { street: queryAddress.street }),
        ...(queryAddress.street2 && { street2: queryAddress.street2 }),
        ...(queryAddress.city && { city: queryAddress.city }),
        ...(queryAddress.state && { state: queryAddress.state }),
        ...(queryAddress.zip && { zip: queryAddress.zip }),
      }),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
  };
}

export const addressApiClient = new AddressApiClient();
