import React, { ReactNode, createContext, useState } from "react";
import { WildfireEvaluation } from "../../types/WildfireApplication/WildfireApplication";
import { EvaluationFormModel } from "../../forms/EvaluationFormModel";
import WildfireAppHeader from "../Header/WildfireAppHeader";
import { Evaluation_HomeStandardEvaluation } from "../../../fortifiedV2/Home/standard/types/HomeEvaluation";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../../fortifiedV2/Home/standard/types/HomeEvaluationFormModel";
import { ProductTypes } from "../../../common/types/ProductTypes";
import { Evaluation_HomeNCIUAEvaluation } from "../../../fortifiedV2/Home/nciua/evaluation/types/HomeNCIUAEvaluation";
import { Evaluation_HomeNCIUAEvaluationFormModel } from "../../../fortifiedV2/Home/nciua/evaluation/types/HomeNCIUAEvaluationFormModel";
import { Evaluation_HomeSCSHEvaluation } from "../../../fortifiedV2/Home/scsh/types/HomeSCSHEvaluation";
import { Evaluation_HomeSCSHEvaluationFormModel } from "../../../fortifiedV2/Home/scsh/types/HomeSCSHEvaluationFormModel";
import { Evaluation_HomeStandardRedesignationFormModel } from "../../../fortifiedV2/Home/standardRedesignation/types/HomeStandardRedesignationFormModel_Model";
import { Evaluation_HomeStandardRedesignation } from "../../../fortifiedV2/Home/standardRedesignation/types/HomeStandardRedesignation";
import { Evaluation_HomeNCIUARedesignation } from "../../../fortifiedV2/Home/nciuaRedesignation/evaluation/types/HomeNCIUARedesignation";
import { Evaluation_HomeNCIUARedesignationFormModel } from "../../../fortifiedV2/Home/nciuaRedesignation/evaluation/types/HomeNCIUARedesignationFormModel_Model";
import FortifiedHomeAppHeader from "../../../fortifiedV2/Home/common/components/Header/FortifiedHomeAppHeader";
import { Evaluation_CommercialEvaluation } from "../../../fortifiedV2/FCFMF/commercial/types/CommercialEvaluation";
import { Evaluation_MultifamilyEvaluation } from "../../../fortifiedV2/FCFMF/multifamily/types/MultifamilyEvaluation";
import FCFMFAppHeader from "../../../fortifiedV2/FCFMF/common/components/Header/FCFMFAppHeader";
import { Evaluation_CommercialEvaluationFormModel } from "../../../fortifiedV2/FCFMF/commercial/types/CommercialEvaluationFormModel";
import { Evaluation_MultifamilyEvaluationFormModel } from "../../../fortifiedV2/FCFMF/multifamily/types/MultifamilyEvaluationFormModel";
import { Evaluation_CommercialRedesignation } from "../../../fortifiedV2/FCFMF/commercialRedesignation/types/CommercialRedesignation";
import { Evaluation_CommercialRedesignationFormModel } from "../../../fortifiedV2/FCFMF/commercialRedesignation/types/CommercialRedesignationFormModel";
import { Evaluation_MultifamilyRedesignation } from "../../../fortifiedV2/FCFMF/multifamilyRedesignation/types/MultifamilyRedesignation";
import { Evaluation_MultifamilyRedesignationFormModel } from "../../../fortifiedV2/FCFMF/multifamilyRedesignation/types/MultifamilyRedesignationFormModel";

type FortifiedHomeEvaluationType =
  | Evaluation_HomeStandardEvaluation
  | Evaluation_HomeStandardEvaluationFormModel
  | Evaluation_HomeStandardRedesignationFormModel
  | Evaluation_HomeStandardRedesignation
  | Evaluation_HomeNCIUAEvaluation
  | Evaluation_HomeNCIUAEvaluationFormModel
  | Evaluation_HomeNCIUARedesignation
  | Evaluation_HomeNCIUARedesignationFormModel
  | Evaluation_HomeSCSHEvaluation
  | Evaluation_HomeSCSHEvaluationFormModel;

type FCFMFEvaluationType =
  | Evaluation_CommercialEvaluation
  | Evaluation_CommercialEvaluationFormModel
  | Evaluation_MultifamilyEvaluation
  | Evaluation_MultifamilyEvaluationFormModel
  | Evaluation_CommercialRedesignation
  | Evaluation_CommercialRedesignationFormModel
  | Evaluation_MultifamilyRedesignation
  | Evaluation_MultifamilyRedesignationFormModel;

interface AppHeaderContextProps {
  appHeader: ReactNode;
  setWildfireEvaluationHeader: (
    wildfireEvaluation: WildfireEvaluation | EvaluationFormModel
  ) => void;
  setFotifiedHomeEvaluationHeader: (
    homeEvaluation: FortifiedHomeEvaluationType
  ) => void;
  setFCFMFEvaluationHeader: (evaluation: FCFMFEvaluationType) => void;
  setDefaultHeader: () => void;
}

const defaultValue: AppHeaderContextProps = {
  appHeader: "",
  setWildfireEvaluationHeader: () => {
    throw new Error("Function not implemented.");
  },
  setFotifiedHomeEvaluationHeader: () => {
    throw new Error("Function not implemented.");
  },
  setFCFMFEvaluationHeader: () => {
    throw new Error("Function not implemented.");
  },
  setDefaultHeader: () => {
    throw new Error("Function not implemented.");
  },
};

export const AppHeaderContext =
  createContext<AppHeaderContextProps>(defaultValue);

export const AppHeaderProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [currentProductType, setCurrentProductType] =
    useState<ProductTypes | null>(null);
  const [appHeader, setAppHeader] = useState<ReactNode>("");

  const setDefaultHeader = () => {
    if (currentProductType) {
      const headerMap = {
        [ProductTypes.FORTIFIEDHome]: "Fortified Home",
        [ProductTypes.FORTIFIEDCommercial]: "Fortified Commercial",
        [ProductTypes.FORTIFIEDMultifamily]: "Fortified Multifamily",
        [ProductTypes.WildFirePrepared]: "Wildfire Prepared",
      };
      setAppHeader(headerMap[currentProductType] || "");
    }
  };

  const setWildfireEvaluationHeader = (
    wildfireEvaluation: WildfireEvaluation | EvaluationFormModel
  ) => {
    setCurrentProductType(ProductTypes.WildFirePrepared);
    setAppHeader(<WildfireAppHeader wildfireEvaluation={wildfireEvaluation} />);
  };

  const setFotifiedHomeEvaluationHeader = (
    homeEvaluation: FortifiedHomeEvaluationType
  ) => {
    setCurrentProductType(ProductTypes.FORTIFIEDHome);
    setAppHeader(<FortifiedHomeAppHeader homeEvaluation={homeEvaluation} />);
  };

  const setFCFMFEvaluationHeader = (evaluation: FCFMFEvaluationType) => {
    if (evaluation.productType_AsString === "FORTIFIED Commercial") {
      setCurrentProductType(ProductTypes.FORTIFIEDCommercial);
    } else if (evaluation.productType_AsString === "FORTIFIED Multifamily") {
      setCurrentProductType(ProductTypes.FORTIFIEDMultifamily);
    }
    setAppHeader(<FCFMFAppHeader evaluation={evaluation} />);
  };

  return (
    <AppHeaderContext.Provider
      value={{
        appHeader,
        setWildfireEvaluationHeader,
        setFotifiedHomeEvaluationHeader,
        setFCFMFEvaluationHeader,
        setDefaultHeader,
      }}
    >
      {children}
    </AppHeaderContext.Provider>
  );
};
