import { useContext, useEffect, useState } from "react";
import { UnsavedChangesBlockerContext } from "../../../../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import {
  FormChecklistPart,
  FormChecklistStatus,
} from "../../../../../../../common/types/Forms/FormChecklist";
import { CommercialEvaluationCompanyAssignmentFormContainerProps } from "./CommercialEvaluationCompanyAssignmentFormContainer";
import { FCFMFEvaluationCompanyAssignmentFormParts } from "../../../../../common/types/Evaluation/BaseFCFMFEvaluationCompanyAssignmentForm";
import { Evaluation_CommercialEvaluationFormModel } from "../../../../types/CommercialEvaluationFormModel";
import { FCFMFSchemaFactoryV2 } from "../../../../../common/customHooks/useFCFMFFieldSchemaFactory";
import { fortifiedFieldSchemaApiClient } from "../../../../../../Common/lib/apiClients/fortifiedFieldSchemaApiClient";

function useCommercialEvaluationCompanyAssignmentFormContainer(
  props: CommercialEvaluationCompanyAssignmentFormContainerProps
) {
  const [commercialSchemaFactory, setCommercialSchemaFactory] =
    useState<FCFMFSchemaFactoryV2>();

  const getSchema = async () => {
    const schema = await fortifiedFieldSchemaApiClient.getSchema();
    const newSchemaFactory = new FCFMFSchemaFactoryV2(schema);
    setCommercialSchemaFactory(newSchemaFactory);
  };

  useEffect(() => {
    if (!commercialSchemaFactory) {
      getSchema();
    }
    //return () => {
    //     setDefaultHeader();
    //   };
  }, []);

  useEffect(() => {
    setEvaluationCompanyAssignmentFormPart(getBestFormPart());
  }, [props.evaluationCompanyAssignmentFormPart]);

  // const { setWildfireEvaluationHeader, setDefaultHeader } =
  //   useContext(AppHeaderContext);
  const { setHasUnsavedChanges } = useContext(UnsavedChangesBlockerContext);

  const [
    evaluationCompanyAssignmentFormPart,
    setEvaluationCompanyAssignmentFormPart,
  ] = useState<FCFMFEvaluationCompanyAssignmentFormParts | undefined>(
    props.evaluationCompanyAssignmentFormPart
  );

  const [evaluationFormModel, setEvaluationFormModel] =
    useState<Evaluation_CommercialEvaluationFormModel>();

  const evaluationCompanyAssignmentForm =
    evaluationFormModel?.commercialEvaluation
      .commercialEvaluationCompanyAssignmentForm;

  const getNextUnsubmittedFormPart = () => {
    if (!evaluationCompanyAssignmentFormPart) {
      return null;
    }

    if (!evaluationCompanyAssignmentFormPart) {
      return FCFMFEvaluationCompanyAssignmentFormParts.EvaluationCompanyAssignment;
    }

    // Form is complete
    return null;
  };

  const getBestFormPart = (): FCFMFEvaluationCompanyAssignmentFormParts => {
    // Default to the first part of the form if we can't find a better option
    const defaultFormPart = Object.values(
      FCFMFEvaluationCompanyAssignmentFormParts
    )[0];

    // If the form part is provided (by URL), use it
    if (!!props.evaluationCompanyAssignmentFormPart) {
      return props.evaluationCompanyAssignmentFormPart;
    }

    // Otherwise, try to find the first unsubmitted part of the form
    // TODO: This doesn't work yet, needs to wait for applicationFormModel to load
    return getNextUnsubmittedFormPart() ?? defaultFormPart;
  };

  const getEvaluationCompanyAssignmentPartChecklistStatus = (
    formPart: FCFMFEvaluationCompanyAssignmentFormParts
  ): FormChecklistStatus => {
    if (formPart === getNextUnsubmittedFormPart()) {
      return FormChecklistStatus.NextUp;
    }

    switch (formPart) {
      case FCFMFEvaluationCompanyAssignmentFormParts.EvaluationCompanyAssignment:
        return evaluationCompanyAssignmentForm?.evaluatorCompany
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
    }
  };

  const evaluationCompanyAssignmentChecklistParts: FormChecklistPart<FCFMFEvaluationCompanyAssignmentFormParts>[] =
    [
      {
        formPart:
          FCFMFEvaluationCompanyAssignmentFormParts.EvaluationCompanyAssignment,
        displayName: "Evaluator Assignment",
      },
    ];

  return {
    evaluationCompanyAssignmentChecklistParts,
    getEvaluationCompanyAssignmentPartChecklistStatus,
    setEvaluationFormModel,
    setHasUnsavedChanges,
    evaluationCompanyAssignmentFormPart,
    getBestFormPart,
    commercialSchemaFactory,
  };
}

export default useCommercialEvaluationCompanyAssignmentFormContainer;
