import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import {
  Column,
  ColumnModel,
  GridComponent,
} from "@syncfusion/ej2-react-grids";
import { get } from "lodash";
import { openGridCommentsDialog } from "../../../Dialogs/GridComments/GridCommentsDialog";
import { CommentType } from "../../../../../common/apiClients/comments/types";
import { parseUntrustedHtmlAsPlaintext } from "../../../../../common/utils/markup";
//import { wildfireUserStore } from "../../../../stores/WildfireUserStore";

interface WildfireCommentsGridClickableIconColumnOptions extends ColumnModel {
  externalUnreadCommentCount: string;
  commentType: CommentType;
}

export interface UnreadCommentsDTO {
  UserId: string;
  UnreadCount: number;
}

export default class WildfireCommentsGridClickableIconColumn extends Column {
  constructor(opts: WildfireCommentsGridClickableIconColumnOptions) {
    const { externalUnreadCommentCount, commentType, ...superOpts } = opts;
    super({
      ...superOpts,
      template: ((model: Record<string, any>) => {
        const modelId = model["id"];
        const fortifiedId = model["fortifiedId"];
        const grid = get(model, "column.parent") as GridComponent;
        const latestAuditorComment = model["latestAuditorComment"];

        const rawFieldValue = model[externalUnreadCommentCount] as number;

        const fieldValue_AsNumber: number = +rawFieldValue;
        const hasUnreadMessages = fieldValue_AsNumber > 0;
        // Only set active class for general comments with unread messages
        const commentIconColor =
          commentType === CommentType.General && hasUnreadMessages
            ? "active-grid-icon"
            : "base-grid-icon";

        return (
          <div
            className={`icon-grid-column ${
              commentType === CommentType.Auditor
                ? "icon-grid-comment-column"
                : ""
            }`}
            onClick={(e) => {
              e.stopPropagation();
              openGridCommentsDialog(
                modelId,
                fortifiedId,
                async () => {
                  return Promise.resolve(grid.refresh());
                },
                commentType
              );
            }}
          >
            <Icon
              className={commentIconColor}
              icon={
                commentType === CommentType.Auditor
                  ? IconNames.EDIT
                  : IconNames.COMMENT
              }
            />

            {/* Show text preview for auditor comments */}
            {commentType === CommentType.Auditor && latestAuditorComment && (
              <span className="grid-note-preview">
                {parseUntrustedHtmlAsPlaintext(latestAuditorComment)}
              </span>
            )}

            {/* Show unread count only for general comments */}
            {commentType === CommentType.General && hasUnreadMessages && (
              <div className="grid-icon-value">
                {Math.min(fieldValue_AsNumber, 99)}
              </div>
            )}
          </div>
        );
      }) as any,
    });
  }
}
