import { Button, Intent } from "@blueprintjs/core";
import { useState } from "react";
import { useNavigate } from "react-router";
import { ProductSubtypes } from "../../types/Evaluation/BaseEvaluation";
import { fortifiedHomeRoutePrefix } from "../../../../foritfied/pages/index/fortifiedHomeRouteConfig";

export interface NonprofitPaymentBypassActionButtonProps<TEvaluation> {
  evaluationId: string;
  productSubtype: ProductSubtypes | undefined;
  nextStepDisplayText: string;
  bypassPaymentStepForNonProfit: (evaluationId: string) => Promise<TEvaluation>;
}

export const NonprofitPaymentBypassActionButton = <TEvaluation extends object>(
  props: NonprofitPaymentBypassActionButtonProps<TEvaluation>
) => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const handleButtonClick = async () => {
    setIsLoading(true);
    await props
      .bypassPaymentStepForNonProfit(props.evaluationId)
      .then(() => navigate(fortifiedHomeRoutePrefix))
      .finally(() => setIsLoading(false));
  };

  return (
    <Button
      intent={Intent.PRIMARY}
      text={`Proceed to ${props.nextStepDisplayText}`}
      loading={isLoading}
      onClick={handleButtonClick}
    />
  );
};
