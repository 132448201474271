import { GridMetricRequestDTO } from "@ucl/library/lib/components/Grids/PrimaryGrid/modules/GridMetrics/types";
import { ApiClient } from "../../lib/apiClients/baseApiClient";

class FortifiedGridMetricsApiClient {
  private _apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this._apiClient = apiClient;
  }

  fetchMetrics = async <T>(url: string, qry: string): Promise<T> => {
    const gridMetricRequestDTO = {
      query: qry,
    } as GridMetricRequestDTO;
    return this._apiClient.post<T>(url, gridMetricRequestDTO);
  };

  fetchMetricOptionValues = async <GridMetricOptionValueReadDTO>(
    url: string
  ): Promise<GridMetricOptionValueReadDTO[]> => {
    return this._apiClient.get<GridMetricOptionValueReadDTO[]>(url);
  };
}

export default FortifiedGridMetricsApiClient;
