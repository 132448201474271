import React, { useEffect, useState } from "react";
import { Evaluation_CommercialEvaluationFormModel } from "../../../commercial/types/CommercialEvaluationFormModel";
import SimpleCommercialApplicationForm from "../../../commercial/components/forms/ApplicationForm/Form/Simple/SimpleCommercialApplicationForm";
import {
  FCFMFSchemaFactoryV2,
  FieldSchemaFactoryProvider,
} from "../../customHooks/useFCFMFFieldSchemaFactory";
import { Evaluation_MultifamilyEvaluationFormModel } from "../../../multifamily/types/MultifamilyEvaluationFormModel";
import "./styles.scss";
import SimpleMultifamilyApplicationForm from "../../../multifamily/components/forms/ApplicationForm/Form/Simple/SimpleMultifamilyApplicationForm";
import { fortifiedFieldSchemaApiClient } from "../../../../Common/lib/apiClients/fortifiedFieldSchemaApiClient";

export interface ExternalCommercialEvaluationSummaryProps {
  commercialEvaluation?: Evaluation_CommercialEvaluationFormModel;
  multifamilyEvaluation?: Evaluation_MultifamilyEvaluationFormModel;
}

const ExternalCommercialEvaluationSummary: React.FC<
  ExternalCommercialEvaluationSummaryProps
> = (props) => {
  const [commercialSchemaFactory, setCommercialSchemaFactory] =
    useState<FCFMFSchemaFactoryV2>();

  const getSchema = async () => {
    const schema = await fortifiedFieldSchemaApiClient.getSchema();
    const newSchemaFactory = new FCFMFSchemaFactoryV2(schema);
    setCommercialSchemaFactory(newSchemaFactory);
  };

  useEffect(() => {
    if (!commercialSchemaFactory) {
      getSchema();
    }
  }, []);

  return (
    <div className="external-fcfmf-forms">
      {props.commercialEvaluation && commercialSchemaFactory && (
        <FieldSchemaFactoryProvider schemaFactory={commercialSchemaFactory}>
          <SimpleCommercialApplicationForm form={props.commercialEvaluation} />
        </FieldSchemaFactoryProvider>
      )}

      {props.multifamilyEvaluation && commercialSchemaFactory && (
        <FieldSchemaFactoryProvider schemaFactory={commercialSchemaFactory}>
          <SimpleMultifamilyApplicationForm
            form={props.multifamilyEvaluation}
          />
        </FieldSchemaFactoryProvider>
      )}
    </div>
  );
};

export default ExternalCommercialEvaluationSummary;
