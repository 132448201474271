import { useCallback, useEffect, useRef, useState } from "react";
import { useHomeFieldSchemaFactory } from "../../../../../../customHooks/useHomeFieldSchemaFactory";
import { FieldFormV2Module } from "@ucl/library";
import { AppToaster } from "@ucl/library/lib/components/Toast/Toast";
import { Intent } from "@blueprintjs/core";
import { debounce } from "lodash";
import {
  BaseFieldProps,
  CoreFieldValue,
} from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { SimpleHomeNCIUARoofingComplianceRoofCoverFormProps } from "./SimpleHomeNCIUAEvaluationRoofingComplianceRoofCoverForm";
import { Evaluation_HomeNCIUAEvaluationFormModel } from "../../../../../evaluation/types/HomeNCIUAEvaluationFormModel";
import { homeNCIUAEvaluationRoofingComplianceRoofCoverAPIClient } from "../../../../../evaluation/lib/apiClients/homeNCIUAEvaluationRoofingComplianceRoofCoverAPIClient";

function useSimpleHomeNCIUARoofingComplianceRoofCoverForm(
  props: SimpleHomeNCIUARoofingComplianceRoofCoverFormProps
) {
  const { builders } = useHomeFieldSchemaFactory();

  const formRef =
    useRef<FieldFormV2Module<Evaluation_HomeNCIUAEvaluationFormModel>>(null);

  const [formModel, setFormModel] =
    useState<Evaluation_HomeNCIUAEvaluationFormModel>(props.form);

  const containerRef = useRef<HTMLDivElement>(null);

  const noBuilders = !builders;

  const getFormBuilder = () => {
    return builders?.HomeNCIUAExternalRoofingComplianceRoofCoverForm;
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  // Debounce setup
  const handleFormUpdateDebounced = useCallback(
    debounce(
      async (
        form: Evaluation_HomeNCIUAEvaluationFormModel,
        _value: any,
        fieldProps: BaseFieldProps<any>
      ) => {
        if (fieldProps.fieldKey) {
          await handleFormUpdate(form);
        }
      },
      2000
    ),
    []
  );

  useEffect(() => {
    // Cleanup the debounce function on component unmount
    return () => handleFormUpdateDebounced.cancel();
  }, [handleFormUpdateDebounced]);

  const onFormFieldChange = async (
    form: Evaluation_HomeNCIUAEvaluationFormModel,
    value: CoreFieldValue,
    fieldProps: BaseFieldProps<any>
  ) => {
    // Call the debounced submit function
    if (!!value) {
      handleFormUpdateDebounced(form, value, fieldProps);
    }
  };

  const handleFormUpdate = async (
    form: Evaluation_HomeNCIUAEvaluationFormModel
  ) => {
    setFormModel(form);
    await homeNCIUAEvaluationRoofingComplianceRoofCoverAPIClient
      .updateHomeNCIUARoofingComplianceRoofCoverFormExternal(
        form.id,
        form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm,
        props.accessToken
      )
      .catch((error) => {
        if (error.response.status !== 400) {
          console.error(error);
          AppToaster.show({
            message: "Unexpected error occurred while saving the form",
            intent: Intent.DANGER,
          });
        }
      });
  };

  const handleFormSubmit = async (
    form: Evaluation_HomeNCIUAEvaluationFormModel
  ) => {
    setFormModel(form);
    setIsSubmitting(true);
    await homeNCIUAEvaluationRoofingComplianceRoofCoverAPIClient
      .submitHomeNCIUARoofingComplianceRoofCoverFormExternal(
        form.id,
        form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm,
        props.accessToken
      )
      .then(async (response) => {
        setFormModel(response);
        AppToaster.show({
          message: "Form submitted successfully",
          intent: Intent.SUCCESS,
        });
      })
      .catch((error) => {
        if (error.response.status !== 400) {
          console.error(error);
          AppToaster.show({
            message: "Unexpected error occurred while submitting the form",
            intent: Intent.DANGER,
          });
        }
        throw error;
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return {
    builders,
    formRef,
    formModel,
    getFormBuilder,
    noBuilders,
    containerRef,
    isSubmitting,
    onFormFieldChange,
    handleFormSubmit,
  };
}

export default useSimpleHomeNCIUARoofingComplianceRoofCoverForm;
