export type RedesignationStepKey =
  | "ApplicationInitiated"
  | "Payment"
  | "EvaluatorAssignment"
  | "FieldInspectionsSubmitted"
  | "EvaluatorComplianceLetterSubmitted"
  | "IBHSReview";

export type RegularStepKey =
  | "ApplicationInitiated"
  | "ApplicationFee"
  | "EligibilityReviewed"
  | "EvaluatorAssignment"
  | "ComplianceFormSubmitted"
  | "FieldInspectionsSubmitted"
  | "EvaluatorComplianceLetterSubmitted"
  | "IBHSReview"
  | "CertificateFee";

export type StepKey = RedesignationStepKey | RegularStepKey;

// Card ORDER FLOW
export const REDESIGNATION_STEPS: Record<RedesignationStepKey, string> = {
  ApplicationInitiated: "Application",
  Payment: "Payment",
  EvaluatorAssignment: "Evaluator Assignment",
  FieldInspectionsSubmitted: "Field Inspections",
  EvaluatorComplianceLetterSubmitted: "Compliance Letter",
  IBHSReview: "IBHS Review",
} as const;

export const REGULAR_STEPS: Record<RegularStepKey, string> = {
  ApplicationInitiated: "Application",
  ApplicationFee: "Application Fee",
  EligibilityReviewed: "IBHS Eligibility Review",
  EvaluatorAssignment: "Evaluator Assignment",
  ComplianceFormSubmitted: "Compliance Forms",
  FieldInspectionsSubmitted: "Field Inspections",
  EvaluatorComplianceLetterSubmitted: "Compliance Letter",
  IBHSReview: "IBHS Review",
  CertificateFee: "Certificate Fee",
} as const;
