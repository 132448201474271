import {
  fortified_Home_browserRouter,
  fortifiedHomeRoutePrefix,
} from "../../foritfied/pages/index/fortifiedHomeRouteConfig";
import { ProductTypeHandlerProvider } from "../../foritfied/providers/ProductTypeHandlerProvider";
import {
  fortifiedHomeLogout,
  fortifiedHomeMsalInstance,
  getFortifiedHomeAccessToken,
} from "../lib/msal/fortifiedHomeMsal";
import Pages from "../../foritfied/pages/index/index";
import { fortified_Home_ExternalBrowserRouter } from "../../foritfied/pages/index/fortifiedHomeExternalRouteConfig";

export const FHProductTypeHandler = () => {
  return (
    <ProductTypeHandlerProvider
      routePrefix={fortifiedHomeRoutePrefix}
      getAccessToken={getFortifiedHomeAccessToken}
      msalInstance={fortifiedHomeMsalInstance}
      router={fortified_Home_browserRouter}
      externalRouter={fortified_Home_ExternalBrowserRouter}
      logoutUser={fortifiedHomeLogout}
    >
      <Pages />
    </ProductTypeHandlerProvider>
  );
};
