import { FieldFormV2Module } from "@ucl/library";
import { useRef, useState } from "react";
import { useFCFMFFieldSchemaFactory } from "../../../../../../common/customHooks/useFCFMFFieldSchemaFactory";
import { Evaluation_CommercialEvaluationFormModel } from "../../../../../types/CommercialEvaluationFormModel";
import { SimpleCommercialApplicationFormProps } from "./SimpleCommercialApplicationForm";

function useSimpleCommercialApplicationForm(
  props: SimpleCommercialApplicationFormProps
) {
  const { builders } = useFCFMFFieldSchemaFactory();

  const formRef =
    useRef<FieldFormV2Module<Evaluation_CommercialEvaluationFormModel>>(null);

  const [applicationFormModel] =
    useState<Evaluation_CommercialEvaluationFormModel>(props.form);

  const containerRef = useRef<HTMLDivElement>(null);

  const noBuilders = !builders;

  const getApplicationFormPartBuilder = () => {
    return builders?.CommercialExternalApplicationForm;
  };

  return {
    builders,
    formRef,
    applicationFormModel,
    getApplicationFormPartBuilder,
    noBuilders,
    containerRef,
  };
}

export default useSimpleCommercialApplicationForm;
