import {
  EnumSingleSelectFieldV2Props,
  FieldSchemaFormBuilderV2,
  SchemaFactoryV2,
  SmartAddressFieldProps,
} from "@ucl/library";
import { addressApiClient } from "../../../../../../foritfied/lib/apiClients/address/addressApiClient";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { WildfireBoxContentExplorer } from "../../../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import { Evaluation_HomeNCIUAEvaluationFormModel } from "../../../evaluation/types/HomeNCIUAEvaluationFormModel";
import { HomeNCIUASpecialProjectTypes } from "../../../evaluation/types/HomeNCIUAEvaluationBasicInformationFormModel";

const modelName = "Evaluation";
const subModelName = "HomeNCIUAEvaluation";
const formName = "BasicInformationForm";

const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

//Part 1: Homeowner Information
const homeownerInformationFields = (
  form: Evaluation_HomeNCIUAEvaluationFormModel
) => [
  buildFieldKey("HomeownerFirstName"),
  buildFieldKey("HomeownerLastName"),
  buildFieldKey("HomeownerEmail"),
  {
    fieldKey: buildFieldKey("Address"),
    getAddressesByQuery: addressApiClient.getAddressesByQuery,
    getAddressByQuery: addressApiClient.getAddressByQuery,
    isSmartOnly: false,
    shouldShowAutoSuggest: true,
    shouldSetCountyOnSelect: true,
  } as SmartAddressFieldProps,
  buildFieldKey("DuplicateAddressConfirmation"),
  buildFieldKey("SpecialProjectType"),
  buildFieldKey("PolicyNumber"),
  {
    fieldKey: buildFieldKey("ClaimPrefix"),
    hidden:
      form.homeNCIUAEvaluation.basicInformationForm.specialProjectType !=
      HomeNCIUASpecialProjectTypes.NCIUA_NCJUA_Claim,
  } as EnumSingleSelectFieldV2Props,
  {
    fieldKey: buildFieldKey("ClaimNumber"),
    hidden:
      form.homeNCIUAEvaluation.basicInformationForm.specialProjectType !=
      HomeNCIUASpecialProjectTypes.NCIUA_NCJUA_Claim,
  } as EnumSingleSelectFieldV2Props,
];

const homeownerInformationRequiredFields = [
  buildFieldKey("HomeownerFirstName"),
  buildFieldKey("HomeownerLastName"),
  buildFieldKey("HomeownerEmail"),
  buildFieldKey("Address"),
  buildFieldKey("SpecialProjectType"),
  buildFieldKey("PolicyNumber"),
  buildFieldKey("ClaimPrefix"),
  buildFieldKey("ClaimNumber"),
];

export const homeNCIUAHomeownerInformationBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeNCIUAEvaluationFormModel>(
        modelName,
        homeownerInformationFields(form),
        onValueChange,
        form,
        errors,
        homeownerInformationRequiredFields
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel>;
};
// Full form builder with all parts included
export const homeNCIUABasicInformationFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeNCIUAEvaluationFormModel>(
        modelName,
        homeownerInformationFields(form),
        onValueChange,
        form,
        errors,
        homeownerInformationRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel>;
};

// Full form builder with all parts included, for Iteration Engine
export const homeNCIUAIterationEngineBasicInformationFormBuilder = (
  factory: SchemaFactoryV2,
  beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
): FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    let fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeNCIUAEvaluationFormModel>(
        modelName,
        homeownerInformationFields(form),
        onValueChange,
        form,
        errors,
        homeownerInformationRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    if (beforeBuild) {
      fields = beforeBuild(fields);
    }
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel>;
};
