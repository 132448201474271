import { dialogStore } from "@ucl/library";
import { DialogProps } from "@ucl/library/lib/components/Dialogs/Dialog/Dialog";
import {
  ImageAnnotationEditor,
  ImageAnnotationEditorProps,
} from "../../../../common/Components/Fields/ImageAnnotationEditor";
import "./styles.scss";

const dialogIdBase = "wildfire-image-annotation-dialog";

const getDialogId = (): string => `${dialogIdBase}`;

const buildImageAnnotationDialog = (
  annotationEditorSettings: ImageAnnotationEditorProps,
  onSubmit?: () => Promise<void>
): DialogProps => {
  return {
    id: getDialogId(),
    className: dialogIdBase,
    header: `Image Annotation - ${annotationEditorSettings.fileName}`,
    allowDragging: true,
    width: "calc(80%)",
    height: "calc(80%)",
    content: () => (
      <ImageAnnotationEditor
        {...annotationEditorSettings}
        onClose={onSubmit}
      ></ImageAnnotationEditor>
    ),
  };
};

export const openImageAnnotationDialog = (
  annotationEditorSettings: ImageAnnotationEditorProps,
  onSubmit?: () => Promise<void>
) => {
  dialogStore.openDialog(
    buildImageAnnotationDialog(annotationEditorSettings, onSubmit)
  );
};

export const closeImageAnnotationDialog = async () =>
  dialogStore.closeDialog(dialogIdBase);
