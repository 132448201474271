import { action, makeObservable, observable, runInAction } from "mobx";
import { AuditorChecklistItem } from "../../fortifiedV2/Common/components/IterationEngine/AuditorChecklist/AuditorChecklistItem";

export class AuditorChecklistStore {
  public checklists: AuditorChecklistItem[] = [];

  constructor() {
    makeObservable(this, {
      checklists: observable,
      setChecklists: action,
    });
  }

  setChecklists = (checklists: AuditorChecklistItem[]) => {
    runInAction(() => {
      this.checklists = [...checklists];
    });
  };

  updateChecklist = (checklist: AuditorChecklistItem) => {
    runInAction(() => {
      this.checklists = this.checklists.map((x) =>
        x.id === checklist.id ? checklist : x
      );
    });
  };
}

export const auditorChecklistStore = new AuditorChecklistStore();
