import { useContext, useEffect, useState } from "react";
import { fortifiedHomeStandardRedesignationRoutePrefix } from "../../../../../foritfied/pages/index/fortifiedHomeRouteConfig";
import { UserRole } from "../../../../../foritfied/stores/FortifiedUserStore";
import { NotifySubmitterFormLink } from "../../../../../foritfied/components/Checklist/NotifySubmitter/NotifySubmitterLinkBuilder";
import { EvaluationStep } from "../../../../../foritfied/types/evaluation/Evaluation";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import {
  errorStore,
  genericErrorMessage,
} from "../../../../../common/Components/Error/ErrorStore";
import { FormRowProps } from "../../../common/components/Checklist/ChecklistTabPanelV2";
import ChecklistContainerV2, {
  ChecklistTabItem,
} from "../../../common/components/Checklist/ChecklistContainerV2";
import { ProductSubtypes } from "../../../../Common/types/Evaluation/BaseEvaluation";
import { useHomeStandardRedesignation } from "../../customHooks/useHomeStandardRedesignation";
import { homeStandardRedesignationAPIClient } from "../../lib/apiClients/homeStandardRedesignationAPIClient";
import { AppHeaderContext } from "../../../../../wildfire/components/Provider/AppHeaderContext";

interface ChecklistContainerProps {
  evaluationId: string;
}

const HomeStandardRedesignationChecklist: React.FC<ChecklistContainerProps> = (
  props
) => {
  const [selectedTabId, setSelectedTabId] = useState(0);

  const { homeStandardRedesignation, setHomeStandardRedesignation } =
    useHomeStandardRedesignation(props.evaluationId);

  const [hasFormsLoading, setHasFormsLoading] = useState(false);

  useEffect(() => {
    return () => {
      setDefaultHeader();
    };
  }, []);

  const { setFotifiedHomeEvaluationHeader, setDefaultHeader } =
    useContext(AppHeaderContext);

  useEffect(() => {
    if (
      homeStandardRedesignation?.homeStandardRedesignation?.redesignationForm
        ?.isRoofingFormCreationInProgress
    ) {
      setHasFormsLoading(true);
    }

    if (homeStandardRedesignation) {
      setFotifiedHomeEvaluationHeader(homeStandardRedesignation);
    }
  }, [homeStandardRedesignation]);

  useEffect(() => {
    if (hasFormsLoading) {
      const interval = setInterval(async () => {
        await homeStandardRedesignationAPIClient
          .getHomeStandardRedesignation(props.evaluationId)
          .then((response) => {
            setHomeStandardRedesignation(response);

            const isLoading =
              response?.homeStandardRedesignation?.redesignationForm
                ?.isRoofingFormCreationInProgress === true ||
              response?.homeStandardRedesignation?.canMoveToPaymentStep ===
                true;

            if (!isLoading) {
              setHasFormsLoading(false);
              clearInterval(interval);
            }
          })
          .catch(() => {
            errorStore.setErrorMessage(genericErrorMessage);
          });
      }, 3000); // Check every 3 seconds

      return () => clearInterval(interval);
    }
  }, [hasFormsLoading]);

  const forms: FormRowProps[] = [
    {
      formName: "Homeowner Authorization Form",
      formURL: `${fortifiedHomeStandardRedesignationRoutePrefix}/${homeStandardRedesignation?.id}/homeowner-authorization`,
      isCompleted:
        homeStandardRedesignation?.homeStandardRedesignation
          ?.homeownerAuthorizationForm?.areAllRequiredFieldsComplete || false,
      isDisabled: false,
      formsNeedingCompletion: [],
      completerRoles:
        homeStandardRedesignation?.homeStandardRedesignation
          ?.homeownerAuthorizationForm?.completerRoles || [],
      completerRoles_AsString:
        homeStandardRedesignation?.homeStandardRedesignation
          ?.homeownerAuthorizationForm?.completerRoles_AsString || [],
      hidden:
        !homeStandardRedesignation?.homeStandardRedesignation
          ?.homeownerAuthorizationForm,
    },
    {
      formName: "Redesignation Form",
      formURL: `${fortifiedHomeStandardRedesignationRoutePrefix}/${homeStandardRedesignation?.id}/redesignation-form`,
      isCompleted:
        homeStandardRedesignation?.homeStandardRedesignation?.redesignationForm
          ?.areAllRequiredFieldsComplete || false,
      isDisabled: false,
      formsNeedingCompletion: [],
      completerRoles:
        homeStandardRedesignation?.homeStandardRedesignation?.redesignationForm
          ?.completerRoles || [],
      completerRoles_AsString:
        homeStandardRedesignation?.homeStandardRedesignation?.redesignationForm
          ?.completerRoles_AsString || [],
      hidden:
        !homeStandardRedesignation?.homeStandardRedesignation
          ?.redesignationForm,
    },
    {
      formName: "Roofing Compliance Form: General Information",
      formURL: `${fortifiedHomeStandardRedesignationRoutePrefix}/${homeStandardRedesignation?.id}/roofing-compliance-general-information`,
      isCompleted:
        homeStandardRedesignation?.homeStandardRedesignation
          ?.roofingComplianceGeneralInformationForm
          ?.areAllRequiredFieldsComplete || false,
      isDisabled: false,
      formsNeedingCompletion: [],
      completerRoles:
        homeStandardRedesignation?.homeStandardRedesignation
          ?.roofingComplianceGeneralInformationForm?.completerRoles || [],
      completerRoles_AsString:
        homeStandardRedesignation?.homeStandardRedesignation
          ?.roofingComplianceGeneralInformationForm?.completerRoles_AsString ||
        [],
      hidden:
        !homeStandardRedesignation?.homeStandardRedesignation
          ?.roofingComplianceGeneralInformationForm,
    },
    {
      formName: "Roof Cover Compliance Form",
      formURL: `${fortifiedHomeStandardRedesignationRoutePrefix}/${homeStandardRedesignation?.id}/roofing-compliance-roof-cover`,
      isCompleted:
        homeStandardRedesignation?.homeStandardRedesignation
          ?.roofingComplianceRoofCoverForm?.areAllRequiredFieldsComplete ||
        false,
      isDisabled:
        !homeStandardRedesignation?.homeStandardRedesignation
          ?.canCompleteRoofCoverComplianceForm,
      formsNeedingCompletion: ["Roofing Compliance Form: General Information"],
      completerRoles:
        homeStandardRedesignation?.homeStandardRedesignation
          ?.roofingComplianceRoofCoverForm?.completerRoles || [],
      completerRoles_AsString:
        homeStandardRedesignation?.homeStandardRedesignation
          ?.roofingComplianceRoofCoverForm?.completerRoles_AsString || [],
      hidden:
        !homeStandardRedesignation?.homeStandardRedesignation
          ?.roofingComplianceRoofCoverForm,
    },
    {
      formName: "Roofer Confirmation",
      formURL: `${fortifiedHomeStandardRedesignationRoutePrefix}/${homeStandardRedesignation?.id}/roofer-confirmation`,
      isCompleted:
        homeStandardRedesignation?.homeStandardRedesignation
          ?.rooferConfirmationForm?.areAllRequiredFieldsComplete || false,
      isDisabled:
        !homeStandardRedesignation?.homeStandardRedesignation
          ?.canCompleteRooferConfirmationForm,
      formsNeedingCompletion: [
        "Roofing Compliance Form: General Information",
        "Roof Cover Compliance Form",
      ],
      completerRoles:
        homeStandardRedesignation?.homeStandardRedesignation
          ?.rooferConfirmationForm?.completerRoles || [],
      completerRoles_AsString:
        homeStandardRedesignation?.homeStandardRedesignation
          ?.rooferConfirmationForm?.completerRoles_AsString || [],
      hidden:
        !homeStandardRedesignation?.homeStandardRedesignation
          ?.rooferConfirmationForm,
    },
  ];

  const baseTab: ChecklistTabItem[] = [{ id: 0, label: "All" }];

  const tabs: ChecklistTabItem[] = [
    { id: 1, label: "FH Evaluator", role: UserRole["FH Evaluator"] },
    { id: 2, label: "FH Roofer", role: UserRole["FH Roofer"] },
    { id: 3, label: "FH Engineer", role: UserRole["FH Engineer"] },
    { id: 4, label: "FH Contractor", role: UserRole["FH Contractor"] },
    { id: 5, label: "IBHS Admin", role: UserRole["IBHS Admin"] },
    {
      id: 6,
      label: "FH Evaluator Admin",
      role: UserRole["FH Evaluator Admin"],
    },
  ];

  const allTabs = baseTab.concat(tabs);

  // Find the selected tab by its ID
  const selectedUserRoleTab =
    allTabs.find((tab) => tab.id === selectedTabId) || baseTab[0];

  const baseLinkURL = window.location.origin;

  const rooferNotifySubmitterLinks: NotifySubmitterFormLink[] = [
    {
      name: "Roofing Compliance Form: General Information",
      link: `${baseLinkURL}${fortifiedHomeStandardRedesignationRoutePrefix}/external/roofing-compliance-general-information/${homeStandardRedesignation?.id}`,
      hidden:
        !homeStandardRedesignation?.homeStandardRedesignation
          ?.roofingComplianceGeneralInformationForm,
    },
    {
      name: "Roof Cover Compliance Form",
      link: `${baseLinkURL}${fortifiedHomeStandardRedesignationRoutePrefix}/external/roofing-compliance-roof-cover/${homeStandardRedesignation?.id}`,
      hidden:
        !homeStandardRedesignation?.homeStandardRedesignation
          ?.roofingComplianceRoofCoverForm,
    },
    {
      name: "Roofer Confirmation",
      link: `${baseLinkURL}${fortifiedHomeStandardRedesignationRoutePrefix}/external/roofer-confirmation/${homeStandardRedesignation?.id}`,
      hidden:
        !homeStandardRedesignation?.homeStandardRedesignation
          ?.rooferConfirmationForm,
    },
  ];

  const getSelectedUserRoleFormLinks = () => {
    switch (selectedUserRoleTab.role) {
      case UserRole["FH Roofer"]:
        return rooferNotifySubmitterLinks;
      default:
        return [];
    }
  };

  const selectedUserRoleFormLinks = getSelectedUserRoleFormLinks();

  const isAllSelected = !selectedUserRoleTab || selectedUserRoleTab.id === 0;

  const filteredForms = forms.filter(
    (form) =>
      !form.hidden &&
      (isAllSelected ||
        form.completerRoles.includes(selectedUserRoleTab.role || 0))
  );

  const filteredTabs = allTabs.filter((tab) =>
    forms.some((form) => form.completerRoles.includes(tab.role || 0))
  );

  // Always include the "All" tab
  const visibleTabs = [baseTab[0], ...filteredTabs];

  return (
    <div>
      {!homeStandardRedesignation && <Loading />}
      {homeStandardRedesignation && (
        <ChecklistContainerV2
          evaluationId={props.evaluationId}
          filteredForms={filteredForms}
          selectedUserRoleFormLinks={selectedUserRoleFormLinks}
          hasFormsLoading={hasFormsLoading}
          setEvaluation={setHomeStandardRedesignation}
          fortifiedId={homeStandardRedesignation.fortifiedId || ""}
          buildingAddress_AsString={
            homeStandardRedesignation.buildingAddress_AsString
          }
          evaluationStep={homeStandardRedesignation?.step}
          selectedUserRoleTab={selectedUserRoleTab}
          isOnPaymentStep={
            homeStandardRedesignation.step === EvaluationStep.Payment
          }
          isPaymentApplicable={homeStandardRedesignation.isPaymentApplicable}
          paymentNextStepDisplayText="IBHS Review"
          bypassPaymentStepForNonProfit={
            homeStandardRedesignationAPIClient.bypassPaymentStepForNonProfit
          }
          visibleTabs={visibleTabs}
          selectedTabId={selectedTabId}
          setSelectedTabId={setSelectedTabId}
          productTypeRoute={`${fortifiedHomeStandardRedesignationRoutePrefix}`}
          getApplicationFeePaymentCreditDetails={
            homeStandardRedesignationAPIClient.getApplicationFeePaymentCreditDetails
          }
          productSubtype={ProductSubtypes.HomeStandardRedesignation}
          canCompleteRooferConfirmation={
            homeStandardRedesignation?.homeStandardRedesignation
              ?.canCompleteRooferConfirmationForm ?? false
          }
          getRooferConfirmation={() =>
            homeStandardRedesignationAPIClient.getRooferConfirmationForm(
              props.evaluationId
            )
          }
        />
      )}
    </div>
  );
};

export default HomeStandardRedesignationChecklist;
