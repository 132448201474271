import { AccountInfo } from "@azure/msal-browser";
import { computed, makeObservable, observable } from "mobx";
import { UserReadDTO } from "../lib/apiClients/user/types";
import { userApiClient } from "../lib/apiClients/user/userApiClient";
import { appInsights } from "../../AppInsights";
import {
  User,
  UserRole,
  UserRoleType,
} from "../../common/Components/User/userTypes";

class FortifiedUserStore {
  public user?: User;

  constructor() {
    makeObservable(this, {
      user: observable,
      isAuditor: computed,
      isEvaluator: computed,
    });
  }

  async initAccount(account: AccountInfo | null) {
    if (account != null) {
      const userFromSystem: UserReadDTO = await this.signIn();
      this.user = {
        id: userFromSystem.id,
        email: userFromSystem.email,
        firstName: userFromSystem.firstName,
        lastName: userFromSystem.lastName,
        role: userFromSystem.role_AsString as UserRoleType,
        canClaimEvaluations: userFromSystem.canClaimEvaluations,
        canUpdateEvaluatorFromAdmin: userFromSystem.canUpdateEvaluatorFromAdmin,
      };

      appInsights.setAuthenticatedUserContext(
        this.user?.id,
        account?.homeAccountId,
        true
      );
    }
  }

  async signIn() {
    return userApiClient.signIn();
  }

  hasRole(userRole: UserRoleType) {
    return this.user?.role === userRole;
  }

  get isAuditor() {
    return false;
  }

  get isEvaluator() {
    return false;
  }

  get displayRole() {
    return !!this.user ? this.user.role : "";
  }

  get fullUserName() {
    return !!this.user ? `${this.user.firstName} ${this.user.lastName}` : "";
  }

  isAnAuditor(role?: number): boolean {
    return (
      role === UserRole["FH Auditor"] ||
      role === UserRole["FH Auditor Trainee"] ||
      role === UserRole["FC/FMF Auditor"] ||
      role === UserRole["IBHS Admin"]
    );
  }

  get isAuditorTrainee() {
    return this.hasRole("FH Auditor Trainee");
  }

  get isTraineeAuditAdmin() {
    return (
      fortifiedUserStore.hasRole("FH Auditor") ||
      fortifiedUserStore.hasRole("IBHS Admin") ||
      fortifiedUserStore.hasRole("Relativity Admin")
    );
  }

  get isFHInternalUser() {
    return (
      fortifiedUserStore.hasRole("FH Auditor Trainee") ||
      fortifiedUserStore.hasRole("FH Auditor") ||
      fortifiedUserStore.hasRole("IBHS Admin") ||
      fortifiedUserStore.hasRole("Relativity Admin")
    );
  }

  get isFortifiedUser() {
    return (
      this.user?.role !== "WFP Applicant" &&
      this.user?.role !== "WFP Admin" &&
      this.user?.role !== "WFP Evaluator" &&
      this.user?.role !== "WFP Auditor"
    );
  }

  get isFHApplicant() {
    return this.user?.role === "FH Applicant";
  }

  get isFCFMFApplicant() {
    return this.user?.role === "FC/FMF Applicant";
  }

  get IsFCFMFAdmin() {
    return (
      this.user?.role === "IBHS Admin" ||
      this.user?.role === "Relativity Admin" ||
      this.user?.role === "FC/FMF Admin" ||
      this.user?.role === "FC/FMF Auditor"
    );
  }

  get IsRelativityAdmin() {
    return (
      this.user?.role === "IBHS Admin" || this.user?.role === "Relativity Admin"
    );
  }
}

export const fortifiedUserStore = new FortifiedUserStore();
export { UserRole };
