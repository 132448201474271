import { useContext, useEffect, useState } from "react";
import { fortifiedHomeSCSHRoutePrefix } from "../../../../../foritfied/pages/index/fortifiedHomeRouteConfig";
import { UserRole } from "../../../../../foritfied/stores/FortifiedUserStore";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import { FormRowProps } from "../../../common/components/Checklist/ChecklistTabPanelV2";
import ChecklistContainerV2, {
  ChecklistTabItem,
} from "../../../common/components/Checklist/ChecklistContainerV2";
import { useHomeSCSHEvaluation } from "../../customHooks/useHomeSCSHEvaluation";
import { homeSCSHEvaluationAPIClient } from "../../lib/apiClients/homeSCSHEvaluationAPIClient";
import { AppHeaderContext } from "../../../../../wildfire/components/Provider/AppHeaderContext";

interface ChecklistContainerProps {
  evaluationId: string;
}

const HomeSCSHChecklist: React.FC<ChecklistContainerProps> = (props) => {
  const [selectedTabId, setSelectedTabId] = useState(0);

  const { homeSCSHEvaluation, setHomeSCSHEvaluation } = useHomeSCSHEvaluation(
    props.evaluationId
  );

  useEffect(() => {
    return () => {
      setDefaultHeader();
    };
  }, []);

  const { setFotifiedHomeEvaluationHeader, setDefaultHeader } =
    useContext(AppHeaderContext);

  useEffect(() => {
    if (homeSCSHEvaluation) {
      setFotifiedHomeEvaluationHeader(homeSCSHEvaluation);
    }
  }, [homeSCSHEvaluation]);

  const forms: FormRowProps[] = [
    {
      formName: "Basic Information Form",
      formURL: `${fortifiedHomeSCSHRoutePrefix}/${homeSCSHEvaluation?.id}/basic-information`,
      isCompleted:
        homeSCSHEvaluation?.homeSCSHEvaluation?.basicInformationForm
          ?.areAllRequiredFieldsComplete || false,
      isDisabled: false,
      formsNeedingCompletion: [],
      completerRoles:
        homeSCSHEvaluation?.homeSCSHEvaluation?.basicInformationForm
          ?.completerRoles || [],
      completerRoles_AsString:
        homeSCSHEvaluation?.homeSCSHEvaluation?.basicInformationForm
          ?.completerRoles_AsString || [],
      hidden: !homeSCSHEvaluation?.homeSCSHEvaluation?.basicInformationForm,
    },
    {
      formName: "SCSH Evaluation Form",
      formURL: `${fortifiedHomeSCSHRoutePrefix}/${homeSCSHEvaluation?.id}/evaluation-form`,
      isCompleted:
        homeSCSHEvaluation?.homeSCSHEvaluation?.homeSCSHEvaluationForm
          ?.areAllRequiredFieldsComplete || false,
      isDisabled: false,
      formsNeedingCompletion: [],
      completerRoles: [],
      completerRoles_AsString: [],
      hidden: !homeSCSHEvaluation?.homeSCSHEvaluation?.homeSCSHEvaluationForm,
    },
  ];

  const baseTab: ChecklistTabItem[] = [{ id: 0, label: "All" }];

  const tabs: ChecklistTabItem[] = [
    { id: 1, label: "FH Evaluator", role: UserRole["FH Evaluator"] },
    { id: 2, label: "FH Roofer", role: UserRole["FH Roofer"] },
    { id: 3, label: "FH Engineer", role: UserRole["FH Engineer"] },
    { id: 4, label: "FH Contractor", role: UserRole["FH Contractor"] },
    { id: 5, label: "IBHS Admin", role: UserRole["IBHS Admin"] },
    {
      id: 6,
      label: "FH Evaluator Admin",
      role: UserRole["FH Evaluator Admin"],
    },
  ];

  const allTabs = baseTab.concat(tabs);

  // Find the selected tab by its ID
  const selectedUserRoleTab =
    allTabs.find((tab) => tab.id === selectedTabId) || baseTab[0];

  const isAllSelected = !selectedUserRoleTab || selectedUserRoleTab.id === 0;

  const filteredForms = forms.filter(
    (form) =>
      !form.hidden &&
      (isAllSelected ||
        form.completerRoles.includes(selectedUserRoleTab.role || 0))
  );

  const filteredTabs = allTabs.filter((tab) =>
    forms.some((form) => form.completerRoles.includes(tab.role || 0))
  );

  // Always include the "All" tab
  const visibleTabs = [baseTab[0], ...filteredTabs];

  return (
    <div>
      {!homeSCSHEvaluation && <Loading />}
      {homeSCSHEvaluation && (
        <ChecklistContainerV2
          evaluationId={props.evaluationId}
          filteredForms={filteredForms}
          selectedUserRoleFormLinks={[]}
          selectedUserRoleAccessTokenMethod={undefined}
          hasFormsLoading={false}
          setEvaluation={setHomeSCSHEvaluation}
          fortifiedId={homeSCSHEvaluation.fortifiedId || ""}
          buildingAddress_AsString={homeSCSHEvaluation.buildingAddress_AsString}
          selectedEmail={undefined}
          evaluationStep={homeSCSHEvaluation?.step}
          selectedUserRoleTab={selectedUserRoleTab}
          isOnPaymentStep={false}
          isPaymentApplicable={false}
          paymentNextStepDisplayText="undefined"
          bypassPaymentStepForNonProfit={async () => {
            // Intentionally empty because SCSH does not use the payment step
          }}
          visibleTabs={visibleTabs}
          selectedTabId={selectedTabId}
          setSelectedTabId={setSelectedTabId}
          productTypeRoute={`${fortifiedHomeSCSHRoutePrefix}`}
          getApplicationFeePaymentCreditDetails={
            homeSCSHEvaluationAPIClient.getApplicationFeePaymentCreditDetails
          }
        />
      )}
    </div>
  );
};

export default HomeSCSHChecklist;
