import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import { BaseHomeRoofingComplianceRoofCoverFormParts } from "../../../common/types/BaseHomeEvaluationRoofingComplianceRoofCoverFormModel";
import { Evaluation_HomeStandardRedesignationFormModel } from "../../types/HomeStandardRedesignationFormModel_Model";
import { HomeStandardRedesignationRoofingComplianceRoofCoverFormModel } from "../../types/HomeStandardRedesignationRoofingComplianceRoofCoverFormModel";

class HomeStandardRedesignationRoofingComplianceRoofCoverAPIClient {
  updateHomeStandardRoofingComplianceRoofCoverForm = async (
    evaluationId: string,
    roofingComplianceRoofCoverForm: HomeStandardRedesignationRoofingComplianceRoofCoverFormModel,
    fieldKey?: string
  ): Promise<Evaluation_HomeStandardRedesignationFormModel> => {
    return baseApiClient.put<Evaluation_HomeStandardRedesignationFormModel>(
      `/home-standard/redesignation/roofing-compliance-roof-cover-form/${evaluationId}`,
      {
        roofingComplianceRoofCoverForm,
        fieldKey,
      }
    );
  };

  submitHomeStandardRoofingComplianceRoofCoverForm = async (
    evaluationId: string,
    form: HomeStandardRedesignationRoofingComplianceRoofCoverFormModel,
    formPart?: BaseHomeRoofingComplianceRoofCoverFormParts
  ): Promise<Evaluation_HomeStandardRedesignationFormModel> => {
    // If applicationFormPart is not provided, default to the last part of the form
    const formPartValue = (
      formPart ??
      (Object.values(
        BaseHomeRoofingComplianceRoofCoverFormParts
      ).pop() as BaseHomeRoofingComplianceRoofCoverFormParts)
    ).valueOf();
    return baseApiClient.post<Evaluation_HomeStandardRedesignationFormModel>(
      `/home-standard/redesignation/roofing-compliance-roof-cover-form/${evaluationId}/${formPartValue}`,
      form
    );
  };
}

export const homeStandardRedesignationRoofingComplianceRoofCoverAPIClient =
  new HomeStandardRedesignationRoofingComplianceRoofCoverAPIClient();
