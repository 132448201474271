import { Button, Icon, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { dialogsViewerStore } from "../../stores/DialogsViewerStore";
import { evaluationStore } from "../../stores/EvaluationStore";
import { permissionStore } from "../../stores/PermissionStore";
import { EvaluationStep } from "../../types/evaluation/Evaluation";
import ApproveEvaluationActionButton from "./EvaluationActions/ApproveEvaluationActionButton";
import ClaimEvaluationActionButton from "./EvaluationActions/ClaimEvaluationActionButton";
import DownloadCertificateActionButton from "./EvaluationActions/DownloadCertificateActionButton";
import { EvaluationLabel } from "./EvaluationActions/EvaluationLabel";
import LegacyEvaluationActionLink from "./EvaluationActions/LegacyEvaluationActionLink";
import RequestChangesActionButton from "./EvaluationActions/RequestChangesActionButton";
import SendForReviewActionButton from "./EvaluationActions/SendForReviewActionButton";
import SendForwardActionButton from "./EvaluationActions/SendForwardActionButton";
import ViewRejectionReasonsActionButton from "./EvaluationActions/ViewRejectionReasonsActionButton";
import WithdrawEvaluationActionButton from "./EvaluationActions/WithdrawEvaluationActionButton";
import LegacyEvaluationDataActionLink from "./EvaluationActions/LegacyEvaluationDataActionLink";
import PostDesignationFilesActionButton from "./EvaluationActions/PostDesignationFilesActionButton";
import {
  MessageComponent,
  Severity,
} from "@syncfusion/ej2-react-notifications";
import ResubmitEvaluationActionButton from "./EvaluationActions/ResubmitEvaluationActionButton";
import { Observer } from "mobx-react";
import ProceedToFCFMFPaymentActionButton from "../Checklist/ProceedToFCFMFPaymentActionButton";
import { UserRoleType } from "../../../common/Components/User/userTypes";
import TraineeSendForReviewActionButton from "./EvaluationActions/TraineeSendForReviewActionButton";
import TraineeClaimEvaluationActionButton from "./EvaluationActions/TraineeClaimEvaluationActionButton";
import TraineeReturnChangesRequiredActionButton from "./EvaluationActions/TraineeReturnChangesRequiredActionButton";
import { CommentType } from "../../../common/apiClients/comments/types";

interface EvaluationActionsBaseProps {
  shouldDisplayLabel: boolean;
  errors?: string[];
  step?: EvaluationStep;
  role?: UserRoleType;
}

interface ApproveProps {
  isApproveDisabled: boolean;
  approveDisabledTooltip?: string;
}

interface RejectProps {
  reject: () => void;
  isRejectDisabled: boolean;
}

interface RequestChangesProps {
  requestChanges: () => void;
  isRequestChangesDisabled: boolean;
}

interface SendForReviewProps {
  shouldDisplaySendForReview: boolean;
  isSendForReviewDisabled: boolean;
  sendForReview: () => Promise<void>;
}

interface SendForwardProps {
  shouldDisplaySendForward: boolean;
  isSendForwardDisabled: boolean;
}

interface TraineeClaimEvaluationProps {
  shouldDisplayTraineeClaimEvaluation: boolean;
  isTraineeClaimEvaluationDisabled: boolean;
}

interface TraineeSendForReviewProps {
  shouldDisplayTraineeSendForReview: boolean;
  isTraineeSendForReviewDisabled: boolean;
}

interface TraineeReturnChangesRequiredProps {
  shouldDisplayTraineeReturnChangesRequired: boolean;
  isTraineeReturnChangesRequiredDisabled: boolean;
}

type EvaluationActionsProps = EvaluationActionsBaseProps &
  ApproveProps &
  RejectProps &
  RequestChangesProps &
  SendForReviewProps &
  SendForwardProps &
  TraineeClaimEvaluationProps &
  TraineeSendForReviewProps &
  TraineeReturnChangesRequiredProps;

export const EvaluationActions: React.FC<EvaluationActionsProps> = ({
  shouldDisplayLabel,
  step,
  role,
  errors,
  isApproveDisabled,
  approveDisabledTooltip,
  requestChanges,
  isRequestChangesDisabled,
  reject,
  isRejectDisabled,
  shouldDisplaySendForReview,
  isSendForReviewDisabled,
  sendForReview,
  shouldDisplaySendForward,
  isSendForwardDisabled,
  shouldDisplayTraineeClaimEvaluation,
  isTraineeClaimEvaluationDisabled,
  shouldDisplayTraineeSendForReview,
  isTraineeSendForReviewDisabled,
  shouldDisplayTraineeReturnChangesRequired,
  isTraineeReturnChangesRequiredDisabled,
}) => {
  return (
    <Observer>
      {() => (
        <>
          <div className="evaluation-container__content__heading">
            {permissionStore.canViewInternalNotes && (
              <Button
                text="View Internal Notes"
                intent={Intent.PRIMARY}
                icon={IconNames.Comment}
                large
                onClick={() => {
                  dialogsViewerStore.setIsCommentsDialogOpen(true, {
                    evaluationId: evaluationStore.evaluation?.id || "",
                    fortifiedId: evaluationStore.evaluation?.fortifiedId || "",
                    productType_AsString:
                      evaluationStore.evaluation?.productType_AsString || "",
                    commentType: CommentType.Auditor,
                  });
                }}
              ></Button>
            )}
            {permissionStore.canViewAdminButton &&
              evaluationStore.evaluation?.id && (
                <Button
                  minimal
                  large
                  onClick={() => {
                    dialogsViewerStore.setIsAdminDialogOpen(true, {
                      evaluationId: evaluationStore.evaluation!.id,
                    });
                  }}
                >
                  <Icon icon={IconNames.COG} size={16} />
                </Button>
              )}
            {evaluationStore.isEvaluationOnRequiredPaymentStep && (
              <MessageComponent
                content={"Payment still needs to be submitted"}
                severity={Severity.Warning}
              />
            )}
            {evaluationStore.isEvaluationNonProfitOnPaymentStep && (
              <MessageComponent
                severity={Severity.Info}
                content={"Non-profit projects do not require payment"}
              />
            )}
            {evaluationStore.isLegacyEvaluation &&
              permissionStore.canViewLegacyEvaluationFiles && (
                <LegacyEvaluationActionLink />
              )}
            {evaluationStore.isLegacyEvaluation &&
              permissionStore.canViewLegacyEvaluationFiles && (
                <LegacyEvaluationDataActionLink />
              )}
            {permissionStore.canWithdraw(evaluationStore.evaluation) && (
              <WithdrawEvaluationActionButton />
            )}
            {evaluationStore.isFCFMFPaymentStep && (
              <ProceedToFCFMFPaymentActionButton />
            )}
            {evaluationStore.evaluation?.approvedAt && (
              <DownloadCertificateActionButton />
            )}
            {evaluationStore.evaluation?.isApproved === false && (
              <ViewRejectionReasonsActionButton />
            )}
            {evaluationStore.isApproved && (
              <PostDesignationFilesActionButton
                evaluationId={evaluationStore.evaluation?.id}
                rootBoxFolderId={evaluationStore.evaluation?.externalFolderId}
                postDesignationBoxFolderId={
                  evaluationStore.evaluation?.postDesignationBoxFolderId
                }
              />
            )}
            {evaluationStore.canResubmit && (
              <ResubmitEvaluationActionButton
                resubmit={evaluationStore.resubmitEvaluation}
              />
            )}
            {permissionStore.canClaimEvaluations && (
              <ClaimEvaluationActionButton
                isEvaluationClaimedByCurrentUser={
                  evaluationStore.isEvaluationClaimedByCurrentUser
                }
              />
            )}
            <div className="evaluation-container__content__heading__label">
              {shouldDisplayLabel && step && role && (
                <EvaluationLabel
                  step={step}
                  role={role}
                  canViewPendingLabels={permissionStore.canViewPendingLabels}
                />
              )}
            </div>
            <div className="evaluation-container__content__heading__actions">
              {permissionStore.canViewAuditorActionButtons && (
                <>
                  <ApproveEvaluationActionButton
                    isDisabled={isApproveDisabled}
                    disabledTooltip={approveDisabledTooltip}
                    errors={errors}
                  />

                  <RequestChangesActionButton
                    isRequestChangesDisabled={isRequestChangesDisabled}
                    requestChanges={requestChanges}
                  />

                  <Button
                    text="Reject"
                    intent={Intent.DANGER}
                    icon={IconNames.DELETE}
                    minimal
                    onClick={reject}
                    disabled={isRejectDisabled}
                  />
                </>
              )}

              <SendForReviewActionButton
                shouldDisplaySendForReview={shouldDisplaySendForReview}
                isSendForReviewDisabled={isSendForReviewDisabled}
                sendForReview={sendForReview}
                errors={errors}
              />

              {shouldDisplaySendForward && (
                <SendForwardActionButton
                  isSendForwardDisabled={isSendForwardDisabled}
                  errors={errors}
                />
              )}

              {!!evaluationStore.evaluation &&
                shouldDisplayTraineeClaimEvaluation && (
                  <TraineeClaimEvaluationActionButton
                    disabled={isTraineeClaimEvaluationDisabled}
                  />
                )}

              {!!evaluationStore.evaluation &&
                shouldDisplayTraineeSendForReview && (
                  <TraineeSendForReviewActionButton
                    evaluationId={evaluationStore.evaluation?.id}
                    disabled={isTraineeSendForReviewDisabled}
                  />
                )}
            </div>
          </div>
          {!!evaluationStore.evaluation &&
            shouldDisplayTraineeReturnChangesRequired && (
              <div className="evaluation-container__content__heading">
                <TraineeReturnChangesRequiredActionButton
                  evaluationId={evaluationStore.evaluation?.id}
                  disabled={isTraineeReturnChangesRequiredDisabled}
                />
              </div>
            )}
        </>
      )}
    </Observer>
  );
};
