import { FieldSchemaFormBuilderV2, SchemaFactoryV2 } from "@ucl/library";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../../types/HomeEvaluationFormModel";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { WildfireBoxContentExplorer } from "../../../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import { GableEndWallFramedTypes } from "../../../../common/types/BaseHomeEvaluationEngineeringComplianceGablesFormModel";
import { BuildingElementTypes } from "../../../../common/types/BaseHomeEvaluationEligibilityFormModel";
import { HazardTypes } from "../../../../../Common/types/Evaluation/BaseEvaluation";
import {
  GableConnectedBracedBottomTypes,
  GableConnectedBracedTopTypes,
  GableStudsBracedTypes,
} from "../../../../common/types/BaseHomeEvaluationInstallerComplianceGablesFormModel";

const modelName = "Evaluation";
const subModelName = "HomeStandardEvaluation";
const formName = "EngineeringComplianceGablesForm";

const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

const engineeringComplianceGablesFields = (
  form: Evaluation_HomeStandardEvaluationFormModel
) => [
  buildFieldKey("HowAreGableEndWallsFramed"),
  {
    fieldKey: buildFieldKey("Attestation207"),
    hidden: !(
      form.homeStandardEvaluation.engineeringComplianceGablesForm.howAreGableEndWallsFramed?.includes(
        GableEndWallFramedTypes.BalloonFramed
      ) &&
      form.homeStandardEvaluation.eligibilityForm.buildingElementsEngineerIsProvidingComplianceDesignInfoFor?.includes(
        BuildingElementTypes.GablesOver3Feet
      )
    ),
  },
  {
    fieldKey: buildFieldKey("Attestation208"),
    hidden: !(
      form.homeStandardEvaluation.engineeringComplianceGablesForm.howAreGableEndWallsFramed?.includes(
        GableEndWallFramedTypes.BalloonFramed
      ) &&
      form.homeStandardEvaluation.eligibilityForm.buildingElementsEngineerIsProvidingComplianceDesignInfoFor?.includes(
        BuildingElementTypes.GablesOver3Feet
      ) &&
      form.hazard == HazardTypes.Hurricane
    ),
  },
  {
    fieldKey: buildFieldKey("Attestation209"),
    hidden: !(
      form.homeStandardEvaluation.engineeringComplianceGablesForm.howAreGableEndWallsFramed?.includes(
        GableEndWallFramedTypes.BalloonFramed
      ) &&
      form.homeStandardEvaluation.eligibilityForm.buildingElementsEngineerIsProvidingComplianceDesignInfoFor?.includes(
        BuildingElementTypes.GableOverhangs
      )
    ),
  },
  {
    fieldKey: buildFieldKey("Attestation210"),
    hidden: !(
      form.homeStandardEvaluation.engineeringComplianceGablesForm.howAreGableEndWallsFramed?.includes(
        GableEndWallFramedTypes.PlatformFramed
      ) &&
      form.homeStandardEvaluation.eligibilityForm.buildingElementsEngineerIsProvidingComplianceDesignInfoFor?.includes(
        BuildingElementTypes.GablesOver3Feet
      )
    ),
  },
  {
    fieldKey: buildFieldKey("GableConnectedBraceTopType"),
    hidden: !(
      form.homeStandardEvaluation.engineeringComplianceGablesForm.howAreGableEndWallsFramed?.includes(
        GableEndWallFramedTypes.PlatformFramed
      ) &&
      form.homeStandardEvaluation.eligibilityForm.buildingElementsEngineerIsProvidingComplianceDesignInfoFor?.includes(
        BuildingElementTypes.GablesOver3Feet
      )
    ),
  },
  {
    fieldKey: buildFieldKey("GableConnectedBraceTopTypeOther"),
    hidden:
      !form.homeStandardEvaluation.engineeringComplianceGablesForm.gableConnectedBraceTopType?.includes(
        GableConnectedBracedTopTypes.Other
      ),
  },
  {
    fieldKey: buildFieldKey("Attestation212"),
    hidden: !(
      form.homeStandardEvaluation.engineeringComplianceGablesForm.howAreGableEndWallsFramed?.includes(
        GableEndWallFramedTypes.PlatformFramed
      ) &&
      form.homeStandardEvaluation.eligibilityForm.buildingElementsEngineerIsProvidingComplianceDesignInfoFor?.includes(
        BuildingElementTypes.GablesOver3Feet
      ) &&
      form.hazard == HazardTypes.Hurricane
    ),
  },
  {
    fieldKey: buildFieldKey("Attestation213"),
    hidden: !(
      form.homeStandardEvaluation.engineeringComplianceGablesForm.howAreGableEndWallsFramed?.includes(
        GableEndWallFramedTypes.PlatformFramed
      ) &&
      form.homeStandardEvaluation.eligibilityForm.buildingElementsEngineerIsProvidingComplianceDesignInfoFor?.includes(
        BuildingElementTypes.GableOverhangs
      )
    ),
  },
  {
    fieldKey: buildFieldKey("Attestation214"),
    hidden: !(
      form.homeStandardEvaluation.engineeringComplianceGablesForm.howAreGableEndWallsFramed?.includes(
        GableEndWallFramedTypes.PlatformFramed
      ) &&
      form.homeStandardEvaluation.eligibilityForm.buildingElementsEngineerIsProvidingComplianceDesignInfoFor?.includes(
        BuildingElementTypes.GablesOver3Feet
      )
    ),
  },
  {
    fieldKey: buildFieldKey("GableConnectedBraceBottomType"),
    hidden: !(
      form.homeStandardEvaluation.engineeringComplianceGablesForm.howAreGableEndWallsFramed?.includes(
        GableEndWallFramedTypes.PlatformFramed
      ) &&
      form.homeStandardEvaluation.eligibilityForm.buildingElementsEngineerIsProvidingComplianceDesignInfoFor?.includes(
        BuildingElementTypes.GablesOver3Feet
      )
    ),
  },
  {
    fieldKey: buildFieldKey("GableConnectedBraceBottomTypeOther"),
    hidden:
      !form.homeStandardEvaluation.engineeringComplianceGablesForm.gableConnectedBraceBottomType?.includes(
        GableConnectedBracedBottomTypes.Other
      ),
  },
  {
    fieldKey: buildFieldKey("Attestation216"),
    hidden: !(
      form.homeStandardEvaluation.engineeringComplianceGablesForm.howAreGableEndWallsFramed?.includes(
        GableEndWallFramedTypes.PlatformFramed
      ) &&
      form.homeStandardEvaluation.eligibilityForm.buildingElementsEngineerIsProvidingComplianceDesignInfoFor?.includes(
        BuildingElementTypes.GablesOver3Feet
      )
    ),
  },
  {
    fieldKey: buildFieldKey("GableStudsBracedType"),
    hidden: !(
      form.homeStandardEvaluation.engineeringComplianceGablesForm.howAreGableEndWallsFramed?.includes(
        GableEndWallFramedTypes.PlatformFramed
      ) &&
      form.homeStandardEvaluation.eligibilityForm.buildingElementsEngineerIsProvidingComplianceDesignInfoFor?.includes(
        BuildingElementTypes.GablesOver3Feet
      )
    ),
  },
  {
    fieldKey: buildFieldKey("GableStudsBracedTypeOther"),
    hidden:
      !form.homeStandardEvaluation.engineeringComplianceGablesForm.gableStudsBracedType?.includes(
        GableStudsBracedTypes.Other
      ),
  },
  {
    fieldKey: buildFieldKey("Attestation218"),
    hidden:
      !form.homeStandardEvaluation.eligibilityForm.buildingElementsEngineerIsProvidingComplianceDesignInfoFor?.includes(
        BuildingElementTypes.GablesOver3Feet
      ),
  },
];

const engineeringComplianceGablesRequiredFields = [
  buildFieldKey("HowAreGableEndWallsFramed"),
  buildFieldKey("Attestation207"),
  buildFieldKey("Attestation208"),
  buildFieldKey("Attestation209"),
  buildFieldKey("Attestation210"),
  buildFieldKey("GableConnectedBraceTopType"),
  buildFieldKey("GableConnectedBraceTopTypeOther"),
  buildFieldKey("Attestation212"),
  buildFieldKey("Attestation213"),
  buildFieldKey("Attestation214"),
  buildFieldKey("GableConnectedBraceBottomType"),
  buildFieldKey("GableConnectedBraceBottomTypeOther"),
  buildFieldKey("Attestation216"),
  buildFieldKey("GableStudsBracedType"),
  buildFieldKey("GableStudsBracedTypeOther"),
  buildFieldKey("Attestation218"),
];

// Full form builder with all parts included
export const engineeringComplianceGablesFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        engineeringComplianceGablesFields(form),
        onValueChange,
        form,
        errors,
        engineeringComplianceGablesRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Full form builder with all parts included, for Iteration Engine
export const engineeringComplianceGablesFormIterationEngineBuilder = (
  factory: SchemaFactoryV2,
  beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    let fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        engineeringComplianceGablesFields(form),
        onValueChange,
        form,
        errors,
        engineeringComplianceGablesRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    if (beforeBuild) {
      fields = beforeBuild(fields);
    }
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};
