import { FieldSchemaFormBuilderV2, SchemaFactoryV2 } from "@ucl/library";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import get from "lodash/get";
import {
  WildfireBoxContentExplorer,
  WildfireBoxContentExplorerProps,
} from "../../../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import { Evaluation_MultifamilyEvaluationFormModel } from "../../../types/MultifamilyEvaluationFormModel";
import { MultifamilyFormNameTypes } from "../../../types/MultifamilyEvaluation";
import { permissionStore } from "../../../../../../foritfied/stores/PermissionStore";

const modelName = "Evaluation";
const subModelName = "MultifamilyEvaluation";
const formName = MultifamilyFormNameTypes.ComplianceForm.fieldKey;

const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

const fileUploaderFields = (
  _form: Evaluation_MultifamilyEvaluationFormModel,
  errors?: { [key: string]: string[] }
) => [
  {
    fieldKey: buildFieldKey("ComplianceFormPhotoUploader"),
    defaultToUpload: false,
    errorMessages: get(errors, "complianceFormPhotoUploader.FileCount"),
    canDelete: permissionStore.canDeleteFilesFromSensitiveUploaders,
  } as WildfireBoxContentExplorerProps,
];

const complianceFormPhotoUploaderRequiredFields = [
  buildFieldKey("ComplianceFormPhotoUploader"),
];

const complianceFormFields = () => [buildFieldKey("EvaluatorEmail")];

export const multifamilyComplianceFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_MultifamilyEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...complianceFormFields(),
      ...fileUploaderFields(form, errors),
    ];

    const requiredFields = [...complianceFormPhotoUploaderRequiredFields];

    return factory.buildFieldSchemas<Evaluation_MultifamilyEvaluationFormModel>(
      modelName,
      fields,
      onValueChange,
      form,
      errors,
      requiredFields,
      {
        FileUploader: WildfireBoxContentExplorer,
      }
    );
  }) as FieldSchemaFormBuilderV2<Evaluation_MultifamilyEvaluationFormModel>;
};

export const multifamilyIterationEngineComplianceFormBuilder = (
  factory: SchemaFactoryV2,
  beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
): FieldSchemaFormBuilderV2<Evaluation_MultifamilyEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    let fields = [
      ...factory.buildFieldSchemas<Evaluation_MultifamilyEvaluationFormModel>(
        modelName,
        [...complianceFormFields(), ...fileUploaderFields(form, errors)],
        onValueChange,
        form,
        errors,
        [...complianceFormPhotoUploaderRequiredFields],
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    if (beforeBuild) {
      fields = beforeBuild(fields);
    }
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_MultifamilyEvaluationFormModel>;
};
