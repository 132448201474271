import { useContext, useEffect, useState } from "react";
import { UnsavedChangesBlockerContext } from "../../../../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import {
  FormChecklistPart,
  FormChecklistStatus,
} from "../../../../../../../common/types/Forms/FormChecklist";
import { HomeSchemaFactoryV2 } from "../../../../../customHooks/useHomeFieldSchemaFactory";
import { HomeStandardRedesignationRoofingComplianceGeneralFormContainerProps } from "./HomeStandardRedesignationRoofingComplianceGeneralFormContainer";
import { BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts } from "../../../../../common/types/BaseHomeEvaluationRoofingComplianceGeneralInformationFormModel";
import { AppHeaderContext } from "../../../../../../../wildfire/components/Provider/AppHeaderContext";
import { Evaluation_HomeStandardRedesignationFormModel } from "../../../../types/HomeStandardRedesignationFormModel_Model";
import { fortifiedFieldSchemaApiClient } from "../../../../../../Common/lib/apiClients/fortifiedFieldSchemaApiClient";
import {
  shouldHideAtticVentsFields,
  shouldHidePermitInformationFields,
  shouldHideSealedRoofDeckFields,
  shouldHideSiteConditionsFields,
} from "../../../../../common/components/FieldSchema/sharedConditionalLogic";

function useHomeStandardRedesignationRoofingComplianceGeneralInformationFormContainer(
  props: HomeStandardRedesignationRoofingComplianceGeneralFormContainerProps
) {
  const [schemaFactory, setSchemaFactory] = useState<HomeSchemaFactoryV2>();

  const getSchema = async () => {
    const schema = await fortifiedFieldSchemaApiClient.getSchema();
    const newSchemaFactory = new HomeSchemaFactoryV2(schema);
    setSchemaFactory(newSchemaFactory);
  };

  useEffect(() => {
    if (!schemaFactory) {
      getSchema();
    }
    return () => {
      setDefaultHeader();
    };
  }, []);

  useEffect(() => {
    setFormPart(getBestFormPart());
  }, [props.formParts]);

  const { setHasUnsavedChanges } = useContext(UnsavedChangesBlockerContext);

  const [formPart, setFormPart] = useState<
    BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts | undefined
  >(props.formParts);

  const [evaluationFormModel, setEvaluationFormModel] =
    useState<Evaluation_HomeStandardRedesignationFormModel>();

  const formModel =
    evaluationFormModel?.homeStandardRedesignation
      .roofingComplianceGeneralInformationForm;

  const { setFotifiedHomeEvaluationHeader, setDefaultHeader } =
    useContext(AppHeaderContext);

  useEffect(() => {
    if (evaluationFormModel) {
      setFotifiedHomeEvaluationHeader(evaluationFormModel);
    }
  }, [evaluationFormModel]);

  const getNextUnsubmittedFormPart = () => {
    if (!formModel) {
      return null;
    }

    if (!formModel.isBasicInformationComplete) {
      return BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.BasicInformation;
    } else if (!formModel.isPermitInformationComplete) {
      return BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.PermitInformation;
    } else if (!formModel.isSiteConditionsComplete) {
      return BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.SiteConditions;
    } else if (!formModel.isRoofStructureComplete) {
      return BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.RoofStructure;
    } else if (!formModel.isRoofSheathingComplete) {
      return BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.RoofSheathing;
    } else if (!formModel.isSealedRoofDeckComplete) {
      return BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.SealedRoofDeck;
    } else if (!formModel.isDripEdgeComplete) {
      return BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.DripEdge;
    } else if (!formModel.isAtticVentsComplete) {
      return BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.AtticVents;
    } else if (!formModel.isPhotoVoltaicPanelsComplete) {
      return BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.PhotoVoltaicPanels;
    } else if (!formModel.isSkyLightsComplete) {
      return BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.SkyLights;
    }

    // Form is complete
    return null;
  };

  const getBestFormPart =
    (): BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts => {
      // Default to the first part of the form if we can't find a better option
      const defaultFormPart = Object.values(
        BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts
      )[0];

      // If the application form part is provided (by URL), use it
      if (!!props.formParts) {
        return props.formParts;
      }

      // Otherwise, try to find the first unsubmitted part of the form
      // TODO: This doesn't work yet, needs to wait for applicationFormModel to load
      return getNextUnsubmittedFormPart() ?? defaultFormPart;
    };

  const getApplicationPartChecklistStatus = (
    formPart: BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts
  ): FormChecklistStatus => {
    if (formPart === getNextUnsubmittedFormPart()) {
      return FormChecklistStatus.NextUp;
    }

    switch (formPart) {
      case BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.BasicInformation:
        return formModel?.isBasicInformationComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.PermitInformation:
        return formModel?.isPermitInformationComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.SiteConditions:
        return formModel?.isSiteConditionsComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.RoofStructure:
        return formModel?.isRoofStructureComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.RoofSheathing:
        return formModel?.isRoofSheathingComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.SealedRoofDeck:
        return formModel?.isSealedRoofDeckComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.DripEdge:
        return formModel?.isDripEdgeComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.AtticVents:
        return formModel?.isAtticVentsComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.PhotoVoltaicPanels:
        return formModel?.isPhotoVoltaicPanelsComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.SkyLights:
        return formModel?.isSkyLightsComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      default:
        return FormChecklistStatus.Unsubmitted;
    }
  };

  const checklistParts: FormChecklistPart<BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts>[] =
    [
      {
        formPart:
          BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.BasicInformation,
        displayName: "Basic Information",
        requiredFieldCount: formModel?.basicInformationRequiredFieldCount,
      },
      {
        formPart:
          BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.PermitInformation,
        displayName: "Permit Information",
        requiredFieldCount: formModel?.permitInformationRequiredFieldCount,
        hidden: shouldHidePermitInformationFields(formModel?.wasPermitIssued),
      },
      {
        formPart:
          BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.SiteConditions,
        displayName: "Site Conditions",
        requiredFieldCount: formModel?.siteConditionsRequiredFieldCount,
        hidden: shouldHideSiteConditionsFields(
          evaluationFormModel?.homeStandardRedesignation.originEligibilityForm
            .newOrExistingRoof
        ),
      },
      {
        formPart:
          BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.RoofStructure,
        displayName: "Roof Structure",
        requiredFieldCount: formModel?.roofStructureRequiredFieldCount,
      },
      {
        formPart:
          BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.RoofSheathing,
        displayName: "Roof Sheathing",
        requiredFieldCount: formModel?.roofSheathingRequiredFieldCount,
      },
      {
        formPart:
          BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.SealedRoofDeck,
        displayName: "Sealed Roof Deck",
        requiredFieldCount: formModel?.sealedRoofDeckRequiredFieldCount,
        hidden: shouldHideSealedRoofDeckFields(
          formModel?.isEntireRoofLowSloped
        ),
      },
      {
        formPart:
          BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.DripEdge,
        displayName: "Drip Edge",
        requiredFieldCount: formModel?.dripEdgeRequiredFieldCount,
      },
      {
        formPart:
          BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.AtticVents,
        displayName: "Attic Vents",
        requiredFieldCount: formModel?.atticVentsRequiredFieldCount,
        hidden: shouldHideAtticVentsFields(evaluationFormModel?.hazard),
      },
      {
        formPart:
          BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.PhotoVoltaicPanels,
        displayName: "Photo Voltaic Panels",
        requiredFieldCount: formModel?.photoVoltaicPanelsRequiredFieldCount,
      },
      {
        formPart:
          BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.SkyLights,
        displayName: "Sky Lights",
        requiredFieldCount: formModel?.skyLightsRequiredFieldCount,
      },
    ];

  return {
    applicationChecklistParts: checklistParts,
    getApplicationPartChecklistStatus,
    setEvaluationFormModel,
    setHasUnsavedChanges,
    formPart,
    getBestFormPart,
    schemaFactory,
  };
}

export default useHomeStandardRedesignationRoofingComplianceGeneralInformationFormContainer;
