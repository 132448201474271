import { useContext, useEffect, useState } from "react";
import { UnsavedChangesBlockerContext } from "../../../../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import {
  FormChecklistPart,
  FormChecklistStatus,
} from "../../../../../../../common/types/Forms/FormChecklist";
import { HomeSchemaFactoryV2 } from "../../../../../customHooks/useHomeFieldSchemaFactory";
import { HomeNCIUAEvaluationFormContainerProps } from "./HomeNCIUAEvaluationFormContainer";
import { NCIUAEvaluationForm_FormParts } from "../../../../evaluation/types/HomeNCIUAEvaluation_EvaluationFormModel";
import { Evaluation_HomeNCIUAEvaluationFormModel } from "../../../../evaluation/types/HomeNCIUAEvaluationFormModel";
import { AppHeaderContext } from "../../../../../../../wildfire/components/Provider/AppHeaderContext";
import { fortifiedFieldSchemaApiClient } from "../../../../../../Common/lib/apiClients/fortifiedFieldSchemaApiClient";

function useHomeNCIUAEvaluationFormContainer(
  props: HomeNCIUAEvaluationFormContainerProps
) {
  const [homeSchemaFactory, setHomeSchemaFactory] =
    useState<HomeSchemaFactoryV2>();

  const getSchema = async () => {
    const schema = await fortifiedFieldSchemaApiClient.getSchema();
    const newSchemaFactory = new HomeSchemaFactoryV2(schema);
    setHomeSchemaFactory(newSchemaFactory);
  };

  useEffect(() => {
    if (!homeSchemaFactory) {
      getSchema();
    }
    return () => {
      setDefaultHeader();
    };
  }, []);

  useEffect(() => {
    setFormPart(getBestFormPart());
  }, [props.formPart]);

  const { setHasUnsavedChanges } = useContext(UnsavedChangesBlockerContext);

  const [formPart, setFormPart] = useState<
    NCIUAEvaluationForm_FormParts | undefined
  >(props.formPart);

  const [evaluationFormModel, setEvaluationFormModel] =
    useState<Evaluation_HomeNCIUAEvaluationFormModel>();

  const homeSCSHEvaluationForm =
    evaluationFormModel?.homeNCIUAEvaluation.homeNCIUAEvaluationForm;

  const { setFotifiedHomeEvaluationHeader, setDefaultHeader } =
    useContext(AppHeaderContext);

  useEffect(() => {
    if (evaluationFormModel) {
      setFotifiedHomeEvaluationHeader(evaluationFormModel);
    }
  }, [evaluationFormModel]);

  const getNextUnsubmittedFormPart = () => {
    if (!homeSCSHEvaluationForm) {
      return null;
    }

    if (!homeSCSHEvaluationForm.isInformationComplete) {
      return NCIUAEvaluationForm_FormParts.Information;
    }
    // Form is complete
    return null;
  };

  const getBestFormPart = (): NCIUAEvaluationForm_FormParts => {
    // Default to the first part of the form if we can't find a better option
    const defaultFormPart = Object.values(NCIUAEvaluationForm_FormParts)[0];

    // If the application form part is provided (by URL), use it
    if (!!props.formPart) {
      return props.formPart;
    }

    // Otherwise, try to find the first unsubmitted part of the form
    // TODO: This doesn't work yet, needs to wait for applicationFormModel to load
    return getNextUnsubmittedFormPart() ?? defaultFormPart;
  };

  const getApplicationPartChecklistStatus = (
    formPart: NCIUAEvaluationForm_FormParts
  ): FormChecklistStatus => {
    if (formPart === getNextUnsubmittedFormPart()) {
      return FormChecklistStatus.NextUp;
    }

    switch (formPart) {
      case NCIUAEvaluationForm_FormParts.Information:
        return homeSCSHEvaluationForm?.isInformationComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
    }
  };

  const checklistParts: FormChecklistPart<NCIUAEvaluationForm_FormParts>[] = [
    {
      formPart: NCIUAEvaluationForm_FormParts.Information,
      displayName: "Information",
      requiredFieldCount: homeSCSHEvaluationForm?.informationRequiredFieldCount,
    },
  ];

  return {
    checklistParts,
    getApplicationPartChecklistStatus,
    setEvaluationFormModel,
    setHasUnsavedChanges,
    formPart,
    getBestFormPart,
    homeSchemaFactory,
  };
}

export default useHomeNCIUAEvaluationFormContainer;
