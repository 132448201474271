import { useContext, useEffect, useState } from "react";
import { UnsavedChangesBlockerContext } from "../../../../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import {
  FormChecklistPart,
  FormChecklistStatus,
} from "../../../../../../../common/types/Forms/FormChecklist";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../../../types/HomeEvaluationFormModel";
import { HomeSchemaFactoryV2 } from "../../../../../customHooks/useHomeFieldSchemaFactory";
import { HomeStandardSilverGoldComplianceGeneralInformationFormContainerProps } from "./HomeStandardSilverGoldComplianceGeneralInformationFormContainer";
import { BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts } from "../../../../../common/types/BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormModel";
import { AppHeaderContext } from "../../../../../../../wildfire/components/Provider/AppHeaderContext";
import { YesOrNoSelection } from "../../../../../../../wildfire/types/FormFields";
import { HazardTypes } from "../../../../../../Common/types/Evaluation/BaseEvaluation";
import { fortifiedFieldSchemaApiClient } from "../../../../../../Common/lib/apiClients/fortifiedFieldSchemaApiClient";
import {
  shouldHideAttachedStructuresFields,
  shouldHideChimneysFields,
  shouldHideEntryDoorsFields,
  shouldHideGableEndsFields,
  shouldHideGoldComplianceInformation,
  shouldHideSoffitsFields,
} from "../../../../../common/components/FieldSchema/sharedConditionalLogic";

function useHomeStandardSilverGoldComplianceGeneralInformationFormContainer(
  props: HomeStandardSilverGoldComplianceGeneralInformationFormContainerProps
) {
  const [commercialSchemaFactory, setCommercialSchemaFactory] =
    useState<HomeSchemaFactoryV2>();

  const getSchema = async () => {
    const schema = await fortifiedFieldSchemaApiClient.getSchema();
    const newSchemaFactory = new HomeSchemaFactoryV2(schema);
    setCommercialSchemaFactory(newSchemaFactory);
  };

  useEffect(() => {
    if (!commercialSchemaFactory) {
      getSchema();
    }
    return () => {
      setDefaultHeader();
    };
  }, []);

  useEffect(() => {
    setFormPart(getBestFormPart());
  }, [props.silverGoldComplianceGeneralInformationFormPart]);

  const { setHasUnsavedChanges } = useContext(UnsavedChangesBlockerContext);

  const [formPart, setFormPart] = useState<
    | BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts
    | undefined
  >(props.silverGoldComplianceGeneralInformationFormPart);

  const [evaluationFormModel, setEvaluationFormModel] =
    useState<Evaluation_HomeStandardEvaluationFormModel>();

  const silverGoldComplianceGeneralInformationForm =
    evaluationFormModel?.homeStandardEvaluation
      .silverGoldComplianceGeneralInformationForm;

  const { setFotifiedHomeEvaluationHeader, setDefaultHeader } =
    useContext(AppHeaderContext);

  useEffect(() => {
    if (evaluationFormModel) {
      setFotifiedHomeEvaluationHeader(evaluationFormModel);
    }
  }, [evaluationFormModel]);

  const getNextUnsubmittedFormPart = () => {
    if (!silverGoldComplianceGeneralInformationForm) {
      return null;
    }

    if (
      !silverGoldComplianceGeneralInformationForm.isSiteDesignInformationComplete
    ) {
      return BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.SiteDesignInformation;
    } else if (
      !silverGoldComplianceGeneralInformationForm.isWindowsAndSkylightsComplete
    ) {
      return BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.WindowsAndSkylights;
    } else if (
      !silverGoldComplianceGeneralInformationForm.isEntryDoorsComplete
    ) {
      return BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.EntryDoors;
    } else if (!silverGoldComplianceGeneralInformationForm.isGarageComplete) {
      return BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.Garage;
    } else if (
      !silverGoldComplianceGeneralInformationForm.isGableEndsComplete
    ) {
      return BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.GableEnds;
    } else if (!silverGoldComplianceGeneralInformationForm.isSoffitsComplete) {
      return BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.Soffits;
    } else if (!silverGoldComplianceGeneralInformationForm.isChimneysComplete) {
      return BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.Chimneys;
    } else if (
      !silverGoldComplianceGeneralInformationForm.isAttachedStructuresComplete
    ) {
      return BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.AttachedStructures;
    } else if (
      !silverGoldComplianceGeneralInformationForm.isDesignPressureRatedOpeningsComplete
    ) {
      return BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.DesignPressureRatedOpenings;
    } else if (
      !silverGoldComplianceGeneralInformationForm.isWallSheathingComplete
    ) {
      return BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.WallSheathing;
    } else if (
      !silverGoldComplianceGeneralInformationForm.isContinuousLoadPathComplete
    ) {
      return BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.ContinuousLoadPath;
    }

    // Form is complete
    return null;
  };

  const getBestFormPart =
    (): BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts => {
      // Default to the first part of the form if we can't find a better option
      const availableParts = checklistParts.filter((x) => !x.hidden);
      const defaultFormPart =
        availableParts?.at(0)?.formPart ||
        Object.values(
          BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts
        )[0];

      // If the application form part is provided (by URL), use it
      if (!!props.silverGoldComplianceGeneralInformationFormPart) {
        return props.silverGoldComplianceGeneralInformationFormPart;
      }

      // Otherwise, try to find the first unsubmitted part of the form
      // TODO: This doesn't work yet, needs to wait for applicationFormModel to load
      return getNextUnsubmittedFormPart() ?? defaultFormPart;
    };

  const getApplicationPartChecklistStatus = (
    formPart: BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts
  ): FormChecklistStatus => {
    if (formPart === getNextUnsubmittedFormPart()) {
      return FormChecklistStatus.NextUp;
    }

    switch (formPart) {
      case BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.SiteDesignInformation:
        return silverGoldComplianceGeneralInformationForm?.isSiteDesignInformationComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.WindowsAndSkylights:
        return silverGoldComplianceGeneralInformationForm?.isWindowsAndSkylightsComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.EntryDoors:
        return silverGoldComplianceGeneralInformationForm?.isEntryDoorsComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.Garage:
        return silverGoldComplianceGeneralInformationForm?.isGarageComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.GableEnds:
        return silverGoldComplianceGeneralInformationForm?.isGableEndsComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.Soffits:
        return silverGoldComplianceGeneralInformationForm?.isSoffitsComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.Chimneys:
        return silverGoldComplianceGeneralInformationForm?.isChimneysComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.AttachedStructures:
        return silverGoldComplianceGeneralInformationForm?.isAttachedStructuresComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.DesignPressureRatedOpenings:
        return silverGoldComplianceGeneralInformationForm?.isDesignPressureRatedOpeningsComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.WallSheathing:
        return silverGoldComplianceGeneralInformationForm?.isWallSheathingComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.ContinuousLoadPath:
        return silverGoldComplianceGeneralInformationForm?.isContinuousLoadPathComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      default:
        return FormChecklistStatus.Unsubmitted;
    }
  };

  const checklistParts: FormChecklistPart<BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts>[] =
    [
      {
        formPart:
          BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.SiteDesignInformation,
        displayName: "Silver: Site Design information",
        requiredFieldCount:
          silverGoldComplianceGeneralInformationForm?.siteDesignInformationRequiredCount,
        hidden: !(
          evaluationFormModel?.homeStandardEvaluation?.eligibilityForm
            .isEngineerProvidingComplianceDesignInfoForSilverOrGoldRequirements ===
          YesOrNoSelection.Yes
        ),
      },
      {
        formPart:
          BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.WindowsAndSkylights,
        displayName: "Silver: Windows And Skylights",
        requiredFieldCount:
          silverGoldComplianceGeneralInformationForm?.windowsAndSkylightsRequiredCount,
        hidden: !(evaluationFormModel?.hazard === HazardTypes.Hurricane),
      },
      {
        formPart:
          BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.EntryDoors,
        displayName: "Silver: Entry Doors",
        requiredFieldCount:
          silverGoldComplianceGeneralInformationForm?.entryDoorsRequiredCount,
        hidden: shouldHideEntryDoorsFields(evaluationFormModel?.hazard),
      },
      {
        formPart:
          BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.Garage,
        displayName: "Silver: Garage",
        requiredFieldCount:
          silverGoldComplianceGeneralInformationForm?.garageRequiredCount,
      },
      {
        formPart:
          BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.GableEnds,
        displayName: "Silver: Gable Ends",
        requiredFieldCount:
          silverGoldComplianceGeneralInformationForm?.gableEndsRequiredCount,
        hidden: shouldHideGableEndsFields(
          evaluationFormModel?.homeStandardEvaluation.eligibilityForm
            .buildingElementTypes
        ),
      },
      {
        formPart:
          BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.Soffits,
        displayName: "Silver: Soffits",
        requiredFieldCount:
          silverGoldComplianceGeneralInformationForm?.soffitsRequiredCount,
        hidden: shouldHideSoffitsFields(evaluationFormModel?.hazard),
      },
      {
        formPart:
          BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.Chimneys,
        displayName: "Silver: Chimneys",
        requiredFieldCount:
          silverGoldComplianceGeneralInformationForm?.chimneysRequiredCount,
        hidden: shouldHideChimneysFields(
          evaluationFormModel?.homeStandardEvaluation.eligibilityForm
            .buildingElementTypes
        ),
      },
      {
        formPart:
          BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.AttachedStructures,
        displayName: "Silver: Attached Structures",
        requiredFieldCount:
          silverGoldComplianceGeneralInformationForm?.attachedStructuresRequiredCount,
        hidden: shouldHideAttachedStructuresFields(
          evaluationFormModel?.homeStandardEvaluation.eligibilityForm
            .buildingElementTypes
        ),
      },
      {
        formPart:
          BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.DesignPressureRatedOpenings,
        displayName: "Gold: Design Pressure Rated Openings",
        requiredFieldCount:
          silverGoldComplianceGeneralInformationForm?.designPressureRatedOpeningsRequiredCount,
        hidden: shouldHideGoldComplianceInformation(
          evaluationFormModel?.homeStandardEvaluation.eligibilityForm
            .designationLevel,
          evaluationFormModel?.hazard
        ),
      },
      {
        formPart:
          BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.WallSheathing,
        displayName: "Gold: Wall Sheathing",
        requiredFieldCount:
          silverGoldComplianceGeneralInformationForm?.wallSheathingRequiredCount,
        hidden: shouldHideGoldComplianceInformation(
          evaluationFormModel?.homeStandardEvaluation.eligibilityForm
            .designationLevel,
          evaluationFormModel?.hazard
        ),
      },
      {
        formPart:
          BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.ContinuousLoadPath,
        displayName: "Gold: Continuous Load Path",
        requiredFieldCount:
          silverGoldComplianceGeneralInformationForm?.continuousLoadPathRequiredCount,
        hidden: shouldHideGoldComplianceInformation(
          evaluationFormModel?.homeStandardEvaluation.eligibilityForm
            .designationLevel,
          evaluationFormModel?.hazard
        ),
      },
    ];

  return {
    applicationChecklistParts: checklistParts,
    getApplicationPartChecklistStatus,
    setEvaluationFormModel,
    setHasUnsavedChanges,
    applicationFormPart: formPart,
    getBestFormPart,
    commercialSchemaFactory,
  };
}

export default useHomeStandardSilverGoldComplianceGeneralInformationFormContainer;
