import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { useState } from "react";
import { EvaluationCommentV2 } from "../../../../wildfire/components/IterationEngine/types/EvaluationCommentV2";

export interface UseEvaluationCommentsProps {
  getEvaluationComments: (
    id: string,
    evaluationFieldKey: string
  ) => Promise<EvaluationCommentV2[]>;

  createEvaluationComments: (
    evaluationId: string,
    evaluationFieldKey: string,
    evaluationFieldLabel: string,
    comments: string[]
  ) => Promise<EvaluationCommentV2[]>;

  createEvaluationComment: (
    evaluationId: string,
    evaluationFieldKey: string,
    evaluationFieldLabel: string,
    comment: string,
    isPublic: boolean
  ) => Promise<EvaluationCommentV2[]>;

  deleteEvaluationComment: (
    evaluationId: string,
    evaluationFieldKey: string,
    evaluationCommentId: string
  ) => Promise<EvaluationCommentV2>;
}

export const useEvaluationIterationEngineComments = (
  props: UseEvaluationCommentsProps
) => {
  const [comments, setComments] = useState<EvaluationCommentV2[]>();

  const [selectedKey, setSelectedKey] = useState<string>();

  const [selectedField, setSelectedField] = useState<BaseFieldProps<any>>();

  const setSelectedKeyAndGetComments = async (
    evaluationId?: string,
    fieldKey?: string
  ) => {
    if (!evaluationId && !fieldKey) {
      setComments(undefined);
      setSelectedKey(undefined);
      setSelectedField(undefined);
      return;
    }

    if (!evaluationId || !fieldKey) return;

    // Fetch and set comments associated with the fieldKey

    setSelectedKey(fieldKey);
    setSelectedField(undefined);
    setComments(undefined);

    props
      .getEvaluationComments(evaluationId, fieldKey)
      .then((fetchedComments) => {
        setComments(fetchedComments);
      });
  };

  const setSelectedFieldAndGetComments = async (
    evaluationId: string,
    fieldProps: BaseFieldProps<any>
  ) => {
    if (!evaluationId) return;
    if (!fieldProps.fieldKey) return;

    // Fetch and set comments associated with the fieldKey
    setSelectedField(fieldProps);
    setSelectedKey(fieldProps.fieldKey);
    setComments(undefined);

    props
      .getEvaluationComments(evaluationId, fieldProps.fieldKey)
      .then((fetchedComments) => {
        setComments(fetchedComments);
      });
  };

  const createComments = async (
    evaluationId: string,
    comments: string[],
    evaluationFieldKey: string,
    evaluationFieldLabel: string
  ) => {
    if (!evaluationId) {
      throw new Error("Missing Evaluation Id");
    }

    const commentsResult = await props.createEvaluationComments(
      evaluationId,
      evaluationFieldKey,
      evaluationFieldLabel,
      comments
    );

    setComments(commentsResult);
  };

  const createComment = async (
    evaluationId: string,
    comment: string,
    evaluationFieldKey: string,
    evaluationFieldLabel: string,
    isPublic: boolean
  ) => {
    if (!evaluationId) {
      throw new Error("Missing Evaluation Id");
    }

    const comments = await props.createEvaluationComment(
      evaluationId,
      evaluationFieldKey,
      evaluationFieldLabel,
      comment,
      isPublic
    );

    setComments(comments);
  };

  const deleteComment = async (
    evaluationId: string,
    commentId: string,
    evaluationFieldKey: string
  ) => {
    if (!evaluationId) {
      throw new Error("Missing Evaluation Id");
    }

    await props.deleteEvaluationComment(
      evaluationId,
      evaluationFieldKey,
      commentId
    );

    setComments(comments?.filter((c) => c.id !== commentId));
  };

  return {
    comments,
    setComments,
    createComment,
    createComments,
    deleteComment,
    selectedKey,
    selectedField,
    setSelectedFieldAndGetComments,
    setSelectedKeyAndGetComments,
  };
};
