/* eslint-disable react/display-name */

import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import "./styles.scss";
import { forwardRef, useState } from "react";
import { ComplexCommercialEvaluationBuildingFormV2 } from "./ComplexCommercialEvaluationBuildingFormV2";
import {
  FieldFormV2Module,
  SecondaryCard,
  SecondaryCardHeader,
} from "@ucl/library";
import { evaluationApiClient } from "../../../../../foritfied/lib/apiClients/evaluation/evaluationApiClient";
import { useCommercialEvaluation } from "../../customHooks/useCommercialEvaluation";
import { Evaluation_CommercialEvaluationFormModel } from "../../types/CommercialEvaluationFormModel";

export interface ComplexCommercialEvaluationBuildingFormV2Props {
  id: string;
  buildingCount: number;
  isSaving: boolean;
  disabled?: boolean;
  setHasUnsavedChanges: (value: boolean) => void;
  isIterationEnginePage?: boolean;
  hideFormControls?: boolean;
  onInitialized?: () => void;
  onFormSave?: ((fieldKey: string, value: any) => Promise<void>) | undefined;
  getBuildings: () => Promise<void>;
  updateChildFormError: (formId: string, hasError: boolean) => void;
  isDeleteButtonDisabled: boolean;
  onParentNeedsRefresh?: () => Promise<void>;
}

const ComplexCommercialEvaluationBuildingCardV2 = forwardRef<
  FieldFormV2Module<Evaluation_CommercialEvaluationFormModel>,
  ComplexCommercialEvaluationBuildingFormV2Props
>((props, ref) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteBuilding = async () => {
    setIsDeleting(true);
    await evaluationApiClient
      .deleteEvaluation(props.id)
      .then(async () => {
        await props.getBuildings();

        if (props.onParentNeedsRefresh) {
          await props.onParentNeedsRefresh();
        }
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  const { commercialEvaluation } = useCommercialEvaluation(props.id);

  return (
    <>
      {commercialEvaluation && (
        <SecondaryCard
          id={`complex-building-form-${props.id}`}
          key={props.id}
          isCollapsible
          headerContent={
            <SecondaryCardHeader label={`Building ${props.buildingCount}`}>
              <h4 className="bp4-heading status-heading">{`Status: ${commercialEvaluation.status_AsString}`}</h4>
              <Button
                intent={Intent.DANGER}
                icon={IconNames.Trash}
                loading={isDeleting}
                disabled={props.disabled || props.isDeleteButtonDisabled}
                minimal
                large
                onClick={async () => {
                  await deleteBuilding();
                }}
              />
            </SecondaryCardHeader>
          }
        >
          <ComplexCommercialEvaluationBuildingFormV2
            complexCommercialEvaluationBuildingId={props.id}
            ref={ref}
            isDeleting={isDeleting}
            isSaving={props.isSaving}
            setHasUnsavedChanges={props.setHasUnsavedChanges}
            isIterationEnginePage={props.isIterationEnginePage}
            hideFormControls={props.hideFormControls}
            onInitialized={props.onInitialized}
            onFormSave={props.onFormSave}
            disabled={props.disabled}
            updateChildFormError={props.updateChildFormError}
            onParentNeedsRefresh={props.onParentNeedsRefresh}
          />
        </SecondaryCard>
      )}
    </>
  );
});

export default ComplexCommercialEvaluationBuildingCardV2;

(
  ComplexCommercialEvaluationBuildingCardV2 as React.ForwardRefExoticComponent<ComplexCommercialEvaluationBuildingFormV2Props>
).displayName = "ComplexCommercialEvaluationBuildingCardV2";
