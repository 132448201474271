import React, { useEffect } from "react";
import { AuditorchecklistItem } from "./AuditorChecklistItem";
import "./styles.scss";
import { auditorChecklistApiClient } from "../../../../../foritfied/lib/apiClients/auditorChecklist/auditorChecklistApiClient";
import { auditorChecklistStore } from "../../../../../foritfied/stores/AuditorChecklistStore";
import { Observer } from "mobx-react";

export interface AuditorchecklistPanelProps {
  evaluationId: string;
  formId: number;
}

export const AuditorChecklistPanel: React.FC<AuditorchecklistPanelProps> = ({
  evaluationId,
  formId,
}) => {
  useEffect(() => {
    auditorChecklistApiClient
      .getAuditorChecklists(evaluationId, formId)
      .then((checklists) => auditorChecklistStore.setChecklists(checklists));
  }, [formId]);
  return (
    <div className="auditor-checklist-panel">
      <Observer>
        {() => (
          <>
            {auditorChecklistStore.checklists.map((x) => (
              <AuditorchecklistItem checklistItem={x} key={x.id} />
            ))}
          </>
        )}
      </Observer>
    </div>
  );
};
