import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { FC } from "react";
import { reactPlugin } from "../../../../AppInsights";
import { UnSavedChangesBlockerProvider } from "../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import HomeStandardRedesignationRoofingComplianceGeneralFormContainer from "../components/Forms/RoofingComplianceGeneralInformationForm/Container/HomeStandardRedesignationRoofingComplianceGeneralFormContainer";
import { BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts } from "../../common/types/BaseHomeEvaluationRoofingComplianceGeneralInformationFormModel";

export interface HomeStandardRedesignationRoofingComplianceGeneralInformationPageProps {
  evaluationId?: string;
  formParts?: BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts;
}

const HomeStandardRedesignationRoofingComplianceGeneralInformationPageComponent: FC<
  HomeStandardRedesignationRoofingComplianceGeneralInformationPageProps
> = (props) => {
  return (
    <>
      {props.evaluationId && (
        <UnSavedChangesBlockerProvider>
          <HomeStandardRedesignationRoofingComplianceGeneralFormContainer
            evaluationId={props.evaluationId}
            formParts={props.formParts}
          />
        </UnSavedChangesBlockerProvider>
      )}
    </>
  );
};

export const HomeStandardRedesignationRoofingComplianceGeneralInformationPage =
  withAITracking(
    reactPlugin,
    HomeStandardRedesignationRoofingComplianceGeneralInformationPageComponent,
    "HomeStandardRedesignationRoofingComplianceGeneralInformationPage",
    "tracking-container"
  );
