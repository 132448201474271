import {
  EnumMultiSelectFieldProps,
  FieldSchemaFormBuilderV2,
  SchemaFactoryV2,
  TextFieldProps,
} from "@ucl/library";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../../types/HomeEvaluationFormModel";
import {
  BaseFieldProps,
  EnumMultiSelectFieldV2Value,
} from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { WildfireBoxContentExplorer } from "../../../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import {
  GableConnectedBracedBottomTypes,
  GableConnectedBracedTopTypes,
  PrescriptiveCodeForGableBracinngInstallationTypes,
} from "../../../../common/types/BaseHomeEvaluationInstallerComplianceGablesFormModel";
import { InstallerGableStudsBracedTypes } from "../../../../common/types/BaseHomeEvaluationInstallerComplianceChimneyFormModel";
import {
  shouldHideInstallerEngineeredGablesFields,
  shouldHideInstallerPrescriptiveGablesFields,
} from "../../../../common/components/FieldSchema/sharedConditionalLogic";

const modelName = "Evaluation";
const subModelName = "HomeStandardEvaluation";
const formName = "InstallerComplianceGablesForm";

const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

// Part 1: Installer Compliance Form General Information
const installerComplianceFormGeneralInformationFields = () => [
  buildFieldKey("PermitNumber"),
  buildFieldKey("DateInstallationWasCompleted"),
];

const installerComplianceFormGeneralInformationRequiredFields = [
  buildFieldKey("PermitNumber"),
  buildFieldKey("DateInstallationWasCompleted"),
];

export const installerComplianceFormGeneralInformationBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        installerComplianceFormGeneralInformationFields(),
        onValueChange,
        form,
        errors,
        installerComplianceFormGeneralInformationRequiredFields
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Part 2: Installer Compliance Form Engineered Gables
const installerComplianceFormEngineeredGablesFields = (
  form: Evaluation_HomeStandardEvaluationFormModel,
  shouldHideSection: (
    elements?: EnumMultiSelectFieldV2Value,
    engineered?: EnumMultiSelectFieldV2Value
  ) => boolean
) =>
  shouldHideSection(
    form.homeStandardEvaluation.eligibilityForm.buildingElementTypes,
    form.homeStandardEvaluation.eligibilityForm
      .buildingElementsEngineerIsProvidingComplianceDesignInfoFor
  )
    ? []
    : [
        buildFieldKey("Attestation281"),
        buildFieldKey("Attestation282"),
        buildFieldKey("Attestation283"),
        buildFieldKey("Attestation284"),
        buildFieldKey("GableConnectedBraceTopType"),
        {
          fieldKey: buildFieldKey("GableConnectedBraceTopTypeOther"),
          hidden:
            !form.homeStandardEvaluation.installerComplianceGablesForm.gableConnectedBraceTopType?.includes(
              GableConnectedBracedTopTypes.Other
            ),
        } as TextFieldProps,
        buildFieldKey("Attestation285"),
        buildFieldKey("GableConnectedBraceBottomType"),
        {
          fieldKey: buildFieldKey("GableConnectedBraceBottomTypeOther"),
          hidden:
            !form.homeStandardEvaluation.installerComplianceGablesForm.gableConnectedBraceBottomType?.includes(
              GableConnectedBracedBottomTypes.Other
            ),
        } as TextFieldProps,
        buildFieldKey("Attestation286"),
        {
          fieldKey: buildFieldKey("InstallerGableStudsBracedType"),
        } as EnumMultiSelectFieldProps,
        {
          fieldKey: buildFieldKey("InstallerGableStudsBracedTypeOther"),
          hidden:
            !form.homeStandardEvaluation.installerComplianceGablesForm.installerGableStudsBracedType?.includes(
              InstallerGableStudsBracedTypes.Other
            ),
        } as TextFieldProps,
        buildFieldKey("Attestation291"),
      ];

const installerComplianceFormEngineeredGablesRequiredFields = [
  buildFieldKey("Attestation281"),
  buildFieldKey("Attestation282"),
  buildFieldKey("Attestation283"),
  buildFieldKey("Attestation284"),
  buildFieldKey("GableConnectedBraceTopType"),
  buildFieldKey("GableConnectedBraceTopTypeOther"),
  buildFieldKey("Attestation285"),
  buildFieldKey("GableConnectedBraceBottomType"),
  buildFieldKey("GableConnectedBraceBottomTypeOther"),
  buildFieldKey("Attestation286"),
  buildFieldKey("InstallerGableStudsBracedType"),
  buildFieldKey("InstallerGableStudsBracedTypeOther"),
  buildFieldKey("Attestation291"),
];

export const installerComplianceFormEngineeredGablesBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        installerComplianceFormEngineeredGablesFields(
          form,
          shouldHideInstallerEngineeredGablesFields
        ),
        onValueChange,
        form,
        errors,
        installerComplianceFormEngineeredGablesRequiredFields
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Part 3: Installer Compliance Form Prescriptive Gables
const installerComplianceFormPrescriptiveGablesFields = (
  form: Evaluation_HomeStandardEvaluationFormModel,
  shouldHideSection: (
    elements?: EnumMultiSelectFieldV2Value,
    engineered?: EnumMultiSelectFieldV2Value
  ) => boolean
) =>
  shouldHideSection(
    form.homeStandardEvaluation.eligibilityForm.buildingElementTypes,
    form.homeStandardEvaluation.eligibilityForm
      .buildingElementsEngineerIsProvidingComplianceDesignInfoFor
  )
    ? []
    : [
        buildFieldKey("Attestation287"),
        buildFieldKey("Attestation288"),
        buildFieldKey("PrescriptiveCodeForGableBracingInstallation"),
        {
          fieldKey: buildFieldKey(
            "PrescriptiveCodeForGableBracingInstallationDescription"
          ),
          hidden: !(
            form.homeStandardEvaluation.installerComplianceGablesForm
              .prescriptiveCodeForGableBracingInstallation ===
            PrescriptiveCodeForGableBracinngInstallationTypes.Other
          ),
        } as TextFieldProps,
        buildFieldKey("GableConnectedBraceTopType"),
        {
          fieldKey: buildFieldKey("GableConnectedBraceTopTypeOther"),
          hidden:
            !form.homeStandardEvaluation.installerComplianceGablesForm.gableConnectedBraceTopType?.includes(
              GableConnectedBracedTopTypes.Other
            ),
        } as TextFieldProps,
        buildFieldKey("Attestation282"),
        buildFieldKey("GableConnectedBraceBottomType"),
        {
          fieldKey: buildFieldKey("GableConnectedBraceBottomTypeOther"),
          hidden:
            !form.homeStandardEvaluation.installerComplianceGablesForm.gableConnectedBraceBottomType?.includes(
              GableConnectedBracedBottomTypes.Other
            ),
        } as TextFieldProps,
        buildFieldKey("PrescriptiveGableStudsBracedType"),
      ];

const installerComplianceFormPrescriptiveGablesRequiredFields = [
  buildFieldKey("Attestation287"),
  buildFieldKey("Attestation288"),
  buildFieldKey("PrescriptiveCodeForGableBracingInstallation"),
  buildFieldKey("PrescriptiveCodeForGableBracingInstallationDescription"),
  buildFieldKey("GableConnectedBraceTopType"),
  buildFieldKey("GableConnectedBraceTopTypeOther"),
  buildFieldKey("Attestation282"),
  buildFieldKey("GableConnectedBraceBottomType"),
  buildFieldKey("GableConnectedBraceBottomTypeOther"),
  buildFieldKey("PrescriptiveGableStudsBracedType"),
];

export const installerComplianceFormPrescriptiveGablesBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        installerComplianceFormPrescriptiveGablesFields(
          form,
          shouldHideInstallerPrescriptiveGablesFields
        ),
        onValueChange,
        form,
        errors,
        installerComplianceFormPrescriptiveGablesRequiredFields
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Full form builder with all parts included
export const homeStandardInstallerComplianceGablesBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        [
          ...installerComplianceFormGeneralInformationFields(),
          ...installerComplianceFormEngineeredGablesFields(
            form,
            shouldHideInstallerEngineeredGablesFields
          ),
          ...installerComplianceFormPrescriptiveGablesFields(
            form,
            shouldHideInstallerPrescriptiveGablesFields
          ),
        ],
        onValueChange,
        form,
        errors,
        [
          ...installerComplianceFormGeneralInformationRequiredFields,
          ...installerComplianceFormEngineeredGablesRequiredFields,
          ...installerComplianceFormPrescriptiveGablesRequiredFields,
        ],
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Full form builder with all parts included, for Iteration Engine
export const homeStandardIterationEngineInstallerComplianceGablesFormBuilder = (
  factory: SchemaFactoryV2,
  beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    let fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        [
          ...installerComplianceFormGeneralInformationFields(),
          ...installerComplianceFormEngineeredGablesFields(
            form,
            shouldHideInstallerEngineeredGablesFields
          ),
          ...installerComplianceFormPrescriptiveGablesFields(
            form,
            shouldHideInstallerPrescriptiveGablesFields
          ),
        ],
        onValueChange,
        form,
        errors,
        [
          ...installerComplianceFormGeneralInformationRequiredFields,
          ...installerComplianceFormEngineeredGablesRequiredFields,
          ...installerComplianceFormPrescriptiveGablesRequiredFields,
        ],
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    if (beforeBuild) {
      fields = beforeBuild(fields);
    }
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};
