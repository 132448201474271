import { useRef, useState } from "react";
import { Evaluation_MultifamilyEvaluationFormModel } from "../../../../../types/MultifamilyEvaluationFormModel";
import { FieldFormV2Module } from "@ucl/library";
import { useFCFMFFieldSchemaFactory } from "../../../../../../common/customHooks/useFCFMFFieldSchemaFactory";
import { SimpleMultifamilyApplicationFormProps } from "./SimpleMultifamilyApplicationForm";

function useSimpleMultifamilyApplicationForm(
  props: SimpleMultifamilyApplicationFormProps
) {
  const { builders } = useFCFMFFieldSchemaFactory();

  const formRef =
    useRef<FieldFormV2Module<Evaluation_MultifamilyEvaluationFormModel>>(null);

  const [applicationFormModel] =
    useState<Evaluation_MultifamilyEvaluationFormModel>(props.form);

  const containerRef = useRef<HTMLDivElement>(null);

  const noBuilders = !builders;

  const getApplicationFormPartBuilder = () => {
    return builders?.MultifamilyExternalApplicationForm;
  };

  return {
    builders,
    formRef,
    applicationFormModel,
    getApplicationFormPartBuilder,
    noBuilders,
    containerRef,
  };
}

export default useSimpleMultifamilyApplicationForm;
