import { action, makeObservable, observable } from "mobx";
import { appSchemaApiClient } from "../lib/apiClients/general/appSchemaApiClient";
import { AppSchema } from "../types/schema";
import { Option } from "@ucl/library/lib/components/Fields/types/fieldTypes";

class AppSchemaStore {
  public schema?: AppSchema;

  constructor() {
    makeObservable(this, {
      schema: observable,
      init: action,
    });
  }

  public async init() {
    if (!this.schema) {
      this.setSchema();
    }
  }

  public async setSchema() {
    this.schema = await appSchemaApiClient.getAppSchema();
  }

  get userRoleOptions() {
    if (!this.schema) {
      return [];
    }

    return this.schema.userRoles.map<Option<number>>((userRole) => ({
      label: userRole.label,
      value: userRole.id,
    }));
  }

  get homeAdminStatusOptions() {
    if (!this.schema) {
      return [];
    }

    return this.schema.homeAdminStatuses.map<Option<number>>((status) => ({
      label: status.label,
      value: status.id,
    }));
  }

  get homeMilestoneAdminStatusOptions() {
    if (!this.schema) {
      return [];
    }

    return this.schema.homeMilestoneAdminStatuses.map<Option<number>>(
      (status) => ({
        label: status.label,
        value: status.id,
      })
    );
  }

  get commercialAdminStatusOptions() {
    if (!this.schema) {
      return [];
    }

    return this.schema.commercialAdminStatuses.map<Option<number>>(
      (status) => ({
        label: status.label,
        value: status.id,
      })
    );
  }

  get designationLevelOptions() {
    if (!this.schema) {
      return [];
    }

    return this.schema.designationLevels.map<Option<number>>((level) => ({
      label: level.label,
      value: level.id,
    }));
  }

  get hazardOptions() {
    if (!this.schema) {
      return [];
    }

    return this.schema.hazards.map<Option<number>>((type) => ({
      label: type.label,
      value: type.id,
    }));
  }
}

export const schemaStore = new AppSchemaStore();
