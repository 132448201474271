import { FeatureToggleSet } from "../FeatureToggles";
import baseExternalApiClient from "../../../../foritfied/lib/apiClients/baseExternalApiClient";

class FeatureToggleApiClient {
  getFeatureToggleSet = async () => {
    return baseExternalApiClient.get<FeatureToggleSet>("feature-toggle/toggle");
  };
}

//TODO - Convet this to Wildfire based toggle API client
export const featureToggleApiClient = new FeatureToggleApiClient();
