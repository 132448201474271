import "./styles.scss";
import { ProductTypes } from "../../../../../common/types/ProductTypes";
import {
  CommentTemplateDialogFormModel,
  CommentTemplateFormProps,
} from "./types";
import { CommentTemplateCommonForm } from "./CommentTemplateCommonForm";
import { WildfireFormSections } from "../../../../../common/types/FormTypes";

const WILDFIRE_PREPARED_FORM_ID = "comment-template-wildfire-form";

export const CommentTemplateWildfireForm: React.FC<
  CommentTemplateFormProps
> = ({ id, refreshGrid }) => {
  const defaultFormValue: CommentTemplateDialogFormModel = {
    productType: ProductTypes.WildFirePrepared.toString(),
    formType: "",
    formFieldKey: "",
    templateName: "",
    templateContent: "",
  };

  return (
    <CommentTemplateCommonForm
      id={id}
      defaultFormValue={defaultFormValue}
      formId={WILDFIRE_PREPARED_FORM_ID}
      showProductType={false}
      refreshGrid={() => refreshGrid()}
      getWildfireFormFields={WildfireFormSections.getFormFields}
    />
  );
};
