import {
  FieldSchemaFormBuilderConfigV2,
  FieldSchemaFormBuilderV2,
  SchemaFactoryV2,
} from "@ucl/library";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import {
  commercialApplicationFormBuilder,
  commercialApplicationInformationBuilder,
  commercialConfirmationFieldsBuilder,
  commercialEmailNotificationsBuilder,
  commercialExistingConstructionReroofingBuilder,
  commercialExistingRoofCoverSystemFieldsBuilder,
  commercialExternalApplicationFormBuilder,
  commercialFileUploaderFieldsBuilder,
  commercialGeneralProjectDetailsBuilder,
  commercialNewConstructionsAndNewAdditionsBuilder,
  commercialProjectConstructionDetailsFieldsBuilder,
} from "../../../commercial/components/forms/ApplicationForm/CommercialApplicationFormBuilder";
import { commercialEvaluationCompanyAssignmentFormBuilder } from "../../../commercial/components/forms/EvaluationCompanyAssignmentForm/CommercialEvaluationCompanyAssignmentFormBuilder";
import { multifamilyEvaluationCompanyAssignmentFormBuilder } from "../../../multifamily/components/forms/EvaluationCompanyAssignmentForm/MultifamilyEvaluationCompanyAssignmentFormBuilder";
import { commercialEvaluationFieldInspectionFormBuilder } from "../../../commercial/components/forms/EvaluationFieldInspectionForm/CommercialEvaluationFieldInspectionFormBuilder";
import { multifamilyEvaluationFieldInspectionFormBuilder } from "../../../multifamily/components/forms/EvaluationFieldInspectionForm/MultifamilyEvaluationFieldInspectionFormBuilder";
import { commercialComplianceFormBuilder } from "../../../commercial/components/forms/ComplianceForm/CommercialComplianceFormBuilder";
import { multifamilyComplianceFormBuilder } from "../../../multifamily/components/forms/ComplianceForm/MultifamilyComplianceFormBuilder";
import { commercialComplianceLetterBuilder } from "../../../commercial/components/forms/ComplianceLetter/CommercialComplianceLetterBuilder";
import { multifamilyComplianceLetterBuilder } from "../../../multifamily/components/forms/ComplianceLetter/MultifamilyComplianceLetterBuilder";
import {
  commercialRedesignationApplicationInformationBuilder,
  commercialRedesignationFormBuilder,
  commercialRedesignationGeneralDetailsBuilder,
} from "../../../commercialRedesignation/components/forms/ApplicationForm/CommercialRedesignationFormBuilder";
import {
  multifamilyRedesignationApplicationInformationBuilder,
  multifamilyRedesignationFormBuilder,
  multifamilyRedesignationGeneralDetailsBuilder,
} from "../../../multifamilyRedesignation/components/forms/ApplicationForm/MultifamilyRedesignationFormBuilder";
import { commercialRedesignationEvaluatorEmailFormBuilder } from "../../../commercialRedesignation/components/forms/RedesignationEvaluatorEmailForm/CommercialRedesignationEvaluatorEmailFormBuilder";
import { multifamilyRedesignationEvaluatorEmailFormBuilder } from "../../../multifamilyRedesignation/components/forms/RedesignationEvaluatorEmailForm/MultifamilyRedesignationEvaluatorEmailFormBuilder";
import {
  multifamilyApplicationFormBuilder,
  multifamilyApplicationInformationBuilder,
  multifamilyConfirmationFieldsBuilder,
  multifamilyEmailNotificationsBuilder,
  multifamilyExistingConstructionReroofingBuilder,
  multifamilyExistingRoofCoverSystemFieldsBuilder,
  multifamilyExternalApplicationFormBuilder,
  multifamilyFileUploaderFieldsBuilder,
  multifamilyGeneralProjectDetailsBuilder,
  multifamilyNewConstructionsAndNewAdditionsBuilder,
  multifamilyProjectConstructionDetailsFieldsBuilder,
} from "../../../multifamily/components/forms/ApplicationForm/MultifamilyApplicationFormBuilder";
import { commercialBuildingApplicationFormBuilder } from "../../../commercial/components/ComplexCommercialEvaluationBuilding/Form/CommercialBuildingApplicationFormBuilder";
import { multifamilyBuildingApplicationFormBuilder } from "../../../multifamily/components/ComplexCommercialEvaluationBuilding/Form/MultifamilyBuildingApplicationFormBuilder";

export interface FCFMFFieldSchemaModel {}
export type FCFMFFieldSchemaFormType =
  typeof FCFMFFieldSchemaFormTypeList[number];
export type FCFMFFieldSchemaConfiguration = Record<
  FCFMFFieldSchemaFormType,
  FieldSchemaFormBuilderConfigV2 | undefined
>;

export const FCFMFFieldSchemaFormTypeList = [
  // Commercial Application Form Types
  "CommercialApplicationForm",
  "CommercialExternalApplicationForm",
  "CommercialApplicationInformation",
  "CommercialEmailNotifications",
  "CommercialGeneralProjectDetails",
  "CommercialNewConstructionsAndNewAdditions",
  "CommercialExistingConstructionReroofing",
  "CommercialProjectConstructionDetails",
  "CommercialExistingRoofCoverSystems",
  "CommercialFileUploaders",
  "CommercialConfirmation",

  // Commercial Compliance Form Types
  "CommercialComplianceForm",

  // Commercial Compliance Letter Form Types
  "CommercialComplianceLetter",

  "CommercialEvaluationCompanyAssignmentForm",

  "CommercialEvaluationFieldInspectionForm",
  "CommercialBuildingApplicationForm",

  // Commercial Redesignation Form Types
  "CommercialRedesignationForm",
  "CommercialRedesignationApplicationInformation",
  "CommercialRedesignationGeneralDetails",
  "CommercialRedesignationEvaluationEmailForm",

  // Multifamily Application Form Types
  "MultifamilyApplicationForm",
  "MultifamilyExternalApplicationForm",
  "MultifamilyApplicationInformation",
  "MultifamilyEmailNotifications",
  "MultifamilyGeneralProjectDetails",
  "MultifamilyNewConstructionsAndNewAdditions",
  "MultifamilyExistingConstructionReroofing",
  "MultifamilyProjectConstructionDetails",
  "MultifamilyExistingRoofCoverSystems",
  "MultifamilyFileUploaders",
  "MultifamilyConfirmation",

  "MultifamilyEvaluationCompanyAssignmentForm",

  "MultifamilyEvaluationFieldInspectionForm",
  "MultifamilyBuildingApplicationForm",

  // Multifamily Compliance Form Types
  "MultifamilyComplianceForm",

  // Multifamily Compliance Letter Form Types
  "MultifamilyComplianceLetter",

  // Multifamily Redesignation Form Types
  "MultifamilyRedesignationForm",
  "MultifamilyRedesignationApplicationInformation",
  "MultifamilyRedesignationGeneralDetails",
  "MultifamilyRedesignationEvaluatorEmailForm",
] as const;

export const commercialFieldSchemaConfig: FCFMFFieldSchemaConfiguration = {
  // Commercial Application Form Types
  CommercialApplicationForm: undefined,
  CommercialExternalApplicationForm: undefined,
  CommercialApplicationInformation: undefined,
  CommercialEmailNotifications: undefined,
  CommercialGeneralProjectDetails: undefined,
  CommercialNewConstructionsAndNewAdditions: undefined,
  CommercialExistingConstructionReroofing: undefined,
  CommercialProjectConstructionDetails: undefined,
  CommercialExistingRoofCoverSystems: undefined,
  CommercialFileUploaders: undefined,
  CommercialConfirmation: undefined,

  // Commercial Compliance Form Types
  CommercialComplianceForm: undefined,

  // Commercial Compliance Letter Form Types
  CommercialComplianceLetter: undefined,

  CommercialEvaluationCompanyAssignmentForm: undefined,

  CommercialEvaluationFieldInspectionForm: undefined,
  CommercialBuildingApplicationForm: undefined,

  // Commercial Redesignation Form Types
  CommercialRedesignationForm: undefined,
  CommercialRedesignationApplicationInformation: undefined,
  CommercialRedesignationGeneralDetails: undefined,
  CommercialRedesignationEvaluationEmailForm: undefined,

  // Multifamily Application Form Types
  MultifamilyApplicationForm: undefined,
  MultifamilyExternalApplicationForm: undefined,
  MultifamilyApplicationInformation: undefined,
  MultifamilyEmailNotifications: undefined,
  MultifamilyGeneralProjectDetails: undefined,
  MultifamilyNewConstructionsAndNewAdditions: undefined,
  MultifamilyExistingConstructionReroofing: undefined,
  MultifamilyProjectConstructionDetails: undefined,
  MultifamilyExistingRoofCoverSystems: undefined,
  MultifamilyFileUploaders: undefined,
  MultifamilyConfirmation: undefined,

  MultifamilyEvaluationCompanyAssignmentForm: undefined,

  MultifamilyEvaluationFieldInspectionForm: undefined,
  // Multifamily Compliance Form Types
  MultifamilyComplianceForm: undefined,

  // Multifamily Compliance Letter Form Types
  MultifamilyComplianceLetter: undefined,
  MultifamilyBuildingApplicationForm: undefined,

  // Multifamily Redesignation Form Types
  MultifamilyRedesignationForm: undefined,
  MultifamilyRedesignationApplicationInformation: undefined,
  MultifamilyRedesignationGeneralDetails: undefined,
  MultifamilyRedesignationEvaluatorEmailForm: undefined,
};

export type FCFMFCustomFieldSchemaFormBuildersConfig = Record<
  FCFMFFieldSchemaFormType,
  (
    factory: SchemaFactoryV2,
    beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
  ) => Partial<FieldSchemaFormBuilderV2<FCFMFFieldSchemaModel>>
>;
export const fcfmfCustomFieldSchemaFormBuilders: FCFMFCustomFieldSchemaFormBuildersConfig =
  {
    //Commercial Application Form Types
    CommercialApplicationForm: commercialApplicationFormBuilder,
    CommercialExternalApplicationForm: commercialExternalApplicationFormBuilder,
    CommercialApplicationInformation: commercialApplicationInformationBuilder,
    CommercialEmailNotifications: commercialEmailNotificationsBuilder,
    CommercialGeneralProjectDetails: commercialGeneralProjectDetailsBuilder,
    CommercialNewConstructionsAndNewAdditions:
      commercialNewConstructionsAndNewAdditionsBuilder,
    CommercialExistingConstructionReroofing:
      commercialExistingConstructionReroofingBuilder,
    CommercialProjectConstructionDetails:
      commercialProjectConstructionDetailsFieldsBuilder,
    CommercialExistingRoofCoverSystems:
      commercialExistingRoofCoverSystemFieldsBuilder,
    CommercialFileUploaders: commercialFileUploaderFieldsBuilder,
    CommercialConfirmation: commercialConfirmationFieldsBuilder,
    CommercialComplianceForm: commercialComplianceFormBuilder,
    CommercialComplianceLetter: commercialComplianceLetterBuilder,
    CommercialEvaluationCompanyAssignmentForm:
      commercialEvaluationCompanyAssignmentFormBuilder,
    CommercialEvaluationFieldInspectionForm:
      commercialEvaluationFieldInspectionFormBuilder,
    CommercialBuildingApplicationForm: commercialBuildingApplicationFormBuilder,

    //Commercial Redesignation Form Types
    CommercialRedesignationForm: commercialRedesignationFormBuilder,
    CommercialRedesignationApplicationInformation:
      commercialRedesignationApplicationInformationBuilder,
    CommercialRedesignationGeneralDetails:
      commercialRedesignationGeneralDetailsBuilder,
    CommercialRedesignationEvaluationEmailForm:
      commercialRedesignationEvaluatorEmailFormBuilder,

    //Multifamily Application Form Types
    MultifamilyApplicationForm: multifamilyApplicationFormBuilder,
    MultifamilyExternalApplicationForm:
      multifamilyExternalApplicationFormBuilder,
    MultifamilyApplicationInformation: multifamilyApplicationInformationBuilder,
    MultifamilyEmailNotifications: multifamilyEmailNotificationsBuilder,
    MultifamilyGeneralProjectDetails: multifamilyGeneralProjectDetailsBuilder,
    MultifamilyNewConstructionsAndNewAdditions:
      multifamilyNewConstructionsAndNewAdditionsBuilder,
    MultifamilyExistingConstructionReroofing:
      multifamilyExistingConstructionReroofingBuilder,
    MultifamilyProjectConstructionDetails:
      multifamilyProjectConstructionDetailsFieldsBuilder,
    MultifamilyExistingRoofCoverSystems:
      multifamilyExistingRoofCoverSystemFieldsBuilder,
    MultifamilyFileUploaders: multifamilyFileUploaderFieldsBuilder,
    MultifamilyConfirmation: multifamilyConfirmationFieldsBuilder,
    MultifamilyEvaluationCompanyAssignmentForm:
      multifamilyEvaluationCompanyAssignmentFormBuilder,
    MultifamilyEvaluationFieldInspectionForm:
      multifamilyEvaluationFieldInspectionFormBuilder,
    MultifamilyComplianceForm: multifamilyComplianceFormBuilder,
    MultifamilyComplianceLetter: multifamilyComplianceLetterBuilder,
    MultifamilyBuildingApplicationForm:
      multifamilyBuildingApplicationFormBuilder,

    // Multifamily Redesignation Form Types
    MultifamilyRedesignationForm: multifamilyRedesignationFormBuilder,
    MultifamilyRedesignationApplicationInformation:
      multifamilyRedesignationApplicationInformationBuilder,
    MultifamilyRedesignationGeneralDetails:
      multifamilyRedesignationGeneralDetailsBuilder,
    MultifamilyRedesignationEvaluatorEmailForm:
      multifamilyRedesignationEvaluatorEmailFormBuilder,
  };
