import {
  homeStandardBasicInformationFormBuilder,
  homeStandardHomeownerInformationBuilder,
} from "../../../standard/components/Forms/BasicInformationForm/HomeStandardBasicInformationFormBuilder";
import {
  externalRooferConfirmationFormBuilder,
  rooferConfirmationFormBuilder,
} from "../../../standard/components/Forms/RooferConfirmationForm/HomeStandardRooferConfirmationFormBuilder";
import {
  homeStandardAtticVentsBuilder,
  homeStandardBasicInformationBuilder,
  homeStandardDripEdgeBuilder,
  homeStandardExternalRoofingComplianceGeneralInformationFormBuilder,
  homeStandardPermitInformationBuilder,
  homeStandardPhotoVoltaicPanelsBuilder,
  homeStandardRoofingComplianceGeneralInformationFormBuilder,
  homeStandardRoofSheathingBuilder,
  homeStandardRoofStructureBuilder,
  homeStandardSealedRoofDeckBuilder,
  homeStandardSiteConditionsBuilder,
  homeStandardSkyLightsBuilder,
} from "../../../standard/components/Forms/RoofingComplianceGeneralInformationForm/HomeStandardRoofingComplianceGeneralInformationFormBuilder";
import {
  asphaltShingleRoofCoverDetailsBuilder,
  clayConcreteTileRoofCoverDetailsBuilder,
  homeStandardExternalRoofingComplianceRoofCoverFormBuilder,
  homeStandardRoofingComplianceRoofCoverFormBuilder,
  lowSlopeRoofCoverDetailsFieldsBuilder,
  metalRoofCoverDetailsFieldsBuilder,
  roofingComplianceRoofCoverInformationBuilder,
  woodShakeShingleRoofCoverDetailsFieldsBuilder,
} from "../../../standard/components/Forms/RoofingComplianceRoofCoverForm/HomeStandardRoofingComplianceRoofCoverFormBuilder";
import { homeAuthorizationFormBuilder } from "../../../standardRedesignation/components/Forms/HomeownerAuthorizationForm/HomeStandardHomeownerAuthorizationFormBuilder";
import {
  engineerConfirmationFormBuilder,
  externalEngineerConfirmationFormBuilder,
} from "../../../standard/components/Forms/EngineerConfirmationForm/HomeStandardEngineerConfirmationFormBuilder";
import {
  silverGoldAttachedStructuresFormBuilder,
  silverGoldChimneysFormBuilder,
  silverGoldComplianceGeneralInformationFormBuilder,
  silverGoldContinuousLoadPathFormBuilder,
  silverGoldDesignPressureRatedOpeningsFormBuilder,
  silverGoldEntryDoorsFormBuilder,
  silverGoldGableEndsFormBuilder,
  silverGoldGarageFormBuilder,
  silverGoldSiteDesignInformationFormBuilder,
  silverGoldSoffitsFormBuilder,
  silverGoldWallSheathingFormBuilder,
  silverGoldWindowsAndSkylightsFormBuilder,
} from "../../../standard/components/Forms/SilverGoldComplianceGeneralInformationForm/HomeStandardSilverGoldComplianceGeneralInformationFormBuilder";
import {
  externalInstallingContractorConfirmationFormBuilder,
  installingContractorConfirmationFormBuilder,
} from "../../../standard/components/Forms/InstallingContractorConfirmationForm/HomeStandardInstallingContractorConfirmationFormBuilder";
import {
  homeStandardInstallerComplianceGablesBuilder,
  installerComplianceFormEngineeredGablesBuilder,
  installerComplianceFormGeneralInformationBuilder,
  installerComplianceFormPrescriptiveGablesBuilder,
} from "../../../standard/components/Forms/InstallerComplianceGablesForm/HomeStandardInstallerComplianceGablesFormBuilder";
import {
  homeStandardInstallerComplianceContinuousLoadPathBuilder,
  installerComplianceFormContinuousLoadPathForNewConstructionBuilder,
  installerComplianceFormContinuousLoadPathForRetrofitOfExistingHomeBuilder,
  installerComplianceFormContinuousLoadPathForWoodFramingBuilder,
  installerComplianceFormContinuousLoadPathGeneralInformationBuilder,
} from "../../../standard/components/Forms/InstallerComplianceContinuousLoadPathForm/HomeStandardInstallerComplianceContinuousLoadPathFormBuilder";
import {
  homeStandardInstallerComplianceChimneyBuilder,
  installerComplianceFormChimneyGeneralInformationBuilder,
  installerComplianceFormEngineeredChimneyBuilder,
  installerComplianceFormPrescriptiveChimneyBuilder,
} from "../../../standard/components/Forms/InstallerComplianceChimneyForm/HomeStandardInstallerComplianceChimneyFormBuilder";
import {
  homeStandardExternalInstallerComplianceAttachedStructureBuilder,
  homeStandardInstallerComplianceAttachedStructureBuilder,
  installerComplianceFormAttachedStructureGeneralInformationBuilder,
  installerComplianceFormEngineeredAttachedStructureBuilder,
  installerComplianceFormPrescriptiveAttachedStructureBuilder,
} from "../../../standard/components/Forms/InstallerComplianceAttachedStructureForm/HomeStandardInstallerComplianceAttachedStructureFormBuilder";
import {
  engineeringComplianceGeneralInformationFormBuilder,
  externalEngineeringComplianceGeneralInformationFormBuilder,
} from "../../../standard/components/Forms/EngineeringComplianceGeneralInformationForm/HomeStandardEngineeringComplianceGeneralInformationFormBuilder";
import { engineeringComplianceGablesFormBuilder } from "../../../standard/components/Forms/EngineeringComplianceGablesForm/HomeStandardEngineeringComplianceGablesFormBuilder";
import {
  engineeringComplianceFormContinuousLoadPathForNewConstructionBuilder,
  engineeringComplianceFormContinuousLoadPathForRetrofitOfExistingHomeBuilder,
  homeStandardEngineeringComplianceContinuousLoadPathBuilder,
} from "../../../standard/components/Forms/EngineeringComplianceContinuousLoadPathForm/HomeStandardEngineeringComplianceContinuousLoadPathFormBuilder";
import { homeStandardEngineeringComplianceChimneyFormBuilder } from "../../../standard/components/Forms/EngineeringComplianceChimneyForm/HomeStandardEngineeringComplianceChimneyFormBuilder";
import { homeStandardEngineeringComplianceAttachedStructureBuilder } from "../../../standard/components/Forms/EngineeringComplianceAttachedStructureForm/HomeStandardEngineeringComplianceAttachedStructureFormBuilder";
import {
  homeStandardDesignationTypeAndLevelInformationFieldsBuilder,
  homeStandardEligibilityFormBuilder,
  homeStandardEligibilityInformationBuilder,
} from "../../../standard/components/Forms/EligibilityForm/HomeStandardEligibilityFormBuilder";
import { homeStandardRedesignationFormBuilder } from "../../../standardRedesignation/components/Forms/RedesignationForm/HomeStandardRedesignationFormBuilder";
import {
  homeStandardRedesignationAtticVentsBuilder,
  homeStandardRedesignationBasicInformationBuilder,
  homeStandardRedesignationDripEdgeBuilder,
  homeStandardRedesignationPermitInformationBuilder,
  homeStandardRedesignationPhotoVoltaicPanelsBuilder,
  homeStandardRedesignationRoofingComplianceGeneralInformationFormBuilder,
  homeStandardRedesignationRoofSheathingBuilder,
  homeStandardRedesignationRoofStructureBuilder,
  homeStandardRedesignationSealedRoofDeckBuilder,
  homeStandardRedesignationSiteConditionsBuilder,
  homeStandardRedesignationSkyLightsBuilder,
} from "../../../standardRedesignation/components/Forms/RoofingComplianceGeneralInformationForm/HomeStandardRedesignationRoofingComplianceGeneralInformationFormBuilder";
import {
  homeStandardRedesignationAsphaltShingleRoofCoverDetailsBuilder,
  homeStandardRedesignationClayConcreteTileRoofCoverDetailsBuilder,
  homeStandardRedesignationLowSlopeRoofCoverDetailsFieldsBuilder,
  homeStandardRedesignationMetalRoofCoverDetailsFieldsBuilder,
  homeStandardRedesignationRoofCoverInformationBuilder,
  homeStandardRedesignationRoofingComplianceRoofCoverFormBuilder,
  homeStandardRedesignationWoodShakeShingleRoofCoverDetailsFieldsBuilder,
} from "../../../standardRedesignation/components/Forms/RoofingComplianceRoofCoverForm/HomeStandardRedesignationRoofingComplianceRoofCoverFormBuilder";
import { homeStandardRedesignationRooferConfirmationFormBuilder } from "../../../standardRedesignation/components/Forms/RooferConfirmationForm/HomeStandardRedesignationRooferConfirmationFormBuilder";

export const HomeStandardBasicInformationForm = [
  "HomeStandardBasicInformationForm",
  "HomeStandardHomeownerInformation",
  "HomeStandardPropertyDetails",
  "HomeStandardConfirmationAffirmations",
];

export const HomeStandardEligibilityForm = [
  "HomeStandardEligibilityForm",
  "HomeStandardDesignationTypeAndLevelInformation",
  "HomeStandardEligibilityInformation",
];

export const HomeStandardRoofingComplianceGeneralInformationForm = [
  "HomeStandardRoofingComplianceGeneralInformationForm",
  "HomeStandardExternalRoofingComplianceGeneralInformationForm",
  "HomeStandardRoofingComplianceBasicInformationForm",
  "HomeStandardRoofStructureForm",
  "HomeStandardRoofSheathingForm",
  "HomeStandardDripEdgeForm",
  "HomeStandardSkyLightsForm",
  "HomeStandardPhotoVoltaicPanelsForm",
  "HomeStandardAtticVentsForm",
  "HomeStandardPermitInformationForm",
  "HomeStandardSiteConditionsForm",
  "HomeStandardSealedRoofDeckForm",
];

export const HomeStandardRoofingComplianceRoofCoverForm = [
  "HomeStandardRoofingComplianceRoofCoverForm",
  "HomeStandardExternalRoofingComplianceRoofCoverForm",
  "HomeStandardRoofCoverInformation",
  "HomeStandardAsphaltShingleRoofCoverDetails",
  "HomeStandardMetalRoofCoverDetails",
  "HomeStandardClayConcreteTileRoofCoverDetails",
  "HomeStandardWoodShakeSingleRoofCoverDetails",
  "HomeStandardLowSlopeRoofCoverDetails",
];

export const HomeStandardInstallerComplianceGablesForm = [
  "HomeStandardInstallerComplianceGablesForm",
  "HomeStandardInstallerComplianceFormGeneralInformationForm",
  "HomeStandardInstallerComplianceFormEngineeredGables",
  "HomeStandardInstallerComplianceFormPrescriptiveGables",
];

export const HomeStandardInstallerComplianceContinuousLoadPathForm = [
  "HomeStandardInstallerComplianceContinuousLoadPathForm",
  "HomeStandardInstallerComplianceFormContinuousLoadPathGeneralInformation",
  "HomeStandardInstallerComplianceFormContinuousLoadPathForNewConstruction",
  "HomeStandardInstallerComplianceFormContinuousLoadPathForRetrofitOfExistingHome",
  "HomeStandardInstallerComplianceFormContinuousLoadPathForWoodFraming",
];

export const HomeStandardInstallerComplianceChimneyForm = [
  "HomeStandardInstallerComplianceChimneyForm",
  "HomeStandardInstallerComplianceChimneyFormGeneralInformation",
  "HomeStandardInstallerComplianceChimneyFormEngineeredChimney",
  "HomeStandardInstallerComplianceChimneyFormPrescriptiveChimney",
];

export const HomeStandardInstallerComplianceAttachedStructureForm = [
  "HomeStandardInstallerComplianceAttachedStructureForm",
  "HomeStandardExternalInstallerComplianceAttachedStructureForm",
  "HomeStandardInstallerComplianceAttachedStructureFormGeneralInformation",
  "HomeStandardInstallerComplianceAttachedStructureFormEngineeredAttachedStructure",
  "HomeStandardInstallerComplianceAttachedStructureFormPrescriptiveAttachedStructure",
];

export const HomeStandardEngineeringComplianceContinuousLoadPathForm = [
  "HomeStandardEngineeringComplianceContinuousLoadPathForm",
  "HomeStandardEngineeringComplianceFormContinuousLoadPathForNewConstruction",
  "HomeStandardEngineeringComplianceFormContinuousLoadPathForRetrofitOfExistingHome",
];

export const HomeStandardRooferConfirmationForm = [
  "HomeStandardRooferConfirmationForm",
  "HomeStandardExternalRooferConfirmationForm",
];

export const HomeStandardHomeownerAuthorizationForm = [
  "HomeStandardHomeownerAuthorizationForm",
];

export const HomeStandardEngineerConfirmationForm = [
  "HomeStandardEngineerConfirmationForm",
  "HomeStandardExternalEngineerConfirmationForm",
];

export const HomeStandardSilverGoldComplianceGeneralInformationForm = [
  "HomeStandardSilverGoldComplianceGeneralInformationForm",
  "HomeStandardSilverGoldSiteDesignInformation",
  "HomeStandardSilverGoldWindowsAndSkylights",
  "HomeStandardSilverGoldEntryDoors",
  "HomeStandardSilverGoldGarage",
  "HomeStandardSilverGoldGableEnds",
  "HomeStandardSilverGoldSoffits",
  "HomeStandardSilverGoldChimneys",
  "HomeStandardSilverGoldAttachedStructures",
  "HomeStandardSilverGoldPressureRatedOpenings",
  "HomeStandardSilverGoldWallSheathing",
  "HomeStandardSilverContinuousLoadPath",
];

export const HomeStandardInstallingContractorConfirmationForm = [
  "HomeStandardInstallingContractorConfirmationForm",
  "HomeStandardExternalInstallingContractorConfirmationForm",
];

export const HomeStandardEngineeringComplianceGeneralInformationForm = [
  "HomeStandardEngineeringComplianceGeneralInformationForm",
  "HomeStandardExternalEngineeringComplianceGeneralInformationForm",
];

export const HomeStandardEngineeringComplianceGablesForm = [
  "HomeStandardEngineeringComplianceGablesForm",
];

export const HomeStandardEngineeringComplianceChimneyForm = [
  "HomeStandardEngineeringComplianceChimneyForm",
];

export const HomeStandardEngineeringComplianceAttachedStructureForm = [
  "HomeStandardEngineeringComplianceAttachedStructureForm",
];

export const HomeStandardRedesignationForm = ["HomeStandardRedesignationForm"];

export const HomeStandardRedesignationRoofingComplianceGeneralInformationForm =
  [
    "HomeStandardRedesignationRoofingComplianceGeneralInformationForm",
    "HomeStandardRedesignationRoofingComplianceBasicInformationForm",
    "HomeStandardRedesignationRoofStructureForm",
    "HomeStandardRedesignationRoofSheathingForm",
    "HomeStandardRedesignationDripEdgeForm",
    "HomeStandardRedesignationSkyLightsForm",
    "HomeStandardRedesignationPhotoVoltaicPanelsForm",
    "HomeStandardRedesignationAtticVentsForm",
    "HomeStandardRedesignationPermitInformationForm",
    "HomeStandardRedesignationSiteConditionsForm",
    "HomeStandardRedesignationSealedRoofDeckForm",
  ];

export const HomeStandardRedesignationRoofingComplianceRoofCoverForm = [
  "HomeStandardRedesignationRoofingComplianceRoofCoverForm",
  "HomeStandardRedesignationRoofCoverInformation",
  "HomeStandardRedesignationAsphaltShingleRoofCoverDetails",
  "HomeStandardRedesignationMetalRoofCoverDetails",
  "HomeStandardRedesignationClayConcreteTileRoofCoverDetails",
  "HomeStandardRedesignationWoodShakeSingleRoofCoverDetails",
  "HomeStandardRedesignationLowSlopeRoofCoverDetails",
];

export const HomeStandardRedesignationRooferConfirmationForm = [
  "HomeStandardRedesignationRooferConfirmationForm",
];

export const HomeStandardForms = [
  ...HomeStandardBasicInformationForm,
  ...HomeStandardEligibilityForm,
  ...HomeStandardRooferConfirmationForm,
  ...HomeStandardRoofingComplianceGeneralInformationForm,
  ...HomeStandardRoofingComplianceRoofCoverForm,
  ...HomeStandardHomeownerAuthorizationForm,
  ...HomeStandardEngineerConfirmationForm,
  ...HomeStandardSilverGoldComplianceGeneralInformationForm,
  ...HomeStandardInstallingContractorConfirmationForm,
  ...HomeStandardInstallerComplianceGablesForm,
  ...HomeStandardInstallerComplianceContinuousLoadPathForm,
  ...HomeStandardInstallerComplianceChimneyForm,
  ...HomeStandardInstallerComplianceAttachedStructureForm,
  ...HomeStandardEngineeringComplianceGeneralInformationForm,
  ...HomeStandardEngineeringComplianceGablesForm,
  ...HomeStandardEngineeringComplianceContinuousLoadPathForm,
  ...HomeStandardEngineeringComplianceChimneyForm,
  ...HomeStandardEngineeringComplianceAttachedStructureForm,
  ...HomeStandardRedesignationForm,
  ...HomeStandardRedesignationRoofingComplianceGeneralInformationForm,
  ...HomeStandardRedesignationRoofingComplianceRoofCoverForm,
  ...HomeStandardRedesignationRooferConfirmationForm,
];

export const HomeStandardFormsConfig = {
  HomeStandardBasicInformationForm: undefined,
  HomeStandardHomeownerInformation: undefined,
  HomeStandardPropertyDetails: undefined,
  HomeStandardConfirmationAffirmations: undefined,
  HomeStandardEligibilityForm: undefined,
  HomeStandardDesignationTypeAndLevelInformation: undefined,
  HomeStandardEligibilityInformation: undefined,
  HomeStandardRooferConfirmationForm: undefined,
  HomeStandardRoofingComplianceGeneralInformationForm: undefined,
  HomeStandardRoofingComplianceBasicInformationForm: undefined,
  HomeStandardPermitInformationForm: undefined,
  HomeStandardSiteConditionsForm: undefined,
  HomeStandardRoofStructureForm: undefined,
  HomeStandardRoofSheathingForm: undefined,
  HomeStandardSealedRoofDeckForm: undefined,
  HomeStandardDripEdgeForm: undefined,
  HomeStandardAtticVentsForm: undefined,
  HomeStandardPhotoVoltaicPanelsForm: undefined,
  HomeStandardSkyLightsForm: undefined,
  HomeStandardRoofingComplianceRoofCoverForm: undefined,
  HomeStandardRoofCoverInformation: undefined,
  HomeStandardAsphaltShingleRoofCoverDetails: undefined,
  HomeStandardMetalRoofCoverDetails: undefined,
  HomeStandardClayConcreteTileRoofCoverDetails: undefined,
  HomeStandardWoodShakeSingleRoofCoverDetails: undefined,
  HomeStandardLowSlopeRoofCoverDetails: undefined,
  HomeStandardHomeownerAuthorizationForm: undefined,
  HomeStandardEngineerConfirmationForm: undefined,
  HomeStandardSilverGoldComplianceGeneralInformationForm: undefined,
  HomeStandardSilverGoldSiteDesignInformation: undefined,
  HomeStandardSilverGoldWindowsAndSkylights: undefined,
  HomeStandardSilverGoldEntryDoors: undefined,
  HomeStandardSilverGoldGarage: undefined,
  HomeStandardSilverGoldGableEnds: undefined,
  HomeStandardSilverGoldSoffits: undefined,
  HomeStandardSilverGoldChimneys: undefined,
  HomeStandardSilverGoldAttachedStructures: undefined,
  HomeStandardSilverGoldPressureRatedOpenings: undefined,
  HomeStandardSilverGoldWallSheathing: undefined,
  HomeStandardSilverContinuousLoadPath: undefined,
  HomeStandardInstallingContractorConfirmationForm: undefined,
  HomeStandardInstallerComplianceGablesForm: undefined,
  HomeStandardInstallerComplianceFormGeneralInformationForm: undefined,
  HomeStandardInstallerComplianceFormEngineeredGables: undefined,
  HomeStandardInstallerComplianceFormPrescriptiveGables: undefined,
  HomeStandardInstallerComplianceContinuousLoadPathForm: undefined,
  HomeStandardInstallerComplianceFormContinuousLoadPathGeneralInformation:
    undefined,
  HomeStandardInstallerComplianceFormContinuousLoadPathForNewConstruction:
    undefined,
  HomeStandardInstallerComplianceFormContinuousLoadPathForRetrofitOfExistingHome:
    undefined,
  HomeStandardInstallerComplianceFormContinuousLoadPathForWoodFraming:
    undefined,
  HomeStandardInstallerComplianceChimneyForm: undefined,
  HomeStandardInstallerComplianceChimneyFormGeneralInformation: undefined,
  HomeStandardInstallerComplianceChimneyFormEngineeredChimney: undefined,
  HomeStandardInstallerComplianceChimneyFormPrescriptiveChimney: undefined,
  HomeStandardInstallerComplianceAttachedStructureForm: undefined,
  HomeStandardInstallerComplianceAttachedStructureFormGeneralInformation:
    undefined,
  HomeStandardInstallerComplianceAttachedStructureFormEngineeredAttachedStructure:
    undefined,
  HomeStandardInstallerComplianceAttachedStructureFormPrescriptiveAttachedStructure:
    undefined,
  HomeStandardEngineeringComplianceGeneralInformationForm: undefined,
  HomeStandardEngineeringComplianceGablesForm: undefined,
  HomeStandardEngineeringComplianceContinuousLoadPathForm: undefined,
  HomeStandardEngineeringComplianceFormContinuousLoadPathForNewConstruction:
    undefined,
  HomeStandardEngineeringComplianceFormContinuousLoadPathForRetrofitOfExistingHome:
    undefined,
  HomeStandardEngineeringComplianceChimneyForm: undefined,
  HomeStandardEngineeringComplianceAttachedStructureForm: undefined,
  HomeStandardRedesignationForm: undefined,
};

export const HomeStandardBuilders = {
  HomeStandardBasicInformationForm: homeStandardBasicInformationFormBuilder,
  HomeStandardHomeownerInformation: homeStandardHomeownerInformationBuilder,
  HomeStandardEligibilityForm: homeStandardEligibilityFormBuilder,
  HomeStandardDesignationTypeAndLevelInformation:
    homeStandardDesignationTypeAndLevelInformationFieldsBuilder,
  HomeStandardEligibilityInformation: homeStandardEligibilityInformationBuilder,
  HomeStandardRooferConfirmationForm: rooferConfirmationFormBuilder,
  HomeStandardExternalRooferConfirmationForm:
    externalRooferConfirmationFormBuilder,
  HomeStandardRoofingComplianceGeneralInformationForm:
    homeStandardRoofingComplianceGeneralInformationFormBuilder,
  HomeStandardExternalRoofingComplianceGeneralInformationForm:
    homeStandardExternalRoofingComplianceGeneralInformationFormBuilder,
  HomeStandardRoofingComplianceBasicInformationForm:
    homeStandardBasicInformationBuilder,
  HomeStandardPermitInformationForm: homeStandardPermitInformationBuilder,
  HomeStandardSiteConditionsForm: homeStandardSiteConditionsBuilder,
  HomeStandardRoofStructureForm: homeStandardRoofStructureBuilder,
  HomeStandardRoofSheathingForm: homeStandardRoofSheathingBuilder,
  HomeStandardSealedRoofDeckForm: homeStandardSealedRoofDeckBuilder,
  HomeStandardDripEdgeForm: homeStandardDripEdgeBuilder,
  HomeStandardAtticVentsForm: homeStandardAtticVentsBuilder,
  HomeStandardPhotoVoltaicPanelsForm: homeStandardPhotoVoltaicPanelsBuilder,
  HomeStandardSkyLightsForm: homeStandardSkyLightsBuilder,
  HomeStandardRoofingComplianceRoofCoverForm:
    homeStandardRoofingComplianceRoofCoverFormBuilder,
  HomeStandardExternalRoofingComplianceRoofCoverForm:
    homeStandardExternalRoofingComplianceRoofCoverFormBuilder,
  HomeStandardRoofCoverInformation:
    roofingComplianceRoofCoverInformationBuilder,
  HomeStandardAsphaltShingleRoofCoverDetails:
    asphaltShingleRoofCoverDetailsBuilder,
  HomeStandardMetalRoofCoverDetails: metalRoofCoverDetailsFieldsBuilder,
  HomeStandardClayConcreteTileRoofCoverDetails:
    clayConcreteTileRoofCoverDetailsBuilder,
  HomeStandardWoodShakeSingleRoofCoverDetails:
    woodShakeShingleRoofCoverDetailsFieldsBuilder,
  HomeStandardLowSlopeRoofCoverDetails: lowSlopeRoofCoverDetailsFieldsBuilder,
  HomeStandardHomeownerAuthorizationForm: homeAuthorizationFormBuilder,
  HomeStandardEngineerConfirmationForm: engineerConfirmationFormBuilder,
  HomeStandardExternalEngineerConfirmationForm:
    externalEngineerConfirmationFormBuilder,
  HomeStandardSilverGoldComplianceGeneralInformationForm:
    silverGoldComplianceGeneralInformationFormBuilder,
  HomeStandardSilverGoldSiteDesignInformation:
    silverGoldSiteDesignInformationFormBuilder,
  HomeStandardSilverGoldWindowsAndSkylights:
    silverGoldWindowsAndSkylightsFormBuilder,
  HomeStandardSilverGoldEntryDoors: silverGoldEntryDoorsFormBuilder,
  HomeStandardSilverGoldGarage: silverGoldGarageFormBuilder,
  HomeStandardSilverGoldGableEnds: silverGoldGableEndsFormBuilder,
  HomeStandardSilverGoldSoffits: silverGoldSoffitsFormBuilder,
  HomeStandardSilverGoldChimneys: silverGoldChimneysFormBuilder,
  HomeStandardSilverGoldAttachedStructures:
    silverGoldAttachedStructuresFormBuilder,
  HomeStandardSilverGoldPressureRatedOpenings:
    silverGoldDesignPressureRatedOpeningsFormBuilder,
  HomeStandardSilverGoldWallSheathing: silverGoldWallSheathingFormBuilder,
  HomeStandardSilverContinuousLoadPath: silverGoldContinuousLoadPathFormBuilder,
  HomeStandardInstallingContractorConfirmationForm:
    installingContractorConfirmationFormBuilder,
  HomeStandardExternalInstallingContractorConfirmationForm:
    externalInstallingContractorConfirmationFormBuilder,
  HomeStandardInstallerComplianceGablesForm:
    homeStandardInstallerComplianceGablesBuilder,
  HomeStandardInstallerComplianceFormGeneralInformationForm:
    installerComplianceFormGeneralInformationBuilder,
  HomeStandardInstallerComplianceFormEngineeredGables:
    installerComplianceFormEngineeredGablesBuilder,
  HomeStandardInstallerComplianceFormPrescriptiveGables:
    installerComplianceFormPrescriptiveGablesBuilder,
  HomeStandardInstallerComplianceContinuousLoadPathForm:
    homeStandardInstallerComplianceContinuousLoadPathBuilder,
  HomeStandardInstallerComplianceFormContinuousLoadPathGeneralInformation:
    installerComplianceFormContinuousLoadPathGeneralInformationBuilder,
  HomeStandardInstallerComplianceFormContinuousLoadPathForNewConstruction:
    installerComplianceFormContinuousLoadPathForNewConstructionBuilder,
  HomeStandardInstallerComplianceFormContinuousLoadPathForRetrofitOfExistingHome:
    installerComplianceFormContinuousLoadPathForRetrofitOfExistingHomeBuilder,
  HomeStandardInstallerComplianceFormContinuousLoadPathForWoodFraming:
    installerComplianceFormContinuousLoadPathForWoodFramingBuilder,
  HomeStandardInstallerComplianceChimneyForm:
    homeStandardInstallerComplianceChimneyBuilder,
  HomeStandardInstallerComplianceChimneyFormGeneralInformation:
    installerComplianceFormChimneyGeneralInformationBuilder,
  HomeStandardInstallerComplianceChimneyFormEngineeredChimney:
    installerComplianceFormEngineeredChimneyBuilder,
  HomeStandardInstallerComplianceChimneyFormPrescriptiveChimney:
    installerComplianceFormPrescriptiveChimneyBuilder,
  HomeStandardInstallerComplianceAttachedStructureForm:
    homeStandardInstallerComplianceAttachedStructureBuilder,
  HomeStandardExternalInstallerComplianceAttachedStructureForm:
    homeStandardExternalInstallerComplianceAttachedStructureBuilder,
  HomeStandardInstallerComplianceAttachedStructureFormGeneralInformation:
    installerComplianceFormAttachedStructureGeneralInformationBuilder,
  HomeStandardInstallerComplianceAttachedStructureFormEngineeredAttachedStructure:
    installerComplianceFormEngineeredAttachedStructureBuilder,
  HomeStandardInstallerComplianceAttachedStructureFormPrescriptiveAttachedStructure:
    installerComplianceFormPrescriptiveAttachedStructureBuilder,
  HomeStandardEngineeringComplianceGeneralInformationForm:
    engineeringComplianceGeneralInformationFormBuilder,
  HomeStandardExternalEngineeringComplianceGeneralInformationForm:
    externalEngineeringComplianceGeneralInformationFormBuilder,
  HomeStandardEngineeringComplianceGablesForm:
    engineeringComplianceGablesFormBuilder,
  HomeStandardEngineeringComplianceContinuousLoadPathForm:
    homeStandardEngineeringComplianceContinuousLoadPathBuilder,
  HomeStandardEngineeringComplianceFormContinuousLoadPathForNewConstruction:
    engineeringComplianceFormContinuousLoadPathForNewConstructionBuilder,
  HomeStandardEngineeringComplianceFormContinuousLoadPathForRetrofitOfExistingHome:
    engineeringComplianceFormContinuousLoadPathForRetrofitOfExistingHomeBuilder,
  HomeStandardEngineeringComplianceChimneyForm:
    homeStandardEngineeringComplianceChimneyFormBuilder,
  HomeStandardEngineeringComplianceAttachedStructureForm:
    homeStandardEngineeringComplianceAttachedStructureBuilder,
  HomeStandardRedesignationForm: homeStandardRedesignationFormBuilder,
  HomeStandardRedesignationRoofingComplianceGeneralInformationForm:
    homeStandardRedesignationRoofingComplianceGeneralInformationFormBuilder,
  HomeStandardRedesignationRoofingComplianceBasicInformationForm:
    homeStandardRedesignationBasicInformationBuilder,
  HomeStandardRedesignationPermitInformationForm:
    homeStandardRedesignationPermitInformationBuilder,
  HomeStandardRedesignationSiteConditionsForm:
    homeStandardRedesignationSiteConditionsBuilder,
  HomeStandardRedesignationRoofStructureForm:
    homeStandardRedesignationRoofStructureBuilder,
  HomeStandardRedesignationRoofSheathingForm:
    homeStandardRedesignationRoofSheathingBuilder,
  HomeStandardRedesignationSealedRoofDeckForm:
    homeStandardRedesignationSealedRoofDeckBuilder,
  HomeStandardRedesignationDripEdgeForm:
    homeStandardRedesignationDripEdgeBuilder,
  HomeStandardRedesignationAtticVentsForm:
    homeStandardRedesignationAtticVentsBuilder,
  HomeStandardRedesignationPhotoVoltaicPanelsForm:
    homeStandardRedesignationPhotoVoltaicPanelsBuilder,
  HomeStandardRedesignationSkyLightsForm:
    homeStandardRedesignationSkyLightsBuilder,
  HomeStandardRedesignationRoofingComplianceRoofCoverForm:
    homeStandardRedesignationRoofingComplianceRoofCoverFormBuilder,
  HomeStandardRedesignationRoofCoverInformation:
    homeStandardRedesignationRoofCoverInformationBuilder,
  HomeStandardRedesignationAsphaltShingleRoofCoverDetails:
    homeStandardRedesignationAsphaltShingleRoofCoverDetailsBuilder,
  HomeStandardRedesignationMetalRoofCoverDetails:
    homeStandardRedesignationMetalRoofCoverDetailsFieldsBuilder,
  HomeStandardRedesignationClayConcreteTileRoofCoverDetails:
    homeStandardRedesignationClayConcreteTileRoofCoverDetailsBuilder,
  HomeStandardRedesignationWoodShakeSingleRoofCoverDetails:
    homeStandardRedesignationWoodShakeShingleRoofCoverDetailsFieldsBuilder,
  HomeStandardRedesignationLowSlopeRoofCoverDetails:
    homeStandardRedesignationLowSlopeRoofCoverDetailsFieldsBuilder,
  HomeStandardRedesignationRooferConfirmationForm:
    homeStandardRedesignationRooferConfirmationFormBuilder,
};
