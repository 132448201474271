import "./styles.scss";
import { CommentTemplateCommonForm } from "./CommentTemplateCommonForm";
import {
  CommentTemplateDialogFormModel,
  CommentTemplateFormProps,
} from "./types";

const COMMENT_TEMPLATE_FORM_ID = "comment-template-form";

export const CommentTemplateForm: React.FC<CommentTemplateFormProps> = ({
  id,
  refreshGrid,
}) => {
  const defaultFormValue: CommentTemplateDialogFormModel = {
    productType: "",
    formType: "",
    formFieldKey: "",
    templateName: "",
    templateContent: "",
  };

  return (
    <CommentTemplateCommonForm
      id={id}
      defaultFormValue={defaultFormValue}
      formId={COMMENT_TEMPLATE_FORM_ID}
      showProductType={true}
      refreshGrid={() => refreshGrid()}
    />
  );
};
