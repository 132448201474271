import {
  EmailFieldProps,
  EnumMultiSelectFieldV2Props,
  EnumRadioFieldProps,
  EnumSingleSelectFieldV2Props,
  FieldSchemaFormBuilderV2,
  SchemaFactoryV2,
  TextFieldProps,
} from "@ucl/library";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import {
  WildfireBoxContentExplorer,
  WildfireBoxContentExplorerProps,
} from "../../../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import { YesOrNoSelection } from "../../../../../../wildfire/types/FormFields";
import { get } from "lodash";
import { Evaluation_HomeNCIUAEvaluationFormModel } from "../../../evaluation/types/HomeNCIUAEvaluationFormModel";
import {
  BuildingElementTypes,
  ContinuousLoadPathPathwayTypes,
  DesignationLevelTypes,
  DwellingTypes,
  getBuildingElementTypeLabel,
  isNewRoofRegardlessOfConstructionStatus,
  NewConstructionOrExistingHomeTypes,
  NewOrExistingRoofTypes,
  requiredBuildingElementTypesForSilverAndGold,
  shouldNotHaveContinousLoadPathIfSilver,
} from "../../../../common/types/BaseHomeEvaluationEligibilityFormModel";
import {
  ReadonlyWarningBannerField,
  ReadonlyWarningBannerFieldProps,
} from "../../../../../Common/components/Fields/ReadonlyWarningBanner/ReadonlyWarningBannerField";
import { HazardTypes } from "../../../../../Common/types/Evaluation/BaseEvaluation";
import { permissionStore } from "../../../../../../foritfied/stores/PermissionStore";
import { enumToOptions } from "../../../../../../common/utils/enum";

const modelName = "Evaluation";
const subModelName = "HomeNCIUAEvaluation";
const formName = "EligibilityForm";

const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

//Part 1: Homeowner Information
const designationTypeAndLevelInformationFields = (
  form: Evaluation_HomeNCIUAEvaluationFormModel
) => [
  buildFieldKey("NewConstructionOrExistingHome"),
  buildFieldKey("NewOrExistingRoof"),
  {
    fieldKey: buildFieldKey("NewOrExistingRoofWarningBanner"),
    hidden: !(
      form.homeNCIUAEvaluation.eligibilityForm.newOrExistingRoof ===
        NewOrExistingRoofTypes.ExistingRoof &&
      form.homeNCIUAEvaluation.eligibilityForm.newConstructionOrExistingHome ===
        NewConstructionOrExistingHomeTypes.NewConstruction
    ),
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("RooferEmail"),
    hidden: !isNewRoofRegardlessOfConstructionStatus(
      form.homeNCIUAEvaluation.eligibilityForm.newOrExistingRoof
    ),
  } as EmailFieldProps,
  buildFieldKey("HasHailSupplement"),
  {
    fieldKey: buildFieldKey("DesignationLevel"),
    value: 1,
    disabled: true,
    readOnly: true,
  } as EnumSingleSelectFieldV2Props,
  {
    fieldKey: buildFieldKey("BuildingElementTypes"),
    hidden: !(
      form.homeNCIUAEvaluation.eligibilityForm.designationLevel ===
        DesignationLevelTypes.Silver ||
      form.homeNCIUAEvaluation.eligibilityForm.designationLevel ===
        DesignationLevelTypes.Gold
    ),
  } as EnumMultiSelectFieldV2Props,
  buildFieldKey("DistanceToSaltWaterShorelineType"),
  {
    fieldKey: buildFieldKey("BuildingElementTypesWarningBanner"),
    hidden: !(
      form.homeNCIUAEvaluation.eligibilityForm.designationLevel ===
        DesignationLevelTypes.Silver &&
      !shouldNotHaveContinousLoadPathIfSilver.some(
        (type) =>
          form.homeNCIUAEvaluation.eligibilityForm.buildingElementTypes?.includes(
            type
          ) ||
          form.homeNCIUAEvaluation.eligibilityForm.buildingElementTypes
            ?.length === 0
      )
    ),
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("InstallingContractorEmail"),
    hidden: !requiredBuildingElementTypesForSilverAndGold.some((type) =>
      form.homeNCIUAEvaluation.eligibilityForm.buildingElementTypes?.includes(
        type
      )
    ),
  } as EmailFieldProps,
  {
    fieldKey: buildFieldKey(
      "IsEngineerProvidingComplianceDesignInfoForSilverOrGoldRequirements"
    ),
    hidden: !requiredBuildingElementTypesForSilverAndGold.some((type) =>
      form.homeNCIUAEvaluation.eligibilityForm.buildingElementTypes?.includes(
        type
      )
    ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey("EngineerEmail"),
    hidden: !(
      form.homeNCIUAEvaluation.eligibilityForm
        .isEngineerProvidingComplianceDesignInfoForSilverOrGoldRequirements ===
      YesOrNoSelection.Yes
    ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey(
      "BuildingElementsEngineerIsProvidingComplianceDesignInfoFor"
    ),
    optionValues: enumToOptions(
      BuildingElementTypes,
      getBuildingElementTypeLabel
    ).filter(
      (option) =>
        option.value === BuildingElementTypes.NoneOfTheAbove ||
        form.homeNCIUAEvaluation.eligibilityForm.buildingElementTypes?.includes(
          option.value
        )
    ),
    hidden: !(
      form.homeNCIUAEvaluation.eligibilityForm
        .isEngineerProvidingComplianceDesignInfoForSilverOrGoldRequirements ===
      YesOrNoSelection.Yes
    ),
  } as EnumMultiSelectFieldV2Props,
  {
    fieldKey: buildFieldKey(
      "BuildingElementsEngineerIsProvidingComplianceDesignInfoForWarningBanner2"
    ),
    hidden: !(
      form.homeNCIUAEvaluation.eligibilityForm.buildingElementTypes?.includes(
        BuildingElementTypes.MultiLevelAttachedStructures
      ) &&
      !form.homeNCIUAEvaluation.eligibilityForm.buildingElementsEngineerIsProvidingComplianceDesignInfoFor?.includes(
        BuildingElementTypes.MultiLevelAttachedStructures
      ) &&
      form.homeNCIUAEvaluation.eligibilityForm
        .buildingElementsEngineerIsProvidingComplianceDesignInfoFor &&
      form.homeNCIUAEvaluation.eligibilityForm
        .buildingElementsEngineerIsProvidingComplianceDesignInfoFor?.length > 0
    ),
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("ContinuousLoadPathPathwayType"),
    hidden: !(
      form.homeNCIUAEvaluation.eligibilityForm.designationLevel ===
      DesignationLevelTypes.Gold
    ),
  } as EnumMultiSelectFieldV2Props,
  {
    fieldKey: buildFieldKey("ContinuousLoadPathPathwayTypeWarningBanner1"),
    hidden: !(
      form.hazard !== HazardTypes.HighWind &&
      form.homeNCIUAEvaluation.eligibilityForm.continuousLoadPathPathwayType ===
        ContinuousLoadPathPathwayTypes.PrescriptiveHighWindOnlyForNewWoodFramedConstruction
    ),
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("ContinuousLoadPathPathwayTypeWarningBanner2"),
    hidden: !(
      form.homeNCIUAEvaluation.eligibilityForm.newConstructionOrExistingHome ===
        NewConstructionOrExistingHomeTypes.ExistingHome &&
      form.homeNCIUAEvaluation.eligibilityForm.continuousLoadPathPathwayType ===
        ContinuousLoadPathPathwayTypes.EngineeredCLPForNewConstruction
    ),
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("ContinuousLoadPathPathwayTypeWarningBanner3"),
    hidden: !(
      form.homeNCIUAEvaluation.eligibilityForm
        .isEngineerProvidingComplianceDesignInfoForSilverOrGoldRequirements ===
        YesOrNoSelection.No &&
      form.homeNCIUAEvaluation.eligibilityForm.continuousLoadPathPathwayType ===
        ContinuousLoadPathPathwayTypes.EngineeredRetrofitOfExistingHome
    ),
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("ContinuousLoadPathPathwayTypeWarningBanner4"),
    hidden: !(
      form.homeNCIUAEvaluation.eligibilityForm
        .isEngineerProvidingComplianceDesignInfoForSilverOrGoldRequirements ===
        YesOrNoSelection.No &&
      form.homeNCIUAEvaluation.eligibilityForm.continuousLoadPathPathwayType ===
        ContinuousLoadPathPathwayTypes.EngineeredCLPForNewConstruction
    ),
  } as ReadonlyWarningBannerFieldProps,
];

const designationTypeAndLevelInformationRequiredFields = [
  buildFieldKey("NewConstructionOrExistingHome"),
  buildFieldKey("NewOrExistingRoof"),
  buildFieldKey("RooferEmail"),
  buildFieldKey("HasHailSupplement"),
  buildFieldKey("DesignationLevel"),
  buildFieldKey("BuildingElementTypes"),
  buildFieldKey("DistanceToSaltWaterShorelineType"),
  buildFieldKey("InstallingContractorEmail"),
  buildFieldKey(
    "IsEngineerProvidingComplianceDesignInfoForSilverOrGoldRequirements"
  ),
  buildFieldKey("EngineerEmail"),
  buildFieldKey("BuildingElementsEngineerIsProvidingComplianceDesignInfoFor"),
  buildFieldKey("ContinuousLoadPathPathwayType"),
];

export const homeNCIUADesignationTypeAndLevelInformationFieldsBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeNCIUAEvaluationFormModel>(
        modelName,
        designationTypeAndLevelInformationFields(form),
        onValueChange,
        form,
        errors,
        designationTypeAndLevelInformationRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel>;
};

//Part 2: Property Details

const eligibilityInformationFields = (
  form: Evaluation_HomeNCIUAEvaluationFormModel,
  errors:
    | {
        [key: string]: string[];
      }
    | undefined
) => [
  buildFieldKey("DwellingType"),
  {
    fieldKey: buildFieldKey("DwellingTypeWarningBanner"),
    hidden: !(
      form.homeNCIUAEvaluation.eligibilityForm.dwellingType ===
      DwellingTypes.NoneOfTheAbove
    ),
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("DwellingTypePhotos"),
    defaultToUpload: false,
    errorMessages: get(errors, "dwellingTypePhotos.FileCount"),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("IsHomeOnADryStackOrHudFoundation"),
    hidden: !(
      form.homeNCIUAEvaluation.eligibilityForm.designationLevel ===
      DesignationLevelTypes.Roof
    ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey("IsHomeOnADryStackOrHudFoundationWarningBanner"),
    hidden: !(
      form.homeNCIUAEvaluation.eligibilityForm
        .isHomeOnADryStackOrHudFoundation === YesOrNoSelection.Yes
    ),
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("IsHomeConnectedToFoundation"),
    hidden: !(
      form.homeNCIUAEvaluation.eligibilityForm.designationLevel ===
        DesignationLevelTypes.Silver ||
      form.homeNCIUAEvaluation.eligibilityForm.designationLevel ===
        DesignationLevelTypes.Gold
    ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey("IsHomeConnectedToFoundationWarningBanner"),
    hidden: !(
      form.homeNCIUAEvaluation.eligibilityForm.isHomeConnectedToFoundation ===
      YesOrNoSelection.No
    ),
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("FoundationEligibilityPhotos"),
    defaultToUpload: false,
    errorMessages: get(errors, "foundationEligibilityPhotos.FileCount"),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
  } as WildfireBoxContentExplorerProps,
  buildFieldKey("IsProfessionalEngineerProvidingCAndCRoofUplifDesingPressures"),
  buildFieldKey("IsProjectAHomeBuiltByNonProfit"),
  {
    fieldKey: buildFieldKey("NonProfitEIN"),
    hidden: !(
      form.homeNCIUAEvaluation.eligibilityForm
        .isProjectAHomeBuiltByNonProfit === YesOrNoSelection.Yes
    ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey("LetterFromNonProfit"),
    defaultToUpload: false,
    errorMessages: get(errors, "letterFromNonProfit.FileCount"),
    hidden: !(
      form.homeNCIUAEvaluation.eligibilityForm
        .isProjectAHomeBuiltByNonProfit === YesOrNoSelection.Yes
    ),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey(
      "IsThisProjectReleatedToFloridaExistingHomesProject"
    ),
    hidden: !(
      form.homeNCIUAEvaluation.eligibilityForm.newOrExistingRoof ===
      NewOrExistingRoofTypes.ExistingRoof
    ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey(
      "IsThisProjectReleatedToFloridaExistingHomesProjectWarningBanner"
    ),
    hidden: !(
      form.homeNCIUAEvaluation.eligibilityForm
        .isThisProjectReleatedToFloridaExistingHomesProject ===
      YesOrNoSelection.Yes
    ),
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("FloridaExistingHomeProjectEligibilityDocuments"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "floridaExistingHomeProjectEligibilityDocuments.FileCount"
    ),
    hidden: !(
      form.homeNCIUAEvaluation.eligibilityForm
        .isThisProjectReleatedToFloridaExistingHomesProject ===
      YesOrNoSelection.Yes
    ),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("OtherDocuments"),
    defaultToUpload: false,
    errorMessages: get(errors, "otherDocuments.FileCount"),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
  } as WildfireBoxContentExplorerProps,
];

const eligibilityInformationRequiredFields = [
  buildFieldKey("DwellingType"),
  buildFieldKey("DwellingTypePhotos"),
  buildFieldKey("IsHomeOnADryStackOrHudFoundation"),
  buildFieldKey("IsHomeConnectedToFoundation"),
  buildFieldKey("FoundationEligibilityPhotos"),
  buildFieldKey("IsProfessionalEngineerProvidingCAndCRoofUplifDesingPressures"),
  buildFieldKey("IsProjectAHomeBuiltByNonProfit"),
  buildFieldKey("NonProfitEIN"),
  buildFieldKey("LetterFromNonProfit"),
  buildFieldKey("IsThisProjectReleatedToFloridaExistingHomesProject"),
  buildFieldKey("FloridaExistingHomeProjectEligibilityDocuments"),
];

export const homeNCIUAEligibilityInformationBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeNCIUAEvaluationFormModel>(
        modelName,
        eligibilityInformationFields(form, errors),
        onValueChange,
        form,
        errors,
        eligibilityInformationRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel>;
};

// Full form builder with all parts included
export const homeNCIUAEligibilityFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeNCIUAEvaluationFormModel>(
        modelName,
        [
          ...designationTypeAndLevelInformationFields(form),
          ...eligibilityInformationFields(form, errors),
        ],
        onValueChange,
        form,
        errors,
        [
          ...designationTypeAndLevelInformationRequiredFields,
          ...eligibilityInformationRequiredFields,
        ],
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel>;
};

// Full form builder with all parts included, for Iteration Engine
export const homeNCIUAIterationEngineEligibilityFormBuilder = (
  factory: SchemaFactoryV2,
  beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
): FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    let fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeNCIUAEvaluationFormModel>(
        modelName,
        [
          ...designationTypeAndLevelInformationFields(form),
          ...eligibilityInformationFields(form, errors),
        ],
        onValueChange,
        form,
        errors,
        [
          ...designationTypeAndLevelInformationRequiredFields,
          ...eligibilityInformationRequiredFields,
        ],
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    if (beforeBuild) {
      fields = beforeBuild(fields);
    }
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel>;
};
