import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { FC, useEffect, useState } from "react";
import { reactPlugin } from "../../AppInsights";
import { WildfireEvaluationProvider } from "../components/Provider/WildfireEvaluationProvider";
import { WildfireEvaluationCommentProvider } from "../components/Provider/WildfireEvaluationCommentsProvider";
import { EvaluationChangeRequestProvider } from "../components/Provider/EvaluationChangeRequestProvider";
import { WildfireSchemaFactoryV2 } from "../customHooks/useFieldSchemaFactory";
import { wildfireUserStore } from "../stores/WildfireUserStore";
import { UnSavedChangesBlockerProvider } from "../components/Provider/UnsavedChangesBlockerContext";
import { WildfireApplicationApplicantIterationEngine } from "../components/IterationEngine/WildfireApplicationApplicantIterationEngine";
import WildfireApplicationAdminIterationEngine from "../components/IterationEngine/WildfireApplicationAdminIterationEngine";
import { wildfireFieldSchemaApiClient } from "../lib/apiClients/wildfireFieldSchemaApiClient";

export interface WildfireApplicationIterationEnginePageProps {
  evaluationId?: string;
}

const WildfireApplicationIterationEngineComponent: FC<
  WildfireApplicationIterationEnginePageProps
> = (props) => {
  const [wildfireSchemaFactory, setWildfireSchemaFactory] =
    useState<WildfireSchemaFactoryV2>();

  const getSchema = async () => {
    const schema = await wildfireFieldSchemaApiClient.getSchema();
    const newSchemaFactory = new WildfireSchemaFactoryV2(schema);
    setWildfireSchemaFactory(newSchemaFactory);
  };

  useEffect(() => {
    if (!wildfireSchemaFactory) {
      getSchema();
    }
  }, []);

  return (
    <div className="wildfire-page">
      {props.evaluationId && wildfireSchemaFactory && (
        <WildfireEvaluationProvider evaluationId={props.evaluationId}>
          <WildfireEvaluationCommentProvider>
            <EvaluationChangeRequestProvider
              evaluationId={props.evaluationId}
              wildfireSchema={wildfireSchemaFactory.schema}
            >
              <UnSavedChangesBlockerProvider>
                <>
                  {wildfireUserStore.isWildfireApplicant && (
                    <WildfireApplicationApplicantIterationEngine
                      evaluationId={props.evaluationId}
                      wildfireSchemaFactory={wildfireSchemaFactory}
                    />
                  )}
                  {!wildfireUserStore.isWildfireApplicant && (
                    <WildfireApplicationAdminIterationEngine
                      evaluationId={props.evaluationId}
                      wildfireSchemaFactory={wildfireSchemaFactory}
                    />
                  )}
                </>
              </UnSavedChangesBlockerProvider>
            </EvaluationChangeRequestProvider>
          </WildfireEvaluationCommentProvider>
        </WildfireEvaluationProvider>
      )}
    </div>
  );
};

export const WildfireApplicationIterationEnginePage = withAITracking(
  reactPlugin,
  WildfireApplicationIterationEngineComponent,
  "WildfireIterationEngine",
  "tracking-container"
);
