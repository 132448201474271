import {
  EnumMultiSelectFieldV2Props,
  EnumRadioFieldProps,
  EnumSingleSelectFieldV2Props,
  FieldSchemaFormBuilderV2,
  NumericFieldProps,
  SchemaFactoryV2,
  TextFieldProps,
} from "@ucl/library";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../../types/HomeEvaluationFormModel";
import {
  BaseFieldProps,
  EnumMultiSelectFieldValue,
} from "@ucl/library/lib/components/Fields/types/fieldTypes";
import {
  ExternalViewTypes,
  WildfireBoxContentExplorer,
  WildfireBoxContentExplorerProps,
} from "../../../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import {
  MechanicalAdhesiveRoofTileAttachmentTypes,
  RoofCoverTypes,
  ShingleHailRatingTypes,
  StarterStripInstallationEavesTypes,
  StarterStripInstallationGlobeRakesTypes,
} from "../../../../common/types/BaseHomeEvaluationRoofingComplianceRoofCoverFormModel";
import { YesOrNoSelection } from "../../../../../../wildfire/types/FormFields";
import { HazardTypes } from "../../../../../Common/types/Evaluation/BaseEvaluation";
import {
  ReadonlyWarningBannerField,
  ReadonlyWarningBannerFieldProps,
} from "../../../../../Common/components/Fields/ReadonlyWarningBanner/ReadonlyWarningBannerField";
import { RoofCoverMinSlopeTypes } from "../../../../common/types/BaseHomeEvaluationRoofingComplianceGeneralInformationFormModel";
import {
  DistanceToSaltWaterShorelineTypes,
  NewConstructionOrExistingHomeTypes,
  NewOrExistingRoofTypes,
} from "../../../../common/types/BaseHomeEvaluationEligibilityFormModel";
import { permissionStore } from "../../../../../../foritfied/stores/PermissionStore";
import { SectionHeaderProps } from "@ucl/library/lib/components/FieldSchema/types";
import {
  shouldHideAsphaltShingleRoofCoverDetailsFields,
  shouldHideClayConcreteTileRoofCoverDetailsFields,
  shouldHideLowSlopeRoofCoverDetailsFields,
  shouldHideMetalRoofCoverDetailsFields,
  shouldHideWoodShakeSingleRoofCoverDetailsFields,
} from "../../../../common/components/FieldSchema/sharedConditionalLogic";

const modelName = "Evaluation";
const subModelName = "HomeStandardEvaluation";
const formName = "RoofingComplianceRoofCoverForm";

const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

// Part 1: Roof Cover Information
const roofCoverInformationFields = (
  form: Evaluation_HomeStandardEvaluationFormModel,
  isExternal: boolean
) => [
  buildFieldKey("RoofCoverType"),
  buildFieldKey("RoofCoverTypeMinSlope"),
  {
    fieldKey: buildFieldKey("RoofCoverTypeMinSlopeWarningBanner1"),
    hidden: !(
      form.homeStandardEvaluation.roofingComplianceRoofCoverForm
        .roofCoverTypeMinSlope === RoofCoverMinSlopeTypes.MinSlope012 &&
      form.homeStandardEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.AsphaltShingle
      )
    ),
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("RoofCoverTypeMinSlopeWarningBanner2"),
    hidden: !(
      form.homeStandardEvaluation.roofingComplianceRoofCoverForm
        .roofCoverTypeMinSlope === RoofCoverMinSlopeTypes.MinSlope112 &&
      form.homeStandardEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.AsphaltShingle
      )
    ),
  } as ReadonlyWarningBannerFieldProps,
  // Does this need to have conditions?
  buildFieldKey("RequiredWindRatingZone1"),
  buildFieldKey("RequiredWindRatingZone2"),
  buildFieldKey("RequiredWindRatingZone3"),
  {
    fieldKey: buildFieldKey("AllZonesRequiredWindRatingDocumentation"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    externalViewType: isExternal ? ExternalViewTypes.ReadWrite : undefined,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("FullyInstalledRoofCoverPhotos"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: !(
      form.homeStandardEvaluation.eligibilityForm.newOrExistingRoof !==
        NewOrExistingRoofTypes.ExistingRoof ||
      form.homeStandardEvaluation.eligibilityForm
        .newConstructionOrExistingHome !==
        NewConstructionOrExistingHomeTypes.ExistingHome
    ),
    externalViewType: isExternal ? ExternalViewTypes.ReadWrite : undefined,
  } as WildfireBoxContentExplorerProps,
];

const roofCoverInformationRequiredFields = [
  buildFieldKey("RoofCoverType"),
  buildFieldKey("RoofCoverTypeMinSlope"),
  buildFieldKey("RequiredWindRatingZone1"),
  buildFieldKey("RequiredWindRatingZone2"),
  buildFieldKey("RequiredWindRatingZone3"),
  buildFieldKey("AllZonesRequiredWindRatingDocumentation"),
  buildFieldKey("FullyInstalledRoofCoverPhotos"),
];

export const roofingComplianceRoofCoverInformationBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        roofCoverInformationFields(form, false),
        onValueChange,
        form,
        errors,
        roofCoverInformationRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Part 2: Asphalt Shingle Roof Cover Details
const asphaltShingleRoofCoverDetailsFields = (
  form: Evaluation_HomeStandardEvaluationFormModel,
  isExternal: boolean,
  shouldHideSection: (roofCoverTypes?: EnumMultiSelectFieldValue) => boolean
) =>
  shouldHideSection(
    form.homeStandardEvaluation.roofingComplianceRoofCoverForm.roofCoverType
  )
    ? []
    : [
        {
          fieldKey: buildFieldKey(
            "AreAsphaltShingleManufacturerAndProductDetailsKnown"
          ),
        },
        {
          fieldKey: buildFieldKey("AsphaltShingleManufacturerName"),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .areAsphaltShingleManufacturerAndProductDetailsKnown ===
            YesOrNoSelection.Yes
          ),
        } as EnumRadioFieldProps,
        {
          fieldKey: buildFieldKey("AsphaltShingleProductName"),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .areAsphaltShingleManufacturerAndProductDetailsKnown ===
            YesOrNoSelection.Yes
          ),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey("ShingleManufacturerAndProductPhoto"),
          defaultToUpload: false,
          canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .areAsphaltShingleManufacturerAndProductDetailsKnown ===
            YesOrNoSelection.Yes
          ),
          externalViewType: isExternal
            ? ExternalViewTypes.ReadWrite
            : undefined,
        } as WildfireBoxContentExplorerProps,
        {
          fieldKey: buildFieldKey(
            "DoesAsphaltShingleWindRatingMeetASTMClassFOrClassH"
          ),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .areAsphaltShingleManufacturerAndProductDetailsKnown ===
            YesOrNoSelection.Yes
          ),
        } as EnumRadioFieldProps,
        {
          fieldKey: buildFieldKey("AsphaltShingleWindRatingTypes"),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .doesAsphaltShingleWindRatingMeetASTMClassFOrClassH ===
            YesOrNoSelection.Yes
          ),
        } as EnumMultiSelectFieldV2Props,
        {
          fieldKey: buildFieldKey("AsphaltShingleWindRatingTypesWarningBanner"),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .doesAsphaltShingleWindRatingMeetASTMClassFOrClassH ===
            YesOrNoSelection.No
          ),
        } as ReadonlyWarningBannerFieldProps,
        {
          fieldKey: buildFieldKey("ShingleWindRatingPhoto"),
          defaultToUpload: false,
          canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .doesAsphaltShingleWindRatingMeetASTMClassFOrClassH ===
            YesOrNoSelection.Yes
          ),
          externalViewType: isExternal
            ? ExternalViewTypes.ReadWrite
            : undefined,
        } as WildfireBoxContentExplorerProps,
        {
          fieldKey: buildFieldKey("AsphaltShingleHailRatingType"),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .areAsphaltShingleManufacturerAndProductDetailsKnown ===
              YesOrNoSelection.Yes &&
            form.homeStandardEvaluation.eligibilityForm.hasHailSupplement ===
              YesOrNoSelection.Yes
          ),
        } as EnumSingleSelectFieldV2Props,
        {
          fieldKey: buildFieldKey("AsphaltShingleHailRatingTypeWarningBanner"),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .asphaltShingleHailRatingType ===
            ShingleHailRatingTypes.NoneOfTheAbove
          ),
        } as ReadonlyWarningBannerFieldProps,
        {
          fieldKey: buildFieldKey("ShingleHailRatingPhoto"),
          defaultToUpload: false,
          canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
          hidden: !(
            form.homeStandardEvaluation.eligibilityForm.hasHailSupplement ===
              YesOrNoSelection.Yes &&
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .asphaltShingleHailRatingType !==
              ShingleHailRatingTypes.NoneOfTheAbove &&
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .areAsphaltShingleManufacturerAndProductDetailsKnown ===
              YesOrNoSelection.Yes
          ),
          externalViewType: isExternal
            ? ExternalViewTypes.ReadWrite
            : undefined,
        } as WildfireBoxContentExplorerProps,
        {
          fieldKey: buildFieldKey(
            "DoAsphaltShingleNailsMeetFortifiedCorrosionResistanceRequirements"
          ),
          hidden: !(
            form.hazard === HazardTypes.Hurricane &&
            form.homeStandardEvaluation.eligibilityForm
              .distanceToSaltWaterShorelineType !==
              DistanceToSaltWaterShorelineTypes.GreaterThanThreeThousandFeet
          ),
        } as EnumRadioFieldProps,
        {
          fieldKey: buildFieldKey(
            "DoAsphaltShingleNailsMeetFortifiedCorrosionResistanceRequirementsWarningBanner"
          ),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .doAsphaltShingleNailsMeetFortifiedCorrosionResistanceRequirements ===
            YesOrNoSelection.No
          ),
        } as ReadonlyWarningBannerFieldProps,
        {
          fieldKey: buildFieldKey(
            "AreAsphaltShinglesInstalledPerManufacturerRecommendationsForHighWindRegions"
          ),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .areAsphaltShingleManufacturerAndProductDetailsKnown ===
            YesOrNoSelection.Yes
          ),
        } as EnumRadioFieldProps,
        {
          fieldKey: buildFieldKey(
            "AreAsphaltShinglesInstalledPerManufacturerRecommendationsForHighWindRegionsWarningBanner"
          ),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .areAsphaltShinglesInstalledPerManufacturerRecommendationsForHighWindRegions ===
            YesOrNoSelection.No
          ),
        } as ReadonlyWarningBannerFieldProps,
        {
          fieldKey: buildFieldKey("NumberOfInstalledNailsPerAsphaltShingle"),
          showIncrementButtons: false,
          min: 1,
          max: 10,
          format: "0",
          decimals: 0,
        } as NumericFieldProps,
        {
          fieldKey: buildFieldKey("StartStripInstallationAtEavesTypes"),
        } as EnumMultiSelectFieldV2Props,
        {
          fieldKey: buildFieldKey(
            "StartStripInstallationAtEavesTypesWarningBanner"
          ),
          hidden:
            !form.homeStandardEvaluation.roofingComplianceRoofCoverForm.startStripInstallationAtEavesTypes?.includes(
              StarterStripInstallationEavesTypes.NoneOfTheAbove
            ),
        } as ReadonlyWarningBannerFieldProps,
        {
          fieldKey: buildFieldKey("StartStripInstallationAtGlobeRakesTypes"),
        } as EnumMultiSelectFieldV2Props,
        {
          fieldKey: buildFieldKey(
            "StartStripInstallationAtGlobeRakesTypesWarningBanner"
          ),
          hidden:
            !form.homeStandardEvaluation.roofingComplianceRoofCoverForm.startStripInstallationAtGlobeRakesTypes?.includes(
              StarterStripInstallationGlobeRakesTypes.NoneOfTheAbove
            ),
        } as ReadonlyWarningBannerFieldProps,
        {
          fieldKey: buildFieldKey("StarterStripManufacturerAndProductPhotos"),
          defaultToUpload: false,
          canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
          externalViewType: isExternal
            ? ExternalViewTypes.ReadWrite
            : undefined,
        } as WildfireBoxContentExplorerProps,
        {
          fieldKey: buildFieldKey("StarterStripInstallationPhotos"),
          defaultToUpload: false,
          canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
          externalViewType: isExternal
            ? ExternalViewTypes.ReadWrite
            : undefined,
        } as WildfireBoxContentExplorerProps,
        {
          fieldKey: buildFieldKey("AsphaltShingleRoofCoverMinSlopePhoto"),
          defaultToUpload: false,
          canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
          externalViewType: isExternal
            ? ExternalViewTypes.ReadWrite
            : undefined,
        } as WildfireBoxContentExplorerProps,
      ];

const asphaltShingleRoofCoverDetailsFieldsRequiredFields = [
  buildFieldKey("AreAsphaltShingleManufacturerAndProductDetailsKnown"),
  buildFieldKey("AsphaltShingleManufacturerName"),
  buildFieldKey("AsphaltShingleProductName"),
  buildFieldKey("AsphaltShingleWindRatingTypes"),
  buildFieldKey("DoesAsphaltShingleWindRatingMeetASTMClassFOrClassH"),
  buildFieldKey("AsphaltShingleHailRatingType"),
  buildFieldKey(
    "DoAsphaltShingleNailsMeetFortifiedCorrosionResistanceRequirements"
  ),
  buildFieldKey(
    "AreAsphaltShinglesInstalledPerManufacturerRecommendationsForHighWindRegions"
  ),
  buildFieldKey("NumberOfInstalledNailsPerAsphaltShingle"),
  buildFieldKey("StartStripInstallationAtEavesTypes"),
  buildFieldKey("StartStripInstallationAtGlobeRakesTypes"),
  buildFieldKey("StarterStripManufacturerAndProductPhotos"),
  buildFieldKey("ShingleManufacturerAndProductPhoto"),
  buildFieldKey("ShingleWindRatingPhoto"),
  buildFieldKey("ShingleHailRatingPhoto"),
  buildFieldKey("StarterStripInstallationPhotos"),
  buildFieldKey("AsphaltShingleRoofCoverMinSlopePhoto"),
];

export const asphaltShingleRoofCoverDetailsBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        asphaltShingleRoofCoverDetailsFields(
          form,
          false,
          shouldHideAsphaltShingleRoofCoverDetailsFields
        ),
        onValueChange,
        form,
        errors,
        asphaltShingleRoofCoverDetailsFieldsRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Part 3: Metal Roof Cover Details
const metalRoofCoverDetailsFields = (
  form: Evaluation_HomeStandardEvaluationFormModel,
  isExternal: boolean,
  shouldHideSection: (roofCoverTypes?: EnumMultiSelectFieldValue) => boolean
) =>
  shouldHideSection(
    form.homeStandardEvaluation.roofingComplianceRoofCoverForm.roofCoverType
  )
    ? []
    : [
        {
          fieldKey: buildFieldKey(
            "AreMetalPanelManufacturerAndProductDetailsKnown"
          ),
        } as EnumRadioFieldProps,
        {
          fieldKey: buildFieldKey("MetalPanelManufacturerName"),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .areMetalPanelManufacturerAndProductDetailsKnown ===
            YesOrNoSelection.Yes
          ),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey("MetalPanelProductName"),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .areMetalPanelManufacturerAndProductDetailsKnown ===
            YesOrNoSelection.Yes
          ),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey(
            "MetalPanelCertifiedTestReportProductApprovalNumber"
          ),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .areMetalPanelManufacturerAndProductDetailsKnown ===
            YesOrNoSelection.Yes
          ),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey("MetalPanelCertifiedTestReportDocumentation"),
          defaultToUpload: false,
          canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .areMetalPanelManufacturerAndProductDetailsKnown ===
            YesOrNoSelection.Yes
          ),
          externalViewType: isExternal
            ? ExternalViewTypes.ReadWrite
            : undefined,
        } as WildfireBoxContentExplorerProps,
        {
          fieldKey: buildFieldKey(
            "MetalPanelSelectNumberOfDifferentSystemsFromCertifiedTestReport"
          ),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .areMetalPanelManufacturerAndProductDetailsKnown ===
            YesOrNoSelection.Yes
          ),
          min: 1,
          max: 3,
          format: "0",
          decimals: 0,
        } as NumericFieldProps,
        {
          fieldKey: buildFieldKey(
            "MetalPanelFirstInstalledSystemNumberPerCertifiedTestReport"
          ),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .areMetalPanelManufacturerAndProductDetailsKnown ===
            YesOrNoSelection.Yes
          ),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey(
            "MatelPanelFirstInstalledSystemDesignPressureOrMomentResistanceRating"
          ),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .areMetalPanelManufacturerAndProductDetailsKnown ===
            YesOrNoSelection.Yes
          ),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey(
            "MetalPanelSecondInstalledSystemNumberPerCertifiedTestReport"
          ),
          hidden: !(
            Number(
              form.homeStandardEvaluation.roofingComplianceRoofCoverForm
                .metalPanelSelectNumberOfDifferentSystemsFromCertifiedTestReport
            ) > 1
          ),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey(
            "MetalPanelSecondInstalledSystemDesignPressureOrMomentResistanceRating"
          ),
          hidden: !(
            Number(
              form.homeStandardEvaluation.roofingComplianceRoofCoverForm
                .metalPanelSelectNumberOfDifferentSystemsFromCertifiedTestReport
            ) > 1
          ),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey(
            "MetalPanelThirdInstalledSystemNumberPerCertifiedTestReport"
          ),
          hidden: !(
            Number(
              form.homeStandardEvaluation.roofingComplianceRoofCoverForm
                .metalPanelSelectNumberOfDifferentSystemsFromCertifiedTestReport
            ) > 2
          ),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey(
            "MetalPanelThirdInstalledSystemDesignPressureOrMomentResistanceRating"
          ),
          hidden: !(
            Number(
              form.homeStandardEvaluation.roofingComplianceRoofCoverForm
                .metalPanelSelectNumberOfDifferentSystemsFromCertifiedTestReport
            ) > 2
          ),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey(
            "MetalPanelAreDesignRatingsOfInstalledSystemsListedInCertifiedTestReport"
          ),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .areMetalPanelManufacturerAndProductDetailsKnown ===
            YesOrNoSelection.Yes
          ),
        } as EnumRadioFieldProps,
        {
          fieldKey: buildFieldKey(
            "MetalPanelAreDesignRatingsOfInstalledSystemsListedInCertifiedTestReportWarningBanner"
          ),
          hidden:
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .metalPanelAreDesignRatingsOfInstalledSystemsListedInCertifiedTestReport !==
            YesOrNoSelection.No,
        } as ReadonlyWarningBannerFieldProps,
        {
          fieldKey: buildFieldKey(
            "DoMetalPanelsAndExposedFastenersMeetFortifiedCorrosionResistanceRequirements"
          ),
          hidden: !(
            form.hazard === HazardTypes.Hurricane &&
            form.homeStandardEvaluation.eligibilityForm
              .distanceToSaltWaterShorelineType !==
              DistanceToSaltWaterShorelineTypes.GreaterThanThreeThousandFeet
          ),
        } as EnumRadioFieldProps,
        {
          fieldKey: buildFieldKey(
            "DoMetalPanelsAndExposedFastenersMeetFortifiedCorrosionResistanceRequirementsWarningBanner"
          ),
          hidden:
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .doMetalPanelsAndExposedFastenersMeetFortifiedCorrosionResistanceRequirements !==
            YesOrNoSelection.No,
        } as ReadonlyWarningBannerFieldProps,
        {
          fieldKey: buildFieldKey("InstalledMetalPanelMaterial"),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey("InstalledMetalPanelGaugeThickness"),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey("InstalledMetalPanelFastenerTypeAndSize"),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey("MetalPanelFastenersPhoto"),
          defaultToUpload: false,
          canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),

          externalViewType: isExternal
            ? ExternalViewTypes.ReadWrite
            : undefined,
        } as WildfireBoxContentExplorerProps,
        {
          fieldName: "",
          fieldKey: "",
          type: "Section",
          label: "Installed fastener pattern and spacing",
        } as SectionHeaderProps,
        {
          fieldKey: buildFieldKey(
            "InstalledMetalPanelFastenerPatternAndSpacingZone1"
          ),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey(
            "InstalledMetalPanelFastenerPatternAndSpacingZone2"
          ),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey(
            "InstalledMetalPanelFastenerPatternAndSpacingZone3"
          ),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey(
            "MetalPanelInstalledFastenerPatternAndSpacingPhotos"
          ),
          defaultToUpload: false,
          canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
          externalViewType: isExternal
            ? ExternalViewTypes.ReadWrite
            : undefined,
        } as WildfireBoxContentExplorerProps,
        {
          fieldKey: buildFieldKey(
            "WasMetalPanelRoofCoverSystemInstalledInAccordanceToStandards"
          ),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .areMetalPanelManufacturerAndProductDetailsKnown ===
            YesOrNoSelection.Yes
          ),
        } as EnumRadioFieldProps,
        {
          fieldKey: buildFieldKey(
            "WasMetalPanelRoofCoverSystemInstalledInAccordanceToStandardsWarningBanner"
          ),
          hidden:
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .wasMetalPanelRoofCoverSystemInstalledInAccordanceToStandards !==
            YesOrNoSelection.No,
        } as ReadonlyWarningBannerFieldProps,
        {
          fieldKey: buildFieldKey(
            "DoesMetalPanelSystemHaveUL2218Class4ImpactRating"
          ),
          hidden: !(
            form.homeStandardEvaluation.eligibilityForm.hasHailSupplement ===
            YesOrNoSelection.Yes
          ),
        } as EnumRadioFieldProps,
        {
          fieldKey: buildFieldKey(
            "DoesMetalPanelSystemHaveUL2218Class4ImpactRatingWarningBanner"
          ),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .doesMetalPanelSystemHaveUL2218Class4ImpactRating ===
            YesOrNoSelection.No
          ),
        } as ReadonlyWarningBannerFieldProps,
        {
          fieldKey: buildFieldKey("MetalPanelRoofCoverMinSlopePhoto"),
          defaultToUpload: false,
          canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
          externalViewType: isExternal
            ? ExternalViewTypes.ReadWrite
            : undefined,
        } as WildfireBoxContentExplorerProps,
      ];

const metalRoofCoverDetailsFieldsRequiredFields = [
  buildFieldKey("AreMetalPanelManufacturerAndProductDetailsKnown"),
  buildFieldKey("MetalPanelManufacturerName"),
  buildFieldKey("MetalPanelProductName"),
  buildFieldKey("MetalPanelCertifiedTestReportProductApprovalNumber"),
  buildFieldKey("MetalPanelCertifiedTestReportDocumentation"),
  buildFieldKey(
    "MetalPanelSelectNumberOfDifferentSystemsFromCertifiedTestReport"
  ),
  buildFieldKey("MetalPanelFirstInstalledSystemNumberPerCertifiedTestReport"),
  buildFieldKey(
    "MatelPanelFirstInstalledSystemDesignPressureOrMomentResistanceRating"
  ),
  buildFieldKey("MetalPanelSecondInstalledSystemNumberPerCertifiedTestReport"),
  buildFieldKey(
    "MetalPanelSecondInstalledSystemDesignPressureOrMomentResistanceRating"
  ),
  buildFieldKey("MetalPanelThirdInstalledSystemNumberPerCertifiedTestReport"),
  buildFieldKey(
    "MetalPanelThirdInstalledSystemDesignPressureOrMomentResistanceRating"
  ),
  buildFieldKey(
    "MetalPanelAreDesignRatingsOfInstalledSystemsListedInCertifiedTestReport"
  ),
  buildFieldKey(
    "DoMetalPanelsAndExposedFastenersMeetFortifiedCorrosionResistanceRequirements"
  ),
  buildFieldKey("InstalledMetalPanelMaterial"),
  buildFieldKey("InstalledMetalPanelGaugeThickness"),
  buildFieldKey("InstalledMetalPanelFastenerTypeAndSize"),
  buildFieldKey("MetalPanelFastenersPhoto"),
  buildFieldKey("InstalledMetalPanelFastenerPatternAndSpacingZone1"),
  buildFieldKey("InstalledMetalPanelFastenerPatternAndSpacingZone2"),
  buildFieldKey("InstalledMetalPanelFastenerPatternAndSpacingZone3"),
  buildFieldKey("MetalPanelInstalledFastenerPatternAndSpacingPhotos"),
  buildFieldKey("WasMetalPanelRoofCoverSystemInstalledInAccordanceToStandards"),
  buildFieldKey("DoesMetalPanelSystemHaveUL2218Class4ImpactRating"),
  buildFieldKey("MetalPanelRoofCoverMinSlopePhoto"),
];

export const metalRoofCoverDetailsFieldsBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        metalRoofCoverDetailsFields(
          form,
          false,
          shouldHideMetalRoofCoverDetailsFields
        ),
        onValueChange,
        form,
        errors,
        metalRoofCoverDetailsFieldsRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Part 4: Clay Concrete Tile Roof Cover Details
const clayConcreteTileRoofCoverDetailsFields = (
  form: Evaluation_HomeStandardEvaluationFormModel,
  isExternal: boolean,
  shouldHideSection: (roofCoverTypes?: EnumMultiSelectFieldValue) => boolean
) =>
  shouldHideSection(
    form.homeStandardEvaluation.roofingComplianceRoofCoverForm.roofCoverType
  )
    ? []
    : [
        {
          fieldKey: buildFieldKey(
            "AreTheClayConcreteTileManufacturerAndProductDetailsKnown"
          ),
        } as EnumRadioFieldProps,
        {
          fieldKey: buildFieldKey("ClayConcreteTileManufacturerName"),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .areTheClayConcreteTileManufacturerAndProductDetailsKnown ===
            YesOrNoSelection.Yes
          ),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey("ClayConcreteTileProductName"),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .areTheClayConcreteTileManufacturerAndProductDetailsKnown ===
            YesOrNoSelection.Yes
          ),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey("ClayConcreteTileTestReportApprovalNumber"),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .areTheClayConcreteTileManufacturerAndProductDetailsKnown ===
            YesOrNoSelection.Yes
          ),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey(
            "ClayConcreteTileCertifiedTestReportDocumentation"
          ),
          defaultToUpload: false,
          canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .areTheClayConcreteTileManufacturerAndProductDetailsKnown ===
            YesOrNoSelection.Yes
          ),
          externalViewType: isExternal
            ? ExternalViewTypes.ReadWrite
            : undefined,
        } as WildfireBoxContentExplorerProps,
        {
          fieldKey: buildFieldKey(
            "ClayConcreteTileNumberOfInstalledSystemsFromTestReport"
          ),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .areTheClayConcreteTileManufacturerAndProductDetailsKnown ===
            YesOrNoSelection.Yes
          ),
          min: 1,
          max: 3,
          format: "0",
          decimals: 0,
        } as NumericFieldProps,
        {
          fieldKey: buildFieldKey(
            "ClayConcreteTileFirstInstalledSystemNumberPerCertifiedTestReport"
          ),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .areTheClayConcreteTileManufacturerAndProductDetailsKnown ===
            YesOrNoSelection.Yes
          ),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey(
            "ClayConcreteTileFirstInstalledSystemDesignPressureOrMomentResistanceRating"
          ),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .areTheClayConcreteTileManufacturerAndProductDetailsKnown ===
            YesOrNoSelection.Yes
          ),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey(
            "ClayConcreteTileSecondInstalledSystemNumberPerCertifiedTestReport"
          ),
          hidden: !(
            Number(
              form.homeStandardEvaluation.roofingComplianceRoofCoverForm
                .clayConcreteTileNumberOfInstalledSystemsFromTestReport
            ) > 1
          ),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey(
            "ClayConcreteTileSecondInstalledSystemDesignPressureOrMomentResistanceRating"
          ),
          hidden: !(
            Number(
              form.homeStandardEvaluation.roofingComplianceRoofCoverForm
                .clayConcreteTileNumberOfInstalledSystemsFromTestReport
            ) > 1
          ),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey(
            "ClayConcreteTileThirdInstalledSystemNumberPerCertifiedTestReport"
          ),
          hidden: !(
            Number(
              form.homeStandardEvaluation.roofingComplianceRoofCoverForm
                .clayConcreteTileNumberOfInstalledSystemsFromTestReport
            ) > 2
          ),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey(
            "ClayConcreteTileThirdInstalledSystemDesignPressureOrMomentResistanceRating"
          ),
          hidden: !(
            Number(
              form.homeStandardEvaluation.roofingComplianceRoofCoverForm
                .clayConcreteTileNumberOfInstalledSystemsFromTestReport
            ) > 2
          ),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey(
            "ClayConcreteTileSystemsIdentifiedFromTestReport"
          ),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .areTheClayConcreteTileManufacturerAndProductDetailsKnown ===
            YesOrNoSelection.Yes
          ),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey(
            "ClayConcreteTileAreDesignRatingsOfInstalledSystemsListedInCertifiedTestReport"
          ),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .areTheClayConcreteTileManufacturerAndProductDetailsKnown ===
            YesOrNoSelection.Yes
          ),
        } as EnumRadioFieldProps,
        {
          fieldKey: buildFieldKey(
            "ClayConcreteTileAreDesignRatingsOfInstalledSystemsListedInCertifiedTestReportWarningBanner"
          ),
          hidden:
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .clayConcreteTileAreDesignRatingsOfInstalledSystemsListedInCertifiedTestReport !==
            YesOrNoSelection.No,
        } as ReadonlyWarningBannerFieldProps,
        {
          fieldKey: buildFieldKey(
            "DoesClayConcreteTileRoofCoverSystemHaveFM4473Class4ImpactRating"
          ),
          hidden: !(
            form.homeStandardEvaluation.eligibilityForm.hasHailSupplement ===
            YesOrNoSelection.Yes
          ),
        } as EnumRadioFieldProps,
        {
          fieldKey: buildFieldKey(
            "DoesClayConcreteTileRoofCoverSystemHaveFM4473Class4ImpactRatingWarningBanner"
          ),
          hidden:
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .doesClayConcreteTileRoofCoverSystemHaveFM4473Class4ImpactRating !==
            YesOrNoSelection.No,
        } as ReadonlyWarningBannerFieldProps,
        {
          fieldKey: buildFieldKey(
            "ClayConcreteTileCertifiedTestReportProductApprovalNumberForStructuralSupports"
          ),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey(
            "TileStructuralSupportsCertifiedTestReportDocumentation"
          ),
          defaultToUpload: false,
          canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
          externalViewType: isExternal
            ? ExternalViewTypes.ReadWrite
            : undefined,
        } as WildfireBoxContentExplorerProps,
        {
          fieldKey: buildFieldKey("MechanicalAdhesiveRoofTileAttachmentType"),
        } as EnumSingleSelectFieldV2Props,
        {
          fieldKey: buildFieldKey("MechanicalFastenerType"),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .mechanicalAdhesiveRoofTileAttachmentType ===
            MechanicalAdhesiveRoofTileAttachmentTypes.Mechanical
          ),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey("RoofTileFastenersPhoto"),
          defaultToUpload: false,
          canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .mechanicalAdhesiveRoofTileAttachmentType ===
            MechanicalAdhesiveRoofTileAttachmentTypes.Mechanical
          ),
          externalViewType: isExternal
            ? ExternalViewTypes.ReadWrite
            : undefined,
        } as WildfireBoxContentExplorerProps,
        {
          fieldKey: buildFieldKey("MechanicalFastenerSize"),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .mechanicalAdhesiveRoofTileAttachmentType ===
            MechanicalAdhesiveRoofTileAttachmentTypes.Mechanical
          ),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey("MechanicalFastenerQuantityPerTile"),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .mechanicalAdhesiveRoofTileAttachmentType ===
            MechanicalAdhesiveRoofTileAttachmentTypes.Mechanical
          ),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey(
            "DoMechanicalTileFastenersMeetFortifiedCorrosionResistanceRequirements"
          ),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .mechanicalAdhesiveRoofTileAttachmentType ===
              MechanicalAdhesiveRoofTileAttachmentTypes.Mechanical &&
            form.hazard === HazardTypes.Hurricane &&
            form.homeStandardEvaluation.eligibilityForm
              .distanceToSaltWaterShorelineType !==
              DistanceToSaltWaterShorelineTypes.GreaterThanThreeThousandFeet
          ),
        } as EnumRadioFieldProps,
        {
          fieldKey: buildFieldKey(
            "DoMechanicalTileFastenersMeetFortifiedCorrosionResistanceRequirementsWarningBanner"
          ),
          hidden:
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .doMechanicalTileFastenersMeetFortifiedCorrosionResistanceRequirements !==
            YesOrNoSelection.No,
        } as ReadonlyWarningBannerFieldProps,
        {
          fieldKey: buildFieldKey("AdhesiveFoamManufacturerName"),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .mechanicalAdhesiveRoofTileAttachmentType ===
            MechanicalAdhesiveRoofTileAttachmentTypes.AdhesiveFoam
          ),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey("AdhesiveFoamProductName"),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .mechanicalAdhesiveRoofTileAttachmentType ===
            MechanicalAdhesiveRoofTileAttachmentTypes.AdhesiveFoam
          ),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey("AdhesiveFoamTestReportApprovalNumber"),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .mechanicalAdhesiveRoofTileAttachmentType ===
            MechanicalAdhesiveRoofTileAttachmentTypes.AdhesiveFoam
          ),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey(
            "IsAdhesiveFoamInstallerCertifiedByFoamManufacturer"
          ),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .mechanicalAdhesiveRoofTileAttachmentType ===
            MechanicalAdhesiveRoofTileAttachmentTypes.AdhesiveFoam
          ),
        } as EnumRadioFieldProps,
        {
          fieldKey: buildFieldKey(
            "IsAdhesiveFoamInstallerCertifiedByFoamManufacturerWarningBanner"
          ),
          hidden:
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .isAdhesiveFoamInstallerCertifiedByFoamManufacturer !==
            YesOrNoSelection.No,
        } as ReadonlyWarningBannerFieldProps,
        {
          fieldKey: buildFieldKey("AdhesiveFoamInstallerCertificationNumber"),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .isAdhesiveFoamInstallerCertifiedByFoamManufacturer ===
            YesOrNoSelection.Yes
          ),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey("TileUnderlaymentCertifiedTestReportNumber"),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey(
            "IsTileAndFlashingInstalledPerFRSATRIInstructions"
          ),
          hidden: !(form.hazard === HazardTypes.Hurricane),
        } as EnumRadioFieldProps,
        {
          fieldKey: buildFieldKey(
            "IsTileAndFlashingInstalledPerFRSATRIInstructionsWarningBanner"
          ),
          hidden:
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .isTileAndFlashingInstalledPerFRSATRIInstructions !==
            YesOrNoSelection.No,
        } as ReadonlyWarningBannerFieldProps,
        {
          fieldKey: buildFieldKey(
            "IsTileAndFlashingInstalledPerTRIWSRCAInstructions"
          ),
          hidden: !(form.hazard === HazardTypes.HighWind),
        } as EnumRadioFieldProps,
        {
          fieldKey: buildFieldKey(
            "IsTileAndFlashingInstalledPerTRIWSRCAInstructionsWarningBanner"
          ),
          hidden:
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .isTileAndFlashingInstalledPerTRIWSRCAInstructions !==
            YesOrNoSelection.No,
        } as ReadonlyWarningBannerFieldProps,
        {
          fieldKey: buildFieldKey("ClayConcreteTileRoofCoverMinSlopePhoto"),
          defaultToUpload: false,
          canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
          externalViewType: isExternal
            ? ExternalViewTypes.ReadWrite
            : undefined,
        } as WildfireBoxContentExplorerProps,
        {
          fieldKey: buildFieldKey("TileRoofCoverSystemInstallationPhoto"),
          defaultToUpload: false,
          canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
          externalViewType: isExternal
            ? ExternalViewTypes.ReadWrite
            : undefined,
        } as WildfireBoxContentExplorerProps,
      ];

const clayConcreteTileRoofCoverDetailsRequiredFields = [
  buildFieldKey("AreTheClayConcreteTileManufacturerAndProductDetailsKnown"),
  buildFieldKey("ClayConcreteTileManufacturerName"),
  buildFieldKey("ClayConcreteTileProductName"),
  buildFieldKey("ClayConcreteTileTestReportApprovalNumber"),
  buildFieldKey("ClayConcreteTileCertifiedTestReportDocumentation"),
  buildFieldKey("ClayConcreteTileNumberOfInstalledSystemsFromTestReport"),
  buildFieldKey(
    "ClayConcreteTileFirstInstalledSystemNumberPerCertifiedTestReport"
  ),
  buildFieldKey(
    "ClayConcreteTileFirstInstalledSystemDesignPressureOrMomentResistanceRating"
  ),
  buildFieldKey(
    "ClayConcreteTileSecondInstalledSystemNumberPerCertifiedTestReport"
  ),
  buildFieldKey(
    "ClayConcreteTileSecondInstalledSystemDesignPressureOrMomentResistanceRating"
  ),
  buildFieldKey(
    "ClayConcreteTileThirdInstalledSystemNumberPerCertifiedTestReport"
  ),
  buildFieldKey(
    "ClayConcreteTileThirdInstalledSystemDesignPressureOrMomentResistanceRating"
  ),
  buildFieldKey("ClayConcreteTileSystemsIdentifiedFromTestReport"),
  buildFieldKey(
    "ClayConcreteTileAreDesignRatingsOfInstalledSystemsListedInCertifiedTestReport"
  ),
  buildFieldKey(
    "DoesClayConcreteTileRoofCoverSystemHaveFM4473Class4ImpactRating"
  ),
  buildFieldKey(
    "ClayConcreteTileCertifiedTestReportProductApprovalNumberForStructuralSupports"
  ),
  buildFieldKey("TileStructuralSupportsCertifiedTestReportDocumentation"),
  buildFieldKey("MechanicalAdhesiveRoofTileAttachmentType"),
  buildFieldKey("MechanicalFastenerType"),
  buildFieldKey("RoofTileFastenersPhoto"),
  buildFieldKey("MechanicalFastenerSize"),
  buildFieldKey("MechanicalFastenerQuantityPerTile"),
  buildFieldKey(
    "DoMechanicalTileFastenersMeetFortifiedCorrosionResistanceRequirements"
  ),
  buildFieldKey("AdhesiveFoamManufacturerName"),
  buildFieldKey("AdhesiveFoamProductName"),
  buildFieldKey("AdhesiveFoamTestReportApprovalNumber"),
  buildFieldKey("IsAdhesiveFoamInstallerCertifiedByFoamManufacturer"),
  buildFieldKey("AdhesiveFoamInstallerCertificationNumber"),
  buildFieldKey("TileUnderlaymentCertifiedTestReportNumber"),
  buildFieldKey("IsTileAndFlashingInstalledPerFRSATRIInstructions"),
  buildFieldKey("IsTileAndFlashingInstalledPerTRIWSRCAInstructions"),
  buildFieldKey("ClayConcreteTileRoofCoverMinSlopePhoto"),
];

export const clayConcreteTileRoofCoverDetailsBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        clayConcreteTileRoofCoverDetailsFields(
          form,
          false,
          shouldHideClayConcreteTileRoofCoverDetailsFields
        ),
        onValueChange,
        form,
        errors,
        clayConcreteTileRoofCoverDetailsRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Part 5: Wood Shake Shingle Roof Cover Details
const woodShakeShingleRoofCoverDetailsFields = (
  form: Evaluation_HomeStandardEvaluationFormModel,
  isExternal: boolean,
  shouldHideSection: (roofCoverTypes?: EnumMultiSelectFieldValue) => boolean
) =>
  shouldHideSection(
    form.homeStandardEvaluation.roofingComplianceRoofCoverForm.roofCoverType
  )
    ? []
    : [
        {
          fieldKey: buildFieldKey(
            "AreTheWoodShakeShingleManufacturerAndProductDetailsKnown"
          ),
        } as EnumRadioFieldProps,
        {
          fieldKey: buildFieldKey("WoodShakeShingleManufacturerName"),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .areTheWoodShakeShingleManufacturerAndProductDetailsKnown ===
            YesOrNoSelection.Yes
          ),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey("WoodShakeShingleProductName"),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .areTheWoodShakeShingleManufacturerAndProductDetailsKnown ===
            YesOrNoSelection.Yes
          ),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey("WoodShakeShingleTestReportApprovalNumber"),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .areTheWoodShakeShingleManufacturerAndProductDetailsKnown ===
            YesOrNoSelection.Yes
          ),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey(
            "WoodShakeShingleCertifiedTestReportDocumentation"
          ),
          defaultToUpload: false,
          canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .areTheWoodShakeShingleManufacturerAndProductDetailsKnown ===
            YesOrNoSelection.Yes
          ),
          externalViewType: isExternal
            ? ExternalViewTypes.ReadWrite
            : undefined,
        } as WildfireBoxContentExplorerProps,
        {
          fieldKey: buildFieldKey(
            "WoodShakeShingleDesignPressureWindRatingOfInstalledSystem"
          ),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .areTheWoodShakeShingleManufacturerAndProductDetailsKnown ===
            YesOrNoSelection.Yes
          ),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey(
            "WoodShakeShingleIsMeanRoofHeightLimitationPerManufacturerProductSpecifications"
          ),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .areTheWoodShakeShingleManufacturerAndProductDetailsKnown ===
            YesOrNoSelection.Yes
          ),
        } as EnumRadioFieldProps,
        {
          fieldKey: buildFieldKey("WoodShakeShingleMeanRoofHeightLimitation"),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .woodShakeShingleIsMeanRoofHeightLimitationPerManufacturerProductSpecifications ===
            YesOrNoSelection.Yes
          ),
          showIncrementButtons: false,
          min: 0,
          format: "0",
          decimals: 0,
        } as NumericFieldProps,
        {
          fieldKey: buildFieldKey(
            "WoodShakeShingleRequiredNumberOfNailsPerShingle"
          ),
          showIncrementButtons: false,
          min: 1,
          max: 10,
          format: "0",
          decimals: 0,
        } as NumericFieldProps,
        {
          fieldKey: buildFieldKey(
            "WoodShakeShingleDoesWindRatingListedInCertifiedTestReportMeetOrExceedDesignConditions"
          ),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .areTheWoodShakeShingleManufacturerAndProductDetailsKnown ===
            YesOrNoSelection.Yes
          ),
        } as EnumRadioFieldProps,
        {
          fieldKey: buildFieldKey(
            "WoodShakeShingleDoesWindRatingListedInCertifiedTestReportMeetOrExceedDesignConditionsWarningBanner"
          ),
          hidden:
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .woodShakeShingleDoesWindRatingListedInCertifiedTestReportMeetOrExceedDesignConditions !==
            YesOrNoSelection.No,
        } as ReadonlyWarningBannerFieldProps,
        {
          fieldKey: buildFieldKey(
            "DoesWoodShakeShingleHaveUL2218Class4OrFM4473Class4ImpactRating"
          ),
          hidden: !(
            form.homeStandardEvaluation.eligibilityForm.hasHailSupplement ===
            YesOrNoSelection.Yes
          ),
        } as EnumRadioFieldProps,
        {
          fieldKey: buildFieldKey(
            "DoesWoodShakeShingleHaveUL2218Class4OrFM4473Class4ImpactRatingWarningBanner"
          ),
          hidden:
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .doesWoodShakeShingleHaveUL2218Class4OrFM4473Class4ImpactRating !==
            YesOrNoSelection.No,
        } as ReadonlyWarningBannerFieldProps,
        {
          fieldKey: buildFieldKey("WoodShakeShingleFastenerType"),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey("WoodShakeShingleFastenersPhoto"),
          defaultToUpload: false,
          canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),

          externalViewType: isExternal
            ? ExternalViewTypes.ReadWrite
            : undefined,
        } as WildfireBoxContentExplorerProps,
        {
          fieldKey: buildFieldKey("WoodShakeShingleFastenerSize"),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey("WereStaplesUsedToInstallWoodShakesShingles"),
        } as EnumRadioFieldProps,
        {
          fieldKey: buildFieldKey(
            "WereStaplesUsedToInstallWoodShakesShinglesWarningBanner"
          ),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .wereStaplesUsedToInstallWoodShakesShingles ===
            YesOrNoSelection.Yes
          ),
        } as ReadonlyWarningBannerFieldProps,
        {
          fieldKey: buildFieldKey(
            "WoodShakeShingleAreStainlessSteelFastenersRequired"
          ),
        } as EnumRadioFieldProps,
        {
          fieldKey: buildFieldKey(
            "WoodShakeShingleAreStainlessSteelFastenersRequiredWarningBanner"
          ),
          hidden:
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .woodShakeShingleAreStainlessSteelFastenersRequired !==
            YesOrNoSelection.No,
        } as ReadonlyWarningBannerFieldProps,
        {
          fieldKey: buildFieldKey(
            "WereStainlessFastenersUsedToInstallWoodShakesShingles"
          ),
        } as EnumRadioFieldProps,
        {
          fieldKey: buildFieldKey(
            "WereStainlessFastenersUsedToInstallWoodShakesShinglesWarningBanner"
          ),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .woodShakeShingleAreStainlessSteelFastenersRequired ===
              YesOrNoSelection.Yes &&
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .wereStainlessFastenersUsedToInstallWoodShakesShingles ===
              YesOrNoSelection.No
          ),
        } as ReadonlyWarningBannerFieldProps,
        {
          fieldKey: buildFieldKey(
            "IsSealedRoofDeckMaterialCompatibleWithWoodShakeShingle"
          ),
        } as EnumRadioFieldProps,
        {
          fieldKey: buildFieldKey(
            "IsSealedRoofDeckMaterialCompatibleWithWoodShakeShingleWarningBanner"
          ),
          hidden:
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .isSealedRoofDeckMaterialCompatibleWithWoodShakeShingle !==
            YesOrNoSelection.No,
        } as ReadonlyWarningBannerFieldProps,
        {
          fieldKey: buildFieldKey(
            "WasWoodShakeShingleRoofCoverSystemInstalledInAccordanceWithCertifiedTestReport"
          ),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .areTheWoodShakeShingleManufacturerAndProductDetailsKnown ===
            YesOrNoSelection.Yes
          ),
        } as EnumRadioFieldProps,
        {
          fieldKey: buildFieldKey(
            "WasWoodShakeShingleRoofCoverSystemInstalledInAccordanceWithCertifiedTestReportWarningBanner"
          ),
          hidden:
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .wasWoodShakeShingleRoofCoverSystemInstalledInAccordanceWithCertifiedTestReport !==
            YesOrNoSelection.No,
        } as ReadonlyWarningBannerFieldProps,
        {
          fieldKey: buildFieldKey("WoodShakeShingleRoofCoverMinSlopePhoto"),
          defaultToUpload: false,
          canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
          externalViewType: isExternal
            ? ExternalViewTypes.ReadWrite
            : undefined,
        } as WildfireBoxContentExplorerProps,
        {
          fieldKey: buildFieldKey(
            "WoodShakeShingleRoofCoverSystemInstallationPhotos"
          ),
          defaultToUpload: false,
          canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
        } as WildfireBoxContentExplorerProps,
      ];

const woodShakeShingleRoofCoverDetailsFieldsRequiredFields = [
  buildFieldKey("AreTheWoodShakeShingleManufacturerAndProductDetailsKnown"),
  buildFieldKey("WoodShakeShingleManufacturerName"),
  buildFieldKey("WoodShakeShingleProductName"),
  buildFieldKey("WoodShakeShingleTestReportApprovalNumber"),
  buildFieldKey("WoodShakeShingleCertifiedTestReportDocumentation"),
  buildFieldKey("WoodShakeShingleDesignPressureWindRatingOfInstalledSystem"),
  buildFieldKey(
    "WoodShakeShingleIsMeanRoofHeightLimitationPerManufacturerProductSpecifications"
  ),
  buildFieldKey("WoodShakeShingleMeanRoofHeightLimitation"),
  buildFieldKey("WoodShakeShingleRequiredNumberOfNailsPerShingle"),
  buildFieldKey(
    "WoodShakeShingleDoesWindRatingListedInCertifiedTestReportMeetOrExceedDesignConditions"
  ),
  buildFieldKey(
    "DoesWoodShakeShingleHaveUL2218Class4OrFM4473Class4ImpactRating"
  ),
  buildFieldKey("WoodShakeShingleFastenerType"),
  buildFieldKey("WoodShakeShingleFastenersPhoto"),
  buildFieldKey("WoodShakeShingleFastenerSize"),
  buildFieldKey("WereStaplesUsedToInstallWoodShakesShingles"),
  buildFieldKey("WoodShakeShingleAreStainlessSteelFastenersRequired"),
  buildFieldKey("WereStainlessFastenersUsedToInstallWoodShakesShingles"),
  buildFieldKey("IsSealedRoofDeckMaterialCompatibleWithWoodShakeShingle"),
  buildFieldKey(
    "WasWoodShakeShingleRoofCoverSystemInstalledInAccordanceWithCertifiedTestReport"
  ),
  buildFieldKey("WoodShakeShingleRoofCoverMinSlopePhoto"),
  buildFieldKey("WoodShakeShingleRoofCoverSystemInstallationPhotos"),
];

export const woodShakeShingleRoofCoverDetailsFieldsBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        woodShakeShingleRoofCoverDetailsFields(
          form,
          false,
          shouldHideWoodShakeSingleRoofCoverDetailsFields
        ),
        onValueChange,
        form,
        errors,
        woodShakeShingleRoofCoverDetailsFieldsRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Part 6: Low Slope Roof Cover Details
const lowSlopeRoofCoverDetailsFields = (
  form: Evaluation_HomeStandardEvaluationFormModel,
  isExternal: boolean,
  shouldHideSection: (roofCoverTypes?: EnumMultiSelectFieldValue) => boolean
) =>
  shouldHideSection(
    form.homeStandardEvaluation.roofingComplianceRoofCoverForm.roofCoverType
  )
    ? []
    : [
        {
          fieldKey: buildFieldKey(
            "AreTheLowSlopeRoofCoverManufacturerAndProductDetailsKnown"
          ),
        } as EnumRadioFieldProps,
        {
          fieldKey: buildFieldKey("LowSlopeRoofCoverManufacturerName"),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .areTheLowSlopeRoofCoverManufacturerAndProductDetailsKnown ===
            YesOrNoSelection.Yes
          ),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey("LowSlopeRoofCoverProductName"),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .areTheLowSlopeRoofCoverManufacturerAndProductDetailsKnown ===
            YesOrNoSelection.Yes
          ),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey("LowSlopeRoofCoverTestReportApprovalNumber"),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .areTheLowSlopeRoofCoverManufacturerAndProductDetailsKnown ===
            YesOrNoSelection.Yes
          ),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey(
            "LowSlopeRoofCoverCertifiedTestReportDocumentation"
          ),
          defaultToUpload: false,
          canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .areTheLowSlopeRoofCoverManufacturerAndProductDetailsKnown ===
            YesOrNoSelection.Yes
          ),
          externalViewType: isExternal
            ? ExternalViewTypes.ReadWrite
            : undefined,
        } as WildfireBoxContentExplorerProps,
        {
          fieldKey: buildFieldKey(
            "LowSlopeRoofCoverSelectNumberOfDifferentSystemsFromCertifiedTestReport"
          ),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .areTheLowSlopeRoofCoverManufacturerAndProductDetailsKnown ===
            YesOrNoSelection.Yes
          ),
          min: 1,
          max: 3,
          format: "0",
          decimals: 0,
        } as NumericFieldProps,
        {
          fieldKey: buildFieldKey(
            "LowSlopeRoofCoverFirstInstalledSystemNumberPerCertifiedTestReport"
          ),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .areTheLowSlopeRoofCoverManufacturerAndProductDetailsKnown ===
            YesOrNoSelection.Yes
          ),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey(
            "LowSlopeRoofCoverFirstInstalledSystemDesignPressureOrMomentResistanceRating"
          ),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .areTheLowSlopeRoofCoverManufacturerAndProductDetailsKnown ===
            YesOrNoSelection.Yes
          ),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey(
            "LowSlopeRoofCoverSecondInstalledSystemNumberPerCertifiedTestReport"
          ),
          hidden: !(
            Number(
              form.homeStandardEvaluation.roofingComplianceRoofCoverForm
                .lowSlopeRoofCoverSelectNumberOfDifferentSystemsFromCertifiedTestReport
            ) > 1
          ),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey(
            "LowSlopeRoofCoverSecondInstalledSystemDesignPressureOrMomentResistanceRating"
          ),
          hidden: !(
            Number(
              form.homeStandardEvaluation.roofingComplianceRoofCoverForm
                .lowSlopeRoofCoverSelectNumberOfDifferentSystemsFromCertifiedTestReport
            ) > 1
          ),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey(
            "LowSlopeRoofCoverThirdInstalledSystemNumberPerCertifiedTestReport"
          ),
          hidden: !(
            Number(
              form.homeStandardEvaluation.roofingComplianceRoofCoverForm
                .lowSlopeRoofCoverSelectNumberOfDifferentSystemsFromCertifiedTestReport
            ) > 2
          ),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey(
            "LowSlopeRoofCoverThirdInstalledSystemDesignPressureOrMomentResistanceRating"
          ),
          hidden: !(
            Number(
              form.homeStandardEvaluation.roofingComplianceRoofCoverForm
                .lowSlopeRoofCoverSelectNumberOfDifferentSystemsFromCertifiedTestReport
            ) > 2
          ),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey(
            "LowSlopeRoofCoverAreDesignRatingsOfInstalledSystemsListedInCertifiedTestReport"
          ),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .areTheLowSlopeRoofCoverManufacturerAndProductDetailsKnown ===
            YesOrNoSelection.Yes
          ),
        } as EnumRadioFieldProps,
        {
          fieldKey: buildFieldKey(
            "LowSlopeRoofCoverAreDesignRatingsOfInstalledSystemsListedInCertifiedTestReportWarningBanner"
          ),
          hidden:
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .lowSlopeRoofCoverAreDesignRatingsOfInstalledSystemsListedInCertifiedTestReport !==
            YesOrNoSelection.No,
        } as ReadonlyWarningBannerFieldProps,
        {
          fieldKey: buildFieldKey(
            "LowSlopeRoofCoverDoExposedFastenersMeetFortifiedCorrosionResistanceRequirements"
          ),
          hidden: !(
            form.hazard === HazardTypes.Hurricane &&
            form.homeStandardEvaluation.eligibilityForm
              .distanceToSaltWaterShorelineType !==
              DistanceToSaltWaterShorelineTypes.GreaterThanThreeThousandFeet
          ),
        } as EnumRadioFieldProps,
        {
          fieldKey: buildFieldKey(
            "LowSlopeRoofCoverDoExposedFastenersMeetFortifiedCorrosionResistanceRequirementsWarningBanner"
          ),
          hidden:
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .lowSlopeRoofCoverDoExposedFastenersMeetFortifiedCorrosionResistanceRequirements !==
            YesOrNoSelection.No,
        } as ReadonlyWarningBannerFieldProps,
        {
          fieldKey: buildFieldKey(
            "DoesLowSlopeRoofCoverSystemHaveADesignatedImpactRating"
          ),
          hidden: !(
            form.homeStandardEvaluation.eligibilityForm.hasHailSupplement ===
            YesOrNoSelection.Yes
          ),
        } as EnumRadioFieldProps,
        {
          fieldKey: buildFieldKey(
            "DoesLowSlopeRoofCoverSystemHaveADesignatedImpactRatingWarningBanner"
          ),
          hidden:
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .doesLowSlopeRoofCoverSystemHaveADesignatedImpactRating !==
            YesOrNoSelection.No,
        } as ReadonlyWarningBannerFieldProps,
        {
          fieldKey: buildFieldKey("LowSlopeRoofCoverFastenerTypeAndSize"),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey("LowSlopeRoofCoverFastenerSpacingZone1"),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey("LowSlopeRoofCoverFastenerSpacingZone2"),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey("LowSlopeRoofCoverFastenerSpacingZone3"),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey(
            "WasLowSlopeRoofCoverInstalledInAccordanceWithCertifiedTestReport"
          ),
          hidden: !(
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .areTheLowSlopeRoofCoverManufacturerAndProductDetailsKnown ===
            YesOrNoSelection.Yes
          ),
        } as EnumRadioFieldProps,
        {
          fieldKey: buildFieldKey(
            "WasLowSlopeRoofCoverInstalledInAccordanceWithCertifiedTestReportWarningBanner"
          ),
          hidden:
            form.homeStandardEvaluation.roofingComplianceRoofCoverForm
              .wasLowSlopeRoofCoverInstalledInAccordanceWithCertifiedTestReport !==
            YesOrNoSelection.No,
        } as ReadonlyWarningBannerFieldProps,
        {
          fieldKey: buildFieldKey("LowSlopeRoofCoverMinSlopePhoto"),
          defaultToUpload: false,
          canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
          externalViewType: isExternal
            ? ExternalViewTypes.ReadWrite
            : undefined,
        } as WildfireBoxContentExplorerProps,
        {
          fieldKey: buildFieldKey("LowSlopeRoofCoverSystemInstallationPhotos"),
          defaultToUpload: false,
          canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
        } as WildfireBoxContentExplorerProps,
      ];

const lowSlopeRoofCoverDetailsFieldsRequiredFields = [
  buildFieldKey("AreTheLowSlopeRoofCoverManufacturerAndProductDetailsKnown"),
  buildFieldKey("LowSlopeRoofCoverManufacturerName"),
  buildFieldKey("LowSlopeRoofCoverProductName"),
  buildFieldKey("LowSlopeRoofCoverTestReportApprovalNumber"),
  buildFieldKey("LowSlopeRoofCoverCertifiedTestReportDocumentation"),
  buildFieldKey(
    "LowSlopeRoofCoverSelectNumberOfDifferentSystemsFromCertifiedTestReport"
  ),
  buildFieldKey(
    "LowSlopeRoofCoverFirstInstalledSystemNumberPerCertifiedTestReport"
  ),
  buildFieldKey(
    "LowSlopeRoofCoverFirstInstalledSystemDesignPressureOrMomentResistanceRating"
  ),
  buildFieldKey(
    "LowSlopeRoofCoverSecondInstalledSystemNumberPerCertifiedTestReport"
  ),
  buildFieldKey(
    "LowSlopeRoofCoverSecondInstalledSystemDesignPressureOrMomentResistanceRating"
  ),
  buildFieldKey(
    "LowSlopeRoofCoverThirdInstalledSystemNumberPerCertifiedTestReport"
  ),
  buildFieldKey(
    "LowSlopeRoofCoverThirdInstalledSystemDesignPressureOrMomentResistanceRating"
  ),
  buildFieldKey(
    "LowSlopeRoofCoverAreDesignRatingsOfInstalledSystemsListedInCertifiedTestReport"
  ),
  buildFieldKey(
    "LowSlopeRoofCoverDoExposedFastenersMeetFortifiedCorrosionResistanceRequirements"
  ),
  buildFieldKey("DoesLowSlopeRoofCoverSystemHaveADesignatedImpactRating"),
  buildFieldKey("LowSlopeRoofCoverFastenerTypeAndSize"),
  buildFieldKey("LowSlopeRoofCoverFastenerSpacingZone1"),
  buildFieldKey("LowSlopeRoofCoverFastenerSpacingZone2"),
  buildFieldKey("LowSlopeRoofCoverFastenerSpacingZone3"),
  buildFieldKey(
    "WasLowSlopeRoofCoverInstalledInAccordanceWithCertifiedTestReport"
  ),
  buildFieldKey("LowSlopeRoofCoverMinSlopePhoto"),
  buildFieldKey("LowSlopeRoofCoverSystemInstallationPhotos"),
];

export const lowSlopeRoofCoverDetailsFieldsBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        lowSlopeRoofCoverDetailsFields(
          form,
          false,
          shouldHideLowSlopeRoofCoverDetailsFields
        ),
        onValueChange,
        form,
        errors,
        lowSlopeRoofCoverDetailsFieldsRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Full form builder with all parts included
export const homeStandardRoofingComplianceRoofCoverFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        [
          ...roofCoverInformationFields(form, false),
          ...asphaltShingleRoofCoverDetailsFields(
            form,
            false,
            shouldHideAsphaltShingleRoofCoverDetailsFields
          ),
          ...metalRoofCoverDetailsFields(
            form,
            false,
            shouldHideMetalRoofCoverDetailsFields
          ),
          ...clayConcreteTileRoofCoverDetailsFields(
            form,
            false,
            shouldHideClayConcreteTileRoofCoverDetailsFields
          ),
          ...woodShakeShingleRoofCoverDetailsFields(
            form,
            false,
            shouldHideWoodShakeSingleRoofCoverDetailsFields
          ),
          ...lowSlopeRoofCoverDetailsFields(
            form,
            false,
            shouldHideLowSlopeRoofCoverDetailsFields
          ),
        ],
        onValueChange,
        form,
        errors,
        [
          ...roofCoverInformationRequiredFields,
          ...asphaltShingleRoofCoverDetailsFieldsRequiredFields,
          ...metalRoofCoverDetailsFieldsRequiredFields,
          ...clayConcreteTileRoofCoverDetailsRequiredFields,
          ...woodShakeShingleRoofCoverDetailsFieldsRequiredFields,
          ...lowSlopeRoofCoverDetailsFieldsRequiredFields,
        ],
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Full form builder with all parts included, for external submission
export const homeStandardExternalRoofingComplianceRoofCoverFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        [
          ...roofCoverInformationFields(form, true),
          ...asphaltShingleRoofCoverDetailsFields(
            form,
            true,
            shouldHideAsphaltShingleRoofCoverDetailsFields
          ),
          ...metalRoofCoverDetailsFields(
            form,
            true,
            shouldHideMetalRoofCoverDetailsFields
          ),
          ...clayConcreteTileRoofCoverDetailsFields(
            form,
            true,
            shouldHideClayConcreteTileRoofCoverDetailsFields
          ),
          ...woodShakeShingleRoofCoverDetailsFields(
            form,
            true,
            shouldHideWoodShakeSingleRoofCoverDetailsFields
          ),
          ...lowSlopeRoofCoverDetailsFields(
            form,
            true,
            shouldHideLowSlopeRoofCoverDetailsFields
          ),
        ],
        onValueChange,
        form,
        errors,
        [
          ...roofCoverInformationRequiredFields,
          ...asphaltShingleRoofCoverDetailsFieldsRequiredFields,
          ...metalRoofCoverDetailsFieldsRequiredFields,
          ...clayConcreteTileRoofCoverDetailsRequiredFields,
          ...woodShakeShingleRoofCoverDetailsFieldsRequiredFields,
          ...lowSlopeRoofCoverDetailsFieldsRequiredFields,
        ],
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Full form builder with all parts included, for Iteration Engine
export const homeStandardIterationEngineRoofingComplianceRoofCoverFormBuilder =
  (
    factory: SchemaFactoryV2,
    beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
  ): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
    return ((onValueChange, form, errors) => {
      let fields = [
        ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
          modelName,
          [
            ...roofCoverInformationFields(form, false),
            ...asphaltShingleRoofCoverDetailsFields(
              form,
              false,
              shouldHideAsphaltShingleRoofCoverDetailsFields
            ),
            ...metalRoofCoverDetailsFields(
              form,
              false,
              shouldHideMetalRoofCoverDetailsFields
            ),
            ...clayConcreteTileRoofCoverDetailsFields(
              form,
              false,
              shouldHideClayConcreteTileRoofCoverDetailsFields
            ),
            ...woodShakeShingleRoofCoverDetailsFields(
              form,
              false,
              shouldHideWoodShakeSingleRoofCoverDetailsFields
            ),
            ...lowSlopeRoofCoverDetailsFields(
              form,
              false,
              shouldHideLowSlopeRoofCoverDetailsFields
            ),
          ],
          onValueChange,
          form,
          errors,
          [
            ...roofCoverInformationRequiredFields,
            ...asphaltShingleRoofCoverDetailsFieldsRequiredFields,
            ...metalRoofCoverDetailsFieldsRequiredFields,
            ...clayConcreteTileRoofCoverDetailsRequiredFields,
            ...woodShakeShingleRoofCoverDetailsFieldsRequiredFields,
            ...lowSlopeRoofCoverDetailsFieldsRequiredFields,
          ],
          {
            FileUploader: WildfireBoxContentExplorer,
            WarningBanner: ReadonlyWarningBannerField,
          }
        ),
      ];
      if (beforeBuild) {
        fields = beforeBuild(fields);
      }
      return fields;
    }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
  };
