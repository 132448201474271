import { useEffect, useState } from "react";
import {
  FCFMFSchemaFactoryV2,
  FieldSchemaFactoryProvider,
} from "../../../common/customHooks/useFCFMFFieldSchemaFactory";
import { useCommercialIterationEngine } from "./useCommercialIterationEngine";
import CommercialIterationEngineFormsContainer from "../forms/EvaluationForm/CommercialIterationEngineFormsContainer";
import CommercialIterationEngineHeader from "./Header/CommercialIterationEngineHeader";
import FortifiedIterationEngineChecklistAndTabs from "../../../../Common/components/IterationEngine/ChecklistAndTabs/FortifiedIterationEngineChecklistAndTabs";
import FortifiedIterationEngineCommentPanel from "../../../../Common/components/IterationEngine/FortifiedIterationEngineCommentPanel";
import { fortifiedUserStore } from "../../../../../foritfied/stores/FortifiedUserStore";
import EvaluationHistory from "../../../../../foritfied/components/Evaluation/History/EvaluationHistory";
import ComplexCommercialEvaluationRelationshipViewV2 from "../ComplexCommercialEvaluationBuilding/ComplexCommercialEvaluationRelationshipViewV2";
import { ProductSubtypes } from "../../../../Common/types/Evaluation/BaseEvaluation";

export interface CommercialIterationEngineProps {
  evaluationId: string;
  commercialSchemaFactory: FCFMFSchemaFactoryV2;
}

const CommercialIterationEngine: React.FC<CommercialIterationEngineProps> = (
  props
) => {
  const {
    //Evaluation
    commercialEvaluation,
    setCommercialEvaluation,
    refreshCommercialEvaluation,
    //Comments
    comments,
    createComment,
    deleteComment,
    selectedKey,
    selectedField,
    setSelectedFieldAndGetComments,
    setSelectedKeyAndGetComments,
    //ChangeRequests
    allChangeRequests,
    resolveChangeRequest,
    unresolveChangeRequest,
    unresolvedChangeRequests,
    getChangeRequests,
    changeRequestCount,
    resolvedChangeRequestCount,
    resolvedAllChangeRequests,
    hasNoUnaddressedChangeRequests,
    getEvaluationFieldKeysForAllUnresolvedChangeRequests,
    resolvedChangeRequestsPercentage,
    addressedAtLeastOneChangeRequest,
    //Iteration Engine
    setSelectedIterationEngineFormTabId,
    selectedIterationEngineFormTabId,
    canEditForm,
    formTabs,
    fieldsToEnable,
    evaluationSteps,
  } = useCommercialIterationEngine(
    props.evaluationId,
    props.commercialSchemaFactory.schema
  );

  const [evaluationFormInitialized, setEvaluationFormInitialized] =
    useState(false);

  useEffect(() => {
    setEvaluationFormInitialized(false);
  }, [props.evaluationId, selectedIterationEngineFormTabId]);

  return (
    <section className="wildfire-admin-iteration-engine">
      {commercialEvaluation && (
        <FieldSchemaFactoryProvider
          schemaFactory={props.commercialSchemaFactory}
          formOverrides={{
            onLabelClick: (fieldProps) =>
              setSelectedFieldAndGetComments?.(
                commercialEvaluation.id,
                fieldProps
              ),
            iterationEngineProps: {
              fieldKeysWithChangeRequests:
                getEvaluationFieldKeysForAllUnresolvedChangeRequests,
              shouldEnableForm: canEditForm,
              changeRequests: allChangeRequests,
              fieldsToEnable: fieldsToEnable(),
            },
          }}
        >
          <div className="wildfire-iteration-engine">
            <div className="wildfire-iteration-engine__header">
              <CommercialIterationEngineHeader
                evaluation={commercialEvaluation}
                evaluationId={props.evaluationId}
                setEvaluation={setCommercialEvaluation}
                allChangeRequestsAreResolved={resolvedAllChangeRequests}
                hasNoUnaddressedChangeRequests={hasNoUnaddressedChangeRequests}
                addressedAtLeastOneChangeRequest={
                  addressedAtLeastOneChangeRequest
                }
                isEvaluationClaimedByCurrentUser={
                  commercialEvaluation.auditorId ===
                  fortifiedUserStore?.user?.id
                }
                refreshEvaluation={refreshCommercialEvaluation}
              />
            </div>
            <div className="wildfire-iteration-engine__content">
              <div className="wildfire-iteration-engine__left-panel">
                <FortifiedIterationEngineChecklistAndTabs
                  evaluationStep={commercialEvaluation.step}
                  evaluationStatus={commercialEvaluation.status_AsString}
                  selectedTabId={selectedIterationEngineFormTabId}
                  setSelectedTabId={setSelectedIterationEngineFormTabId}
                  formTabs={formTabs}
                  evaluationStepDetails={evaluationSteps}
                >
                  <div>
                    <EvaluationHistory
                      evaluationId={props.evaluationId}
                      productSubtype={ProductSubtypes.CommercialEvaluation}
                    />
                    {(commercialEvaluation.isComplexCommercialEvaluation ||
                      commercialEvaluation.isComplexCommercialEvaluationBuilding) && (
                      <ComplexCommercialEvaluationRelationshipViewV2
                        evaluationId={props.evaluationId}
                      />
                    )}
                  </div>
                </FortifiedIterationEngineChecklistAndTabs>
              </div>
              <div className="wildfire-iteration-engine__center-panel">
                <CommercialIterationEngineFormsContainer
                  fieldsContainerClassName="evaluation-forms-container"
                  selectedTabId={selectedIterationEngineFormTabId}
                  changeRequests={allChangeRequests}
                  evaluationId={props.evaluationId}
                  evaluationStep={commercialEvaluation.step}
                  getChangeRequests={getChangeRequests}
                  onEvaluationFormInitialized={() => {
                    setEvaluationFormInitialized(true);
                  }}
                  refreshEvaluation={refreshCommercialEvaluation}
                  evaluation={commercialEvaluation}
                />
              </div>
              <div className="wildfire-iteration-engine__right-panel">
                <FortifiedIterationEngineCommentPanel
                  fieldsContainerClassName="evaluation-forms-container"
                  isOpen={evaluationFormInitialized}
                  changeRequests={allChangeRequests}
                  unresolvedChangeRequests={unresolvedChangeRequests}
                  setSelectedIterationEngineFormTabId={
                    setSelectedIterationEngineFormTabId
                  }
                  selectedIterationEngineFormTabId={
                    selectedIterationEngineFormTabId
                  }
                  selectedKey={selectedKey}
                  comments={comments}
                  createComment={(
                    comment,
                    evaluationFieldKey,
                    evaluationFieldLabel,
                    isPublic
                  ) =>
                    createComment(
                      commercialEvaluation.id,
                      comment,
                      evaluationFieldKey,
                      evaluationFieldLabel,
                      isPublic
                    )
                  }
                  selectedField={selectedField}
                  deleteComment={(commentId, evaluationFieldKey) =>
                    deleteComment(
                      commercialEvaluation.id,
                      commentId,
                      evaluationFieldKey
                    )
                  }
                  setSelectedKeyAndGetComments={(fieldKey) =>
                    setSelectedKeyAndGetComments!(
                      commercialEvaluation.id,
                      fieldKey
                    )
                  }
                  allChangeRequests={allChangeRequests}
                  changeRequestCount={changeRequestCount}
                  resolvedChangeRequestCount={resolvedChangeRequestCount}
                  resolvedChangeRequestsPercentage={
                    resolvedChangeRequestsPercentage
                  }
                  unresolveChangeRequest={unresolveChangeRequest}
                  resolveChangeRequest={resolveChangeRequest}
                  getChangeRequests={getChangeRequests}
                />
              </div>
            </div>
          </div>
        </FieldSchemaFactoryProvider>
      )}
    </section>
  );
};

export default CommercialIterationEngine;
