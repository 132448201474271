import moment from "moment";
import { RefObject } from "react";
import { createPortal } from "react-dom";
import { Evaluation } from "../../types/evaluation/Evaluation";
import {
  commercialAddressStyles,
  commercialApprovalDateStyles,
  commercialEvaluationIdStyles,
  commercialExpirationDateStyles,
  iframeStyles,
  multifamilyAddressStyles,
  multifamilyApprovalDateStyles,
  multifamilyEvaluationIdStyles,
  multifamilyExpirationDateStyles,
  printableCertificateCertificationStyles,
  printableCertificateOverlayStyles,
  printableCertificateStyles,
} from "./styles";
import "./styles";

interface CertificateProps {
  printableRef: RefObject<HTMLIFrameElement>;
  evaluation: Evaluation;
}

export default function Certificate({
  evaluation,
  printableRef,
}: CertificateProps) {
  const printableBodyRef = printableRef?.current?.contentWindow?.document?.body;
  const printableheadRef = printableRef?.current?.contentWindow?.document?.head;

  const productType =
    evaluation.productType_AsString === "FORTIFIED Commercial"
      ? "commercial"
      : "multifamily";
  const newOrExistingConstruction = evaluation.isNewConstruction
    ? "new"
    : "existing";
  const designationLevel = evaluation.designationLevel_AsString.toLowerCase();
  const hazardType = evaluation.hazard_AsString.replace(" ", "").toLowerCase();

  return (
    <iframe
      id="printable-wrapper"
      width={"100%"}
      height={"100%"}
      style={iframeStyles}
      ref={printableRef}
    >
      <>
        {printableheadRef &&
          createPortal(
            <style
              dangerouslySetInnerHTML={{
                __html: `@page {
                size: landscape;
                margin: 0;
              }
              @media print {
                html,
                body {
                  margin: 0 !important; 
                  padding: 0 !important;
                }
              }`,
              }}
            />,
            printableheadRef
          )}
        {printableBodyRef &&
          createPortal(
            <div id="printable-certificate" style={printableCertificateStyles}>
              <img
                style={printableCertificateCertificationStyles}
                src={`/certificates/${productType}_${designationLevel}_${newOrExistingConstruction}_${hazardType}${
                  evaluation.hasHailSupplement ? "_hail" : ""
                }.jpg`}
              />
              <div style={printableCertificateOverlayStyles}>
                <div
                  style={
                    productType === "commercial"
                      ? commercialAddressStyles
                      : multifamilyAddressStyles
                  }
                >
                  <div>{evaluation.buildingAddress}</div>
                  <div>
                    {evaluation.buildingCity},{" "}
                    {evaluation.buildingState_AsString} {evaluation.buildingZip}
                  </div>
                </div>
                <div
                  style={
                    productType === "commercial"
                      ? commercialApprovalDateStyles
                      : multifamilyApprovalDateStyles
                  }
                >
                  {moment(evaluation.approvedAt).format("L")}
                </div>
                <div
                  style={
                    productType === "commercial"
                      ? commercialEvaluationIdStyles
                      : multifamilyEvaluationIdStyles
                  }
                >
                  {evaluation.id}
                </div>
                <div
                  style={
                    productType === "commercial"
                      ? commercialExpirationDateStyles
                      : multifamilyExpirationDateStyles
                  }
                >
                  {moment(evaluation.expiresAt).format("L")}
                </div>
              </div>
            </div>,
            printableBodyRef
          )}
      </>
    </iframe>
  );
}
