import { useState } from "react";
import { auditorChecklistApiClient } from "../../foritfied/lib/apiClients/auditorChecklist/auditorChecklistApiClient";
import { AppToaster } from "../../foritfied/components/Toast/Toast";
import { Intent } from "@blueprintjs/core";

export const useFailedAuditorChecklists = () => {
  const [failedChecklists, setFailedChecklists] = useState<string[]>([]);

  const retrieveFailedAuditorChecklists = (evalId?: string) => {
    if (evalId === undefined) return;

    auditorChecklistApiClient
      .getFailedAuditorChecklists(evalId)
      .then((x) => setFailedChecklists(x))
      .catch(() =>
        AppToaster.show({
          intent: Intent.DANGER,
          message: "Failed to retrieve failed auditor checklists",
          timeout: 5000,
        })
      );
  };

  const renderFailedAuditorChecklists = () => {
    if (failedChecklists.length > 0) {
      return (
        <>
          <div style={{ padding: "15px 10px 0" }}>
            <p>The following forms contain items that require correction: </p>
          </div>
          <ul>
            {failedChecklists.map((x, i) => (
              <li key={`faileChecklist.${i}`}>{x}</li>
            ))}
          </ul>
        </>
      );
    }
  };

  return {
    failedChecklists,
    retrieveFailedAuditorChecklists,
    renderFailedAuditorChecklists,
  };
};
