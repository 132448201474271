import { BaseHomeEvaluationBasicInformationFormModel } from "../../common/types/BaseHomeEvaluationBasicInformationFormModel";

export interface HomeStandardEvaluationBasicInformationFormModel
  extends BaseHomeEvaluationBasicInformationFormModel,
    HomeStandardEvaluationBasicInformationFormBase {}

export interface HomeStandardEvaluationBasicInformationFormBase
  extends FormChecklistFields {
  id: string;
  homeStandardEvaluationId: string;
  evaluationId: string;

  homeownerInformationRequiredFieldCount: number;

  homeownerInformationHasBeenSubmitted: boolean;

  isHomeownerInformationComplete: boolean;
  areAllRequiredFieldsComplete: boolean;
}

export interface FormChecklistFields {
  completerRoles: number[];
  completerRoles_AsString: string[];
}

export enum HomeStandardSpecialProjectTypes {
  NCIUA_NCJUA_SYR = 1,
  NCIUA_NCJUA_SYCR = 2,
  NCIUA_NCJUA_Claim = 3,
  NCIUA_NCJUA_Other = 4,
  StrengthenAlabamaHomes = 11,
  SCSH = 21,
  LDIFORTIFIEDRoofGrant = 31,
  FannieMae = 41,
  NA = 999,
}
