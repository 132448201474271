import { useCommercialRedesignation } from "../../customHooks/useCommercialRedesignation";
import {
  useEvaluationChangeRequest,
  UseEvaluationChangeRequestProps,
} from "../../../../../common/customHooks/IterationEngine/ChangeRequests/useEvaluationChangeRequest";
import {
  UseEvaluationCommentsProps,
  useEvaluationIterationEngineComments,
} from "../../../../../common/customHooks/IterationEngine/Comments/useEvaluationIterationEngineComments";
import { FCFMFSchema } from "../../../common/customHooks/useFCFMFFieldSchemaFactory";
import { useContext, useEffect, useRef, useState } from "react";
import { getThirdLevelNesting } from "../../../../Common/customHooks/useFortifiedChangeRequestNavigator";
import { ChangeRequestV2 } from "../../../../../wildfire/components/IterationEngine/types/ChangeRequestV2";
import { fortifiedUserStore } from "../../../../../foritfied/stores/FortifiedUserStore";
import { fortifiedEvaluationCommentAPIClient } from "../../../../Common/lib/apiClients/fortifiedCommentAPIClient";
import { fortifiedChangeRequestApiClient } from "../../../../Common/lib/apiClients/fortifiedChangeRequestAPIClient";
import { AppHeaderContext } from "../../../../../wildfire/components/Provider/AppHeaderContext";
import { EvaluationStep } from "../../../../../foritfied/types/evaluation/Evaluation";
import { FeatureToggleSet } from "../../../../../common/Components/FeatureToggle/FeatureToggles";
import { featureToggleApiClient } from "../../../../../common/Components/FeatureToggle/class/featureToggleApiClient";

export const useCommercialRedesignationIterationEngine = (
  evaluationId: string,
  commercialSchema: FCFMFSchema
) => {
  const [featureToggles, setFeatureToggles] = useState<FeatureToggleSet>();

  const getFeatureToggles = async () => {
    const toggles = await featureToggleApiClient.getFeatureToggleSet();

    setFeatureToggles(toggles);
  };

  useEffect(() => {
    getFeatureToggles();
  }, []);

  const {
    commercialRedesignation,
    refreshCommercialRedesignation,
    isApplicationLoading,
    setCommercialRedesignation,
  } = useCommercialRedesignation(evaluationId);

  const useEvaluationIterationEngineCommentsProps: UseEvaluationCommentsProps =
    {
      getEvaluationComments:
        fortifiedEvaluationCommentAPIClient.getEvaluationComments,
      createEvaluationComments:
        fortifiedEvaluationCommentAPIClient.createEvaluationComments,
      createEvaluationComment:
        fortifiedEvaluationCommentAPIClient.createEvaluationComment,
      deleteEvaluationComment:
        fortifiedEvaluationCommentAPIClient.deleteEvaluationComment,
    };

  const {
    comments,
    setComments,
    createComment,
    deleteComment,
    selectedKey,
    selectedField,
    setSelectedFieldAndGetComments,
    setSelectedKeyAndGetComments,
  } = useEvaluationIterationEngineComments(
    useEvaluationIterationEngineCommentsProps
  );

  const useEvaluationChangeRequestProps: UseEvaluationChangeRequestProps = {
    evaluationId: evaluationId,
    schema: commercialSchema,
    getChangeRequest: fortifiedChangeRequestApiClient.getChangeRequest,
    getChangeRequestsByEvaluation:
      fortifiedChangeRequestApiClient.getChangeRequestsByEvaluation,
    resolveChangeRequest: fortifiedChangeRequestApiClient.resolveChangeRequest,
    unresolveChangeRequest:
      fortifiedChangeRequestApiClient.unresolveChangeRequest,
  };

  const {
    allChangeRequests,
    setAllChangeRequests,
    updateChangeRequest,
    resolveChangeRequest,
    unresolveChangeRequest,
    unresolvedChangeRequests,
    hasNoUnaddressedChangeRequests,
    addressedAtLeastOneChangeRequest,
    resolvedAllChangeRequests,
    getChangeRequests,
    changeRequestCount,
    resolvedChangeRequestCount,
    getEvaluationFieldKeysForAllUnresolvedChangeRequests,
    resolvedChangeRequestsPercentage,
    hasOneNewChangeRequest,
    longestOutstandingChangeRequest,
  } = useEvaluationChangeRequest(useEvaluationChangeRequestProps);

  const defaultTabId = "CommercialRedesignationForm";

  const [
    selectedIterationEngineFormTabId,
    setSelectedIterationEngineFormTabId,
  ] = useState<string>(defaultTabId);
  const hasSetInitialTab = useRef(false);

  useEffect(() => {
    if (unresolvedChangeRequests.length === 0 || hasSetInitialTab.current) {
      return;
    }

    const initialTab = getInitialTabFromChangeRequests(
      unresolvedChangeRequests
    );

    setSelectedIterationEngineFormTabId(initialTab);
    hasSetInitialTab.current = true;
  }, [allChangeRequests]);

  const getInitialTabFromChangeRequests = (
    unresolvedChangeRequests: ChangeRequestV2[]
  ): string => {
    return unresolvedChangeRequests.length > 0
      ? getThirdLevelNesting(unresolvedChangeRequests[0].evaluationFieldKey) ||
          defaultTabId
      : defaultTabId;
  };

  const { setFCFMFEvaluationHeader } = useContext(AppHeaderContext);

  useEffect(() => {
    if (commercialRedesignation) {
      setFCFMFEvaluationHeader(commercialRedesignation);
    }
  }, [commercialRedesignation]);

  const canEditForm = fortifiedUserStore.IsFCFMFAdmin;

  const baseTabs = [
    { id: "CommercialRedesignationForm", label: "Redesignation Form" },
    {
      id: "CommercialRedesignationEvaluatorEmailForm",
      label: "Evaluator Email Form",
    },
    {
      id: "CommercialRedesignationFieldInspectionForm",
      label: "Field Inspection Form",
    },
    {
      id: "CommercialRedesignationComplianceLetterForm",
      label: "Compliance Letter Form",
    },
  ];

  const formTabs = [...baseTabs];

  const baseEvaluationStatuses = [
    {
      id: EvaluationStep.Application,
      label: "Application",
    },
    {
      id: EvaluationStep.Payment,
      label: "Payment",
    },
    {
      id: EvaluationStep.EvaluatorAssignment,
      label: "Evaluator Assignment",
    },
    {
      id: EvaluationStep.FieldInspectionsSubmitted,
      label: "Field Inspections",
    },
    {
      id: EvaluationStep.EvaluatorComplianceLetterSubmitted,
      label: "Compliance Letter",
    },
    {
      id: EvaluationStep.FinalReview,
      label: "IBHS Review",
    },
  ];

  const evaluationStatuses = baseEvaluationStatuses.filter(
    (status) =>
      featureToggles?.FCFMFRedesignationPayment ||
      status.id !== EvaluationStep.Payment
  );

  return {
    //Evaluation
    commercialRedesignation,
    refreshCommercialRedesignation,
    isApplicationLoading,
    setCommercialRedesignation,
    //Comments
    comments,
    setComments,
    createComment,
    deleteComment,
    selectedKey,
    selectedField,
    setSelectedFieldAndGetComments,
    setSelectedKeyAndGetComments,
    //ChangeRequests
    allChangeRequests,
    setAllChangeRequests,
    updateChangeRequest,
    resolveChangeRequest,
    unresolveChangeRequest,
    unresolvedChangeRequests,
    hasNoUnaddressedChangeRequests,
    addressedAtLeastOneChangeRequest,
    resolvedAllChangeRequests,
    getChangeRequests,
    changeRequestCount,
    resolvedChangeRequestCount,
    getEvaluationFieldKeysForAllUnresolvedChangeRequests,
    resolvedChangeRequestsPercentage,
    hasOneNewChangeRequest,
    longestOutstandingChangeRequest,
    //Iteration Engine
    setSelectedIterationEngineFormTabId,
    selectedIterationEngineFormTabId,
    canEditForm,
    formTabs,
    defaultTabId,
    evaluationStatuses,
  };
};
