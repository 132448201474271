import { FieldSchemaFormBuilderV2, SchemaFactoryV2 } from "@ucl/library";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import {
  WildfireBoxContentExplorer,
  WildfireBoxContentExplorerProps,
} from "../../../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import { get } from "lodash";
import { Evaluation_CommercialRedesignationFormModel } from "../../../types/CommercialRedesignationFormModel";
import { permissionStore } from "../../../../../../foritfied/stores/PermissionStore";

const modelName = "Evaluation";
const subModelName = "CommercialRedesignation";
const formName = "FieldInspectionForm";

const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

const fileUploaderFields = (
  _form: Evaluation_CommercialRedesignationFormModel,
  errors?: { [key: string]: string[] }
) => [
  {
    fieldKey: buildFieldKey("FieldInspectionPhotos"),
    defaultToUpload: false,
    errorMessages: get(errors, "fieldInspectionPhotos.FileCount"),
    canDelete: permissionStore.canDeleteFilesFromSensitiveUploaders,
  } as WildfireBoxContentExplorerProps,
];

const fileUploaderRequiredFields = [buildFieldKey("FieldInspectionPhotos")];

export const commercialEvaluationFieldInspectionFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_CommercialRedesignationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [...fileUploaderFields(form, errors)];

    return factory.buildFieldSchemas<Evaluation_CommercialRedesignationFormModel>(
      modelName,
      fields,
      onValueChange,
      form,
      errors,
      fileUploaderRequiredFields,
      {
        FileUploader: WildfireBoxContentExplorer,
      }
    );
  }) as FieldSchemaFormBuilderV2<Evaluation_CommercialRedesignationFormModel>;
};

export const commercialRedesignationIterationEngineFieldInspectionFormBuilder =
  (
    factory: SchemaFactoryV2,
    beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
  ): FieldSchemaFormBuilderV2<Evaluation_CommercialRedesignationFormModel> => {
    return ((onValueChange, form, errors) => {
      let fields = [
        ...factory.buildFieldSchemas<Evaluation_CommercialRedesignationFormModel>(
          modelName,
          [...fileUploaderFields(form, errors)],
          onValueChange,
          form,
          errors,
          [...fileUploaderRequiredFields],
          {
            FileUploader: WildfireBoxContentExplorer,
          }
        ),
      ];
      if (beforeBuild) {
        fields = beforeBuild(fields);
      }
      return fields;
    }) as FieldSchemaFormBuilderV2<Evaluation_CommercialRedesignationFormModel>;
  };
