/* eslint-disable react/no-unescaped-entities */
import { Intent } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { dialogsViewerStore } from "../../stores/DialogsViewerStore";
import { evaluationStore } from "../../stores/EvaluationStore";
import { AppToaster } from "../Toast/Toast";
import { AdminDialog } from "./AdminDialog";
import { ApplyPaymentCreditDialog } from "./ApplyPaymentCreditDialog";
import { AuditorApprovalDialog } from "./AuditorApprovalDialog";
import { AuditorNotesDialog } from "./AuditorNotesDialog";
import { AuditorRejectionDialog } from "./AuditorRejectionDialog";
import { AuditorRequestChangeDialog } from "./AuditorRequestChangeDialog";
import { CommentsDialog } from "./CommentsDialog";
import { EvaluationCreationDialog } from "./EvaluationCreationDialog";
import { EvaluatorNotesDialog } from "./EvaluatorNotesDialog";
import { EvaluatorReviewSubmissionDialog } from "./EvaluatorReviewSubmissionDialog";
import { PaymentCreditDialog } from "./PaymentCreditDialog";
import { RejectionReasonsDialog } from "./RejectionReasonsDialog";
import "./styles.scss";
import { UserUpdateDialog } from "./UserUpdateDialog";
import { ViewCertificateDialog } from "./ViewCertificateDialog";
import { WithdrawEvaluationDialog } from "./WithdrawEvaluationDialog";
import { LegacyEvaluationFilesDialog } from "./LegacyEvaluationFilesDialog";
import { LegacyEvaluationDataDialog } from "./LegacyEvaluationDataDialog";
import { ProceedToIBHSReviewDialog } from "./ProceedToIBHSReviewDialog";
import { permissionStore } from "../../stores/PermissionStore";
import ConfirmDialog from "./ConfirmDialog";
import { PostDesignationFilesDialog } from "./PostDesignationFilesDialog";
import { uniqueId } from "lodash";
import { EvaluationTypes } from "../../types/evaluation/EvaluationTypes";
import CreateEvaluationDialog from "./CreateEvaluation/CreateEvaluationDialog";
import { SimpleConfirmationDialog } from "./SimpleConfirmationDialog";
import FCFMFEvaluationComfirmationInfo from "./FCFMFEvaluationConfirmationInfo";
import { evaluationApiClient } from "../../lib/apiClients/evaluation/evaluationApiClient";
import { EvaluationStep } from "../../types/evaluation/Evaluation";
import { AuditorTraineeSendForReviewDialog } from "./AuditorTraineeSendForReviewDialog";

export const Dialogs = observer(() => {
  return (
    <>
      <SimpleConfirmationDialog
        isOpen={dialogsViewerStore.isComplianceLetterSendForwardDialogOpen}
        title={"Send Forward Confirmation"}
        content={<FCFMFEvaluationComfirmationInfo />}
        closeDialog={() =>
          dialogsViewerStore.setIsComplianceLetterSendForwardDialogOpen(false)
        }
        submitDialog={async () => {
          await evaluationStore.sendForward().then(() => {
            AppToaster.show({
              message: (
                <div>
                  <h3>Step Complete</h3>A status update email has been sent.
                </div>
              ),
              intent: Intent.SUCCESS,
            });
            dialogsViewerStore.setIsComplianceLetterSendForwardDialogOpen(
              false
            );
          });
        }}
      />
      <SimpleConfirmationDialog
        isOpen={dialogsViewerStore.isIBHSReviewApprovalDialogOpen}
        title={"Approval Confirmation"}
        content={<FCFMFEvaluationComfirmationInfo />}
        closeDialog={() =>
          dialogsViewerStore.setIsIBHSReviewApprovalDialogOpen(false)
        }
        submitDialog={async () => {
          if (evaluationStore.evaluation?.hasFCFMFPaymentWorkflow) {
            await evaluationStore.sendForward();
          } else {
            await evaluationStore.approveEvaluation();
          }
          AppToaster.show({
            message: (
              <div>
                <h3>Success</h3>Evaluation Approved.
              </div>
            ),
            intent: Intent.SUCCESS,
          });
          dialogsViewerStore.setIsIBHSReviewApprovalDialogOpen(false);
        }}
      />
      <AuditorApprovalDialog
        isOpen={dialogsViewerStore.isAuditorApprovalDialogOpen}
        step={evaluationStore.evaluation?.step}
        isComplexCommercialEvaluation={
          evaluationStore.isComplexCommercialEvaluation
        }
        afterApproval={
          dialogsViewerStore.auditorApprovalDialogOptions?.afterApproval
        }
        closeDialog={() =>
          dialogsViewerStore.setIsAuditorApprovalDialogOpen(false)
        }
        submitDialog={async () => {
          if (evaluationStore.evaluation?.hasFCFMFPaymentWorkflow) {
            await evaluationStore.sendForward();
            AppToaster.show({
              message: (
                <div>
                  <h3>Success</h3>Evaluation Approved.
                </div>
              ),
              intent: Intent.SUCCESS,
            });
          } else {
            await evaluationStore.approveEvaluation();
            AppToaster.show({
              message: (
                <div>
                  <h3>Success</h3>Evaluation Approved.
                </div>
              ),
              intent: Intent.SUCCESS,
            });
          }

          dialogsViewerStore.setIsAuditorApprovalDialogOpen(false);
        }}
      />
      <AuditorApprovalDialog
        isOpen={dialogsViewerStore.isAuditorApprovalDialogOpenV2}
        step={
          dialogsViewerStore.auditorApprovalDialogOptionsV2?.evaluation?.step
        }
        isComplexCommercialEvaluation={
          dialogsViewerStore.auditorApprovalDialogOptionsV2?.evaluation
            ?.isComplexCommercialEvaluation
        }
        afterApproval={
          dialogsViewerStore.auditorApprovalDialogOptionsV2?.afterApproval
        }
        closeDialog={() =>
          dialogsViewerStore.setIsAuditorApprovalDialogOpenV2(false)
        }
        submitDialog={async () => {
          if (
            !dialogsViewerStore.auditorApprovalDialogOptionsV2?.evaluation?.id
          ) {
            return;
          }

          if (
            dialogsViewerStore.auditorApprovalDialogOptionsV2?.evaluation
              ?.hasFCFMFPaymentWorkflow ||
            dialogsViewerStore.auditorApprovalDialogOptionsV2?.evaluation
              ?.step !== EvaluationStep.FinalReview
          ) {
            await evaluationApiClient.moveWorkflowToNextStep(
              dialogsViewerStore.auditorApprovalDialogOptionsV2?.evaluation?.id
            );
            AppToaster.show({
              message: (
                <div>
                  <h3>Success</h3>Evaluation Approved.
                </div>
              ),
              intent: Intent.SUCCESS,
            });
          } else {
            await evaluationApiClient.approveEvaluation(
              dialogsViewerStore.auditorApprovalDialogOptionsV2?.evaluation?.id
            );
            AppToaster.show({
              message: (
                <div>
                  <h3>Success</h3>Evaluation Approved.
                </div>
              ),
              intent: Intent.SUCCESS,
            });
          }

          dialogsViewerStore.setIsAuditorApprovalDialogOpenV2(false);
        }}
      />

      <AuditorTraineeSendForReviewDialog
        isOpen={dialogsViewerStore.isAuditorTraineeSendForReviewDialogOpen}
        step={evaluationStore.evaluation?.step}
        isComplexCommercialEvaluation={
          evaluationStore.isComplexCommercialEvaluation
        }
        afterConfirm={
          dialogsViewerStore.auditorTraineeSendForReviewDialogOpenOptions
            ?.afterConfirm
        }
        closeDialog={() =>
          dialogsViewerStore.setIsAuditorTraineeSendForReviewDialogOpen(false)
        }
        submitDialog={async () => {
          dialogsViewerStore.setIsAuditorTraineeSendForReviewDialogOpen(false);
        }}
      />

      <AuditorRejectionDialog
        key={evaluationStore.evaluation?.id}
        isOpen={dialogsViewerStore.isAuditorRejectionDialogOpen}
        closeDialog={() =>
          dialogsViewerStore.setIsAuditorRejectionDialogOpen(false)
        }
        submitDialog={async (options, additionalNotes) => {
          await evaluationStore.rejectEvaluation(options, additionalNotes);
          AppToaster.show({
            message: (
              <div>
                <h3>Rejected</h3>A "Not Approved" email has been sent.
              </div>
            ),
            intent: Intent.DANGER,
          });
          dialogsViewerStore.setIsAuditorRejectionDialogOpen(false);
        }}
        afterRejection={
          dialogsViewerStore.auditorRejectionDialogOptions?.afterRejection
        }
      />
      <AuditorRequestChangeDialog
        isOpen={dialogsViewerStore.isAuditorRequestChangeDialogOpen}
        closeDialog={() =>
          dialogsViewerStore.setIsAuditorRequestChangeDialogOpen(false)
        }
        submitDialog={async () => {
          await evaluationStore.requestChanges();
          AppToaster.show({
            message: (
              <div>
                <h3>Changes Requested</h3>A "Changes Requested" email has been
                sent.
              </div>
            ),
            intent: Intent.SUCCESS,
          });

          dialogsViewerStore.setIsAuditorRequestChangeDialogOpen(false);
        }}
      />
      <EvaluatorReviewSubmissionDialog
        isOpen={dialogsViewerStore.isEvaluatorReviewSubmissionDialogOpen}
        closeDialog={() =>
          dialogsViewerStore.setIsEvaluatorReviewSubmissionDialogOpen(false)
        }
        submitDialog={async () => {
          await evaluationStore.sendForReview();
          AppToaster.show({
            message: (
              <div>
                <h3>Review Sent</h3>A "Submitted For Review" email has been
                sent.
              </div>
            ),
            intent: Intent.SUCCESS,
          });

          dialogsViewerStore.setIsEvaluatorReviewSubmissionDialogOpen(false);
        }}
      />
      <EvaluationCreationDialog
        isOpen={dialogsViewerStore.isEvaluationCreationDialogOpen}
        closeDialog={() =>
          dialogsViewerStore.setIsEvaluationCreationDialog(false)
        }
        productType={permissionStore.getProductTypeByUserRole}
        submitDialog={async () => {
          // We must open the new tab synchronously so that the
          // browser doesn't flag it as a suspicious popup
          // source: https://www.yaplex.com/blog/avoid-browser-pop-up-blockers/
          const appWindow = window.open("", "_blank") as Window;
          const productType = permissionStore.getProductTypeByUserRole;

          const evaluation =
            productType === EvaluationTypes.Home
              ? await evaluationStore.createHomeEvaluation()
              : await evaluationStore.createEvaluation();

          const newEvaluationUrl = evaluationStore.getNewEvaluationUrl(
            productType,
            evaluation
          );

          if (newEvaluationUrl) {
            appWindow.location = newEvaluationUrl;
          }

          dialogsViewerStore.setIsEvaluationCreationDialog(false);
        }}
      />
      <ViewCertificateDialog
        isOpen={dialogsViewerStore.isViewCertificateDialogOpen}
        closeDialog={() =>
          dialogsViewerStore.setIsViewCertificateDialogOpen(false)
        }
        submitDialog={async () => {
          //
        }}
      />
      <UserUpdateDialog
        isOpen={dialogsViewerStore.isUserUpdateDialogOpen}
        closeDialog={() => dialogsViewerStore.setIsUserUpdateDialogOpen(false)}
        submitDialog={async () => {
          AppToaster.show({
            message: (
              <div>
                <h3>Success</h3>User Updated.
              </div>
            ),
            intent: Intent.SUCCESS,
          });

          dialogsViewerStore.setIsUserUpdateDialogOpen(false);
        }}
      />
      <WithdrawEvaluationDialog
        isOpen={dialogsViewerStore.isWithdrawEvaluationDialogOpen}
        closeDialog={async () =>
          dialogsViewerStore.setIsWithdrawEvaluationDialogOpen(false)
        }
        submitDialog={async () => {
          if (evaluationStore.evaluation) {
            await evaluationStore
              .withdrawApplication()
              .then(() => {
                AppToaster.show({
                  message: (
                    <div>
                      <h3>Success</h3>Evaluation Withdrawn.
                    </div>
                  ),
                  intent: Intent.SUCCESS,
                });
              })
              .finally(() => {
                dialogsViewerStore.setIsWithdrawEvaluationDialogOpen(false);
              });
          }
        }}
      />

      <ApplyPaymentCreditDialog
        isOpen={dialogsViewerStore.isApplyPaymentCreditDialogOpen}
        closeDialog={async () =>
          dialogsViewerStore.setIsApplyPaymentCreditDialogOpen(false)
        }
        submitDialog={async () => {
          AppToaster.show({
            message: (
              <div>
                <h3>Success</h3>Payment Credit Applied.
              </div>
            ),
            intent: Intent.SUCCESS,
          });

          dialogsViewerStore.setIsApplyPaymentCreditDialogOpen(false);
        }}
      />
      <CommentsDialog
        isOpen={dialogsViewerStore.isCommentsDialogOpen}
        closeDialog={async () =>
          dialogsViewerStore.setIsCommentsDialogOpen(false)
        }
        submitDialog={async () => {
          AppToaster.show({
            message: (
              <div>
                <h3>Success</h3>Comment Saved.
              </div>
            ),
            intent: Intent.SUCCESS,
          });

          dialogsViewerStore.setIsCommentsDialogOpen(false);
        }}
      />
      <PaymentCreditDialog
        isOpen={dialogsViewerStore.isPaymentCreditDialogOpen}
        closeDialog={async () =>
          dialogsViewerStore.setIsPaymentCreditDialogOpen(false)
        }
        submitDialog={async () => {
          AppToaster.show({
            message: (
              <div>
                <h3>Success</h3>Payment Credit Saved.
              </div>
            ),
            intent: Intent.SUCCESS,
          });

          dialogsViewerStore.setIsPaymentCreditDialogOpen(false);
        }}
      />
      <AuditorNotesDialog
        isOpen={dialogsViewerStore.isAuditorNotesDialogOpen}
        closeDialog={async () =>
          dialogsViewerStore.setIsAuditorNotesDialogOpen(false)
        }
        submitDialog={async () => {
          AppToaster.show({
            message: (
              <div>
                <h3>Success</h3>Auditor Note Saved
              </div>
            ),
            intent: Intent.SUCCESS,
          });
          dialogsViewerStore.setIsAuditorNotesDialogOpen(false);
        }}
      />
      <EvaluatorNotesDialog
        isOpen={dialogsViewerStore.isEvaluatorNotesDialogOpen}
        closeDialog={async () =>
          dialogsViewerStore.setIsEvaluatorNotesDialogOpen(false)
        }
        submitDialog={async () => {
          AppToaster.show({
            message: (
              <div>
                <h3>Success</h3>Evaluator Note Saved
              </div>
            ),
            intent: Intent.SUCCESS,
          });
          dialogsViewerStore.setIsEvaluatorNotesDialogOpen(false);
        }}
      />
      <CreateEvaluationDialog
        isOpen={dialogsViewerStore.isEvaluationCreationDialogOpen}
        closeDialog={() =>
          dialogsViewerStore.setIsEvaluationCreationDialog(false)
        }
        productType={permissionStore.getProductTypeByUserRole}
        submitDialog={async () => {
          dialogsViewerStore.setIsEvaluationCreationDialog(false);
        }}
      />
      <RejectionReasonsDialog
        key={evaluationStore.evaluation?.id}
        isOpen={dialogsViewerStore.isRejectionReasonDialogOpen}
        closeDialog={() =>
          dialogsViewerStore.setIsRejectionReasonDialogOpen(false)
        }
        submitDialog={async () =>
          dialogsViewerStore.setIsRejectionReasonDialogOpen(false)
        }
      />
      <AdminDialog
        isOpen={dialogsViewerStore.isAdminDialogOpen}
        closeDialog={() => dialogsViewerStore.setIsAdminDialogOpen(false)}
        submitDialog={async () => {
          dialogsViewerStore.setIsAdminDialogOpen(false);
          evaluationStore.refresh();
        }}
      />
      <LegacyEvaluationFilesDialog
        isOpen={dialogsViewerStore.isLegacyEvaluationFilesDialogOpen}
        closeDialog={() =>
          dialogsViewerStore.setIsLegacyEvaluationFilesDialogOpen(false)
        }
        submitDialog={async () => {
          dialogsViewerStore.setIsLegacyEvaluationFilesDialogOpen(false);
        }}
      />
      <LegacyEvaluationDataDialog
        isOpen={dialogsViewerStore.isLegacyEvaluationDataDialogOpen}
        closeDialog={() =>
          dialogsViewerStore.setIsLegacyEvaluationDataDialogOpen(false)
        }
        submitDialog={async () => {
          dialogsViewerStore.setIsLegacyEvaluationDataDialogOpen(false);
        }}
      />
      <ProceedToIBHSReviewDialog
        isOpen={dialogsViewerStore.isProceedToIBHSReviewDialogOpen}
        closeDialog={() =>
          dialogsViewerStore.setIsProceedToIBHSReviewDialogOpen(false)
        }
        submitDialog={async () => {
          dialogsViewerStore.setIsProceedToIBHSReviewDialogOpen(false);
        }}
      />
      <ConfirmDialog
        key={dialogsViewerStore.isConfirmDialogOpen.toString()}
        isOpen={dialogsViewerStore.isConfirmDialogOpen}
        content={dialogsViewerStore?.confirmDialogOptions?.content}
        closeDialog={() => {
          dialogsViewerStore?.confirmDialogOptions?.onClose();
          dialogsViewerStore.setIsConfirmDialogOpen(false);
        }}
        onConfirm={dialogsViewerStore?.confirmDialogOptions?.onConfirm}
        confirmHeaderText={
          dialogsViewerStore?.confirmDialogOptions?.confirmHeaderText
        }
        confirmButtonText={
          dialogsViewerStore?.confirmDialogOptions?.confirmButtonText
        }
        cancelButtonText={
          dialogsViewerStore?.confirmDialogOptions?.cancelButtonText
        }
        confirmButtonIntent={
          dialogsViewerStore?.confirmDialogOptions?.confirmButtonIntent
        }
        shouldHideCancelButton={
          dialogsViewerStore?.confirmDialogOptions?.shouldHideCancelButton
        }
      />
      <PostDesignationFilesDialog
        key={uniqueId()}
        isOpen={dialogsViewerStore.isPostDesignationFilesDialogOpen}
        closeDialog={() =>
          dialogsViewerStore.setIsPostDesignationFilesDialogOpen(false)
        }
        submitDialog={async () => {
          dialogsViewerStore.setIsPostDesignationFilesDialogOpen(false);
        }}
      />
    </>
  );
});
