import {
  commercialRedesignationRoutePrefix,
  commercialRoutePrefix,
  multifamilyRedesignationRoutePrefix,
  multifamilyRoutePrefix,
} from "../../foritfied/pages/index/fortifiedCommercialMultifamilyRouteConfig";
import {
  fortifiedHomeNCIUARoutePrefix,
  fortifiedHomeStandardRedesignationRoutePrefix,
  fortifiedHomeStandardRoutePrefix,
} from "../../foritfied/pages/index/fortifiedHomeRouteConfig";
import { EvaluationStep } from "../../foritfied/types/evaluation/Evaluation";
import { commercialEvaluationAPIClient } from "../../fortifiedV2/FCFMF/commercial/lib/apiClients/commercialEvaluationAPIClient";
import { ProductSubtypes } from "../../fortifiedV2/Common/types/Evaluation/BaseEvaluation";
import { wildfireRoutePrefix } from "../../wildfire/pages/index/wildfireRouteConfig";
import { wildfireApplicationApiClient } from "../../wildfire/lib/apiClients/evaluation/wildfireApplicationApiClient";
import { wildfireLandscapeReviewApiClient } from "../../wildfire/lib/apiClients/evaluation/wildfireLandscapeReviewApiClient";

// Fortified
const getFortifiedHomeStandardPaymentPageRoute = (
  evaluationId: string
): string => {
  return `${fortifiedHomeStandardRoutePrefix}/${evaluationId}/payment`;
};

const getFortifiedHomeStandardRedesignationPaymentPageRoute = (
  evaluationId: string
): string => {
  return `${fortifiedHomeStandardRedesignationRoutePrefix}/${evaluationId}/payment`;
};

const getFortifiedHomeNCIUAPaymentPageRoute = (
  evaluationId: string
): string => {
  return `${fortifiedHomeNCIUARoutePrefix}/${evaluationId}/payment`;
};

const getFortifiedCommercialApplicationFeePaymentPageRoute = (
  evaluationId: string
): string => {
  return `${commercialRoutePrefix}/${evaluationId}/payment/application-fee`;
};

const getFortifiedCommercialCertificateFeePaymentPageRoute = (
  evaluationId: string
): string => {
  return `${commercialRoutePrefix}/${evaluationId}/payment/certificate-fee`;
};

const getFortifiedCommercialPaymentPageRoute = (
  evaluationId: string,
  step: EvaluationStep
): string | undefined => {
  switch (step) {
    case EvaluationStep.ApplicationFee:
      return getFortifiedCommercialApplicationFeePaymentPageRoute(evaluationId);
    case EvaluationStep.CertificateFee:
      return getFortifiedCommercialCertificateFeePaymentPageRoute(evaluationId);
  }
};

const getFortifiedCommercialRedesignationPaymentPageRoute = (
  evaluationId: string
): string => {
  return `${commercialRedesignationRoutePrefix}/${evaluationId}/payment`;
};

const getFortifiedMultifamilyRedesignationPaymentPageRoute = (
  evaluationId: string
): string => {
  return `${multifamilyRedesignationRoutePrefix}/${evaluationId}/payment`;
};

const getFortifiedMultifamilyApplicationFeePaymentPageRoute = (
  evaluationId: string
): string => {
  return `${multifamilyRoutePrefix}/${evaluationId}/payment/application-fee`;
};

const getFortifiedMultifamilyCertificateFeePaymentPageRoute = (
  evaluationId: string
): string => {
  return `${multifamilyRoutePrefix}/${evaluationId}/payment/certificate-fee`;
};

const getFortifiedMultifamilyPaymentPageRoute = (
  evaluationId: string,
  step: EvaluationStep
): string | undefined => {
  switch (step) {
    case EvaluationStep.ApplicationFee:
      return getFortifiedMultifamilyApplicationFeePaymentPageRoute(
        evaluationId
      );
    case EvaluationStep.CertificateFee:
      return getFortifiedMultifamilyCertificateFeePaymentPageRoute(
        evaluationId
      );
  }
};

export const getFortifiedPaymentPageRoute = (
  productSubtype: ProductSubtypes,
  evaluationId: string,
  step: EvaluationStep
): string | undefined => {
  switch (productSubtype) {
    case ProductSubtypes.HomeStandardEvaluation:
      return getFortifiedHomeStandardPaymentPageRoute(evaluationId);
    case ProductSubtypes.HomeStandardRedesignation:
      return getFortifiedHomeStandardRedesignationPaymentPageRoute(
        evaluationId
      );
    case ProductSubtypes.HomeNCIUAEvaluation:
      return getFortifiedHomeNCIUAPaymentPageRoute(evaluationId);
    case ProductSubtypes.HomeNCIUARedesignation:
      // NOTE: HomeNCIUARedesignation is intentionally not implemented
      break;
    case ProductSubtypes.CommercialEvaluation:
      return getFortifiedCommercialPaymentPageRoute(evaluationId, step);
    case ProductSubtypes.CommercialRedesignation:
      return getFortifiedCommercialRedesignationPaymentPageRoute(evaluationId);
    case ProductSubtypes.MultifamilyEvaluation:
      return getFortifiedMultifamilyPaymentPageRoute(evaluationId, step);
    case ProductSubtypes.MultifamilyRedesignation:
      return getFortifiedMultifamilyRedesignationPaymentPageRoute(evaluationId);
  }
};

export const getFortifiedCheckoutUrlMethod = (
  paymentType: string | undefined
): ((evaluationId: string) => Promise<string>) | undefined => {
  switch (paymentType) {
    case "application-fee":
      return commercialEvaluationAPIClient.getApplicationFeeStripeCheckoutLink;

    case "certificate-fee":
      return commercialEvaluationAPIClient.getCertificateFeeStripeCheckoutLink;

    default:
      return undefined;
  }
};

// Wildfire
export const getWildfireApplicationFeePaymentPageRoute = (
  evaluationId: string
): string => {
  return `${wildfireRoutePrefix}/${evaluationId}/payment/application-fee`;
};

export const getWildfireLandscapeReviewFeePaymentPageRoute = (
  evaluationId: string
): string => {
  return `${wildfireRoutePrefix}/${evaluationId}/payment/landscape-review-fee`;
};

export const getWildfireCheckoutUrlMethod = (
  paymentType: string | undefined
): ((evaluationId: string) => Promise<string>) | undefined => {
  switch (paymentType) {
    case "application-fee":
      return wildfireApplicationApiClient.getApplicationFeeStripeCheckoutLink;

    case "landscape-review-fee":
      return wildfireLandscapeReviewApiClient.getLandscapeReviewFeeStripeCheckoutLink;

    default:
      return undefined;
  }
};
