import { useEffect, useState } from "react";
import {
  FCFMFSchemaFactoryV2,
  FieldSchemaFactoryProvider,
} from "../../../common/customHooks/useFCFMFFieldSchemaFactory";
import MultifamilyIterationEngineHeader from "./Header/MultifamilyIterationEngineHeader";
import FortifiedIterationEngineChecklistAndTabs from "../../../../Common/components/IterationEngine/ChecklistAndTabs/FortifiedIterationEngineChecklistAndTabs";
import { useMultifamilyIterationEngine } from "./useMultifamilyIterationEngine";
import MultifamilyIterationEngineFormsContainer from "../forms/EvaluationForm/MultifamilyIterationEngineFormsContainer";
import FortifiedIterationEngineCommentPanel from "../../../../Common/components/IterationEngine/FortifiedIterationEngineCommentPanel";
import { fortifiedUserStore } from "../../../../../foritfied/stores/FortifiedUserStore";
import EvaluationHistory from "../../../../../foritfied/components/Evaluation/History/EvaluationHistory";
import ComplexMultifamilyEvaluationRelationshipViewV2 from "../ComplexCommercialEvaluationBuilding/ComplexMultifamilyEvaluationRelationshipViewV2";
import { ProductSubtypes } from "../../../../Common/types/Evaluation/BaseEvaluation";

export interface MultifamilyIterationEngineProps {
  evaluationId: string;
  commercialSchemaFactory: FCFMFSchemaFactoryV2;
}

const MultifamilyIterationEngine: React.FC<MultifamilyIterationEngineProps> = (
  props
) => {
  const {
    //Evaluation
    multifamilyEvaluation,
    setMultifamilyEvaluation,
    refreshMultifamilyEvaluation,
    //Comments
    comments,
    createComment,
    deleteComment,
    selectedKey,
    selectedField,
    setSelectedFieldAndGetComments,
    setSelectedKeyAndGetComments,
    //ChangeRequests
    allChangeRequests,
    resolveChangeRequest,
    unresolveChangeRequest,
    unresolvedChangeRequests,
    getChangeRequests,
    changeRequestCount,
    resolvedChangeRequestCount,
    getEvaluationFieldKeysForAllUnresolvedChangeRequests,
    resolvedChangeRequestsPercentage,
    resolvedAllChangeRequests,
    hasNoUnaddressedChangeRequests,
    addressedAtLeastOneChangeRequest,
    //Iteration Engine
    setSelectedIterationEngineFormTabId,
    selectedIterationEngineFormTabId,
    canEditForm,
    formTabs,
    fieldsToEnable,
    evaluationSteps,
  } = useMultifamilyIterationEngine(
    props.evaluationId,
    props.commercialSchemaFactory.schema
  );

  const [evaluationFormInitialized, setEvaluationFormInitialized] =
    useState(false);

  useEffect(() => {
    setEvaluationFormInitialized(false);
  }, [props.evaluationId, selectedIterationEngineFormTabId]);

  return (
    <section className="wildfire-admin-iteration-engine">
      {multifamilyEvaluation && (
        <FieldSchemaFactoryProvider
          schemaFactory={props.commercialSchemaFactory}
          formOverrides={{
            onLabelClick: (fieldProps) =>
              setSelectedFieldAndGetComments?.(
                multifamilyEvaluation.id,
                fieldProps
              ),
            iterationEngineProps: {
              fieldKeysWithChangeRequests:
                getEvaluationFieldKeysForAllUnresolvedChangeRequests,
              shouldEnableForm: canEditForm,
              changeRequests: allChangeRequests,
              fieldsToEnable: fieldsToEnable(),
            },
          }}
        >
          <div className="wildfire-iteration-engine">
            <div className="wildfire-iteration-engine__header">
              <MultifamilyIterationEngineHeader
                evaluationId={props.evaluationId}
                evaluation={multifamilyEvaluation}
                setEvaluation={setMultifamilyEvaluation}
                allChangeRequestsAreResolved={resolvedAllChangeRequests}
                hasNoUnaddressedChangeRequests={hasNoUnaddressedChangeRequests}
                addressedAtLeastOneChangeRequest={
                  addressedAtLeastOneChangeRequest
                }
                isEvaluationClaimedByCurrentUser={
                  multifamilyEvaluation.auditorId ===
                  fortifiedUserStore?.user?.id
                }
                refreshEvaluation={refreshMultifamilyEvaluation}
              />
            </div>
            <div className="wildfire-iteration-engine__content">
              <div className="wildfire-iteration-engine__left-panel">
                <FortifiedIterationEngineChecklistAndTabs
                  evaluationStep={multifamilyEvaluation.step}
                  evaluationStatus={multifamilyEvaluation.status_AsString}
                  selectedTabId={selectedIterationEngineFormTabId}
                  setSelectedTabId={setSelectedIterationEngineFormTabId}
                  formTabs={formTabs}
                  evaluationStepDetails={evaluationSteps}
                >
                  <div>
                    <EvaluationHistory
                      evaluationId={props.evaluationId}
                      productSubtype={ProductSubtypes.MultifamilyEvaluation}
                    />
                    {(multifamilyEvaluation.isComplexCommercialEvaluation ||
                      multifamilyEvaluation.isComplexCommercialEvaluationBuilding) && (
                      <ComplexMultifamilyEvaluationRelationshipViewV2
                        evaluationId={props.evaluationId}
                      />
                    )}
                  </div>
                </FortifiedIterationEngineChecklistAndTabs>
              </div>
              <div className="wildfire-iteration-engine__center-panel">
                <MultifamilyIterationEngineFormsContainer
                  fieldsContainerClassName="evaluation-forms-container"
                  selectedTabId={selectedIterationEngineFormTabId}
                  changeRequests={allChangeRequests}
                  evaluationId={props.evaluationId}
                  evaluationStep={multifamilyEvaluation.step}
                  getChangeRequests={getChangeRequests}
                  onEvaluationFormInitialized={() => {
                    setEvaluationFormInitialized(true);
                  }}
                  evaluation={multifamilyEvaluation}
                  refreshEvaluation={refreshMultifamilyEvaluation}
                />
              </div>
              <div className="wildfire-iteration-engine__right-panel">
                <FortifiedIterationEngineCommentPanel
                  fieldsContainerClassName="evaluation-forms-container"
                  isOpen={evaluationFormInitialized}
                  changeRequests={allChangeRequests}
                  unresolvedChangeRequests={unresolvedChangeRequests}
                  setSelectedIterationEngineFormTabId={
                    setSelectedIterationEngineFormTabId
                  }
                  selectedIterationEngineFormTabId={
                    selectedIterationEngineFormTabId
                  }
                  selectedKey={selectedKey}
                  comments={comments}
                  createComment={(
                    comment,
                    evaluationFieldKey,
                    evaluationFieldLabel,
                    isPublic
                  ) =>
                    createComment(
                      multifamilyEvaluation.id,
                      comment,
                      evaluationFieldKey,
                      evaluationFieldLabel,
                      isPublic
                    )
                  }
                  selectedField={selectedField}
                  deleteComment={(commentId, evaluationFieldKey) =>
                    deleteComment(
                      multifamilyEvaluation.id,
                      commentId,
                      evaluationFieldKey
                    )
                  }
                  setSelectedKeyAndGetComments={(fieldKey) =>
                    setSelectedKeyAndGetComments!(
                      multifamilyEvaluation.id,
                      fieldKey
                    )
                  }
                  allChangeRequests={allChangeRequests}
                  changeRequestCount={changeRequestCount}
                  resolvedChangeRequestCount={resolvedChangeRequestCount}
                  resolvedChangeRequestsPercentage={
                    resolvedChangeRequestsPercentage
                  }
                  unresolveChangeRequest={unresolveChangeRequest}
                  resolveChangeRequest={resolveChangeRequest}
                  getChangeRequests={getChangeRequests}
                />
              </div>
            </div>
          </div>
        </FieldSchemaFactoryProvider>
      )}
    </section>
  );
};

export default MultifamilyIterationEngine;
