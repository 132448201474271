import { Button } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { ReactNode } from "react";
import {
  faCircle,
  faCircleCheck,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
  Evaluation,
  ProductTypes,
} from "../../../../foritfied/types/evaluation/Evaluation";
import { EvaluationReadDTO } from "../../../../foritfied/types/dtos/EvaluationReadDTO";
import DownloadCertificateActionButtonV2 from "../IterationEngine/DownloadCertificateActionButtonV2/DownloadCertificateActionButtonV2";
import { BaseEvaluation } from "../../types/Evaluation/BaseEvaluation";
import { fortifiedHomeRoutePrefix } from "../../../../foritfied/pages/index/fortifiedHomeRouteConfig";
import { fortifiedCommercialMultifamilyRoutePrefix } from "../../../../foritfied/pages/index/fortifiedCommercialMultifamilyRouteConfig";
import { useNavigate } from "react-router";
import { REDESIGNATION_STEPS, REGULAR_STEPS, StepKey } from "./types";

interface UseFortifiedApplicationCardProps {
  evaluation: Evaluation;
  onNavigateToEvaluation: (evaluation: EvaluationReadDTO) => void;
}

interface UseFortifiedApplicationCardReturn {
  status: string;
  secondaryHeaderInfo: string;
  primaryHeaderInfo: string;
  statusBadgeClass: string;
  statusSummary: ReactNode[];
  nextStatusSummary: ReactNode[];
  statusCallout: ReactNode | null;
  actionButtons: ReactNode;
  currentStep: number;
  progressSteps: ReadonlyArray<StepKey>;
  progressStepDisplayNames: typeof REDESIGNATION_STEPS | typeof REGULAR_STEPS;
  hasFinalDetermination: boolean;
  getStatusClass: (uiStep: number, currentStep: number) => string;
  getStatusIcon: (
    uiStep: number,
    currentStep: number,
    status: string
  ) => IconDefinition;
  progressValue: number;
  secondaryProgress: number;
  actionText: string;
}

/**
 * This hook handles:
 * - Progress tracking through application steps
 * - Status display and updates
 * - Action button logic
 * - Status messages and next steps
 */
const useFortifiedApplicationCard = ({
  evaluation,
  onNavigateToEvaluation,
}: UseFortifiedApplicationCardProps): UseFortifiedApplicationCardReturn => {
  const navigate = useNavigate();

  const navigateToBids = (rowData: EvaluationReadDTO) => {
    switch (Number(rowData.productType)) {
      case ProductTypes.FortifiedCommercial:
      case ProductTypes.FortifiedMultifamily:
        navigate(
          `${fortifiedCommercialMultifamilyRoutePrefix}/bid/${rowData.id}`
        );
        break;
      case ProductTypes.FortifiedHome:
        navigate(`${fortifiedHomeRoutePrefix}/bid/${rowData.id}`);
        break;
    }
  };

  /**
   * Maps backend enum step values (1-11) to UI step positions (1-9 or 1-6) for both regular and redesignation flows.
   * Regular flow: 9 steps total
   * Redesignation flow: 6 steps total
   */
  const mapEnumStepToUIStep = (
    enumStep: number,
    isRedesignation: boolean
  ): number => {
    if (isRedesignation) {
      return (
        {
          1: 1, // Application
          9: 2, // Payment
          3: 3, // Evaluator Assignment
          5: 4, // Field Inspections
          6: 5, // Compliance Letter
          7: 6, // IBHS Review
        }[enumStep] || enumStep
      );
    }

    return (
      {
        1: 1,
        10: 2,
        2: 3,
        3: 4,
        4: 5,
        5: 6,
        6: 7,
        7: 8,
        11: 9,
      }[enumStep] || enumStep
    );
  };

  // Get the appropriate steps based on evaluation type
  const progressStepDisplayNames = evaluation.isRedesignation
    ? REDESIGNATION_STEPS
    : REGULAR_STEPS;

  const progressSteps = Object.keys(
    progressStepDisplayNames
  ) as ReadonlyArray<StepKey>;

  /**
   * Status strings are normalized between regular flow ("Application Fee")
   * and redesignation flow ("Payment") for consistent display
   */
  const getNormalizedStatus = (status: string): string => {
    if (evaluation.isRedesignation && status === "Application Fee") {
      return "Payment";
    }
    if (!evaluation.isRedesignation && status === "Payment") {
      return "Application Fee";
    }
    return status;
  };

  const getFormattedAddressInfo = (): string => {
    const hasAddress =
      !!evaluation.buildingAddress &&
      !!evaluation.buildingCity &&
      !!evaluation.buildingState_AsString &&
      !!evaluation.buildingZip;

    if (!hasAddress) return "";

    const addressParts = [
      evaluation.buildingAddress,
      evaluation.buildingCity,
      evaluation.buildingState_AsString,
      evaluation.buildingZip,
    ].filter(Boolean);

    return `\u00A0${addressParts.join(", ")}`;
  };

  const getPrimaryHeaderInfo = (): string => {
    return `${evaluation.fortifiedId} - `;
  };

  const getStatusBadgeClass = (step: number, status: string): string => {
    // Handle special statuses first
    switch (status) {
      case "Changes Required":
        return "changes-required";
      case "Ready For Re-Review":
        return "ready-for-re-review";
      case "Approved":
        return "approved";
      case "Not Approved":
      case "Withdrawn":
        return "not-approved";
    }

    // Then handle step-based statuses
    switch (step) {
      case 1: // ApplicationInitiated
        return "1";
      case 10: // ApplicationFee
      case 9: // Payment
        return "10";
      case 2: // EligibilityReviewed
        return "2";
      case 3: // EvaluatorAssignment
        return "3";
      case 7: // IBHSReview
        return "7";
      case 4: // ComplianceFormSubmitted
      case 5: // FieldInspectionsSubmitted
      case 6: // EvaluatorComplianceLetterSubmitted
      case 11: // CertificateFee
      default:
        return "default";
    }
  };

  const getStatusSummary = (): ReactNode[] => {
    const normalizedStatus = getNormalizedStatus(evaluation.status_AsString);

    switch (normalizedStatus) {
      case "Application":
        return [
          evaluation.isRedesignation
            ? "Your redesignation application has been started, but not submitted."
            : "Your application has been started, but not submitted.",
        ];
      case "Application Fee":
      case "Payment":
        return ["Your application is pending payment."];
      case "IBHS Eligibility Review":
        return [
          "Your application is under eligibility review to determine if your project meets the minimum requirements to enter the program.",
        ];
      case "Evaluator Assignment":
        return [
          "Your application has been approved to seek FORTIFIED, and you are ready to proceed with requesting bids for your project.",
        ];
      case "Compliance Forms":
        return [
          "Your FORTIFIED Evaluator will contact you to complete the FORTIFIED Evaluator agreement and compliance forms.",
        ];
      case "Field Inspections":
        return [
          "Your application is making progress! The compliance forms have been accepted by the FORTIFIED Evaluator and your project is now in the field inspection step.",
        ];
      case "Compliance Letter":
        return [
          "Your application is making progress! Your FORTIFIED Evaluator has submitted the field inspections.",
        ];
      case "IBHS Review":
        return [
          "Your application is making progress! Your FORTIFIED Evaluator has submitted the compliance letter to the IBHS FORTIFIED Team.",
        ];
      case "Certificate Fee":
        return ["Your application is pending payment."];
      case "Changes Required":
        return [
          "After reviewing your application, there are outstanding requirements to reach designation.",
        ];
      case "Ready For Re-Review":
        return ["The team is reviewing the changes you have made."];
      case "Approved":
        return [
          `Congratulations! We are pleased to inform you that the above-named project meets the Insurance Institute for Business & Home Safety (IBHS) ${evaluation.productType_AsString}™ standards`,
          <span key="product">
            • <strong>Product Type:</strong> {evaluation.productType_AsString}
          </span>,
          <span key="approval">
            • <strong>Approval Date:</strong>{" "}
            {evaluation.approvedAt
              ? new Date(evaluation.approvedAt).toLocaleDateString()
              : "N/A"}
          </span>,
          <span key="expiration">
            • <strong>Expiration Date:</strong>{" "}
            {evaluation.expiresAt
              ? new Date(evaluation.expiresAt).toLocaleDateString()
              : "N/A"}
          </span>,
          <span key="designation">
            • <strong>Designation Level:</strong>{" "}
            {evaluation.designationLevel_AsString}
          </span>,
        ];
      case "Not Approved":
        return [
          "Thank you for your interest and participation in the program, unfortunately we are not able to approve your application as the following items did not meet FORTIFIED requirements:",
        ];
      case "Withdrawn":
        return ["Your application has been withdrawn."];
      default:
        return ["Status information unavailable. Please contact support."];
    }
  };

  const getNextStatusSummary = (): ReactNode[] => {
    const normalizedStatus = getNormalizedStatus(evaluation.status_AsString);

    switch (normalizedStatus) {
      case "Application":
        if (evaluation.isRedesignation) {
          return [
            "Fill out all required fields for your redesignation application. Once complete, you'll be directed to the payment page.",
          ];
        }
        return [
          "Fill out all required fields. Once complete, you'll be directed to the payment page.",
        ];
      case "Application Fee":
      case "Payment":
        return [
          "The application fee is nonrefundable, so ensure you are ready to continue.",
        ];
      case "IBHS Eligibility Review":
        return [
          <>
            Wait for the FORTIFIED team to review your application. In the
            meantime, please become familiar with the FORTIFIED standards and
            compliance process. Please visit the appropriate link below:
            <ul>
              <li>
                <a
                  href="https://fortifiedhome.org/fortified-commercial/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  FORTIFIED Commercial™
                </a>
              </li>
              <li>
                <a
                  href="https://fortifiedhome.org/fortified-multifamily/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  FORTIFIED Multifamily™
                </a>
              </li>
            </ul>
          </>,
        ];
      case "Not Approved":
        return [
          <span key="rejection-reasons">
            <strong>List of Rejection Reasons</strong>
            <ul>
              {evaluation.rejectionReasons_AsCSV
                ?.split(",")
                .map((reason, index) => (
                  <li key={index}>{reason.trim()}</li>
                ))}
            </ul>
          </span>,
          evaluation.rejectionAdditionalNotes && (
            <span key="rejection-notes">
              <strong>Rejection Reason Notes</strong>
              <p>{evaluation.rejectionAdditionalNotes}</p>
            </span>
          ),
        ];
      case "Evaluator Assignment":
        return [
          <>
            At this time, please visit the application page and submit project
            bid requests to FORTIFIED Evaluators.
            <ol>
              <li>
                Click the &apos;Send Request&apos; button next to the Evaluator
                you&apos;d like to send a bid request to.
              </li>
              <li>
                You can send a bid request to multiple companies at once. You
                can also re-send a bid request at any time by clicking the
                &apos;Re-send Request&apos; button.
              </li>
              <li>
                Once you have determined which evaluator you want to work with,
                navigate back to the same page and select the company
                you&apos;ve chosen.
              </li>
              <li>
                Click &apos;Submit&apos; to move the application forward and
                assign your desired Evaluator to the evaluation.
              </li>
            </ol>
          </>,
        ];
      case "Compliance Forms":
        return [
          <>
            <ol>
              <li>
                Once you complete the Evaluator agreement, please take the time
                to fill out the appropriate fillable PDF compliance forms for
                your project:
                <ul>
                  <li>
                    <a
                      href="https://fortifiedhome.org/commercial-standards/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      FORTIFIED Commercial Compliance Forms™
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://fortifiedhome.org/multifamily-technical-resources/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      FORTIFIED Multifamily Compliance Forms™
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                Next, upload the PDF(s) using the file uploader under the
                Compliance Forms section of the application page. If necessary,
                upload multiple forms. Then click &apos;Send for Review&apos; on
                the top right of the project page to notify your evaluator.
              </li>
              <li>
                Your FORTIFIED Evaluator will review your compliance forms and
                may ask for clarifications or additional
                information/documentation as noted in the FORTIFIED standards.
              </li>
            </ol>
          </>,
        ];
      case "Field Inspections":
        return [
          "Your FORTIFIED Evaluator will be reaching out to coordinating onsite inspections.",
        ];
      case "Compliance Letter":
        return [
          "Your FORTIFIED Evaluator will verify that all the information provided in the application is correct and create a compliance letter. You will be notified when this step is complete.",
        ];
      case "IBHS Review":
        return ["Wait for the FORTIFIED team to review your application."];
      case "Certificate Fee":
        return [
          <>
            <ul>
              <li>
                Your evaluation has been reviewed by IBHS. At this time, please
                submit the certificate payment.
              </li>
              <li>
                After payment is received, you will be able to download your
                certificate.
              </li>
            </ul>
          </>,
        ];
      case "Changes Required":
        return [
          <>
            <ol>
              <li>
                Click &quot;See Change Requests&quot; below to review the
                outstanding requirements with comments for remediation.
              </li>
              <li>
                Once you&apos;ve completed the required work, click the
                &quot;Send for Review&quot; button at the top right of the
                application page.
              </li>
            </ol>
          </>,
        ];
      case "Ready For Re-Review":
        return [
          <>
            <ul>
              <li>
                Watch your email inbox for any emails requesting additional
                changes required.
              </li>
              <li>You will be notified when this step is complete.</li>
            </ul>
          </>,
        ];
      case "Approved":
        const productLink = getProductLink();
        return [
          <span key="redesignation-info">
            To remain compliant with the designation, a re-evaluation must occur
            no later than 5 years from the date of this letter. It is the
            responsibility of the building owner to contact IBHS to initiate the
            re-designation audit. For more information on re-designation, refer
            to the {evaluation.productType_AsString}™ standards located at{" "}
            <a href={productLink} target="_blank" rel="noopener noreferrer">
              {productLink}
            </a>
            , as well as the IBHS website homepage at{" "}
            <a
              href="https://DisasterSafety.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              DisasterSafety.org
            </a>
            .
          </span>,
        ];

      default:
        return [];
    }
  };

  const getProductLink = () => {
    const baseUrl = "https://fortifiedhome.org";
    switch (evaluation.productType_AsString) {
      case "FORTIFIED Commercial":
        return `${baseUrl}/commercial-standards/`;
      case "FORTIFIED Multifamily":
        return `${baseUrl}/multifamily-technical-resources/`;
      default:
        return baseUrl;
    }
  };

  const getActionButtons = (): ReactNode => {
    const enumStep = evaluation.step;
    const normalizedStatus = getNormalizedStatus(evaluation.status_AsString);

    switch (enumStep) {
      case 1: // Application
        return (
          <Button
            intent="primary"
            rightIcon={IconNames.ARROW_RIGHT}
            text="Continue Application"
            onClick={() =>
              onNavigateToEvaluation(evaluation as unknown as EvaluationReadDTO)
            }
            className="fortified-application-overview-card-footer-navigation-button"
          />
        );
      case 10: // Application Fee
        return (
          <Button
            intent="primary"
            rightIcon={IconNames.ARROW_RIGHT}
            text="Continue to Payment"
            onClick={() =>
              onNavigateToEvaluation(evaluation as unknown as EvaluationReadDTO)
            }
            className="fortified-application-overview-card-footer-navigation-button"
          />
        );
      case 3: // Evaluator Assignment
        return (
          <Button
            intent="primary"
            rightIcon={IconNames.ARROW_RIGHT}
            text="Evaluator Bid and Assignment"
            onClick={() =>
              navigateToBids(evaluation as unknown as EvaluationReadDTO)
            }
            className="fortified-application-overview-card-footer-navigation-button"
          />
        );
      default:
        // Handle special statuses
        if (normalizedStatus === "Changes Required") {
          return (
            <Button
              intent="primary"
              rightIcon={IconNames.ARROW_RIGHT}
              text="See Change Requests"
              onClick={() =>
                onNavigateToEvaluation(
                  evaluation as unknown as EvaluationReadDTO
                )
              }
              className="fortified-application-overview-card-footer-navigation-button"
            />
          );
        }
        if (normalizedStatus === "Approved") {
          return (
            <div className="fortified-application-overview-card-footer-certificate-button-container">
              <DownloadCertificateActionButtonV2
                evaluation={evaluation as unknown as BaseEvaluation}
              />
            </div>
          );
        }
        return (
          <Button
            intent="primary"
            rightIcon={IconNames.ARROW_RIGHT}
            text="View Application"
            onClick={() =>
              onNavigateToEvaluation(evaluation as unknown as EvaluationReadDTO)
            }
            className="fortified-application-overview-card-footer-navigation-button"
          />
        );
    }
  };

  const getActionText = (): string => {
    const actionSteps = [1, 10, 3, 4]; // Application, ApplicationFee, EvaluatorAssignment, ComplianceForms

    if (
      actionSteps.includes(evaluation.step) ||
      evaluation.status_AsString === "Changes Required"
    ) {
      return "Action Needed:";
    }

    if (
      ["Approved", "Not Approved", "Withdrawn"].includes(
        evaluation.status_AsString
      )
    ) {
      return "";
    }

    return "No action needed at this time:";
  };

  /**
   * Status classes control the color/style of step indicators:
   * - Yellow: Current incomplete step
   * - Green: Completed step
   * - Default (Gray): Future step
   *
   * Special cases:
   * - Application Fee (step 10) is always yellow when current
   * - Certificate Fee (step 11) is green if payment processed
   * - IBHS Review (step 7) is green for approved redesignation
   */
  const getStatusClass = (uiStep: number): string => {
    const baseClass = "application-overview-status-bar__item";
    const mappedCurrentStep = mapEnumStepToUIStep(
      evaluation.step,
      evaluation.isRedesignation
    );

    // uiStep is the position in UI (1-based index)
    // mappedCurrentStep is where we are in the process
    if (uiStep === 2 && evaluation.step === 10) {
      return `${baseClass}__yellow`; // Force yellow for Application Fee step
    }

    if (
      evaluation.step === 11 &&
      evaluation.hasFinalCertificateFeePaymentBeenProcessed
    ) {
      return `${baseClass}__green`; // Force green for non-Redesignation Certificate Fee step, ONLY IF final payment has been processed.
    }

    if (
      evaluation.step === 7 &&
      evaluation.status_AsString === "Approved" &&
      evaluation.isRedesignation
    ) {
      return `${baseClass}__green`; // Force green for Redesignation IBHS Review step, ONLY IF status is Approved.
    }

    if (uiStep < mappedCurrentStep) {
      return `${baseClass}__green`;
    }
    if (uiStep > mappedCurrentStep) {
      return `${baseClass}__default`;
    }
    return `${baseClass}__yellow`;
  };

  const getStatusIcon = (
    step: number,
    currentStep: number,
    status: string
  ): IconDefinition => {
    const normalizedStatus = getNormalizedStatus(status);

    if (["Not Approved", "Withdrawn"].includes(normalizedStatus)) {
      if (step < currentStep) return faCircleCheck;
      if (step === currentStep) return faTimesCircle;
      return faCircle;
    }

    if (step < currentStep) return faCircleCheck;
    if (step === currentStep) {
      return normalizedStatus === "Approved" ? faCircleCheck : faCircle;
    }
    return faCircle;
  };

  /**
   * Progress bar value controls green fill (completed steps)
   * Secondary progress controls yellow fill (current step)
   * Special cases match status classes for consistency
   */
  const getProgressValue = () => {
    if (evaluation.hasFinalCertificateFeePaymentBeenProcessed) {
      return progressSteps.length; // All steps completed
    }
    if (
      evaluation.step === 7 && // IBHS Review
      evaluation.status_AsString === "Approved" &&
      evaluation.isRedesignation
    ) {
      return progressSteps.length;
    }
    return Math.max(
      0,
      mapEnumStepToUIStep(evaluation.step, evaluation.isRedesignation) - 1
    );
  };

  const hasFinalDetermination = ["Approved", "Not Approved"].includes(
    evaluation.status_AsString
  );

  return {
    status: evaluation.status_AsString,
    secondaryHeaderInfo: getFormattedAddressInfo(),
    primaryHeaderInfo: getPrimaryHeaderInfo(),
    statusBadgeClass: getStatusBadgeClass(
      evaluation.step,
      evaluation.status_AsString
    ),
    statusSummary: getStatusSummary(),
    nextStatusSummary: getNextStatusSummary(),
    statusCallout: null, // Implement if needed
    actionButtons: getActionButtons(),
    currentStep: mapEnumStepToUIStep(
      evaluation.step,
      evaluation.isRedesignation
    ),
    progressSteps,
    progressStepDisplayNames,
    hasFinalDetermination,
    getStatusClass,
    getStatusIcon,
    progressValue: getProgressValue(),
    secondaryProgress: mapEnumStepToUIStep(
      evaluation.step,
      evaluation.isRedesignation
    ),
    actionText: getActionText(),
  };
};

export default useFortifiedApplicationCard;
