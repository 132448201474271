import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import baseExternalApiClient from "../../../../../foritfied/lib/apiClients/baseExternalApiClient";
import { BaseHomeEvaluationEngineeringComplianceGeneralInformationFormParts } from "../../../common/types/BaseHomeEvaluationEngineeringComplianceGeneralInformationFormModel";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../types/HomeEvaluationFormModel";
import { HomeStandardEvaluationEngineeringComplianceGeneralInformationFormModel } from "../../types/HomeStandardEvaluationEngineeringComplianceGeneralInformationFormModel";

class HomeStandardEvaluationEngineeringComplianceGeneralInformationAPIClient {
  updateHomeStandardEngineeringComplianceGeneralInformationForm = async (
    evaluationId: string,
    engineeringComplianceGeneralInformationForm: HomeStandardEvaluationEngineeringComplianceGeneralInformationFormModel,
    fieldKey?: string
  ): Promise<Evaluation_HomeStandardEvaluationFormModel> => {
    return baseApiClient.put<Evaluation_HomeStandardEvaluationFormModel>(
      `/home-standard/engineering-compliance-general-information-form/${evaluationId}`,
      {
        engineeringComplianceGeneralInformationForm,
        fieldKey,
      }
    );
  };

  updateHomeStandardEngineeringComplianceGeneralInformationFormExternal =
    async (
      evaluationId: string,
      engineeringComplianceGeneralInformationForm: HomeStandardEvaluationEngineeringComplianceGeneralInformationFormModel,
      accessToken: string
    ): Promise<Evaluation_HomeStandardEvaluationFormModel> => {
      return baseExternalApiClient.put<Evaluation_HomeStandardEvaluationFormModel>(
        `/home-standard/engineering-compliance-general-information-form/external/${evaluationId}`,
        {
          engineeringComplianceGeneralInformationForm,
        },
        {
          headers: {
            AccessToken: accessToken,
          },
        }
      );
    };

  submitHomeStandardEngineeringComplianceGeneralInformationForm = async (
    evaluationId: string,
    engineeringComplianceGeneralInformationForm: HomeStandardEvaluationEngineeringComplianceGeneralInformationFormModel,
    formPart?: BaseHomeEvaluationEngineeringComplianceGeneralInformationFormParts
  ): Promise<Evaluation_HomeStandardEvaluationFormModel> => {
    // If applicationFormPart is not provided, default to the last part of the form
    const formPartValue = (
      formPart ??
      (Object.values(
        BaseHomeEvaluationEngineeringComplianceGeneralInformationFormParts
      ).pop() as BaseHomeEvaluationEngineeringComplianceGeneralInformationFormParts)
    ).valueOf();
    return baseApiClient.post<Evaluation_HomeStandardEvaluationFormModel>(
      `/home-standard/engineering-compliance-general-information-form/${evaluationId}/${formPartValue}`,
      engineeringComplianceGeneralInformationForm
    );
  };

  submitHomeStandardEngineeringComplianceGeneralInformationFormExternal =
    async (
      evaluationId: string,
      engineeringComplianceGeneralInformationForm: HomeStandardEvaluationEngineeringComplianceGeneralInformationFormModel,
      accessToken: string
    ): Promise<Evaluation_HomeStandardEvaluationFormModel> => {
      // Always default to the last part of the form
      const formPartValue = (
        Object.values(
          BaseHomeEvaluationEngineeringComplianceGeneralInformationFormParts
        ).pop() as BaseHomeEvaluationEngineeringComplianceGeneralInformationFormParts
      ).valueOf();
      return baseExternalApiClient.post<Evaluation_HomeStandardEvaluationFormModel>(
        `/home-standard/engineering-compliance-general-information-form/external/${evaluationId}/${formPartValue}`,
        engineeringComplianceGeneralInformationForm,
        {
          headers: {
            AccessToken: accessToken,
          },
        }
      );
    };
}

export const homeStandardEvaluationEngineeringComplianceGeneralInformationAPIClient =
  new HomeStandardEvaluationEngineeringComplianceGeneralInformationAPIClient();
