import { PrimaryCard } from "@ucl/library";
import { EvaluationStep } from "../../../../../../foritfied/types/evaluation/Evaluation";
import { useContext, useEffect, useState } from "react";
import { UnsavedChangesBlockerContext } from "../../../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import classNames from "classnames";
import CommercialApplicationForm from "../ApplicationForm/Form/CommercialApplicationForm";
import CommercialEvaluationCompanyAssignmentForm from "../EvaluationCompanyAssignmentForm/Form/CommercialEvaluationCompanyAssignmentForm";
import CommercialEvaluationFieldInspectionForm from "../EvaluationFieldInspectionForm/Form/CommercialEvaluationFieldInspectionForm";
import CommercialComplianceForm from "../ComplianceForm/Form/CommercialComplianceForm";
import CommercialComplianceLetter from "../ComplianceLetter/Form/CommercialComplianceLetter";
import {
  CommercialFormNameTypes,
  Evaluation_CommercialEvaluation,
} from "../../../types/CommercialEvaluation";

interface IterationEngineFormsProps {
  evaluationId: string;
  evaluationStep: EvaluationStep;
  onFormSave: (fieldKey: string) => Promise<void>;
  onAllFormsInitialized: () => void;
  fieldsContainerClassName: string;
  selectedTabId: string;
  evaluation: Evaluation_CommercialEvaluation;
}

const CommercialIterationEngineForms: React.FC<IterationEngineFormsProps> = (
  props
) => {
  //const loadEvaluationForms = props.evaluationStep === EvaluationStep.FinalReview;
  const [applicationFormInitiaized, setApplicationFormInitiaized] =
    useState<boolean>();
  const [
    evaluationCompanyAssignmentFormIntialized,
    setEvaluationCompanyAssignmentFormIntialized,
  ] = useState<boolean>();
  const [
    evaluationFieldInspectionFormIntialized,
    setEvaluationFieldInspectionFormIntialized,
  ] = useState<boolean>();

  const [complianceFormInitialized, setComplianceFormInitialized] =
    useState<boolean>(false);
  const [complianceLetterInitialized, setComplianceLetterInitialized] =
    useState<boolean>(false);

  const [allFormsInitialized, setAllFormsInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const formsInitialized =
      applicationFormInitiaized ||
      evaluationCompanyAssignmentFormIntialized ||
      evaluationFieldInspectionFormIntialized ||
      complianceFormInitialized ||
      complianceLetterInitialized;

    if (formsInitialized) {
      setAllFormsInitialized(true);
    } else {
      setAllFormsInitialized(false);
    }
  }, [
    applicationFormInitiaized,
    evaluationCompanyAssignmentFormIntialized,
    evaluationFieldInspectionFormIntialized,
    complianceFormInitialized,
    complianceLetterInitialized,
  ]);

  useEffect(() => {
    if (allFormsInitialized) {
      setTimeout(() => {
        props.onAllFormsInitialized();
      }, 800);
      setIsLoading(false);
      setAllFormsInitialized(false);
      setApplicationFormInitiaized(false);
      setEvaluationCompanyAssignmentFormIntialized(false);
      setEvaluationFieldInspectionFormIntialized(false);
      setComplianceFormInitialized(false);
      setComplianceLetterInitialized(false);
    }
  }, [allFormsInitialized]);

  const { setHasUnsavedChanges } = useContext(UnsavedChangesBlockerContext);

  const getFormAndHeaderContent = () => {
    switch (props.selectedTabId) {
      case CommercialFormNameTypes.ComplianceForm.fieldKey:
        return {
          headerContent: "Commercial Compliance Form",
          form: (
            <CommercialComplianceForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setComplianceFormInitialized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      case CommercialFormNameTypes.ComplianceLetterForm.fieldKey:
        return {
          headerContent: "Commercial Compliance Letter",
          form: (
            <CommercialComplianceLetter
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setComplianceLetterInitialized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      case CommercialFormNameTypes.EvaluatorAssignmentForm.fieldKey:
        return {
          headerContent: "Commercial Evaluation Evaluator Assignment Form",
          form: (
            <CommercialEvaluationCompanyAssignmentForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setEvaluationCompanyAssignmentFormIntialized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      case CommercialFormNameTypes.FieldInspectionForm.fieldKey:
        return {
          headerContent: "Commercial Evaluation Field Inspection Form",
          form: (
            <CommercialEvaluationFieldInspectionForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setEvaluationFieldInspectionFormIntialized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      default:
        return {
          headerContent: "Commercial Application Form",
          form: (
            <CommercialApplicationForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setApplicationFormInitiaized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
    }
  };

  const { headerContent, form } = getFormAndHeaderContent();

  return (
    <>
      {isLoading && (
        <>
          <Loading />
        </>
      )}
      <div
        className={classNames(props.fieldsContainerClassName, {
          "evaluation-forms-container-initialized": !isLoading,
        })}
      >
        <PrimaryCard headerContent={headerContent}>{form}</PrimaryCard>
      </div>
    </>
  );
};

export default CommercialIterationEngineForms;
