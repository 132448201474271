import { Intent } from "@blueprintjs/core";
import classNames from "classnames";
import { Observer } from "mobx-react";
import React, { useEffect, useRef } from "react";
import { SkeletonComponent } from "@syncfusion/ej2-react-notifications";
import { get } from "lodash";
import {
  GridMetricLayout,
  GridMetricOptionValueReadDTO,
} from "@ucl/library/lib/components/Grids/PrimaryGrid/modules/GridMetrics/types";
import baseApiClient from "../../lib/apiClients/baseApiClient";
import FortifiedGridMetricsApiClient from "./FortifiedGridMetricsApiClient";
import { AppToaster } from "../Toast/Toast";
import {
  PrimaryCard,
  ReadOnlyField,
  ReadOnlyFieldProps,
  SecondaryButton,
  SecondaryCard,
} from "@ucl/library";
import { fortifiedGridStore } from "../../stores/FortifiedGridStore";
import { fortifiedGridViewModuleStore } from "../../stores/FortifiedGridViewModuleStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { openGridMetricsPickerDialog } from "@ucl/library/lib/components/Grids/PrimaryGrid/modules/GridMetrics/dialog/GridMetricsPickerDialog";

export interface FortifiedGridMetricsProps<T> {
  gridKey: string;
  url: string;
  languageMapping: T;
  columns: number;
  layout?: GridMetricLayout;
  minimal?: boolean;
  gridViewTitle?: string;
  showMetricViewPicker?: boolean;
  metricConfiguration: string[];
  viewId?: string;
  updateViewMetricSettings: (
    viewId: string,
    metricSettings: string[]
  ) => Promise<void>;
}
export const FortifiedGridMetrics = <T extends object>(
  props: FortifiedGridMetricsProps<T>
) => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [metrics, setMetrics] = React.useState<T>();
  const [queryKey, setQueryKey] = React.useState<string>();

  const gridMetricsApiClient = useRef<FortifiedGridMetricsApiClient>(
    new FortifiedGridMetricsApiClient(baseApiClient)
  );

  const filterSettings = fortifiedGridStore.filterSettings;
  const gridViewTitle = fortifiedGridViewModuleStore.currentGridView?.title;

  useEffect(() => {
    if (!!filterSettings?.rawQuery) {
      const requestKey = filterSettings?.rawQuery;
      if (requestKey !== queryKey) {
        setQueryKey(requestKey);
      }
    }
  }, [filterSettings?.rawQuery]);

  useEffect(() => {
    fetchMetrics();
  }, [queryKey]);

  const fetchMetrics = async () => {
    if (!queryKey) {
      return;
    }

    setLoading(true);
    const newMetrics = await gridMetricsApiClient.current
      .fetchMetrics<T>(
        `${props.url}/${props.gridKey}/${gridViewTitle}/metrics`,
        queryKey
      )
      .catch(() => {
        AppToaster.show({
          message: "An error ocurred loading the metrics data.",
          intent: Intent.DANGER,
        });
      });

    setMetrics(newMetrics || undefined);
    setLoading(false);
  };

  const fetchMetricOptionValues = async () => {
    const metricOptionValues = await gridMetricsApiClient.current
      .fetchMetricOptionValues<GridMetricOptionValueReadDTO>(
        `${props.url}/${props.gridKey}/${props.gridViewTitle}/metric-option-values`
      )
      .catch(() => {
        AppToaster.show({
          message: "An error ocurred loading the metrics options.",
          intent: Intent.DANGER,
        });
      });

    return metricOptionValues;
  };

  const isShowingMetrics =
    !props.showMetricViewPicker ||
    (props.metricConfiguration && props.metricConfiguration.length > 0);

  return (
    <Observer>
      {() => {
        return (
          <section
            className={classNames(
              "grid-metrics",
              {
                "grid-metrics-minimal": props.minimal,
                "grid-metrics-loading": loading,
              },
              isShowingMetrics ? "grid-metrics-expanded" : "grid-metrics-empty"
            )}
          >
            <PrimaryCard className="grid-metrics__card">
              <SecondaryCard
                columns={
                  props.columns ||
                  (metrics && Array.from(Object.entries(metrics))?.length)
                }
              >
                {loading &&
                  Array.from(Array(props.columns)).map((_x, idx) => {
                    return (
                      <div className="bp4-form-group" key={idx}>
                        <div className="bp4-form-content">
                          <SkeletonComponent
                            id="skeletonText"
                            shape="Rectangle"
                          />
                        </div>
                      </div>
                    );
                  })}
                {!loading &&
                  metrics &&
                  !props.showMetricViewPicker &&
                  Array.from(Object.entries(metrics)).map((metric, idx) => {
                    return (
                      <ReadOnlyField
                        key={idx}
                        {...({ value: metric[1] } as ReadOnlyFieldProps)}
                        minimal={true}
                        label={get(props.languageMapping, metric[0])}
                      />
                    );
                  })}
                {!loading &&
                  metrics &&
                  props.showMetricViewPicker &&
                  Array.from(Object.entries(metrics))
                    .filter(([key]) => props.metricConfiguration.includes(key))
                    .map((metric, idx) => {
                      return (
                        <ReadOnlyField
                          key={idx}
                          {...({ value: metric[1] } as ReadOnlyFieldProps)}
                          minimal={true}
                          label={get(props.languageMapping, metric[0])}
                        />
                      );
                    })}
              </SecondaryCard>
              {!loading && props.showMetricViewPicker && (
                <div className="grid-metrics__picker-button-container">
                  <SecondaryButton
                    duration={0}
                    onClick={() => {
                      openGridMetricsPickerDialog(
                        props.viewId || "",
                        props.updateViewMetricSettings,
                        fetchMetricOptionValues,
                        props.metricConfiguration
                      );
                    }}
                  >
                    <FontAwesomeIcon icon={faCog} />
                  </SecondaryButton>
                </div>
              )}
            </PrimaryCard>
          </section>
        );
      }}
    </Observer>
  );
};
