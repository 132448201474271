import baseApiClient from "../../../../../../foritfied/lib/apiClients/baseApiClient";
import { BaseHomeEvaluationRooferConfirmationFormParts } from "../../../../common/types/BaseHomeEvaluationRooferConfirmationFormModel";
import { Evaluation_HomeNCIUAEvaluationFormModel } from "../../types/HomeNCIUAEvaluationFormModel";
import { HomeNCIUAEvaluationRooferConfirmationFormModel } from "../../types/HomeNCIUAEvaluationRooferConfirmationFormModel";
import baseExternalApiClient from "../../../../../../foritfied/lib/apiClients/baseExternalApiClient";

class HomeNCIUAEvaluationRooferConfirmationAPIClient {
  updateHomeNCIUARooferConfirmationForm = async (
    evaluationId: string,
    rooferConfirmationForm: HomeNCIUAEvaluationRooferConfirmationFormModel,
    fieldKey?: string
  ): Promise<Evaluation_HomeNCIUAEvaluationFormModel> => {
    return baseApiClient.put<Evaluation_HomeNCIUAEvaluationFormModel>(
      `/home-nciua/roofer-confirmation-form/${evaluationId}`,
      {
        rooferConfirmationForm,
        fieldKey,
      }
    );
  };

  updateHomeNCIUARooferConfirmationFormExternal = async (
    evaluationId: string,
    rooferConfirmationForm: HomeNCIUAEvaluationRooferConfirmationFormModel,
    accessToken: string
  ): Promise<Evaluation_HomeNCIUAEvaluationFormModel> => {
    return baseExternalApiClient.put<Evaluation_HomeNCIUAEvaluationFormModel>(
      `/home-nciua/roofer-confirmation-form/${evaluationId}`,
      {
        rooferConfirmationForm,
      },
      {
        headers: {
          AccessToken: accessToken,
        },
      }
    );
  };

  submitHomeNCIUARooferConfirmationForm = async (
    evaluationId: string,
    rooferConfirmationForm: HomeNCIUAEvaluationRooferConfirmationFormModel,
    formPart?: BaseHomeEvaluationRooferConfirmationFormParts
  ): Promise<Evaluation_HomeNCIUAEvaluationFormModel> => {
    // If applicationFormPart is not provided, default to the last part of the form
    const formPartValue = (
      formPart ??
      (Object.values(
        BaseHomeEvaluationRooferConfirmationFormParts
      ).pop() as BaseHomeEvaluationRooferConfirmationFormParts)
    ).valueOf();
    return baseApiClient.post<Evaluation_HomeNCIUAEvaluationFormModel>(
      `/home-nciua/roofer-confirmation-form/${evaluationId}/${formPartValue}`,
      rooferConfirmationForm
    );
  };

  submitHomeNCIUARooferConfirmationFormExternal = async (
    evaluationId: string,
    rooferConfirmationForm: HomeNCIUAEvaluationRooferConfirmationFormModel,
    accessToken: string
  ): Promise<Evaluation_HomeNCIUAEvaluationFormModel> => {
    // Always default to the last part of the form
    const formPartValue = (
      Object.values(
        BaseHomeEvaluationRooferConfirmationFormParts
      ).pop() as BaseHomeEvaluationRooferConfirmationFormParts
    ).valueOf();
    return baseExternalApiClient.post<Evaluation_HomeNCIUAEvaluationFormModel>(
      `/home-nciua/roofer-confirmation-form/${evaluationId}/${formPartValue}`,
      rooferConfirmationForm,
      {
        headers: {
          AccessToken: accessToken,
        },
      }
    );
  };
}

export const homeNCIUAEvaluationRooferConfirmationAPIClient =
  new HomeNCIUAEvaluationRooferConfirmationAPIClient();
