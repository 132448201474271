import baseApiClient from "../../../../../../foritfied/lib/apiClients/baseApiClient";
import baseExternalApiClient from "../../../../../../foritfied/lib/apiClients/baseExternalApiClient";
import { BaseHomeRoofingComplianceRoofCoverFormParts } from "../../../../common/types/BaseHomeEvaluationRoofingComplianceRoofCoverFormModel";
import { Evaluation_HomeNCIUAEvaluationFormModel } from "../../types/HomeNCIUAEvaluationFormModel";
import { HomeNCIUAEvaluationRoofingComplianceRoofCoverFormModel } from "../../types/HomeNCIUAEvaluationRoofingComplianceRoofCoverFormModel";

class HomeNCIUAEvaluationRoofingComplianceRoofCoverAPIClient {
  updateHomeNCIUARoofingComplianceRoofCoverForm = async (
    evaluationId: string,
    roofingComplianceRoofCoverForm: HomeNCIUAEvaluationRoofingComplianceRoofCoverFormModel,
    fieldKey?: string
  ): Promise<Evaluation_HomeNCIUAEvaluationFormModel> => {
    return baseApiClient.put<Evaluation_HomeNCIUAEvaluationFormModel>(
      `/home-nciua/roofing-compliance-roof-cover-form/${evaluationId}`,
      {
        roofingComplianceRoofCoverForm,
        fieldKey,
      }
    );
  };

  updateHomeNCIUARoofingComplianceRoofCoverFormExternal = async (
    evaluationId: string,
    roofingComplianceRoofCoverForm: HomeNCIUAEvaluationRoofingComplianceRoofCoverFormModel,
    accessToken: string
  ): Promise<Evaluation_HomeNCIUAEvaluationFormModel> => {
    return baseExternalApiClient.put<Evaluation_HomeNCIUAEvaluationFormModel>(
      `/home-nciua/roofing-compliance-roof-cover-form/external/${evaluationId}`,
      {
        roofingComplianceRoofCoverForm,
      },
      {
        headers: {
          AccessToken: accessToken,
        },
      }
    );
  };

  submitHomeNCIUARoofingComplianceRoofCoverForm = async (
    evaluationId: string,
    form: HomeNCIUAEvaluationRoofingComplianceRoofCoverFormModel,
    formPart?: BaseHomeRoofingComplianceRoofCoverFormParts
  ): Promise<Evaluation_HomeNCIUAEvaluationFormModel> => {
    // If applicationFormPart is not provided, default to the last part of the form
    const formPartValue = (
      formPart ??
      (Object.values(
        BaseHomeRoofingComplianceRoofCoverFormParts
      ).pop() as BaseHomeRoofingComplianceRoofCoverFormParts)
    ).valueOf();
    return baseApiClient.post<Evaluation_HomeNCIUAEvaluationFormModel>(
      `/home-nciua/roofing-compliance-roof-cover-form/${evaluationId}/${formPartValue}`,
      form
    );
  };

  submitHomeNCIUARoofingComplianceRoofCoverFormExternal = async (
    evaluationId: string,
    form: HomeNCIUAEvaluationRoofingComplianceRoofCoverFormModel,
    accessToken: string
  ): Promise<Evaluation_HomeNCIUAEvaluationFormModel> => {
    // Always default to the last part of the form
    const formPartValue = (
      Object.values(
        BaseHomeRoofingComplianceRoofCoverFormParts
      ).pop() as BaseHomeRoofingComplianceRoofCoverFormParts
    ).valueOf();
    return baseExternalApiClient.post<Evaluation_HomeNCIUAEvaluationFormModel>(
      `/home-nciua/roofing-compliance-roof-cover-form/external/${evaluationId}/${formPartValue}`,
      form,
      {
        headers: {
          AccessToken: accessToken,
        },
      }
    );
  };
}

export const homeNCIUAEvaluationRoofingComplianceRoofCoverAPIClient =
  new HomeNCIUAEvaluationRoofingComplianceRoofCoverAPIClient();
