import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import baseExternalApiClient from "../../../../../foritfied/lib/apiClients/baseExternalApiClient";
import { BaseHomeEvaluationEngineeringComplianceChimneyFormParts } from "../../../common/types/BaseHomeEvaluationEngineeringComplianceChimneyFormModel";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../types/HomeEvaluationFormModel";
import { HomeStandardEvaluationEngineeringComplianceChimneyFormModel } from "../../types/HomeStandardEvaluationEngineeringComplianceChimneyFormModel";

class HomeStandardEvaluationEngineeringComplianceChimneyAPIClient {
  updateHomeStandardEngineeringComplianceChimneyForm = async (
    evaluationId: string,
    engineeringComplianceChimneyForm: HomeStandardEvaluationEngineeringComplianceChimneyFormModel,
    fieldKey?: string
  ): Promise<Evaluation_HomeStandardEvaluationFormModel> => {
    return baseApiClient.put<Evaluation_HomeStandardEvaluationFormModel>(
      `/home-standard/engineering-compliance-chimney-form/${evaluationId}`,
      {
        engineeringComplianceChimneyForm,
        fieldKey,
      }
    );
  };

  updateHomeStandardEngineeringComplianceChimneyFormExternal = async (
    evaluationId: string,
    engineeringComplianceChimneyForm: HomeStandardEvaluationEngineeringComplianceChimneyFormModel,
    accessToken: string
  ): Promise<Evaluation_HomeStandardEvaluationFormModel> => {
    return baseExternalApiClient.put<Evaluation_HomeStandardEvaluationFormModel>(
      `/home-standard/engineering-compliance-chimney-form/external/${evaluationId}`,
      {
        engineeringComplianceChimneyForm,
      },
      {
        headers: {
          AccessToken: accessToken,
        },
      }
    );
  };

  submitHomeStandardEngineeringComplianceChimneyForm = async (
    evaluationId: string,
    engineeringComplianceChimneyForm: HomeStandardEvaluationEngineeringComplianceChimneyFormModel,
    formPart?: BaseHomeEvaluationEngineeringComplianceChimneyFormParts
  ): Promise<Evaluation_HomeStandardEvaluationFormModel> => {
    // If applicationFormPart is not provided, default to the last part of the form
    const formPartValue = (
      formPart ??
      (Object.values(
        BaseHomeEvaluationEngineeringComplianceChimneyFormParts
      ).pop() as BaseHomeEvaluationEngineeringComplianceChimneyFormParts)
    ).valueOf();
    return baseApiClient.post<Evaluation_HomeStandardEvaluationFormModel>(
      `/home-standard/engineering-compliance-chimney-form/${evaluationId}/${formPartValue}`,
      engineeringComplianceChimneyForm
    );
  };

  submitHomeStandardEngineeringComplianceChimneyFormExternal = async (
    evaluationId: string,
    engineeringComplianceChimneyForm: HomeStandardEvaluationEngineeringComplianceChimneyFormModel,
    accessToken: string
  ): Promise<Evaluation_HomeStandardEvaluationFormModel> => {
    // Always default to the last part of the form
    const formPartValue = (
      Object.values(
        BaseHomeEvaluationEngineeringComplianceChimneyFormParts
      ).pop() as BaseHomeEvaluationEngineeringComplianceChimneyFormParts
    ).valueOf();
    return baseExternalApiClient.post<Evaluation_HomeStandardEvaluationFormModel>(
      `/home-standard/engineering-compliance-chimney-form/external/${evaluationId}/${formPartValue}`,
      engineeringComplianceChimneyForm,
      {
        headers: {
          AccessToken: accessToken,
        },
      }
    );
  };
}

export const homeStandardEvaluationEngineeringComplianceChimneyAPIClient =
  new HomeStandardEvaluationEngineeringComplianceChimneyAPIClient();
