import {
  FieldSchemaFormBuilderV2,
  SchemaFactoryV2,
  SmartAddressFieldProps,
} from "@ucl/library";
import { addressApiClient } from "../../../../../../foritfied/lib/apiClients/address/addressApiClient";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../../types/HomeEvaluationFormModel";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import {
  ExternalViewTypes,
  WildfireBoxContentExplorer,
  WildfireBoxContentExplorerProps,
} from "../../../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import { get } from "lodash";
import {
  ReadonlyWarningBannerField,
  ReadonlyWarningBannerFieldProps,
} from "../../../../../Common/components/Fields/ReadonlyWarningBanner/ReadonlyWarningBannerField";
import { YesOrNoSelection } from "../../../../../../wildfire/types/FormFields";
import { permissionStore } from "../../../../../../foritfied/stores/PermissionStore";

const modelName = "Evaluation";
const subModelName = "HomeStandardEvaluation";
const formName = "RooferConfirmationForm";

const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

const rooferConfirmationFields = (
  form: Evaluation_HomeStandardEvaluationFormModel,
  isExternal: boolean,
  errors?: { [key: string]: string[] }
) => [
  buildFieldKey("IsCertifiedRoofingContractorAndComplianceAccurate"),
  {
    fieldKey: buildFieldKey(
      "IsCertifiedRoofingContractorAndComplianceAccurateWarningBanner"
    ),
    hidden:
      form.homeStandardEvaluation.rooferConfirmationForm
        .isCertifiedRoofingContractorAndComplianceAccurate !==
      YesOrNoSelection.No,
  } as ReadonlyWarningBannerFieldProps,
  buildFieldKey("UnderstandsFraudulentInformationPenalties"),
  {
    fieldKey: buildFieldKey(
      "UnderstandsFraudulentInformationPenaltiesWarningBanner"
    ),
    hidden:
      form.homeStandardEvaluation.rooferConfirmationForm
        .understandsFraudulentInformationPenalties !== YesOrNoSelection.No,
  } as ReadonlyWarningBannerFieldProps,
  buildFieldKey("PrintedName"),
  buildFieldKey("CompanyName"),
  buildFieldKey("PhoneNumber"),
  {
    fieldKey: buildFieldKey("Address"),
    getAddressesByQuery: addressApiClient.getAddressesByQuery,
    getAddressByQuery: addressApiClient.getAddressByQuery,
    isSmartOnly: false,
    shouldShowAutoSuggest: true,
    shouldSetCountyOnSelect: true,
  } as SmartAddressFieldProps,
  buildFieldKey("LicenseOrRegistrationNumber"),
  buildFieldKey("FortifiedWiseRoofingConactorID"),
  {
    fieldKey: buildFieldKey("SignedRoofingComplianceForms"),
    defaultToUpload: false,
    errorMessages: get(errors, "signedRoofingComplianceForms.FileCount"),
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    externalViewType: isExternal ? ExternalViewTypes.ReadWrite : undefined,
  } as WildfireBoxContentExplorerProps,
];

const rooferConfirmationRequiredFields = [
  buildFieldKey("Address"),
  buildFieldKey("IsCertifiedRoofingContractorAndComplianceAccurate"),
  buildFieldKey("UnderstandsFraudulentInformationPenalties"),
  buildFieldKey("PrintedName"),
  buildFieldKey("CompanyName"),
  buildFieldKey("PhoneNumber"),
  buildFieldKey("SignedRoofingComplianceForms"),
];

// Full form builder with all parts included
export const rooferConfirmationFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        rooferConfirmationFields(form, false, errors),
        onValueChange,
        form,
        errors,
        rooferConfirmationRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Full form builder with all parts included, for external submission
export const externalRooferConfirmationFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        rooferConfirmationFields(form, true, errors),
        onValueChange,
        form,
        errors,
        rooferConfirmationRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Full form builder with all parts included, for Iteration Engine
export const rooferConfirmationFormIterationEngineBuilder = (
  factory: SchemaFactoryV2,
  beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    let fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        rooferConfirmationFields(form, false, errors),
        onValueChange,
        form,
        errors,
        rooferConfirmationRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    if (beforeBuild) {
      fields = beforeBuild(fields);
    }
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};
