import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../../../../types/HomeEvaluationFormModel";
import { Button, NonIdealState } from "@blueprintjs/core";
import { FieldFormV2, FieldSchemaFormBuilderV2 } from "@ucl/library";
import { IconNames } from "@blueprintjs/icons";
import "./styles.scss";
import useSimpleHomeStandardInstallerComplianceAttachedStructureForm from "./useSimpleHomeStandardEvaluationInstallerComplianceAttachedStructureForm";

export interface SimpleHomeStandardInstallerComplianceAttachedStructureFormProps {
  form: Evaluation_HomeStandardEvaluationFormModel;
  accessToken: string;
}

const SimpleHomeStandardInstallerComplianceAttachedStructureForm = (
  props: SimpleHomeStandardInstallerComplianceAttachedStructureFormProps
) => {
  const {
    formRef,
    formModel,
    getFormBuilder,
    noBuilders,
    containerRef,
    isSubmitting,
    onFormFieldChange,
    handleFormSubmit,
  } = useSimpleHomeStandardInstallerComplianceAttachedStructureForm(props);

  return (
    <div ref={containerRef} className="external-form-container">
      {noBuilders ? (
        <Loading />
      ) : !formModel ? (
        <NonIdealState />
      ) : (
        <>
          <FieldFormV2<Evaluation_HomeStandardEvaluationFormModel>
            value={formModel}
            fieldSchemaFormBuilder={
              getFormBuilder() as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>
            }
            onFieldChanged={onFormFieldChange}
            onFormSubmit={handleFormSubmit}
            ref={formRef}
          />
          <div className="external-form-footer">
            <Button
              className="external-form-submit-button"
              text={"Submit Installer Compliance Attached Structure Form"}
              rightIcon={IconNames.ARROW_RIGHT}
              loading={isSubmitting}
              onClick={() => {
                formRef.current?.submit();
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default SimpleHomeStandardInstallerComplianceAttachedStructureForm;
