import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import baseExternalApiClient from "../../../../../foritfied/lib/apiClients/baseExternalApiClient";
import { BaseHomeEvaluationEngineeringComplianceContinuousLoadPathFormParts } from "../../../common/types/BaseHomeEvaluationEngineeringComplianceContinuousLoadPathFormModel";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../types/HomeEvaluationFormModel";
import { HomeStandardEvaluationEngineeringComplianceContinuousLoadPathFormModel } from "../../types/HomeStandardEvaluationEngineeringComplianceContinuousLoadPathFormModel";

class HomeStandardEvaluationEngineeringComplianceContinuousLoadPathAPIClient {
  updateHomeStandardEngineeringComplianceContinuousLoadPathForm = async (
    evaluationId: string,
    engineeringComplianceContinuousLoadPathForm: HomeStandardEvaluationEngineeringComplianceContinuousLoadPathFormModel,
    fieldKey?: string
  ): Promise<Evaluation_HomeStandardEvaluationFormModel> => {
    return baseApiClient.put<Evaluation_HomeStandardEvaluationFormModel>(
      `/home-standard/engineering-compliance-continuous-load-path-form/${evaluationId}`,
      {
        engineeringComplianceContinuousLoadPathForm,
        fieldKey,
      }
    );
  };

  updateHomeStandardEngineeringComplianceContinuousLoadPathFormExternal =
    async (
      evaluationId: string,
      engineeringComplianceContinuousLoadPathForm: HomeStandardEvaluationEngineeringComplianceContinuousLoadPathFormModel,
      accessToken: string
    ): Promise<Evaluation_HomeStandardEvaluationFormModel> => {
      return baseExternalApiClient.put<Evaluation_HomeStandardEvaluationFormModel>(
        `/home-standard/engineering-compliance-continuous-load-path-form/external/${evaluationId}`,
        {
          engineeringComplianceContinuousLoadPathForm,
        },
        {
          headers: {
            AccessToken: accessToken,
          },
        }
      );
    };

  submitHomeStandardEngineeringComplianceContinuousLoadPathForm = async (
    evaluationId: string,
    engineeringComplianceContinuousLoadPathForm: HomeStandardEvaluationEngineeringComplianceContinuousLoadPathFormModel,
    formPart?: BaseHomeEvaluationEngineeringComplianceContinuousLoadPathFormParts
  ): Promise<Evaluation_HomeStandardEvaluationFormModel> => {
    // If applicationFormPart is not provided, default to the last part of the form
    const formPartValue = (
      formPart ??
      (Object.values(
        BaseHomeEvaluationEngineeringComplianceContinuousLoadPathFormParts
      ).pop() as BaseHomeEvaluationEngineeringComplianceContinuousLoadPathFormParts)
    ).valueOf();
    return baseApiClient.post<Evaluation_HomeStandardEvaluationFormModel>(
      `/home-standard/engineering-compliance-continuous-load-path-form/${evaluationId}/${formPartValue}`,
      engineeringComplianceContinuousLoadPathForm
    );
  };

  submitHomeStandardEngineeringComplianceContinuousLoadPathFormExternal =
    async (
      evaluationId: string,
      engineeringComplianceContinuousLoadPathForm: HomeStandardEvaluationEngineeringComplianceContinuousLoadPathFormModel,
      accessToken: string
    ): Promise<Evaluation_HomeStandardEvaluationFormModel> => {
      // Always default to the last part of the form
      const formPartValue = (
        Object.values(
          BaseHomeEvaluationEngineeringComplianceContinuousLoadPathFormParts
        ).pop() as BaseHomeEvaluationEngineeringComplianceContinuousLoadPathFormParts
      ).valueOf();
      return baseExternalApiClient.post<Evaluation_HomeStandardEvaluationFormModel>(
        `/home-standard/engineering-compliance-continuous-load-path-form/external/${evaluationId}/${formPartValue}`,
        engineeringComplianceContinuousLoadPathForm,
        {
          headers: {
            AccessToken: accessToken,
          },
        }
      );
    };
}

export const homeStandardEvaluationEngineeringComplianceContinuousLoadPathAPIClient =
  new HomeStandardEvaluationEngineeringComplianceContinuousLoadPathAPIClient();
