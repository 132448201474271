import {
  EnumCheckboxFieldProps,
  FieldSchemaFormBuilderV2,
  NumericFieldProps,
  SchemaFactoryV2,
  TextFieldProps,
} from "@ucl/library";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../../types/HomeEvaluationFormModel";
import {
  BaseFieldProps,
  EnumMultiSelectFieldV2Value,
} from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { WildfireBoxContentExplorer } from "../../../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import {
  ReadonlyWarningBannerField,
  ReadonlyWarningBannerFieldProps,
} from "../../../../../Common/components/Fields/ReadonlyWarningBanner/ReadonlyWarningBannerField";
import { YesOrNoSelection } from "../../../../../../wildfire/types/FormFields";
import {
  shouldHideInstallerEngineeredChimneysFields,
  shouldHideInstallerPrescriptiveChimneysFields,
} from "../../../../common/components/FieldSchema/sharedConditionalLogic";

const modelName = "Evaluation";
const subModelName = "HomeStandardEvaluation";
const formName = "InstallerComplianceChimneyForm";

const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

// Part 1: Installer Compliance Form General Information
const installerComplianceFormGeneralInformationFields = () => [
  buildFieldKey("PermitNumber"),
  buildFieldKey("DateInstallationWasCompleted"),
];

const installerComplianceFormGeneralInformationRequiredFields = [
  buildFieldKey("PermitNumber"),
  buildFieldKey("DateInstallationWasCompleted"),
];

export const installerComplianceFormChimneyGeneralInformationBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        installerComplianceFormGeneralInformationFields(),
        onValueChange,
        form,
        errors,
        installerComplianceFormGeneralInformationRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Part 2: Installer Compliance Form Engineered Chimney
const installerComplianceFormEngineeredChimneyFields = (
  form: Evaluation_HomeStandardEvaluationFormModel,
  shouldHideSection: (
    elements?: EnumMultiSelectFieldV2Value,
    engineered?: EnumMultiSelectFieldV2Value
  ) => boolean
) =>
  shouldHideSection(
    form.homeStandardEvaluation.eligibilityForm.buildingElementTypes,
    form.homeStandardEvaluation.eligibilityForm
      .buildingElementsEngineerIsProvidingComplianceDesignInfoFor
  )
    ? []
    : [
        buildFieldKey("Attestation292"),
        buildFieldKey("Attestation293"),
        buildFieldKey("Attestation294"),
      ];

const installerComplianceFormEngineeredChimneyRequiredFields = [
  buildFieldKey("Attestation292"),
  buildFieldKey("Attestation293"),
  buildFieldKey("Attestation294"),
];

export const installerComplianceFormEngineeredChimneyBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        installerComplianceFormEngineeredChimneyFields(
          form,
          shouldHideInstallerEngineeredChimneysFields
        ),
        onValueChange,
        form,
        errors,
        installerComplianceFormEngineeredChimneyRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Part 3: Installer Compliance Form Prescriptive Chimney
const installerComplianceFormPrescriptiveChimneyFields = (
  form: Evaluation_HomeStandardEvaluationFormModel,
  shouldHideSection: (
    elements?: EnumMultiSelectFieldV2Value,
    engineered?: EnumMultiSelectFieldV2Value
  ) => boolean
) =>
  shouldHideSection(
    form.homeStandardEvaluation.eligibilityForm.buildingElementTypes,
    form.homeStandardEvaluation.eligibilityForm
      .buildingElementsEngineerIsProvidingComplianceDesignInfoFor
  )
    ? []
    : [
        buildFieldKey("AreChimneysInstalledPerFCTD1"),
        {
          fieldKey: buildFieldKey("AreChimneysInstalledPerFCTD1WarningBanner"),
          hidden: !(
            form.homeStandardEvaluation.installerComplianceChimneyForm
              ?.areChimneysInstalledPerFCTD1 === YesOrNoSelection.No
          ),
        } as ReadonlyWarningBannerFieldProps,
        {
          fieldKey: buildFieldKey("Attestation295"),
          hidden: !(
            form.homeStandardEvaluation.installerComplianceChimneyForm
              ?.areChimneysInstalledPerFCTD1 === YesOrNoSelection.Yes
          ),
        } as EnumCheckboxFieldProps,
        {
          fieldKey: buildFieldKey("Attestation296"),
          hidden: !(
            form.homeStandardEvaluation.installerComplianceChimneyForm
              ?.areChimneysInstalledPerFCTD1 === YesOrNoSelection.Yes
          ),
        } as EnumCheckboxFieldProps,
        {
          fieldKey: buildFieldKey("Attestation297"),
          hidden: !(
            form.homeStandardEvaluation.installerComplianceChimneyForm
              ?.areChimneysInstalledPerFCTD1 === YesOrNoSelection.Yes
          ),
        } as EnumCheckboxFieldProps,
        {
          fieldKey: buildFieldKey("StrapManufacturerAndModel"),
          hidden: !(
            form.homeStandardEvaluation.installerComplianceChimneyForm
              ?.areChimneysInstalledPerFCTD1 === YesOrNoSelection.Yes
          ),
        } as TextFieldProps,
        {
          fieldKey: buildFieldKey(
            "MaxAllowableUpliftLoadPerStrapPerManufacturer"
          ),
          hidden: !(
            form.homeStandardEvaluation.installerComplianceChimneyForm
              ?.areChimneysInstalledPerFCTD1 === YesOrNoSelection.Yes
          ),
        } as NumericFieldProps,
      ];

const installerComplianceFormPrescriptiveChimneyRequiredFields = [
  buildFieldKey("AreChimneysInstalledPerFCTD1"),
  buildFieldKey("Attestation295"),
  buildFieldKey("Attestation296"),
  buildFieldKey("Attestation297"),
  buildFieldKey("StrapManufacturerAndModel"),
  buildFieldKey("MaxAllowableUpliftLoadPerStrapPerManufacturer"),
];

export const installerComplianceFormPrescriptiveChimneyBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        installerComplianceFormPrescriptiveChimneyFields(
          form,
          shouldHideInstallerPrescriptiveChimneysFields
        ),
        onValueChange,
        form,
        errors,
        installerComplianceFormPrescriptiveChimneyRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Full form builder with all parts included
export const homeStandardInstallerComplianceChimneyBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        [
          ...installerComplianceFormGeneralInformationFields(),
          ...installerComplianceFormEngineeredChimneyFields(
            form,
            shouldHideInstallerEngineeredChimneysFields
          ),
          ...installerComplianceFormPrescriptiveChimneyFields(
            form,
            shouldHideInstallerPrescriptiveChimneysFields
          ),
        ],
        onValueChange,
        form,
        errors,
        [
          ...installerComplianceFormGeneralInformationRequiredFields,
          ...installerComplianceFormEngineeredChimneyRequiredFields,
          ...installerComplianceFormPrescriptiveChimneyRequiredFields,
        ],
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Full form builder with all parts included, for Iteration Engine
export const homeStandardIterationEngineInstallerComplianceChimneyFormBuilder =
  (
    factory: SchemaFactoryV2,
    beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
  ): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
    return ((onValueChange, form, errors) => {
      let fields = [
        ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
          modelName,
          [
            ...installerComplianceFormGeneralInformationFields(),
            ...installerComplianceFormEngineeredChimneyFields(
              form,
              shouldHideInstallerEngineeredChimneysFields
            ),
            ...installerComplianceFormPrescriptiveChimneyFields(
              form,
              shouldHideInstallerPrescriptiveChimneysFields
            ),
          ],
          onValueChange,
          form,
          errors,
          [
            ...installerComplianceFormGeneralInformationRequiredFields,
            ...installerComplianceFormEngineeredChimneyRequiredFields,
            ...installerComplianceFormPrescriptiveChimneyRequiredFields,
          ],
          {
            FileUploader: WildfireBoxContentExplorer,
            WarningBanner: ReadonlyWarningBannerField,
          }
        ),
      ];
      if (beforeBuild) {
        fields = beforeBuild(fields);
      }
      return fields;
    }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
  };
