import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import { PaymentCreditResponseReadDTO } from "../../../../Common/types/PaymentCredit/PaymentCreditResponseReadDTO";
import { Evaluation_HomeStandardRedesignation } from "../../types/HomeStandardRedesignation";
import { Evaluation_HomeStandardRedesignationFormModel } from "../../types/HomeStandardRedesignationFormModel_Model";

class HomeStandardRedesignationAPIClient {
  createHomeStandardRedesignation = async (evaluationId: string) => {
    return baseApiClient.post<Evaluation_HomeStandardRedesignation>(
      `home-standard/redesignation/${evaluationId}`
    );
  };

  getHomeStandardRedesignation = async (evaluationId: string) => {
    return await baseApiClient.get<Evaluation_HomeStandardRedesignation>(
      `home-standard/redesignation/${evaluationId}`
    );
  };

  getHomeStandardRedesignationFormModel = async (evaluationId: string) => {
    return await baseApiClient.get<Evaluation_HomeStandardRedesignationFormModel>(
      `home-standard/redesignation/${evaluationId}`
    );
  };

  getHomeStandardRedesignationFormModelWithEligibility = async (
    evaluationId: string
  ) => {
    return await baseApiClient.get<Evaluation_HomeStandardRedesignationFormModel>(
      `home-standard/redesignation/with-eligibility/${evaluationId}`
    );
  };

  getApplicationFeePaymentCreditDetails = async (evaluationId: string) => {
    return await baseApiClient.get<PaymentCreditResponseReadDTO>(
      `home-standard/redesignation/${evaluationId}/payment/credit-details`
    );
  };

  applyApplicationFeePaymentCredit = async (evaluationId: string) => {
    return await baseApiClient.put<Evaluation_HomeStandardRedesignation>(
      `home-standard/redesignation/${evaluationId}/payment/credit`
    );
  };

  bypassPaymentStepForNonProfit = async (evaluationId: string) => {
    return await baseApiClient.post<Evaluation_HomeStandardRedesignation>(
      `home-standard/redesignation/${evaluationId}/payment/nonprofit`
    );
  };

  getApplicationFeeStripeCheckoutLink = async (evaluationId: string) => {
    return await baseApiClient.get<string>(
      `home-standard/redesignation/${evaluationId}/payment/stripe-checkout`
    );
  };

  sendRequestedChangesEmail = async (evaluationId: string) => {
    return await baseApiClient.post<void>(
      `home-standard/redesignation/${evaluationId}/requested-changes-email`
    );
  };

  getRooferConfirmationForm = async (evaluationId: string) => {
    return baseApiClient.downloadGet(
      `home-standard/redesignation/${evaluationId}/generate-roofing-confirmation-form`
    );
  };
}

export const homeStandardRedesignationAPIClient =
  new HomeStandardRedesignationAPIClient();
