import { debounce } from "lodash";
import { action, makeObservable, observable } from "mobx";

export abstract class BaseExternalBoxTokenStore {
  public boxToken?: string;
  public lastUpdatedAt?: number;
  public parentFolderId?: string;
  public refreshingToken = false;
  private tokenRefreshPromise?: Promise<void>;

  constructor() {
    makeObservable(this, {
      boxToken: observable,
      init: action,
      refreshingToken: observable,
    });
  }

  protected abstract getExternalBoxToken(
    parentFolderId: string
  ): Promise<string>;

  getBoxAccessToken = debounce(async (parentFolderId: string) => {
    // If the token is not valid or a refresh is in progress, start a new refresh
    if (!this.isTokenValid(parentFolderId) || this.tokenRefreshPromise) {
      this.tokenRefreshPromise = this.refreshBoxToken(parentFolderId);
      await this.tokenRefreshPromise;
    }
    return this.boxToken;
  }, 200).bind(this);

  public async init(parentFolderId: string): Promise<void> {
    if (!this.isTokenValid(parentFolderId)) {
      await this.getBoxAccessToken(parentFolderId);
    }
  }

  public async refreshBoxToken(parentFolderId: string): Promise<void> {
    if (this.refreshingToken) {
      return this.tokenRefreshPromise;
    }

    this.refreshingToken = true;

    this.tokenRefreshPromise = new Promise(async (resolve, reject) => {
      try {
        this.boxToken = await this.getExternalBoxToken(parentFolderId);
        this.lastUpdatedAt = new Date().getTime();
        this.parentFolderId = parentFolderId;
        resolve();
      } catch (error) {
        console.error("Box token refresh error:", error);
        reject(error);
      } finally {
        this.refreshingToken = false;
      }
    });

    return this.tokenRefreshPromise;
  }

  public isTokenValid(parentFolderId: string): boolean {
    return (
      !!this.parentFolderId &&
      this.parentFolderId === parentFolderId &&
      !!this.boxToken &&
      !!this.lastUpdatedAt &&
      this.lastUpdatedAt >= new Date().getTime() - 1800000
    );
  }
}
