import { useState, useEffect, useRef } from "react";
import { ChangeRequestStatus } from "../../../foritfied/types/evaluation/ChangeRequest";
import { wildfireUserStore } from "../../../wildfire/stores/WildfireUserStore";
import { first, isNil, orderBy } from "lodash";
import { ChangeRequestV2 } from "../../../wildfire/components/IterationEngine/types/ChangeRequestV2";
import { FotifiedCommentContainerProps } from "../components/IterationEngine/FortifiedIterationEngineCommentPanel";

export const getThirdLevelNesting = (
  inputString: string
): string | undefined => {
  const separator = "__";
  const parts = inputString.split(separator);

  // Check if the array has at least three elements (for third level)
  if (parts.length >= 3) {
    return parts[2]; // Return the third element (index 2)
  }
};

const activeClassName = "change-request-navigator-element-active";

const useFortifiedChangeRequestNavigator = (
  props: Pick<
    FotifiedCommentContainerProps,
    | "selectedKey"
    | "selectedField"
    | "setSelectedIterationEngineFormTabId"
    | "selectedIterationEngineFormTabId"
    | "setSelectedKeyAndGetComments"
    | "isOpen"
    | "comments"
  >,
  allChangeRequests: ChangeRequestV2[],
  fieldsContainerClassName: string
) => {
  const [currentChangeRequestKey, setCurrentChangeRequestKey] = useState<
    string | undefined | null
  >(null);
  const [isCurrentKeyOnPage, setIsCurrentKeyOnPage] = useState<boolean>();

  const [currentChangeRequest, setCurrentChangeRequest] =
    useState<ChangeRequestV2>();

  const [navigationChangeRequests, setNavigationChangeRequests] = useState<
    ChangeRequestV2[]
  >([]);

  const lastElementChangeRequestIndex = useRef<number>();

  const navigationChangeRequestsOnPageKeys = useRef<string[]>([]);
  const changeRequestElementRef = useRef<Element>();

  useEffect(() => {
    setCurrentChangeRequestKey(undefined);
  }, [props.selectedIterationEngineFormTabId]);

  useEffect(() => {
    // If we loaded the requests, and there are requests on the page, navigate to the one assigned. default : 0
    if (
      navigationChangeRequests.length > 0 &&
      navigationChangeRequestsOnPageKeys?.current?.length > 0
    ) {
      let reqKey = currentChangeRequestKey;
      if (isNil(reqKey)) {
        const unresolved = getUnresolved();
        if (!unresolved || unresolved.length === 0) {
          const firstRequest = navigationChangeRequests.at(0)!;
          reqKey = firstRequest.evaluationFieldKey;
        } else {
          const firstRequest = unresolved.at(0)!;
          reqKey = firstRequest.evaluationFieldKey;
        }
      }
      navigateToChangeRequest(reqKey, false);
    }
  }, [navigationChangeRequests]);

  useEffect(() => {
    if (isNil(currentChangeRequestKey)) {
      unAssignChangeRequest();
      return;
    }
  }, [currentChangeRequestKey]);

  useEffect(() => {
    autoNavigateToChangeRequestOnInit();
  }, []);

  const initNavigationChangeRequestsTimeout = useRef<number>(5);

  useEffect(() => {
    if (props.isOpen) {
      setTimeout(() => {
        initNavigationChangeRequests().then((requests) => {
          if (requests) {
          } else {
            const interval = setInterval(() => {
              initNavigationChangeRequests().then((requests) => {
                if (requests) {
                  clearInterval(interval);
                  initNavigationChangeRequestsTimeout.current = 5;
                } else {
                  if (initNavigationChangeRequestsTimeout.current < 0) {
                    clearInterval(interval);
                    initNavigationChangeRequestsTimeout.current = 5;
                  } else {
                    initNavigationChangeRequestsTimeout.current--;
                  }
                }
              });
            }, 800);
          }
        });
      }, 800);
    }
  }, [props.isOpen, allChangeRequests]);

  useEffect(() => {
    if (!props.selectedField) {
      return;
    }
    if (props.selectedField?.fieldKey) {
      navigateToChangeRequest(props.selectedField?.fieldKey, false);
    } else {
      setCurrentChangeRequestKey(undefined);
    }
  }, [props.selectedField]);

  useEffect(() => {
    if (!props.selectedKey || props.selectedKey === currentChangeRequestKey) {
      return;
    }
    if (props.selectedKey) {
      navigateToChangeRequest(props.selectedKey, false);
    } else {
      setCurrentChangeRequestKey(undefined);
    }
  }, [props.selectedKey]);

  const assignChangeRequest = async (key?: string) => {
    if (!key) {
      return undefined;
    }

    const request = navigationChangeRequests.find(
      (cr) => cr.evaluationFieldKey === key
    );

    if (!request) {
      return undefined;
    }

    setCurrentChangeRequest(request);

    if (request) {
      const element = getElementsByFieldKeys([request.evaluationFieldKey]);

      if (element.length === 1) {
        setIsCurrentKeyOnPage(true);
      } else {
        setIsCurrentKeyOnPage(false);
      }
    }

    return request;
  };

  const unAssignChangeRequest = async () => {
    setCurrentChangeRequest(undefined);
    setIsCurrentKeyOnPage(false);
    props.setSelectedKeyAndGetComments?.(undefined);
  };

  const initNavigationChangeRequests = async () => {
    // TODO
    // We don't know which field is the first or the last one when we enter the page
    // We most likley will need to send the position from where we want to navigate from along with the key in cases
    // we want to go to gist or last element
    const sortedBykeyChangeRequests = orderBy(allChangeRequests, [
      "evaluationFieldKey",
    ]);

    const requestElements = sortedBykeyChangeRequests.map((cr, idx) => {
      const elementGroup = {
        key: cr.evaluationFieldKey,
        request: cr,
        element: getElementsByFieldKeys([cr.evaluationFieldKey]),
        crIdx: idx,
        index: undefined as number | undefined,
      };
      return elementGroup;
    });

    // If there are no elements on the page, return
    if (
      requestElements.map((req) => req.element).filter((x) => x).length === 0
    ) {
      return;
    }

    const allFieldElements = document.querySelectorAll(
      `.${fieldsContainerClassName} .ucl-field-label-clickable`
    );

    // If there are no elements on the page, return
    if (allFieldElements.length === 0) {
      return;
    }

    // Order the change requests by the order of the elements on the page
    requestElements.forEach((req) => {
      const element = first(req.element);
      if (element) {
        const index = Array.from(allFieldElements).indexOf(element);
        req.index = index;
      }
    });

    // Sort change requests by elements on the page
    const changeRequestsOnPage = requestElements
      .filter((req) => req.index !== undefined)
      .sort((a, b) => (a.index || 0) - (b.index || 0));

    const changeRequestsNotOnPage = requestElements.filter(
      (req) => req.index === undefined
    );

    const onPageKeys = changeRequestsOnPage.map(
      (cr) => cr.request.evaluationFieldKey
    );

    navigationChangeRequestsOnPageKeys.current = onPageKeys;

    let sortedChangeRequestsEls = [
      ...changeRequestsOnPage,
      ...changeRequestsNotOnPage,
    ];
    sortedChangeRequestsEls = orderBy(
      sortedChangeRequestsEls,
      ["crIdx"],
      ["asc"]
    );
    const sortedChangeRequests = sortedChangeRequestsEls.map(
      (req) => req.request
    );

    if (
      currentChangeRequestKey &&
      onPageKeys &&
      !onPageKeys.includes(currentChangeRequestKey)
    ) {
      setCurrentChangeRequestKey(first(onPageKeys));
    } else if (!currentChangeRequestKey && onPageKeys) {
      setCurrentChangeRequestKey(first(onPageKeys));
    } else if (
      currentChangeRequestKey &&
      onPageKeys.includes(currentChangeRequestKey)
    ) {
      setCurrentChangeRequestKey(currentChangeRequestKey);
    }

    setNavigationChangeRequests(sortedChangeRequests);

    return sortedChangeRequests;
  };

  const getUnresolved = () => {
    return navigationChangeRequests?.filter(
      (cr) => cr.status !== ChangeRequestStatus.Resolved
    );
  };

  const autoNavigateToChangeRequestOnInit = async () => {
    props.setSelectedIterationEngineFormTabId(
      props.selectedIterationEngineFormTabId
    );
  };

  const getElementsByFieldKeys = (fieldKeys: string[]): Element[] => {
    if (!fieldKeys || fieldKeys.length === 0) {
      return [];
    }

    const elements: Element[] = [];

    fieldKeys.forEach((key) => {
      const elementCollection = document.getElementsByClassName(key);

      if (elementCollection.length === 1) {
        const el = first(elementCollection);
        if (el) {
          elements.push(el);
        }
      }
    });
    return elements || [];
  };

  const hasAssociatedChangeRequests = navigationChangeRequests.some(
    (cr) =>
      cr.evaluationFieldKey ===
      (currentChangeRequest?.evaluationFieldKey ||
        props.selectedField?.fieldKey)
  );

  const hasNoNewChangeRequests = navigationChangeRequests.every(
    (cr) => cr.status !== ChangeRequestStatus.New
  );

  const applicantChangeRequestStatusLabel =
    wildfireUserStore.isWildfireAdmin &&
    currentChangeRequest?.status_AsString === "Addressed"
      ? "Unresolved"
      : currentChangeRequest?.status_AsString || "New";

  const navigateToPreviousChangeRequest = async () => {
    if (isNil(currentChangeRequestKey)) {
      return;
    }

    if (
      navigationChangeRequestsOnPageKeys.current.includes(
        currentChangeRequestKey
      )
    ) {
      const currentChangeRequestIndex =
        navigationChangeRequestsOnPageKeys.current.findIndex(
          (cr) => cr === currentChangeRequestKey
        );

      const newIndex = currentChangeRequestIndex - 1;

      if (newIndex >= 0) {
        const nextKey = navigationChangeRequestsOnPageKeys.current.at(newIndex);

        const nextRequest = navigationChangeRequests.find(
          (x) => x.evaluationFieldKey == nextKey
        );

        if (nextRequest) {
          await navigateToChangeRequest(nextRequest.evaluationFieldKey, true);
          return;
        }
      }
    }

    // Get the request key after the current one given athe current key
    const currentChangeRequestIndex = navigationChangeRequests.findIndex(
      (cr) => cr.evaluationFieldKey === currentChangeRequestKey
    );

    const newIndex = Math.max(currentChangeRequestIndex - 1, 0);

    // Remove all index greater than newindex
    const allGreater = navigationChangeRequests.filter((_, i) => i < newIndex);

    const navExcl = allGreater.filter(
      (x) =>
        !navigationChangeRequestsOnPageKeys.current.includes(
          x.evaluationFieldKey
        )
    );

    const prevRequest = navExcl.at(navExcl.length - 1);

    // Get Key
    if (prevRequest) {
      await navigateToChangeRequest(prevRequest.evaluationFieldKey, true);
    }
  };

  const navigateToNextChangeRequest = async () => {
    if (isNil(currentChangeRequestKey)) {
      return;
    }

    if (
      navigationChangeRequestsOnPageKeys.current.includes(
        currentChangeRequestKey
      )
    ) {
      const currentChangeRequestIndex =
        navigationChangeRequestsOnPageKeys.current.findIndex(
          (cr) => cr === currentChangeRequestKey
        );

      const newIndex = currentChangeRequestIndex + 1;

      if (newIndex < navigationChangeRequestsOnPageKeys.current.length) {
        const nextKey = navigationChangeRequestsOnPageKeys.current.at(newIndex);

        const nextRequest = navigationChangeRequests.find(
          (x) => x.evaluationFieldKey == nextKey
        );

        if (nextRequest) {
          await navigateToChangeRequest(nextRequest.evaluationFieldKey, true);
          return;
        }
      }
    }

    // Get the request key after the current one given athe current key
    const currentChangeRequestIndex = navigationChangeRequests.findIndex(
      (cr) => cr.evaluationFieldKey === currentChangeRequestKey
    );

    const newIndex = Math.min(
      currentChangeRequestIndex + 1,
      navigationChangeRequests.length - 1
    );

    // Remove all index less than newindex
    const lessThan = navigationChangeRequests.filter((_, i) => i > newIndex);

    const navExcl = lessThan.filter(
      (x) =>
        !navigationChangeRequestsOnPageKeys.current.includes(
          x.evaluationFieldKey
        )
    );

    const nextRequest = navExcl.at(0);

    if (nextRequest) {
      await navigateToChangeRequest(nextRequest.evaluationFieldKey, true);
    }
  };

  const navigateToFirstChangeRequest = async () => {
    const firstRequest = navigationChangeRequests.at(0);
    if (firstRequest) {
      await navigateToChangeRequest(firstRequest.evaluationFieldKey, true);
    }
  };

  const navigateToChangeRequest = async (
    key: string,
    autoChangeFormTab: boolean
  ) => {
    if (!key) {
      return;
    }

    let formFieldKey: string | undefined = key;
    if (autoChangeFormTab) {
      formFieldKey = setSelectedTabIdAndGetNewFormFieldKey(key);
    }

    unAssignChangeRequest();

    await fetchFieldCommentsByChangeRequestIndexAndScrollToChangeRequest(
      formFieldKey
    );

    setCurrentChangeRequestKey(formFieldKey);
    assignChangeRequest(formFieldKey);
  };

  const setSelectedTabIdAndGetNewFormFieldKey = (
    key: string
  ): string | undefined => {
    const fieldKey = getThirdLevelNesting(key);

    if (!fieldKey) {
      setCurrentChangeRequestKey(undefined);
      return undefined;
    } else {
      props.setSelectedIterationEngineFormTabId(fieldKey);
      props.setSelectedKeyAndGetComments?.(key);
    }

    return key;
  };

  const fetchFieldCommentsByChangeRequestIndexAndScrollToChangeRequest = async (
    formFieldKey: string | undefined
  ) => {
    if (!formFieldKey) {
      props.setSelectedKeyAndGetComments?.(undefined);
      return;
    }

    const changeRequest = navigationChangeRequests.find(
      (cr) => cr.evaluationFieldKey === formFieldKey
    );

    if (changeRequest) {
      const fieldKey = changeRequest.evaluationFieldKey;

      if (
        currentChangeRequest?.id === changeRequest.id ||
        props.selectedField?.fieldKey === fieldKey
      ) {
        if (goToChangeRequest(fieldKey, false)) {
          if (
            props.comments &&
            props.comments?.length > 0 &&
            props.comments.at(0)?.evaluationFieldKey !== fieldKey
          ) {
            props.setSelectedKeyAndGetComments?.(fieldKey);
          }
        } else {
          // No element. Fallback.
          navigationFallback();
        }
      } else {
        if (goToChangeRequest(fieldKey, false)) {
          props.setSelectedKeyAndGetComments?.(fieldKey);
        } else {
          // No element. Fallback.
          navigationFallback();
        }
      }
    } else if (formFieldKey) {
      //goToChangeRequest(formFieldKey, false);
      if (!goToChangeRequest(formFieldKey, false)) {
        //No element. Fallback.
        navigationFallback();
      }
    }
  };

  const navigationFallback = () => {
    return;

    // Leave commented for future fix.
    // if (!isNil(lastElementChangeRequestIndex.current)) {
    //   const idx = navigationChangeRequests.findIndex(
    //     (cr) => cr.evaluationFieldKey === formFieldKey
    //   );

    //   if (idx === lastElementChangeRequestIndex.current) {
    //     return;
    //   }

    //   if (idx === 0) {
    //     navigateToNextChangeRequest();
    //   } else if (idx === navigationChangeRequests.length - 1) {
    //     navigateToPreviousChangeRequest();
    //   } else if (lastElementChangeRequestIndex.current < idx) {
    //     navigateToNextChangeRequest();
    //   } else {
    //     navigateToPreviousChangeRequest();
    //   }
    // }
  };

  const goToChangeRequest = (fieldKey: string, instant?: boolean): boolean => {
    if (fieldKey) {
      const elements = getElementsByFieldKeys([fieldKey]);
      if (elements.length !== 1) {
        return false;
      }

      const el = first(elements);
      if (el) {
        const parentEl = el.closest(".field-form-field");
        if (parentEl) {
          scrollToElement(parentEl, instant);
        } else {
          scrollToElement(el, instant);
        }
      }

      lastElementChangeRequestIndex.current =
        navigationChangeRequests.findIndex(
          (cr) => cr.evaluationFieldKey === fieldKey
        );

      return true;
    } else {
      return false;
    }
  };

  const clearPrevElement = () => {
    if (changeRequestElementRef.current) {
      changeRequestElementRef.current.classList.remove(activeClassName);
    }
  };

  const scrollToElement = (element?: Element, instant?: boolean): boolean => {
    if (!element) {
      return false;
    }

    // Scroll
    element.scrollIntoView({
      behavior: instant ? "auto" : "smooth",
      block: "start",
      inline: "start",
    });

    // Clear Prev
    clearPrevElement();

    setTimeout(() => {
      // Re-Scroll to ensure the element is in view
      element.scrollIntoView({
        behavior: instant ? "auto" : "smooth",
        block: "start",
        inline: "start",
      });

      // Assign
      element.classList.add(activeClassName);
      changeRequestElementRef.current = element;

      // Re-Scroll to ensure the element is in view
      setTimeout(() => {
        element.scrollIntoView({
          behavior: instant ? "auto" : "smooth",
          block: "start",
          inline: "start",
        });
      }, 300);
    }, 600);

    return true;
  };

  const moveToNextChangeRequest = () => {
    if (isNil(currentChangeRequestKey)) {
      return;
    }

    const currentIndex = navigationChangeRequests.findIndex(
      (cr) => cr.evaluationFieldKey === currentChangeRequestKey
    );
    let nextIndex = currentIndex + 1;
    let foundNewChangeRequest = false;

    // Loop through all change requests starting from the next index
    for (let i = 0; i < navigationChangeRequests.length; i++) {
      const indexToCheck = (nextIndex + i) % navigationChangeRequests.length; // Wrap around if we reach the end
      const changeRequest = navigationChangeRequests[indexToCheck];

      // Check if the change request is not Resolved
      if (changeRequest.status !== ChangeRequestStatus.Resolved) {
        nextIndex = indexToCheck;
        foundNewChangeRequest = true;
        break; // Exit the loop once a new change request is found
      }
    }

    if (foundNewChangeRequest) {
      const req = navigationChangeRequests.at(nextIndex);
      if (req) {
        navigateToChangeRequest(req.evaluationFieldKey, true);
      }
    }
  };

  return {
    moveToNextChangeRequest,
    hasAssociatedChangeRequests,
    hasNoNewChangeRequests,
    navigateToPreviousChangeRequest,
    currentChangeRequestIndex: navigationChangeRequests.findIndex(
      (cr) => cr.evaluationFieldKey === currentChangeRequestKey
    ),
    currentChangeRequestKey,
    navigateToNextChangeRequest,
    applicantChangeRequestStatusLabel,
    currentChangeRequest,
    fieldsOnPageCount: navigationChangeRequestsOnPageKeys.current?.length,
    isCurrentKeyOnPage,
    navigateToFirstChangeRequest,
    navigateToChangeRequest,
  };
};

export default useFortifiedChangeRequestNavigator;
