import { useEffect, useState } from "react";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import { EvaluationStep } from "../../../../../../foritfied/types/evaluation/Evaluation";
import HomeStandardIterationEngineForms from "./HomeStandardIterationEngineForms";

interface HomeStandardIterationEngineFormsProps {
  selectedTabId: string;
  evaluationId: string;
  evaluationStep: EvaluationStep;
  getChangeRequests: () => Promise<void>;
  onEvaluationFormInitialized?: () => void;
  fieldsContainerClassName: string;
}

const HomeStandardIterationEngineFormsContainer: React.FC<
  HomeStandardIterationEngineFormsProps
> = (props) => {
  const [ready, setReady] = useState(false);

  const onAllFormsInitialized = () => {
    props.onEvaluationFormInitialized?.();
    setTimeout(() => {
      setReady(true);
    }, 800);
  };

  useEffect(() => {
    setReady(false);
  }, [props.selectedTabId]);

  return (
    <>
      {!ready && <Loading />}

      <div style={{ visibility: ready ? "visible" : "hidden" }}>
        <HomeStandardIterationEngineForms
          selectedTabId={props.selectedTabId}
          fieldsContainerClassName={props.fieldsContainerClassName}
          evaluationId={props.evaluationId}
          evaluationStep={props.evaluationStep}
          onAllFormsInitialized={onAllFormsInitialized}
          onFormSave={props.getChangeRequests}
        />
      </div>
    </>
  );
};

export default HomeStandardIterationEngineFormsContainer;
