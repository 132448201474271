import {
  EnumSingleSelectFieldValue,
  TextFieldValue,
} from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { BaseHomeEvaluationBasicInformationFormModel } from "../../../common/types/BaseHomeEvaluationBasicInformationFormModel";
import { FormChecklistFields } from "../../../standard/types/HomeStandardEvaluationBasicInformationFormModel";

export interface HomeNCIUAEvaluationBasicInformationFormModel
  extends BaseHomeEvaluationBasicInformationFormModel,
    HomeNCIUAEvaluationBasicInformationFormBase {
  specialProjectType: EnumSingleSelectFieldValue;
  claimPrefix: EnumSingleSelectFieldValue;
  claimNumber: TextFieldValue;
}

export interface HomeNCIUAEvaluationBasicInformationFormBase
  extends FormChecklistFields {
  id: string;
  homeNCIUAEvaluationId: string;
  evaluationId: string;

  homeownerInformationRequiredFieldCount: number;

  homeownerInformationHasBeenSubmitted: boolean;

  isHomeownerInformationComplete: boolean;
  areAllRequiredFieldsComplete: boolean;
}

export enum HomeNCIUASpecialProjectTypes {
  NCIUA_NCJUA_SYR = 1,
  NCIUA_NCJUA_SYCR = 2,
  NCIUA_NCJUA_Claim = 3,
  NCIUA_NCJUA_Other = 4,
}
