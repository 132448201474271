import { Button, Intent } from "@blueprintjs/core";
import { FC, useState } from "react";
import { PaymentCreditResponseReadDTO } from "../../types/PaymentCredit/PaymentCreditResponseReadDTO";
import { EvaluationStep } from "../../../../foritfied/types/evaluation/Evaluation";
import { openPaymentCreditDialogV2 } from "../Dialogs/PaymentCredit/PaymentCreditDialogV2";
import { useNavigate } from "react-router";
import { getFortifiedPaymentPageRoute } from "../../../../common/utils/paymentRouteUtils";
import { ProductSubtypes } from "../../types/Evaluation/BaseEvaluation";

export interface ProceedToPaymentButtonV2Props {
  evaluationId: string;
  fortifiedId: string;
  productSubtype: ProductSubtypes;
  evaluationStep: EvaluationStep;
  text?: string;
  getPaymentDetails: () => Promise<PaymentCreditResponseReadDTO>;
  setEvaluation: (evaluation: any) => void;
}

export const ProceedToPaymentActionButtonV2: FC<
  ProceedToPaymentButtonV2Props
> = (props) => {
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);

  const handlePaymentButtonClick = async () => {
    setIsSaving(true);

    const paymentDetails = await props.getPaymentDetails();

    if (paymentDetails.hasSufficientPaymentCredits) {
      openPaymentCreditDialogV2(
        paymentDetails,
        props.fortifiedId,
        props.productSubtype,
        props.evaluationStep,
        props.setEvaluation,
        props.evaluationId
      );
    } else {
      const route = getFortifiedPaymentPageRoute(
        props.productSubtype,
        props.evaluationId,
        props.evaluationStep
      );
      if (route) {
        navigate(route);
      }
    }

    setIsSaving(false);
  };

  return (
    <Button
      intent={Intent.PRIMARY}
      text={props.text ?? "Proceed to Payment"}
      loading={isSaving}
      onClick={handlePaymentButtonClick}
    />
  );
};
