import { PrimaryCard } from "@ucl/library";
import { useContext, useEffect, useState } from "react";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import classNames from "classnames";
import { EvaluationStep } from "../../../../../../foritfied/types/evaluation/Evaluation";
import HomeStandardRedesignationForm from "../RedesignationForm/Form/HomeStandardRedesignationForm";
import { UnsavedChangesBlockerContext } from "../../../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import HomeStandardRedesignationHomeownerAuthorizationForm from "../HomeownerAuthorizationForm/Form/HomeStandardRedesignationHomeownerAuthorizationForm";
import HomeStandardRedesignationRoofingComplianceGeneralInformationForm from "../RoofingComplianceGeneralInformationForm/Form/HomeStandardRedesignationRoofingComplianceGeneralInformationForm";
import HomeStandardRedesignationRoofingComplianceRoofCoverForm from "../RoofingComplianceRoofCoverForm/Form/HomeStandardRedesignationRoofingComplianceRoofCoverForm";
import HomeStandardRedesignationRooferConfirmationForm from "../RooferConfirmationForm/Form/HomeStandardRedesignationRooferConfirmationForm";

interface HomeStandardRedesignationIterationEngineFormsProps {
  selectedTabId: string;
  evaluationId: string;
  evaluationStep: EvaluationStep;
  onFormSave: (fieldKey: string) => Promise<void>;
  onAllFormsInitialized: () => void;
  fieldsContainerClassName: string;
}

const HomeStandardRedesignationIterationEngineForms: React.FC<
  HomeStandardRedesignationIterationEngineFormsProps
> = (props) => {
  const [redesignationForm, setRedesignationForm] = useState<boolean>(false);

  const [
    homeownerAuthorizationFormInitiaized,
    setHomeownerAuthorizationFormInitiaized,
  ] = useState<boolean>();

  const [
    roofingComplianceGeneralInformationFormInitialized,
    setRoofingComplianceGeneralInformationFormInitialized,
  ] = useState<boolean>();

  const [
    roofingComplianceRoofCoverFormInitialized,
    setRoofingComplianceRoofCoverFormInitialized,
  ] = useState<boolean>();

  const [
    rooferConfirmationFormInitialized,
    setRooferConfirmationFormInitialized,
  ] = useState<boolean>();

  const [allFormsInitialized, setAllFormsInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const formsInitialized =
      redesignationForm ||
      homeownerAuthorizationFormInitiaized ||
      roofingComplianceGeneralInformationFormInitialized ||
      roofingComplianceRoofCoverFormInitialized ||
      rooferConfirmationFormInitialized;

    if (formsInitialized) {
      setAllFormsInitialized(true);
    } else {
      setAllFormsInitialized(false);
    }
  }, [
    redesignationForm,
    homeownerAuthorizationFormInitiaized,
    roofingComplianceGeneralInformationFormInitialized,
    roofingComplianceRoofCoverFormInitialized,
    rooferConfirmationFormInitialized,
  ]);

  useEffect(() => {
    if (allFormsInitialized) {
      setTimeout(() => {
        props.onAllFormsInitialized();
      }, 800);
      setIsLoading(false);
      setAllFormsInitialized(false);
      setRedesignationForm(false);
      setHomeownerAuthorizationFormInitiaized(false);
      setRoofingComplianceGeneralInformationFormInitialized(false);
      setRoofingComplianceRoofCoverFormInitialized(false);
      setRooferConfirmationFormInitialized(false);
    }
  }, [allFormsInitialized]);

  const { setHasUnsavedChanges } = useContext(UnsavedChangesBlockerContext);

  const getFormAndHeaderContent = () => {
    switch (props.selectedTabId) {
      case "HomeownerAuthorizationForm":
        return {
          headerContent: "Homeowner Authorization Form",
          form: (
            <HomeStandardRedesignationHomeownerAuthorizationForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setHomeownerAuthorizationFormInitiaized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      case "RoofingComplianceGeneralInformationForm":
        return {
          headerContent: "Roofing Compliance: General Information Form",
          form: (
            <HomeStandardRedesignationRoofingComplianceGeneralInformationForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setRoofingComplianceGeneralInformationFormInitialized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      case "RoofingComplianceRoofCoverForm":
        return {
          headerContent: "Roofing Compliance: Roof Cover Form",
          form: (
            <HomeStandardRedesignationRoofingComplianceRoofCoverForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setRoofingComplianceRoofCoverFormInitialized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      case "RooferConfirmationForm":
        return {
          headerContent: "Roofer Confirmation Form",
          form: (
            <HomeStandardRedesignationRooferConfirmationForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setRooferConfirmationFormInitialized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      default:
        return {
          headerContent: "Redesignation Form",
          form: (
            <HomeStandardRedesignationForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setRedesignationForm(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
    }
  };

  const { headerContent, form } = getFormAndHeaderContent();

  return (
    <>
      {isLoading && (
        <>
          <Loading />
        </>
      )}
      <div
        className={classNames(props.fieldsContainerClassName, {
          "evaluation-forms-container-initialized": !isLoading,
        })}
      >
        <PrimaryCard headerContent={headerContent}>{form}</PrimaryCard>
      </div>
    </>
  );
};

export default HomeStandardRedesignationIterationEngineForms;
