import { useEffect, useState } from "react";
import FortifiedIterationEngineChecklistAndTabs from "../../../../Common/components/IterationEngine/ChecklistAndTabs/FortifiedIterationEngineChecklistAndTabs";
import { EvaluationStep } from "../../../../../foritfied/types/evaluation/Evaluation";
import {
  FieldSchemaFactoryProvider,
  HomeSchemaFactoryV2,
} from "../../../customHooks/useHomeFieldSchemaFactory";
import FortifiedIterationEngineCommentPanel from "../../../../Common/components/IterationEngine/FortifiedIterationEngineCommentPanel";
import { useHomeStandardRedesignationIterationEngine } from "./useHomeStandardRedesignationIterationEngine";
import HomeStandardRedesignationIterationEngineHeader from "./HomeStandardRedesignationIterationEngineHeader";
import HomeStandardRedesignationIterationEngineFormsContainer from "../Forms/EvaluationForm/HomeStandardRedesignationIterationEngineFormsContainer";
import { fortifiedUserStore } from "../../../../../foritfied/stores/FortifiedUserStore";
import EvaluationExpiration from "../../../../Common/components/IterationEngine/RedesignationExpiration/RedesignationExpiration";
import EvaluationHistory from "../../../../../foritfied/components/Evaluation/History/EvaluationHistory";
import { ProductSubtypes } from "../../../../Common/types/Evaluation/BaseEvaluation";

export interface HomeStandardRedesignationIterationEngineProps {
  evaluationId: string;
  homeSchemaFactory: HomeSchemaFactoryV2;
}

const HomeStandardRedesignationIterationEngine: React.FC<
  HomeStandardRedesignationIterationEngineProps
> = (props) => {
  const {
    //Evaluation
    homeStandardRedesignation,
    refreshHomeStandardEvaluation,
    setHomeStandardRedesignation,
    //Comments
    comments,
    createComment,
    deleteComment,
    selectedKey,
    selectedField,
    setSelectedFieldAndGetComments,
    setSelectedKeyAndGetComments,
    //ChangeRequests
    allChangeRequests,
    resolveChangeRequest,
    unresolveChangeRequest,
    unresolvedChangeRequests,
    getChangeRequests,
    changeRequestCount,
    resolvedChangeRequestCount,
    getEvaluationFieldKeysForAllUnresolvedChangeRequests,
    resolvedChangeRequestsPercentage,
    resolvedAllChangeRequests,
    hasNoUnaddressedChangeRequests,
    addressedAtLeastOneChangeRequest,
    longestOutstandingChangeRequest,
    //Iteration Engine
    setSelectedIterationEngineFormTabId,
    selectedIterationEngineFormTabId,
    canEditForm,
    formTabs,
  } = useHomeStandardRedesignationIterationEngine(
    props.evaluationId,
    props.homeSchemaFactory.schema
  );

  const [evaluationFormInitialized, setEvaluationFormInitialized] =
    useState(false);

  useEffect(() => {
    setEvaluationFormInitialized(false);
  }, [props.evaluationId, selectedIterationEngineFormTabId]);

  return (
    <section className="wildfire-admin-iteration-engine">
      {homeStandardRedesignation && (
        <FieldSchemaFactoryProvider
          schemaFactory={props.homeSchemaFactory}
          formOverrides={{
            onLabelClick: (fieldProps) =>
              setSelectedFieldAndGetComments?.(
                homeStandardRedesignation.id,
                fieldProps
              ),
            iterationEngineProps: {
              fieldKeysWithChangeRequests:
                getEvaluationFieldKeysForAllUnresolvedChangeRequests,
              shouldEnableForm: canEditForm,
              changeRequests: allChangeRequests,
            },
          }}
        >
          <div className="wildfire-iteration-engine">
            <div className="wildfire-iteration-engine__header">
              <HomeStandardRedesignationIterationEngineHeader
                evaluationId={props.evaluationId}
                evaluation={homeStandardRedesignation}
                setEvaluation={setHomeStandardRedesignation}
                allChangeRequestsAreResolved={resolvedAllChangeRequests}
                hasNoUnaddressedChangeRequests={hasNoUnaddressedChangeRequests}
                addressedAtLeastOneChangeRequest={
                  addressedAtLeastOneChangeRequest
                }
                isEvaluationClaimedByCurrentUser={
                  homeStandardRedesignation.auditorId ===
                  fortifiedUserStore?.user?.id
                }
                refreshEvaluation={refreshHomeStandardEvaluation}
                longestOutstandingChangeRequest={
                  longestOutstandingChangeRequest
                }
              />
            </div>
            <div className="wildfire-iteration-engine__content">
              <div className="wildfire-iteration-engine__left-panel">
                <FortifiedIterationEngineChecklistAndTabs
                  evaluationStep={homeStandardRedesignation.step}
                  evaluationStatus={homeStandardRedesignation.status_AsString}
                  selectedTabId={selectedIterationEngineFormTabId}
                  setSelectedTabId={setSelectedIterationEngineFormTabId}
                  formTabs={formTabs}
                  evaluationStepDetails={[
                    {
                      id: EvaluationStep.Application,
                      label: "Application",
                    },
                    {
                      id: EvaluationStep.ApplicationForms,
                      label: "Application Forms",
                    },
                    {
                      id: EvaluationStep.Payment,
                      label: "Payment",
                    },
                    {
                      id: EvaluationStep.FinalReview,
                      label: "IBHS Review",
                    },
                  ]}
                >
                  <>
                    <EvaluationExpiration
                      isRedesignation={
                        homeStandardRedesignation.isRedesignation
                      }
                      expiresAt={homeStandardRedesignation.expiresAt}
                      evaluationExpirationDateType_AsString={
                        homeStandardRedesignation.previousEvaluationExpirationDateType_AsString
                      }
                    />
                    <EvaluationHistory
                      evaluationId={props.evaluationId}
                      productSubtype={ProductSubtypes.HomeStandardRedesignation}
                    />
                  </>
                </FortifiedIterationEngineChecklistAndTabs>
              </div>
              <div className="wildfire-iteration-engine__center-panel">
                <HomeStandardRedesignationIterationEngineFormsContainer
                  fieldsContainerClassName="evaluation-forms-container"
                  selectedTabId={selectedIterationEngineFormTabId}
                  evaluationId={props.evaluationId}
                  evaluationStep={homeStandardRedesignation.step}
                  getChangeRequests={getChangeRequests}
                  onEvaluationFormInitialized={() => {
                    setEvaluationFormInitialized(true);
                  }}
                  refreshHomeStandardEvaluation={refreshHomeStandardEvaluation}
                />
              </div>
              <div className="wildfire-iteration-engine__right-panel">
                <FortifiedIterationEngineCommentPanel
                  fieldsContainerClassName="evaluation-forms-container"
                  isOpen={evaluationFormInitialized}
                  changeRequests={allChangeRequests}
                  unresolvedChangeRequests={unresolvedChangeRequests}
                  setSelectedIterationEngineFormTabId={
                    setSelectedIterationEngineFormTabId
                  }
                  selectedIterationEngineFormTabId={
                    selectedIterationEngineFormTabId
                  }
                  selectedKey={selectedKey}
                  comments={comments}
                  createComment={(
                    comment,
                    evaluationFieldKey,
                    evaluationFieldLabel,
                    isPublic
                  ) =>
                    createComment(
                      homeStandardRedesignation.id,
                      comment,
                      evaluationFieldKey,
                      evaluationFieldLabel,
                      isPublic
                    )
                  }
                  selectedField={selectedField}
                  deleteComment={(commentId, evaluationFieldKey) =>
                    deleteComment(
                      homeStandardRedesignation.id,
                      commentId,
                      evaluationFieldKey
                    )
                  }
                  setSelectedKeyAndGetComments={(fieldKey) =>
                    setSelectedKeyAndGetComments!(
                      homeStandardRedesignation.id,
                      fieldKey
                    )
                  }
                  allChangeRequests={allChangeRequests}
                  changeRequestCount={changeRequestCount}
                  resolvedChangeRequestCount={resolvedChangeRequestCount}
                  resolvedChangeRequestsPercentage={
                    resolvedChangeRequestsPercentage
                  }
                  unresolveChangeRequest={unresolveChangeRequest}
                  resolveChangeRequest={resolveChangeRequest}
                  getChangeRequests={getChangeRequests}
                />
              </div>
            </div>
          </div>
        </FieldSchemaFactoryProvider>
      )}
    </section>
  );
};

export default HomeStandardRedesignationIterationEngine;
