import { useEffect, useState } from "react";
import "./styles.scss";
import { closeCommentTemplateDialog } from "./CommentTemplateDialog";
import { ToolbarSettingsModel } from "@syncfusion/ej2-dropdowns";
import {
  Button,
  FormGroup,
  InputGroup,
  Intent,
  Radio,
  RadioGroup,
} from "@blueprintjs/core";
import { MinimalSelectableLabel } from "../../../../../foritfied/components/Fields/SelectableLabel/MinimalSelectableLabel";
import {
  EnumSingleSelectFieldV2,
  Footer,
  RichTextEditorField,
  RichTextEditorValueType,
} from "@ucl/library";
import { commentTemplateApiClient } from "../../../../../foritfied/lib/apiClients/commentTemplate/commentTemplateApiClient";
import { ProductTypeOptions } from "../../../../../common/types/ProductTypes";
import {
  FormTypeOptions,
  FormTypes,
} from "../../../../../common/types/FormTypes";
import {
  CommentTemplateDialogFormModel,
  CommentTemplateFormProps,
} from "./types";
import {
  CommentTemplateCreateDTO,
  CommentTemplateUpdateDTO,
} from "../../../../../foritfied/lib/apiClients/commentTemplate/types";
import { AppToaster } from "@ucl/library/lib/components/Toast/Toast";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import { fortifiedFieldSchemaApiClient } from "../../../lib/apiClients/fortifiedFieldSchemaApiClient";

// This form uses a cascading selection pattern where:
// 1. Product Type selection determines available Form Types
// 2. Form Type selection determines available Form Fields
//
// Special care is needed when handling state changes for Product Type
// to maintain synchronization between:
// - Form state (formModel)
// - Validation error state (errors)
// - Radio button group internal state
//
// Without proper state management, the UI can break when:
// - Validation errors are present
// - User tries to select a different product type
// - Dependent fields need to be reset

interface ValidationErrors {
  productType?: string;
  formType?: string;
  formFieldKey?: string;
  templateName?: string;
  templateContent?: string;
}

interface CommentTemplateCommonFormProps extends CommentTemplateFormProps {
  defaultFormValue: CommentTemplateDialogFormModel;
  formId: string;
  showProductType?: boolean;
  refreshGrid: () => void;
  getWildfireFormFields?: (
    formType: FormTypes
  ) => Array<{ value: string; label: string }>;
}

export const CommentTemplateCommonForm: React.FC<
  CommentTemplateCommonFormProps
> = ({
  id,
  defaultFormValue,
  formId,
  showProductType = true,
  refreshGrid,
  getWildfireFormFields,
}) => {
  const [formModel, setFormModel] =
    useState<CommentTemplateDialogFormModel>(defaultFormValue);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [isLoadingFields, setIsLoadingFields] = useState(false);
  const [formFields, setFormFields] = useState<
    Array<{ value: string; label: string }>
  >([]);
  const [errors, setErrors] = useState<ValidationErrors>({});

  useEffect(() => {
    if (id) {
      setIsLoadingForm(true);
      commentTemplateApiClient
        .getCommentTemplateById(id)
        .then((commentTemplate) => {
          if (!!commentTemplate) {
            const {
              formType,
              productType,
              templateContent,
              templateName,
              formFieldKey,
            } = commentTemplate;

            setFormModel({
              formType: String(formType),
              productType: String(productType),
              formFieldKey,
              templateContent,
              templateName,
            });
          }
        })
        .finally(() => {
          setIsLoadingForm(false);
        });
    }
  }, [id]);

  useEffect(() => {
    // Only fetch fields if a form type is selected
    if (formModel.formType) {
      setFormFields([]);
      setIsLoadingFields(true);

      if (getWildfireFormFields) {
        // For Wildfire forms, use section-based field getter
        const fields = getWildfireFormFields(Number(formModel.formType));
        setFormFields(fields);
        setIsLoadingFields(false);
      } else {
        // For non-Wildfire forms, fetch the form schema from API
        fortifiedFieldSchemaApiClient
          .getSchema()
          .then((response) => {
            // Get the form options for the selected product type
            const selectedFormOptions =
              FormTypeOptions[Number(formModel.productType)];

            // Find the specific form that matches our selected form type
            const selectedForm = selectedFormOptions.find(
              (option) => option.value.toString() === formModel.formType
            );

            if (selectedForm?.fullFormName && response.schema) {
              // Get the schema for our selected form using its full name
              const selectedFormSchema =
                response.schema[selectedForm.fullFormName];

              if (selectedFormSchema) {
                // Transform the schema fields into dropdown options
                // Exclude FileCount and RootFolderId fields but keep file uploaders
                const fields = Object.entries(selectedFormSchema)
                  .filter(
                    ([key, _]: [string, any]) =>
                      !key.includes("FileCount") &&
                      !key.includes("RootFolderId")
                  )
                  .map(([key, field]: [string, any]) => ({
                    value: key,
                    label: field.label || key,
                  }));

                setFormFields(fields);
              }
            }
          })
          .catch((error) => {
            console.error("Error fetching schema:", error);
            setFormFields([]);
          })
          .finally(() => {
            setIsLoadingFields(false);
          });
      }
    }
  }, [formModel.formType, formModel.productType, getWildfireFormFields]);

  const createCommentTemplate = async (
    form: CommentTemplateDialogFormModel
  ) => {
    if (!validateForm()) {
      return;
    }

    setIsLoading(true);

    const createDTO: CommentTemplateCreateDTO = {
      productType: Number(form.productType),
      formType: Number(form.formType),
      formFieldKey: form.formFieldKey,
      templateName: form.templateName,
      templateContent: form.templateContent,
    };

    await commentTemplateApiClient
      .createCommentTemplate(createDTO)
      .then(() => {
        refreshGrid();
        closeCommentTemplateDialog();
        AppToaster.show({
          message: (
            <div>
              <h3>Success</h3>Comment Template created.
            </div>
          ),
          intent: Intent.SUCCESS,
        });
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateCommentTemplate = async (
    id: string,
    form: CommentTemplateDialogFormModel
  ) => {
    if (!validateForm()) {
      return;
    }

    setIsLoading(true);

    const updateDTO: CommentTemplateUpdateDTO = {
      productType: Number(form.productType),
      formType: Number(form.formType),
      formFieldKey: form.formFieldKey,
      templateName: form.templateName,
      templateContent: form.templateContent,
    };

    await commentTemplateApiClient
      .updateCommentTemplateById(id, updateDTO)
      .then(() => {
        refreshGrid();
        closeCommentTemplateDialog();
        AppToaster.show({
          message: (
            <div>
              <h3>Success</h3>Comment Template saved.
            </div>
          ),
          intent: Intent.SUCCESS,
        });
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteCommentTemplate = async (id: string) => {
    setIsLoading(true);
    await commentTemplateApiClient
      .deleteCommentTemplateById(id)
      .then(() => {
        refreshGrid();
        closeCommentTemplateDialog();
        AppToaster.show({
          message: (
            <div>
              <h3>Success</h3>Comment Template deleted.
            </div>
          ),
          intent: Intent.SUCCESS,
        });
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // TODO: Leverage the existing api validator to validate the form &
  // Connect validator error messages to this form.
  const validateForm = (): boolean => {
    const newErrors: ValidationErrors = {};

    if (showProductType && !formModel.productType) {
      newErrors.productType = "This field is required";
    }
    if (!formModel.formType) {
      newErrors.formType = "This field is required";
    }
    if (!formModel.formFieldKey) {
      newErrors.formFieldKey = "This field is required";
    }
    if (!formModel.templateName?.trim()) {
      newErrors.templateName = "This field is required";
    }
    if (!formModel.templateContent?.trim()) {
      newErrors.templateContent = "This field is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return (
    <>
      {isLoadingForm ? (
        <Loading />
      ) : (
        <div>
          <div>
            <form
              id={formId}
              onSubmit={async (e) => {
                e.preventDefault();

                if (id) {
                  updateCommentTemplate(id, formModel);
                } else {
                  createCommentTemplate(formModel);
                }
              }}
            >
              {showProductType && (
                <FormGroup
                  className="base-field"
                  helperText={
                    errors.productType ? errors.productType : undefined
                  }
                  intent={errors.productType ? Intent.DANGER : Intent.NONE}
                >
                  <MinimalSelectableLabel label={"Product Type"} />
                  <RadioGroup
                    onChange={(item) => {
                      const newValue = item.currentTarget.value;

                      // Product Type requires special state handling because:
                      // 1. It's the top-level field in a cascading form (product type -> form type -> form field)
                      // 2. Changing it requires resetting dependent field values
                      // 3. After validation errors appear, we need to carefully manage state to prevent UI breaks
                      setFormModel((prev) => ({
                        ...prev, // Preserve unrelated form values (templateName, templateContent)
                        productType: newValue,
                        // Reset dependent fields since they're no longer valid for new product type
                        formType: "",
                        formFieldKey: "",
                      }));

                      // Error state must be managed alongside form state to maintain consistency
                      setErrors((prev) => {
                        const newErrors = { ...prev };
                        // Clear validation errors for this field and dependent fields
                        // This prevents state conflicts that could break the RadioGroup UI
                        // while preserving validation errors for unrelated fields
                        delete newErrors.productType;
                        delete newErrors.formType;
                        delete newErrors.formFieldKey;
                        return newErrors;
                      });
                    }}
                    selectedValue={formModel.productType}
                  >
                    {ProductTypeOptions.slice(0, 3).map((option) => (
                      <Radio
                        key={option.label}
                        label={option.label}
                        value={String(option.value)} // Ensure string value
                      />
                    ))}
                  </RadioGroup>
                </FormGroup>
              )}
              {formModel.productType && (
                <FormGroup
                  className="base-field"
                  helperText={errors.formType ? errors.formType : undefined}
                  intent={errors.formType ? Intent.DANGER : Intent.NONE}
                >
                  <EnumSingleSelectFieldV2
                    type="EnumSingleSelect"
                    label="Form"
                    value={Number(formModel.formType)}
                    optionValues={
                      FormTypeOptions[Number(formModel.productType)]
                    }
                    onSubmit={(value) => {
                      setFormModel((prev) => ({
                        ...prev,
                        formType: value?.toString() ?? "",
                        formFieldKey: "", // Reset the formFieldKey when form type changes
                      }));
                      setErrors((prev) => ({ ...prev, formType: undefined }));
                    }}
                  />
                </FormGroup>
              )}
              {formModel.formType && (
                <FormGroup
                  className="base-field"
                  helperText={
                    errors.formFieldKey ? errors.formFieldKey : undefined
                  }
                  intent={errors.formFieldKey ? Intent.DANGER : Intent.NONE}
                >
                  {isLoadingFields ? (
                    <Loading />
                  ) : (
                    <EnumSingleSelectFieldV2
                      type="EnumSingleSelect"
                      label={
                        getWildfireFormFields ? "Form Section" : "Form Field"
                      }
                      value={formModel.formFieldKey}
                      optionValues={formFields}
                      onSubmit={(value) => {
                        setFormModel((prev) => ({
                          ...prev,
                          formFieldKey: value?.toString() ?? "",
                        }));
                        setErrors((prev) => ({
                          ...prev,
                          formFieldKey: undefined,
                        }));
                      }}
                    />
                  )}
                </FormGroup>
              )}
              <FormGroup
                className="base-field"
                helperText={
                  errors.templateName ? errors.templateName : undefined
                }
                intent={errors.templateName ? Intent.DANGER : Intent.NONE}
              >
                <MinimalSelectableLabel label={"Template Name"} />
                <div className="template-content-field">
                  <div>
                    <InputGroup
                      className="comment-textarea"
                      value={formModel.templateName}
                      onChange={(e) => {
                        setFormModel((prev) => ({
                          ...prev,
                          templateName: e.target.value,
                        }));
                        setErrors((prev) => ({
                          ...prev,
                          templateName: undefined,
                        }));
                      }}
                    />
                  </div>
                </div>
              </FormGroup>
              <FormGroup
                className="base-field"
                helperText={
                  errors.templateContent ? errors.templateContent : undefined
                }
                intent={errors.templateContent ? Intent.DANGER : Intent.NONE}
              >
                <MinimalSelectableLabel label={"Template"} />
                <div className="template-content-field">
                  <div>
                    <RichTextEditorField
                      className="comment-template-rte"
                      valueType={RichTextEditorValueType.html}
                      value={formModel.templateContent}
                      onSubmit={(value) => {
                        setFormModel((prev) => ({
                          ...prev,
                          templateContent: value ?? "",
                        }));
                        setErrors((prev) => ({
                          ...prev,
                          templateContent: undefined,
                        }));
                      }}
                      richTextEditorSettings={{
                        height: "200px",
                        enableHtmlSanitizer: true,
                        autoSaveOnIdle: true,
                        saveInterval: 50,
                        toolbarSettings: {
                          items: [
                            "OrderedList",
                            "UnorderedList",
                            "Bold",
                            "Italic",
                            "Underline",
                            "StrikeThrough",
                            "Formats",
                            "ClearFormat",
                          ],
                        } as ToolbarSettingsModel,
                        pasteCleanupSettings: {
                          prompt: false,
                          plainText: false,
                          keepFormat: true,
                          allowedStyleProps: [""],
                          deniedAttrs: ["class", "id", "style"],
                        },
                      }}
                    />
                  </div>
                </div>
              </FormGroup>
            </form>
          </div>
          <Footer>
            {id && (
              <Button
                minimal
                intent={Intent.DANGER}
                onClick={() => deleteCommentTemplate(id)}
                icon={"trash"}
                large
              />
            )}
            <Button
              minimal
              intent={Intent.NONE}
              text={"Cancel"}
              onClick={closeCommentTemplateDialog}
            />
            <Button
              intent={Intent.SUCCESS}
              text={"Save"}
              type="submit"
              form={formId}
              loading={isLoading}
            />
          </Footer>
        </div>
      )}
    </>
  );
};
