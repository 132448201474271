import React, { useEffect, useState } from "react";
import { boxApiClient } from "../../apiClients/box/boxApiClient";
import "./styles.scss";
import { Intent } from "@blueprintjs/core";
import { AppToaster } from "@ucl/library/lib/components/Toast/Toast";
import { documentApiClient } from "../../apiClients/document/documentApiClient";
import { DocumentUploadRequest } from "../../apiClients/document/types";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import { SkeletonComponent } from "@syncfusion/ej2-react-notifications";
import FilerobotImageEditor, {
  TABS,
  TOOLS,
} from "react-filerobot-image-editor";

export interface ImageAnnotationEditorProps {
  token: string;
  fileId: string;
  fileName: string;
  parentFolderId: string;
  onClose?: () => void;
  onSave?: () => void;
}

// Utility function to convert Blob to Base64
const convertBlobToBase64 = (blob: Blob): Promise<string | null> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string | null);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

export const ImageAnnotationEditor: React.FC<ImageAnnotationEditorProps> = ({
  fileId,
  fileName,
  parentFolderId,
  token,
  onClose,
  onSave,
}) => {
  const [, setIsLoading] = useState<boolean>(false);
  const [base64Image, setBase64Image] = useState<string>();

  const fetchData = async () => {
    try {
      const data = await boxApiClient.getImage({ token, fileId });

      if (data) {
        const blob = await data.blob();
        const base64Data = await convertBlobToBase64(blob);
        if (base64Data) {
          setBase64Image(base64Data);
        }
      }
    } catch (error) {
      AppToaster.show({
        message: "Failed to fetch image.",
        intent: Intent.DANGER,
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  function base64ToFile(base64: string, fileName: string): File | null {
    try {
      // Extract the base64 data from the string
      const [metadata, base64Data] = base64.split(",");
      if (!base64Data) {
        throw new Error("Invalid base64 string.");
      }

      const mimeType =
        metadata.match(/:(.*?);/)?.[1] || "application/octet-stream";

      // Decode the base64 data
      const binaryString = window.atob(base64Data);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);

      // Convert binary string to byte array
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      // Create a Blob from the byte array
      const blob = new Blob([bytes], { type: mimeType });

      // Create and return a File object from the Blob
      return new File([blob], fileName, { type: mimeType });
    } catch (e) {
      console.error("Failed to convert base64 to file:", e);
      return null;
    }
  }

  const replaceExistingBoxImage = async (base64Image: string) => {
    setIsLoading(true);
    const annotatedFileName = fileName.includes("_annotated.png")
      ? fileName
      : fileName.replace(/(\.[\w\d_-]+)$/i, "_annotated.png");

    const annotatedFile = base64ToFile(base64Image, annotatedFileName);

    if (annotatedFile === null) {
      throw new Error("Could not convert base 64 image to file.");
    }

    const payload: DocumentUploadRequest = {
      fileName: annotatedFileName,
      file: annotatedFile,
      existingFileId: fileId,
      parentFolderId,
    };

    await documentApiClient.uploadAnnotatedImage(payload);
    setIsLoading(false);
  };

  const saveAnnotatedImage = async (
    base64AnnotatedImage: string | undefined
  ) => {
    try {
      setIsLoading(true);
      if (base64AnnotatedImage) {
        await replaceExistingBoxImage(base64AnnotatedImage);
        onSave && onSave();
      }

      onClose && onClose();
    } catch (err) {
      AppToaster.show({
        message: "Error saving image.",
        intent: Intent.DANGER,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="image-annotation-editor">
      {base64Image ? (
        <FilerobotImageEditor
          defaultSavedImageName={fileName}
          source={base64Image}
          previewPixelRatio={2}
          savingPixelRatio={2}
          onSave={async (editedImageObject) => {
            await saveAnnotatedImage(editedImageObject.imageBase64);
          }}
          Text={{ text: "Type Here..." }}
          annotationsCommon={{
            fill: "#ff0000",
          }}
          Rotate={{ angle: 90, componentType: "slider" }}
          tabsIds={[TABS.ANNOTATE]}
          defaultTabId={TABS.ANNOTATE}
          defaultToolId={TOOLS.TEXT}
          onBeforeSave={() => false}
          disableSaveIfNoChanges={true}
        />
      ) : (
        <SkeletonComponent>
          <Loading />
        </SkeletonComponent>
      )}
    </div>
  );
};
