import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import { PaymentCreditResponseReadDTO } from "../../../../Common/types/PaymentCredit/PaymentCreditResponseReadDTO";
import { Evaluation_MultifamilyRedesignation } from "../../types/MultifamilyRedesignation";
import { Evaluation_MultifamilyRedesignationFormModel } from "../../types/MultifamilyRedesignationFormModel";

class MultifamilyRedesignationAPIClient {
  createMultifamilyRedesignation = async (parentEvaluationId: string) => {
    return baseApiClient.post<Evaluation_MultifamilyRedesignation>(
      `multifamily/redesignation/${parentEvaluationId}`
    );
  };

  getMultifamilyRedesignation = async (evaluationId: string) => {
    return await baseApiClient.get<Evaluation_MultifamilyRedesignation>(
      `multifamily/redesignation/${evaluationId}`
    );
  };

  getMultifamilyRedesignationFormModel = async (evaluationId: string) => {
    return await baseApiClient.get<Evaluation_MultifamilyRedesignationFormModel>(
      `multifamily/redesignation/${evaluationId}`
    );
  };
  moveToFieldInspectionsStep = async (evaluationId: string) => {
    return await baseApiClient.put<Evaluation_MultifamilyRedesignation>(
      `multifamily/redesignation/${evaluationId}/field-inspections`
    );
  };
  moveToComplianceLetterStep = async (evaluationId: string) => {
    return await baseApiClient.put<Evaluation_MultifamilyRedesignation>(
      `multifamily/redesignation/${evaluationId}/compliance-letter`
    );
  };

  moveToIBHSReviewStep = async (evaluationId: string) => {
    return await baseApiClient.put<Evaluation_MultifamilyRedesignation>(
      `multifamily/redesignation/${evaluationId}/ibhs-review`
    );
  };

  sendRequestedChangesEmail = async (evaluationId: string) => {
    return await baseApiClient.post<void>(
      `multifamily/redesignation/${evaluationId}/requested-changes-email`
    );
  };
  sendChangesForReviewEmail = async (evaluationId: string) => {
    return await baseApiClient.post<void>(
      `multifamily/redesignation/${evaluationId}/changes-for-review-email`
    );
  };

  getApplicationFeePaymentCreditDetails = async (evaluationId: string) => {
    return await baseApiClient.get<PaymentCreditResponseReadDTO>(
      `multifamily/redesignation/${evaluationId}/payment/credit-details`
    );
  };

  applyApplicationFeePaymentCredit = async (evaluationId: string) => {
    return await baseApiClient.put<Evaluation_MultifamilyRedesignation>(
      `multifamily/redesignation/${evaluationId}/payment/credit`
    );
  };

  getApplicationFeeStripeCheckoutLink = async (evaluationId: string) => {
    return await baseApiClient.get<string>(
      `multifamily/redesignation/${evaluationId}/payment/stripe-checkout`
    );
  };
}

export const multifamilyRedesignationAPIClient =
  new MultifamilyRedesignationAPIClient();
