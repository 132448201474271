import { useState } from "react";
import { useEffect } from "react";
import { evaluationApiClient } from "../../../lib/apiClients/evaluation/evaluationApiClient";
import {
  EvaluationRedesignationHistory,
  ProductTypes,
} from "../../../types/evaluation/Evaluation";
import { parseDate } from "../../../../common/utils/date";
import { EvaluationHistoryProps } from "./EvaluationHistory";
import { useProductTypeHandler } from "../../../providers/ProductTypeHandlerProvider";
import { ProductSubtypes } from "../../../../fortifiedV2/Common/types/Evaluation/BaseEvaluation";
import {
  commercialRedesignationRoutePrefix,
  commercialRoutePrefix,
  multifamilyRedesignationRoutePrefix,
  multifamilyRoutePrefix,
} from "../../../pages/index/fortifiedCommercialMultifamilyRouteConfig";
import {
  fortifiedHomeNCIUARedesignationRoutePrefix,
  fortifiedHomeNCIUARoutePrefix,
  fortifiedHomeStandardRedesignationRoutePrefix,
  fortifiedHomeStandardRoutePrefix,
} from "../../../pages/index/fortifiedHomeRouteConfig";

export const useEvaluationHistory = (props: EvaluationHistoryProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [evaluationRedesignations, setEvaluationRedesignations] = useState<
    EvaluationRedesignationHistory[]
  >([]);

  const { routePrefix } = useProductTypeHandler();

  const getEvaluationLinkPrefix = (isParent: boolean) => {
    switch (props.productSubtype) {
      case ProductSubtypes.CommercialEvaluation:
      case ProductSubtypes.CommercialRedesignation:
        return isParent
          ? commercialRoutePrefix
          : commercialRedesignationRoutePrefix;
      case ProductSubtypes.MultifamilyEvaluation:
      case ProductSubtypes.MultifamilyRedesignation:
        return isParent
          ? multifamilyRoutePrefix
          : multifamilyRedesignationRoutePrefix;
      case ProductSubtypes.HomeStandardEvaluation:
      case ProductSubtypes.HomeStandardRedesignation:
        return isParent
          ? fortifiedHomeStandardRoutePrefix
          : fortifiedHomeStandardRedesignationRoutePrefix;
      case ProductSubtypes.HomeNCIUAEvaluation:
      case ProductSubtypes.HomeNCIUARedesignation:
        return isParent
          ? fortifiedHomeNCIUARoutePrefix
          : fortifiedHomeNCIUARedesignationRoutePrefix;
      default:
        return routePrefix;
    }
  };

  const [currentEvaluation, setCurrentEvaluation] =
    useState<EvaluationRedesignationHistory>();

  const shouldShowEvaluationHistory =
    !!currentEvaluation &&
    (currentEvaluation.isApproved || currentEvaluation.isRedesignation);

  const shouldNavigateToIterationEngine = (
    redesignationEvaluation: EvaluationRedesignationHistory
  ) => {
    return (
      redesignationEvaluation.isApproved ||
      redesignationEvaluation.productType ===
        ProductTypes.FortifiedCommercial ||
      redesignationEvaluation.productType === ProductTypes.FortifiedMultifamily
    );
  };

  const shouldHideHyperlink = (evaluation: EvaluationRedesignationHistory) => {
    return evaluation.id === props.evaluationId || props.readonly;
  };

  const getHistoryRowDisplayText = (
    evaluation: EvaluationRedesignationHistory
  ) => {
    const isParent = !evaluation.parentEvaluationId;
    const display = isParent ? "Evaluation" : "Redesignation";

    return evaluation.isApproved && evaluation.approvedAt
      ? `${display}${" - "}${parseDate(evaluation.approvedAt)}`
      : `${display}${" - "}${evaluation.status_AsString}`;
  };

  const getEvaluationLink = (evaluation: EvaluationRedesignationHistory) => {
    const isV2 = !!props.productSubtype;
    const isParent = !evaluation.parentEvaluationId;

    const prefix = getEvaluationLinkPrefix(isParent);

    if (shouldNavigateToIterationEngine(evaluation)) {
      return isV2
        ? `${prefix}/iteration-engine/${evaluation.id}`
        : `${prefix}/evaluation/${evaluation.id}`;
    } else {
      return isV2
        ? `${prefix}/checklist/${evaluation.id}`
        : `${prefix}/checklist?fortifiedId=${evaluation.fortifiedId}`;
    }
  };

  const getCurrentEvaluation = (
    redesignations: EvaluationRedesignationHistory[],
    parentId: string
  ) => {
    return redesignations.find(
      (redesignation) => redesignation.id === parentId
    );
  };

  const getEvaluationRedesignations = async () => {
    const redesignations =
      await evaluationApiClient.getEvaluationRedesignations(props.evaluationId);

    const currentEvaluation = getCurrentEvaluation(
      redesignations,
      props.evaluationId
    );

    setEvaluationRedesignations(redesignations);
    setCurrentEvaluation(currentEvaluation);

    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    getEvaluationRedesignations().catch(console.error);
  }, []);

  return {
    isLoading,
    shouldShowEvaluationHistory,
    evaluationRedesignations,
    shouldHideHyperlink,
    getEvaluationLink,
    getHistoryRowDisplayText,
  };
};
