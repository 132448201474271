/* eslint-disable react/display-name */
import { forwardRef } from "react";
import CommercialBuildingApplicationForm from "./Form/CommercialBuildingApplicationForm";
import { FieldFormV2Module } from "@ucl/library";
import { Evaluation_CommercialEvaluationFormModel } from "../../types/CommercialEvaluationFormModel";

export interface ComplexCommercialEvaluationBuildingFormV2Props {
  complexCommercialEvaluationBuildingId: string;
  isSaving: boolean;
  isDeleting: boolean;
  setHasUnsavedChanges: (value: boolean) => void;
  isIterationEnginePage?: boolean;
  hideFormControls?: boolean;
  onInitialized?: () => void;
  onFormSave?: ((fieldKey: string, value: any) => Promise<void>) | undefined;
  disabled?: boolean;
  updateChildFormError: (formId: string, hasError: boolean) => void;
  onParentNeedsRefresh?: () => Promise<void>;
}

export const ComplexCommercialEvaluationBuildingFormV2 = forwardRef<
  FieldFormV2Module<Evaluation_CommercialEvaluationFormModel>,
  ComplexCommercialEvaluationBuildingFormV2Props
>((props, ref) => {
  return (
    <div className={`evaluation-container__content__evaluation-form`}>
      <CommercialBuildingApplicationForm
        evaluationId={props.complexCommercialEvaluationBuildingId}
        ref={ref}
        setHasUnsavedChanges={props.setHasUnsavedChanges}
        disableForm={props.isSaving || props.isDeleting || props.disabled}
        isIterationEnginePage={props.isIterationEnginePage}
        hideFormControls={props.hideFormControls}
        onInitialized={props.onInitialized}
        onFormSave={props.onFormSave}
        updateChildFormError={props.updateChildFormError}
        onParentNeedsRefresh={props.onParentNeedsRefresh}
      />
    </div>
  );
});

(
  ComplexCommercialEvaluationBuildingFormV2 as React.ForwardRefExoticComponent<ComplexCommercialEvaluationBuildingFormV2Props>
).displayName = "ComplexCommercialEvaluationBuildingFormV2";
