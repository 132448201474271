import { useContext, useEffect, useState } from "react";
import { UnsavedChangesBlockerContext } from "../../../../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import {
  FormChecklistPart,
  FormChecklistStatus,
} from "../../../../../../../common/types/Forms/FormChecklist";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../../../types/HomeEvaluationFormModel";
import { HomeSchemaFactoryV2 } from "../../../../../customHooks/useHomeFieldSchemaFactory";
import { HomeStandardInstallerComplianceContinuousLoadPathFormContainerProps } from "./HomeStandardInstallerComplianceContinuousLoadPathFormContainer";
import { BaseHomeEvaluationInstallerComplianceContinuousLoadPathFormParts } from "../../../../../common/types/BaseHomeEvaluationInstallerComplianceContinuousLoadPathFormModel";
import { AppHeaderContext } from "../../../../../../../wildfire/components/Provider/AppHeaderContext";
import { fortifiedFieldSchemaApiClient } from "../../../../../../Common/lib/apiClients/fortifiedFieldSchemaApiClient";
import {
  shouldHideInstallerCLPForNewConstructionFields,
  shouldHideInstallerCLPForRetrofitOfExistingHomeFields,
  shouldHideInstallerCLPForWoodFramingFields,
} from "../../../../../common/components/FieldSchema/sharedConditionalLogic";

function useHomeStandardInstallerComplianceContinuousLoadPathFormContainer(
  props: HomeStandardInstallerComplianceContinuousLoadPathFormContainerProps
) {
  const [schemaFactory, setSchemaFactory] = useState<HomeSchemaFactoryV2>();

  const getSchema = async () => {
    const schema = await fortifiedFieldSchemaApiClient.getSchema();
    const newSchemaFactory = new HomeSchemaFactoryV2(schema);
    setSchemaFactory(newSchemaFactory);
  };

  useEffect(() => {
    if (!schemaFactory) {
      getSchema();
    }
    return () => {
      setDefaultHeader();
    };
  }, []);

  useEffect(() => {
    setFormPart(getBestFormPart());
  }, [props.formParts]);

  const { setHasUnsavedChanges } = useContext(UnsavedChangesBlockerContext);

  const [formPart, setFormPart] = useState<
    BaseHomeEvaluationInstallerComplianceContinuousLoadPathFormParts | undefined
  >(props.formParts);

  const [evaluationFormModel, setEvaluationFormModel] =
    useState<Evaluation_HomeStandardEvaluationFormModel>();

  const formModel =
    evaluationFormModel?.homeStandardEvaluation
      .installerComplianceContinuousLoadPathForm;

  const { setFotifiedHomeEvaluationHeader, setDefaultHeader } =
    useContext(AppHeaderContext);

  useEffect(() => {
    if (evaluationFormModel) {
      setFotifiedHomeEvaluationHeader(evaluationFormModel);
    }
  }, [evaluationFormModel]);

  const getNextUnsubmittedFormPart = () => {
    if (!formModel) {
      return null;
    }

    if (!formModel.isInstallerComplianceFormGeneralInformationComplete) {
      return BaseHomeEvaluationInstallerComplianceContinuousLoadPathFormParts.ContinuousLoadPathGeneralInformation;
    } else if (
      !formModel.isInstallerComplianceFormContinuousLoadPathForNewConstructionComplete
    ) {
      return BaseHomeEvaluationInstallerComplianceContinuousLoadPathFormParts.ContinuousLoadPathForNewConstruction;
    } else if (
      !formModel.isInstallerComplianceFormContinuousLoadPathForRetrofitOfExistingHomeComplete
    ) {
      return BaseHomeEvaluationInstallerComplianceContinuousLoadPathFormParts.ContinuousLoadPathForRetrofitOfExistingHome;
    } else if (
      !formModel.isInstallerComplianceFormContinuousLoadPathForWoodFramingComplete
    ) {
      return BaseHomeEvaluationInstallerComplianceContinuousLoadPathFormParts.ContinuousLoadPathForWoodFraming;
    }

    // Form is complete
    return null;
  };

  const getBestFormPart =
    (): BaseHomeEvaluationInstallerComplianceContinuousLoadPathFormParts => {
      // Default to the first part of the form if we can't find a better option
      const defaultFormPart = Object.values(
        BaseHomeEvaluationInstallerComplianceContinuousLoadPathFormParts
      )[0];

      // If the application form part is provided (by URL), use it
      if (!!props.formParts) {
        return props.formParts;
      }

      // Otherwise, try to find the first unsubmitted part of the form
      // TODO: This doesn't work yet, needs to wait for applicationFormModel to load
      return getNextUnsubmittedFormPart() ?? defaultFormPart;
    };

  const getApplicationPartChecklistStatus = (
    formPart: BaseHomeEvaluationInstallerComplianceContinuousLoadPathFormParts
  ): FormChecklistStatus => {
    if (formPart === getNextUnsubmittedFormPart()) {
      return FormChecklistStatus.NextUp;
    }

    switch (formPart) {
      case BaseHomeEvaluationInstallerComplianceContinuousLoadPathFormParts.ContinuousLoadPathGeneralInformation:
        return formModel?.isInstallerComplianceFormGeneralInformationComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case BaseHomeEvaluationInstallerComplianceContinuousLoadPathFormParts.ContinuousLoadPathForNewConstruction:
        return formModel?.isInstallerComplianceFormContinuousLoadPathForNewConstructionComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case BaseHomeEvaluationInstallerComplianceContinuousLoadPathFormParts.ContinuousLoadPathForRetrofitOfExistingHome:
        return formModel?.isInstallerComplianceFormContinuousLoadPathForRetrofitOfExistingHomeComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case BaseHomeEvaluationInstallerComplianceContinuousLoadPathFormParts.ContinuousLoadPathForWoodFraming:
        return formModel?.isInstallerComplianceFormContinuousLoadPathForWoodFramingComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      default:
        return FormChecklistStatus.Unsubmitted;
    }
  };

  const checklistParts: FormChecklistPart<BaseHomeEvaluationInstallerComplianceContinuousLoadPathFormParts>[] =
    [
      {
        formPart:
          BaseHomeEvaluationInstallerComplianceContinuousLoadPathFormParts.ContinuousLoadPathGeneralInformation,
        displayName: "Installer Compliance Form: General Information",
        requiredFieldCount:
          formModel?.installerComplianceFormGeneralInformationRequiredFieldCount,
      },
      {
        formPart:
          BaseHomeEvaluationInstallerComplianceContinuousLoadPathFormParts.ContinuousLoadPathForNewConstruction,
        displayName:
          "Installer Compliance Form: Continuous Load Path For New Construction",
        requiredFieldCount:
          formModel?.installerComplianceFormContinuousLoadPathForNewConstructionRequiredFieldCount,
        hidden: shouldHideInstallerCLPForNewConstructionFields(
          evaluationFormModel?.homeStandardEvaluation?.eligibilityForm
            ?.continuousLoadPathPathwayType
        ),
      },
      {
        formPart:
          BaseHomeEvaluationInstallerComplianceContinuousLoadPathFormParts.ContinuousLoadPathForRetrofitOfExistingHome,
        displayName:
          "Installer Compliance Form: Continuous Load Path For Retrofit Of Existing Home",
        requiredFieldCount:
          formModel?.installerComplianceFormContinuousLoadPathForRetrofitOfExistingHomeRequiredFieldCount,
        hidden: shouldHideInstallerCLPForRetrofitOfExistingHomeFields(
          evaluationFormModel?.homeStandardEvaluation?.eligibilityForm
            ?.continuousLoadPathPathwayType
        ),
      },
      {
        formPart:
          BaseHomeEvaluationInstallerComplianceContinuousLoadPathFormParts.ContinuousLoadPathForWoodFraming,
        displayName:
          "Installer Compliance Form: Continuous Load Path For High Wind Prescriptive Wood Framing",
        requiredFieldCount:
          formModel?.installerComplianceFormContinuousLoadPathForWoodFramingRequiredFieldCount,
        hidden: shouldHideInstallerCLPForWoodFramingFields(
          evaluationFormModel?.homeStandardEvaluation?.eligibilityForm
            ?.continuousLoadPathPathwayType
        ),
      },
    ];

  return {
    applicationChecklistParts: checklistParts,
    getApplicationPartChecklistStatus,
    setEvaluationFormModel,
    setHasUnsavedChanges,
    formPart,
    getBestFormPart,
    schemaFactory,
  };
}

export default useHomeStandardInstallerComplianceContinuousLoadPathFormContainer;
