import React, { useContext } from "react";
import { Navigate, useNavigate, useOutlet } from "react-router";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import fortified_logo_home from "../../../../../foritfied/assets/fortified_logo_home.png";
import {
  AppHeader,
  AppSideBar,
  PrimaryCard,
  NavigationExpandModes,
  SidebarTypes,
} from "@ucl/library";
import variables from "../../../../../common/config/variables";
import { fortifiedUserStore } from "../../../../../foritfied/stores/FortifiedUserStore";
import { UserAvatar } from "../../../../../common/Components/Avatar/UserAvatar";
import WelcomeApplicantComponent from "../../../../../foritfied/components/User/WelcomeApplicantComponent";
import { fortifiedHomeLogout } from "../../../../../common/lib/msal/fortifiedHomeMsal";
import {
  fortifiedHomeRoutePrefix,
  uclRouteObject,
} from "../../../../../foritfied/pages/index/fortifiedHomeRouteConfig";
import "../styles.scss";
import { Dialogs as UCLDialogs } from "@ucl/library";
import { Dialogs } from "../../../../../foritfied/components/Dialogs";
import { AppHeaderContext } from "../../../../../wildfire/components/Provider/AppHeaderContext";
import { Observer } from "mobx-react";

export interface PagesLayoutComponentProps {
  redirectPath: string;
}

const HomePagesLayoutComponent: React.FC<PagesLayoutComponentProps> = (
  props
) => {
  const outlet = useOutlet();
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(true);

  const { appHeader } = useContext(AppHeaderContext);

  const navigate = useNavigate();

  // const { hasPermission } =
  //   usePermissions() as PermissionsContextProps<PermissionsKey>;

  const hasWelcomePage = fortifiedUserStore.isFHApplicant;
  const shouldRenderRoutes =
    fortifiedUserStore.isFortifiedUser && !hasWelcomePage;

  const ErrorFallback = (args: FallbackProps) => {
    return (
      <PrimaryCard headerContent="Something went wrong">
        <div role="alert">
          <pre>{args.error.message}</pre>
          <button onClick={args.resetErrorBoundary}>Try again</button>
        </div>
      </PrimaryCard>
    );
  };

  return (
    <>
      {hasWelcomePage && (
        <WelcomeApplicantComponent
          productType={"Fortified Home"}
          logOutUser={fortifiedHomeLogout}
        />
      )}
      {shouldRenderRoutes && (
        <div className="fortified-home-page">
          <UCLDialogs />
          <Dialogs />
          <AppHeader
            theme={variables.theme}
            appLogo={
              <a onClick={() => navigate(`${fortifiedHomeRoutePrefix}`)}>
                <img src={fortified_logo_home} width={100} />
              </a>
            }
            appTitle={appHeader}
            breadcrumbSettings={{
              uclRouteObject: uclRouteObject(),
            }}
          >
            <UserAvatar
              logout={fortifiedHomeLogout}
              userFullName={fortifiedUserStore.fullUserName}
              userDisplayRole={fortifiedUserStore.displayRole}
            />
          </AppHeader>
          <div className="content">
            {
              <Observer>
                {() => (
                  <>
                    {fortifiedUserStore.user && (
                      <AppSideBar
                        uclRouteObject={uclRouteObject()}
                        isOpen={false}
                        onNavigationExpandChange={(sideBarOpen: boolean) => {
                          if (sideBarOpen !== isSidebarOpen)
                            setIsSidebarOpen(sideBarOpen);
                        }}
                        showSearchBar={false}
                        navigationExpandMode={NavigationExpandModes.AllClosed}
                        dockSidebarType={SidebarTypes.Column}
                      />
                    )}
                  </>
                )}
              </Observer>
            }
            <div className="fortified-home-page-main-content">
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <div>
                  {outlet || <Navigate to={props.redirectPath} replace />}
                </div>
              </ErrorBoundary>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export const HomePagesLayout = HomePagesLayoutComponent;
