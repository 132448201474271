import { useEffect, useState } from "react";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import { EvaluationStep } from "../../../../../../foritfied/types/evaluation/Evaluation";
import HomeNCIUARedesignationIterationEngineForms from "./HomeNCIUARedesignationIterationEngineForms";

interface HomeNCIUARedesignationIterationEngineFormsProps {
  selectedTabId: string;
  evaluationId: string;
  evaluationStep: EvaluationStep;
  getChangeRequests: () => Promise<void>;
  onEvaluationFormInitialized?: () => void;
  fieldsContainerClassName: string;
  refreshHomeNCIUAEvaluation: () => void;
}

const HomeNCIUARedesignationIterationEngineFormsContainer: React.FC<
  HomeNCIUARedesignationIterationEngineFormsProps
> = (props) => {
  const [ready, setReady] = useState(false);

  const onAllFormsInitialized = () => {
    props.onEvaluationFormInitialized?.();
    setTimeout(() => {
      setReady(true);
    }, 800);
  };

  useEffect(() => {
    setReady(false);
  }, [props.selectedTabId]);

  return (
    <>
      {!ready && <Loading />}

      <div style={{ visibility: ready ? "visible" : "hidden" }}>
        <HomeNCIUARedesignationIterationEngineForms
          selectedTabId={props.selectedTabId}
          fieldsContainerClassName={props.fieldsContainerClassName}
          evaluationId={props.evaluationId}
          evaluationStep={props.evaluationStep}
          onAllFormsInitialized={onAllFormsInitialized}
          onFormSave={async () => {
            await props.getChangeRequests();
            props.refreshHomeNCIUAEvaluation();
          }}
        />
      </div>
    </>
  );
};

export default HomeNCIUARedesignationIterationEngineFormsContainer;
