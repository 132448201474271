import { useContext, useEffect, useState } from "react";
import { UnsavedChangesBlockerContext } from "../../../../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import {
  FormChecklistPart,
  FormChecklistStatus,
} from "../../../../../../../common/types/Forms/FormChecklist";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../../../types/HomeEvaluationFormModel";
import { HomeSchemaFactoryV2 } from "../../../../../customHooks/useHomeFieldSchemaFactory";
import { HomeStandardEngineeringComplianceChimneyFormContainerProps } from "./HomeStandardEngineeringComplianceChimneyFormContainer";
import { BaseHomeEvaluationEngineeringComplianceChimneyFormParts } from "../../../../../common/types/BaseHomeEvaluationEngineeringComplianceChimneyFormModel";
import { AppHeaderContext } from "../../../../../../../wildfire/components/Provider/AppHeaderContext";
import { fortifiedFieldSchemaApiClient } from "../../../../../../Common/lib/apiClients/fortifiedFieldSchemaApiClient";

function useHomeStandardEngineeringComplianceChimneyFormContainer(
  props: HomeStandardEngineeringComplianceChimneyFormContainerProps
) {
  const [schemaFactory, setSchemaFactory] = useState<HomeSchemaFactoryV2>();

  const getSchema = async () => {
    const schema = await fortifiedFieldSchemaApiClient.getSchema();
    const newSchemaFactory = new HomeSchemaFactoryV2(schema);
    setSchemaFactory(newSchemaFactory);
  };

  useEffect(() => {
    if (!schemaFactory) {
      getSchema();
    }
    return () => {
      setDefaultHeader();
    };
  }, []);

  useEffect(() => {
    setFormPart(getBestFormPart());
  }, [props.formParts]);

  const { setHasUnsavedChanges } = useContext(UnsavedChangesBlockerContext);

  const [formPart, setFormPart] = useState<
    BaseHomeEvaluationEngineeringComplianceChimneyFormParts | undefined
  >(props.formParts);

  const [evaluationFormModel, setEvaluationFormModel] =
    useState<Evaluation_HomeStandardEvaluationFormModel>();

  const { setFotifiedHomeEvaluationHeader, setDefaultHeader } =
    useContext(AppHeaderContext);

  useEffect(() => {
    if (evaluationFormModel) {
      setFotifiedHomeEvaluationHeader(evaluationFormModel);
    }
  }, [evaluationFormModel]);

  const formModel =
    evaluationFormModel?.homeStandardEvaluation
      .engineeringComplianceChimneyForm;

  const getNextUnsubmittedFormPart = () => {
    if (!formModel) {
      return null;
    }

    if (!formModel.isEngineeringComplianceChimneyComplete) {
      return BaseHomeEvaluationEngineeringComplianceChimneyFormParts.EngineeringComplianceChimney;
    }

    // Form is complete
    return null;
  };

  const getBestFormPart =
    (): BaseHomeEvaluationEngineeringComplianceChimneyFormParts => {
      // Default to the first part of the form if we can't find a better option
      const defaultFormPart = Object.values(
        BaseHomeEvaluationEngineeringComplianceChimneyFormParts
      )[0];

      // If the application form part is provided (by URL), use it
      if (!!props.formParts) {
        return props.formParts;
      }

      // Otherwise, try to find the first unsubmitted part of the form
      // TODO: This doesn't work yet, needs to wait for applicationFormModel to load
      return getNextUnsubmittedFormPart() ?? defaultFormPart;
    };

  const getApplicationPartChecklistStatus = (
    formPart: BaseHomeEvaluationEngineeringComplianceChimneyFormParts
  ): FormChecklistStatus => {
    if (formPart === getNextUnsubmittedFormPart()) {
      return FormChecklistStatus.NextUp;
    }

    switch (formPart) {
      case BaseHomeEvaluationEngineeringComplianceChimneyFormParts.EngineeringComplianceChimney:
        return formModel?.isEngineeringComplianceChimneyComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      default:
        return FormChecklistStatus.Unsubmitted;
    }
  };

  const checklistParts: FormChecklistPart<BaseHomeEvaluationEngineeringComplianceChimneyFormParts>[] =
    [
      {
        formPart:
          BaseHomeEvaluationEngineeringComplianceChimneyFormParts.EngineeringComplianceChimney,
        displayName: "Chimney",
        requiredFieldCount:
          formModel?.engineeringComplianceChimneyRequiredFieldCount,
      },
    ];

  return {
    applicationChecklistParts: checklistParts,
    getApplicationPartChecklistStatus,
    setEvaluationFormModel,
    setHasUnsavedChanges,
    formPart,
    getBestFormPart,
    schemaFactory,
  };
}

export default useHomeStandardEngineeringComplianceChimneyFormContainer;
