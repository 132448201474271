import { EnumRadioField, RelativityTooltip } from "@ucl/library";
import React from "react";
import { Option } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import "./styles.scss";
import { auditorChecklistApiClient } from "../../../../../foritfied/lib/apiClients/auditorChecklist/auditorChecklistApiClient";
import { AuditorChecklistItemUpdateDTO } from "../../../../../foritfied/lib/apiClients/auditorChecklist/types";
import { auditorChecklistStore } from "../../../../../foritfied/stores/AuditorChecklistStore";
import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

export interface AuditorChecklistItemProps {
  checklistItem: AuditorChecklistItem;
}

export interface AuditorChecklistItem {
  id: string;
  status?: number;
  hazard: string;
  formName: string;
  text: string;
  tooltipText: string;
  shouldDisplayNaOption?: boolean;
}

export const auditorOptions: Option<number>[] = [
  { label: "Pass", value: 1 },
  { label: "Fail", value: 2 },
];

const getStatusText = (status: number): string => {
  switch (status) {
    case 0:
      return "";
    case 1:
      return "pass";
    case 2:
      return "fail";
    case 3:
      return "na";
    default:
      return "";
  }
};

export const AuditorchecklistItem: React.FC<AuditorChecklistItemProps> = ({
  checklistItem,
}) => {
  return (
    <div
      className={`auditor-checklist-item ${getStatusText(
        checklistItem.status ?? 0
      )}`}
    >
      <div className="auditor-checklist-item-title">
        <span>{checklistItem.text}</span>
        <RelativityTooltip
          placement="auto"
          compact={true}
          shouldShowTooltip={true}
          interactionKind={"hover"}
          content={checklistItem.tooltipText}
        >
          <Icon iconSize={15} icon={IconNames.INFO_SIGN} />
        </RelativityTooltip>
      </div>
      <hr />
      <EnumRadioField
        optionValues={[
          ...auditorOptions,
          ...(!!checklistItem.shouldDisplayNaOption
            ? [
                {
                  label: "N/A",
                  value: 3,
                },
              ]
            : []),
        ]}
        onSubmit={async (x) => {
          if (x === undefined) return;

          const auditUpdate: AuditorChecklistItemUpdateDTO = {
            status: x,
          };

          const updatedChecklist =
            await auditorChecklistApiClient.updateAuditorChecklist(
              checklistItem.id,
              auditUpdate
            );

          auditorChecklistStore.updateChecklist(updatedChecklist);
        }}
        value={checklistItem?.status !== 0 ? checklistItem.status : undefined}
      />
    </div>
  );
};
