import { FC, useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  FieldSchemaFactoryProvider,
  HomeSchemaFactoryV2,
} from "../../../customHooks/useHomeFieldSchemaFactory";
import { fortifiedFieldSchemaApiClient } from "../../../../Common/lib/apiClients/fortifiedFieldSchemaApiClient";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../types/HomeEvaluationFormModel";
import { homeStandardEvaluationAPIClient } from "../../lib/apiClients/homeStandardEvaluationAPIClient";
import { AppToaster } from "@ucl/library/lib/components/Toast/Toast";
import { Intent, NonIdealState } from "@blueprintjs/core";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../../../../AppInsights";
import ExternalPageHeader from "../../../../../foritfied/components/Header/ExternalPageHeader";
import { useSearchParams } from "react-router-dom";
import "./styles.scss";
import SimpleHomeStandardEngineeringComplianceContinuousLoadPathForm from "../../components/Forms/EngineeringComplianceContinuousLoadPathForm/Form/Simple/SimpleHomeStandardEvaluationEngineeringComplianceContinuousLoadPathForm";

const HomeStandardExternalEngineeringComplianceContinuousLoadPathPageComponent: FC =
  () => {
    const { id } = useParams<{ id: string }>();

    const [searchParams] = useSearchParams();
    const accessToken = searchParams.get("token");

    const [isLoading, setIsLoading] = useState(false);
    const [evaluation, setEvaluation] =
      useState<Evaluation_HomeStandardEvaluationFormModel>();
    const [schemaFactory, setSchemaFactory] = useState<HomeSchemaFactoryV2>();

    const getEvaluation = async (evaluationId: string, accessToken: string) => {
      setIsLoading(true);
      await homeStandardEvaluationAPIClient
        .getHomeStandardEvaluationFormModelExternal(evaluationId, accessToken)
        .then((foundEvaluation) => {
          setEvaluation(foundEvaluation);
        })
        .catch((error) => {
          console.error(error);
          AppToaster.show({
            message: "Unexpected error occurred while loading evaluation",
            intent: Intent.DANGER,
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    const getSchema = async () => {
      setIsLoading(true);
      await fortifiedFieldSchemaApiClient
        .getSchema()
        .then((schema) => {
          const newSchemaFactory = new HomeSchemaFactoryV2(schema);
          setSchemaFactory(newSchemaFactory);
        })
        .catch((error) => {
          console.error(error);
          AppToaster.show({
            message: "Unexpected error occurred while loading form schema",
            intent: Intent.DANGER,
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    useEffect(() => {
      if (id && accessToken) {
        getEvaluation(id, accessToken);
        getSchema();
      }
    }, []);

    if (isLoading) {
      return <Loading />;
    }

    if (!accessToken || !evaluation || !schemaFactory) {
      return <NonIdealState />;
    }

    return (
      <>
        <ExternalPageHeader
          name={"Engineering Compliance Continuous Load Path Form"}
          address={evaluation.buildingAddress_AsString}
          fortifiedId={evaluation.fortifiedId}
        />
        <div className="content">
          <div className="external-fh-forms">
            <FieldSchemaFactoryProvider schemaFactory={schemaFactory}>
              <SimpleHomeStandardEngineeringComplianceContinuousLoadPathForm
                form={evaluation}
                accessToken={accessToken}
              />
            </FieldSchemaFactoryProvider>
          </div>
        </div>
      </>
    );
  };

export const HomeStandardExternalEngineeringComplianceContinuousLoadPathPage =
  withAITracking(
    reactPlugin,
    HomeStandardExternalEngineeringComplianceContinuousLoadPathPageComponent,
    "HomeStandardExternalEngineeringComplianceContinuousLoadPathPage",
    "tracking-container"
  );
