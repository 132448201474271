import { Navigate, useNavigate } from "react-router";
import { LandscapeReviewFormParts } from "../../forms/LandscapeReviewForm/LandscapeReviewFormModel";
import { useContext, useEffect, useState } from "react";
import {
  FieldSchemaFactoryProvider,
  WildfireSchemaFactoryV2,
} from "../../customHooks/useFieldSchemaFactory";
import { PrimaryCard, SecondaryCard } from "@ucl/library";
import { AppHeaderContext } from "../../components/Provider/AppHeaderContext";
import { EvaluationFormModel } from "../../forms/EvaluationFormModel";
import { wildfireEvaluationApiClient } from "../../lib/apiClients/evaluation/wildfireEvaluationApiClient";
import {
  errorStore,
  genericErrorMessage,
} from "../../../common/Components/Error/ErrorStore";
import {
  FormChecklistPart,
  FormChecklistStatus,
} from "../../../common/types/Forms/FormChecklist";
import useWildfirePermissons from "../../customHooks/useWildfirePermissons";
import { UnsavedChangesBlockerContext } from "../../components/Provider/UnsavedChangesBlockerContext";
import { wildfireRoutePrefix } from "../index/wildfireRouteConfig";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import { DashboardNavigationButton } from "../../components/Button/DashboardNavigationButton/DashboardNavigationButton";
import { Button, Icon, Intent, Tag } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import LandscapeReviewForm from "../../components/Forms/LandscapeReviewForm/LandscapeReviewForm";
import { PhotoRequirementsBanner } from "../../components/Forms/FormSections/InformationalBanner/InformationalBanners/PhotoRequirementsBanner";
import { wildfireFieldSchemaApiClient } from "../../lib/apiClients/wildfireFieldSchemaApiClient";

interface LandscapeReviewFormPageProps {
  wildfireEvaluationId: string;
  landscapeReviewFormPart?: LandscapeReviewFormParts;
}

const LandscapeReviewFormPage = (props: LandscapeReviewFormPageProps) => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  const [wildfireSchemaFactory, setWildfireSchemaFactory] =
    useState<WildfireSchemaFactoryV2>();

  const getSchema = async () => {
    const schema = await wildfireFieldSchemaApiClient.getSchema();
    const newSchemaFactory = new WildfireSchemaFactoryV2(schema);
    setWildfireSchemaFactory(newSchemaFactory);
  };

  useEffect(() => {
    if (!wildfireSchemaFactory) {
      getSchema();
    }
  }, []);

  const { setWildfireEvaluationHeader, setDefaultHeader } =
    useContext(AppHeaderContext);

  const [landscapeReviewFormPart, setLandscapeReviewFormPart] = useState<
    LandscapeReviewFormParts | undefined
  >(props.landscapeReviewFormPart);

  const [evaluationFormModel, setEvaluationFormModel] =
    useState<EvaluationFormModel>();

  const landscapeReviewForm =
    evaluationFormModel?.wildfireLandscapeReview.landscapeReviewForm;

  useEffect(() => {
    return () => {
      setDefaultHeader();
    };
  }, []);

  const fetchLandscapeReviewForm = async () => {
    await wildfireEvaluationApiClient
      .getLandscapeReviewForm(props.wildfireEvaluationId)
      .then((response) => {
        setEvaluationFormModel(response);
        setWildfireEvaluationHeader(response);
      })
      .catch(() => {
        errorStore.setErrorMessage(genericErrorMessage);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getNextUnsubmittedFormPart = () => {
    if (!landscapeReviewForm) {
      return null;
    }

    if (!landscapeReviewForm.propertyInformationSubmitted) {
      return LandscapeReviewFormParts.PropertyInformation;
    } else if (
      landscapeReviewForm.propertyInformationSubmitted &&
      !landscapeReviewForm.hasPaymentBeenSubmitted
    ) {
      return LandscapeReviewFormParts.Payment;
    } else if (
      !landscapeReviewForm.photoRequirementsSubmitted ||
      !landscapeReviewForm.submitted
    ) {
      return LandscapeReviewFormParts.PhotoRequirements;
    }

    // Form is complete
    return null;
  };

  const getBestFormPart = (): LandscapeReviewFormParts => {
    // Default to the first part of the form if we can't find a better option
    const defaultFormPart = Object.values(LandscapeReviewFormParts)[0];

    // If the landscape review form part is provided (by URL), use it
    if (!!props.landscapeReviewFormPart) {
      return props.landscapeReviewFormPart;
    }

    // Otherwise, try to find the first unsubmitted part of the form
    // TODO: This doesn't work yet, needs to wait for landscapeReviewFormModel to load
    return getNextUnsubmittedFormPart() ?? defaultFormPart;
  };

  useEffect(() => {
    setLandscapeReviewFormPart(getBestFormPart());
    fetchLandscapeReviewForm();
  }, [props.landscapeReviewFormPart]);

  const getLandscapeReviewPartChecklistStatus = (
    formPart: LandscapeReviewFormParts
  ): FormChecklistStatus => {
    if (formPart === getNextUnsubmittedFormPart()) {
      return FormChecklistStatus.NextUp;
    }

    switch (formPart) {
      case LandscapeReviewFormParts.PropertyInformation:
        return landscapeReviewForm?.propertyInformationSubmitted
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case LandscapeReviewFormParts.Payment:
        return landscapeReviewForm?.hasPaymentBeenSubmitted
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case LandscapeReviewFormParts.PhotoRequirements:
        return landscapeReviewForm?.photoRequirementsSubmitted
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
    }
  };

  const landscapeReviewChecklistParts: FormChecklistPart<LandscapeReviewFormParts>[] =
    [
      {
        formPart: LandscapeReviewFormParts.PropertyInformation,
        displayName: "Property Information",
        requiredFieldCount:
          landscapeReviewForm?.propertyInformationRequiredFieldCount,
      },
      {
        formPart: LandscapeReviewFormParts.Payment,
        displayName: "Payment",
        disabled: true,
      },
      {
        formPart: LandscapeReviewFormParts.PhotoRequirements,
        displayName: "Photo Requirements",
        requiredFieldCount:
          landscapeReviewForm?.photoRequirementsRequiredFieldCount,
        disabled: !(
          landscapeReviewForm?.propertyInformationSubmitted === true &&
          landscapeReviewForm?.hasPaymentBeenSubmitted === true
        ),
      },
    ];

  const { canEditForm } = useWildfirePermissons(
    evaluationFormModel?.wildfireLandscapeReview.step,
    evaluationFormModel?.status_AsString
  );

  const { setHasUnsavedChanges } = useContext(UnsavedChangesBlockerContext);

  return (
    <>
      {!landscapeReviewFormPart ||
      !Object.values(LandscapeReviewFormParts).includes(
        landscapeReviewFormPart
      ) ? (
        // TODO: Show error page if the landscape review form part is not valid
        <Navigate
          to={`${wildfireRoutePrefix}/${
            props.wildfireEvaluationId
          }/landscape-review-form/${getBestFormPart().valueOf()}`}
        />
      ) : isLoading || !wildfireSchemaFactory ? (
        <Loading />
      ) : (
        <div className="wildfire-form-page">
          <div className="wildfire-form-progress">
            <DashboardNavigationButton />
            <div className="wildfire-form-progress__steps">
              {landscapeReviewChecklistParts.map((checklistPart, index) => {
                if (checklistPart.hidden) {
                  return null;
                }

                const formPartValue = checklistPart.formPart.valueOf();
                return (
                  <div key={formPartValue}>
                    <div className="wildfire-form-progress__steps__item">
                      <div
                        className={"wildfire-form-progress__steps__item__page"}
                      >
                        <Icon
                          icon={(() => {
                            switch (
                              getLandscapeReviewPartChecklistStatus(
                                checklistPart.formPart
                              )
                            ) {
                              case FormChecklistStatus.Unsubmitted:
                                return IconNames.CIRCLE;
                              case FormChecklistStatus.NextUp:
                                return IconNames.RING;
                              case FormChecklistStatus.Submitted:
                                return IconNames.TICK_CIRCLE;
                              default:
                                return IconNames.ERROR;
                            }
                          })()}
                        />
                        <Button
                          minimal
                          intent={Intent.PRIMARY}
                          active={
                            landscapeReviewFormPart === checklistPart.formPart
                          }
                          text={checklistPart.displayName}
                          onClick={() => {
                            navigate(
                              `${wildfireRoutePrefix}/${props.wildfireEvaluationId}/landscape-review-form/${formPartValue}`
                            );
                          }}
                          disabled={checklistPart.disabled}
                        />
                      </div>
                      {!checklistPart.disabled &&
                        !!checklistPart.requiredFieldCount &&
                        checklistPart.requiredFieldCount > 0 && (
                          <div className="wildfire-form-progress__steps__item__badge">
                            <Tag large={true} round={true}>
                              {checklistPart.requiredFieldCount}
                            </Tag>
                          </div>
                        )}
                    </div>
                    {index !==
                      landscapeReviewChecklistParts.filter(
                        (part) => !part.hidden
                      ).length -
                        1 && <div className="vertical-line" />}
                  </div>
                );
              })}
            </div>
          </div>
          <section className="wildfire-form-content">
            <div className="wildfire-form-content__header">
              <DashboardNavigationButton />
            </div>

            <PrimaryCard
              className="wildfire-form-primary-card"
              headerContent={
                <div className="wildfire-form-primary-card__header">
                  WFP Landscape Review Form:{" "}
                  {
                    landscapeReviewChecklistParts.find(
                      (value) => value.formPart === landscapeReviewFormPart
                    )?.displayName
                  }
                </div>
              }
            >
              <SecondaryCard
                className="wildfire-form-secondary-card"
                hideBody={false}
                isCollapsible={false}
              >
                <FieldSchemaFactoryProvider
                  schemaFactory={wildfireSchemaFactory}
                >
                  {landscapeReviewFormPart ===
                    LandscapeReviewFormParts.PhotoRequirements && (
                    <PhotoRequirementsBanner />
                  )}
                  <LandscapeReviewForm
                    key={landscapeReviewFormPart}
                    wildfireEvaluationId={props.wildfireEvaluationId}
                    formPart={landscapeReviewFormPart}
                    setFormModel={setEvaluationFormModel}
                    disableForm={!canEditForm}
                    setHasUnsavedChanges={setHasUnsavedChanges}
                    checklistParts={landscapeReviewChecklistParts}
                  />
                </FieldSchemaFactoryProvider>
              </SecondaryCard>
            </PrimaryCard>
          </section>
        </div>
      )}
    </>
  );
};
export default LandscapeReviewFormPage;
