import { FieldSchemaFormBuilderV2, SchemaFactoryV2 } from "@ucl/library";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../../types/HomeEvaluationFormModel";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { WildfireBoxContentExplorer } from "../../../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import { BuildingElementTypes } from "../../../../common/types/BaseHomeEvaluationEligibilityFormModel";

const modelName = "Evaluation";
const subModelName = "HomeStandardEvaluation";
const formName = "EngineeringComplianceAttachedStructureForm";

const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

const engineeringComplianceAttachedStructureFields = (
  form: Evaluation_HomeStandardEvaluationFormModel
) => [
  buildFieldKey("Attestation223"),
  buildFieldKey("Attestation224"),
  buildFieldKey("Attestation225"),
  buildFieldKey("Attestation226"),
  {
    fieldKey: buildFieldKey("Attestation227"),
    hidden:
      !form.homeStandardEvaluation.eligibilityForm.buildingElementsEngineerIsProvidingComplianceDesignInfoFor?.includes(
        BuildingElementTypes.SingleLevelAttachedStructures
      ),
  },
  {
    fieldKey: buildFieldKey("Attestation228"),
    hidden:
      !form.homeStandardEvaluation.eligibilityForm.buildingElementsEngineerIsProvidingComplianceDesignInfoFor?.includes(
        BuildingElementTypes.SingleLevelAttachedStructures
      ),
  },
  {
    fieldKey: buildFieldKey("Attestation229"),
    hidden:
      !form.homeStandardEvaluation.eligibilityForm.buildingElementsEngineerIsProvidingComplianceDesignInfoFor?.includes(
        BuildingElementTypes.MultiLevelAttachedStructures
      ),
  },
  {
    fieldKey: buildFieldKey("Attestation230"),
    hidden:
      !form.homeStandardEvaluation.eligibilityForm.buildingElementsEngineerIsProvidingComplianceDesignInfoFor?.includes(
        BuildingElementTypes.MultiLevelAttachedStructures
      ),
  },
  {
    fieldKey: buildFieldKey("Attestation231"),
    hidden:
      !form.homeStandardEvaluation.eligibilityForm.buildingElementsEngineerIsProvidingComplianceDesignInfoFor?.includes(
        BuildingElementTypes.MultiLevelAttachedStructures
      ),
  },
  {
    fieldKey: buildFieldKey("Attestation232"),
    hidden:
      !form.homeStandardEvaluation.eligibilityForm.buildingElementsEngineerIsProvidingComplianceDesignInfoFor?.includes(
        BuildingElementTypes.MultiLevelAttachedStructures
      ),
  },
  buildFieldKey("Attestation233"),
];

const engineeringComplianceAttachedStructureRequiredFields = [
  buildFieldKey("Attestation223"),
  buildFieldKey("Attestation224"),
  buildFieldKey("Attestation225"),
  buildFieldKey("Attestation226"),
  buildFieldKey("Attestation227"),
  buildFieldKey("Attestation228"),
  buildFieldKey("Attestation229"),
  buildFieldKey("Attestation230"),
  buildFieldKey("Attestation231"),
  buildFieldKey("Attestation232"),
  buildFieldKey("Attestation233"),
];

// Full form builder with all parts included
export const homeStandardEngineeringComplianceAttachedStructureBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        engineeringComplianceAttachedStructureFields(form),
        onValueChange,
        form,
        errors,
        engineeringComplianceAttachedStructureRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Full form builder with all parts included, for Iteration Engine
export const homeStandardIterationEngineEngineeringComplianceAttachedStructureFormBuilder =
  (
    factory: SchemaFactoryV2,
    beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
  ): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
    return ((onValueChange, form, errors) => {
      let fields = [
        ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
          modelName,
          engineeringComplianceAttachedStructureFields(form),
          onValueChange,
          form,
          errors,
          engineeringComplianceAttachedStructureRequiredFields,
          {
            FileUploader: WildfireBoxContentExplorer,
          }
        ),
      ];
      if (beforeBuild) {
        fields = beforeBuild(fields);
      }
      return fields;
    }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
  };
