import { useContext, useEffect, useState } from "react";
import { UnsavedChangesBlockerContext } from "../../../../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import {
  FormChecklistPart,
  FormChecklistStatus,
} from "../../../../../../../common/types/Forms/FormChecklist";
import { HomeSchemaFactoryV2 } from "../../../../../customHooks/useHomeFieldSchemaFactory";
import { BaseHomeEvaluationRooferConfirmationFormParts } from "../../../../../common/types/BaseHomeEvaluationRooferConfirmationFormModel";
import { HomeNCIUARooferConfirmationFormContainerProps } from "./HomeNCIUARooferConfirmationFormContainer";
import { Evaluation_HomeNCIUAEvaluationFormModel } from "../../../../evaluation/types/HomeNCIUAEvaluationFormModel";
import { AppHeaderContext } from "../../../../../../../wildfire/components/Provider/AppHeaderContext";
import { fortifiedFieldSchemaApiClient } from "../../../../../../Common/lib/apiClients/fortifiedFieldSchemaApiClient";

function useHomeNCIUARooferConfirmationFormContainer(
  props: HomeNCIUARooferConfirmationFormContainerProps
) {
  const [commercialSchemaFactory, setCommercialSchemaFactory] =
    useState<HomeSchemaFactoryV2>();

  const getSchema = async () => {
    const schema = await fortifiedFieldSchemaApiClient.getSchema();
    const newSchemaFactory = new HomeSchemaFactoryV2(schema);
    setCommercialSchemaFactory(newSchemaFactory);
  };

  useEffect(() => {
    if (!commercialSchemaFactory) {
      getSchema();
    }
    return () => {
      setDefaultHeader();
    };
  }, []);

  useEffect(() => {
    setFormPart(getBestFormPart());
  }, [props.rooferConfirmationFormPart]);

  const { setHasUnsavedChanges } = useContext(UnsavedChangesBlockerContext);

  const [formPart, setFormPart] = useState<
    BaseHomeEvaluationRooferConfirmationFormParts | undefined
  >(props.rooferConfirmationFormPart);

  const [evaluationFormModel, setEvaluationFormModel] =
    useState<Evaluation_HomeNCIUAEvaluationFormModel>();

  const rooferConfirmationForm =
    evaluationFormModel?.homeNCIUAEvaluation.rooferConfirmationForm;

  const { setFotifiedHomeEvaluationHeader, setDefaultHeader } =
    useContext(AppHeaderContext);

  useEffect(() => {
    if (evaluationFormModel) {
      setFotifiedHomeEvaluationHeader(evaluationFormModel);
    }
  }, [evaluationFormModel]);

  const getNextUnsubmittedFormPart = () => {
    if (!rooferConfirmationForm) {
      return null;
    }

    if (!rooferConfirmationForm.rooferConfirmationComplete) {
      return BaseHomeEvaluationRooferConfirmationFormParts.RoofingConfirmation;
    }

    // Form is complete
    return null;
  };

  const getBestFormPart = (): BaseHomeEvaluationRooferConfirmationFormParts => {
    // Default to the first part of the form if we can't find a better option
    const defaultFormPart = Object.values(
      BaseHomeEvaluationRooferConfirmationFormParts
    )[0];

    // If the application form part is provided (by URL), use it
    if (!!props.rooferConfirmationFormPart) {
      return props.rooferConfirmationFormPart;
    }

    // Otherwise, try to find the first unsubmitted part of the form
    // TODO: This doesn't work yet, needs to wait for applicationFormModel to load
    return getNextUnsubmittedFormPart() ?? defaultFormPart;
  };

  const getApplicationPartChecklistStatus = (
    formPart: BaseHomeEvaluationRooferConfirmationFormParts
  ): FormChecklistStatus => {
    if (formPart === getNextUnsubmittedFormPart()) {
      return FormChecklistStatus.NextUp;
    }

    switch (formPart) {
      case BaseHomeEvaluationRooferConfirmationFormParts.RoofingConfirmation:
        return rooferConfirmationForm?.rooferConfirmationComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
    }
  };

  const checklistParts: FormChecklistPart<BaseHomeEvaluationRooferConfirmationFormParts>[] =
    [
      {
        formPart:
          BaseHomeEvaluationRooferConfirmationFormParts.RoofingConfirmation,
        displayName: "Roofer Confirmation",
        requiredFieldCount:
          rooferConfirmationForm?.rooferConfirmationRequiredCount,
      },
    ];

  return {
    applicationChecklistParts: checklistParts,
    getApplicationPartChecklistStatus,
    setEvaluationFormModel,
    setHasUnsavedChanges,
    applicationFormPart: formPart,
    getBestFormPart,
    commercialSchemaFactory,
    evaluationFormModel,
  };
}

export default useHomeNCIUARooferConfirmationFormContainer;
