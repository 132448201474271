import { Schema } from "@ucl/library";
import baseExternalApiClient from "../../../../foritfied/lib/apiClients/baseExternalApiClient";

class FortifiedFieldSchemaApiClient {
  getSchema = async (): Promise<Schema> => {
    return await baseExternalApiClient.get<Schema>(`/field-schema/fortified`);
  };
}

export const fortifiedFieldSchemaApiClient =
  new FortifiedFieldSchemaApiClient();
