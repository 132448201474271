export enum ASCECodeTypes {
  PreASCE702 = 1,
  ASCE702 = 2,
  ASCE705 = 3,
  ASCE710 = 4,
  ASCE716 = 5,
  ASCE722 = 6,
}

export enum RoofSlopeTypes {
  LowSlopeLessThan10Degrees = 1,
  SteepSlopeGreaterThan10degrees = 2,
}

export const getEvaluationCompanyTypeLabel = (
  value: EvaluationCompanyTypes
): string => {
  switch (value) {
    case EvaluationCompanyTypes.Paragon:
      return "Paragon";
    case EvaluationCompanyTypes.GRC:
      return "GRC";
    case EvaluationCompanyTypes.Zurich:
      return "Zurich";
    case EvaluationCompanyTypes.AEI:
      return "AEI";
  }
};

export enum EvaluationCompanyTypes {
  Paragon = 1,
  GRC = 2,
  Zurich = 3,
  AEI = 4,
}
