import baseApiClient from "../../../../../../foritfied/lib/apiClients/baseApiClient";
import { PaymentCreditResponseReadDTO } from "../../../../../Common/types/PaymentCredit/PaymentCreditResponseReadDTO";
import { Evaluation_HomeNCIUARedesignation } from "../../types/HomeNCIUARedesignation";
import { Evaluation_HomeNCIUARedesignationFormModel } from "../../types/HomeNCIUARedesignationFormModel_Model";

class HomeNCIUARedesignationAPIClient {
  createHomeNCIUARedesignation = async (evaluationId: string) => {
    return baseApiClient.post<Evaluation_HomeNCIUARedesignation>(
      `home-nciua/redesignation/${evaluationId}`
    );
  };

  getHomeNCIUARedesignation = async (evaluationId: string) => {
    return await baseApiClient.get<Evaluation_HomeNCIUARedesignation>(
      `home-nciua/redesignation/${evaluationId}`
    );
  };

  getHomeNCIUARedesignationFormModel = async (evaluationId: string) => {
    return await baseApiClient.get<Evaluation_HomeNCIUARedesignationFormModel>(
      `home-nciua/redesignation/${evaluationId}`
    );
  };

  getApplicationFeePaymentCreditDetails = async (evaluationId: string) => {
    return await baseApiClient.get<PaymentCreditResponseReadDTO>(
      `home-nciua/redesignation/${evaluationId}/payment/credit-details`
    );
  };

  applyApplicationFeePaymentCredit = async (evaluationId: string) => {
    return await baseApiClient.put<Evaluation_HomeNCIUARedesignation>(
      `home-nciua/redesignation/${evaluationId}/payment/credit`
    );
  };

  bypassPaymentStepForNonProfit = async (evaluationId: string) => {
    return await baseApiClient.post<Evaluation_HomeNCIUARedesignation>(
      `home-nciua/redesignation/${evaluationId}/payment/nonprofit`
    );
  };

  getApplicationFeeStripeCheckoutLink = async (evaluationId: string) => {
    return await baseApiClient.get<string>(
      `home-nciua/redesignation/${evaluationId}/payment/stripe-checkout`
    );
  };

  sendRequestedChangesEmail = async (evaluationId: string) => {
    return await baseApiClient.post<void>(
      `home-nciua/redesignation/${evaluationId}/requested-changes-email`
    );
  };
}

export const homeNCIUARedesignationAPIClient =
  new HomeNCIUARedesignationAPIClient();
