import { Button, Intent, Menu, MenuItem, Position } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Popover2 } from "@blueprintjs/popover2";
import { useState } from "react";
import "./styles.scss";
import { browserDownloadFileResponse } from "../../../../../common/utils/download";
import { RelativityTooltip } from "@ucl/library";
import { BaseEvaluation } from "../../../types/Evaluation/BaseEvaluation";
import { fortifiedUserStore } from "../../../../../foritfied/stores/FortifiedUserStore";
import { evaluationApiClient } from "../../../../../foritfied/lib/apiClients/evaluation/evaluationApiClient";
import { permissionStore } from "../../../../../foritfied/stores/PermissionStore";

interface DownloadCertificateActionButtonV2Props {
  evaluation: BaseEvaluation;
}

const DownloadCertificateActionButtonV2: React.FC<
  DownloadCertificateActionButtonV2Props
> = ({ evaluation }) => {
  const [isLoading, setIsLoading] = useState(false);

  const certificateName = "Certificate.pdf";
  const fileName = evaluation.fortifiedId
    ? `${evaluation.fortifiedId}-${certificateName}`
    : `${certificateName}`;

  const isCertificateButtonDisplayed = !!evaluation.approvedAt;

  const isFCFMFEvaluation =
    evaluation.productType === "FortifiedCommercial" ||
    evaluation.productType === "FortifiedMultifamily";

  const hasFCFMFPaymentWorkflow =
    isFCFMFEvaluation &&
    !evaluation.isComplexCommercialEvaluation &&
    !evaluation.isEvaluationCreatorExemptFromPayment &&
    !evaluation.isRedesignation &&
    !!evaluation.createdAt &&
    new Date(evaluation.createdAt) > new Date("2024-01-21");

  const shouldDisableDownload =
    fortifiedUserStore.isFCFMFApplicant &&
    hasFCFMFPaymentWorkflow &&
    !evaluation.hasFinalCertificateFeePaymentBeenProcessed;

  return (
    <>
      <Popover2
        position={Position.BOTTOM}
        content={
          <Menu>
            <MenuItem
              icon={IconNames.DOWNLOAD}
              text="Download"
              onClick={async () => {
                setIsLoading(true);
                await evaluationApiClient
                  .getEvaluationCertificate(evaluation.id)
                  .then((response) => {
                    browserDownloadFileResponse(response.data, fileName);
                  })
                  .finally(() => {
                    setIsLoading(false);
                  });
              }}
            />
            {permissionStore.canRegenerateCertificate && (
              <MenuItem
                icon={IconNames.REFRESH}
                text="Regenerate"
                onClick={async () => {
                  setIsLoading(true);
                  await evaluationApiClient
                    .regenerateEvaluationCertificate(evaluation.id)
                    .then((response) => {
                      browserDownloadFileResponse(response.data, fileName);
                    })
                    .finally(() => {
                      setIsLoading(false);
                    });
                }}
              />
            )}
          </Menu>
        }
      >
        <RelativityTooltip
          shouldShowTooltip={shouldDisableDownload}
          compact={true}
          className="certificate-tooltip-container"
          content={
            <div className="certificate-tooltip">
              Available for download after Certificate Payment has been
              successfully processed
            </div>
          }
        >
          <Button
            loading={isLoading}
            intent={shouldDisableDownload ? Intent.NONE : Intent.SUCCESS}
            rightIcon={IconNames.CARET_DOWN}
            icon={IconNames.DOCUMENT}
            hidden={!isCertificateButtonDisplayed}
            disabled={shouldDisableDownload}
          >
            Certificate
          </Button>
        </RelativityTooltip>
      </Popover2>
    </>
  );
};

export default DownloadCertificateActionButtonV2;
