import { PrimaryCard, SecondaryCard } from "@ucl/library";
import { FieldSchemaFactoryProvider } from "../../../../../common/customHooks/useFCFMFFieldSchemaFactory";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import { Navigate } from "react-router-dom";
import { FCFMFApplicationFormParts } from "../../../../../common/types/Evaluation/BaseFCFMFApplicationForm";
import FormChecklistProgress from "../../../../../../Common/components/Forms/FormChecklistProgress/FormChecklistProgress";
import CommercialApplicationForm from "../Form/CommercialApplicationForm";
import useCommercialApplicationFormContainer from "./useCommercialApplicationFormContainer";
import { commercialRoutePrefix } from "../../../../../../../foritfied/pages/index/fortifiedCommercialMultifamilyRouteConfig";
import { FCFMFPricingInformationBanner } from "../../../../../common/components/Banners/FCFMFPricingBanner";

export interface CommercialApplicationFormContainerProps {
  evaluationId: string;
  applicationFormPart?: FCFMFApplicationFormParts;
}

export const CommercialApplicationFormContainer = (
  props: CommercialApplicationFormContainerProps
) => {
  const {
    applicationChecklistParts,
    getApplicationPartChecklistStatus,
    setEvaluationFormModel,
    setHasUnsavedChanges,
    applicationFormPart,
    getBestFormPart,
    commercialSchemaFactory,
    refreshParentData,
  } = useCommercialApplicationFormContainer(props);

  return (
    <>
      {!applicationFormPart ||
      !Object.values(FCFMFApplicationFormParts).includes(
        applicationFormPart
      ) ? (
        // TODO: Show error page if the application form part is not valid
        <Navigate
          to={`${commercialRoutePrefix}/${
            props.evaluationId
          }/application-form/${getBestFormPart().valueOf()}`}
        />
      ) : !commercialSchemaFactory ? (
        <Loading />
      ) : (
        <div className="wildfire-form-page">
          <div className="wildfire-form-progress">
            <div className="wildfire-form-progress__steps">
              <FormChecklistProgress
                checklistParts={applicationChecklistParts}
                evaluationId={props.evaluationId}
                formURL={`${commercialRoutePrefix}/${props.evaluationId}/application-form`}
                title={"Application Form"}
                activeFormPart={applicationFormPart}
                getChecklistStatus={getApplicationPartChecklistStatus}
              />
            </div>
          </div>
          <section className="wildfire-form-content">
            <div className="wildfire-form-content__header"></div>
            <PrimaryCard
              className="wildfire-form-primary-card"
              headerContent={
                <div className="wildfire-form-primary-card__header">
                  Commercial Application Form:{" "}
                  {
                    applicationChecklistParts.find(
                      (value) => value.formPart === applicationFormPart
                    )?.displayName
                  }
                </div>
              }
            >
              {applicationFormPart ===
                FCFMFApplicationFormParts.ConfirmationFields && (
                <FCFMFPricingInformationBanner />
              )}
              <SecondaryCard
                className="wildfire-form-secondary-card"
                hideBody={false}
                isCollapsible={false}
              >
                <FieldSchemaFactoryProvider
                  schemaFactory={commercialSchemaFactory}
                >
                  <CommercialApplicationForm
                    key={applicationFormPart}
                    evaluationId={props.evaluationId}
                    isIterationEnginePage={false}
                    formPart={applicationFormPart}
                    disableForm={false}
                    setFormModel={setEvaluationFormModel}
                    setHasUnsavedChanges={setHasUnsavedChanges}
                    formChecklistParts={applicationChecklistParts}
                    onParentNeedsRefresh={refreshParentData}
                  />
                </FieldSchemaFactoryProvider>
              </SecondaryCard>
            </PrimaryCard>
          </section>
        </div>
      )}
    </>
  );
};
export default CommercialApplicationFormContainer;
