import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import baseExternalApiClient from "../../../../../foritfied/lib/apiClients/baseExternalApiClient";
import { BaseHomeEvaluationEngineerConfirmationFormParts } from "../../../common/types/BaseHomeEvaluationEngineerConfirmationFormModel";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../types/HomeEvaluationFormModel";
import { HomeStandardEvaluationEngineerConfirmationFormModel } from "../../types/HomeStandardEvaluationEngineerConfirmationFormModel";

class HomeStandardEvaluationEngineerConfirmationAPIClient {
  updateHomeStandardEngineerConfirmationForm = async (
    evaluationId: string,
    engineerConfirmationForm: HomeStandardEvaluationEngineerConfirmationFormModel,
    fieldKey?: string
  ): Promise<Evaluation_HomeStandardEvaluationFormModel> => {
    return baseApiClient.put<Evaluation_HomeStandardEvaluationFormModel>(
      `/home-standard/engineer-confirmation-form/${evaluationId}`,
      {
        engineerConfirmationForm,
        fieldKey,
      }
    );
  };

  updateHomeStandardEngineerConfirmationFormExternal = async (
    evaluationId: string,
    engineerConfirmationForm: HomeStandardEvaluationEngineerConfirmationFormModel,
    accessToken: string
  ): Promise<Evaluation_HomeStandardEvaluationFormModel> => {
    return baseExternalApiClient.put<Evaluation_HomeStandardEvaluationFormModel>(
      `/home-standard/engineer-confirmation-form/external/${evaluationId}`,
      {
        engineerConfirmationForm,
      },
      {
        headers: {
          AccessToken: accessToken,
        },
      }
    );
  };

  submitHomeStandardEngineerConfirmationForm = async (
    evaluationId: string,
    engineerConfirmationForm: HomeStandardEvaluationEngineerConfirmationFormModel,
    formPart?: BaseHomeEvaluationEngineerConfirmationFormParts
  ): Promise<Evaluation_HomeStandardEvaluationFormModel> => {
    // If applicationFormPart is not provided, default to the last part of the form
    const formPartValue = (
      formPart ??
      (Object.values(
        BaseHomeEvaluationEngineerConfirmationFormParts
      ).pop() as BaseHomeEvaluationEngineerConfirmationFormParts)
    ).valueOf();
    return baseApiClient.post<Evaluation_HomeStandardEvaluationFormModel>(
      `/home-standard/engineer-confirmation-form/${evaluationId}/${formPartValue}`,
      engineerConfirmationForm
    );
  };

  submitHomeStandardEngineerConfirmationFormExternal = async (
    evaluationId: string,
    engineerConfirmationForm: HomeStandardEvaluationEngineerConfirmationFormModel,
    accessToken: string
  ): Promise<Evaluation_HomeStandardEvaluationFormModel> => {
    // Always default to the last part of the form
    const formPartValue = (
      Object.values(
        BaseHomeEvaluationEngineerConfirmationFormParts
      ).pop() as BaseHomeEvaluationEngineerConfirmationFormParts
    ).valueOf();
    return baseExternalApiClient.post<Evaluation_HomeStandardEvaluationFormModel>(
      `/home-standard/engineer-confirmation-form/external/${evaluationId}/${formPartValue}`,
      engineerConfirmationForm,
      {
        headers: {
          AccessToken: accessToken,
        },
      }
    );
  };
}

export const homeStandardEvaluationEngineerConfirmationAPIClient =
  new HomeStandardEvaluationEngineerConfirmationAPIClient();
