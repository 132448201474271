import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { FC, useState } from "react";
import { evaluationApiClient } from "../../../lib/apiClients/evaluation/evaluationApiClient";
import { useNavigate } from "react-router";
import { useProductTypeHandler } from "../../../providers/ProductTypeHandlerProvider";
import { AppToaster } from "@ucl/library/lib/components/Toast/Toast";
import { RelativityTooltip } from "@ucl/library";
import { dialogsViewerStore } from "../../../stores/DialogsViewerStore";

interface TraineeSendForReviewActionButtonProps {
  evaluationId: string;
  disabled: boolean;
}

const TraineeSendForReviewActionButton: FC<
  TraineeSendForReviewActionButtonProps
> = ({ evaluationId, disabled }) => {
  const navigate = useNavigate();
  const { routePrefix } = useProductTypeHandler();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const sendToIBHSForReview = async () => {
    setIsLoading(true);
    try {
      await evaluationApiClient.sendTraineeAuditForReview(evaluationId);
      AppToaster.show({
        intent: Intent.SUCCESS,
        message: "Sucessfully submitted Trainee Audit for review",
        timeout: 5000,
      });
      // Navigate back to board
      navigate(`${routePrefix}`);
    } catch (err) {
      console.error(err);
      AppToaster.show({
        intent: Intent.DANGER,
        message:
          "Unexpected error occured while submitting Trainee Audit for review",
        timeout: 5000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <RelativityTooltip
      shouldShowTooltip={disabled}
      compact={true}
      className="trainee-send-for-review-tooltip-container"
      content={
        <div className="trainee-send-for-review-tooltip">
          This Evaluation has already been submitted for review.
        </div>
      }
    >
      <Button
        minimal
        intent={Intent.PRIMARY}
        icon={IconNames.KEY_ENTER}
        text="Send to IBHS for Review"
        onClick={() => {
          dialogsViewerStore.setIsAuditorTraineeSendForReviewDialogOpen(true, {
            afterConfirm: sendToIBHSForReview,
          });
        }}
        loading={isLoading}
        disabled={disabled}
      />
    </RelativityTooltip>
  );
};

export default TraineeSendForReviewActionButton;
