import { FieldSchemaFormBuilderV2, SchemaFactoryV2 } from "@ucl/library";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { HomeFieldSchemaModel } from "./homeFieldSchemaConfig";

// Standard Builders
import { homeStandardIterationEngineBasicInformationFormBuilder } from "../../../standard/components/Forms/BasicInformationForm/HomeStandardBasicInformationFormBuilder";
import { rooferConfirmationFormIterationEngineBuilder } from "../../../standard/components/Forms/RooferConfirmationForm/HomeStandardRooferConfirmationFormBuilder";
import { homeStandardIterationEngineRoofingComplianceGeneralInformationFormBuilder } from "../../../standard/components/Forms/RoofingComplianceGeneralInformationForm/HomeStandardRoofingComplianceGeneralInformationFormBuilder";
import { homeStandardIterationEngineRoofingComplianceRoofCoverFormBuilder } from "../../../standard/components/Forms/RoofingComplianceRoofCoverForm/HomeStandardRoofingComplianceRoofCoverFormBuilder";
import { homeAuthorizationFormIterationEngineBuilder } from "../../../standardRedesignation/components/Forms/HomeownerAuthorizationForm/HomeStandardHomeownerAuthorizationFormBuilder";
import { engineerConfirmationFormIterationEngineBuilder } from "../../../standard/components/Forms/EngineerConfirmationForm/HomeStandardEngineerConfirmationFormBuilder";
import { silverGoldComplianceGeneralInformationFormIterationEngineBuilder } from "../../../standard/components/Forms/SilverGoldComplianceGeneralInformationForm/HomeStandardSilverGoldComplianceGeneralInformationFormBuilder";
import { installingContractorConfirmationFormIterationEngineBuilder } from "../../../standard/components/Forms/InstallingContractorConfirmationForm/HomeStandardInstallingContractorConfirmationFormBuilder";
import { homeStandardIterationEngineInstallerComplianceGablesFormBuilder } from "../../../standard/components/Forms/InstallerComplianceGablesForm/HomeStandardInstallerComplianceGablesFormBuilder";
import { homeStandardIterationEngineInstallerComplianceContinuousLoadPathFormBuilder } from "../../../standard/components/Forms/InstallerComplianceContinuousLoadPathForm/HomeStandardInstallerComplianceContinuousLoadPathFormBuilder";
import { homeStandardIterationEngineInstallerComplianceChimneyFormBuilder } from "../../../standard/components/Forms/InstallerComplianceChimneyForm/HomeStandardInstallerComplianceChimneyFormBuilder";
import { homeStandardIterationEngineInstallerComplianceAttachedStructureFormBuilder } from "../../../standard/components/Forms/InstallerComplianceAttachedStructureForm/HomeStandardInstallerComplianceAttachedStructureFormBuilder";
import { engineeringComplianceGeneralInformationFormIterationEngineBuilder } from "../../../standard/components/Forms/EngineeringComplianceGeneralInformationForm/HomeStandardEngineeringComplianceGeneralInformationFormBuilder";
import { engineeringComplianceGablesFormIterationEngineBuilder } from "../../../standard/components/Forms/EngineeringComplianceGablesForm/HomeStandardEngineeringComplianceGablesFormBuilder";
import { homeStandardIterationEngineEngineeringComplianceContinuousLoadPathFormBuilder } from "../../../standard/components/Forms/EngineeringComplianceContinuousLoadPathForm/HomeStandardEngineeringComplianceContinuousLoadPathFormBuilder";
import { homeStandardIterationEngineEngineeringComplianceChimneyFormBuilder } from "../../../standard/components/Forms/EngineeringComplianceChimneyForm/HomeStandardEngineeringComplianceChimneyFormBuilder";
import { homeStandardIterationEngineEngineeringComplianceAttachedStructureFormBuilder } from "../../../standard/components/Forms/EngineeringComplianceAttachedStructureForm/HomeStandardEngineeringComplianceAttachedStructureFormBuilder";

// SCSH Builders
import { homeSCSHIterationEngineBasicInformationFormBuilder } from "../../../scsh/components/Forms/BasicInformationForm/HomeSCSHBasicInformationFormBuilder";
import { homeSCSHIterationEngineEvaluationFormBuilder } from "../../../scsh/components/Forms/SCSHEvaluationForm/HomeSCSHEvaluationFormBuilder";

// NCIUA Builders
import { homeNCIUAIterationEngineEvaluationFormBuilder } from "../../../nciua/components/Forms/NCIUAEvaluationForm/HomeNCIUAEvaluationFormBuilder";
import { homeNCIUAIterationEngineRoofingComplianceRoofCoverFormBuilder } from "../../../nciua/components/Forms/RoofingComplianceRoofCoverForm/HomeNCIUARoofingComplianceRoofCoverFormBuilder";
import { homeNCIUAIterationEngineRoofingComplianceGeneralInformationFormBuilder } from "../../../nciua/components/Forms/RoofingComplianceGeneralInformationForm/HomeNCIUARoofingComplianceGeneralInformationFormBuilder";
import { rooferConfirmationNCIUAFormIterationEngineBuilder } from "../../../nciua/components/Forms/RooferConfirmationForm/HomeNCIUARooferConfirmationFormBuilder";
import { homeAuthorizationFormIterationEngineBuilderNCUIARedesignation } from "../../../nciuaRedesignation/components/Forms/HomeownerAuthorizationForm/HomeNCIUARedesignationHomeownerAuthorizationFormBuilder";
import { homeNCIUARedesignationFormIterationEngineBuilder } from "../../../nciuaRedesignation/components/Forms/RedesignationForm/HomeNCIUARedesignationFormBuilder";
import { homeStandardIterationEngineEligibilityFormBuilder } from "../../../standard/components/Forms/EligibilityForm/HomeStandardEligibilityFormBuilder";
import { homeStandardRedesignationFormIterationEngineBuilder } from "../../../standardRedesignation/components/Forms/RedesignationForm/HomeStandardRedesignationFormBuilder";
import { homeNCIUAIterationEngineEligibilityFormBuilder } from "../../../nciua/components/Forms/EligibilityForm/HomeNCIUAEligibilityFormBuilder";
import { homeNCIUAIterationEngineBasicInformationFormBuilder } from "../../../nciua/components/Forms/BasicInformationForm/HomeNCIUABasicInformationFormBuilder";
import { homeStandardRedesignationIterationEngineRoofingComplianceGeneralInformationFormBuilder } from "../../../standardRedesignation/components/Forms/RoofingComplianceGeneralInformationForm/HomeStandardRedesignationRoofingComplianceGeneralInformationFormBuilder";
import { homeStandardRedesignationIterationEngineRoofingComplianceRoofCoverFormBuilder } from "../../../standardRedesignation/components/Forms/RoofingComplianceRoofCoverForm/HomeStandardRedesignationRoofingComplianceRoofCoverFormBuilder";
import { homeStandardRedesignationRooferConfirmationFormIterationEngineBuilder } from "../../../standardRedesignation/components/Forms/RooferConfirmationForm/HomeStandardRedesignationRooferConfirmationFormBuilder";

export type HomeIterationEngineFieldSchemaFormType =
  typeof HomeIterationEngineFieldSchemaFormTypeList[number];

export const HomeIterationEngineFieldSchemaFormTypeList = [
  "HomeStandardIterationEngineBasicInformationForm",
  "HomeStandardIterationEngineEligibilityForm",
  "HomeSCSHIterationEngineBasicInformationForm",
  "HomeSCSHIterationEngineEvaluationForm",
  "HomeStandardIterationEngineRooferConfirmationForm",
  "HomeStandardIterationEngineRoofingComplianceGeneralInformationForm",
  "HomeStandardIterationEngineRoofingComplianceRoofCoverForm",
  "HomeStandardIterationEngineHomeownerAuthorizationForm",
  "HomeStandardIterationEngineEngineerConfirmationForm",
  "HomeStandardIterationEngineSilverGoldComplianceGeneralInformationForm",
  "HomeStandardIterationEngineInstallingContractorConfirmationForm",
  "HomeStandardIterationEngineInstallerComplianceGablesForm",
  "HomeStandardIterationEngineInstallerComplianceContinuousLoadPathForm",
  "HomeStandardIterationEngineInstallerComplianceChimneyForm",
  "HomeStandardIterationEngineInstallerComplianceAttachedStructureForm",
  "HomeStandardIterationEngineEngineeringComplianceGeneralInformationForm",
  "HomeStandardIterationEngineEngineeringComplianceGablesForm",
  "HomeStandardIterationEngineEngineeringComplianceContinuousLoadPathForm",
  "HomeStandardIterationEngineEngineeringComplianceChimneyForm",
  "HomeStandardIterationEngineEngineeringComplianceAttachedStructureForm",
  "HomeStandardRedesignationIterationEngineForm",
  "HomeStandardRedesignationIterationEngineRoofingComplianceGeneralInformationForm",
  "HomeStandardRedesignationIterationEngineRoofingComplianceRoofCoverForm",
  "HomeStandardRedesignationIterationEngineRooferConfirmationForm",
  "HomeNCIUAIterationEngineEvaluationForm",
  "HomeNCIUAIterationEngineRoofingComplianceRoofCoverForm",
  "HomeNCIUAIterationEngineRoofingComplianceGeneralInformationForm",
  "HomeNCIUAIterationEngineRooferConfirmationForm",
  "HomeNCUIARedesignationIterationEngineHomeownerAuthorizationForm",
  "HomeNCIUARedesignationIterationEngineForm",
  "HomeNCIUAIterationEngineEligibilityForm",
  "HomeNCIUAIterationEngineBasicInformationForm",
] as const;

export type HomeIterationEngineFieldSchemaFormBuildersConfig = Record<
  HomeIterationEngineFieldSchemaFormType,
  (
    factory: SchemaFactoryV2,
    beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
  ) => Partial<FieldSchemaFormBuilderV2<HomeFieldSchemaModel>>
>;

const HomeStandardBuilders = {
  HomeStandardIterationEngineBasicInformationForm:
    homeStandardIterationEngineBasicInformationFormBuilder,
  HomeStandardIterationEngineEligibilityForm:
    homeStandardIterationEngineEligibilityFormBuilder,
  HomeStandardIterationEngineRooferConfirmationForm:
    rooferConfirmationFormIterationEngineBuilder,
  HomeStandardIterationEngineRoofingComplianceGeneralInformationForm:
    homeStandardIterationEngineRoofingComplianceGeneralInformationFormBuilder,
  HomeStandardIterationEngineRoofingComplianceRoofCoverForm:
    homeStandardIterationEngineRoofingComplianceRoofCoverFormBuilder,
  HomeStandardIterationEngineHomeownerAuthorizationForm:
    homeAuthorizationFormIterationEngineBuilder,
  HomeStandardIterationEngineEngineerConfirmationForm:
    engineerConfirmationFormIterationEngineBuilder,
  HomeStandardIterationEngineSilverGoldComplianceGeneralInformationForm:
    silverGoldComplianceGeneralInformationFormIterationEngineBuilder,
  HomeStandardIterationEngineInstallingContractorConfirmationForm:
    installingContractorConfirmationFormIterationEngineBuilder,
  HomeStandardIterationEngineInstallerComplianceGablesForm:
    homeStandardIterationEngineInstallerComplianceGablesFormBuilder,
  HomeStandardIterationEngineInstallerComplianceContinuousLoadPathForm:
    homeStandardIterationEngineInstallerComplianceContinuousLoadPathFormBuilder,
  HomeStandardIterationEngineInstallerComplianceChimneyForm:
    homeStandardIterationEngineInstallerComplianceChimneyFormBuilder,
  HomeStandardIterationEngineInstallerComplianceAttachedStructureForm:
    homeStandardIterationEngineInstallerComplianceAttachedStructureFormBuilder,
  HomeStandardIterationEngineEngineeringComplianceGeneralInformationForm:
    engineeringComplianceGeneralInformationFormIterationEngineBuilder,
  HomeStandardIterationEngineEngineeringComplianceGablesForm:
    engineeringComplianceGablesFormIterationEngineBuilder,
  HomeStandardIterationEngineEngineeringComplianceContinuousLoadPathForm:
    homeStandardIterationEngineEngineeringComplianceContinuousLoadPathFormBuilder,
  HomeStandardIterationEngineEngineeringComplianceChimneyForm:
    homeStandardIterationEngineEngineeringComplianceChimneyFormBuilder,
  HomeStandardIterationEngineEngineeringComplianceAttachedStructureForm:
    homeStandardIterationEngineEngineeringComplianceAttachedStructureFormBuilder,
  HomeStandardRedesignationIterationEngineForm:
    homeStandardRedesignationFormIterationEngineBuilder,
  HomeStandardRedesignationIterationEngineRoofingComplianceGeneralInformationForm:
    homeStandardRedesignationIterationEngineRoofingComplianceGeneralInformationFormBuilder,
  HomeStandardRedesignationIterationEngineRoofingComplianceRoofCoverForm:
    homeStandardRedesignationIterationEngineRoofingComplianceRoofCoverFormBuilder,
  HomeStandardRedesignationIterationEngineRooferConfirmationForm:
    homeStandardRedesignationRooferConfirmationFormIterationEngineBuilder,
};

const HomeSCSHBuilders = {
  HomeSCSHIterationEngineBasicInformationForm:
    homeSCSHIterationEngineBasicInformationFormBuilder,
  HomeSCSHIterationEngineEvaluationForm:
    homeSCSHIterationEngineEvaluationFormBuilder,
};

const HomeNCIUABuilders = {
  HomeNCIUAIterationEngineEvaluationForm:
    homeNCIUAIterationEngineEvaluationFormBuilder,
  HomeNCIUAIterationEngineRoofingComplianceRoofCoverForm:
    homeNCIUAIterationEngineRoofingComplianceRoofCoverFormBuilder,
  HomeNCIUAIterationEngineRoofingComplianceGeneralInformationForm:
    homeNCIUAIterationEngineRoofingComplianceGeneralInformationFormBuilder,
  HomeNCIUAIterationEngineRooferConfirmationForm:
    rooferConfirmationNCIUAFormIterationEngineBuilder,
  HomeNCUIARedesignationIterationEngineHomeownerAuthorizationForm:
    homeAuthorizationFormIterationEngineBuilderNCUIARedesignation,
  HomeNCIUARedesignationIterationEngineForm:
    homeNCIUARedesignationFormIterationEngineBuilder,
  HomeNCIUAIterationEngineEligibilityForm:
    homeNCIUAIterationEngineEligibilityFormBuilder,
  HomeNCIUAIterationEngineBasicInformationForm:
    homeNCIUAIterationEngineBasicInformationFormBuilder,
};

export const homeIterationEngineFieldSchemaFormBuilders: HomeIterationEngineFieldSchemaFormBuildersConfig =
  {
    ...HomeStandardBuilders,
    ...HomeSCSHBuilders,
    ...HomeNCIUABuilders,
  };
