import { NotifySubmitterFormLink } from "../../../../../../foritfied/components/Checklist/NotifySubmitter/NotifySubmitterLinkBuilder";

const nextParagraph = "\n\n";

export const buildLinkV2 = (
  mailTo: string,
  address: string,
  fortifiedId: string,
  formLinks: NotifySubmitterFormLink[],
  accessToken: string
) => {
  return `mailto:${mailTo}?${buildLinkSubjectV2(
    address,
    fortifiedId
  )}&${buildLinkBodyV2(address, fortifiedId, formLinks, accessToken)}`;
};

const buildLinkSubjectV2 = (address: string, fortifiedId: string) =>
  `subject=FORTIFIED Compliance Form(s) for ${address} - ${fortifiedId}`;

const buildLinkBodyV2 = (
  address: string,
  fortifiedId: string,
  formLinks: NotifySubmitterFormLink[],
  accessToken: string
) => {
  let body = "Hello,";
  body += nextParagraph;
  body += `Please complete the following form(s) for ${address} - ${fortifiedId} as soon as possible.`;
  body += nextParagraph;
  body += formLinks
    .map(
      (formLink) => `${formLink.name} - ${formLink.link}?token=${accessToken}`
    )
    .join(nextParagraph);
  body += nextParagraph;
  body += "Thank you!";

  return `body=${encodeURIComponent(body)}`;
};
