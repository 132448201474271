import { ExistingResource } from "./ExistingResource";

export interface NewEvaluationBid {
  id?: string;
  evaluationCompany: EvaluationBidCompanyTypes;
  evaluationCompany_AsString: string;
  hasBidBeenResent?: boolean;
}
export type EvaluationBid = ExistingResource<NewEvaluationBid>;

export enum EvaluationBidCompanyTypes {
  // Paragon = 1,
  GRC = 2,
  AEI = 4,
}

export const evaluationCompanyDefaults: Record<
  EvaluationBidCompanyTypes,
  NewEvaluationBid
> = {
  // [EvaluationCompanyTypes.Paragon]: {
  //   evaluationCompany: EvaluationCompanyTypes.Paragon,
  //   evaluationCompany_AsString: "Paragon",
  // },
  [EvaluationBidCompanyTypes.GRC]: {
    evaluationCompany: EvaluationBidCompanyTypes.GRC,
    evaluationCompany_AsString: "GRC",
  },
  [EvaluationBidCompanyTypes.AEI]: {
    evaluationCompany: EvaluationBidCompanyTypes.AEI,
    evaluationCompany_AsString: "AEI",
  },
};
