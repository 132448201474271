import { PrimaryCard, SecondaryCard } from "@ucl/library";
import { ApplicationFormDashboardNavigationButton } from "../../components/Button/DashboardNavigationButton/ApplicationFormDashboardNavigationButton";
import ApplicationForm from "../../components/Forms/ApplicationForm/ApplicationForm";
import "./styles.scss";
import { ApplicationFormParts } from "../../forms/ApplicationForm/ApplicationFormModel";
import { Navigate, useNavigate } from "react-router";
import { Button, Icon, Intent, Tag } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { wildfireEvaluationApiClient } from "../../lib/apiClients/evaluation/wildfireEvaluationApiClient";
import { useContext, useEffect, useRef, useState } from "react";
import {
  errorStore,
  genericErrorMessage,
} from "../../../common/Components/Error/ErrorStore";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import { EligibilityBanner } from "../../components/Forms/FormSections/InformationalBanner/InformationalBanners/EligibilityBanner/EligibilityBanner";
import { ServiceOptionsDisplay } from "../../components/Forms/FormSections/ServiceOptionsDisplay/ServiceOptionsDisplay";
import { PhotoRequirementsBanner } from "../../components/Forms/FormSections/InformationalBanner/InformationalBanners/PhotoRequirementsBanner";
import {
  FieldSchemaFactoryProvider,
  WildfireSchemaFactoryV2,
} from "../../customHooks/useFieldSchemaFactory";
import {
  FormChecklistPart,
  FormChecklistStatus,
} from "../../../common/types/Forms/FormChecklist";
import { ServiceTypes } from "../../types/FormFields";
import { AddressFieldValue } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { debounce } from "lodash";
import { EvaluationFormModel } from "../../forms/EvaluationFormModel";
import useWildfirePermissons from "../../customHooks/useWildfirePermissons";
import { wildfireRoutePrefix } from "../index/wildfireRouteConfig";
import {
  MessageComponent,
  Severity,
} from "@syncfusion/ej2-react-notifications";
import { AppHeaderContext } from "../../components/Provider/AppHeaderContext";
import { UnsavedChangesBlockerContext } from "../../components/Provider/UnsavedChangesBlockerContext";
import { WildfireEvaluationSteps } from "../../../foritfied/types/evaluation/Evaluation";
import DuplicateAddressWarning from "../../../common/Components/Banners/DuplicateAddressWarning/DuplicateAddressWarning";
import { wildfireFieldSchemaApiClient } from "../../lib/apiClients/wildfireFieldSchemaApiClient";

interface ApplicationFormPageProps {
  wildfireEvaluationId: string;
  applicationFormPart?: ApplicationFormParts;
}

export const ApplicationFormPage = (props: ApplicationFormPageProps) => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  const [wildfireSchemaFactory, setWildfireSchemaFactory] =
    useState<WildfireSchemaFactoryV2>();

  const getSchema = async () => {
    const schema = await wildfireFieldSchemaApiClient.getSchema();
    const newSchemaFactory = new WildfireSchemaFactoryV2(schema);
    setWildfireSchemaFactory(newSchemaFactory);
  };

  useEffect(() => {
    if (!wildfireSchemaFactory) {
      getSchema();
    }
  }, []);

  const { setWildfireEvaluationHeader, setDefaultHeader } =
    useContext(AppHeaderContext);

  const [applicationFormPart, setApplicationFormPart] = useState<
    ApplicationFormParts | undefined
  >(props.applicationFormPart);

  const [evaluationFormModel, setEvaluationFormModel] =
    useState<EvaluationFormModel>();

  const applicationForm =
    evaluationFormModel?.wildfireApplication.applicationForm;

  const [hasDuplicateAddress, setHasDuplicateAddress] = useState(false);

  const checkForDuplicateAddress = useRef(
    debounce(async (address: AddressFieldValue) => {
      // Avoid sending a request if the address is incomplete
      if (!address.street || !address.city || !address.state) {
        return;
      }

      await wildfireEvaluationApiClient
        .duplicateAddressCheck(props.wildfireEvaluationId, address)
        .then((response) => {
          setHasDuplicateAddress(response);
        });
    }, 2000)
  ).current;

  useEffect(() => {
    return () => {
      setDefaultHeader();
    };
  }, []);

  // Cancel the debounce when the component is unmounted
  useEffect(() => {
    return () => {
      checkForDuplicateAddress.cancel();
    };
  }, [checkForDuplicateAddress]);

  const onFieldChange = async (fieldKey: string, value: any) => {
    if (
      fieldKey ===
      "Evaluation__WildfireApplication__ApplicationForm__StreetAddress"
    ) {
      checkForDuplicateAddress(value);
    }
  };

  const fetchApplicationForm = async () => {
    await wildfireEvaluationApiClient
      .getWildfireEvaluationApplicationForm(props.wildfireEvaluationId)
      .then((response) => {
        setEvaluationFormModel(response);
        setWildfireEvaluationHeader(response);
      })
      .catch(() => {
        errorStore.setErrorMessage(genericErrorMessage);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getNextUnsubmittedFormPart = () => {
    if (!applicationForm) {
      return null;
    }

    if (!applicationForm.propertyInformationSubmitted) {
      return ApplicationFormParts.PropertyInformation;
    } else if (!applicationForm.serviceOptionsSubmitted) {
      return ApplicationFormParts.ServiceOptions;
    } else if (!applicationForm.eligibilitySubmitted) {
      return ApplicationFormParts.Eligibility;
    } else if (
      applicationForm.eligibilitySubmitted &&
      !applicationForm.hasPaymentBeenSubmitted
    ) {
      return ApplicationFormParts.Payment;
    } else if (
      !applicationForm.photoRequirementsSubmitted ||
      !applicationForm.submitted
    ) {
      return ApplicationFormParts.PhotoRequirements;
    }

    // Form is complete
    return null;
  };

  const getBestFormPart = (): ApplicationFormParts => {
    // Default to the first part of the form if we can't find a better option
    const defaultFormPart = Object.values(ApplicationFormParts)[0];

    // If the application form part is provided (by URL), use it
    if (!!props.applicationFormPart) {
      return props.applicationFormPart;
    }

    // Otherwise, try to find the first unsubmitted part of the form
    // TODO: This doesn't work yet, needs to wait for applicationFormModel to load
    return getNextUnsubmittedFormPart() ?? defaultFormPart;
  };

  useEffect(() => {
    setApplicationFormPart(getBestFormPart());
    fetchApplicationForm();
  }, [props.applicationFormPart]);

  const getApplicationPartChecklistStatus = (
    formPart: ApplicationFormParts
  ): FormChecklistStatus => {
    if (formPart === getNextUnsubmittedFormPart()) {
      return FormChecklistStatus.NextUp;
    }

    switch (formPart) {
      case ApplicationFormParts.PropertyInformation:
        return applicationForm?.propertyInformationSubmitted
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case ApplicationFormParts.ServiceOptions:
        return applicationForm?.serviceOptionsSubmitted
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case ApplicationFormParts.Eligibility:
        return applicationForm?.eligibilitySubmitted
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case ApplicationFormParts.Payment:
        return applicationForm?.hasPaymentBeenSubmitted
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case ApplicationFormParts.PhotoRequirements:
        return applicationForm?.photoRequirementsSubmitted
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
    }
  };

  const applicationChecklistParts: FormChecklistPart<ApplicationFormParts>[] = [
    {
      formPart: ApplicationFormParts.PropertyInformation,
      displayName: "Application",
      requiredFieldCount:
        applicationForm?.propertyInformationRequiredFieldCount,
    },
    {
      formPart: ApplicationFormParts.ServiceOptions,
      displayName: "Service Options",
      requiredFieldCount: applicationForm?.serviceOptionsRequiredFieldCount,
      disabled: applicationForm?.propertyInformationSubmitted === false,
    },
    {
      formPart: ApplicationFormParts.Eligibility,
      displayName: "Acknowledge Steps",
      requiredFieldCount: applicationForm?.eligibilityRequiredFieldCount,
      disabled:
        applicationForm?.serviceType === ServiceTypes.FullService ||
        applicationForm?.serviceOptionsSubmitted === false,
    },
    {
      formPart: ApplicationFormParts.Payment,
      displayName: "Payment",
      disabled: true,
    },
    {
      formPart: ApplicationFormParts.PhotoRequirements,
      displayName: "Upload Eligibility Photos",
      requiredFieldCount: applicationForm?.photoRequirementsRequiredFieldCount,
      disabled:
        applicationForm?.serviceType === ServiceTypes.FullService ||
        applicationForm?.eligibilitySubmitted === false ||
        evaluationFormModel?.wildfireApplication.step ===
          WildfireEvaluationSteps.WildfireApplication ||
        evaluationFormModel?.wildfireApplication.step ===
          WildfireEvaluationSteps.WildfireApplicationPayment,
    },
  ];

  const canBeSaved =
    !!evaluationFormModel?.wildfireApplication.applicationForm.streetAddress
      ?.street &&
    !!evaluationFormModel?.wildfireApplication.applicationForm.streetAddress
      ?.state;

  const { canEditForm } = useWildfirePermissons(
    evaluationFormModel?.wildfireApplication.step,
    evaluationFormModel?.status_AsString
  );

  const { setHasUnsavedChanges } = useContext(UnsavedChangesBlockerContext);

  return (
    <>
      {!applicationFormPart ||
      !Object.values(ApplicationFormParts).includes(applicationFormPart) ? (
        // TODO: Show error page if the application form part is not valid
        <Navigate
          to={`${wildfireRoutePrefix}/${
            props.wildfireEvaluationId
          }/application-form/${getBestFormPart().valueOf()}`}
        />
      ) : isLoading || !wildfireSchemaFactory ? (
        <Loading />
      ) : (
        <div className="wildfire-form-page">
          <div className="wildfire-form-progress">
            <ApplicationFormDashboardNavigationButton
              wildfireApplicationId={props.wildfireEvaluationId}
              canBeSaved={canBeSaved}
              onApplicationIncompleteDialogOpened={() =>
                setHasUnsavedChanges(false)
              }
            />

            <div className="wildfire-form-progress__steps">
              {applicationChecklistParts.map((checklistPart, index) => {
                const formPartValue = checklistPart.formPart.valueOf();
                return (
                  <div key={formPartValue}>
                    <div className="wildfire-form-progress__steps__item">
                      <div
                        className={"wildfire-form-progress__steps__item__page"}
                      >
                        <Icon
                          icon={(() => {
                            switch (
                              getApplicationPartChecklistStatus(
                                checklistPart.formPart
                              )
                            ) {
                              case FormChecklistStatus.Unsubmitted:
                                return IconNames.CIRCLE;
                              case FormChecklistStatus.NextUp:
                                return IconNames.RING;
                              case FormChecklistStatus.Submitted:
                                return IconNames.TICK_CIRCLE;
                              default:
                                return IconNames.ERROR;
                            }
                          })()}
                        />
                        <Button
                          minimal
                          intent={Intent.PRIMARY}
                          active={
                            applicationFormPart === checklistPart.formPart
                          }
                          text={checklistPart.displayName}
                          onClick={() => {
                            navigate(
                              `${wildfireRoutePrefix}/${props.wildfireEvaluationId}/application-form/${formPartValue}`
                            );
                          }}
                          disabled={checklistPart.disabled}
                        />
                      </div>
                      {!checklistPart.disabled &&
                        !!checklistPart.requiredFieldCount &&
                        checklistPart.requiredFieldCount > 0 && (
                          <div className="wildfire-form-progress__steps__item__badge">
                            <Tag large={true} round={true}>
                              {checklistPart.requiredFieldCount}
                            </Tag>
                          </div>
                        )}
                    </div>
                    {index !== applicationChecklistParts.length - 1 && (
                      <div className="vertical-line" />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <section className="wildfire-form-content">
            <div className="wildfire-form-content__header">
              <ApplicationFormDashboardNavigationButton
                wildfireApplicationId={props.wildfireEvaluationId}
                canBeSaved={canBeSaved}
                onApplicationIncompleteDialogOpened={() =>
                  setHasUnsavedChanges(false)
                }
              />
            </div>

            <PrimaryCard
              className="wildfire-form-primary-card"
              headerContent={
                <div className="wildfire-form-primary-card__header">
                  WFP Application Form:{" "}
                  {
                    applicationChecklistParts.find(
                      (value) => value.formPart === applicationFormPart
                    )?.displayName
                  }
                </div>
              }
            >
              <DuplicateAddressWarning
                hasDuplicateAddress={hasDuplicateAddress}
                warningText={
                  "There is already a Wildfire Prepared Home application for this address. Please ensure that you are not submitting a duplicate application."
                }
              />
              <SecondaryCard
                className="wildfire-form-secondary-card"
                hideBody={false}
                isCollapsible={false}
              >
                {applicationFormPart ===
                  ApplicationFormParts.ServiceOptions && (
                  <ServiceOptionsDisplay
                    canOnlyHaveSelfService={
                      evaluationFormModel?.wildfireApplication.applicationForm
                        .canOnlyHaveSelfService
                    }
                  />
                )}
                {applicationFormPart === ApplicationFormParts.Eligibility && (
                  <EligibilityBanner />
                )}
                {applicationFormPart ===
                  ApplicationFormParts.PhotoRequirements && (
                  <PhotoRequirementsBanner />
                )}
                <FieldSchemaFactoryProvider
                  schemaFactory={wildfireSchemaFactory}
                >
                  <ApplicationForm
                    key={applicationFormPart}
                    wildfireEvaluationId={props.wildfireEvaluationId}
                    formPart={applicationFormPart}
                    onFormSave={onFieldChange}
                    setFormModel={setEvaluationFormModel}
                    disableForm={!canEditForm}
                    hasDuplicateAddress={hasDuplicateAddress}
                    setHasUnsavedChanges={setHasUnsavedChanges}
                  />
                </FieldSchemaFactoryProvider>
                {applicationFormPart === ApplicationFormParts.ServiceOptions &&
                  evaluationFormModel?.wildfireApplication.applicationForm
                    .canOnlyHaveSelfService && (
                    <MessageComponent
                      className="message"
                      variant="Text"
                      content={
                        "Unable to select 'Full Service'? It means this service is currently unavailable in your area. Please click 'Continue' to proceed with Standard DIY Service."
                      }
                      severity={Severity.Warning}
                      showCloseIcon={true}
                    />
                  )}
              </SecondaryCard>
            </PrimaryCard>
          </section>
        </div>
      )}
    </>
  );
};
export default ApplicationFormPage;
