import { Tab, Tabs } from "@blueprintjs/core";
import EvaluationHistory from "../../../../../foritfied/components/Evaluation/History/EvaluationHistory";
import AdminPanelButton from "../../../common/components/Buttons/AdminPanelButton/AdminPanelButton";
import GoToIterationEngineButton from "../../../common/components/Buttons/GoToIterationEngine/GoToIterationEngineButton";
import { evaluationStore } from "../../../../../foritfied/stores/EvaluationStore";
import { permissionStore } from "../../../../../foritfied/stores/PermissionStore";
import LegacyEvaluationActionLink from "../../../../../foritfied/components/Evaluation/EvaluationActions/LegacyEvaluationActionLink";
import LegacyEvaluationDataActionLink from "../../../../../foritfied/components/Evaluation/EvaluationActions/LegacyEvaluationDataActionLink";
import ChecklistTabPanelV2, { FormRowProps } from "./ChecklistTabPanelV2";
import { UserRole } from "../../../../../foritfied/stores/FortifiedUserStore";
import { NotifySubmitterButton } from "../Buttons/NotifySubmitterButton/NotifySubmitterButton";
import WithdrawEvaluationActionButton from "../../../../../foritfied/components/Evaluation/EvaluationActions/WithdrawEvaluationActionButton";
import "./styles.scss";
import { EvaluationStep } from "../../../../../foritfied/types/evaluation/Evaluation";
import { ProceedToPaymentActionButtonV2 } from "../../../../Common/components/IterationEngine/ProceedToPaymentActionButtonV2";
import { ProductSubtypes } from "../../../../Common/types/Evaluation/BaseEvaluation";
import DownloadRooferConfirmationFormContainer from "../Buttons/DownloadRooferConfirmationForm/DownloadRooferConfirmationFormContainer";
import { NotifySubmitterFormLink } from "../../../../../foritfied/components/Checklist/NotifySubmitter/NotifySubmitterLinkBuilder";
import { PaymentCreditResponseReadDTO } from "../../../../Common/types/PaymentCredit/PaymentCreditResponseReadDTO";
import DownloadInstallerConfirmationFormContainer from "../Buttons/DownloadInstallerConfirmationForm/DownloadInstallerConfirmationFormContainer";
import DownloadEngineeringConfirmationFormContainer from "../Buttons/DownloadEngineeringConfirmationForm/DownloadEngineeringConfirmationFormContainer";
import { NonprofitPaymentBypassActionButton } from "../../../../Common/components/IterationEngine/NonprofitPaymentBypassActionButton";
import { AxiosResponse } from "axios";
import { AccessToken } from "../../../../../common/types/accessTokens";

interface ChecklistContainerProps {
  evaluationId: string;
  filteredForms: FormRowProps[];
  hasFormsLoading: boolean;
  setEvaluation: (evaluation: any) => void;
  fortifiedId: string;
  buildingAddress_AsString: string;
  selectedEmail?: string;
  selectedUserRoleFormLinks: NotifySubmitterFormLink[];
  selectedUserRoleAccessTokenMethod?: () => Promise<AccessToken>;
  evaluationStep: EvaluationStep;
  selectedUserRoleTab: ChecklistTabItem;
  isOnPaymentStep: boolean;
  isPaymentApplicable: boolean;
  paymentNextStepDisplayText: string;
  bypassPaymentStepForNonProfit: (evaluationId: string) => Promise<any>;
  visibleTabs: ChecklistTabItem[];
  selectedTabId: number;
  setSelectedTabId: (value: React.SetStateAction<number>) => void;
  productTypeRoute: string;
  getApplicationFeePaymentCreditDetails: (
    evaluationId: string
  ) => Promise<PaymentCreditResponseReadDTO>;
  productSubtype?: ProductSubtypes;
  canCompleteRooferConfirmation?: boolean;
  getRooferConfirmation?: () => Promise<AxiosResponse<unknown, any>>;
  canCompleteEngineerConfirmation?: boolean;
  canCompleteInstallingContractorConfirmation?: boolean;
}
export interface ChecklistTabItem {
  id: number;
  label: string;
  role?: UserRole;
}

const ChecklistContainerV2: React.FC<ChecklistContainerProps> = (props) => {
  return (
    <div className="checklist-container-v2">
      <div>
        <div className="checklist-header">
          <div className="checklist-header__left-panel">
            <GoToIterationEngineButton
              evaluationId={props.evaluationId}
              productTypeRoute={props.productTypeRoute}
            />
          </div>
          <div className="checklist-header__right-panel">
            {props.selectedEmail &&
              props.fortifiedId &&
              props.selectedUserRoleAccessTokenMethod && (
                <NotifySubmitterButton
                  mailTo={props.selectedEmail}
                  address={props.buildingAddress_AsString}
                  fortifiedId={props.fortifiedId}
                  formLinks={props.selectedUserRoleFormLinks.filter(
                    (x) => !x.hidden
                  )}
                  getAccessToken={props.selectedUserRoleAccessTokenMethod}
                />
              )}
            {evaluationStore.isLegacyEvaluation &&
              permissionStore.canViewLegacyEvaluationFiles && (
                <LegacyEvaluationActionLink />
              )}
            {evaluationStore.isLegacyEvaluation &&
              permissionStore.canViewLegacyEvaluationFiles && (
                <LegacyEvaluationDataActionLink />
              )}
            {permissionStore.canWithdraw(evaluationStore.evaluation) && (
              <WithdrawEvaluationActionButton />
            )}
            {props.filteredForms.some(
              (form) => form.formName === "Roofer Confirmation"
            ) &&
              props.canCompleteRooferConfirmation &&
              props.getRooferConfirmation && (
                <DownloadRooferConfirmationFormContainer
                  getRooferConfirmation={props.getRooferConfirmation}
                  fortifiedId={props.fortifiedId}
                />
              )}
            {props.filteredForms.some(
              (form) => form.formName === "Installing Contractor Confirmation"
            ) &&
              props.canCompleteInstallingContractorConfirmation && (
                <DownloadInstallerConfirmationFormContainer
                  evaluationId={props.evaluationId}
                  fortifiedId={props.fortifiedId}
                />
              )}
            {props.filteredForms.some(
              (form) => form.formName === "Engineer Confirmation"
            ) &&
              props.canCompleteEngineerConfirmation && (
                <DownloadEngineeringConfirmationFormContainer
                  evaluationId={props.evaluationId}
                  fortifiedId={props.fortifiedId}
                />
              )}
            {props.isOnPaymentStep &&
              (props.isPaymentApplicable ? (
                props.productSubtype && (
                  <ProceedToPaymentActionButtonV2
                    evaluationId={props.evaluationId}
                    fortifiedId={props.fortifiedId}
                    productSubtype={props.productSubtype}
                    evaluationStep={props.evaluationStep}
                    getPaymentDetails={async () =>
                      await props.getApplicationFeePaymentCreditDetails(
                        props.evaluationId
                      )
                    }
                    setEvaluation={props.setEvaluation}
                  />
                )
              ) : (
                <NonprofitPaymentBypassActionButton
                  evaluationId={props.evaluationId}
                  productSubtype={props.productSubtype}
                  nextStepDisplayText={props.paymentNextStepDisplayText}
                  bypassPaymentStepForNonProfit={
                    props.bypassPaymentStepForNonProfit
                  }
                />
              ))}
            <AdminPanelButton evaluationId={props.evaluationId} />
          </div>
        </div>
        <Tabs
          vertical
          selectedTabId={props.selectedTabId}
          onChange={(newTabId: number) => props.setSelectedTabId(newTabId)}
        >
          {props.visibleTabs.map((tab) => {
            return (
              <Tab
                key={tab.id}
                id={tab.id}
                title={tab.label}
                panel={
                  <ChecklistTabPanelV2
                    forms={props.filteredForms}
                    selectedUserRoleTab={props.selectedUserRoleTab}
                    hasFormsLoading={props.hasFormsLoading}
                  />
                }
              />
            );
          })}
          <EvaluationHistory
            evaluationId={props.evaluationId}
            productSubtype={props.productSubtype}
          />
        </Tabs>
      </div>
    </div>
  );
};

export default ChecklistContainerV2;
