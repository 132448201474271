import {
  FieldFormV2,
  FieldSchemaFormBuilderV2,
  RelativityTooltip,
} from "@ucl/library";
import { Button, NonIdealState } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import { useNavigate } from "react-router";
import { Evaluation_HomeNCIUAEvaluationFormModel } from "../../../../evaluation/types/HomeNCIUAEvaluationFormModel";
import useHomeNCIUAEvaluationEligibilityForm from "./useHomeNCIUAEvaluationEligibilityForm";
import { fortifiedHomeNCIUARoutePrefix } from "../../../../../../../foritfied/pages/index/fortifiedHomeRouteConfig";
import { HomeEligibilityFormParts } from "../../../../../common/types/BaseHomeEvaluationEligibilityFormModel";
import classNames from "classnames";
import { InformationalBanner } from "../../../../../../../wildfire/components/Forms/FormSections/InformationalBanner/InformationalBanner";

export interface HomeNCIUAEvaluationEligibilityFormProps {
  evaluationId: string;
  isIterationEnginePage?: boolean;
  formPart?: HomeEligibilityFormParts;
  onFormSave?: (fieldKey: string, value: any) => Promise<void>;
  setFormModel?: (formModel: Evaluation_HomeNCIUAEvaluationFormModel) => void;
  disableForm?: boolean;
  onInitialized?: () => void;
  setHasUnsavedChanges: (value: boolean) => void;
  hideFormControls?: boolean;
}

export const HomeNCIUAEvaluationEligibilityForm = (
  props: HomeNCIUAEvaluationEligibilityFormProps
) => {
  const {
    formRef,
    isLoading,
    isSubmitting,
    setIsSubmitting,
    applicationFormModel,
    isFirstPartOfForm,
    getApplicationFormPartBuilder,
    handleFormSubmit,
    onFormFieldChange,
    noBuilders,
    containerRef,
    setShouldSubmit,
    areAllRequiredFieldsComplete,
    areAllFormSectionsComplete,
    isLastPartOfForm,
  } = useHomeNCIUAEvaluationEligibilityForm(props);

  const navigate = useNavigate();

  const shouldShowEligbilityBanner =
    areAllFormSectionsComplete &&
    applicationFormModel?.homeNCIUAEvaluation.eligibilityForm
      .eligibilityInformationHasBeenSubmitted &&
    applicationFormModel?.homeNCIUAEvaluation.eligibilityForm
      .designationTypeAndLevelInformationHasBeenSubmitted;

  const shouldShowSubmitButton = areAllFormSectionsComplete || isLastPartOfForm;

  return (
    <div ref={containerRef} className="wildfire-form-container">
      {noBuilders || isLoading ? (
        <Loading />
      ) : !applicationFormModel ? (
        <NonIdealState />
      ) : (
        <>
          {!props.hideFormControls && shouldShowEligbilityBanner && (
            <InformationalBanner
              title={"Eligibility Form Changes"}
              content={
                <div>
                  <p>
                    If changes have been applied to this form after the inital
                    submission please click &quot;Submit Eligibility Form&quot;
                    to process changes
                  </p>
                </div>
              }
            />
          )}
          <FieldFormV2<Evaluation_HomeNCIUAEvaluationFormModel>
            isDisabled={isSubmitting || props.disableForm}
            value={applicationFormModel}
            fieldSchemaFormBuilder={
              getApplicationFormPartBuilder() as FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel>
            }
            onFormSubmit={async (form) => {
              await handleFormSubmit(form);
            }}
            ref={formRef}
            onFieldChanged={async (form, value, fieldProps) => {
              await onFormFieldChange(form, value, fieldProps);
            }}
          />
          {!props.hideFormControls && (
            <div className="wildfire-form-footer">
              {!isFirstPartOfForm && (
                <Button
                  className={
                    "wildfire-form-back-button " +
                    "wildfire-form-navigation-button"
                  }
                  text="Back"
                  icon={IconNames.ARROW_LEFT}
                  onClick={() => {
                    // Navigate to the previous part of the form
                    const previousFormPart = Object.values(
                      HomeEligibilityFormParts
                    )[
                      Object.values(HomeEligibilityFormParts).indexOf(
                        props.formPart!
                      ) - 1
                    ].valueOf();
                    navigate(
                      `${fortifiedHomeNCIUARoutePrefix}/${props.evaluationId}/eligibility/${previousFormPart}`
                    );
                  }}
                />
              )}
              {!isLastPartOfForm && !areAllFormSectionsComplete && (
                <RelativityTooltip shouldShowTooltip={false} content={""}>
                  <Button
                    text={"Continue"}
                    className={classNames({
                      "wildfire-form-submit-button":
                        areAllRequiredFieldsComplete,
                      "wildfire-form-wide-button": areAllRequiredFieldsComplete,
                      "wildfire-form-continue-button":
                        !areAllRequiredFieldsComplete,
                      "wildfire-form-navigation-button":
                        !areAllRequiredFieldsComplete,
                    })}
                    rightIcon={IconNames.ARROW_RIGHT}
                    loading={isSubmitting}
                    onClick={() => {
                      if (formRef.current) {
                        props.setHasUnsavedChanges(false);
                        setShouldSubmit(true);
                        setIsSubmitting(true);
                        formRef.current.submit();
                      }
                    }}
                  />
                </RelativityTooltip>
              )}
              {shouldShowSubmitButton && (
                <RelativityTooltip shouldShowTooltip={false} content={""}>
                  <Button
                    className="wildfire-form-submit-button"
                    text={"Submit Eligibility Form"}
                    rightIcon={IconNames.ARROW_RIGHT}
                    loading={isSubmitting}
                    onClick={() => {
                      if (formRef.current) {
                        props.setHasUnsavedChanges(false);
                        setShouldSubmit(true);
                        setIsSubmitting(true);
                        formRef.current.submit();
                      }
                    }}
                  />
                </RelativityTooltip>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default HomeNCIUAEvaluationEligibilityForm;
