import { FC, useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  FieldSchemaFactoryProvider,
  HomeSchemaFactoryV2,
} from "../../../customHooks/useHomeFieldSchemaFactory";
import { fortifiedFieldSchemaApiClient } from "../../../../Common/lib/apiClients/fortifiedFieldSchemaApiClient";
import { AppToaster } from "@ucl/library/lib/components/Toast/Toast";
import { Intent, NonIdealState } from "@blueprintjs/core";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../../../../AppInsights";
import ExternalPageHeader from "../../../../../foritfied/components/Header/ExternalPageHeader";
import { useSearchParams } from "react-router-dom";
import "./styles.scss";
import { Evaluation_HomeNCIUAEvaluationFormModel } from "../../evaluation/types/HomeNCIUAEvaluationFormModel";
import { homeNCIUAEvaluationAPIClient } from "../../evaluation/lib/apiClients/homeNCIUAEvaluationAPIClient";
import SimpleHomeNCIUARooferConfirmationForm from "../../components/Forms/RooferConfirmationForm/Form/Simple/SimpleHomeNCIUAEvaluationRooferConfirmationForm";

const HomeNCIUAExternalRooferConfirmationPageComponent: FC = () => {
  const { id } = useParams<{ id: string }>();

  const [searchParams] = useSearchParams();
  const accessToken = searchParams.get("token");

  const [isLoading, setIsLoading] = useState(false);
  const [evaluation, setEvaluation] =
    useState<Evaluation_HomeNCIUAEvaluationFormModel>();
  const [schemaFactory, setSchemaFactory] = useState<HomeSchemaFactoryV2>();

  const getEvaluation = async (evaluationId: string, accessToken: string) => {
    setIsLoading(true);
    await homeNCIUAEvaluationAPIClient
      .getHomeNCIUAEvaluationFormModelExternal(evaluationId, accessToken)
      .then((foundEvaluation) => {
        setEvaluation(foundEvaluation);
      })
      .catch((error) => {
        console.error(error);
        AppToaster.show({
          message: "Unexpected error occurred while loading evaluation",
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getSchema = async () => {
    setIsLoading(true);
    await fortifiedFieldSchemaApiClient
      .getSchema()
      .then((schema) => {
        const newSchemaFactory = new HomeSchemaFactoryV2(schema);
        setSchemaFactory(newSchemaFactory);
      })
      .catch((error) => {
        console.error(error);
        AppToaster.show({
          message: "Unexpected error occurred while loading form schema",
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (id && accessToken) {
      getEvaluation(id, accessToken);
      getSchema();
    }
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  if (!accessToken || !evaluation || !schemaFactory) {
    return <NonIdealState />;
  }

  return (
    <>
      <ExternalPageHeader
        name={"Roofer Confirmation Form"}
        address={evaluation.buildingAddress_AsString}
        fortifiedId={evaluation.fortifiedId}
      />
      <div className="content">
        <div className="external-fh-forms">
          <FieldSchemaFactoryProvider schemaFactory={schemaFactory}>
            <SimpleHomeNCIUARooferConfirmationForm
              form={evaluation}
              accessToken={accessToken}
            />
          </FieldSchemaFactoryProvider>
        </div>
      </div>
    </>
  );
};

export const HomeNCIUAExternalRooferConfirmationPage = withAITracking(
  reactPlugin,
  HomeNCIUAExternalRooferConfirmationPageComponent,
  "HomeNCIUAExternalRooferConfirmationPage",
  "tracking-container"
);
