import React, { useState, useEffect } from "react";
import { Evaluation } from "../../../../foritfied/types/evaluation/Evaluation";
import { FortifiedApplicationCard } from "./ApplicationOverviewCard";
import { evaluationApiClient } from "../../../../foritfied/lib/apiClients/evaluation/evaluationApiClient";
import { fortifiedGridViewModuleStore } from "../../../../foritfied/stores/FortifiedGridViewModuleStore";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import { EvaluationReadDTO } from "../../../../foritfied/types/dtos/EvaluationReadDTO";

interface ApplicationOverviewCardListProps {
  evaluations: Evaluation[];
  refreshEvaluations: () => Promise<void>;
  onNavigateToEvaluation: (evaluation: EvaluationReadDTO) => void;
}

const ApplicationOverviewCardList: React.FC<
  ApplicationOverviewCardListProps
> = ({ evaluations, refreshEvaluations, onNavigateToEvaluation }) => (
  <div className="fortified-application-cards">
    {evaluations.map((evaluation, index) => (
      <FortifiedApplicationCard
        key={evaluation.id}
        evaluation={evaluation}
        index={index}
        fetchEvaluations={refreshEvaluations}
        onNavigateToEvaluation={onNavigateToEvaluation}
      />
    ))}
  </div>
);

interface ApplicationOverviewCardContainerProps {
  onNavigateToEvaluation: (evaluation: EvaluationReadDTO) => void;
}

export const ApplicationOverviewCardContainer: React.FC<
  ApplicationOverviewCardContainerProps
> = ({ onNavigateToEvaluation }) => {
  const [evaluations, setEvaluations] = useState<Evaluation[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchEvaluations = async () => {
    try {
      const response = await evaluationApiClient.getFCFMFApplicantEvaluations();

      const filtered = response
        .filter(
          (evaluation) => !evaluation.isComplexCommercialEvaluationBuilding
        )
        .sort(
          (a, b) =>
            new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        );

      return filtered;
    } catch (error) {
      console.error("Failed to fetch evaluations:", error);
      return [];
    }
  };

  const refreshEvaluations = async () => {
    setIsLoading(true);
    try {
      const data = await fetchEvaluations();
      setEvaluations(data || []);
    } catch (error) {
      console.error("Failed to load evaluations:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    refreshEvaluations();
  }, [
    fortifiedGridViewModuleStore.currentGridViewKey,
    fortifiedGridViewModuleStore.currentGridView?.title,
  ]);

  if (isLoading) return <Loading />;

  return (
    <ApplicationOverviewCardList
      evaluations={evaluations}
      refreshEvaluations={refreshEvaluations}
      onNavigateToEvaluation={onNavigateToEvaluation}
    />
  );
};
