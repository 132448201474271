/* eslint-disable react/display-name */

import { forwardRef } from "react";
import CommercialBuildingApplicationForm from "./Form/MultifamilyBuildingApplicationForm";
import { Evaluation_MultifamilyEvaluationFormModel } from "../../types/MultifamilyEvaluationFormModel";
import { FieldFormV2Module } from "@ucl/library";

export interface ComplexMultifamilyEvaluationBuildingFormV2Props {
  complexCommercialEvaluationBuildingId: string;
  isSaving: boolean;
  isDeleting: boolean;
  setHasUnsavedChanges: (value: boolean) => void;
  isIterationEnginePage?: boolean;
  hideFormControls?: boolean;
  onInitialized?: () => void;
  onFormSave?: ((fieldKey: string, value: any) => Promise<void>) | undefined;
  disabled?: boolean;
  updateChildFormError: (formId: string, hasError: boolean) => void;
  onParentNeedsRefresh?: () => Promise<void>;
}

export const ComplexMultifamilyEvaluationBuildingFormV2 = forwardRef<
  FieldFormV2Module<Evaluation_MultifamilyEvaluationFormModel>,
  ComplexMultifamilyEvaluationBuildingFormV2Props
>((props, ref) => {
  return (
    <div className={`evaluation-container__content__evaluation-form`}>
      <CommercialBuildingApplicationForm
        ref={ref}
        evaluationId={props.complexCommercialEvaluationBuildingId}
        setHasUnsavedChanges={props.setHasUnsavedChanges}
        disableForm={props.isSaving || props.isDeleting || props.disabled}
        isIterationEnginePage={props.isIterationEnginePage}
        hideFormControls={props.hideFormControls}
        onInitialized={props.onInitialized}
        onFormSave={props.onFormSave}
        updateChildFormError={props.updateChildFormError}
        onParentNeedsRefresh={props.onParentNeedsRefresh}
      />
    </div>
  );
});

(
  ComplexMultifamilyEvaluationBuildingFormV2 as React.ForwardRefExoticComponent<ComplexMultifamilyEvaluationBuildingFormV2Props>
).displayName = "ComplexMultifamilyEvaluationBuildingFormV2";
