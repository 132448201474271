import { FieldSchemaFormBuilderV2, SchemaFactoryV2 } from "@ucl/library";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../../types/HomeEvaluationFormModel";
import {
  BaseFieldProps,
  EnumSingleSelectFieldValue,
} from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { WildfireBoxContentExplorer } from "../../../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import { YesOrNoSelection } from "../../../../../../wildfire/types/FormFields";
import { InstalledFoundationTypes } from "../../../../common/types/BaseHomeEvaluationEngineeringComplianceContinuousLoadPathFormModel";
import {
  shouldHideEngineeringCLPForNewConstructionFields,
  shouldHideEngineeringCLPForRetrofitOfExistingHomeFields,
} from "../../../../common/components/FieldSchema/sharedConditionalLogic";

const modelName = "Evaluation";
const subModelName = "HomeStandardEvaluation";
const formName = "EngineeringComplianceContinuousLoadPathForm";

const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

// Part 1: Installer Compliance Form Continuous Load Path For New Construction
const engineeringComplianceFormContinuousLoadPathForNewConstructionFields = (
  form: Evaluation_HomeStandardEvaluationFormModel,
  shouldHideSection: (
    continuousLoadPathPathwayType: EnumSingleSelectFieldValue
  ) => boolean
) =>
  shouldHideSection(
    form.homeStandardEvaluation.eligibilityForm.continuousLoadPathPathwayType
  )
    ? []
    : [
        {
          fieldKey: buildFieldKey("Attestation234"),
          isRequired:
            form.homeStandardEvaluation
              .engineeringComplianceContinuousLoadPathForm
              .areAttestations234And235NotApplicable != true,
          disabled:
            form.homeStandardEvaluation
              .engineeringComplianceContinuousLoadPathForm
              .areAttestations234And235NotApplicable == true,
        },
        {
          fieldKey: buildFieldKey("Attestation235"),
          isRequired:
            form.homeStandardEvaluation
              .engineeringComplianceContinuousLoadPathForm
              .areAttestations234And235NotApplicable != true,
          disabled:
            form.homeStandardEvaluation
              .engineeringComplianceContinuousLoadPathForm
              .areAttestations234And235NotApplicable == true,
        },
        {
          fieldKey: buildFieldKey("AreAttestations234And235NotApplicable"),
          hidden:
            (form.homeStandardEvaluation
              .engineeringComplianceContinuousLoadPathForm.attestation234 ==
              true ||
              form.homeStandardEvaluation
                .engineeringComplianceContinuousLoadPathForm.attestation235 ==
                true) &&
            form.homeStandardEvaluation
              .engineeringComplianceContinuousLoadPathForm
              .areAttestations234And235NotApplicable != true,
        },
        {
          fieldKey: buildFieldKey("Attestation236"),
          isRequired:
            form.homeStandardEvaluation
              .engineeringComplianceContinuousLoadPathForm
              .areAttestations236And237NotApplicable != true,
          disabled:
            form.homeStandardEvaluation
              .engineeringComplianceContinuousLoadPathForm
              .areAttestations236And237NotApplicable == true,
        },
        {
          fieldKey: buildFieldKey("Attestation237"),
          isRequired:
            form.homeStandardEvaluation
              .engineeringComplianceContinuousLoadPathForm
              .areAttestations236And237NotApplicable != true,
          disabled:
            form.homeStandardEvaluation
              .engineeringComplianceContinuousLoadPathForm
              .areAttestations236And237NotApplicable == true,
        },
        {
          fieldKey: buildFieldKey("AreAttestations236And237NotApplicable"),
          hidden:
            (form.homeStandardEvaluation
              .engineeringComplianceContinuousLoadPathForm.attestation236 ==
              true ||
              form.homeStandardEvaluation
                .engineeringComplianceContinuousLoadPathForm.attestation237 ==
                true) &&
            form.homeStandardEvaluation
              .engineeringComplianceContinuousLoadPathForm
              .areAttestations236And237NotApplicable != true,
        },
        {
          fieldKey: buildFieldKey("Attestation238"),
        },
        {
          fieldKey: buildFieldKey("Attestation239"),
        },
        {
          fieldKey: buildFieldKey("DoesHomeIncludeConcreteCMUWalls"),
        },
        {
          fieldKey: buildFieldKey("Attestation241"),
          hidden:
            !form.homeStandardEvaluation
              .engineeringComplianceContinuousLoadPathForm.attestation249,
        },
        {
          fieldKey: buildFieldKey("Attestation242"),
          hidden: !(
            form.homeStandardEvaluation
              .engineeringComplianceContinuousLoadPathForm
              .doesHomeIncludeConcreteCMUWalls == YesOrNoSelection.Yes
          ),
        },
        {
          fieldKey: buildFieldKey("Attestation243"),
          hidden: !(
            form.homeStandardEvaluation
              .engineeringComplianceContinuousLoadPathForm
              .doesHomeIncludeConcreteCMUWalls == YesOrNoSelection.Yes
          ),
        },
        {
          fieldKey: buildFieldKey("Attestation244"),
          hidden: !(
            form.homeStandardEvaluation
              .engineeringComplianceContinuousLoadPathForm
              .doesHomeIncludeConcreteCMUWalls == YesOrNoSelection.Yes
          ),
        },
        {
          fieldKey: buildFieldKey("InstalledFoundationType"),
        },
        {
          fieldKey: buildFieldKey("InstalledFoundationTypeDescription"),
          hidden: !(
            form.homeStandardEvaluation
              .engineeringComplianceContinuousLoadPathForm
              .installedFoundationType == InstalledFoundationTypes.Other
          ),
        },
        {
          fieldKey: buildFieldKey("Attestation246"),
          hidden: !(
            form.homeStandardEvaluation
              .engineeringComplianceContinuousLoadPathForm
              .installedFoundationType == InstalledFoundationTypes.Other
          ),
        },
        {
          fieldKey: buildFieldKey("Attestation301"),
          hidden: !(
            form.homeStandardEvaluation
              .engineeringComplianceContinuousLoadPathForm
              .installedFoundationType ==
            InstalledFoundationTypes.SlabOnGradeOrStemWall
          ),
        },
        {
          fieldKey: buildFieldKey("Attestation247"),
          hidden: !(
            form.homeStandardEvaluation
              .engineeringComplianceContinuousLoadPathForm
              .installedFoundationType ==
            InstalledFoundationTypes.SlabOnGradeOrStemWall
          ),
          isRequired:
            form.homeStandardEvaluation
              .engineeringComplianceContinuousLoadPathForm
              .isAttestation247NotApplicable != true,
          disabled:
            form.homeStandardEvaluation
              .engineeringComplianceContinuousLoadPathForm
              .isAttestation247NotApplicable == true,
        },
        {
          fieldKey: buildFieldKey("IsAttestation247NotApplicable"),
          hidden:
            !(
              form.homeStandardEvaluation
                .engineeringComplianceContinuousLoadPathForm
                .installedFoundationType ==
              InstalledFoundationTypes.SlabOnGradeOrStemWall
            ) ||
            (form.homeStandardEvaluation
              .engineeringComplianceContinuousLoadPathForm.attestation247 ==
              true &&
              form.homeStandardEvaluation
                .engineeringComplianceContinuousLoadPathForm
                .isAttestation247NotApplicable != true),
        },
        {
          fieldKey: buildFieldKey("Attestation248"),
          hidden: !(
            form.homeStandardEvaluation
              .engineeringComplianceContinuousLoadPathForm
              .installedFoundationType ==
            InstalledFoundationTypes.ElevatedFoundationOnPiersPilings
          ),
        },
        {
          fieldKey: buildFieldKey("Attestation249"),
          hidden: !(
            form.homeStandardEvaluation
              .engineeringComplianceContinuousLoadPathForm
              .installedFoundationType == InstalledFoundationTypes.Other ||
            form.homeStandardEvaluation
              .engineeringComplianceContinuousLoadPathForm
              .installedFoundationType ==
              InstalledFoundationTypes.ElevatedFoundationOnPiersPilings
          ),
        },
        {
          fieldKey: buildFieldKey("WerePrescriptiveDesignStandardsUtilized"),
        },
        {
          fieldKey: buildFieldKey("PrescriptiveDesignStandardsDescription"),
          hidden: !(
            form.homeStandardEvaluation
              .engineeringComplianceContinuousLoadPathForm
              .werePrescriptiveDesignStandardsUtilized == YesOrNoSelection.Yes
          ),
        },
        {
          fieldKey: buildFieldKey("Attestation251"),
          hidden: !(
            form.homeStandardEvaluation
              .engineeringComplianceContinuousLoadPathForm
              .werePrescriptiveDesignStandardsUtilized == YesOrNoSelection.Yes
          ),
        },
        {
          fieldKey: buildFieldKey("Attestation252"),
          hidden: !(
            form.homeStandardEvaluation
              .engineeringComplianceContinuousLoadPathForm
              .werePrescriptiveDesignStandardsUtilized == YesOrNoSelection.Yes
          ),
        },
        {
          fieldKey: buildFieldKey("Attestation253"),
          hidden: !(
            form.homeStandardEvaluation
              .engineeringComplianceContinuousLoadPathForm
              .werePrescriptiveDesignStandardsUtilized == YesOrNoSelection.Yes
          ),
        },
        {
          fieldKey: buildFieldKey("Attestation254"),
          hidden: !(
            form.homeStandardEvaluation
              .engineeringComplianceContinuousLoadPathForm
              .werePrescriptiveDesignStandardsUtilized == YesOrNoSelection.Yes
          ),
        },
        {
          fieldKey: buildFieldKey("Attestation255"),
          hidden: !(
            form.homeStandardEvaluation
              .engineeringComplianceContinuousLoadPathForm
              .werePrescriptiveDesignStandardsUtilized == YesOrNoSelection.Yes
          ),
        },
        {
          fieldKey: buildFieldKey("Attestation256"),
          hidden: true,
        },
        {
          fieldKey: buildFieldKey("InstalledRoofToWallConnectionsDescription"),
        },
        {
          fieldKey: buildFieldKey("Attestation258"),
        },
        {
          fieldKey: buildFieldKey("IsHomeMoreThan1Story"),
        },
        {
          fieldKey: buildFieldKey("Attestation260"),
          hidden: !(
            form.homeStandardEvaluation
              .engineeringComplianceContinuousLoadPathForm
              .isHomeMoreThan1Story === YesOrNoSelection.Yes
          ),
        },
        {
          fieldKey: buildFieldKey(
            "InstalledWallAboveToWallBelowConnectionsDescriptionType"
          ),
          hidden: !(
            form.homeStandardEvaluation
              .engineeringComplianceContinuousLoadPathForm
              .isHomeMoreThan1Story === YesOrNoSelection.Yes
          ),
        },
        {
          fieldKey: buildFieldKey("Attestation262"),
        },
        {
          fieldKey: buildFieldKey("Attestation263"),
        },
        {
          fieldKey: buildFieldKey("Attestation264"),
        },
        {
          fieldKey: buildFieldKey("Attestation265"),
        },
      ];

const engineeringComplianceFormContinuousLoadPathForNewConstructionRequiredFields =
  [
    buildFieldKey("Attestation238"),
    buildFieldKey("Attestation239"),
    buildFieldKey("DoesHomeIncludeConcreteCMUWalls"),
    buildFieldKey("Attestation241"),
    buildFieldKey("Attestation242"),
    buildFieldKey("Attestation243"),
    buildFieldKey("Attestation244"),
    buildFieldKey("InstalledFoundationType"),
    buildFieldKey("InstalledFoundationTypeDescription"),
    buildFieldKey("Attestation246"),
    buildFieldKey("Attestation301"),
    buildFieldKey("Attestation247"),
    buildFieldKey("Attestation248"),
    buildFieldKey("Attestation249"),
    buildFieldKey("WerePrescriptiveDesignStandardsUtilized"),
    buildFieldKey("PrescriptiveDesignStandardsDescription"),
    buildFieldKey("Attestation251"),
    buildFieldKey("Attestation252"),
    buildFieldKey("Attestation253"),
    buildFieldKey("Attestation254"),
    buildFieldKey("Attestation255"),
    buildFieldKey("InstalledRoofToWallConnectionsDescription"),
    buildFieldKey("Attestation258"),
    buildFieldKey("IsHomeMoreThan1Story"),
    buildFieldKey("Attestation260"),
    buildFieldKey("InstalledWallAboveToWallBelowConnectionsDescriptionType"),
    buildFieldKey("Attestation262"),
    buildFieldKey("Attestation263"),
    buildFieldKey("Attestation264"),
    buildFieldKey("Attestation265"),
  ];

export const engineeringComplianceFormContinuousLoadPathForNewConstructionBuilder =
  (
    factory: SchemaFactoryV2
  ): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
    return ((onValueChange, form, errors) => {
      const fields = [
        ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
          modelName,
          engineeringComplianceFormContinuousLoadPathForNewConstructionFields(
            form,
            shouldHideEngineeringCLPForNewConstructionFields
          ),
          onValueChange,
          form,
          errors,
          engineeringComplianceFormContinuousLoadPathForNewConstructionRequiredFields
        ),
      ];
      return fields;
    }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
  };

// Part 2: engineering Compliance Form Continuous Load Path For Retrofit Of Existing Home
const engineeringComplianceFormContinuousLoadPathForRetrofitOfExistingHomeFields =
  (
    form: Evaluation_HomeStandardEvaluationFormModel,
    shouldHideSection: (
      continuousLoadPathPathwayType: EnumSingleSelectFieldValue
    ) => boolean
  ) =>
    shouldHideSection(
      form.homeStandardEvaluation.eligibilityForm.continuousLoadPathPathwayType
    )
      ? []
      : [
          {
            fieldKey: buildFieldKey("Attestation266"),
          },
          {
            fieldKey: buildFieldKey("Attestation267"),
          },
        ];

const engineeringComplianceFormContinuousLoadPathForRetrofitOfExistingHomeRequiredFields =
  [buildFieldKey("Attestation266"), buildFieldKey("Attestation267")];

export const engineeringComplianceFormContinuousLoadPathForRetrofitOfExistingHomeBuilder =
  (
    factory: SchemaFactoryV2
  ): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
    return ((onValueChange, form, errors) => {
      const fields = [
        ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
          modelName,
          engineeringComplianceFormContinuousLoadPathForRetrofitOfExistingHomeFields(
            form,
            shouldHideEngineeringCLPForRetrofitOfExistingHomeFields
          ),
          onValueChange,
          form,
          errors,
          engineeringComplianceFormContinuousLoadPathForRetrofitOfExistingHomeRequiredFields
        ),
      ];
      return fields;
    }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
  };

// Full form builder with all parts included
export const homeStandardEngineeringComplianceContinuousLoadPathBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        [
          ...engineeringComplianceFormContinuousLoadPathForNewConstructionFields(
            form,
            shouldHideEngineeringCLPForNewConstructionFields
          ),
          ...engineeringComplianceFormContinuousLoadPathForRetrofitOfExistingHomeFields(
            form,
            shouldHideEngineeringCLPForRetrofitOfExistingHomeFields
          ),
        ],
        onValueChange,
        form,
        errors,
        [
          ...engineeringComplianceFormContinuousLoadPathForNewConstructionRequiredFields,
          ...engineeringComplianceFormContinuousLoadPathForRetrofitOfExistingHomeRequiredFields,
        ],
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Full form builder with all parts included, for Iteration Engine
export const homeStandardIterationEngineEngineeringComplianceContinuousLoadPathFormBuilder =
  (
    factory: SchemaFactoryV2,
    beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
  ): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
    return ((onValueChange, form, errors) => {
      let fields = [
        ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
          modelName,
          [
            ...engineeringComplianceFormContinuousLoadPathForNewConstructionFields(
              form,
              shouldHideEngineeringCLPForNewConstructionFields
            ),
            ...engineeringComplianceFormContinuousLoadPathForRetrofitOfExistingHomeFields(
              form,
              shouldHideEngineeringCLPForRetrofitOfExistingHomeFields
            ),
          ],
          onValueChange,
          form,
          errors,
          [
            ...engineeringComplianceFormContinuousLoadPathForNewConstructionRequiredFields,
            ...engineeringComplianceFormContinuousLoadPathForRetrofitOfExistingHomeRequiredFields,
          ],
          {
            FileUploader: WildfireBoxContentExplorer,
          }
        ),
      ];
      if (beforeBuild) {
        fields = beforeBuild(fields);
      }
      return fields;
    }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
  };
