import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../../../../types/HomeEvaluationFormModel";
import { Button, NonIdealState } from "@blueprintjs/core";
import { FieldFormV2, FieldSchemaFormBuilderV2 } from "@ucl/library";
import { IconNames } from "@blueprintjs/icons";
import "./styles.scss";
import useSimpleHomeStandardInstallerComplianceContinuousLoadPathForm from "./useSimpleHomeStandardEvaluationInstallerComplianceContinuousLoadPathForm";

export interface SimpleHomeStandardInstallerComplianceContinuousLoadPathFormProps {
  form: Evaluation_HomeStandardEvaluationFormModel;
  accessToken: string;
}

const SimpleHomeStandardInstallerComplianceContinuousLoadPathForm = (
  props: SimpleHomeStandardInstallerComplianceContinuousLoadPathFormProps
) => {
  const {
    formRef,
    formModel,
    getFormBuilder,
    noBuilders,
    containerRef,
    isSubmitting,
    onFormFieldChange,
    handleFormSubmit,
  } = useSimpleHomeStandardInstallerComplianceContinuousLoadPathForm(props);

  return (
    <div ref={containerRef} className="external-form-container">
      {noBuilders ? (
        <Loading />
      ) : !formModel ? (
        <NonIdealState />
      ) : (
        <>
          <FieldFormV2<Evaluation_HomeStandardEvaluationFormModel>
            value={formModel}
            fieldSchemaFormBuilder={
              getFormBuilder() as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>
            }
            onFieldChanged={onFormFieldChange}
            onFormSubmit={handleFormSubmit}
            ref={formRef}
          />
          <div className="external-form-footer">
            <Button
              className="external-form-submit-button"
              text={"Submit Installer Compliance Continuous Load Path Form"}
              rightIcon={IconNames.ARROW_RIGHT}
              loading={isSubmitting}
              onClick={() => {
                formRef.current?.submit();
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default SimpleHomeStandardInstallerComplianceContinuousLoadPathForm;
