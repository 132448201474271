import {
  AuthenticatedTemplate,
  MsalProvider,
  useAccount,
} from "@azure/msal-react";
import { FeatureToggleProvider } from "../../customHooks/useFeatureToggle";
import { wildfireMsalInstance } from "../../../common/lib/msal/wildfireMsal";
import { useWildfirePagesAuthentication } from "./useWildfirePagesAuthentication";
import { PermissionsProvider } from "@ucl/library";
import { permissionsApiClient } from "../../../common/apiClients/permissionsApiClient";
import WildfireRouterProvider from "./WildfireRouterProvider";
import { AppHeaderProvider } from "../../components/Provider/AppHeaderContext";

function WildfireAuthenticatedPages() {
  return (
    <MsalProvider instance={wildfireMsalInstance}>
      <WildfirePages />
    </MsalProvider>
  );
}

function WildfirePages() {
  const { isInitialized, isAuthenticated } = useWildfirePagesAuthentication();
  const account = useAccount();

  const shouldRender = isAuthenticated && isInitialized && account;

  return (
    <>
      {shouldRender && (
        <AuthenticatedTemplate>
          <FeatureToggleProvider>
            <PermissionsProvider
              getPermissions={permissionsApiClient.getPermissions}
            >
              <AppHeaderProvider>
                <WildfireRouterProvider />
              </AppHeaderProvider>
            </PermissionsProvider>
          </FeatureToggleProvider>
        </AuthenticatedTemplate>
      )}
    </>
  );
}

export default WildfireAuthenticatedPages;
