import ErrorWrapper from "../../../common/Components/Error/ErrorWrapper";
import { PagesLayout } from "./PagesLayout";
import { createBrowserRouter } from "react-router-dom";
import { utils, WithParams } from "@ucl/library";
import { Board } from "../Board";
import { Evaluation } from "../Evaluation";
import { Checklist } from "../Checklist";
import { ReportsPage } from "../ReportsPage";
import { AdminPage } from "../AdminPage";
import { HelpPage } from "../HelpPage";
import { EvaluationBidPage } from "../EvaluationBidPage";
import { ExternalEvaluationSummaryPage } from "../ExternalEvaluationSummaryPage";
import { ReportViewerPage } from "../reports/ReportViewerPage";
import { fortifiedHomeLogout } from "../../../common/lib/msal/fortifiedHomeMsal";
import { HomeBasicInformationFormParts } from "../../../fortifiedV2/Home/common/types/BaseHomeEvaluationBasicInformationFormModel";
import { HomePagesLayout } from "../../../fortifiedV2/Home/common/pages/index/HomePagesLayout";
import { HomeStandardBasicInformationPage } from "../../../fortifiedV2/Home/standard/pages/HomeStandardBasicInformationPage";
import { HomeStandardRoofingComplianceGeneralInformationPage } from "../../../fortifiedV2/Home/standard/pages/HomeStandardRoofingComplianceGeneralInformationPage";
import { HomeStandardIterationEnginePage } from "../../../fortifiedV2/Home/standard/pages/HomeStandardIterationEnginePage";
import {
  faChartSimple,
  faList,
  faSliders,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { HomeSCSHBasicInformationFormPage } from "../../../fortifiedV2/Home/scsh/pages/HomeSCSHBasicInformationFormPage";
import { HomeSCSHIterationEnginePage } from "../../../fortifiedV2/Home/scsh/pages/HomeSCSHIterationEnginePage";
import { HomeSCSHEvaluationFormPage } from "../../../fortifiedV2/Home/scsh/pages/HomeSCSHEvaluationFormPage";
import { SCSHEvaluationForm_FormParts } from "../../../fortifiedV2/Home/scsh/types/HomeSCSHEvaluation_EvaluationFormModel";
import { homeStandardEvaluationAPIClient } from "../../../fortifiedV2/Home/standard/lib/apiClients/homeStandardEvaluationAPIClient";
import { PaymentRedirectPage } from "../../../common/pages/PaymentRedirectPage";
import { homeSCSHEvaluationAPIClient } from "../../../fortifiedV2/Home/scsh/lib/apiClients/homeSCSHEvaluationAPIClient";
import { BaseHomeEvaluationRooferConfirmationFormParts } from "../../../fortifiedV2/Home/common/types/BaseHomeEvaluationRooferConfirmationFormModel";
import { HomeStandardRooferConfirmationPage } from "../../../fortifiedV2/Home/standard/pages/HomeStandardRooferConfirmationPage";
import { BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts } from "../../../fortifiedV2/Home/common/types/BaseHomeEvaluationRoofingComplianceGeneralInformationFormModel";
import { BaseHomeEvaluationHomeownerAuthorizationFormParts } from "../../../fortifiedV2/Home/common/types/BaseHomeEvaluationHomeownerAuthorizationFormModel";
import { HomeStandardHomeownerAuthorizationPage } from "../../../fortifiedV2/Home/standardRedesignation/pages/HomeStandardHomeownerAuthorizationPage";
import { HomeStandardRoofingComplianceRoofCoverPage } from "../../../fortifiedV2/Home/standard/pages/HomeStandardRoofingComplianceRoofCoverPage";
import { BaseHomeRoofingComplianceRoofCoverFormParts } from "../../../fortifiedV2/Home/common/types/BaseHomeEvaluationRoofingComplianceRoofCoverFormModel";
import { BaseHomeEvaluationEngineerConfirmationFormParts } from "../../../fortifiedV2/Home/common/types/BaseHomeEvaluationEngineerConfirmationFormModel";
import { HomeStandardEngineerConfirmationPage } from "../../../fortifiedV2/Home/standard/pages/HomeStandardEngineerConfirmationPage";
import { HomeStandardSilverGoldComplianceGeneralInformationPage } from "../../../fortifiedV2/Home/standard/pages/HomeStandardSilverGoldComplianceGeneralInformationPage";
import { BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts } from "../../../fortifiedV2/Home/common/types/BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormModel";
import { HomeStandardInstallingContractorConfirmationPage } from "../../../fortifiedV2/Home/standard/pages/HomeStandardInstallingContractorConfirmationPage";
import { BaseHomeEvaluationInstallingContractorConfirmationFormParts } from "../../../fortifiedV2/Home/common/types/BaseHomeEvaluationInstallingContractorConfirmationFormModel";
import { HomeStandardInstallerComplianceGablesPage } from "../../../fortifiedV2/Home/standard/pages/HomeStandardInstallerComplianceGablesPage";
import { BaseHomeEvaluationInstallerComplianceGablesFormParts } from "../../../fortifiedV2/Home/common/types/BaseHomeEvaluationInstallerComplianceGablesFormModel";
import { HomeStandardInstallerComplianceContinuousLoadPathPage } from "../../../fortifiedV2/Home/standard/pages/HomeStandardInstallerComplianceContinuousLoadPathPage";
import { BaseHomeEvaluationInstallerComplianceContinuousLoadPathFormParts } from "../../../fortifiedV2/Home/common/types/BaseHomeEvaluationInstallerComplianceContinuousLoadPathFormModel";
import { HomeStandardInstallerComplianceChimneyPage } from "../../../fortifiedV2/Home/standard/pages/HomeStandardInstallerComplianceChimneyPage";
import { BaseHomeEvaluationInstallerComplianceChimneyFormParts } from "../../../fortifiedV2/Home/common/types/BaseHomeEvaluationInstallerComplianceChimneyFormModel";
import { HomeStandardInstallerComplianceAttachedStructurePage } from "../../../fortifiedV2/Home/standard/pages/HomeStandardInstallerComplianceAttachedStructurePage";
import { BaseHomeEvaluationInstallerComplianceAttachedStructureFormParts } from "../../../fortifiedV2/Home/common/types/BaseHomeEvaluationInstallerComplianceAttachedStructureFormModel";
import { HomeStandardEngineeringComplianceGeneralInformationPage } from "../../../fortifiedV2/Home/standard/pages/HomeStandardEngineeringComplianceGeneralInformationPage";
import { BaseHomeEvaluationEngineeringComplianceGeneralInformationFormParts } from "../../../fortifiedV2/Home/common/types/BaseHomeEvaluationEngineeringComplianceGeneralInformationFormModel";
import { BaseHomeEvaluationEngineeringComplianceGablesFormParts } from "../../../fortifiedV2/Home/common/types/BaseHomeEvaluationEngineeringComplianceGablesFormModel";
import { HomeStandardEngineeringComplianceGablesPage } from "../../../fortifiedV2/Home/standard/pages/HomeStandardEngineeringComplianceGablesPage";
import { HomeStandardEngineeringComplianceContinuousLoadPathPage } from "../../../fortifiedV2/Home/standard/pages/HomeStandardEngineeringComplianceContinuousLoadPathPage";
import { BaseHomeEvaluationEngineeringComplianceContinuousLoadPathFormParts } from "../../../fortifiedV2/Home/common/types/BaseHomeEvaluationEngineeringComplianceContinuousLoadPathFormModel";
import { BaseHomeEvaluationEngineeringComplianceChimneyFormParts } from "../../../fortifiedV2/Home/common/types/BaseHomeEvaluationEngineeringComplianceChimneyFormModel";
import { HomeStandardEngineeringComplianceChimneyPage } from "../../../fortifiedV2/Home/standard/pages/HomeStandardEngineeringComplianceChimneyPage";
import { HomeStandardEngineeringComplianceAttachedStructurePage } from "../../../fortifiedV2/Home/standard/pages/HomeStandardEngineeringComplianceAttachedStructurePage";
import { BaseHomeEvaluationEngineeringComplianceAttachedStructureFormParts } from "../../../fortifiedV2/Home/common/types/BaseHomeEvaluationEngineeringComplianceAttachedStructureFormModel";
import { HomeNCIUAEvaluationFormPage } from "../../../fortifiedV2/Home/nciua/pages/HomeNCIUAEvaluationFormPage";
import { NCIUAEvaluationForm_FormParts } from "../../../fortifiedV2/Home/nciua/evaluation/types/HomeNCIUAEvaluation_EvaluationFormModel";
import { HomeNCIUAIterationEnginePage } from "../../../fortifiedV2/Home/nciua/pages/HomeNCIUAIterationEnginePage";
import { HomeNCIUARedesignationIterationEnginePage } from "../../../fortifiedV2/Home/nciuaRedesignation/pages/HomeNCIUARedesignationIterationEnginePage";
import { HomeNCIUARoofingComplianceRoofCoverPage } from "../../../fortifiedV2/Home/nciua/pages/HomeNCIUARoofingComplianceRoofCoverPage";
import { HomeNCIUARoofingComplianceGeneralInformationPage } from "../../../fortifiedV2/Home/nciua/pages/HomeNCIUARoofingComplianceGeneralInformationPage";
import { HomeNCIUARooferConfirmationPage } from "../../../fortifiedV2/Home/nciua/pages/HomeNCIUARooferConfirmationPage";
import { HomeNCIUARedesignationHomeownerAuthorizationPage } from "../../../fortifiedV2/Home/nciuaRedesignation/pages/HomeNCUIARedesignationHomeownerAuthorizationPage";
import { HomeNCIUARedesignationFormPage } from "../../../fortifiedV2/Home/nciuaRedesignation/pages/HomeNCUIARedesignationFormPage";
import { BaseHomeEvaluationRedesignationFormParts } from "../../../fortifiedV2/Home/common/types/BaseHomeEvaluationRedesignationFormModel";
import { HomeStandardRedesignationIterationEnginePage } from "../../../fortifiedV2/Home/standardRedesignation/pages/HomeStandardRedesignationIterationEnginePage";
import { HomeStandardEligibilityPage } from "../../../fortifiedV2/Home/standard/pages/HomeStandardEligibilityPage";
import { HomeEligibilityFormParts } from "../../../fortifiedV2/Home/common/types/BaseHomeEvaluationEligibilityFormModel";
import { HomeStandardChecklistPage } from "../../../fortifiedV2/Home/standard/pages/HomeStandardChecklistPage";
import { HomeStandardRedesignationFormPage } from "../../../fortifiedV2/Home/standardRedesignation/pages/HomeStandardRedesignationFormPage";
import { HomeNCIUAEligibilityPage } from "../../../fortifiedV2/Home/nciua/pages/HomeNCIUAEligibilityPage";
import { HomeNCIUABasicInformationPage } from "../../../fortifiedV2/Home/nciua/pages/HomeStandardBasicInformationPage";
import { HomeNCIUAChecklistPage } from "../../../fortifiedV2/Home/nciua/pages/HomeStandardChecklistPage";
import { homeNCIUAEvaluationAPIClient } from "../../../fortifiedV2/Home/nciua/evaluation/lib/apiClients/homeNCIUAEvaluationAPIClient";
import { HomeSCSHChecklistPage } from "../../../fortifiedV2/Home/scsh/pages/HomeSCSHChecklistPage";
import { HomeStandardRedesignationChecklistPage } from "../../../fortifiedV2/Home/standardRedesignation/pages/HomeStandardChecklistPage";
import { homeStandardRedesignationAPIClient } from "../../../fortifiedV2/Home/standardRedesignation/lib/apiClients/homeStandardRedesignationAPIClient";
import { homeNCIUARedesignationAPIClient } from "../../../fortifiedV2/Home/nciuaRedesignation/evaluation/lib/apiClients/homeNCIUARedesignationAPIClient";
import { HomeNCIUARedesignationChecklistPage } from "../../../fortifiedV2/Home/nciuaRedesignation/pages/HomeStandardChecklistPage";
import { HomeStandardRedesignationRoofingComplianceGeneralInformationPage } from "../../../fortifiedV2/Home/standardRedesignation/pages/HomeStandardRedesignationRoofingComplianceGeneralInformationPage";
import { HomeStandardRedesignationRoofingComplianceRoofCoverPage } from "../../../fortifiedV2/Home/standardRedesignation/pages/HomeStandardRedesignationRoofingComplianceRoofCoverPage";
import { HomeStandardRedesignationRooferConfirmationPage } from "../../../fortifiedV2/Home/standardRedesignation/pages/HomeStandardRedesignationRooferConfirmationPage";
import { ExternalPagesLayout } from "./ExternalPagesLayout";
import { HomeStandardExternalRoofingComplianceGeneralInformationPage } from "../../../fortifiedV2/Home/standard/pages/external/HomeStandardExternalRoofingComplianceGeneralInformationPage";
import { HomeNCIUAExternalRoofingComplianceGeneralInformationPage } from "../../../fortifiedV2/Home/nciua/pages/external/HomeNCIUAExternalRoofingComplianceGeneralInformationPage";
import { HomeStandardExternalRooferConfirmationPage } from "../../../fortifiedV2/Home/standard/pages/external/HomeStandardExternalRooferConfirmationPage";
import { HomeNCIUAExternalRooferConfirmationPage } from "../../../fortifiedV2/Home/nciua/pages/external/HomeExternalNCIUARooferConfirmationPage";
import { HomeStandardExternalRoofingComplianceRoofCoverPage } from "../../../fortifiedV2/Home/standard/pages/external/HomeStandardExternalRoofingComplianceRoofCoverPage";
import { HomeNCIUAExternalRoofingComplianceRoofCoverPage } from "../../../fortifiedV2/Home/nciua/pages/external/HomeNCIUAExternalRoofingComplianceRoofCoverPage";
import { HomeStandardExternalEngineeringComplianceGeneralInformationPage } from "../../../fortifiedV2/Home/standard/pages/external/HomeStandardExternalEngineeringComplianceGeneralInformationPage";
import { HomeStandardExternalEngineeringComplianceGablesPage } from "../../../fortifiedV2/Home/standard/pages/external/HomeStandardExternalEngineeringComplianceGablesPage";
import { HomeStandardExternalEngineeringComplianceChimneyPage } from "../../../fortifiedV2/Home/standard/pages/external/HomeStandardExternalEngineeringComplianceChimneyPage";
import { HomeStandardExternalEngineeringComplianceAttachedStructurePage } from "../../../fortifiedV2/Home/standard/pages/external/HomeStandardExternalEngineeringComplianceAttachedStructurePage";
import { HomeStandardExternalEngineeringComplianceContinuousLoadPathPage } from "../../../fortifiedV2/Home/standard/pages/external/HomeStandardExternalEngineeringComplianceContinuousLoadPathPage";
import { HomeStandardExternalEngineerConfirmationPage } from "../../../fortifiedV2/Home/standard/pages/external/HomeStandardExternalEngineerConfirmationPage";
import { HomeStandardExternalInstallerComplianceGablesPage } from "../../../fortifiedV2/Home/standard/pages/external/HomeStandardExternalInstallerComplianceGablesPage";
import { HomeStandardExternalInstallerComplianceChimneyPage } from "../../../fortifiedV2/Home/standard/pages/external/HomeStandardExternalInstallerComplianceChimneyPage";
import { HomeStandardExternalInstallerComplianceAttachedStructurePage } from "../../../fortifiedV2/Home/standard/pages/external/HomeStandardExternalInstallerComplianceAttachedStructurePage";
import { HomeStandardExternalInstallerComplianceContinuousLoadPathPage } from "../../../fortifiedV2/Home/standard/pages/external/HomeStandardExternalInstallerComplianceContinuousLoadPathPage";
import { HomeStandardExternalInstallingContractorConfirmationPage } from "../../../fortifiedV2/Home/standard/pages/external/HomeStandardExternalInstallingContractorConfirmationPage";
import { uclRouteObject as uclRouteObject_fortifiedCommercialMultifamilyRouteConfig } from "./fortifiedCommercialMultifamilyRouteConfig";
import { permissionStore } from "../../stores/PermissionStore";

const errorWrapper = (
  <div className="fortified-page">
    <ErrorWrapper logOutUser={fortifiedHomeLogout} />
  </div>
);

export const fortifiedHomeRoutePrefix = "/fh";
export const fortifiedHomeStandardRoutePrefix = `${fortifiedHomeRoutePrefix}/standard`;
export const fortifiedHomeStandardRedesignationRoutePrefix = `${fortifiedHomeRoutePrefix}/standard-redesignation`;
export const fortifiedHomeSCSHRoutePrefix = `${fortifiedHomeRoutePrefix}/scsh`;
export const fortifiedHomeNCIUARoutePrefix = `${fortifiedHomeRoutePrefix}/nciua`;
export const fortifiedHomeNCIUARedesignationRoutePrefix = `${fortifiedHomeRoutePrefix}/nciua-redesignation`;

export const uclRouteObject = (): utils.routes.UclRouteObject[] => {
  return [
    {
      path: `${fortifiedHomeRoutePrefix}`,
      label: "Board",
      icon: faList,
      isAvailable: true,
      showInNavigation: true,
      showInSubNavigation: true,
      errorElement: errorWrapper,
      element: <PagesLayout redirectPath="/" errorWrapper={errorWrapper} />,
      children: [
        {
          path: "",
          label: "",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: true,
          errorElement: errorWrapper,
          element: <Board />,
        },
      ],
    },
    {
      path: `${fortifiedHomeRoutePrefix}/evaluation/:id`,
      label: "Evaluation Page",
      icon: undefined,
      isAvailable: true,
      showInNavigation: false,
      showInSubNavigation: false,
      errorElement: errorWrapper,
      element: <PagesLayout redirectPath="/" errorWrapper={errorWrapper} />,
      children: [
        {
          path: "",
          label: "Evaluation Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: <Evaluation />,
        },
      ],
    },
    {
      path: `${fortifiedHomeRoutePrefix}/checklist`,
      label: "Checklist Page",
      icon: undefined,
      isAvailable: true,
      showInNavigation: false,
      showInSubNavigation: false,
      errorElement: errorWrapper,
      element: <PagesLayout redirectPath="/" errorWrapper={errorWrapper} />,
      children: [
        {
          path: "",
          label: "Checklist Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: <Checklist />,
        },
      ],
    },
    {
      path: `${fortifiedHomeRoutePrefix}/reports`,
      label: "Reports",
      icon: faChartSimple,
      isAvailable: true,
      showInNavigation: permissionStore.canViewReportsPage,
      showInSubNavigation: permissionStore.canViewReportsPage,
      errorElement: errorWrapper,
      element: <PagesLayout redirectPath="/" errorWrapper={errorWrapper} />,
      children: [
        {
          path: "",
          label: "Reports",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: <ReportsPage />,
        },
        {
          path: ":slug",
          label: "Reports",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: <ReportViewerPage />,
        },
      ],
    },
    {
      path: `${fortifiedHomeRoutePrefix}/admin`,
      label: "Admin",
      icon: faSliders,
      isAvailable: true,
      showInNavigation: permissionStore.canViewAdminPage,
      showInSubNavigation: permissionStore.canViewAdminPage,
      errorElement: errorWrapper,
      element: <PagesLayout redirectPath="/" errorWrapper={errorWrapper} />,
      children: [
        {
          path: "",
          label: "Admin Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: <AdminPage />,
        },
      ],
    },
    {
      path: `${fortifiedHomeRoutePrefix}/help`,
      label: "Help",
      icon: faQuestionCircle,
      isAvailable: true,
      showInNavigation: true,
      showInSubNavigation: true,
      errorElement: errorWrapper,
      element: <PagesLayout redirectPath="/" errorWrapper={errorWrapper} />,
      children: [
        {
          path: "",
          label: "Help",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: true,
          errorElement: errorWrapper,
          element: <HelpPage />,
        },
      ],
    },
    {
      path: `${fortifiedHomeRoutePrefix}/bid/:id`,
      label: "Bids Page",
      icon: undefined,
      isAvailable: true,
      showInNavigation: false,
      showInSubNavigation: false,
      errorElement: errorWrapper,
      element: <PagesLayout redirectPath="/" errorWrapper={errorWrapper} />,
      children: [
        {
          path: "",
          label: "Bids Page",
          isAvailable: true,
          showInNavigation: false,
          errorElement: errorWrapper,
          showInSubNavigation: false,
          element: <EvaluationBidPage />,
        },
      ],
    },
    {
      path: `${fortifiedHomeRoutePrefix}/external/evaluation-summary/:id`,
      label: "Bids Page",
      icon: undefined,
      isAvailable: true,
      showInNavigation: false,
      showInSubNavigation: false,
      errorElement: errorWrapper,
      element: <PagesLayout redirectPath="/" errorWrapper={errorWrapper} />,
      children: [
        {
          path: "",
          label: "Bids Page",
          isAvailable: true,
          showInNavigation: false,
          errorElement: errorWrapper,
          showInSubNavigation: false,
          element: <ExternalEvaluationSummaryPage />,
        },
      ],
    },
    //Home V2 Routes
    {
      path: `${fortifiedHomeStandardRoutePrefix}/:evaluationId`,
      label: "Forms",
      icon: undefined,
      isAvailable: true,
      showInNavigation: false,
      showInSubNavigation: false,
      errorElement: errorWrapper,
      element: <HomePagesLayout redirectPath={`${fortifiedHomeRoutePrefix}`} />,
      children: [
        {
          path: "payment",
          label: "Payment",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                if (!evaluationId) {
                  return errorWrapper;
                }

                return (
                  <PaymentRedirectPage
                    getCheckoutUrl={async () =>
                      homeStandardEvaluationAPIClient.getApplicationFeeStripeCheckoutLink(
                        evaluationId
                      )
                    }
                  />
                );
              }}
            </WithParams>
          ),
        },
        {
          path: "basic-information",
          label: "Basic Information Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return (
                  <HomeStandardBasicInformationPage
                    evaluationId={evaluationId}
                  />
                );
              }}
            </WithParams>
          ),
        },
        {
          path: "basic-information/:homeStandardBasicInformationFormPart",
          label: "Basic Information Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              evaluationId: string;
              homeStandardBasicInformationFormPart: HomeBasicInformationFormParts;
            }>>
              {({ evaluationId, homeStandardBasicInformationFormPart }) => {
                return (
                  <HomeStandardBasicInformationPage
                    evaluationId={evaluationId}
                    homeStandardBasicInformationFormPart={
                      homeStandardBasicInformationFormPart
                    }
                  />
                );
              }}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
        {
          path: "eligibility",
          label: "Eligibility Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return (
                  <HomeStandardEligibilityPage evaluationId={evaluationId} />
                );
              }}
            </WithParams>
          ),
        },
        {
          path: "eligibility/:formPart",
          label: "Eligibility Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              evaluationId: string;
              formPart: HomeEligibilityFormParts;
            }>>
              {({ evaluationId, formPart }) => {
                return (
                  <HomeStandardEligibilityPage
                    evaluationId={evaluationId}
                    formPart={formPart}
                  />
                );
              }}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
        {
          path: "roofing-compliance-general-information",
          label: "Roofing Compliance General Information Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return (
                  <HomeStandardRoofingComplianceGeneralInformationPage
                    evaluationId={evaluationId}
                  />
                );
              }}
            </WithParams>
          ),
        },
        {
          path: "roofing-compliance-general-information/:formPart",
          label: "Roofing Compliance General Information Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              evaluationId: string;
              formPart: BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts;
            }>>
              {({ evaluationId, formPart }) => {
                return (
                  <HomeStandardRoofingComplianceGeneralInformationPage
                    evaluationId={evaluationId}
                    formParts={formPart}
                  />
                );
              }}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
        {
          path: "roofing-compliance-roof-cover",
          label: "Roofing Compliance Roof Cover Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return (
                  <HomeStandardRoofingComplianceRoofCoverPage
                    evaluationId={evaluationId}
                  />
                );
              }}
            </WithParams>
          ),
        },
        {
          path: "roofing-compliance-roof-cover/:formPart",
          label: "Roofing Compliance Roof Cover Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              evaluationId: string;
              formPart: BaseHomeRoofingComplianceRoofCoverFormParts;
            }>>
              {({ evaluationId, formPart }) => {
                return (
                  <HomeStandardRoofingComplianceRoofCoverPage
                    evaluationId={evaluationId}
                    formParts={formPart}
                  />
                );
              }}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
        {
          path: "roofer-confirmation",
          label: "Roofer Confirmation Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return (
                  <HomeStandardRooferConfirmationPage
                    evaluationId={evaluationId}
                  />
                );
              }}
            </WithParams>
          ),
        },
        {
          path: "roofer-confirmation/:formPart",
          label: "Roofer Confirmation Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              evaluationId: string;
              formPart: BaseHomeEvaluationRooferConfirmationFormParts;
            }>>
              {({ evaluationId, formPart }) => {
                return (
                  <HomeStandardRooferConfirmationPage
                    evaluationId={evaluationId}
                    rooferConfirmationFormPart={formPart}
                  />
                );
              }}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
        {
          path: "engineer-confirmation",
          label: "Engineer Confirmation Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return (
                  <HomeStandardEngineerConfirmationPage
                    evaluationId={evaluationId}
                  />
                );
              }}
            </WithParams>
          ),
        },
        {
          path: "engineer-confirmation/:formPart",
          label: "Engineer Confirmation Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              evaluationId: string;
              formPart: BaseHomeEvaluationEngineerConfirmationFormParts;
            }>>
              {({ evaluationId, formPart }) => {
                return (
                  <HomeStandardEngineerConfirmationPage
                    evaluationId={evaluationId}
                    engineerConfirmationFormPart={formPart}
                  />
                );
              }}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
        {
          path: "silver-gold-compliance-general-information",
          label: "Silver/Gold Compliance General Information Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return (
                  <HomeStandardSilverGoldComplianceGeneralInformationPage
                    evaluationId={evaluationId}
                  />
                );
              }}
            </WithParams>
          ),
        },
        {
          path: "silver-gold-compliance-general-information/:formPart",
          label: "Silver/Gold Compliance General Information Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              evaluationId: string;
              formPart: BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts;
            }>>
              {({ evaluationId, formPart }) => {
                return (
                  <HomeStandardSilverGoldComplianceGeneralInformationPage
                    evaluationId={evaluationId}
                    silverGoldComplianceGeneralInformationFormPart={formPart}
                  />
                );
              }}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
        {
          path: "installing-contractor-confirmation",
          label: "Installing Contractor Confirmation Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return (
                  <HomeStandardInstallingContractorConfirmationPage
                    evaluationId={evaluationId}
                  />
                );
              }}
            </WithParams>
          ),
        },
        {
          path: "installing-contractor-confirmation/:formPart",
          label: "Installing Contractor Confirmation Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              evaluationId: string;
              formPart: BaseHomeEvaluationInstallingContractorConfirmationFormParts;
            }>>
              {({ evaluationId, formPart }) => {
                return (
                  <HomeStandardInstallingContractorConfirmationPage
                    evaluationId={evaluationId}
                    installingContractorConfirmationFormPart={formPart}
                  />
                );
              }}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
        {
          path: "installer-compliance-gables",
          label: "Installer Compliance Gables Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return (
                  <HomeStandardInstallerComplianceGablesPage
                    evaluationId={evaluationId}
                  />
                );
              }}
            </WithParams>
          ),
        },
        {
          path: "installer-compliance-gables/:formPart",
          label: "Installer Compliance Gables Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              evaluationId: string;
              formPart: BaseHomeEvaluationInstallerComplianceGablesFormParts;
            }>>
              {({ evaluationId, formPart }) => {
                return (
                  <HomeStandardInstallerComplianceGablesPage
                    evaluationId={evaluationId}
                    installerComplianceGablesFormPart={formPart}
                  />
                );
              }}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
        {
          path: "installer-compliance-continuous-load-path",
          label: "Installer Compliance Continuous Load Path Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return (
                  <HomeStandardInstallerComplianceContinuousLoadPathPage
                    evaluationId={evaluationId}
                  />
                );
              }}
            </WithParams>
          ),
        },
        {
          path: "installer-compliance-continuous-load-path/:formPart",
          label: "Installer Compliance Continuous Load Path Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              evaluationId: string;
              formPart: BaseHomeEvaluationInstallerComplianceContinuousLoadPathFormParts;
            }>>
              {({ evaluationId, formPart }) => {
                return (
                  <HomeStandardInstallerComplianceContinuousLoadPathPage
                    evaluationId={evaluationId}
                    installerComplianceContinuousLoadPathFormPart={formPart}
                  />
                );
              }}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
        {
          path: "installer-compliance-chimney",
          label: "Installer Compliance Chimney Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return (
                  <HomeStandardInstallerComplianceChimneyPage
                    evaluationId={evaluationId}
                  />
                );
              }}
            </WithParams>
          ),
        },
        {
          path: "installer-compliance-chimney/:formPart",
          label: "Installer Compliance Chimney Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              evaluationId: string;
              formPart: BaseHomeEvaluationInstallerComplianceChimneyFormParts;
            }>>
              {({ evaluationId, formPart }) => {
                return (
                  <HomeStandardInstallerComplianceChimneyPage
                    evaluationId={evaluationId}
                    installerComplianceChimneyFormPart={formPart}
                  />
                );
              }}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
        {
          path: "installer-compliance-attached-structure",
          label: "Installer Compliance Attached Structure Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return (
                  <HomeStandardInstallerComplianceAttachedStructurePage
                    evaluationId={evaluationId}
                  />
                );
              }}
            </WithParams>
          ),
        },
        {
          path: "installer-compliance-attached-structure/:formPart",
          label: "Installer Compliance Attached Structure Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              evaluationId: string;
              formPart: BaseHomeEvaluationInstallerComplianceAttachedStructureFormParts;
            }>>
              {({ evaluationId, formPart }) => {
                return (
                  <HomeStandardInstallerComplianceAttachedStructurePage
                    evaluationId={evaluationId}
                    installerComplianceAttachedStructureFormPart={formPart}
                  />
                );
              }}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
        {
          path: "engineering-compliance-general-information",
          label: "Engineering Compliance General Information Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return (
                  <HomeStandardEngineeringComplianceGeneralInformationPage
                    evaluationId={evaluationId}
                  />
                );
              }}
            </WithParams>
          ),
        },
        {
          path: "engineering-compliance-general-information/:formPart",
          label: "Engineering Compliance General Information Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              evaluationId: string;
              formPart: BaseHomeEvaluationEngineeringComplianceGeneralInformationFormParts;
            }>>
              {({ evaluationId, formPart }) => {
                return (
                  <HomeStandardEngineeringComplianceGeneralInformationPage
                    evaluationId={evaluationId}
                    engineeringComplianceGeneralInformationFormPart={formPart}
                  />
                );
              }}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
        {
          path: "engineering-compliance-gables",
          label: "Engineering Compliance Gables Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return (
                  <HomeStandardEngineeringComplianceGablesPage
                    evaluationId={evaluationId}
                  />
                );
              }}
            </WithParams>
          ),
        },
        {
          path: "engineering-compliance-gables/:formPart",
          label: "Engineering Compliance Gables Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              evaluationId: string;
              formPart: BaseHomeEvaluationEngineeringComplianceGablesFormParts;
            }>>
              {({ evaluationId, formPart }) => {
                return (
                  <HomeStandardEngineeringComplianceGablesPage
                    evaluationId={evaluationId}
                    engineeringComplianceGablesFormPart={formPart}
                  />
                );
              }}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
        {
          path: "engineering-compliance-continuous-load-path",
          label: "Engineering Compliance Continuous Load Path Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return (
                  <HomeStandardEngineeringComplianceContinuousLoadPathPage
                    evaluationId={evaluationId}
                  />
                );
              }}
            </WithParams>
          ),
        },
        {
          path: "engineering-compliance-continuous-load-path/:formPart",
          label: "Engineering Compliance Continuous Load Path Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              evaluationId: string;
              formPart: BaseHomeEvaluationEngineeringComplianceContinuousLoadPathFormParts;
            }>>
              {({ evaluationId, formPart }) => {
                return (
                  <HomeStandardEngineeringComplianceContinuousLoadPathPage
                    evaluationId={evaluationId}
                    engineeringComplianceContinuousLoadPathFormPart={formPart}
                  />
                );
              }}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
        {
          path: "engineering-compliance-chimney",
          label: "Engineering Compliance Chimney Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return (
                  <HomeStandardEngineeringComplianceChimneyPage
                    evaluationId={evaluationId}
                  />
                );
              }}
            </WithParams>
          ),
        },
        {
          path: "engineering-compliance-chimney/:formPart",
          label: "Engineering Compliance Chimney Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              evaluationId: string;
              formPart: BaseHomeEvaluationEngineeringComplianceChimneyFormParts;
            }>>
              {({ evaluationId, formPart }) => {
                return (
                  <HomeStandardEngineeringComplianceChimneyPage
                    evaluationId={evaluationId}
                    engineeringComplianceChimneyFormPart={formPart}
                  />
                );
              }}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
        {
          path: "engineering-compliance-attached-structure",
          label: "Engineering Compliance Attached Structure Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return (
                  <HomeStandardEngineeringComplianceAttachedStructurePage
                    evaluationId={evaluationId}
                  />
                );
              }}
            </WithParams>
          ),
        },
        {
          path: "engineering-compliance-attached-structure/:formPart",
          label: "Engineering Compliance Attached Structure Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              evaluationId: string;
              formPart: BaseHomeEvaluationEngineeringComplianceAttachedStructureFormParts;
            }>>
              {({ evaluationId, formPart }) => {
                return (
                  <HomeStandardEngineeringComplianceAttachedStructurePage
                    evaluationId={evaluationId}
                    engineeringComplianceAttachedStructureFormPart={formPart}
                  />
                );
              }}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
      ],
    },
    {
      path: `${fortifiedHomeStandardRoutePrefix}/external`,
      label: "External Home Standard Forms",
      icon: undefined,
      isAvailable: true,
      showInNavigation: false,
      showInSubNavigation: true,
      errorElement: errorWrapper,
      element: (
        <ExternalPagesLayout redirectPath={`${fortifiedHomeRoutePrefix}`} />
      ),
      children: [
        {
          path: "roofing-compliance-general-information/:id",
          label: "External Roofing Compliance General Information Form",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: true,
          element: (
            <HomeStandardExternalRoofingComplianceGeneralInformationPage />
          ),
        },
        {
          path: "roofing-compliance-roof-cover/:id",
          label: "External Roofing Compliance Roof Cover Form",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: true,
          element: <HomeStandardExternalRoofingComplianceRoofCoverPage />,
        },
        {
          path: "roofer-confirmation/:id",
          label: "External Roofer Confirmation Form",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: true,
          element: <HomeStandardExternalRooferConfirmationPage />,
        },
        {
          path: "engineering-compliance-general-information/:id",
          label: "External Engineering Compliance General Information Form",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: true,
          element: (
            <HomeStandardExternalEngineeringComplianceGeneralInformationPage />
          ),
        },
        {
          path: "engineering-compliance-gables/:id",
          label: "External Engineering Compliance Gables Form",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: true,
          element: <HomeStandardExternalEngineeringComplianceGablesPage />,
        },
        {
          path: "engineering-compliance-chimney/:id",
          label: "External Engineering Compliance Chimney Form",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: true,
          element: <HomeStandardExternalEngineeringComplianceChimneyPage />,
        },
        {
          path: "engineering-compliance-attached-structure/:id",
          label: "External Engineering Compliance Attached Structure Form",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: true,
          element: (
            <HomeStandardExternalEngineeringComplianceAttachedStructurePage />
          ),
        },
        {
          path: "engineering-compliance-continuous-load-path/:id",
          label: "External Engineering Compliance Continuous Load Path Form",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: true,
          element: (
            <HomeStandardExternalEngineeringComplianceContinuousLoadPathPage />
          ),
        },
        {
          path: "engineer-confirmation/:id",
          label: "External Engineer Confirmation Form",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: true,
          element: <HomeStandardExternalEngineerConfirmationPage />,
        },
        {
          path: "installer-compliance-gables/:id",
          label: "External Installer Compliance Gables Form",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: true,
          element: <HomeStandardExternalInstallerComplianceGablesPage />,
        },
        {
          path: "installer-compliance-chimney/:id",
          label: "External Installer Compliance Chimney Form",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: true,
          element: <HomeStandardExternalInstallerComplianceChimneyPage />,
        },
        {
          path: "installer-compliance-attached-structure/:id",
          label: "External Installer Compliance Attached Structure Form",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: true,
          element: (
            <HomeStandardExternalInstallerComplianceAttachedStructurePage />
          ),
        },
        {
          path: "installer-compliance-continuous-load-path/:id",
          label: "External Installer Compliance Continuous Load Path Form",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: true,
          element: (
            <HomeStandardExternalInstallerComplianceContinuousLoadPathPage />
          ),
        },
        {
          path: "installing-contractor-confirmation/:id",
          label: "External Installing Contractor Confirmation Form",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: true,
          element: <HomeStandardExternalInstallingContractorConfirmationPage />,
        },
      ],
    },
    {
      path: `${fortifiedHomeStandardRoutePrefix}/iteration-engine/:evaluationId`,
      label: "Iteration Engine",
      isAvailable: true,
      showInNavigation: false,
      showInSubNavigation: false,
      errorElement: errorWrapper,
      element: <HomePagesLayout redirectPath={`${fortifiedHomeRoutePrefix}`} />,
      children: [
        {
          path: "",
          label: "",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return (
                  <HomeStandardIterationEnginePage
                    evaluationId={evaluationId}
                  />
                );
              }}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
      ],
    },
    {
      path: `${fortifiedHomeStandardRoutePrefix}/checklist/:evaluationId`,
      label: "Checklist",
      isAvailable: true,
      showInNavigation: false,
      showInSubNavigation: false,
      errorElement: errorWrapper,
      element: <HomePagesLayout redirectPath={`${fortifiedHomeRoutePrefix}`} />,
      children: [
        {
          path: "",
          label: "",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return (
                  <HomeStandardChecklistPage evaluationId={evaluationId} />
                );
              }}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
      ],
    },
    //Home Standard redesignation routes
    {
      path: `${fortifiedHomeStandardRedesignationRoutePrefix}/:evaluationId`,
      label: "Forms",
      icon: undefined,
      isAvailable: true,
      showInNavigation: false,
      showInSubNavigation: false,
      errorElement: errorWrapper,
      element: <HomePagesLayout redirectPath={`${fortifiedHomeRoutePrefix}`} />,
      children: [
        {
          path: "payment",
          label: "Payment",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                if (!evaluationId) {
                  return errorWrapper;
                }

                return (
                  <PaymentRedirectPage
                    getCheckoutUrl={async () =>
                      homeStandardRedesignationAPIClient.getApplicationFeeStripeCheckoutLink(
                        evaluationId
                      )
                    }
                  />
                );
              }}
            </WithParams>
          ),
        },
        {
          path: "redesignation-form",
          label: "Redesignation Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return (
                  <HomeStandardRedesignationFormPage
                    evaluationId={evaluationId}
                  />
                );
              }}
            </WithParams>
          ),
        },
        {
          path: "redesignation-form/:formPart",
          label: "Redesignation Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              evaluationId: string;
              formPart: BaseHomeEvaluationRedesignationFormParts;
            }>>
              {({ evaluationId, formPart }) => {
                return (
                  <HomeStandardRedesignationFormPage
                    evaluationId={evaluationId}
                    redesignationFormPart={formPart}
                  />
                );
              }}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
        {
          path: "homeowner-authorization",
          label: "Homeowner Authorization Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return (
                  <HomeStandardHomeownerAuthorizationPage
                    evaluationId={evaluationId}
                  />
                );
              }}
            </WithParams>
          ),
        },
        {
          path: "homeowner-authorization/:formPart",
          label: "Homeowner Authorization Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              evaluationId: string;
              formPart: BaseHomeEvaluationHomeownerAuthorizationFormParts;
            }>>
              {({ evaluationId, formPart }) => {
                return (
                  <HomeStandardHomeownerAuthorizationPage
                    evaluationId={evaluationId}
                    homeownerAuthorizationFormPart={formPart}
                  />
                );
              }}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
        {
          path: "roofing-compliance-general-information",
          label: "Roofing Compliance General Information Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return (
                  <HomeStandardRedesignationRoofingComplianceGeneralInformationPage
                    evaluationId={evaluationId}
                  />
                );
              }}
            </WithParams>
          ),
        },
        {
          path: "roofing-compliance-general-information/:formPart",
          label: "Roofing Compliance General Information Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              evaluationId: string;
              formPart: BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts;
            }>>
              {({ evaluationId, formPart }) => {
                return (
                  <HomeStandardRedesignationRoofingComplianceGeneralInformationPage
                    evaluationId={evaluationId}
                    formParts={formPart}
                  />
                );
              }}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
        {
          path: "roofing-compliance-roof-cover",
          label: "Roofing Compliance Roof Cover Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return (
                  <HomeStandardRedesignationRoofingComplianceRoofCoverPage
                    evaluationId={evaluationId}
                  />
                );
              }}
            </WithParams>
          ),
        },
        {
          path: "roofing-compliance-roof-cover/:formPart",
          label: "Roofing Compliance Roof Cover Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              evaluationId: string;
              formPart: BaseHomeRoofingComplianceRoofCoverFormParts;
            }>>
              {({ evaluationId, formPart }) => {
                return (
                  <HomeStandardRedesignationRoofingComplianceRoofCoverPage
                    evaluationId={evaluationId}
                    formParts={formPart}
                  />
                );
              }}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
        {
          path: "roofer-confirmation",
          label: "Roofer Confirmation Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return (
                  <HomeStandardRedesignationRooferConfirmationPage
                    evaluationId={evaluationId}
                  />
                );
              }}
            </WithParams>
          ),
        },
        {
          path: "roofer-confirmation/:formPart",
          label: "Roofer Confirmation Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              evaluationId: string;
              formPart: BaseHomeEvaluationRooferConfirmationFormParts;
            }>>
              {({ evaluationId, formPart }) => {
                return (
                  <HomeStandardRedesignationRooferConfirmationPage
                    evaluationId={evaluationId}
                    rooferConfirmationFormPart={formPart}
                  />
                );
              }}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
      ],
    },
    {
      path: `${fortifiedHomeStandardRedesignationRoutePrefix}/iteration-engine/:evaluationId`,
      label: "Iteration Engine",
      isAvailable: true,
      showInNavigation: false,
      showInSubNavigation: false,
      errorElement: errorWrapper,
      element: <HomePagesLayout redirectPath={`${fortifiedHomeRoutePrefix}`} />,
      children: [
        {
          path: "",
          label: "",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return (
                  <HomeStandardRedesignationIterationEnginePage
                    evaluationId={evaluationId}
                  />
                );
              }}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
      ],
    },
    {
      path: `${fortifiedHomeStandardRedesignationRoutePrefix}/checklist/:evaluationId`,
      label: "Checklist",
      isAvailable: true,
      showInNavigation: false,
      showInSubNavigation: false,
      errorElement: errorWrapper,
      element: <HomePagesLayout redirectPath={`${fortifiedHomeRoutePrefix}`} />,
      children: [
        {
          path: "",
          label: "",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return (
                  <HomeStandardRedesignationChecklistPage
                    evaluationId={evaluationId}
                  />
                );
              }}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
      ],
    },
    //scsh routes
    {
      path: `${fortifiedHomeSCSHRoutePrefix}/:evaluationId`,
      label: "Forms",
      icon: undefined,
      isAvailable: true,
      showInNavigation: false,
      showInSubNavigation: false,
      errorElement: errorWrapper,
      element: <HomePagesLayout redirectPath={`${fortifiedHomeRoutePrefix}`} />,
      children: [
        {
          path: "payment",
          label: "Payment",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                if (!evaluationId) {
                  return errorWrapper;
                }

                return (
                  <PaymentRedirectPage
                    getCheckoutUrl={async () =>
                      homeSCSHEvaluationAPIClient.getApplicationFeeStripeCheckoutLink(
                        evaluationId
                      )
                    }
                  />
                );
              }}
            </WithParams>
          ),
        },
        {
          path: "basic-information",
          label: "Basic Information Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return (
                  <HomeSCSHBasicInformationFormPage
                    evaluationId={evaluationId}
                  />
                );
              }}
            </WithParams>
          ),
        },
        {
          path: "basic-information/:homeSCSHBasicInformationFormPart",
          label: "Basic Information Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              evaluationId: string;
              homeSCSHBasicInformationFormPart: HomeBasicInformationFormParts;
            }>>
              {({ evaluationId, homeSCSHBasicInformationFormPart }) => {
                return (
                  <HomeSCSHBasicInformationFormPage
                    evaluationId={evaluationId}
                    homeSCSHBasicInformationFormPart={
                      homeSCSHBasicInformationFormPart
                    }
                  />
                );
              }}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
        {
          path: "evaluation-form",
          label: "SCSH Evaluation Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return (
                  <HomeSCSHEvaluationFormPage evaluationId={evaluationId} />
                );
              }}
            </WithParams>
          ),
        },
        {
          path: "evaluation-form/:homeSCSHEvaluationFormPart",
          label: "SCSH Evaluation Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              evaluationId: string;
              homeSCSHEvaluationFormPart: SCSHEvaluationForm_FormParts;
            }>>
              {({ evaluationId, homeSCSHEvaluationFormPart }) => {
                return (
                  <HomeSCSHEvaluationFormPage
                    evaluationId={evaluationId}
                    homeSCSHEvaluationFormPart={homeSCSHEvaluationFormPart}
                  />
                );
              }}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
      ],
    },
    {
      path: `${fortifiedHomeSCSHRoutePrefix}/iteration-engine/:evaluationId`,
      label: "Iteration Engine",
      isAvailable: true,
      showInNavigation: false,
      showInSubNavigation: false,
      errorElement: errorWrapper,
      element: <HomePagesLayout redirectPath={`${fortifiedHomeRoutePrefix}`} />,
      children: [
        {
          path: "",
          label: "",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return (
                  <HomeSCSHIterationEnginePage evaluationId={evaluationId} />
                );
              }}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
      ],
    },
    {
      path: `${fortifiedHomeSCSHRoutePrefix}/checklist/:evaluationId`,
      label: "Checklist",
      isAvailable: true,
      showInNavigation: false,
      showInSubNavigation: false,
      errorElement: errorWrapper,
      element: <HomePagesLayout redirectPath={`${fortifiedHomeRoutePrefix}`} />,
      children: [
        {
          path: "",
          label: "",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return <HomeSCSHChecklistPage evaluationId={evaluationId} />;
              }}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
      ],
    },
    //nciua routes
    {
      path: `${fortifiedHomeNCIUARoutePrefix}/:evaluationId`,
      label: "Forms",
      icon: undefined,
      isAvailable: true,
      showInNavigation: false,
      showInSubNavigation: false,
      errorElement: errorWrapper,
      element: <HomePagesLayout redirectPath={`${fortifiedHomeRoutePrefix}`} />,
      children: [
        {
          path: "payment",
          label: "Payment",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                if (!evaluationId) {
                  return errorWrapper;
                }

                return (
                  <PaymentRedirectPage
                    getCheckoutUrl={async () =>
                      homeNCIUAEvaluationAPIClient.getApplicationFeeStripeCheckoutLink(
                        evaluationId
                      )
                    }
                  />
                );
              }}
            </WithParams>
          ),
        },
        {
          path: "basic-information",
          label: "NCIUA Basic Information Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return (
                  <HomeNCIUABasicInformationPage evaluationId={evaluationId} />
                );
              }}
            </WithParams>
          ),
        },
        {
          path: "basic-information/:homeNCIUABasicInformationFormPart",
          label: "NCIUA Basic Information Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              evaluationId: string;
              homeNCIUABasicInformationFormPart: HomeBasicInformationFormParts;
            }>>
              {({ evaluationId, homeNCIUABasicInformationFormPart }) => {
                return (
                  <HomeNCIUABasicInformationPage
                    evaluationId={evaluationId}
                    homeNCIUABasicInformationFormPart={
                      homeNCIUABasicInformationFormPart
                    }
                  />
                );
              }}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
        {
          path: "evaluation-form",
          label: "NCIUA Evaluation Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return (
                  <HomeNCIUAEvaluationFormPage evaluationId={evaluationId} />
                );
              }}
            </WithParams>
          ),
        },
        {
          path: "evaluation-form/:homeNCIUAEvaluationFormPart",
          label: "NCIUA Evaluation Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              evaluationId: string;
              homeNCIUAEvaluationFormPart: NCIUAEvaluationForm_FormParts;
            }>>
              {({ evaluationId, homeNCIUAEvaluationFormPart }) => {
                return (
                  <HomeNCIUAEvaluationFormPage
                    evaluationId={evaluationId}
                    homeNCIUAEvaluationFormPart={homeNCIUAEvaluationFormPart}
                  />
                );
              }}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
        {
          path: "roofing-compliance-roof-cover",
          label: "Roofing Compliance Roof Cover Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return (
                  <HomeNCIUARoofingComplianceRoofCoverPage
                    evaluationId={evaluationId}
                  />
                );
              }}
            </WithParams>
          ),
        },
        {
          path: "roofing-compliance-roof-cover/:formPart",
          label: "Roofing Compliance Roof Cover Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              evaluationId: string;
              formPart: BaseHomeRoofingComplianceRoofCoverFormParts;
            }>>
              {({ evaluationId, formPart }) => {
                return (
                  <HomeNCIUARoofingComplianceRoofCoverPage
                    evaluationId={evaluationId}
                    formParts={formPart}
                  />
                );
              }}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
        {
          path: "roofing-compliance-general-information",
          label: "Roofing Compliance: General Information Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return (
                  <HomeNCIUARoofingComplianceGeneralInformationPage
                    evaluationId={evaluationId}
                  />
                );
              }}
            </WithParams>
          ),
        },
        {
          path: "roofing-compliance-general-information/:formPart",
          label: "Roofing Compliance: General Information Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              evaluationId: string;
              formPart: BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts;
            }>>
              {({ evaluationId, formPart }) => {
                return (
                  <HomeNCIUARoofingComplianceGeneralInformationPage
                    evaluationId={evaluationId}
                    formParts={formPart}
                  />
                );
              }}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
        {
          path: "roofer-confirmation",
          label: "Roofer Confirmation Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return (
                  <HomeNCIUARooferConfirmationPage
                    evaluationId={evaluationId}
                  />
                );
              }}
            </WithParams>
          ),
        },
        {
          path: "roofer-confirmation/:formPart",
          label: "Roofer Confirmation Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              evaluationId: string;
              formPart: BaseHomeEvaluationRooferConfirmationFormParts;
            }>>
              {({ evaluationId, formPart }) => {
                return (
                  <HomeNCIUARooferConfirmationPage
                    evaluationId={evaluationId}
                    formParts={formPart}
                  />
                );
              }}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
        {
          path: "eligibility",
          label: "Eligibility Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return <HomeNCIUAEligibilityPage evaluationId={evaluationId} />;
              }}
            </WithParams>
          ),
        },
        {
          path: "eligibility/:formPart",
          label: "Eligibility Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              evaluationId: string;
              formPart: HomeEligibilityFormParts;
            }>>
              {({ evaluationId, formPart }) => {
                return (
                  <HomeNCIUAEligibilityPage
                    evaluationId={evaluationId}
                    formPart={formPart}
                  />
                );
              }}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
      ],
    },
    {
      path: `${fortifiedHomeNCIUARoutePrefix}/external`,
      label: "External Home NCIUA Forms",
      icon: undefined,
      isAvailable: true,
      showInNavigation: false,
      showInSubNavigation: true,
      errorElement: errorWrapper,
      element: (
        <ExternalPagesLayout redirectPath={`${fortifiedHomeRoutePrefix}`} />
      ),
      children: [
        {
          path: "roofing-compliance-general-information/:id",
          label: "External Roofing Compliance General Information Form",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: true,
          element: <HomeNCIUAExternalRoofingComplianceGeneralInformationPage />,
        },
        {
          path: "roofing-compliance-roof-cover/:id",
          label: "External Roofing Compliance Roof Cover Form",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: true,
          element: <HomeNCIUAExternalRoofingComplianceRoofCoverPage />,
        },
        {
          path: "roofer-confirmation/:id",
          label: "External Roofer Confirmation Form",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: true,
          element: <HomeNCIUAExternalRooferConfirmationPage />,
        },
      ],
    },
    {
      path: `${fortifiedHomeNCIUARoutePrefix}/iteration-engine/:evaluationId`,
      label: "Iteration Engine",
      isAvailable: true,
      showInNavigation: false,
      showInSubNavigation: false,
      errorElement: errorWrapper,
      element: <HomePagesLayout redirectPath={`${fortifiedHomeRoutePrefix}`} />,
      children: [
        {
          path: "",
          label: "",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return (
                  <HomeNCIUAIterationEnginePage evaluationId={evaluationId} />
                );
              }}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
      ],
    },
    {
      path: `${fortifiedHomeNCIUARoutePrefix}/checklist/:evaluationId`,
      label: "Checklist",
      isAvailable: true,
      showInNavigation: false,
      showInSubNavigation: false,
      errorElement: errorWrapper,
      element: <HomePagesLayout redirectPath={`${fortifiedHomeRoutePrefix}`} />,
      children: [
        {
          path: "",
          label: "",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return <HomeNCIUAChecklistPage evaluationId={evaluationId} />;
              }}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
      ],
    },
    //nciua redesignation routes
    {
      path: `${fortifiedHomeNCIUARedesignationRoutePrefix}/:evaluationId`,
      label: "Forms",
      icon: undefined,
      isAvailable: true,
      showInNavigation: false,
      showInSubNavigation: false,
      errorElement: errorWrapper,
      element: <HomePagesLayout redirectPath={`${fortifiedHomeRoutePrefix}`} />,
      children: [
        {
          path: "payment",
          label: "Payment",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                if (!evaluationId) {
                  return errorWrapper;
                }

                return (
                  <PaymentRedirectPage
                    getCheckoutUrl={async () =>
                      homeNCIUARedesignationAPIClient.getApplicationFeeStripeCheckoutLink(
                        evaluationId
                      )
                    }
                  />
                );
              }}
            </WithParams>
          ),
        },
        {
          path: "homeowner-authorization",
          label: "Homeowner Authorization Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return (
                  <HomeNCIUARedesignationHomeownerAuthorizationPage
                    evaluationId={evaluationId}
                  />
                );
              }}
            </WithParams>
          ),
        },
        {
          path: "homeowner-authorization/:formPart",
          label: "Homeowner Authorization Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              evaluationId: string;
              formPart: BaseHomeEvaluationHomeownerAuthorizationFormParts;
            }>>
              {({ evaluationId, formPart }) => {
                return (
                  <HomeNCIUARedesignationHomeownerAuthorizationPage
                    evaluationId={evaluationId}
                    homeownerAuthorizationFormPart={formPart}
                  />
                );
              }}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
        {
          path: "redesignation-form",
          label: "Redesignation Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          errorElement: errorWrapper,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return (
                  <HomeNCIUARedesignationFormPage evaluationId={evaluationId} />
                );
              }}
            </WithParams>
          ),
        },
        {
          path: "redesignation-form/:formPart",
          label: "Redesignation Form Page",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              evaluationId: string;
              formPart: BaseHomeEvaluationRedesignationFormParts;
            }>>
              {({ evaluationId, formPart }) => {
                return (
                  <HomeNCIUARedesignationFormPage
                    evaluationId={evaluationId}
                    redesignationFormPart={formPart}
                  />
                );
              }}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
      ],
    },
    {
      path: `${fortifiedHomeNCIUARedesignationRoutePrefix}/iteration-engine/:evaluationId`,
      label: "Iteration Engine",
      isAvailable: true,
      showInNavigation: false,
      showInSubNavigation: false,
      errorElement: errorWrapper,
      element: <HomePagesLayout redirectPath={`${fortifiedHomeRoutePrefix}`} />,
      children: [
        {
          path: "",
          label: "",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return (
                  <HomeNCIUARedesignationIterationEnginePage
                    evaluationId={evaluationId}
                  />
                );
              }}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
      ],
    },
    {
      path: `${fortifiedHomeNCIUARedesignationRoutePrefix}/checklist/:evaluationId`,
      label: "Checklist",
      isAvailable: true,
      showInNavigation: false,
      showInSubNavigation: false,
      errorElement: errorWrapper,
      element: <HomePagesLayout redirectPath={`${fortifiedHomeRoutePrefix}`} />,
      children: [
        {
          path: "",
          label: "",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return (
                  <HomeNCIUARedesignationChecklistPage
                    evaluationId={evaluationId}
                  />
                );
              }}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
      ],
    },
  ] as utils.routes.UclRouteObject[];
};

export const fortified_Home_browserRouter = createBrowserRouter(
  [
    ...uclRouteObject(),
    ...uclRouteObject_fortifiedCommercialMultifamilyRouteConfig(),
  ],
  {
    basename: process.env.REACT_APP_PATH_PREFIX,
  }
);
