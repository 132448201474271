import { useContext, useEffect, useState } from "react";
import { fortifiedHomeNCIUARedesignationRoutePrefix } from "../../../../../foritfied/pages/index/fortifiedHomeRouteConfig";
import { UserRole } from "../../../../../foritfied/stores/FortifiedUserStore";
import { EvaluationStep } from "../../../../../foritfied/types/evaluation/Evaluation";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import {
  errorStore,
  genericErrorMessage,
} from "../../../../../common/Components/Error/ErrorStore";
import { FormRowProps } from "../../../common/components/Checklist/ChecklistTabPanelV2";
import ChecklistContainerV2, {
  ChecklistTabItem,
} from "../../../common/components/Checklist/ChecklistContainerV2";
import { ProductSubtypes } from "../../../../Common/types/Evaluation/BaseEvaluation";
import { useHomeNCIUARedesignation } from "../../customHooks/useHomeNCIUARedesignation";
import { homeNCIUARedesignationAPIClient } from "../../evaluation/lib/apiClients/homeNCIUARedesignationAPIClient";
import { AppHeaderContext } from "../../../../../wildfire/components/Provider/AppHeaderContext";

interface ChecklistContainerProps {
  evaluationId: string;
}

const HomeNCIUARedesignationChecklist: React.FC<ChecklistContainerProps> = (
  props
) => {
  const [selectedTabId, setSelectedTabId] = useState(0);

  const { homeNCIUARedesignation, setHomeNCIUARedesignation } =
    useHomeNCIUARedesignation(props.evaluationId);

  const [hasFormsLoading, setHasFormsLoading] = useState(false);

  useEffect(() => {
    return () => {
      setDefaultHeader();
    };
  }, []);

  const { setFotifiedHomeEvaluationHeader, setDefaultHeader } =
    useContext(AppHeaderContext);

  useEffect(() => {
    // if (
    //   homeStandardRedesignation?.homeStandardRedesignation?.eligibilityForm
    //     ?.isEvaluationFormCreationInProgress
    // ) {
    //   setHasFormsLoading(true);
    // }
    if (homeNCIUARedesignation) {
      setFotifiedHomeEvaluationHeader(homeNCIUARedesignation);
    }
  }, [homeNCIUARedesignation]);

  useEffect(() => {
    if (hasFormsLoading) {
      const interval = setInterval(async () => {
        await homeNCIUARedesignationAPIClient
          .getHomeNCIUARedesignation(props.evaluationId)
          .then((response) => {
            setHomeNCIUARedesignation(response);

            //Set Loading and fetching state if an event is occuring in the background.
            //Currently used for form generation and payment step movement
            // const isLoading =
            //   response?.homeStandardRedesignation?.eligibilityForm
            //     ?.isEvaluationFormCreationInProgress === true ||
            //   response.homeStandardRedesignation?.canMoveToPaymentStep === true;

            if (
              //!isLoading
              false
            ) {
              setHasFormsLoading(false);
              clearInterval(interval);
            }
          })
          .catch(() => {
            errorStore.setErrorMessage(genericErrorMessage);
          });
      }, 3000); // Check every 3 seconds

      return () => clearInterval(interval);
    }
  }, [hasFormsLoading]);

  const forms: FormRowProps[] = [
    {
      formName: "Homeowner Authorization Form",
      formURL: `${fortifiedHomeNCIUARedesignationRoutePrefix}/${homeNCIUARedesignation?.id}/homeowner-authorization`,
      isCompleted:
        homeNCIUARedesignation?.homeNCIUARedesignation
          ?.homeownerAuthorizationForm?.areAllRequiredFieldsComplete || false,
      isDisabled: false,
      formsNeedingCompletion: [],
      completerRoles:
        homeNCIUARedesignation?.homeNCIUARedesignation
          ?.homeownerAuthorizationForm?.completerRoles || [],
      completerRoles_AsString:
        homeNCIUARedesignation?.homeNCIUARedesignation
          ?.homeownerAuthorizationForm?.completerRoles_AsString || [],
      hidden:
        !homeNCIUARedesignation?.homeNCIUARedesignation
          ?.homeownerAuthorizationForm,
    },
    {
      formName: "Redesignation Form",
      formURL: `${fortifiedHomeNCIUARedesignationRoutePrefix}/${homeNCIUARedesignation?.id}/redesignation-form`,
      isCompleted:
        homeNCIUARedesignation?.homeNCIUARedesignation?.redesignationForm
          ?.areAllRequiredFieldsComplete || false,
      isDisabled: false,
      formsNeedingCompletion: [],
      completerRoles:
        homeNCIUARedesignation?.homeNCIUARedesignation?.redesignationForm
          ?.completerRoles || [],
      completerRoles_AsString:
        homeNCIUARedesignation?.homeNCIUARedesignation?.redesignationForm
          ?.completerRoles_AsString || [],
      hidden:
        !homeNCIUARedesignation?.homeNCIUARedesignation?.redesignationForm,
    },
  ];

  const baseTab: ChecklistTabItem[] = [{ id: 0, label: "All" }];

  const tabs: ChecklistTabItem[] = [
    { id: 1, label: "FH Evaluator", role: UserRole["FH Evaluator"] },
    { id: 2, label: "FH Roofer", role: UserRole["FH Roofer"] },
    { id: 3, label: "FH Engineer", role: UserRole["FH Engineer"] },
    { id: 4, label: "FH Contractor", role: UserRole["FH Contractor"] },
    { id: 5, label: "IBHS Admin", role: UserRole["IBHS Admin"] },
    {
      id: 6,
      label: "FH Evaluator Admin",
      role: UserRole["FH Evaluator Admin"],
    },
  ];

  const allTabs = baseTab.concat(tabs);

  // Find the selected tab by its ID
  const selectedUserRoleTab =
    allTabs.find((tab) => tab.id === selectedTabId) || baseTab[0];

  const isAllSelected = !selectedUserRoleTab || selectedUserRoleTab.id === 0;

  const filteredForms = forms.filter(
    (form) =>
      !form.hidden &&
      (isAllSelected ||
        form.completerRoles.includes(selectedUserRoleTab.role || 0))
  );

  const filteredTabs = allTabs.filter((tab) =>
    forms.some((form) => form.completerRoles.includes(tab.role || 0))
  );

  // Always include the "All" tab
  const visibleTabs = [baseTab[0], ...filteredTabs];

  return (
    <div>
      {!homeNCIUARedesignation && <Loading />}
      {homeNCIUARedesignation && (
        <ChecklistContainerV2
          evaluationId={props.evaluationId}
          filteredForms={filteredForms}
          selectedUserRoleFormLinks={[]}
          selectedUserRoleAccessTokenMethod={undefined}
          hasFormsLoading={hasFormsLoading}
          setEvaluation={setHomeNCIUARedesignation}
          fortifiedId={homeNCIUARedesignation.fortifiedId || ""}
          buildingAddress_AsString={
            homeNCIUARedesignation.buildingAddress_AsString
          }
          selectedEmail={undefined}
          evaluationStep={homeNCIUARedesignation?.step}
          selectedUserRoleTab={selectedUserRoleTab}
          isOnPaymentStep={
            homeNCIUARedesignation.step === EvaluationStep.Payment
          }
          isPaymentApplicable={homeNCIUARedesignation.isPaymentApplicable}
          paymentNextStepDisplayText="IBHS Review"
          bypassPaymentStepForNonProfit={
            homeNCIUARedesignationAPIClient.bypassPaymentStepForNonProfit
          }
          visibleTabs={visibleTabs}
          selectedTabId={selectedTabId}
          setSelectedTabId={setSelectedTabId}
          productTypeRoute={`${fortifiedHomeNCIUARedesignationRoutePrefix}`}
          getApplicationFeePaymentCreditDetails={
            homeNCIUARedesignationAPIClient.getApplicationFeePaymentCreditDetails
          }
          productSubtype={ProductSubtypes.HomeNCIUARedesignation}
        />
      )}
    </div>
  );
};

export default HomeNCIUARedesignationChecklist;
