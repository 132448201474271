import { createRef, useEffect, useState } from "react";
import { commercialEvaluationAPIClient } from "../../../commercial/lib/apiClients/commercialEvaluationAPIClient";
import { ComplexCommercialEvaluationBuildingsCardV2Props } from "./ComplexCommercialEvaluationBuildingsCardV2";

export const useComplexCommercialEvaluationBuildingsCardV2 = (
  props: ComplexCommercialEvaluationBuildingsCardV2Props
) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [buildingIds, setBuildingIds] = useState<string[]>([]);

  useEffect(() => {
    getBuildingIds();
  }, []);

  const getBuildingIds = async () => {
    setIsFetching(true);
    const buildingIds =
      await commercialEvaluationAPIClient.getComplexCommercialEvaluationBuildingIds(
        props.evaluationId
      );

    buildingIds?.forEach((id) => {
      if (!props.buildingFormRefs.current.has(id)) {
        props.buildingFormRefs.current.set(id, createRef());
      }
    });

    setBuildingIds(buildingIds);
    setIsFetching(false);
  };

  const createBuilding = async (complexCommercialEvaluationId: string) => {
    setIsSaving(true);
    await commercialEvaluationAPIClient
      .createComplexCommercialEvaluationBuilding(complexCommercialEvaluationId)
      .then(async () => {
        await getBuildingIds();

        if (props.onParentNeedsRefresh) {
          await props.onParentNeedsRefresh();
        }
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const isBuildingDeleteButtonDisabled = buildingIds.length <= 2;

  return {
    createBuilding,
    isSaving,
    isFetching,
    buildingIds,
    getBuildingIds,
    isBuildingDeleteButtonDisabled,
  };
};
