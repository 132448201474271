import {
  EnumSingleSelectFieldV2Props,
  FieldSchemaFormBuilderV2,
  SchemaFactoryV2,
  SingleCheckboxFieldProps,
  SmartAddressFieldProps,
  TextFieldProps,
} from "@ucl/library";
import { addressApiClient } from "../../../../../../foritfied/lib/apiClients/address/addressApiClient";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../../types/HomeEvaluationFormModel";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { WildfireBoxContentExplorer } from "../../../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import { HomeStandardSpecialProjectTypes } from "../../../types/HomeStandardEvaluationBasicInformationFormModel";

const modelName = "Evaluation";
const subModelName = "HomeStandardEvaluation";
const formName = "BasicInformationForm";

const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

//Part 1: Homeowner Information
const homeownerInformationFields = (
  form: Evaluation_HomeStandardEvaluationFormModel
) => [
  buildFieldKey("HomeownerFirstName"),
  buildFieldKey("HomeownerLastName"),
  buildFieldKey("HomeownerEmail"),
  {
    fieldKey: buildFieldKey("Address"),
    getAddressesByQuery: addressApiClient.getAddressesByQuery,
    getAddressByQuery: addressApiClient.getAddressByQuery,
    isSmartOnly: false,
    shouldShowAutoSuggest: true,
    shouldSetCountyOnSelect: true,
  } as SmartAddressFieldProps,
  {
    fieldKey: buildFieldKey("DuplicateAddressConfirmation"),
    hidden: !form.hasDuplicateAddress,
  } as SingleCheckboxFieldProps,

  buildFieldKey("SpecialProjectType"),
  {
    fieldKey: buildFieldKey("ContractorType"),
    hidden:
      form.homeStandardEvaluation.basicInformationForm.specialProjectType !=
      HomeStandardSpecialProjectTypes.FannieMae,
  } as EnumSingleSelectFieldV2Props,
  {
    fieldKey: buildFieldKey("PolicyNumber"),
    hidden: !(
      form.homeStandardEvaluation.basicInformationForm.specialProjectType ===
        HomeStandardSpecialProjectTypes.NCIUA_NCJUA_SYR ||
      form.homeStandardEvaluation.basicInformationForm.specialProjectType ===
        HomeStandardSpecialProjectTypes.NCIUA_NCJUA_SYCR ||
      form.homeStandardEvaluation.basicInformationForm.specialProjectType ===
        HomeStandardSpecialProjectTypes.NCIUA_NCJUA_Claim ||
      form.homeStandardEvaluation.basicInformationForm.specialProjectType ===
        HomeStandardSpecialProjectTypes.NCIUA_NCJUA_Other ||
      form.homeStandardEvaluation.basicInformationForm.specialProjectType ===
        HomeStandardSpecialProjectTypes.StrengthenAlabamaHomes
    ),
    maxLength: 10,
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey("ClaimPrefix"),
    hidden:
      form.homeStandardEvaluation.basicInformationForm.specialProjectType !=
      HomeStandardSpecialProjectTypes.NCIUA_NCJUA_Claim,
  } as EnumSingleSelectFieldV2Props,
  {
    fieldKey: buildFieldKey("ClaimNumber"),
    hidden:
      form.homeStandardEvaluation.basicInformationForm.specialProjectType !=
      HomeStandardSpecialProjectTypes.NCIUA_NCJUA_Claim,
    maxLength: 8,
  } as TextFieldProps,
  buildFieldKey("IsFHLBDFortifiedFundProject"),
];

const homeownerInformationRequiredFields = [
  buildFieldKey("HomeownerFirstName"),
  buildFieldKey("HomeownerLastName"),
  buildFieldKey("HomeownerEmail"),
  buildFieldKey("Address"),
  buildFieldKey("SpecialProjectType"),
  buildFieldKey("ContractorType"),
  buildFieldKey("PolicyNumber"),
  buildFieldKey("ClaimPrefix"),
  buildFieldKey("ClaimNumber"),
  buildFieldKey("IsFHLBDFortifiedFundProject"),
  buildFieldKey("DuplicateAddressConfirmation"),
];

export const homeStandardHomeownerInformationBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        homeownerInformationFields(form),
        onValueChange,
        form,
        errors,
        homeownerInformationRequiredFields
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};
// Full form builder with all parts included
export const homeStandardBasicInformationFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        homeownerInformationFields(form),
        onValueChange,
        form,
        errors,
        homeownerInformationRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Full form builder with all parts included, for Iteration Engine
export const homeStandardIterationEngineBasicInformationFormBuilder = (
  factory: SchemaFactoryV2,
  beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    let fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        homeownerInformationFields(form),
        onValueChange,
        form,
        errors,
        homeownerInformationRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    if (beforeBuild) {
      fields = beforeBuild(fields);
    }
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};
