import { utils } from "@ucl/library";
import ErrorWrapper from "../../../common/Components/Error/ErrorWrapper";
import { ExternalPagesLayout } from "./ExternalPagesLayout";
import {
  fortifiedHomeNCIUARoutePrefix,
  fortifiedHomeRoutePrefix,
  fortifiedHomeStandardRoutePrefix,
} from "./fortifiedHomeRouteConfig";
import { createBrowserRouter } from "react-router-dom";
import { HomeStandardExternalRoofingComplianceGeneralInformationPage } from "../../../fortifiedV2/Home/standard/pages/external/HomeStandardExternalRoofingComplianceGeneralInformationPage";
import { HomeNCIUAExternalRoofingComplianceGeneralInformationPage } from "../../../fortifiedV2/Home/nciua/pages/external/HomeNCIUAExternalRoofingComplianceGeneralInformationPage";
import { HomeStandardExternalRooferConfirmationPage } from "../../../fortifiedV2/Home/standard/pages/external/HomeStandardExternalRooferConfirmationPage";
import { HomeNCIUAExternalRooferConfirmationPage } from "../../../fortifiedV2/Home/nciua/pages/external/HomeExternalNCIUARooferConfirmationPage";
import { HomeStandardExternalRoofingComplianceRoofCoverPage } from "../../../fortifiedV2/Home/standard/pages/external/HomeStandardExternalRoofingComplianceRoofCoverPage";
import { HomeNCIUAExternalRoofingComplianceRoofCoverPage } from "../../../fortifiedV2/Home/nciua/pages/external/HomeNCIUAExternalRoofingComplianceRoofCoverPage";
import { HomeStandardExternalEngineeringComplianceGeneralInformationPage } from "../../../fortifiedV2/Home/standard/pages/external/HomeStandardExternalEngineeringComplianceGeneralInformationPage";
import { HomeStandardExternalEngineeringComplianceGablesPage } from "../../../fortifiedV2/Home/standard/pages/external/HomeStandardExternalEngineeringComplianceGablesPage";
import { HomeStandardExternalEngineeringComplianceChimneyPage } from "../../../fortifiedV2/Home/standard/pages/external/HomeStandardExternalEngineeringComplianceChimneyPage";
import { HomeStandardExternalEngineeringComplianceAttachedStructurePage } from "../../../fortifiedV2/Home/standard/pages/external/HomeStandardExternalEngineeringComplianceAttachedStructurePage";
import { HomeStandardExternalEngineeringComplianceContinuousLoadPathPage } from "../../../fortifiedV2/Home/standard/pages/external/HomeStandardExternalEngineeringComplianceContinuousLoadPathPage";
import { HomeStandardExternalEngineerConfirmationPage } from "../../../fortifiedV2/Home/standard/pages/external/HomeStandardExternalEngineerConfirmationPage";
import { HomeStandardExternalInstallerComplianceGablesPage } from "../../../fortifiedV2/Home/standard/pages/external/HomeStandardExternalInstallerComplianceGablesPage";
import { HomeStandardExternalInstallerComplianceChimneyPage } from "../../../fortifiedV2/Home/standard/pages/external/HomeStandardExternalInstallerComplianceChimneyPage";
import { HomeStandardExternalInstallerComplianceAttachedStructurePage } from "../../../fortifiedV2/Home/standard/pages/external/HomeStandardExternalInstallerComplianceAttachedStructurePage";
import { HomeStandardExternalInstallerComplianceContinuousLoadPathPage } from "../../../fortifiedV2/Home/standard/pages/external/HomeStandardExternalInstallerComplianceContinuousLoadPathPage";
import { HomeStandardExternalInstallingContractorConfirmationPage } from "../../../fortifiedV2/Home/standard/pages/external/HomeStandardExternalInstallingContractorConfirmationPage";

const errorWrapper = (
  <div className="fortified-page">
    <ErrorWrapper />
  </div>
);

const uclRouteObject = [
  {
    path: `${fortifiedHomeStandardRoutePrefix}/external`,
    label: "External Home Standard Forms",
    icon: undefined,
    isAvailable: true,
    showInNavigation: false,
    showInSubNavigation: true,
    errorElement: errorWrapper,
    element: (
      <ExternalPagesLayout redirectPath={`${fortifiedHomeRoutePrefix}`} />
    ),
    children: [
      {
        path: "roofing-compliance-general-information/:id",
        label: "External Roofing Compliance General Information Form",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: true,
        element: (
          <HomeStandardExternalRoofingComplianceGeneralInformationPage />
        ),
      },
      {
        path: "roofing-compliance-roof-cover/:id",
        label: "External Roofing Compliance Roof Cover Form",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: true,
        element: <HomeStandardExternalRoofingComplianceRoofCoverPage />,
      },
      {
        path: "roofer-confirmation/:id",
        label: "External Roofer Confirmation Form",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: true,
        element: <HomeStandardExternalRooferConfirmationPage />,
      },
      {
        path: "engineering-compliance-general-information/:id",
        label: "External Engineering Compliance General Information Form",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: true,
        element: (
          <HomeStandardExternalEngineeringComplianceGeneralInformationPage />
        ),
      },
      {
        path: "engineering-compliance-gables/:id",
        label: "External Engineering Compliance Gables Form",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: true,
        element: <HomeStandardExternalEngineeringComplianceGablesPage />,
      },
      {
        path: "engineering-compliance-chimney/:id",
        label: "External Engineering Compliance Chimney Form",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: true,
        element: <HomeStandardExternalEngineeringComplianceChimneyPage />,
      },
      {
        path: "engineering-compliance-attached-structure/:id",
        label: "External Engineering Compliance Attached Structure Form",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: true,
        element: (
          <HomeStandardExternalEngineeringComplianceAttachedStructurePage />
        ),
      },
      {
        path: "engineering-compliance-continuous-load-path/:id",
        label: "External Engineering Compliance Continuous Load Path Form",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: true,
        element: (
          <HomeStandardExternalEngineeringComplianceContinuousLoadPathPage />
        ),
      },
      {
        path: "engineer-confirmation/:id",
        label: "External Engineer Confirmation Form",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: true,
        element: <HomeStandardExternalEngineerConfirmationPage />,
      },
      {
        path: "installer-compliance-gables/:id",
        label: "External Installer Compliance Gables Form",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: true,
        element: <HomeStandardExternalInstallerComplianceGablesPage />,
      },
      {
        path: "installer-compliance-chimney/:id",
        label: "External Installer Compliance Chimney Form",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: true,
        element: <HomeStandardExternalInstallerComplianceChimneyPage />,
      },
      {
        path: "installer-compliance-attached-structure/:id",
        label: "External Installer Compliance Attached Structure Form",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: true,
        element: (
          <HomeStandardExternalInstallerComplianceAttachedStructurePage />
        ),
      },
      {
        path: "installer-compliance-continuous-load-path/:id",
        label: "External Installer Compliance Continuous Load Path Form",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: true,
        element: (
          <HomeStandardExternalInstallerComplianceContinuousLoadPathPage />
        ),
      },
      {
        path: "installing-contractor-confirmation/:id",
        label: "External Installing Contractor Confirmation Form",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: true,
        element: <HomeStandardExternalInstallingContractorConfirmationPage />,
      },
    ],
  },
  {
    path: `${fortifiedHomeNCIUARoutePrefix}/external`,
    label: "External Home NCIUA Forms",
    icon: undefined,
    isAvailable: true,
    showInNavigation: false,
    showInSubNavigation: true,
    errorElement: errorWrapper,
    element: (
      <ExternalPagesLayout redirectPath={`${fortifiedHomeRoutePrefix}`} />
    ),
    children: [
      {
        path: "roofing-compliance-general-information/:id",
        label: "External Roofing Compliance General Information Form",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: true,
        element: <HomeNCIUAExternalRoofingComplianceGeneralInformationPage />,
      },
      {
        path: "roofing-compliance-roof-cover/:id",
        label: "External Roofing Compliance Roof Cover Form",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: true,
        element: <HomeNCIUAExternalRoofingComplianceRoofCoverPage />,
      },
      {
        path: "roofer-confirmation/:id",
        label: "External Roofer Confirmation Form",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: true,
        element: <HomeNCIUAExternalRooferConfirmationPage />,
      },
    ],
  },
] as utils.routes.UclRouteObject[];

export const fortified_Home_ExternalBrowserRouter = createBrowserRouter(
  uclRouteObject,
  {
    basename: process.env.REACT_APP_PATH_PREFIX,
  }
);
