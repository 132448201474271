import { FieldFormV2Module } from "@ucl/library";
import { useCallback, useEffect, useRef, useState } from "react";

import { debounce } from "lodash";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { AppToaster } from "@ucl/library/lib/components/Toast/Toast";
import { Intent } from "@blueprintjs/core";
import { Evaluation_CommercialEvaluationFormModel } from "../../../../commercial/types/CommercialEvaluationFormModel";
import { commercialEvaluationAPIClient } from "../../../../commercial/lib/apiClients/commercialEvaluationAPIClient";
import {
  errorStore,
  genericErrorMessage,
} from "../../../../../../common/Components/Error/ErrorStore";
import { commercialEvaluationApplicationFormAPIClient } from "../../../../commercial/lib/apiClients/commercialEvaluationApplicationFormAPIClient";
import { CommercialBuildingApplicationFormProps } from "./CommercialBuildingApplicationForm";
import { useFCFMFFieldSchemaFactory } from "../../../../common/customHooks/useFCFMFFieldSchemaFactory";

function useCommercialBuildingApplicationForm(
  props: CommercialBuildingApplicationFormProps
) {
  const { builders, wieBuilders } = useFCFMFFieldSchemaFactory();

  const formRef =
    useRef<FieldFormV2Module<Evaluation_CommercialEvaluationFormModel>>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [applicationFormModel, setApplicationFormModel] =
    useState<Evaluation_CommercialEvaluationFormModel>();

  const [isInitialized, setIsInitialized] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isLoading && !isInitialized) {
      setIsInitialized(true);
      props.onInitialized?.();
    }
  }, [isLoading]);

  useEffect(() => {
    if (isInitialized) {
      props.onInitialized?.();
    }
  }, [isInitialized]);

  useEffect(() => {
    //Used to React to External Changes Pushed on the Form (Iteration Engine)
    if (applicationFormModel) {
      setApplicationFormModel({ ...applicationFormModel });
    }
  }, [wieBuilders]);

  useEffect(() => {
    fetchApplicationForm();
  }, []);

  useEffect(() => {
    if (!!applicationFormModel) {
      setApplicationFormModel(applicationFormModel);

      if (props.setFormModel) {
        props.setFormModel(applicationFormModel);
      }
    }
  }, [applicationFormModel]);

  const noBuilders = !builders && !wieBuilders;

  const fetchApplicationForm = async () => {
    setIsLoading(true);
    await commercialEvaluationAPIClient
      .getCommercialEvaluationFormModel(props.evaluationId)
      .then((response) => {
        setApplicationFormModel((prevModel) => ({
          ...prevModel,
          ...response,
        }));
      })
      .catch(() => {
        errorStore.setErrorMessage(genericErrorMessage);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Debounce setup
  const handleFormSubmitDebounced = useCallback(
    debounce(
      async (
        form: Evaluation_CommercialEvaluationFormModel,
        _value: any,
        fieldProps: BaseFieldProps<any>
      ) => {
        if (fieldProps.fieldKey) {
          await handleFormUpdate(form, fieldProps.fieldKey).then(async () => {
            await props.onFormSave?.(fieldProps.fieldKey || "", _value);
          });
        }
      },
      2000
    ),
    []
  );

  useEffect(() => {
    // Cleanup the debounce function on component unmount
    return () => handleFormSubmitDebounced.cancel();
  }, [handleFormSubmitDebounced]);

  const onFormFieldChange = async (
    form: Evaluation_CommercialEvaluationFormModel,
    _value: any,
    fieldProps: BaseFieldProps<any>
  ) => {
    // Call the debounced submit function
    if (!!_value) {
      handleFormSubmitDebounced(form, _value, fieldProps);
      props.setHasUnsavedChanges(true);
    }
  };

  const getFormBuilder = () => {
    if (props.isIterationEnginePage) {
      return wieBuilders?.CommercialBuildingApplicationForm;
    } else {
      return builders?.CommercialBuildingApplicationForm;
    }
  };

  const handleFormSubmit = async (
    form: Evaluation_CommercialEvaluationFormModel
  ) => {
    setApplicationFormModel(form);

    await commercialEvaluationApplicationFormAPIClient
      .submitCommercialApplicationForm(
        form.id,
        form.commercialEvaluation.commercialEvaluationApplicationForm
      )
      .then(async (response) => {
        handleFormResponse(response, true);
      })
      .catch((error) => {
        if (error.response.status !== 400) {
          console.error(error);
          AppToaster.show({
            message: "Unexpected error occurred while saving the form",
            intent: Intent.DANGER,
          });
        }
        props.updateChildFormError(form.id, true);
        throw error;
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleFormUpdate = async (
    form: Evaluation_CommercialEvaluationFormModel,
    fieldKey?: string
  ) => {
    setApplicationFormModel(form);

    await commercialEvaluationApplicationFormAPIClient
      .updateCommercialApplicationForm(
        form.id,
        form.commercialEvaluation.commercialEvaluationApplicationForm,
        fieldKey
      )
      .then(async (response) => {
        handleFormResponse(response, false);

        if (props.onParentNeedsRefresh) {
          await props.onParentNeedsRefresh();
        }
      })
      .catch((error) => {
        if (error.response.status !== 400) {
          console.error(error);
          AppToaster.show({
            message: "Unexpected error occurred while saving the form",
            intent: Intent.DANGER,
          });
        }
        props.updateChildFormError(form.id, true);
        throw error;
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleFormResponse = async (
    response: Evaluation_CommercialEvaluationFormModel,
    shouldSubmit: boolean
  ) => {
    props.setHasUnsavedChanges(false);

    if (shouldSubmit) {
      setApplicationFormModel(response);
    } else {
      handlePartialResponseFormUpdate(response);
    }
  };

  const handlePartialResponseFormUpdate = (
    response: Evaluation_CommercialEvaluationFormModel
  ) => {
    //This is used to combat save on blur issues. This only updates fields that are essetnial to the form.
    //These are set in the API response. Field Counts and sub section completion status
    setApplicationFormModel((prevState) => {
      if (!prevState) return prevState;

      const {
        emailNotificationsRequiredFieldCount,
        applicationInformationRequiredFieldCount,
        newConstructionsAndNewAdditionsRequiredFieldCount,
        existingConstructionReroofingRequiredFieldCount,
        projectConstructionDetailsRequiredFieldCount,
        existingRoofCoverSystemRequiredFieldCount,
        fileUploadersRequiredFieldCount,
        confirmationFieldsRequiredFieldCount,
        generalProjectDetailsRequiredFieldCount,
        isApplicationInformationComplete,
        isEmailNotificationsComplete,
        isGeneralProjectDetailsComplete,
        isNewConstructionsAndNewAdditionsComplete,
        isExistingConstructionReroofingComplete,
        isProjectConstructionDetailsComplete,
        isExistingRoofCoverSystemComplete,
        isFileUploadersComplete,
        isConfirmationFieldsComplete,
        architecturalDrawings,
        architecturalStructuralDrawings,
        buildingRequiredFieldCount,
      } = response.commercialEvaluation.commercialEvaluationApplicationForm;

      const { canProceedToApplicationFee } = response.commercialEvaluation;

      return {
        ...prevState,
        commercialEvaluation: {
          ...prevState.commercialEvaluation,
          canProceedToApplicationFee,
          commercialEvaluationApplicationForm: {
            ...prevState.commercialEvaluation
              .commercialEvaluationApplicationForm,
            emailNotificationsRequiredFieldCount,
            applicationInformationRequiredFieldCount,
            newConstructionsAndNewAdditionsRequiredFieldCount,
            existingConstructionReroofingRequiredFieldCount,
            projectConstructionDetailsRequiredFieldCount,
            existingRoofCoverSystemRequiredFieldCount,
            fileUploadersRequiredFieldCount,
            confirmationFieldsRequiredFieldCount,
            generalProjectDetailsRequiredFieldCount,
            isApplicationInformationComplete,
            isEmailNotificationsComplete,
            isGeneralProjectDetailsComplete,
            isNewConstructionsAndNewAdditionsComplete,
            isExistingConstructionReroofingComplete,
            isProjectConstructionDetailsComplete,
            isExistingRoofCoverSystemComplete,
            isFileUploadersComplete,
            isConfirmationFieldsComplete,
            architecturalDrawings,
            architecturalStructuralDrawings,
            buildingRequiredFieldCount,
          },
        },
      };
    });
  };

  return {
    builders,
    wieBuilders,
    formRef,
    isLoading,
    isSubmitting,
    setIsSubmitting,
    applicationFormModel,
    getFormBuilder,
    handleFormSubmit,
    onFormFieldChange,
    noBuilders,
    containerRef,
  };
}

export default useCommercialBuildingApplicationForm;
