import { FC, useState } from "react";
import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { buildLinkV2 } from "./NotifySubmitterLinkBuilderV2";
import { NotifySubmitterFormLink } from "../../../../../../foritfied/components/Checklist/NotifySubmitter/NotifySubmitterLinkBuilder";
import { AppToaster } from "@ucl/library/lib/components/Toast/Toast";
import { AccessToken } from "../../../../../../common/types/accessTokens";

export interface NotifySubmitterButtonProps {
  mailTo: string;
  address: string;
  fortifiedId: string;
  formLinks: NotifySubmitterFormLink[];
  getAccessToken: () => Promise<AccessToken>;
}

export const NotifySubmitterButton: FC<NotifySubmitterButtonProps> = (
  props
) => {
  const [isLoading, setIsLoading] = useState(false);

  const buildAndOpenLink = async () => {
    setIsLoading(true);
    await props
      .getAccessToken()
      .then((token) => {
        const link = buildLinkV2(
          props.mailTo,
          props.fortifiedId,
          props.address,
          props.formLinks,
          token.id
        );
        window.open(link, "_blank");
      })
      .catch(() => {
        AppToaster.show({
          message:
            "An unexpected error occurred while generating the submitter form links.",
          intent: Intent.DANGER,
        });
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Button
      intent={Intent.PRIMARY}
      icon={IconNames.ENVELOPE}
      text="Notify Submitter"
      loading={isLoading}
      onClick={buildAndOpenLink}
    />
  );
};
