import { Button, Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { UserRole } from "../../../../common/Components/User/userTypes";
import "./styles.scss";
import { useContext, useState } from "react";
import { EvaluationCommentV2 } from "../types/EvaluationCommentV2";
import { ChangeRequestV2 } from "../types/ChangeRequestV2";
import { ChangeRequestStatus } from "../../../../foritfied/types/evaluation/ChangeRequest";
import { convertDateTo_EST_timeZone_AsString } from "../../../../common/utils/momentTimeZone";
import {
  EnumSingleSelectFieldV2,
  PermissionsContext,
  PermissionsContextProps,
} from "@ucl/library";
import { formatField } from "./commentFormatter";
import { parseUntrustedHtml } from "../../../../common/utils/markup";
import { useFeatureToggle } from "../../../customHooks/useFeatureToggle";
import { permissionStore } from "../../../../foritfied/stores/PermissionStore";
import classNames from "classnames";
import { EnumSingleSelectFieldValue } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import { isNil, orderBy } from "lodash";

export interface CommentItemView {
  id: string;
  createdBy: string;
  createdBy_AsName: string;
  createdAt: string;
  deletedAt?: string | null;
  deletedBy?: string | null;
  comment: React.ReactNode;
  role?: UserRole;
  role_AsString?: string;
  title?: string;
  isPublic: boolean;
}

export interface CommentListViewProps {
  comments: EvaluationCommentV2[] | undefined;
  changeRequest?: ChangeRequestV2;
  deleteComment?: (
    commentId: string,
    evaluationFieldKey: string
  ) => Promise<void>;
  currentKey: string | undefined;
  hasAssociatedChangeRequests: boolean;
  canViewSystemGeneratedComments?: boolean;
}

const changeRequestCommentId = "CHANGE_REQUEST_COMMENT_ID";

const IterationEngineCommentList: React.FC<CommentListViewProps> = ({
  comments,
  changeRequest,
  hasAssociatedChangeRequests,
  deleteComment,
  currentKey,
  canViewSystemGeneratedComments,
}) => {
  const { hasPermission } = useContext(
    PermissionsContext
  ) as PermissionsContextProps<PermissionsKey>;
  const canDeleteComments = hasPermission("CanDeleteAnyComments");

  const { areInternalCommentsEnabled } = useFeatureToggle();

  const hasInternalCommentPermisson =
    permissionStore.canViewCommentModeSelector ||
    hasPermission("CanSelectCommentVisiblity");

  const canViewInternalComments =
    hasInternalCommentPermisson && areInternalCommentsEnabled;

  const [commentFilterValue, setCommentFilterValue] =
    useState<EnumSingleSelectFieldValue>(canViewInternalComments ? 1 : 2);

  let valueChangeComments: CommentItemView[] = [];
  const shouldShowValueChangeComment =
    canViewSystemGeneratedComments &&
    hasAssociatedChangeRequests &&
    changeRequest?.to &&
    changeRequest?.status !== ChangeRequestStatus.New;

  if (shouldShowValueChangeComment) {
    const valueChangeComment: CommentItemView = {
      id: changeRequestCommentId,
      createdBy: changeRequest.valueChangedBy || changeRequest.updatedBy,
      createdBy_AsName:
        changeRequest.valueChangedBy_AsName || changeRequest.updatedBy_AsName,
      createdAt: convertDateTo_EST_timeZone_AsString(
        changeRequest.valueChangedAt || changeRequest.updatedAt
      ),
      comment: (
        <span className="change-request-comment">
          Value changed from{" "}
          <span className="change-request-comment_old">
            {formatField(changeRequest.from, changeRequest.evaluationFieldKey)}
          </span>{" "}
          to{" "}
          <span className="change-request-comment_new">
            {formatField(changeRequest.to, changeRequest.evaluationFieldKey)}
          </span>
        </span>
      ),
      title: changeRequest.evaluationFieldLabel,
      isPublic: true,
    };
    valueChangeComments = [valueChangeComment];
  }

  const sortedComments = [
    ...valueChangeComments,
    ...(orderBy(comments, "createdAt", "asc")?.map((comment) => ({
      ...comment,
      comment: <div>{parseUntrustedHtml(comment.comment)}</div>,
      ogCreatedAt: comment.createdAt,
      createdAt: convertDateTo_EST_timeZone_AsString(comment.createdAt),
    })) || []),
  ].sort(
    (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
  );

  const commentFilterOptions = [
    { value: 1, label: "All Comments" },
    { value: 2, label: "Public Comments" },
    { value: 3, label: "Internal Comments" },
  ];

  const filteredComments = sortedComments.filter((comment) => {
    if (commentFilterValue === 1) {
      return true;
    } else if (commentFilterValue === 2) {
      return comment.isPublic;
    } else if (commentFilterValue === 3) {
      return !comment.isPublic;
    }
    return false;
  });

  const canViewCommentFilter =
    sortedComments.length > 0 && canViewInternalComments;

  return (
    <div>
      {isNil(comments) && <Loading />}

      {!isNil(comments) && (
        <>
          {canViewCommentFilter && (
            <div className="comment-list-view-filter">
              <EnumSingleSelectFieldV2
                type={"EnumSingleSelect"}
                optionValues={commentFilterOptions}
                showClearButton={false}
                value={commentFilterValue}
                onSubmit={(value) => {
                  setCommentFilterValue(value);
                }}
              />
            </div>
          )}

          <div className="comment-list-scroll-container">
            <div className="comment-list-view">
              {filteredComments.map((comment) => (
                <div
                  key={comment.id}
                  className={classNames({
                    "comment-list-view_item": true,
                    "internal-comment": !comment.isPublic,
                  })}
                >
                  <div className="comment-list-view_item_line-1">
                    <div className="comment-list-view_item_line-1_name">
                      {canViewInternalComments && (
                        <Icon
                          className="comment-list-view_item_line-1_name_icon"
                          icon={
                            comment.isPublic ? IconNames.GLOBE : IconNames.LOCK
                          }
                          size={12}
                        />
                      )}
                      {comment.createdBy_AsName}
                      {comment.role_AsString && ` (${comment.role_AsString})`}
                    </div>
                    {comment.id !== changeRequestCommentId &&
                      deleteComment &&
                      canDeleteComments && (
                        <div className="comment-list-view_item_line-1_delete">
                          <Button
                            minimal
                            icon={IconNames.TRASH}
                            onClick={() =>
                              deleteComment(comment.id, currentKey || "")
                            }
                          />
                        </div>
                      )}
                  </div>
                  <div className="comment-list-view_item_line-1_time">
                    {comment.createdAt}
                  </div>
                  <div className="comment-list-view_item_line-2">
                    {comment.comment}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default IterationEngineCommentList;
