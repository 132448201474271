import { FC } from "react";
import { UnSavedChangesBlockerProvider } from "../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import { BaseHomeEvaluationRooferConfirmationFormParts } from "../../common/types/BaseHomeEvaluationRooferConfirmationFormModel";
import HomeStandardRedesignationRooferConfirmationFormContainer from "../components/Forms/RooferConfirmationForm/Container/HomeStandardRedesignationRooferConfirmationFormContainer";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../../../AppInsights";

export interface HomeStandardRedesignationRooferConfirmationPageProps {
  evaluationId?: string;
  rooferConfirmationFormPart?: BaseHomeEvaluationRooferConfirmationFormParts;
}

const HomeStandardRedesignationRooferConfirmationPageComponent: FC<
  HomeStandardRedesignationRooferConfirmationPageProps
> = (props) => {
  return (
    <>
      {props.evaluationId && (
        <UnSavedChangesBlockerProvider>
          <HomeStandardRedesignationRooferConfirmationFormContainer
            evaluationId={props.evaluationId}
            rooferConfirmationFormPart={props.rooferConfirmationFormPart}
          />
        </UnSavedChangesBlockerProvider>
      )}
    </>
  );
};

export const HomeStandardRedesignationRooferConfirmationPage = withAITracking(
  reactPlugin,
  HomeStandardRedesignationRooferConfirmationPageComponent,
  "HomeStandardRedesignationRooferConfirmationPage",
  "tracking-container"
);
