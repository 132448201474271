import {
  EnumRadioFieldValue,
  EnumSingleSelectFieldValue,
  EnumMultiSelectFieldValue,
  NumericFieldValue,
  TextFieldValue,
} from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { WildfireBoxContentExplorerValue } from "../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";

export enum BaseHomeRoofingComplianceRoofCoverFormParts {
  RoofCoverInformation = "roof-cover-information",
  AsphaltShingleRoofCoverDetails = "asphalt-shingle-roof-cover-details",
  MetalRoofCoverDetails = "metal-roof-cover-details",
  ClayConcreteTileRoofCoverDetails = "clay-concrete-tile-roof-cover-details",
  WoodShakeSingleRoofCoverDetails = "wood-shake-single-roof-cover-details",
  LowSlopeRoofCoverDetails = "low-slope-roof-cover-details",
}

export const getActiveBaseHomeRoofingComplianceRoofCoverFormParts = (
  roofCoverType: RoofCoverTypes[]
) => {
  const parts: BaseHomeRoofingComplianceRoofCoverFormParts[] = [
    BaseHomeRoofingComplianceRoofCoverFormParts.RoofCoverInformation,
  ];

  if (roofCoverType?.includes(RoofCoverTypes.AsphaltShingle)) {
    parts.push(
      BaseHomeRoofingComplianceRoofCoverFormParts.AsphaltShingleRoofCoverDetails
    );
  }

  if (roofCoverType?.includes(RoofCoverTypes.MetalPanel)) {
    parts.push(
      BaseHomeRoofingComplianceRoofCoverFormParts.MetalRoofCoverDetails
    );
  }

  if (roofCoverType?.includes(RoofCoverTypes.ClayOrConcreteTile)) {
    parts.push(
      BaseHomeRoofingComplianceRoofCoverFormParts.ClayConcreteTileRoofCoverDetails
    );
  }

  if (roofCoverType?.includes(RoofCoverTypes.WoodShakeOrShingle)) {
    parts.push(
      BaseHomeRoofingComplianceRoofCoverFormParts.WoodShakeSingleRoofCoverDetails
    );
  }

  if (roofCoverType?.includes(RoofCoverTypes.LowSlope)) {
    parts.push(
      BaseHomeRoofingComplianceRoofCoverFormParts.LowSlopeRoofCoverDetails
    );
  }

  return parts;
};

export interface BaseHomeEvaluationRoofingComplianceRoofCoverFormModel
  extends RoofCoverInformation,
    AsphaltShingleRoofCoverDetails,
    MetalRoofCoverDetails,
    ClayConcreteTileRoofCoverDetails,
    WoodShakeShingleRoofCoverDetails,
    LowSlopeRoofCoverDetails {}

interface RoofCoverInformation {
  roofCoverType?: EnumMultiSelectFieldValue;
  roofCoverTypeMinSlope?: EnumSingleSelectFieldValue;
  requiredWindRatingZone1?: TextFieldValue;
  requiredWindRatingZone2?: TextFieldValue;
  requiredWindRatingZone3?: TextFieldValue;
  allZonesRequiredWindRatingDocumentation?: WildfireBoxContentExplorerValue;
  fullyInstalledRoofCoverPhotos?: WildfireBoxContentExplorerValue;
}

interface AsphaltShingleRoofCoverDetails {
  areAsphaltShingleManufacturerAndProductDetailsKnown?: EnumRadioFieldValue;
  asphaltShingleManufacturerName?: TextFieldValue;
  asphaltShingleProductName?: TextFieldValue;
  shingleManufacturerAndProductPhoto?: WildfireBoxContentExplorerValue;
  doesAsphaltShingleWindRatingMeetASTMClassFOrClassH?: EnumRadioFieldValue;
  asphaltShingleWindRatingTypes?: EnumMultiSelectFieldValue;
  shingleWindRatingPhoto?: WildfireBoxContentExplorerValue;
  asphaltShingleHailRatingType?: EnumSingleSelectFieldValue;
  shingleHailRatingPhoto?: WildfireBoxContentExplorerValue;
  doAsphaltShingleNailsMeetFortifiedCorrosionResistanceRequirements?: EnumRadioFieldValue;
  areAsphaltShinglesInstalledPerManufacturerRecommendationsForHighWindRegions?: EnumRadioFieldValue;
  numberOfInstalledNailsPerAsphaltShingle?: NumericFieldValue;
  startStripInstallationAtEavesTypes?: EnumMultiSelectFieldValue;
  startStripInstallationAtGlobeRakesTypes?: EnumMultiSelectFieldValue;
  starterStripManufacturerAndProductPhotos?: WildfireBoxContentExplorerValue;
  starterStripInstallationPhotos?: WildfireBoxContentExplorerValue;
  asphaltShingleRoofCoverMinSlopePhoto?: WildfireBoxContentExplorerValue;
}

interface MetalRoofCoverDetails {
  areMetalPanelManufacturerAndProductDetailsKnown?: EnumRadioFieldValue;
  metalPanelManufacturerName?: TextFieldValue;
  metalPanelProductName?: TextFieldValue;
  metalPanelCertifiedTestReportProductApprovalNumber?: TextFieldValue;
  metalPanelCertifiedTestReportDocumentation?: WildfireBoxContentExplorerValue;
  metalPanelSelectNumberOfDifferentSystemsFromCertifiedTestReport?: NumericFieldValue;
  metalPanelFirstInstalledSystemNumberPerCertifiedTestReport?: TextFieldValue;
  matelPanelFirstInstalledSystemDesignPressureOrMomentResistanceRating?: TextFieldValue;
  metalPanelSecondInstalledSystemNumberPerCertifiedTestReport?: TextFieldValue;
  metalPanelSecondInstalledSystemDesignPressureOrMomentResistanceRating?: TextFieldValue;
  metalPanelThirdInstalledSystemNumberPerCertifiedTestReport?: TextFieldValue;
  metalPanelThirdInstalledSystemDesignPressureOrMomentResistanceRating?: TextFieldValue;
  metalPanelAreDesignRatingsOfInstalledSystemsListedInCertifiedTestReport?: EnumRadioFieldValue;
  doMetalPanelsAndExposedFastenersMeetFortifiedCorrosionResistanceRequirements?: EnumRadioFieldValue;
  installedMetalPanelMaterial?: TextFieldValue;
  installedMetalPanelGaugeThickness?: TextFieldValue;
  installedMetalPanelFastenerTypeAndSize?: TextFieldValue;
  metalPanelFastenersPhoto?: WildfireBoxContentExplorerValue;
  installedMetalPanelFastenerPatternAndSpacingZone1?: TextFieldValue;
  installedMetalPanelFastenerPatternAndSpacingZone2?: TextFieldValue;
  installedMetalPanelFastenerPatternAndSpacingZone3?: TextFieldValue;
  metalPanelInstalledFastenerPatternAndSpacingPhotos?: WildfireBoxContentExplorerValue;
  wasMetalPanelRoofCoverSystemInstalledInAccordanceToStandards?: EnumRadioFieldValue;
  doesMetalPanelSystemHaveUL2218Class4ImpactRating?: EnumRadioFieldValue;
  metalPanelRoofCoverMinSlopePhoto?: WildfireBoxContentExplorerValue;
}

interface ClayConcreteTileRoofCoverDetails {
  areTheClayConcreteTileManufacturerAndProductDetailsKnown?: EnumRadioFieldValue;
  clayConcreteTileManufacturerName?: TextFieldValue;
  clayConcreteTileProductName?: TextFieldValue;
  clayConcreteTileTestReportApprovalNumber?: TextFieldValue;
  clayConcreteTileCertifiedTestReportDocumentation?: WildfireBoxContentExplorerValue;
  clayConcreteTileNumberOfInstalledSystemsFromTestReport?: TextFieldValue;
  clayConcreteTileFirstInstalledSystemNumberPerCertifiedTestReport?: TextFieldValue;
  clayConcreteTileFirstInstalledSystemDesignPressureOrMomentResistanceRating?: TextFieldValue;
  clayConcreteTileSecondInstalledSystemNumberPerCertifiedTestReport?: NumericFieldValue;
  clayConcreteTileSecondInstalledSystemDesignPressureOrMomentResistanceRating?: TextFieldValue;
  clayConcreteTileThirdInstalledSystemNumberPerCertifiedTestReport?: TextFieldValue;
  clayConcreteTileThirdInstalledSystemDesignPressureOrMomentResistanceRating?: TextFieldValue;
  clayConcreteTileSystemsIdentifiedFromTestReport?: TextFieldValue;
  clayConcreteTileAreDesignRatingsOfInstalledSystemsListedInCertifiedTestReport?: EnumRadioFieldValue;
  doesClayConcreteTileRoofCoverSystemHaveFM4473Class4ImpactRating?: EnumRadioFieldValue;
  clayConcreteTileCertifiedTestReportProductApprovalNumberForStructuralSupports?: TextFieldValue;
  tileStructuralSupportsCertifiedTestReportDocumentation?: WildfireBoxContentExplorerValue;
  mechanicalAdhesiveRoofTileAttachmentType?: EnumSingleSelectFieldValue;
  mechanicalFastenerType?: TextFieldValue;
  roofTileFastenersPhoto?: WildfireBoxContentExplorerValue;
  mechanicalFastenerSize?: TextFieldValue;
  mechanicalFastenerQuantityPerTile?: TextFieldValue;
  doMechanicalTileFastenersMeetFortifiedCorrosionResistanceRequirements?: EnumRadioFieldValue;
  adhesiveFoamManufacturerName?: TextFieldValue;
  adhesiveFoamProductName?: TextFieldValue;
  adhesiveFoamTestReportApprovalNumber?: TextFieldValue;
  isAdhesiveFoamInstallerCertifiedByFoamManufacturer?: EnumRadioFieldValue;
  adhesiveFoamInstallerCertificationNumber?: TextFieldValue;
  tileUnderlaymentCertifiedTestReportNumber?: TextFieldValue;
  isTileAndFlashingInstalledPerFRSATRIInstructions?: EnumRadioFieldValue;
  isTileAndFlashingInstalledPerTRIWSRCAInstructions?: EnumRadioFieldValue;
  clayConcreteTileRoofCoverMinSlopePhoto?: WildfireBoxContentExplorerValue;
  tileRoofCoverSystemInstallationPhoto?: WildfireBoxContentExplorerValue;
}

interface WoodShakeShingleRoofCoverDetails {
  areTheWoodShakeShingleManufacturerAndProductDetailsKnown?: EnumRadioFieldValue;
  woodShakeShingleManufacturerName?: TextFieldValue;
  woodShakeShingleProductName?: TextFieldValue;
  woodShakeShingleTestReportApprovalNumber?: TextFieldValue;
  woodShakeShingleCertifiedTestReportDocumentation?: WildfireBoxContentExplorerValue;
  woodShakeShingleDesignPressureWindRatingOfInstalledSystem?: NumericFieldValue;
  woodShakeShingleIsMeanRoofHeightLimitationPerManufacturerProductSpecifications?: EnumRadioFieldValue;
  woodShakeShingleMeanRoofHeightLimitation?: NumericFieldValue;
  woodShakeShingleRequiredNumberOfNailsPerShingle?: NumericFieldValue;
  woodShakeShingleDoesWindRatingListedInCertifiedTestReportMeetOrExceedDesignConditions?: EnumRadioFieldValue;
  doesWoodShakeShingleHaveUL2218Class4OrFM4473Class4ImpactRating?: EnumRadioFieldValue;
  woodShakeShingleFastenerType?: TextFieldValue;
  woodShakeShingleFastenersPhoto?: WildfireBoxContentExplorerValue;
  woodShakeShingleFastenerSize?: TextFieldValue;
  wereStaplesUsedToInstallWoodShakesShingles?: EnumRadioFieldValue;
  woodShakeShingleAreStainlessSteelFastenersRequired?: EnumRadioFieldValue;
  wereStainlessFastenersUsedToInstallWoodShakesShingles?: EnumRadioFieldValue;
  isSealedRoofDeckMaterialCompatibleWithWoodShakeShingle?: EnumRadioFieldValue;
  wasWoodShakeShingleRoofCoverSystemInstalledInAccordanceWithCertifiedTestReport?: EnumRadioFieldValue;
  woodShakeShingleRoofCoverMinSlopePhoto?: WildfireBoxContentExplorerValue;
  woodShakeShingleRoofCoverSystemInstallationPhotos?: WildfireBoxContentExplorerValue;
}

interface LowSlopeRoofCoverDetails {
  areTheLowSlopeRoofCoverManufacturerAndProductDetailsKnown?: EnumRadioFieldValue;
  lowSlopeRoofCoverManufacturerName?: TextFieldValue;
  lowSlopeRoofCoverProductName?: TextFieldValue;
  lowSlopeRoofCoverTestReportApprovalNumber?: TextFieldValue;
  lowSlopeRoofCoverCertifiedTestReportDocumentation?: WildfireBoxContentExplorerValue;
  lowSlopeRoofCoverSelectNumberOfDifferentSystemsFromCertifiedTestReport?: NumericFieldValue;
  lowSlopeRoofCoverFirstInstalledSystemNumberPerCertifiedTestReport?: TextFieldValue;
  lowSlopeRoofCoverFirstInstalledSystemDesignPressureOrMomentResistanceRating?: TextFieldValue;
  lowSlopeRoofCoverSecondInstalledSystemNumberPerCertifiedTestReport?: TextFieldValue;
  lowSlopeRoofCoverSecondInstalledSystemDesignPressureOrMomentResistanceRating?: TextFieldValue;
  lowSlopeRoofCoverThirdInstalledSystemNumberPerCertifiedTestReport?: TextFieldValue;
  lowSlopeRoofCoverThirdInstalledSystemDesignPressureOrMomentResistanceRating?: TextFieldValue;
  lowSlopeRoofCoverAreDesignRatingsOfInstalledSystemsListedInCertifiedTestReport?: EnumRadioFieldValue;
  lowSlopeRoofCoverDoExposedFastenersMeetFortifiedCorrosionResistanceRequirements?: EnumRadioFieldValue;
  doesLowSlopeRoofCoverSystemHaveADesignatedImpactRating?: EnumRadioFieldValue;
  lowSlopeRoofCoverFastenerTypeAndSize?: TextFieldValue;
  lowSlopeRoofCoverFastenerSpacingZone1?: TextFieldValue;
  lowSlopeRoofCoverFastenerSpacingZone2?: TextFieldValue;
  lowSlopeRoofCoverFastenerSpacingZone3?: TextFieldValue;
  wasLowSlopeRoofCoverInstalledInAccordanceWithCertifiedTestReport?: EnumRadioFieldValue;
  lowSlopeRoofCoverMinSlopePhoto?: WildfireBoxContentExplorerValue;
  lowSlopeRoofCoverSystemInstallationPhotos?: WildfireBoxContentExplorerValue;
}

export enum RoofCoverTypes {
  AsphaltShingle = 1,

  MetalPanel = 2,

  ClayOrConcreteTile = 3,

  WoodShakeOrShingle = 4,

  LowSlope = 5,
}

export enum ShingleWindRatingTypes {
  ASTM3161ClassF = 1,
  ASTMD7158ClassH = 2,
}

export enum ShingleHailRatingTypes {
  ASTM3161ClassF = 1,
  ASTMD7158ClassH = 2,
  FM4473Class4 = 3,
  NoneOfTheAbove = 4,
}

export enum StarterStripInstallationEavesTypes {
  FRC1 = 1,
  FRC2 = 2,
  NoneOfTheAbove = 3,
}

export enum StarterStripInstallationGlobeRakesTypes {
  FRC1 = 1,
  FRC2 = 2,
  FRC3 = 3,
  NoneOfTheAbove = 4,
}

export enum MechanicalAdhesiveRoofTileAttachmentTypes {
  Mechanical = 1,
  AdhesiveFoam = 2,
}
