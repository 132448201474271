import { Button } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { ProgressBarComponent } from "@syncfusion/ej2-react-progressbar";
import "../styles.scss";
import { wildfireUserStore } from "../../../../stores/WildfireUserStore";

interface ChangeRequestNavigatorProps {
  selectedField: any;
  hasAssociatedChangeRequests: boolean;
  hasNoNewChangeRequests: boolean;
  changeRequestCount: number;
  resolvedChangeRequestCount: number;
  resolvedChangeRequestsPercentage: () => number;
  currentChangeRequestIndex: number;
  navigateToPreviousChangeRequest: () => void;
  navigateToNextChangeRequest: () => void;
  applicantChangeRequestStatusLabel: string;
}

const ChangeRequestNavigator: React.FC<ChangeRequestNavigatorProps> = (
  props
) => {
  const shouldDisableRightArrow =
    props.currentChangeRequestIndex >= props.changeRequestCount - 1;

  const shouldDisableLeftArrow = props.currentChangeRequestIndex <= 0;

  const shouldShowChangeRequestProgressCircle =
    (wildfireUserStore.isWildfireAdmin ||
      wildfireUserStore.isWildfireAuditor) &&
    props.hasNoNewChangeRequests;

  const countOfResolvedVsUnresolvedChangeRequests = `${props.resolvedChangeRequestCount}/${props.changeRequestCount}`;

  return (
    <div className="change-request-navigator-v2">
      <div className="change-request-navigator-v2__buttons">
        <div className="change-request-navigator-v2-nav">
          <Button
            icon={IconNames.ARROW_LEFT}
            onClick={props.navigateToPreviousChangeRequest}
            disabled={shouldDisableLeftArrow}
          />
          <div className="change-request-navigator-v2-label">
            Change Requests
          </div>
          <Button
            rightIcon={IconNames.ARROW_RIGHT}
            onClick={props.navigateToNextChangeRequest}
            disabled={shouldDisableRightArrow}
          />
        </div>
        {shouldShowChangeRequestProgressCircle && (
          <>
            <ProgressBarComponent
              progressColor="#4DD45A"
              type="Circular"
              value={props.resolvedChangeRequestsPercentage()}
              height="60px"
              width="60px"
            />
            {countOfResolvedVsUnresolvedChangeRequests}
          </>
        )}
      </div>
    </div>
  );
};

export default ChangeRequestNavigator;
