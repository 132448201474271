import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { RelativityTooltip } from "@ucl/library";
import { useState } from "react";

export interface SendToSilverGoldApplicationFormsButtonProps {
  evaluationId: string;
  isDisabled: boolean;
  sendToSilverGoldApplicationForms: (evaluationId: string) => Promise<void>;
}
export const SendToSilverGoldApplicationFormsButton: React.FC<
  SendToSilverGoldApplicationFormsButtonProps
> = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <RelativityTooltip
      shouldShowTooltip={props.isDisabled}
      content={
        "All Change Requests need to be resolved before sending forward to Silver/Gold Application Forms"
      }
    >
      <Button
        text="Send Forward"
        intent={Intent.PRIMARY}
        minimal
        disabled={props.isDisabled || isLoading}
        loading={isLoading}
        icon={IconNames.ArrowRight}
        onClick={async () => {
          setIsLoading(true);
          await props
            .sendToSilverGoldApplicationForms(props.evaluationId)
            .finally(() => {
              setIsLoading(false);
            });
        }}
      />
    </RelativityTooltip>
  );
};
