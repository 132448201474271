import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { FC, useEffect, useState } from "react";
import { reactPlugin } from "../../../../AppInsights";
import { UnSavedChangesBlockerProvider } from "../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import { HomeSchemaFactoryV2 } from "../../customHooks/useHomeFieldSchemaFactory";
import HomeStandardRedesignationIterationEngine from "../components/IterationEngine/HomeStandardRedesignationIterationEngine";
import { evaluationStore } from "../../../../foritfied/stores/EvaluationStore";
import { fortifiedFieldSchemaApiClient } from "../../../Common/lib/apiClients/fortifiedFieldSchemaApiClient";

export interface HomeStandardRedesignationIterationEnginePageProps {
  evaluationId?: string;
}

const HomeStandardRedesignationIterationEnginePageComponent: FC<
  HomeStandardRedesignationIterationEnginePageProps
> = (props) => {
  const [schemaFactory, setschemaFactory] = useState<HomeSchemaFactoryV2>();

  const getSchema = async () => {
    const schema = await fortifiedFieldSchemaApiClient.getSchema();
    const newSchemaFactory = new HomeSchemaFactoryV2(schema);
    setschemaFactory(newSchemaFactory);
  };

  useEffect(() => {
    if (!schemaFactory) {
      getSchema();
    }
  }, []);

  evaluationStore.init(props.evaluationId || "");

  return (
    <>
      {props.evaluationId && schemaFactory && (
        <UnSavedChangesBlockerProvider>
          <HomeStandardRedesignationIterationEngine
            evaluationId={props.evaluationId}
            homeSchemaFactory={schemaFactory}
          />
        </UnSavedChangesBlockerProvider>
      )}
    </>
  );
};

export const HomeStandardRedesignationIterationEnginePage = withAITracking(
  reactPlugin,
  HomeStandardRedesignationIterationEnginePageComponent,
  "HomeStandardRedesignationIterationEnginePage",
  "tracking-container"
);
