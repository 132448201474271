import baseApiClient from "../../../../../../foritfied/lib/apiClients/baseApiClient";
import baseExternalApiClient from "../../../../../../foritfied/lib/apiClients/baseExternalApiClient";
import { PaymentCreditResponseReadDTO } from "../../../../../Common/types/PaymentCredit/PaymentCreditResponseReadDTO";
import { Evaluation_HomeNCIUAEvaluation } from "../../types/HomeNCIUAEvaluation";
import { Evaluation_HomeNCIUAEvaluationFormModel } from "../../types/HomeNCIUAEvaluationFormModel";

class HomeNCIUAEvaluationAPIClient {
  createHomeNCIUAEvaluation = async () => {
    return baseApiClient.post<Evaluation_HomeNCIUAEvaluation>(
      `home-nciua/evaluation`
    );
  };

  getHomeNCIUAEvaluation = async (evaluationId: string) => {
    return await baseApiClient.get<Evaluation_HomeNCIUAEvaluation>(
      `home-nciua/evaluation/${evaluationId}`
    );
  };

  getHomeNCIUAEvaluationFormModel = async (evaluationId: string) => {
    return await baseApiClient.get<Evaluation_HomeNCIUAEvaluationFormModel>(
      `home-nciua/evaluation/${evaluationId}`
    );
  };

  getHomeNCIUAEvaluationFormModelExternal = async (
    evaluationId: string,
    accessToken: string
  ) => {
    return await baseExternalApiClient.get<Evaluation_HomeNCIUAEvaluationFormModel>(
      `home-nciua/evaluation/${evaluationId}`,
      {
        headers: {
          AccessToken: accessToken,
        },
      }
    );
  };

  hasEvaluationsWithSameAddress = async (evaluationId: string) => {
    return await baseApiClient.get<boolean>(
      `home-nciua/evaluation/${evaluationId}/evaluations-with-same-address`
    );
  };

  moveToApplicationFormsStep = async (evaluationId: string) => {
    return baseApiClient.put<Evaluation_HomeNCIUAEvaluation>(
      `home-nciua/evaluation/${evaluationId}/application-forms`
    );
  };

  getApplicationFeePaymentCreditDetails = async (evaluationId: string) => {
    return await baseApiClient.get<PaymentCreditResponseReadDTO>(
      `home-nciua/evaluation/${evaluationId}/payment/credit-details`
    );
  };

  applyApplicationFeePaymentCredit = async (evaluationId: string) => {
    return await baseApiClient.put<Evaluation_HomeNCIUAEvaluation>(
      `home-nciua/evaluation/${evaluationId}/payment/credit`
    );
  };

  bypassPaymentStepForNonProfit = async (evaluationId: string) => {
    return await baseApiClient.post<Evaluation_HomeNCIUAEvaluation>(
      `home-nciua/evaluation/${evaluationId}/payment/nonprofit`
    );
  };

  getApplicationFeeStripeCheckoutLink = async (evaluationId: string) => {
    return await baseApiClient.get<string>(
      `home-nciua/evaluation/${evaluationId}/payment/stripe-checkout`
    );
  };
  sendRequestedChangesEmail = async (evaluationId: string) => {
    return await baseApiClient.post<void>(
      `home-nciua/evaluation/${evaluationId}/requested-changes-email`
    );
  };
}

export const homeNCIUAEvaluationAPIClient = new HomeNCIUAEvaluationAPIClient();
