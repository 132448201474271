import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { FC, useEffect, useState } from "react";
import { reactPlugin } from "../../../../AppInsights";
import { UnSavedChangesBlockerProvider } from "../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import { FCFMFSchemaFactoryV2 } from "../../common/customHooks/useFCFMFFieldSchemaFactory";
import MultifamilyRedesignationIterationEngine from "../components/IterationEngine/MultifamilyRedesignationIterationEngine";
import { evaluationStore } from "../../../../foritfied/stores/EvaluationStore";
import { fortifiedFieldSchemaApiClient } from "../../../Common/lib/apiClients/fortifiedFieldSchemaApiClient";

export interface MultifamilyRedesignationIterationEnginePageProps {
  evaluationId?: string;
}

const MultifamilyRedesignationIterationEnginePageComponent: FC<
  MultifamilyRedesignationIterationEnginePageProps
> = (props) => {
  const [schemaFactory, setSchemaFactory] = useState<FCFMFSchemaFactoryV2>();

  const getSchema = async () => {
    const schema = await fortifiedFieldSchemaApiClient.getSchema();
    const newSchemaFactory = new FCFMFSchemaFactoryV2(schema);
    setSchemaFactory(newSchemaFactory);
  };

  useEffect(() => {
    if (!schemaFactory) {
      getSchema();
    }
  }, []);

  //TODO: Ideally we should not use this in the new system.
  //For time sake, we will use this for now. This is only used for the Header Buttons
  evaluationStore.init(props.evaluationId || "");

  return (
    <>
      {props.evaluationId && schemaFactory && (
        <UnSavedChangesBlockerProvider>
          <MultifamilyRedesignationIterationEngine
            evaluationId={props.evaluationId}
            schemaFactory={schemaFactory}
          />
        </UnSavedChangesBlockerProvider>
      )}
    </>
  );
};

export const MultifamilyRedesignationIterationEnginePage = withAITracking(
  reactPlugin,
  MultifamilyRedesignationIterationEnginePageComponent,
  "MultifamilyRedesignationIterationEnginePage",
  "tracking-container"
);
