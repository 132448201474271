import {
  PredicateModel,
  SortDescriptorModel,
} from "@syncfusion/ej2-react-grids";
import { indexOf, sortBy } from "lodash";
import FortifiedGridColumn from "./FortifiedGridColumn";
import FortifiedGridViewReadDTO from "../dtos/FortifiedGridViewReadDTO";

export default class FortifiedGridView {
  public id: string;
  public gridId: string;
  public title: string;
  public columnSettings: string[];
  public filterSettings?: string;
  public sortSettings?: string;
  public metricSettings?: string;

  public columnConfiguration: FortifiedGridColumn[];
  public filterConfiguration?: PredicateModel[];
  public sortConfiguration?: SortDescriptorModel[];
  public metricConfiguration: string[];

  constructor(
    fortifiedGridViewReadDTO: FortifiedGridViewReadDTO,
    baseGridColumnsConfiguration: FortifiedGridColumn[]
  ) {
    this.id = fortifiedGridViewReadDTO.id;
    this.gridId = fortifiedGridViewReadDTO.gridId;
    this.title = fortifiedGridViewReadDTO.title;
    this.columnSettings = fortifiedGridViewReadDTO.columnSettings;
    this.filterSettings = fortifiedGridViewReadDTO.filterSettings;
    this.sortSettings = fortifiedGridViewReadDTO.sortSettings;
    this.metricSettings = fortifiedGridViewReadDTO.metricSettings;

    // Compute
    this.columnConfiguration = this.getColumnConfiguration(
      this.columnSettings,
      baseGridColumnsConfiguration
    );
    this.filterConfiguration = this.getFilterConfiguration(
      this.columnConfiguration
    );
    this.sortConfiguration = this.getSortConfiguration(
      this.columnConfiguration
    );

    this.metricConfiguration = this.getMetricConfiguration();
  }

  private getMetricConfiguration(): string[] {
    return this.metricSettings ? this.metricSettings.split(",") : [];
  }

  private getColumnConfiguration(
    columnSettings: string[],
    baseGridColumnsConfiguration: FortifiedGridColumn[]
  ): FortifiedGridColumn[] {
    // Return default if corrupted
    if (!columnSettings || columnSettings.length <= 0)
      return {
        ...(baseGridColumnsConfiguration as FortifiedGridColumn[]),
      };

    let config = baseGridColumnsConfiguration.filter((x) =>
      columnSettings?.find((y) => x.field === y)
    );

    config = sortBy(config, function (col) {
      return indexOf(columnSettings, col.field);
    });

    return config;
  }

  private getFilterConfiguration(
    establishedColumnsConfiguration: FortifiedGridColumn[]
  ): PredicateModel[] {
    if (!this.filterSettings) return [];

    let config = JSON.parse(this.filterSettings) as PredicateModel[];
    config = config.filter((x) =>
      establishedColumnsConfiguration?.find((y) => x.field === y.field)
    );

    return config;
  }
  public getSortConfiguration(
    establishedColumnsConfiguration: FortifiedGridColumn[]
  ): SortDescriptorModel[] {
    if (!this.sortSettings) return [];

    let config = JSON.parse(this.sortSettings) as SortDescriptorModel[];
    config = config.filter((x) =>
      establishedColumnsConfiguration?.find((y) => x.field === y.field)
    );

    return config;
  }
}
