import { Observer } from "mobx-react";
import { Button } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import "../../pages/styles.scss";
import { FortifiedGrid } from "../FortifiedGrid/FortifiedGrid";
import { FortifiedGridViewPicker } from "../FortifiedGridViewsModule/FortifiedGridViewPicker";
import { FortifiedGridFiltersModule } from "../FortifiedGridFiltersModule/FortifiedGridFiltersModule";
import { useFortifiedGridViewModule } from "../../../common/customHooks/useFortifiedGridViewModule";
import { dialogsViewerStore } from "../../stores/DialogsViewerStore";
import { fortifiedGridViewModuleStore } from "../../stores/FortifiedGridViewModuleStore";
import variables from "../../../common/config/variables";
import { permissionStore } from "../../stores/PermissionStore";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../../AppInsights";
import { getGridColumnsConfigurationByRole } from "./configurations/gridColumnsConfiguration";
import { FortifiedGridMetrics } from "../FortifiedGrid/FortifiedGridMetrics";
import { FortifiedPaymentCreditMetricReadDTO } from "../../types/dtos/FortifiedPaymentCreditMetricReadDTO";
import { FeatureToggleSet } from "../../../common/Components/FeatureToggle/FeatureToggles";
import { featureToggleApiClient } from "../../../common/Components/FeatureToggle/class/featureToggleApiClient";

export interface BoardProps {}

const PaymentCreditGridComponent: React.FC<BoardProps> = () => {
  const [featureToggles, setFeatureToggles] = useState<FeatureToggleSet>();
  const [isInitialized, setIsInitialized] = useState(false);

  const getFeatureToggles = async () => {
    const toggles = await featureToggleApiClient.getFeatureToggleSet();

    setFeatureToggles(toggles);
    setIsInitialized(true);
  };

  useEffect(() => {
    getFeatureToggles();
  }, []);

  //Grid View Module
  useFortifiedGridViewModule(
    "paymentcredit-board-grid",
    "Payment Credit Board",
    getGridColumnsConfigurationByRole,
    (_role) => "All"
  );

  return (
    <div>
      <section className="board-toolbar">
        <ul className="board-toolbar-left">
          <li>
            {/* Grid Views Module - Picker */}
            <FortifiedGridViewPicker />
          </li>
        </ul>
        <ul className="board-toolbar-right">
          <li>
            {/* Grid Filters Module */}
            <FortifiedGridFiltersModule />
          </li>
          <li>
            {permissionStore.canCreatePaymentCredits && (
              <Button
                title="Add Payment Credit"
                icon="add"
                intent="primary"
                onClick={() => {
                  dialogsViewerStore.setIsPaymentCreditDialogOpen(true, {
                    isCreate: true,
                  });
                }}
              >
                Create New Credit
              </Button>
            )}
          </li>
        </ul>
      </section>
      <section className="board-grid-container has-tabs">
        {/* PaymentCredit Admin Grid */}
        <Observer>
          {() => (
            <>
              {!!fortifiedGridViewModuleStore.currentGridView && (
                <FortifiedGrid<{ id: string }>
                  key={fortifiedGridViewModuleStore.currentGridViewKey}
                  dataSource={`${variables.apiBaseUrl}odata/paymentcreditodata`}
                  columnsConfiguration={[
                    ...fortifiedGridViewModuleStore.currentGridView
                      .columnConfiguration,
                  ]}
                  filterConfiguration={[
                    ...(fortifiedGridViewModuleStore.currentGridView
                      .filterConfiguration || []),
                  ]}
                  sortConfiguration={[
                    ...(fortifiedGridViewModuleStore.currentGridView
                      .sortConfiguration || []),
                  ]}
                  gridConfiguration={{
                    allowTextWrap: true,
                    textWrapSettings: { wrapMode: "Header" },
                    defaultSort: {
                      field: "createdAt",
                      direction: "Descending",
                    },
                    exportFilename: "PaymentCredits.xlsx",
                    onRecordClick: ({ rowData }) => {
                      if (permissionStore.canUpdatePaymentCredits) {
                        dialogsViewerStore.setIsPaymentCreditDialogOpen(true, {
                          paymentCreditId: rowData.id,
                          isCreate: false,
                        });
                      }
                    },
                  }}
                />
              )}
            </>
          )}
        </Observer>
      </section>
      {isInitialized && featureToggles?.NewGridMetrics && (
        <section className="board-metrics-container">
          {/* Payment Credit Metrics Module */}
          <Observer>
            {() => (
              <>
                {!!fortifiedGridViewModuleStore.currentGridView && (
                  <FortifiedGridMetrics<FortifiedPaymentCreditMetricReadDTO>
                    gridKey="paymentcredit-board-grid"
                    url={`payment-credits`}
                    columns={4}
                    minimal={true}
                    metricConfiguration={
                      fortifiedGridViewModuleStore.currentGridView
                        ?.metricConfiguration
                    }
                    updateViewMetricSettings={
                      fortifiedGridViewModuleStore.updateViewMetricSettings
                    }
                    showMetricViewPicker={false}
                    languageMapping={{
                      paymentCreditCount: "Credit Count",
                      sumAllPaymentCredits: "Sum of All Credits",
                      sumUsedPaymentCredits: "Sum of Used Credits",
                      sumUnusedPaymentCredits: "Sum of Unused Credits",
                    }}
                  />
                )}
              </>
            )}
          </Observer>
        </section>
      )}
    </div>
  );
};

export const PaymentCreditGrid = withAITracking(
  reactPlugin,
  PaymentCreditGridComponent,
  "PaymentCreditGrid",
  "tracking-container"
);
