import {
  AsyncAutoCompleteFieldProps,
  EnumRadioFieldProps,
  FieldFormV2,
  FieldFormV2Module,
  FieldSchemaFormBuilderV2,
  Footer,
} from "@ucl/library";
import { FC, useRef, useState } from "react";
import WildfireSecondaryButton from "../../../../../../wildfire/components/Button/DialogFooterButtons/SecondaryButton/SecondaryButton";
import WildfireSubmitButton from "../../../../../../wildfire/components/Button/DialogFooterButtons/SubmitButton/SubmitButton";
import { closeCreateFCFMFEvaluationDialog } from "./CreateFCFMFEvaluationDialog";
import { EnumRadioFieldValue } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { commercialEvaluationAPIClient } from "../../../../commercial/lib/apiClients/commercialEvaluationAPIClient";
import { useNavigate } from "react-router";
import "./styles.scss";
import { multifamilyEvaluationAPIClient } from "../../../../multifamily/lib/apiClients/multifamilyEvaluationAPIClient";
import variables from "../../../../../../common/config/variables";
import { getAuthRequestHeader } from "../../../../../../common/lib/msal/fortifiedCommercialMultifamilyMsal";
import { Predicate } from "@syncfusion/ej2-data";
import { commercialRedesignationAPIClient } from "../../../../commercialRedesignation/lib/apiClients/commercialRedesignationAPIClient";
import { multifamilyRedesignationAPIClient } from "../../../../multifamilyRedesignation/lib/apiClients/multifamilyRedesignationAPIClient";
import EvaluationHistory from "../../../../../../foritfied/components/Evaluation/History/EvaluationHistory";
import { ProductTypes } from "../../../../../../foritfied/types/evaluation/Evaluation";
import { CommercialEvaluationOptions } from "../../../../../../foritfied/types/schema";
import CommercialEvaluationFieldInfoContainer from "../../../../../../foritfied/components/Dialogs/CreateEvaluation/Commercial/CommercialEvaluationFieldInfoContainer";
import { ProductSubtypes } from "../../../../../Common/types/Evaluation/BaseEvaluation";

export interface CreateFCFMFEvaluationFormModel {
  productType: EnumRadioFieldValue;
  redesignationOption?: CommercialEvaluationOptions;
  commercialParentEvaluationId?: string;
}

const optionValues = [
  {
    label: "New Commercial Application",
    value: 1,
  },
  {
    label: "New Multi-Family Application",
    value: 2,
  },
  {
    label: "Redesignation",
    value: 3,
  },
];

const createFcFMFFormBuilder: FieldSchemaFormBuilderV2<
  CreateFCFMFEvaluationFormModel
> = (onValueChange, form, _) => {
  return [
    {
      type: "EnumRadio",
      fieldName: "evaluationProductSelection",
      fieldKey: "evaluationProductSelection",
      label: "Choose a Product",
      value: form.productType,
      optionValues: optionValues,
      onSubmit: function (value) {
        onValueChange(
          {
            ...form,
            productType: value as number,
            redesignationOption: undefined,
            commercialParentEvaluationId: undefined,
          },
          value,
          this
        );
      },
    } as EnumRadioFieldProps,
    {
      type: "AsyncAutoComplete",
      fieldName: "commercialEvaluationLookUpByFortifiedId",
      fieldKey: "commercialEvaluationLookUpByFortifiedId",
      hidden: form.productType != 3,
      label: "Lookup Commercial Evaluation by FID",
      placeholder: "Type to search Evaluations...",
      noResultsLabel: "No Evaluations found",
      apiBaseUrl: variables.apiBaseUrl,
      odataUrl: `${variables.apiBaseUrl}odata/commercialevaluationodata/options`,
      getAuthRequestHeader,
      fieldNames: [
        "id",
        "fortifiedId",
        "isApproved",
        "isRedesignation",
        "isActive",
      ],
      autoCompleteSettings: {
        fields: { text: "fortifiedId", value: "id" },
        itemTemplate: "<table><tr><td>${fortifiedId}</td></tr><table>",
      },
      sortBy: "fortifiedId",
      searchBy: (searchText: string) =>
        new Predicate("fortifiedId", "contains", searchText, true),

      value: form.commercialParentEvaluationId,
      onSubmit: function (value) {
        const evaluation = value as unknown as CommercialEvaluationOptions;
        onValueChange(
          {
            ...form,
            commercialParentEvaluationId: evaluation?.id,
            redesignationOption: evaluation,
          },
          value,
          this
        );
      },
    } as AsyncAutoCompleteFieldProps,
  ];
};

export const CreateFCFMFEvaluationForm: FC = () => {
  const createFCFMFEvaluationFormRef =
    useRef<FieldFormV2Module<CreateFCFMFEvaluationFormModel>>(null);

  const [fcfmfvaluationFormModel, setFCFMFEvaluationFormModel] =
    useState<CreateFCFMFEvaluationFormModel>();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const createFcFMFEvaluation = async (
    form: CreateFCFMFEvaluationFormModel
  ) => {
    switch (form.productType) {
      case 1:
        setIsLoading(true);
        await commercialEvaluationAPIClient
          .createCommercialEvaluation()
          .then((commercialEvaluation) => {
            closeCreateFCFMFEvaluationDialog();
            navigate(
              `/fc-fmf/commercial/${commercialEvaluation.id}/application-form`
            );
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            setIsLoading(false);
          });

        break;
      case 2:
        setIsLoading(true);
        await multifamilyEvaluationAPIClient
          .createMultifamilyEvaluation()
          .then((multifamilyEvaluation) => {
            closeCreateFCFMFEvaluationDialog();
            navigate(
              `/fc-fmf/multifamily/${multifamilyEvaluation.id}/application-form`
            );
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;
      case 3:
        if (
          form.redesignationOption?.productType ===
          ProductTypes.FortifiedCommercial
        ) {
          setIsLoading(true);
          await commercialRedesignationAPIClient
            .createCommercialRedesignation(form.redesignationOption?.id || "")
            .then((commercialRedesignation) => {
              closeCreateFCFMFEvaluationDialog();
              navigate(
                `/fc-fmf/commercial-redesignation/${commercialRedesignation.id}/form`
              );
            })
            .catch((error) => {
              console.error(error);
            })
            .finally(() => {
              setIsLoading(false);
            });
        } else if (
          form.redesignationOption?.productType ===
          ProductTypes.FortifiedMultifamily
        ) {
          setIsLoading(true);
          await multifamilyRedesignationAPIClient
            .createMultifamilyRedesignation(form.redesignationOption?.id || "")
            .then((multifamilyRedesignation) => {
              closeCreateFCFMFEvaluationDialog();
              navigate(
                `/fc-fmf/multifamily-redesignation/${multifamilyRedesignation.id}/form`
              );
            })
            .catch((error) => {
              console.error(error);
            })
            .finally(() => {
              setIsLoading(false);
            });
        }
        break;
      default:
        break;
    }
  };

  const isRedesignationDisabled =
    fcfmfvaluationFormModel?.productType === 3 &&
    !fcfmfvaluationFormModel?.redesignationOption;

  const isFormDisabled =
    !fcfmfvaluationFormModel?.productType || isRedesignationDisabled;

  return (
    <div className="create-fcfmf-evalaution-dialog">
      <FieldFormV2<CreateFCFMFEvaluationFormModel>
        ref={createFCFMFEvaluationFormRef}
        value={fcfmfvaluationFormModel}
        fieldSchemaFormBuilder={createFcFMFFormBuilder}
        onFormSubmit={async (form) => {
          await createFcFMFEvaluation(form);
        }}
        onFieldChanged={(form) => {
          setFCFMFEvaluationFormModel(form);
        }}
        isDisabled={isLoading}
      />
      {fcfmfvaluationFormModel?.redesignationOption && (
        <div>
          <div>
            Please ensure the information matches the property you are looking
            to redesignate. If you are having trouble, please reach out to{" "}
            <a href="FORTIFIEDTechSupport@ibhs.org">
              FORTIFIEDTechSupport@ibhs.org
            </a>
          </div>
          {fcfmfvaluationFormModel?.redesignationOption && (
            <>
              <div className="create-fc-fmf-evaluation-info-container">
                <CommercialEvaluationFieldInfoContainer
                  selectedOption={fcfmfvaluationFormModel?.redesignationOption}
                  selectedChildOption={undefined}
                />
              </div>

              <EvaluationHistory
                evaluationId={fcfmfvaluationFormModel?.redesignationOption.id}
                productSubtype={ProductSubtypes.CommercialEvaluation}
              />
            </>
          )}
        </div>
      )}
      {(fcfmfvaluationFormModel?.productType ===
        ProductTypes.FortifiedCommercial ||
        fcfmfvaluationFormModel?.productType ===
          ProductTypes.FortifiedMultifamily) && (
        <>You will be starting a new FORTIFIED Application, continue?</>
      )}
      <Footer>
        <WildfireSecondaryButton
          className="wildfire-admin-update-dialog-cancel-button"
          buttonText="Cancel"
          onClick={() => closeCreateFCFMFEvaluationDialog()}
          disabled={isLoading}
        />
        <WildfireSubmitButton
          className="wildfire-admin-update-dialog-submit-button"
          buttonText="Submit"
          onClick={() => createFCFMFEvaluationFormRef.current?.submit()}
          isLoading={isLoading}
          disabled={isFormDisabled}
        />
      </Footer>
    </div>
  );
};
