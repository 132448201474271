import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import baseExternalApiClient from "../../../../../foritfied/lib/apiClients/baseExternalApiClient";
import { PaymentCreditResponseReadDTO } from "../../../../Common/types/PaymentCredit/PaymentCreditResponseReadDTO";
import { Evaluation_HomeStandardEvaluation } from "../../types/HomeEvaluation";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../types/HomeEvaluationFormModel";

const baseHomeStandardURL = "home-standard/evaluation";

class HomeStandardEvaluationAPIClient {
  createHomeStandardEvaluation = async (isMilestoneInspection: boolean) => {
    return baseApiClient.post<Evaluation_HomeStandardEvaluation>(
      `${baseHomeStandardURL}`,
      { isMilestoneInspection }
    );
  };

  getHomeStandardEvaluation = async (evaluationId: string) => {
    return await baseApiClient.get<Evaluation_HomeStandardEvaluation>(
      `${baseHomeStandardURL}/${evaluationId}`
    );
  };

  getHomeStandardEvaluationFormModel = async (evaluationId: string) => {
    return await baseApiClient.get<Evaluation_HomeStandardEvaluationFormModel>(
      `${baseHomeStandardURL}/${evaluationId}`
    );
  };

  getHomeStandardEvaluationFormModelExternal = async (
    evaluationId: string,
    accessToken: string
  ) => {
    return await baseExternalApiClient.get<Evaluation_HomeStandardEvaluationFormModel>(
      `${baseHomeStandardURL}/external/${evaluationId}`,
      {
        headers: {
          AccessToken: accessToken,
        },
      }
    );
  };

  getApplicationFeePaymentCreditDetails = async (evaluationId: string) => {
    return await baseApiClient.get<PaymentCreditResponseReadDTO>(
      `${baseHomeStandardURL}/${evaluationId}/payment/credit-details`
    );
  };

  applyApplicationFeePaymentCredit = async (evaluationId: string) => {
    return await baseApiClient.put<Evaluation_HomeStandardEvaluation>(
      `${baseHomeStandardURL}/${evaluationId}/payment/credit`
    );
  };

  bypassPaymentStepForNonProfit = async (evaluationId: string) => {
    return await baseApiClient.post<Evaluation_HomeStandardEvaluation>(
      `${baseHomeStandardURL}/${evaluationId}/payment/nonprofit`
    );
  };

  getApplicationFeeStripeCheckoutLink = async (evaluationId: string) => {
    return await baseApiClient.get<string>(
      `${baseHomeStandardURL}/${evaluationId}/payment/stripe-checkout`
    );
  };

  sendRequestedChangesEmail = async (evaluationId: string) => {
    return await baseApiClient.post<void>(
      `${baseHomeStandardURL}/${evaluationId}/requested-changes-email`
    );
  };

  hasEvaluationsWithSameAddress = async (evaluationId: string) => {
    return await baseApiClient.get<boolean>(
      `${baseHomeStandardURL}/${evaluationId}/evaluations-with-same-address`
    );
  };
  getRooferConfirmationForm = async (evaluationId: string) => {
    return baseApiClient.downloadGet(
      `${baseHomeStandardURL}/${evaluationId}/generate-roofing-confirmation-form`
    );
  };
  getInstallerConfirmationForm = async (evaluationId: string) => {
    return baseApiClient.downloadGet(
      `${baseHomeStandardURL}/${evaluationId}/generate-installing-contractor-confirmation-form`
    );
  };
  getEngineeringConfirmationForm = async (evaluationId: string) => {
    return baseApiClient.downloadGet(
      `${baseHomeStandardURL}/${evaluationId}/generate-engineering-form`
    );
  };

  moveToSilverGoldApplicationFormsStep = async (evaluationId: string) => {
    return await baseApiClient.post(
      `${baseHomeStandardURL}/${evaluationId}/silver-gold-application-forms`
    );
  };

  resubmitMilestoneInspection = async (evaluationId: string) => {
    return await baseApiClient.post(
      `${baseHomeStandardURL}/${evaluationId}/resubmit-milestone-inspection`
    );
  };
}

export const homeStandardEvaluationAPIClient =
  new HomeStandardEvaluationAPIClient();
