import {
  PrimaryCard,
  PrimaryCardHeader,
  RelativityTooltip,
} from "@ucl/library";
import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import "./styles.scss";
import { ProgressBarComponent } from "@syncfusion/ej2-react-progressbar";
import useFortifiedApplicationCard from "./useApplicationOverviewCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Evaluation } from "../../../../foritfied/types/evaluation/Evaluation";
import { evaluationStore } from "../../../../foritfied/stores/EvaluationStore";
import { useEffect } from "react";
import { EvaluationReadDTO } from "../../../../foritfied/types/dtos/EvaluationReadDTO";
import {
  REDESIGNATION_STEPS,
  RedesignationStepKey,
  REGULAR_STEPS,
  RegularStepKey,
} from "./types";

export interface FortifiedApplicationCardProps {
  evaluation: Evaluation;
  index: number;
  fetchEvaluations: () => Promise<Evaluation[] | void>;
  onNavigateToEvaluation: (evaluation: EvaluationReadDTO) => void;
}

export const FortifiedApplicationCard: React.FC<
  FortifiedApplicationCardProps
> = ({ evaluation, index, onNavigateToEvaluation }) => {
  const {
    status,
    secondaryHeaderInfo,
    primaryHeaderInfo,
    statusBadgeClass,
    statusSummary,
    nextStatusSummary,
    statusCallout,
    actionButtons,
    currentStep,
    progressSteps,
    progressStepDisplayNames,
    getStatusClass,
    getStatusIcon,
    progressValue,
    secondaryProgress,
    actionText,
  } = useFortifiedApplicationCard({
    evaluation,
    onNavigateToEvaluation,
  });

  useEffect(() => {
    evaluationStore.setEvaluation({ evaluation });
  }, [evaluation]);

  const getStepDisplayName = (step: RedesignationStepKey | RegularStepKey) => {
    if (evaluation.isRedesignation) {
      return (progressStepDisplayNames as typeof REDESIGNATION_STEPS)[
        step as RedesignationStepKey
      ];
    }
    return (progressStepDisplayNames as typeof REGULAR_STEPS)[
      step as RegularStepKey
    ];
  };

  return (
    <div className="fortified-application-overview-card">
      <PrimaryCard>
        <PrimaryCardHeader label="">
          <div className="fortified-application-overview-card-header">
            <div className="fortified-application-overview-card-header-text">
              <div className="fortified-application-overview-card-header-text-primary-info">
                <Icon icon={IconNames.MAP_MARKER} color="#0E1624" />
                <div>{primaryHeaderInfo}</div>
              </div>
              <div className="fortified-application-overview-card-header-text-secondary-info">
                {secondaryHeaderInfo}
              </div>
            </div>
          </div>
          <RelativityTooltip
            className="fortified-application-overview-card-progress-container"
            shouldShowTooltip={true}
            content={
              <div>
                {progressSteps.map((step, idx) => (
                  <div key={step}>
                    <span>{`${idx + 1}. ${getStepDisplayName(step)}`}</span>
                  </div>
                ))}
              </div>
            }
          >
            <div className="fortified-application-overview-card-progress-container">
              <div className="application-overview-status-bar">
                {progressSteps.map((step, idx) => {
                  const statusClass = getStatusClass(idx + 1, currentStep);
                  return (
                    <div
                      key={step}
                      className={`application-overview-status-bar__item ${
                        idx + 1 === currentStep ? "__current" : ""
                      }`}
                    >
                      <FontAwesomeIcon
                        icon={getStatusIcon(idx + 1, currentStep, status)}
                        className={`application-overview-status-bar__item__icon application-overview-status-bar__item__${statusClass
                          .split("__")
                          .pop()}`}
                      />
                      <span>{`${idx + 1}. ${getStepDisplayName(step)}`}</span>
                    </div>
                  );
                })}
              </div>
              <ProgressBarComponent
                id={`${index}-progress`}
                type="Linear"
                secondaryProgress={secondaryProgress}
                value={progressValue}
                progressColor="#26cf8b"
                animation={{ enable: true, duration: 2000, delay: 0 }}
                progressThickness={8}
                trackThickness={8}
                secondaryProgressColor="#ffbd63"
                minimum={0}
                maximum={progressSteps.length}
                gapWidth={8}
                segmentCount={progressSteps.length}
                cornerRadius="Round"
              />
            </div>
          </RelativityTooltip>
        </PrimaryCardHeader>

        <div className="fortified-application-overview-card-body">
          <div className="fortified-application-overview-card-body-column">
            <h2>
              Application Status:{" "}
              <span
                className={`fortified-application-status-badge fortified-application-status-badge__${statusBadgeClass}`}
              >
                {status}
              </span>
            </h2>
            <div className="fortified-application-overview-card-body-list">
              {statusSummary.map((summary_line, index) => (
                <div key={`${evaluation.id}_current_summary_item_${index}`}>
                  {summary_line}
                </div>
              ))}
            </div>
            {statusCallout}
          </div>
          <div className="fortified-application-overview-card-body-column">
            <h2>{actionText}</h2>
            <div className="fortified-application-overview-card-body-list">
              {nextStatusSummary.map((summary_line, index) => (
                <div key={`${evaluation.id}_next_summary_item_${index}`}>
                  {summary_line}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="fortified-application-overview-card-footer">
          {actionButtons}
        </div>
      </PrimaryCard>
    </div>
  );
};
