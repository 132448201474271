import { AuditorChecklistItem } from "../../../../fortifiedV2/Common/components/IterationEngine/AuditorChecklist/AuditorChecklistItem";
import baseApiClient from "../baseApiClient";
import { AuditorChecklistItemUpdateDTO } from "./types";

class AuditorChecklistApiClient {
  getAuditorChecklists = async (evaluationId: string, formId?: number) => {
    let url = `auditorChecklistItem/${evaluationId}`;

    if (!!formId) {
      url += `?form=${formId}`;
    }
    return await baseApiClient.get<AuditorChecklistItem[]>(url);
  };
  updateAuditorChecklist = async (
    checklistId: string,
    value: AuditorChecklistItemUpdateDTO
  ) => {
    return await baseApiClient.post<AuditorChecklistItem>(
      `auditorChecklistItem/${checklistId}`,
      value
    );
  };

  getFailedAuditorChecklists = async (evaluationId: string) => {
    return await baseApiClient.get<string[]>(
      `auditorChecklistItem/${evaluationId}/failedChecklists`
    );
  };
}

export const auditorChecklistApiClient = new AuditorChecklistApiClient();
