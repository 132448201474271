import {
  EnumRadioFieldProps,
  FieldSchemaFormBuilderV2,
  SchemaFactoryV2,
} from "@ucl/library";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import {
  WildfireBoxContentExplorer,
  WildfireBoxContentExplorerProps,
} from "../../../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import { Evaluation_HomeStandardRedesignationFormModel } from "../../../types/HomeStandardRedesignationFormModel_Model";
import { YesOrNoSelection } from "../../../../../../wildfire/types/FormFields";
import { RedesignationUpdatedSystemTypes } from "../../../../common/types/BaseHomeEvaluationRedesignationFormModel";
import { permissionStore } from "../../../../../../foritfied/stores/PermissionStore";
import {
  ReadonlyWarningBannerField,
  ReadonlyWarningBannerFieldProps,
} from "../../../../../Common/components/Fields/ReadonlyWarningBanner/ReadonlyWarningBannerField";

const modelName = "Evaluation";
const subModelName = "HomeStandardRedesignation";
const formName = "RedesignationForm";

const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

const redesignationFormFields = (
  form: Evaluation_HomeStandardRedesignationFormModel
) => [
  buildFieldKey("DesignationLevel"),
  buildFieldKey("HasTheRoofCoverBeenReplacedInLast5Years"),
  {
    fieldKey: buildFieldKey(
      "HasTheRoofCoverBeenReplacedInLast5YearsWarningBanner"
    ),
    hidden: !(
      form.homeStandardRedesignation.redesignationForm
        .hasTheRoofCoverBeenReplacedInLast5Years === YesOrNoSelection.Yes
    ),
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("OverallRedesignationElevationPhotos"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("HasAnyPortionOfRoofBeenRepairedInLast5Years"),
    hidden: !(
      form.homeStandardRedesignation.redesignationForm
        .hasTheRoofCoverBeenReplacedInLast5Years === YesOrNoSelection.No
    ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey("WereAnyStructuralMembersRepaired"),
    hidden: !(
      form.homeStandardRedesignation.redesignationForm
        .hasTheRoofCoverBeenReplacedInLast5Years === YesOrNoSelection.No &&
      form.homeStandardRedesignation.redesignationForm
        .hasAnyPortionOfRoofBeenRepairedInLast5Years === YesOrNoSelection.Yes
    ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey("RoofStructuralMemberRepairPhoto"),
    defaultToUpload: false,

    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: !(
      form.homeStandardRedesignation.redesignationForm
        .hasTheRoofCoverBeenReplacedInLast5Years === YesOrNoSelection.No &&
      form.homeStandardRedesignation.redesignationForm
        .hasAnyPortionOfRoofBeenRepairedInLast5Years === YesOrNoSelection.Yes &&
      form.homeStandardRedesignation.redesignationForm
        .wereAnyStructuralMembersRepaired === YesOrNoSelection.Yes
    ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey(
      "SignedAndSealedRepairDesignFromProfessionalEngineer"
    ),
    defaultToUpload: false,

    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: !(
      form.homeStandardRedesignation.redesignationForm
        .hasTheRoofCoverBeenReplacedInLast5Years === YesOrNoSelection.No &&
      form.homeStandardRedesignation.redesignationForm
        .hasAnyPortionOfRoofBeenRepairedInLast5Years === YesOrNoSelection.Yes &&
      form.homeStandardRedesignation.redesignationForm
        .wereAnyStructuralMembersRepaired === YesOrNoSelection.Yes
    ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("WereAnyRoofSheathingPanelsReplaced"),
    hidden: !(
      form.homeStandardRedesignation.redesignationForm
        .hasTheRoofCoverBeenReplacedInLast5Years === YesOrNoSelection.No &&
      form.homeStandardRedesignation.redesignationForm
        .hasAnyPortionOfRoofBeenRepairedInLast5Years === YesOrNoSelection.Yes
    ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey("ReplacedRoofSheathingTypeThicknessPhoto"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: !(
      form.homeStandardRedesignation.redesignationForm
        .hasTheRoofCoverBeenReplacedInLast5Years === YesOrNoSelection.No &&
      form.homeStandardRedesignation.redesignationForm
        .hasAnyPortionOfRoofBeenRepairedInLast5Years === YesOrNoSelection.Yes &&
      form.homeStandardRedesignation.redesignationForm
        .wereAnyRoofSheathingPanelsReplaced === YesOrNoSelection.Yes
    ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ReplacedRoofSheathingNailsPhoto"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: !(
      form.homeStandardRedesignation.redesignationForm
        .hasTheRoofCoverBeenReplacedInLast5Years === YesOrNoSelection.No &&
      form.homeStandardRedesignation.redesignationForm
        .hasAnyPortionOfRoofBeenRepairedInLast5Years === YesOrNoSelection.Yes &&
      form.homeStandardRedesignation.redesignationForm
        .wereAnyRoofSheathingPanelsReplaced === YesOrNoSelection.Yes
    ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ReplacedRoofSheathingNailSpacingPhotos"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: !(
      form.homeStandardRedesignation.redesignationForm
        .hasTheRoofCoverBeenReplacedInLast5Years === YesOrNoSelection.No &&
      form.homeStandardRedesignation.redesignationForm
        .hasAnyPortionOfRoofBeenRepairedInLast5Years === YesOrNoSelection.Yes &&
      form.homeStandardRedesignation.redesignationForm
        .wereAnyRoofSheathingPanelsReplaced === YesOrNoSelection.Yes
    ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("WasAnyPortionOfSealedRoofDeckRepaired"),
    hidden: !(
      form.homeStandardRedesignation.redesignationForm
        .hasTheRoofCoverBeenReplacedInLast5Years === YesOrNoSelection.No &&
      form.homeStandardRedesignation.redesignationForm
        .hasAnyPortionOfRoofBeenRepairedInLast5Years === YesOrNoSelection.Yes
    ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey("RepairedSealedRoofDeckPhotos"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: !(
      form.homeStandardRedesignation.redesignationForm
        .hasTheRoofCoverBeenReplacedInLast5Years === YesOrNoSelection.No &&
      form.homeStandardRedesignation.redesignationForm
        .hasAnyPortionOfRoofBeenRepairedInLast5Years === YesOrNoSelection.Yes &&
      form.homeStandardRedesignation.redesignationForm
        .wasAnyPortionOfSealedRoofDeckRepaired === YesOrNoSelection.Yes
    ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ReplacedUnderlaymentLabelPhoto"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: !(
      form.homeStandardRedesignation.redesignationForm
        .hasTheRoofCoverBeenReplacedInLast5Years === YesOrNoSelection.No &&
      form.homeStandardRedesignation.redesignationForm
        .hasAnyPortionOfRoofBeenRepairedInLast5Years === YesOrNoSelection.Yes &&
      form.homeStandardRedesignation.redesignationForm
        .wasAnyPortionOfSealedRoofDeckRepaired === YesOrNoSelection.Yes
    ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("CertifiedDesignPressureReportForMetalPanelSystem"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: !(
      form.homeStandardRedesignation.redesignationForm
        .hasTheRoofCoverBeenReplacedInLast5Years === YesOrNoSelection.No &&
      form.homeStandardRedesignation.redesignationForm
        .hasAnyPortionOfRoofBeenRepairedInLast5Years === YesOrNoSelection.Yes &&
      form.homeStandardRedesignation.redesignationForm
        .wasAnyPortionOfSealedRoofDeckRepaired === YesOrNoSelection.Yes
    ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("WasAnyPortionOfDripEdgeReplaced"),
    hidden: !(
      form.homeStandardRedesignation.redesignationForm
        .hasTheRoofCoverBeenReplacedInLast5Years === YesOrNoSelection.No &&
      form.homeStandardRedesignation.redesignationForm
        .hasAnyPortionOfRoofBeenRepairedInLast5Years === YesOrNoSelection.Yes
    ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey("ReplacedDripEdgePhoto"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: !(
      form.homeStandardRedesignation.redesignationForm
        .hasTheRoofCoverBeenReplacedInLast5Years === YesOrNoSelection.No &&
      form.homeStandardRedesignation.redesignationForm
        .hasAnyPortionOfRoofBeenRepairedInLast5Years === YesOrNoSelection.Yes &&
      form.homeStandardRedesignation.redesignationForm
        .wasAnyPortionOfDripEdgeReplaced === YesOrNoSelection.Yes
    ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("WasAnyPortionOfRoofCoverReplaced"),
    hidden: !(
      form.homeStandardRedesignation.redesignationForm
        .hasTheRoofCoverBeenReplacedInLast5Years === YesOrNoSelection.No &&
      form.homeStandardRedesignation.redesignationForm
        .hasAnyPortionOfRoofBeenRepairedInLast5Years === YesOrNoSelection.Yes
    ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey("ReplacedRoofCoverOverallSectionsPhoto"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: !(
      form.homeStandardRedesignation.redesignationForm
        .hasTheRoofCoverBeenReplacedInLast5Years === YesOrNoSelection.No &&
      form.homeStandardRedesignation.redesignationForm
        .hasAnyPortionOfRoofBeenRepairedInLast5Years === YesOrNoSelection.Yes &&
      form.homeStandardRedesignation.redesignationForm
        .wasAnyPortionOfRoofCoverReplaced === YesOrNoSelection.Yes
    ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ReplacedRoofCoverInstallationPhotos"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: !(
      form.homeStandardRedesignation.redesignationForm
        .hasTheRoofCoverBeenReplacedInLast5Years === YesOrNoSelection.No &&
      form.homeStandardRedesignation.redesignationForm
        .hasAnyPortionOfRoofBeenRepairedInLast5Years === YesOrNoSelection.Yes &&
      form.homeStandardRedesignation.redesignationForm
        .wasAnyPortionOfRoofCoverReplaced === YesOrNoSelection.Yes
    ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ReplacedRoofCoverProductLabelPhoto"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: !(
      form.homeStandardRedesignation.redesignationForm
        .hasTheRoofCoverBeenReplacedInLast5Years === YesOrNoSelection.No &&
      form.homeStandardRedesignation.redesignationForm
        .hasAnyPortionOfRoofBeenRepairedInLast5Years === YesOrNoSelection.Yes &&
      form.homeStandardRedesignation.redesignationForm
        .wasAnyPortionOfRoofCoverReplaced === YesOrNoSelection.Yes
    ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("DoesNewRoofCoverMeetHailImpactRequirements"),
    hidden: !(
      form.homeStandardRedesignation.redesignationForm
        .hasTheRoofCoverBeenReplacedInLast5Years === YesOrNoSelection.No &&
      form.homeStandardRedesignation.originEligibilityForm
        ?.hasHailSupplement === YesOrNoSelection.Yes &&
      form.homeStandardRedesignation.redesignationForm
        .hasAnyPortionOfRoofBeenRepairedInLast5Years === YesOrNoSelection.Yes
    ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey("ReplacedRoofCoverHailImpactRatingPhoto"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: !(
      form.homeStandardRedesignation.redesignationForm
        .hasTheRoofCoverBeenReplacedInLast5Years === YesOrNoSelection.No &&
      form.homeStandardRedesignation.originEligibilityForm
        ?.hasHailSupplement === YesOrNoSelection.Yes &&
      form.homeStandardRedesignation.redesignationForm
        .hasAnyPortionOfRoofBeenRepairedInLast5Years === YesOrNoSelection.Yes &&
      form.homeStandardRedesignation.redesignationForm
        .doesNewRoofCoverMeetHailImpactRequirements === YesOrNoSelection.Yes
    ),
  } as WildfireBoxContentExplorerProps,
  buildFieldKey("HaveThereBeenAnyStructuralAdditionsToHomeInLast5Years"),
  {
    fieldKey: buildFieldKey("StructuralAdditionsRenovationsPhotos"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: !(
      form.homeStandardRedesignation.redesignationForm
        .haveThereBeenAnyStructuralAdditionsToHomeInLast5Years ===
      YesOrNoSelection.Yes
    ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("StructuralAdditionsRenovationsDocumentation"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: !(
      form.homeStandardRedesignation.redesignationForm
        .haveThereBeenAnyStructuralAdditionsToHomeInLast5Years ===
      YesOrNoSelection.Yes
    ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("DoAnyNewAdditionsQualifyAsAttachedStructure"),
    hidden: !(
      form.homeStandardRedesignation.redesignationForm
        .haveThereBeenAnyStructuralAdditionsToHomeInLast5Years ===
      YesOrNoSelection.Yes
    ),
  } as EnumRadioFieldProps,
  buildFieldKey("RedesignationUpdatedSystemTypes"),
  {
    fieldKey: buildFieldKey("ModifiedRoofMountedVentsOrSoffitsPhoto"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeStandardRedesignation.redesignationForm.redesignationUpdatedSystemTypes?.includes(
        RedesignationUpdatedSystemTypes.RoofMountedVentsOrSoffits
      ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ModifiedRoofMountedVentsOrSoffitsDocumentation"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeStandardRedesignation.redesignationForm.redesignationUpdatedSystemTypes?.includes(
        RedesignationUpdatedSystemTypes.RoofMountedVentsOrSoffits
      ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ModifiedRoofMountedPhotovoltaicPVSystemPhoto"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeStandardRedesignation.redesignationForm.redesignationUpdatedSystemTypes?.includes(
        RedesignationUpdatedSystemTypes.RoofMountedPhotovoltaicSystem
      ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey(
      "ModifiedRoofMountedPhotovoltaicPVSystemDocumentation"
    ),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeStandardRedesignation.redesignationForm.redesignationUpdatedSystemTypes?.includes(
        RedesignationUpdatedSystemTypes.RoofMountedPhotovoltaicSystem
      ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ModifiedSkylightsPhotos"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeStandardRedesignation.redesignationForm.redesignationUpdatedSystemTypes?.includes(
        RedesignationUpdatedSystemTypes.Skylights
      ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ModifiedSkylightsDocumentation"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeStandardRedesignation.redesignationForm.redesignationUpdatedSystemTypes?.includes(
        RedesignationUpdatedSystemTypes.Skylights
      ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ModifiedEntryDoorsPhotos"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeStandardRedesignation.redesignationForm.redesignationUpdatedSystemTypes?.includes(
        RedesignationUpdatedSystemTypes.EntryDoors
      ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ModifiedEntryDoorsDocumentation"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeStandardRedesignation.redesignationForm.redesignationUpdatedSystemTypes?.includes(
        RedesignationUpdatedSystemTypes.EntryDoors
      ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ModifiedWindowsPhotos"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeStandardRedesignation.redesignationForm.redesignationUpdatedSystemTypes?.includes(
        RedesignationUpdatedSystemTypes.Windows
      ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ModifiedWindowsDocumentation"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeStandardRedesignation.redesignationForm.redesignationUpdatedSystemTypes?.includes(
        RedesignationUpdatedSystemTypes.Windows
      ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ModifiedGarageDoorsPhotos"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeStandardRedesignation.redesignationForm.redesignationUpdatedSystemTypes?.includes(
        RedesignationUpdatedSystemTypes.GarageDoors
      ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ModifiedGarageDoorsDocumentation"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeStandardRedesignation.redesignationForm.redesignationUpdatedSystemTypes?.includes(
        RedesignationUpdatedSystemTypes.GarageDoors
      ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ModifiedWindowAndDoorShutterSystemsPhotos"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeStandardRedesignation.redesignationForm.redesignationUpdatedSystemTypes?.includes(
        RedesignationUpdatedSystemTypes.WindowAndDoorShutterSystems
      ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ModifiedWindowAndDoorShutterSystemsDocumentation"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeStandardRedesignation.redesignationForm.redesignationUpdatedSystemTypes?.includes(
        RedesignationUpdatedSystemTypes.WindowAndDoorShutterSystems
      ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("IsCorrosionRustVisible"),
    hidden: ![
      RedesignationUpdatedSystemTypes.RoofMountedVentsOrSoffits,
      RedesignationUpdatedSystemTypes.RoofMountedPhotovoltaicSystem,
      RedesignationUpdatedSystemTypes.Skylights,
      RedesignationUpdatedSystemTypes.EntryDoors,
      RedesignationUpdatedSystemTypes.Windows,
      RedesignationUpdatedSystemTypes.GarageDoors,
      RedesignationUpdatedSystemTypes.WindowAndDoorShutterSystems,
    ].some((systemType) =>
      form.homeStandardRedesignation.redesignationForm.redesignationUpdatedSystemTypes?.includes(
        systemType
      )
    ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey("VisibleCorrosionPhotos"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: !(
      [
        RedesignationUpdatedSystemTypes.RoofMountedVentsOrSoffits,
        RedesignationUpdatedSystemTypes.RoofMountedPhotovoltaicSystem,
        RedesignationUpdatedSystemTypes.Skylights,
        RedesignationUpdatedSystemTypes.EntryDoors,
        RedesignationUpdatedSystemTypes.Windows,
        RedesignationUpdatedSystemTypes.GarageDoors,
        RedesignationUpdatedSystemTypes.WindowAndDoorShutterSystems,
      ].some((systemType) =>
        form.homeStandardRedesignation.redesignationForm.redesignationUpdatedSystemTypes?.includes(
          systemType
        )
      ) &&
      form.homeStandardRedesignation.redesignationForm
        .isCorrosionRustVisible === YesOrNoSelection.Yes
    ),
  } as WildfireBoxContentExplorerProps,
];

const redesignationFormRequiredFields = [
  buildFieldKey("DesignationLevel"),
  buildFieldKey("HasTheRoofCoverBeenReplacedInLast5Years"),
  buildFieldKey("OverallRedesignationElevationPhotos"),
  buildFieldKey("HasAnyPortionOfRoofBeenRepairedInLast5Years"),
  buildFieldKey("WereAnyStructuralMembersRepaired"),
  buildFieldKey("RoofStructuralMemberRepairPhoto"),
  buildFieldKey("SignedAndSealedRepairDesignFromProfessionalEngineer"),
  buildFieldKey("WereAnyRoofSheathingPanelsReplaced"),
  buildFieldKey("ReplacedRoofSheathingTypeThicknessPhoto"),
  buildFieldKey("ReplacedRoofSheathingNailsPhoto"),
  buildFieldKey("ReplacedRoofSheathingNailSpacingPhotos"),
  buildFieldKey("WasAnyPortionOfSealedRoofDeckRepaired"),
  buildFieldKey("RepairedSealedRoofDeckPhotos"),
  buildFieldKey("ReplacedUnderlaymentLabelPhoto"),
  buildFieldKey("CertifiedDesignPressureReportForMetalPanelSystem"),
  buildFieldKey("WasAnyPortionOfDripEdgeReplaced"),
  buildFieldKey("ReplacedDripEdgePhoto"),
  buildFieldKey("WasAnyPortionOfRoofCoverReplaced"),
  buildFieldKey("ReplacedRoofCoverOverallSectionsPhoto"),
  buildFieldKey("ReplacedRoofCoverInstallationPhotos"),
  buildFieldKey("ReplacedRoofCoverProductLabelPhoto"),
  buildFieldKey("DoesNewRoofCoverMeetHailImpactRequirements"),
  buildFieldKey("ReplacedRoofCoverHailImpactRatingPhoto"),
  buildFieldKey("HaveThereBeenAnyStructuralAdditionsToHomeInLast5Years"),
  buildFieldKey("StructuralAdditionsRenovationsPhotos"),
  buildFieldKey("StructuralAdditionsRenovationsDocumentation"),
  buildFieldKey("DoAnyNewAdditionsQualifyAsAttachedStructure"),
  buildFieldKey("RedesignationUpdatedSystemTypes"),
  buildFieldKey("ModifiedRoofMountedVentsOrSoffitsPhoto"),
  buildFieldKey("ModifiedRoofMountedVentsOrSoffitsDocumentation"),
  buildFieldKey("ModifiedRoofMountedPhotovoltaicPVSystemPhoto"),
  buildFieldKey("ModifiedRoofMountedPhotovoltaicPVSystemDocumentation"),
  buildFieldKey("ModifiedSkylightsPhotos"),
  buildFieldKey("ModifiedSkylightsDocumentation"),
  buildFieldKey("ModifiedEntryDoorsPhotos"),
  buildFieldKey("ModifiedEntryDoorsDocumentation"),
  buildFieldKey("ModifiedWindowsPhotos"),
  buildFieldKey("ModifiedWindowsDocumentation"),
  buildFieldKey("ModifiedGarageDoorsPhotos"),
  buildFieldKey("ModifiedGarageDoorsDocumentation"),
  buildFieldKey("ModifiedWindowAndDoorShutterSystemsPhotos"),
  buildFieldKey("ModifiedWindowAndDoorShutterSystemsDocumentation"),
  buildFieldKey("IsCorrosionRustVisible"),
  buildFieldKey("VisibleCorrosionPhotos"),
];

// Full form builder with all parts included
export const homeStandardRedesignationFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardRedesignationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardRedesignationFormModel>(
        modelName,
        redesignationFormFields(form),
        onValueChange,
        form,
        errors,
        redesignationFormRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardRedesignationFormModel>;
};

// Full form builder with all parts included, for Iteration Engine
export const homeStandardRedesignationFormIterationEngineBuilder = (
  factory: SchemaFactoryV2,
  beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardRedesignationFormModel> => {
  return ((onValueChange, form, errors) => {
    let fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardRedesignationFormModel>(
        modelName,
        redesignationFormFields(form),
        onValueChange,
        form,
        errors,
        redesignationFormRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    if (beforeBuild) {
      fields = beforeBuild(fields);
    }
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardRedesignationFormModel>;
};
