import { Schema } from "@ucl/library";
import wildfireBaseApiClient from "../wildfireBaseApiClient";

class WildfireFieldSchemaApiClient {
  getSchema = async (): Promise<Schema> => {
    return await wildfireBaseApiClient.get<Schema>(`/field-schema/wildfire`);
  };
}

export const wildfireFieldSchemaApiClient = new WildfireFieldSchemaApiClient();
