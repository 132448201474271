import { PrimaryCard } from "@ucl/library";
import { useContext, useEffect, useState } from "react";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import classNames from "classnames";
import { EvaluationStep } from "../../../../../../foritfied/types/evaluation/Evaluation";
import { UnsavedChangesBlockerContext } from "../../../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import HomeNCIUARedesignationHomeownerAuthorizationForm from "../HomeownerAuthorizationForm/Form/HomeNCUIARedesignationHomeownerAuthorizationForm";
import HomeNCIUARedesignationForm from "../RedesignationForm/Form/HomeNCUIARedesignationForm";

interface HomeNCIUARedesignationIterationEngineFormsProps {
  selectedTabId: string;
  evaluationId: string;
  evaluationStep: EvaluationStep;
  onFormSave: (fieldKey: string) => Promise<void>;
  onAllFormsInitialized: () => void;
  fieldsContainerClassName: string;
}

const HomeNCIUARedesignationIterationEngineForms: React.FC<
  HomeNCIUARedesignationIterationEngineFormsProps
> = (props) => {
  // const [
  //   evaluationFormInitiaized,
  //   //setEvaluationFormInitiaized
  // ] = useState<boolean>();

  const [
    homeownerAuthorizationInitialized,
    setHomeownerAuthorizationFormInitialized,
  ] = useState<boolean>(false);

  const [redesignationFormInitialized, setRedesignationFormInitialized] =
    useState<boolean>(false);

  const [allFormsInitialized, setAllFormsInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const formsInitialized =
      homeownerAuthorizationInitialized || redesignationFormInitialized;

    if (formsInitialized) {
      setAllFormsInitialized(true);
    } else {
      setAllFormsInitialized(false);
    }
  }, [homeownerAuthorizationInitialized, redesignationFormInitialized]);

  useEffect(() => {
    if (allFormsInitialized) {
      setTimeout(() => {
        props.onAllFormsInitialized();
      }, 800);
      setIsLoading(false);
      setAllFormsInitialized(false);
      setHomeownerAuthorizationFormInitialized(false);
      setRedesignationFormInitialized(false);
    }
  }, [allFormsInitialized]);

  const { setHasUnsavedChanges } = useContext(UnsavedChangesBlockerContext);

  const getFormAndHeaderContent = () => {
    switch (props.selectedTabId) {
      case "HomeNCIUARedesignationHomeownerAuthorizationForm":
        return {
          headerContent:
            "Home NCIUA Redesignation Homeowner Authorization Form",
          form: (
            <HomeNCIUARedesignationHomeownerAuthorizationForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setHomeownerAuthorizationFormInitialized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      default:
        return {
          headerContent: "Home NCIUA Redesignation Form",
          form: (
            <HomeNCIUARedesignationForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setRedesignationFormInitialized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
    }
  };

  const { headerContent, form } = getFormAndHeaderContent();

  return (
    <>
      {isLoading && (
        <>
          <Loading />
        </>
      )}
      <div
        className={classNames(props.fieldsContainerClassName, {
          "evaluation-forms-container-initialized": !isLoading,
        })}
      >
        <PrimaryCard headerContent={headerContent}>{form}</PrimaryCard>
      </div>
    </>
  );
};

export default HomeNCIUARedesignationIterationEngineForms;
